import { Select, message } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'
import crossOrange from '../../../../../../../assets/images/cross-orange.svg'
import Button from '../../../../../../../components/common/Button/button'
import { useStore } from '../../../../../../../mobx-store/context'
import { Title, Item, ItemsWrap, SelectBLock, Wrap } from './style'
import { api } from '../../../../../../../api'
import { getError } from '../../../../../../../helpers/helpers'
import { toJS } from 'mobx'

const CustomizeBlock = observer(
  ({ name, restrictions, title, userId }) => {
    const store = useStore()
    const [value, setValue] = useState(null)

    const [filteredItems, setFilteredItems] = useState([])
    const [customizeEmails, setCustomizeEmails] = useState([])

    const getFilteredItems = () => {
      api(`/users/${userId}/customize_emails/`, {}, 'GET').then((data) => {
        if (!data.errors) {
          store.setSingleValue('customize_emails', data)
        }
      })
    }
    const getCustomizeEmails = () => {
      api(`/users/${userId}/customize_data/`, {}, 'GET').then((data) => {
        if (!data.errors) {
          store.setSingleValue('customize_data', data)
        }
      })
    }

    const updateFliteredList = (id, remove) => {
      api(`/users/${userId}/customize_emails/`,
        { [restrictions]: +id },
        remove ? 'POST' : 'DELETE',
      )
        .then(data => {
          if (data.errors) return message.error(getError(data))
          getFilteredItems()
          getCustomizeEmails()
        })
    }

    const moveItem = (id, remove) => {
      let itemToMove = null;
      let sourceArray = null;
      let destinationArray = null;


      if (remove) {
        // Move from activeItems to disabledItems
        itemToMove = customizeEmails.find((item) => item.id === id);
        sourceArray = customizeEmails;
        destinationArray = filteredItems;
      } else {
        // Move from disabledItems to activeItems
        itemToMove = filteredItems.find((item) => item.id === id);
        sourceArray = filteredItems;
        destinationArray = customizeEmails;
      }
      if (itemToMove) {
        const updatedSourceArray = sourceArray.filter((item) => item.id !== id);

        const updatedDestinationArray = [...destinationArray, itemToMove];

        // Update the state
        if (remove) {
          setCustomizeEmails(updatedSourceArray);
          setFilteredItems(updatedDestinationArray);
          updateFliteredList(id, remove)
        } else {
          setCustomizeEmails(updatedDestinationArray);
          setFilteredItems(updatedSourceArray);
          updateFliteredList(id, remove)
        }
      }
    };

    useEffect(() => {
      setFilteredItems(toJS(store?.customize_emails?.[restrictions]))
      setCustomizeEmails(toJS(store?.customize_data?.[name]))
    }, [])

    const onRestrict = (value, mode) => {
      if (!filteredItems.includes(value)) {
        moveItem(value, mode)
        setValue(null)
      }
    }

    const getOptions = () => {
      const filteredEmails = customizeEmails?.filter(item => !item.is_taken);
      return filteredEmails?.map((item) => (
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      ))
    }

    return (
      <Wrap>
        {store.customize_emails &&
          <>
            <Title>
              {title} <span>({filteredItems.length})</span>
            </Title>
            <ItemsWrap>
              {filteredItems?.map((item, index) => (
                item.name && <Item
                  key={item.id}
                  style={{
                    backgroundColor: index % 2 !== 0 && 'rgba(34, 81, 148, 0.05)',
                  }}
                >
                  {item.name}
                  <img
                    src={crossOrange}
                    onClick={() => moveItem(item.id, false)}
                    alt='cross-icon'
                  />
                </Item>
              ))}
            </ItemsWrap>
            {(
              <SelectBLock>
                <Select
                  onChange={(value) => setValue(value)}
                  value={value}
                >
                  {getOptions()}
                </Select>
                <Button
                  text={'Add'}
                  style={{ height: 20, fontSize: 10, marginLeft: 15 }}
                  onClick={() => onRestrict(value, true)}
                />
              </SelectBLock>
            )}
          </>
        }
      </Wrap>
    )
  }
)

export default CustomizeBlock;
