import { message } from 'antd'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { api } from '../../../../../api'
import { useStore } from '../../../../../mobx-store/context'
import Modal from './Modal'
import { Button, Wrap } from './style'

const UserDependenciesWarning = ({
  deactivateUser,
  error,
  userID,
  setError,
  deleting,
  setDeleting,
  onEdit,
  isRoleChanging,
  setIsAccessRemoving,
}) => {
  const store = useStore()
  const history = useHistory()

  const isActive = store.member?.is_active

  const activateUser = () => {
    setError(null)
    api(
      `/members/${userID}/change_is_active/`,
      { is_active: true },
      'POST'
    ).then((data) => {
      if (!data.ok) {
        message.success('Error')
      } else {
        history.push('/manage-team-members')
        message.success('User Activated')
      }
    })
  }

  useEffect(() => {
    if (!store.members.length) store.loadMembers()
  }, [])

  return (
    <Wrap>
      {error && (
        <Modal
          error={error}
          onClose={() => {
            setError(null)
            setIsAccessRemoving(false)
            if (deleting) setDeleting(false)
          }}
          deactivate={deactivateUser}
          activate={activateUser}
          deleting={deleting}
          onEdit={onEdit}
          isRoleChanging={isRoleChanging}
        />
      )}
      <Button
        onClick={() => {
          store.setSingleValue('userDeactivation', true)
          setDeleting(true)
          if (!isActive) {
            setError({ code: 'reactivate' })
          } else {
            setError({ code: 'are_you_sure' })
          }
        }}
      >
        {isActive ? 'Deactivate This User' : 'Reactivate This User'}
      </Button>
    </Wrap>
  )
}

export default UserDependenciesWarning
