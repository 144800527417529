import React from 'react'
import Form from '../../../../Billing/Form'

const Payment = ({
  handleChangeTariff,
  onClose,
  loading,
  addingNewMethod,
  leftTitle,
}) => {

  return <Form
    addingNewMethod={addingNewMethod}
    handleCustomSubmit={values => handleChangeTariff(values)}
    isTariffChanging
    onClose={onClose}
    propLoading={loading}
    leftTitle={leftTitle}
  />
}

export default Payment
