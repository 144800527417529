import styled from 'styled-components'

export const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
  padding-top: 100px;
`

export const Wrap = styled('div')`
  width: 904px;
  text-align: center;
  img {
    width: 554px;
    margin-top: 50px;
  }
`

export const Title = styled('div')`
  font-family: var(--regular);
  font-size: 36px;
  span {
    font-family: var(--bold);
    color: var(--green);
  }
  a {
    color: var(--orange);
    text-decoration: underline;
  }
`
