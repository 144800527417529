import styled from 'styled-components'
import { DescriptionGlobal, TitleGlobal } from '../../Compliance/styles'


export const Wrap = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
`
export const SubTitleGlobal = styled(TitleGlobal)`
  text-align: left;
  font-family: var(--medium);
  font-size: 32px;
  color: var(--text);
  line-height: 1.3em;
  transition: all 0.2s ease-in-out;
    br {
      display: none;
    }
  }
  `

export const Description = styled(DescriptionGlobal)`
  font-family: var(--regular);  
  color: var(--text);
  font-size: 20px;
  text-align: center;
  line-height: 28px;
  margin-top: 20px;
  @media (max-width: 1300px) {
    font-size: 16px;
    line-height: 26px;
  }
`
export const Grid = styled('div')`
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-top: 70px;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`

export const Container = styled('div')`
  max-width: 1250px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 70px;
  align-items: center;
  @media (max-width: 950px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 0 15px;
    ${Grid} {
      grid-template-columns: 1fr 1fr;
    }
  }
`
export const Image = styled('img')`
  width: 100%;
  @media (max-width: 950px) {
    width: 70%;
  }
`