import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import Moment from 'react-moment'
import { useHistory } from 'react-router-dom'
import { useStore } from '../../../../../../mobx-store/context'
import { Title } from '../../../../components/common/style'
import ResourceModal from '../../../../components/ResourceAccessModal/resourceModal'
import Info from '../../info'

const LicenseSourceTab = observer(() => {
  const store = useStore()
  const history = useHistory()


  const company = store.company[0]

  const leftData = [
    {
      key: 0,
      title: 'Plan Admin',
      text:
        store.members
          ?.filter((item) => item.member_type === 'admin')
          ?.map((item) => ` ${item.first_name} ${item.last_name}`)
          .toString() || '-',
    },
    {
      key: 1,
      title: 'Effective Date',
      hide: !company.license_source_registration_date,
      text: (
        company?.license_source_registration_date ?
          <>
            <Moment format={'MM/DD/YYYY'}>{company?.license_source_registration_date}</Moment>
          </> : '-'
      )
    },
    {
      key: 2,
      title: 'Access Through',
      hide: !company.license_source_next_payment_date,
      text: (company?.license_source_next_payment_date ?
        <>
          <Moment format={'MM/DD/YYYY'}>{company?.license_source_next_payment_date}</Moment>
        </> : '-'
      )
    },
    {
      key: 3,
      title: 'Look Back Date',
      hide: !company.show_license_items_due_date,
      text: (company?.show_license_items_due_date ?
        <>
          <Moment format={'MM/DD/YYYY'}>{company?.show_license_items_due_date}</Moment>
        </> : '-'
      )
    },
  ]
  const rightData = [
    // {
    //   key: 0,
    //   title: company?.under_contract ? 'Custom Price' : 'Plan Cost',
    //   text: company?.under_contract
    //     ? `$${company.report_source_custom_price}/year`
    //     : '$4,500/year',
    // },
    // {
    //   key: 1,
    //   title: 'Next Billing Date',
    //   text: (
    //     <Moment format={'MM/DD/YYYY'}>
    //       {company.report_source_next_payment_date}
    //     </Moment>
    //   ),
    // },
    // {
    //   key: 2,
    //   title: 'Billing Schedule',
    //   text: 'Annual (Discount Applied)',
    // },
  ]

  useEffect(() => {
    if (!store.members.length) store.loadMembers(null, false, true)
    if (!store.billingCards) store.getBillingCards()
    store.loadSelect('memberOptions')
  }, [])


  if (!store.user?.[0]?.have_access_to_license_source || !store.company?.[0]?.have_access_to_license_source)
    return (
      <ResourceModal
        type='licenseSource'
        to={store.user?.[0]?.member_type === 'admin' ? '/addReportMember' : '/edit-profile'}
        onClose={() => history.push('/edit-profile')}
      />
    )

  return (
    <>
      <Title>
        {'LicenseSource'}
      </Title>
      <Info leftData={leftData} rightData={rightData} />
      {/* <BillingSchedule /> */}
    </>
  )
})

export default LicenseSourceTab
