import styled from 'styled-components';

export const UnderlinedButton = styled('div')`
font-size: 0.75em;
color: var(--grey);
text-decoration: underline;
font-family: var(--bold);
cursor: pointer;
margin-bottom: 10px;
`

export const TextContainer = styled('div')`
  display: flex;
  padding: 7px 11px;
  border-radius: 6px;
  border: 1px solid var(--grey);
  width: 280px;
`
export const Text = styled('div')`
color: var(--text);
font-size: 14px;
font-family: var(--regular);
`

export const PasswordButton = styled('div')`
font-size: 14px; 
font-family: var(--semiBold); 
margin-bottom: 8px;
cursor: pointer;
color: var(--blue);
&:hover {
  text-decoration: underline;
 } 

`