import React from 'react'
import { PlainText as Wrap } from './style'

const PlainText = ({ children, style, onClick }) => {
  return (
    <Wrap style={style} onClick={onClick}>
      {children}
    </Wrap>
  )
}

export default PlainText
