import styled from 'styled-components'

export const Wrap = styled('div')`
  padding-top: 100px;
  height: 90vh;
  // min-height: 910px;
  // padding-bottom: 100px;
`

export const CrossJurContainer = styled('div')`
  width: ${(props) => (+props.numberOfColumns <= 4 ? '1400px' : '2030px' )} ;
  margin: 0 auto;
  padding: 0 10px 20px 10px;
`
export const Container = styled('div')`
  width: 1400px;
  margin: 0 auto;
  padding: 0 10px 20px 10px;
`

export const Title = styled('div')`
  font-family: var(--regular);
  font-size: 40px;
  color: var(--text);
`

export const Grid = styled('div')`
  display: grid;
  grid-template-columns: 450px 1fr;
  column-gap: 50px;
`
