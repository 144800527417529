import React from "react";
import { Overlay, Wrap, Title, Row, } from "./CustomPrompt.style";
import Button from '../../../components/common/Button/button'
import closeIcon from '../../../assets/images/close-grey.svg'

const CustomPromptModal = ({ onConfirm, onCancel}) => {


  return (
    <Overlay onClick={onCancel}>
      <Wrap>
        <img
          src={closeIcon}
          alt='close-icon'
          onClick={onCancel}
          id='close'
        />
        <Title
          style={{ textAlign: 'center' }}
        >
          Are you sure you want to leave before saving?
        </Title>
        <Row>

        </Row>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 15,
          }}
        >
          <Button
            text={'Cancel'}
            onClick={onCancel}
            type={'white'}
            style={{ marginRight: 20 }}
          />
          <Button
            text={'Confirm'}
            onClick={onConfirm}
          />
        </div>
      </Wrap>
    </Overlay>
  );
};

export default CustomPromptModal;