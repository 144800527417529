import styled from 'styled-components'

export const Title = styled("div")`
  font-family: var(--thin);
  font-size: 3.000em;
  color: var(--text);
`;

export const Header = styled("div")`
  display: flex;
  align-items: center;
  color: var(--text);
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Name = styled("div")`
  font-family: var(--medium);
  font-size: 1.375em;
  line-height: 1em;
  border-right: 2px solid var(--text);
  padding-right: 15px;
  margin-right: 15px;
`;
