import React from 'react'
import { Image, Wrap } from './logo.style'
import logo from '../../../assets/images/Logo.svg'
import { Link, useLocation } from 'react-router-dom'

const noClickUrls = [
  'step1',
  'reset-password-enter-email',
  'reset-password-email',
  'reset-password-confirm',
  'terms-and-privacy',
  'businessInfo',
  'pricing',
  'topicsAndJur',
  'addMember',
  'billing',
  'startDate',
  'confirm-email',
]

const Logo = ({ alt, large, style }) => {
  const location = useLocation()

  const isInclude = noClickUrls.includes(location.pathname.split('/')[1])

  return (
    <Wrap
      to={!isInclude ? '/home' : '#'}
      style={{ cursor: !isInclude ? 'pointer' : 'default' }}
      as={!isInclude ? Link : 'div'}
    >
      <Image src={logo} alt={alt} large={large} style={style}/>
    </Wrap>
  )
}

export default Logo
