import { Spin } from 'antd'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import Cookies from 'universal-cookie'
import { api } from '../../../../../api'
import ButtonOrange from '../../../../../components/common/ButtonOrange'
import { checkRole, tariffs } from '../../../../../helpers/helpers'
import { useStore } from '../../../../../mobx-store/context'
import { Row, Title } from '../../../components/common/style'
import PastDueAlerts from '../pastDueAlerts'
import AutoRenewal from '../autoRenewal'
import { Text } from '../style'
import Table from '../table'

const ComplianceTab = observer(() => {
  const store = useStore()
  const company = store.company[0]

  const members = store.members.filter((item) => item.have_access_to_tracking)
  const planName = `${tariffs[company?.tariff]} `

  const patchAutoRenewal = (checked) => {
    api(
      `/companies/${store.company[0].id}/`,
      {
        auto_renew_subscription: checked,
      },
      'PATCH'
    ).then((data) => {
      if (!data.error) {
        store.setSingleValue('company', [data])
      }
    })
  }


  // const getFile = () => {
  //   const cookies = new Cookies()
  //   const csrftoken = cookies.get('csrftoken')
  //   let token = cookies.get('pbm_token')

  //   fetch(
  //     `${process.env.REACT_APP_API_DEV
  //     }${'/export_legislative/'}`,
  //     {
  //       method: 'POST',
  //       headers: {
  //         Authorization: `Token ${token}`,
  //         'X-CSRFToken': csrftoken,
  //         'Content-Type': 'application/json',
  //       },
  //     }
  //   )
  //     .then((response) => response.blob())
  //     .then((blob) => {

  //       const url = window.URL.createObjectURL(new Blob([blob]))
  //       const link = document.createElement('a')
  //       link.href = url
  //       link.setAttribute('download', `export_legilsative.csv`)
  //       document.body.appendChild(link)
  //       link.click()
  //       link.parentNode.removeChild(link)
  //     })
  // }

  useEffect(() => {
    if (!store.tariffInfo) store.getTariffInfo()
  }, [])

  if (!company) return <Spin />

  return (
    <>
      <Row Row style={{ marginTop: '1em' }}>
        {/* <Title>{planName}</Title> */}
        {/* {checkRole([store.user?.[0]?.member_type], ['admin']) && (
          <ButtonOrange
            text={'Edit Your Plan'}
            link={'/current-plan'}
            style={{ marginLeft: '60px' }}
          />
        )} */}
      </Row>
      {/* {company?.under_contract ?
        <Text style={{ marginTop: '10px' }}>
          Custom Price:{' '}
          <span style={{ fontFamily: 'var(--medium)' }}>
            ${company?.compliance_custom_price}/yr
          </span>
        </Text>
        :
        <Text style={{ marginTop: '10px' }}>
          {store.company[0]?.payment_period === 'month' ? 'Month' : 'Annual'}{' '}
          Subscription:{' '}
          <span style={{ fontFamily: 'var(--medium)' }}>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(getPrice(store))}
            /{store.company[0]?.payment_period === 'month' ? 'mo' : 'yr'}
          </span>
        </Text>
      } */}
      <Text>{members?.length} Active Users</Text>
      {/* {checkRole([store.user?.[0]?.member_type], ['admin']) && !company?.under_contract && (
        <AutoRenewal
          checked={company?.auto_renew_subscription}
          onCheck={patchAutoRenewal}
          firstPaymentDate={company?.first_payment_date}
          nextDate={company?.next_year_date}
        />
      )} */}
      {checkRole([store.user?.[0]?.member_type], ['admin']) &&
        <PastDueAlerts 
        checked={company?.compliance_past_due_items_notifications_frequency}
        isReportSource={false}
        />
      }
      {/* <ButtonOrange
        text={'Export Compliance Tracking'}
        onClick={getFile}
      /> */}
      {store.members.length > 0 ? (
        <Table data={members} isProductSetting />
      ) : (
        <Spin />
      )}
    </>
  )
})

export default ComplianceTab

export const getPrice = (store) => {
  const company = store.company?.[0]

  if (company?.payment_period === 'month') {
    return company.month_price
  } else {
    return (
      company.month_price * 12 -
      company.month_price * 12 * store.tariffInfo?.discount
    )
  }
}

