import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Disclaimer from '../../../components/common/Disclaimer'
import TopLine from '../../../components/TopLine/topLine'
import { useStore } from '../../../mobx-store/context'
import Header from '../components/Header'
import { CrossJurLayout } from '../components/style'
import { CrossJurContainer, Wrap } from '../Resource/style'
import Left from './left'
import Right from './right'

const ViewAcrossJuris = observer(() => {
  const params = useParams()
  const store = useStore()
  const [currentItem, setCurrentItem] = useState(null)
  const [showDisclaimer, setShowDisclaimer] = useState(false)
  const isGuides = params.type === 'guides'

  function countTrueValues(obj) {
    const values = Object.values(obj);
    const trueCount = values.filter(value => value === true).length;
    return trueCount + 1;
  }

  const count = countTrueValues(store?.crossJursTebleSettings)

  useEffect(() => {
    if (!store.lineOfBusiness) store.loadSelect('companyOptions')
    if (!store.resourcesCurrent) store.getJurisdiction(+params.selectId)
    if (!store.resourceFilters) store.getResourceFilters()
    if (!store.lineOfBusiness) store.loadSelect('companyOptions')
  }, [])

  useEffect(() => {
    if (store.resourceFiltered) {
      store.setSingleValue(
        'resourcesCurrent',
        store.resourceFiltered?.find((item) => item.id === +params.topicId)
      )
    }
  }, [store.resourceFiltered])

  useEffect(() => {
    if (params.code)
      setCurrentItem(
        store.resourcesCurrentTopic?.guide_data?.custom_fields?.find(
          (item) => item?.code === params.code
        )
      )
  }, [store.resourcesCurrentTopic])

  useEffect(() => {
    setShowDisclaimer(sessionStorage.getItem('showDisclaimer') === 'true')
  }, [])

  return (
    <>
      <TopLine />
      <Wrap>
        <CrossJurContainer numberOfColumns={count}>
          <Header goBack />
          <CrossJurLayout numberOfColumns={count}>
            <Left
              onItemChoose={(item) => setCurrentItem(item)}
              currentItemCode={currentItem?.code}
            />
            {isGuides
              ? currentItem &&
              store.lineOfBusinesses &&
              store.resourcesCurrentTopic && (
                <Right currentItem={currentItem} numberOfColumns={count} />
              )
              : currentItem &&
              store.lineOfBusinesses &&
              store.resourcesCurrent &&
              store.resourceFilters && <Right currentItem={currentItem} />}
          </CrossJurLayout>
        </CrossJurContainer>
      </Wrap>
      {showDisclaimer && <Disclaimer onClose={() => setShowDisclaimer(false)} />}
    </>
  )
})

export default ViewAcrossJuris
