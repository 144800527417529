import React from 'react'
import { useHistory } from 'react-router-dom'
import Input from '../../../../../components/common/Input'
import { useStore } from '../../../../../mobx-store/context'
import ResourceModal from '../../../components/ResourceAccessModal/resourceModal'
import { complianceInputs } from './inputs'

const ComplianceTab = ({
  control,
  errors,
  showFields,
  memberType,
  register,
  active,
  handleShowField,
}) => {
  const store = useStore()
  const history = useHistory()
  const user = store.user?.[0]

  const { name, label, type, customOptions, placeholder, validation, error } =
    complianceInputs(store, errors, showFields)[0]

  if (
    user?.member_type !== 'admin' &&
    user?.resource_role !== 'admin' &&
    !user?.have_access_to_tracking
  )
    return (
      <ResourceModal
        type='tracking'
        to='/edit-profile'
        onClose={() => history.push('/edit-profile')}
      />
    )

  return (
    <>
      <Input
        name={name}
        label={label}
        type={type}
        control={control}
        customOptions={customOptions}
        placeholder={placeholder}
        validation={validation}
        error={error}
        handleChange={handleShowField}
      />
      <input
        type='hidden'
        name='have_access_to_tracking'
        value={true}
        ref={register}
      />
      {complianceInputs(store, errors, showFields).map(
        ({
          name,
          label,
          type,
          options,
          placeholder,
          validation,
          error,
          showFields,
          key,
          transparent,
          style,
          loading,
        }) =>
          showFields &&
          memberType &&
          memberType[active] !== 'member' && (
            <Input
              name={name}
              label={label}
              error={error}
              type={type}
              control={control}
              validation={validation}
              key={key}
              transparent={transparent}
              style={style}
              placeholder={placeholder}
              options={options}
              loading={loading}
            />
          )
      )}
    </>
  )
}

export default ComplianceTab
