import styled, { css } from "styled-components";
import { Checkbox } from "antd";
export const Title = styled.div`
color: var(--text);
font-family: var(--semiBold);
font-size: 16px;
margin-Top: 20px;
`
export const CheckboxCover = styled.div`
display: flex;
align-items: center;
margin-right: 10px;
`

export const TaskCreateButton = styled.a`
color: #225194;
font-family: var(--medium);
font-size: 14px;
line-height: 15px;
text-decoration: none;
cursor: ${props => props.inActive ? "not-allowed" : "pointer"};
`

export const Text = styled.div`
color: var(--text);
font-family: var(--medium);
font-size: 14px;
line-height: 15px;
`
export const CustomCheckbox = styled(Checkbox)`
  color: var(--text);
  font-family: var(--medium);
  font-size: 14px;
  margin-right: 10px;
  .ant-checkbox-checked .ant-checkbox-inner {
    ${({ green }) =>
    green
      ? css`
            background-color: green;
            border-color: green;
          `
      : css`
            background-color: grey;
            border-color: grey;
          `}
  }
`;
