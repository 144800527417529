import styled from 'styled-components'
import { Wrap as Button } from '../../../../components/common/Button/button.style'
import { query1300, query500, query800 } from '../../helpers'
import { ContainerGlobal, DescriptionGlobal } from '../styles'

export const Wrap = styled('div')`
  height: 730px;
  margin-top: 90px;
  @media${query800} {
    height: auto;
  }

  ${ContainerGlobal} {
    @media ${query800} {
      display: block;
    }
  }
`

export const LeftSide = styled('div')`
  width: 580px;
  height: 730px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media${query1300} {
    width: 430px;
  }
  @media${query800} {
    height: auto;
    padding: 50px 0;
    width: 100%;
  }
  @media${query500} {
    width: 100%;
    padding-top: 30px;
  }

  ${DescriptionGlobal} {
    font-size: 20px;
    margin-top: 40px;
    @media${query1300} {
      font-size: 16px;
    }
    @media${query500} {
      line-height: 1.2em;
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }

  ${Button} {
    font-size: 20px;
    height: 60px;
    padding: 0 27px;
    margin-top: 40px;

    img {
      height: 18px;
      margin-left: 10px;
      margin-top: 3px;
      @media${query1300} {
        height: 12px;
      }
    }

    @media${query1300} {
      height: 51px;
      font-size: 16px;
    }
    @media${query500} {
      margin-top: 0;
    }
  }
`

export const RightSide = styled('div')`
  background-color: #70a3ec;
  height: 730px;
  position: relative;
  display: flex;
  align-items: center;
  @media${query800} {
    height: 500px;
    width: 100vw;
    margin-left: -15px;
  }
  @media${query500} {
    height: 304px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 200px;
    width: 50vw;
    height: 730px;
    background-color: #70a3ec;
    @media ${query800} {
      display: none;
    }
  }

  img {
    height: 650px;
    position: absolute;
    transform: translateX(20px);
    z-index: 3;
    @media${query800} {
      height: 500px;
      transform: none;
    }
    @media${query500} {
      height: 320px;
      right: 0;
      transform: translateX(150px);
    }
  }
`

export const FreeSign = styled('div')`
  font-family: var(--medium);
  font-size: 16px;
  color: var(--orange);
  margin-top: 15px;
  @media${query1300} {
    font-size: 12px;
  }
  @media${query500} {
    display: none;
  }
`
