import React, { useEffect, useState } from 'react'
import { Title } from './style'
import { api } from '../../../../../api'
import CheckboxComponent from './list.jsx'

const TasksCreation = ({ legislative, handleOpen, setAssigneNew, getConsiderationData, considerationData }) => {

  return (
    <>
      <Title style={{ fontSize: 19 }}>
        Select from the list below to create and assign tasks:
      </Title>
      {considerationData && <CheckboxComponent data={considerationData} legislative={legislative} getData={getConsiderationData} handleOpen={handleOpen} setAssigneNew={setAssigneNew} />}
    </>
  )
}

export default TasksCreation