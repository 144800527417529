import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const SelectBLock = styled("div")`
  display: flex;
  width: 210px;
  margin-top: 15px;
  .ant-select-selector {
    height: 30px !important;
  }
  .ant-select-selection-search-input {
    height: 30px !important;
  }
  .ant-select {
    height: 30px;
    width: 100%;
  }
  .ant-select-selection-item {
    height: 30px;
    line-height: 30px !important;
    font-size: 12px !important;
  }
  .ant-select-selection-search {
    height: 30px;
  }
  .ant-select-selection-placeholder {
    color: var(--text); 
    font-Size: 12px;
  }
`;

export const Back = styled("div")`
  cursor: pointer;
  font-family: var(--regular);
  font-size: 14px;
  color: #70a3ec;
  margin-bottom: 10px;
`;