import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { useStore } from '../../../../../../mobx-store/context'
import { useForm } from 'react-hook-form'
import Input from '../../../../../../components/common/Input'
import Button from '../../../../../../components/common/Button/button'
import { Title } from '../../../../components/common/style'
import ResourceModal from '../../../../components/ResourceAccessModal/resourceModal'
import { getError } from '../../../../../../helpers/helpers'
import { api } from '../../../../../../api'
import Table from '../../Table'
import { checkRole } from '../../../../../../helpers/helpers'
import { Back } from '../Tracking/style'
import AutoAssignmentsInfoModal from '../LoadingModal'


const assignmentTypes = [
    {
        id: 'report_source_jurisdiction',
        name: 'Jurisdictions',
    },
    {
        id: 'report_type',
        name: 'Type',
    },
    {
        id: 'jurisdiction_and_report_type',
        name: 'Jurisdictions and Types',
    },
]



const ReportTab = observer(({ setShowActivity }) => {
    const store = useStore()
    const history = useHistory()
    const [assignType, setAssignType] = useState('')
    const [memberId, setMemberId] = useState('')
    const [btnLoading, setBtnLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [data, setData] = useState([])

    const { handleSubmit, errors, control, reset, setError } = useForm()

    const members = store?.members
        .filter((item) => {
            return (
                item.is_active &&
                item.have_access_to_report_source &&
                checkRole([item.report_source_role], ['admin', 'report_owner', 'owner'])
            )
        })
        .map((item) => (
            {
                id: item.id,
                name: item.first_name + ' ' + item.last_name
            }
        ))

    if (!store.user?.[0]?.have_access_to_report_source
        || !store.company?.[0]?.have_access_to_report_source)
        return (
            <ResourceModal
                type='tracking'
                to='/edit-profile'
                onClose={() => history.push('/edit-profile')}
            />
        )

    const onSubmit = (values) => {
        const data = {
            report_source_jurisdictions_auto_assign: values.report_source_jurisdictions_auto_assign,
            report_source_report_types_auto_assign: values.report_source_report_types_auto_assign,
            auto_assign: true
        }
        setBtnLoading(true)
        setVisible(true)
        api(`/members/${memberId}/`, { ...data }, 'PATCH',).then(
            (data) => {
                setBtnLoading(false)
                if (!data.errors) {
                    reset({
                        report_source_jurisdictions_auto_assign: '',
                        report_source_report_types_auto_assign: '',
                    })
                    setMemberId('')
                    api('/companies/auto_assign_members/?filter_for=report_source', {}, 'GET').then((data) => {
                        store.setSingleValue('autoAssignMembers', data)
                        setData(data)
                    })
                    api('/companies/auto_assign_lists/', {}, 'GET').then((data) => {
                        if (!data.errors) {
                            store.setSingleValue('autoAssignLists', data)
                        }
                    })
                } else {
                    getError(data, setError)
                }
            },
        )
    }

    const onAssignmentTypeChange = (value) => {
        api(`/companies/auto_assign_type/`, { auto_assign_by: value }, 'PATCH').then(
            (data) => {
                if (!data.errors) {
                    store.setSingleValue('autoAssignType', data)
                } else {
                    getError(data, setError)
                }
            },
        )
    }


    useEffect(() => {
        setAssignType(store.autoAssignType.report_source_auto_assign_type)
    }, [])

    useEffect(() => {
        api('/companies/auto_assign_members/?filter_for=report_source', {}, 'GET').then((data) => {
            store.setSingleValue('autoAssignMembers', data)
            setData(data)
        })
    }, [])

    return (
        <>
            {visible && <AutoAssignmentsInfoModal onClose={() => setVisible(false)} />}
            <Title style={{ fontSize: 14, marginBottom: 15, fontFamily: 'var(--regular)' }}>
                Select the type of data, then user, to set up an auto assignment. <br />
                The user will automatically be assigned as the Report Owner of the selected items.
                <Back onClick={() => setShowActivity(true)}>View Auto Assignment Activity</Back>
            </Title>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ width: 270 }}>
                    <Input
                        defaultValue={store?.autoAssignType?.report_source_auto_assign_type}
                        control={control}
                        name='auto_assign_by'
                        type='select'
                        placeholder='Select Auto Assignment Type'
                        options={assignmentTypes}
                        styleContainer={{ marginRight: 15, width: 250 }}
                        handleChange={(value) => {
                            setAssignType(value)
                            onAssignmentTypeChange(value)
                        }}
                        smallLabel
                        getPopupContainer={true}
                        disabled={store?.autoAssignMembers.length > 0}
                    />
                </div>
                <div style={{ fontSize: 16, fontFamily: 'var(--regular)', color: '#515151' }}>
                    Selecting an Assignment Type applies to all users.
                </div>
            </div>
            <div style={{ display: 'flex', marginTop: 20 }}>

                <Input
                    control={control}
                    name='member'
                    type='select'
                    placeholder='Select a User'
                    options={members}
                    style={{ height: 30 }}
                    styleContainer={{ marginRight: 15 }}
                    handleChange={(value) => setMemberId(value)}
                    smallLabel
                    getPopupContainer={true}
                    disabled={!assignType}
                />
                <Input
                    selectAll={true}
                    control={control}
                    name='report_source_jurisdictions_auto_assign'
                    type='multiSelect'
                    placeholder='Select Jurisdiction'
                    options={store?.autoAssignLists?.report_source_jurisdictions.map((item) => {
                        return {
                            ...item,
                            disabled: item.is_taken,
                            name: item.name + (item.is_taken ? ' (taken)' : '')
                        }
                    })}
                    styleContainer={{ marginRight: 15 }}
                    style={{ width: 180 }}
                    smallLabel
                    showSearch
                    getPopupContainer={true}
                    disabled={assignType === 'report_type' || !assignType || !memberId}
                />
                <Input
                    control={control}
                    selectAll={true}
                    name='report_source_report_types_auto_assign'
                    type='multiSelect'
                    placeholder='Select Type'
                    options={store?.autoAssignLists?.report_source_report_types.map((item) => {
                        return {
                            ...item,
                            disabled: item.is_taken,
                            name: item.name + (item.is_taken ? ' (taken)' : '')
                        }
                    })}
                    styleContainer={{ marginRight: 15 }}
                    style={{ minWidth: 180 }}
                    smallLabel
                    showSearch
                    getPopupContainer={true}
                    disabled={assignType === 'report_source_jurisdiction' || !assignType || !memberId}
                />
                <Button
                    text={'Add Auto Assignment'}
                    onClick={handleSubmit(onSubmit)}
                    type={'main'}
                    loading={btnLoading}
                    disabled={!assignType || !memberId}
                />
            </div>
            <Table data={data} setData={(value) => setData(value)} reset={(value) => { reset(value) }} serviceName='report_source' />
        </>
    )
})

export default ReportTab
