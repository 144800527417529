import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Wrap = styled('div')`
  width: 100%;
  // padding: 15px 0 15px 20px;
  height: 540px;
  top: 100px;
`

export const Title = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: var(--regular);
  color: var(--grey);
  font-size: 14px;
  letter-spacing: -1.25px;
  margin-bottom: 0.5em;

  img {
    margin-right: 5px;
  }
`

export const Item = styled(NavLink)`
  padding-left: 10px;
  font-size:${(props) => (props.secondary ? '12px' : '16px')};
  // height: 2.188em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color:${(props) => (props.secondary ? ' var(--blue)' : ' var(--text)')};
  text-decoration: ${(props) => (props.secondary ? 'underline' : 'none')};
  cursor: pointer;
  font-family: var(--regular);
  border-radius: 6px 0 0 6px;
  white-space: nowrap;

  span {
    margin-right: 5px;
  }

  &:hover {
    font-family: var(--semiBold);
    color: ${(props) => (props.secondary ? ' var(--blue)' : ' var(--text)')};
  }

  &[disabled] {
    color: #fff;
    opacity: 0.5;
  }
`
