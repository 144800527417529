import PropTypes from 'prop-types'
import React from 'react'
import { Button, Wrap } from './buttons.style'

const buttons = [
  {
    key: 1,
    title: 'Billed Monthly',
    active: 'month',
  },
  {
    key: 0,
    title: 'Billed Annually',
    active: 'year',
  },
]

const BillPeriodButtons = ({
  setActiveButton,
  styleButton,
  styleContainer,
  activeColor,
  activeTextColor,
  paymentPeriod,
}) => (
  <Wrap style={styleContainer}>
    {buttons.map((item) => (
      <Button
        key={item.key}
        active={paymentPeriod === item.active}
        onClick={() => setActiveButton(item.active)}
        style={styleButton}
        activeTextColor={activeTextColor}
        activeColor={activeColor}
      >
        {item.title}
        {item.active === 'year' && (
          <span
            style={{
              marginTop: 0,
              lineHeight: '10px',
              marginBottom: 5,
              fontSize: 12,
            }}
          >
            Save 10% by paying annually
          </span>
        )}
      </Button>
    ))}
  </Wrap>
)

BillPeriodButtons.propTypes = {
  active: PropTypes.string,
  setActiveButton: PropTypes.func,
  styleButton: PropTypes.object,
  styleContainer: PropTypes.object,
  activeColor: PropTypes.string,
  paymentPeriod: PropTypes.string,
}

export default BillPeriodButtons
