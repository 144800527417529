import React, { useState } from 'react'
import Footer from '../Compliance/Footer'
import Header from './Header'
import Team from './Team'
import CookieNotification from '../../../components/common/CookieNotification/CookieNotification'

const AboutUs = () => {

  return (
    <div>
      <Header />
      <Team />
      <CookieNotification/>
      <Footer />
    </div>
  )
}

export default AboutUs
