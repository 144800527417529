import React, { useState, useEffect, } from 'react'
import { Wrap, Report, ShowMore } from './style'
import { useHistory } from 'react-router-dom'
import { Dropdown, Menu } from 'antd'
import { useStore } from '../../../../mobx-store/context'
import { is } from 'immutable'
const DataList = ({ listData, setActive, setLsChapter, getCurrentLegislative, isLicenseSource }) => {

  const history = useHistory()
  const store = useStore()

  const openNewPage = (url) => {
    window.open(url, '_blank')
  }

  const onClick = (e, item) => {
    e.stopPropagation()
    isLicenseSource && item.type === 'report' &&  localStorage.setItem('reportInLs', true)
    isLicenseSource && item.type === 'report'
      ? localStorage.setItem('noResetForReportInLs', true)
      : localStorage.setItem('noResetForReportInLs', false)
    getCurrentLegislative(item.id)
    if (isLicenseSource) {
      // setLsChapter('renewals_reporting')
      // store.setSingleValue('legislativesStatus', 'all_renewals')
      // setActive('all_renewals')
    } else {
      setActive('all')
    }
    isLicenseSource ? openNewPage(`/license-source/all_renewals/?license_item=${item.id}`) :
    history.push(`/report-source/all/?report=${item.id}`)
  }

  const menu = (
    <Menu>
      {listData.map((item) => (
        <Menu.Item>
          <Report
            type={item.type}
            isLicenseSource={isLicenseSource}
            onClick={(e) => onClick(e, item)}
          >
            {isLicenseSource && item.license_title ? item.license_title : item.report_title}{(item.is_new_report || item.is_new_requirement) && '-'} {item.is_new_report && <span>New Report</span>}
            {item.is_new_report && item.is_new_requirement && ','} {item.is_new_requirement && <span style={{ color: '#DF7411' }}>New Requirement(s)</span>}
          </Report>
        </Menu.Item>
      ))}
    </Menu>
  )


  return (
    <>
      <Wrap>
        {listData.slice(0, 3).map((item) => (
          <Report type={item.type} isLicenseSource={isLicenseSource} onClick={(e) => onClick(e, item)}>
            {isLicenseSource && item.license_title ? item.license_title : item.report_title}{(item.is_new_report || item.is_new_requirement) && '-'} {item.is_new_report && <span>New Report</span>}
            {item.is_new_report && item.is_new_requirement && ','} {item.is_new_requirement && <span style={{ color: '#DF7411' }}>New Requirement(s)</span>}
          </Report>
        ))}
      </Wrap>
      {listData.length > 3
        && <Dropdown overlay={menu}
          placement="top"
          trigger={["click", "hover"]}
        >
          <ShowMore onClick={(e) => e.stopPropagation()}>
            + {listData.length - 3} More
          </ShowMore>
        </Dropdown>
      }
    </>
  )

}

export default DataList