import styled from "styled-components";

export const Wrap = styled("div")`
  width: 290px;
  border: 1px solid var(--green);
  margin-left: 15px;
`;

export const Top = styled("div")`
  text-align: center;
  font-family: var(--semiBold);
  font-size: 16px;
  color: var(--green);
  padding: 5px 0;
  span {
    font-family: var(--regular);
  }
`;

export const Bottom = styled("div")`
  background-color: var(--green);
  text-align: center;
  color: #fff;
  font-family: var(--medium);
  font-size: 12px;
  padding: 5px 0;
`;
