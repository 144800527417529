import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useStore } from "../../../../../mobx-store/context";
import moment from 'moment'
import { observer } from "mobx-react-lite";
import { StyledSwitch } from "../../../CreateCompany/pages/FirstPage/style";
import { SwitchTitle, SwitchText, Row, DetailsWrap, AllCheckboxContainer, TableTitle } from "../../../CreateCompany/pages/ManageProducts/style";
import { useForm } from "react-hook-form";
import Input from "../../../../../components/common/Input";
import { Divider, Checkbox, Select } from "antd";
import Button from "../../../../../components/common/Button/button";
import { PrevBtn } from "../../../CreateCompany/pages/FirstPage/Form/style";
import CheckboxGroup from '../../../CreateCompany/pages/ManageProducts/CheckboxGroup'
import { api } from "../../../../../api";
import { TextContainer, Text } from "../../style";

const ReportTab = observer(({ handleTabChange }) => {
  const history = useHistory()
  const store = useStore()
  const { handleSubmit, control, getValues, watch, errors } = useForm()

  const [hasAccesstoRS, setHasAccesstoRS] = useState(store.editedCmsUser.have_access_to_report_source || false)
  const [allJurisdictions, setAllJurisdictions] = useState(store.editedCmsUser.all_report_source_jurisdictions || false)
  const [allTypes, setAllTypes] = useState(store.editedCmsUser.all_report_source_report_types || false)
  const [allLinesOfBusiness, setAllLinesOfBusiness] = useState(store.editedCmsUser.all_report_source_line_of_business || false)
  const [jursCount, setJursCount] = useState(store.editedCmsUser.report_source_jurisdictions?.length || 0)
  const [topicsCount, setTopicsCount] = useState(store.editedCmsUser.report_source_report_types?.length || 0)
  const [linesOfBusinessCount, setLinesOfBusinessCount] = useState(store.editedCmsUser.report_source_line_of_buisness?.length || 0)

  const watchValues = watch();

  const updateCompany = (values) => {
    const data = {
      ...values,
      have_access_to_report_source: hasAccesstoRS,
      all_report_source_jurisdictions: allJurisdictions,
      all_report_source_report_types: allTypes,
      all_report_source_line_of_business: allLinesOfBusiness,
      report_source_role: values.report_source_role,
    }
    api(`/cms/users/${store.editedCmsUser.id}/`, data, 'PATCH').then((res) => {
      if (!res.errors)
        store.setSingleValue('editedCmsUser', res)
    })
  }

  const rolesMap = [
    {
      name: 'Admin',
      value: 'admin'
    },
    {
      name: 'ReportSource Team Member',
      value: 'member'
    },
    {
      name: 'ReportSource Owner',
      value: 'report_owner'
    },
  ]

  const onSubmit = (values) => {
    updateCompany(values)
    handleTabChange('users')
  }

  useEffect(() => {
    setJursCount((getValues('report_source_jurisdictions') && getValues('report_source_jurisdictions').length) || 0)
    setTopicsCount((getValues('report_source_report_types') && getValues('report_source_report_types').length) || 0)
    setLinesOfBusinessCount((getValues('report_source_line_of_business') && getValues('report_source_line_of_business').length) || 0)
  }, [watchValues]);

  useEffect(() => {
    updateCompany(getValues())
  }, [hasAccesstoRS,
    allJurisdictions,
    allTypes,
    allLinesOfBusiness,
    watch('report_source_role'),
    watch('report_source_jurisdictions'),
    watch('report_source_report_types'),
    watch('report_source_line_of_business'),
    watch('report_source_role')])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <form style={{ width: 710 }} onSubmit={handleSubmit(onSubmit)}>
      <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: 25, marginBottom: 20, flexDirection: 'column' }}>
        <SwitchTitle>Has Access to ReportSource</SwitchTitle>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 10, }}>
          <StyledSwitch
            defaultChecked={store.editedCmsUser.have_access_to_report_source}
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={(checked) => { setHasAccesstoRS(checked) }}
          />
          <TextContainer style={{ marginLeft: 10 }}>
            <Text>{store.editedCmsUser.access_to_report_source_granted_date}</Text>
          </TextContainer>
        </div>
      </div>
      <Row>
        <Input
          smallLabel
          type={'select'}
          customOptions={
            rolesMap.map((item) => (
              <Select.Option value={item.value}>
                {item.name}
              </Select.Option>
            ))
          }
          name="report_source_role"
          label="User Role for ReportSource"
          defaultValue={store.editedCmsUser.report_source_role}
          control={control}
          errors={errors}
        />
      </Row>
      <Divider style={{ background: '#CCC' }} />
      <SwitchTitle>Select Jurisdictions, Report Types or Lines of Business for this user. <br />Choices will be limited to what the company has access to:</SwitchTitle>
      <DetailsWrap>
        <TableTitle>Jurisdictions ({allJurisdictions ? 'All' : jursCount})</TableTitle>
        <TableTitle>Report Types ({allTypes ? 'All' : topicsCount})</TableTitle>
        <TableTitle>Lines of Business ({allLinesOfBusiness ? 'All' : linesOfBusinessCount})</TableTitle>
      </DetailsWrap>
      <DetailsWrap>
        <AllCheckboxContainer>
          <div>All Jurisdictions</div>
          <Checkbox
            checked={allJurisdictions}
            onChange={(e) => setAllJurisdictions(e.target.checked)}
            name={'all_jurisdictions'}
            style={{ marginLeft: 0, marginRight: 16 }}
          />
        </AllCheckboxContainer>
        <AllCheckboxContainer>
          <div>All Types</div>
          <Checkbox
            checked={allTypes}
            onChange={(e) => setAllTypes(e.target.checked)}
            name={'all_topics'}
            style={{ marginLeft: 0, marginRight: 15 }} />
        </AllCheckboxContainer>
        <AllCheckboxContainer>
          <div> All Lines of Business</div>
          <Checkbox
            checked={allLinesOfBusiness}
            onChange={(e) => setAllLinesOfBusiness(e.target.checked)}
            name={'all_line_of_business'}
            style={{ marginLeft: 0, marginRight: 9 }} />
        </AllCheckboxContainer>
      </DetailsWrap>
      {(!allJurisdictions || !allTypes || !allLinesOfBusiness) &&
        <DetailsWrap >
          <CheckboxGroup
            defaultValue={store.editedCmsUser.report_source_jurisdictions}
            control={control}
            name={'report_source_jurisdictions'}
            options={store?.cms_company_options.report_source_jurisdictions}
            disabled={allJurisdictions} />
          <CheckboxGroup
            defaultValue={store.editedCmsUser.report_source_report_types}
            control={control}
            name={'report_source_report_types'}
            options={store?.cms_company_options.report_source_report_types}
            disabled={allTypes} />
          <CheckboxGroup
            defaultValue={store.editedCmsUser.report_source_line_of_business}
            control={control}
            name={'report_source_line_of_business'}
            options={store?.cms_company_options.report_source_line_of_business}
            disabled={allLinesOfBusiness} />
        </DetailsWrap>
      }
    </form>
  )
});

export default ReportTab;