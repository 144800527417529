import React from 'react'
import { useParams } from 'react-router-dom'
import Button from '../../../../components/common/Button/button'
import { Label } from '../../../../components/common/Input/input.style'
import Modal from '../../../../components/common/ModalNew/index'
import { Cancel } from '../../../Tracking/Right/Tasks/Modal/style'

const RemoveModal = ({ name, onRemove, onClose }) => {
  const params = useParams()
  return (
    <Modal onClose={onClose}>
      <Label>Removing a {types[params.type]}</Label>
      <Label style={{ fontFamily: 'var(--regular)', fontSize: 16 }}>
        “{name}” will no longer show up as an option when creating a new
        Compliance Item. Any existing Compliance Items with this{' '}
        {types[params.type]?.toLowerCase()} will not be removed and will still
        be searchable by this name.
      </Label>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Cancel onClick={onClose}>Cancel</Cancel>
        <Button text='Remove' onClick={onRemove} />
      </div>
    </Modal>
  )
}

export default RemoveModal

const types = {
  jurisdiction: 'Jurisdiction',
  topics: 'Topic',
  lines_of_business: 'Line of Business',
}
