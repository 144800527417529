import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { notification } from 'antd';
import { Wrap, Text, Buttons, MessageText, DescriptionText } from './CookieNotification.style'
import Button from '../Button/button'

const CookieNotification = () => {
  const cookiesConfirmed = !localStorage.getItem('cookiesConfirmed');
  const saveToStorage = () => localStorage.setItem('cookiesConfirmed', true);
  const key = `open${Date.now()}`;

  const [visibleCookiePopup, setVisibleCookiePopup] = useState(cookiesConfirmed);

  const openNotification = () => {
    notification.open({
      message: <MessageText>Risk of limited functionality.</MessageText>,
      description: <DescriptionText>
        Staying logged in and application functionality may be hindered as cookies enable essential functionality and personalization.
      </DescriptionText>,
      className: 'custom-class',
      style: {
        width: 508,
        height: 240,
        borderRadius: 8,
      },
      placement: 'bottom',
      btn,
      key,
      duration: 0,
      closeIcon: (<></>),
    });
  };


  const onAccept = () => {
    setVisibleCookiePopup(false);
    saveToStorage();
  }

  const onDeny = () => {
    setVisibleCookiePopup(false);
    openNotification();
  }

  const btn = (
    <Buttons style={{ marginTop: 30, marginRight: 3  }}>
      <Button
        onClick={() => notification.close(key)}
        text={'Deny'}
        type={'grey'}
        style={{ marginRight: '1em', width: 116, height: 44 }}
      />
      <Button
        onClick={() => {
          notification.close(key)
          saveToStorage()
        }}
        text={'Accept'}
        type={'main'}
        style={{ marginRight: '1em', width: 116, height: 44 }}
      />
    </Buttons>
  );


  return (
    <>
      {visibleCookiePopup &&
        <Wrap>
          <Text>
            This website stores data such as cookies to enable essential site functionality, as well as marketing, personalization, and analytics. <Link to='/terms-and-privacy'>Cookie Policy</Link>
          </Text>
          <Buttons>
            <Button
              onClick={onAccept}
              text={'Accept'}
              type={'main'}
              style={{ marginRight: '1em', width: 116, height: 44 }}
            />
            <Button
              onClick={onDeny}
              text={'Deny'}
              type={'grey'}
              style={{ marginRight: '1em', width: 116, height: 44 }}
            />
          </Buttons>
        </Wrap>
      }
    </>
  )

}

export default CookieNotification
