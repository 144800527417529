import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Wrap = styled('div')`
  display: grid;
  grid-template-columns: 10px 1fr 50px 20px;
  height: 50px;
  padding: 0 8px;
  align-items: center;
  gap: 10px;
`

export const Status = styled('div')`
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #000;
`

export const Title = styled(Link)`
  font-size: 0.75em;
  font-family: var(--regular);
  text-decoration: underline;
  color: var(--text);
`

export const Date = styled('div')`
  font-size: 10px;
  font-family: var(--light);
  font-style: italic;
  margin-left: 5px;
`

export const Image = styled('img')`
  width: 7px;
  height: 7px;
  margin-left: auto;
  cursor: pointer;
`
