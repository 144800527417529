import styled from 'styled-components'
import { TableStyled } from '../../../../../../components/common/style'

export const TableS = styled(TableStyled)`
  table tr:nth-child(odd) td {
    background-color: #f4f6fa;
  }
`;

export const Footer = styled("div")`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 10px;
`;
