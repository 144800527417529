import React, { useState } from 'react'
import { Circle, Wrap } from './switch.style'

const Switch = ({ onChange, style, checked }) => {
  const [active, setActive] = useState(false);

  return (
    <Wrap
      active={checked || active}
      onClick={() => {
        setActive(!active);
        onChange(!active);
      }}
      style={style}
    >
      <Circle active={checked || active}>
        {checked || active ? "Yes" : "No"}
        {}
      </Circle>
    </Wrap>
  );
};

export default Switch;
