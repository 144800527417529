import styled from 'styled-components'
import { InputWrap } from '../../../../../components/common/Input/input.style'

export const Wrap = styled('form')`
  margin-top: 1em;
  ${InputWrap} {
    margin-bottom: 1em;
  }
  .ant-list-header {
    font-family: var(--open-sans-semiBold);
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
  }
  .ant-list-bordered {
    border: 0;
  }
  .ant-spin-container {
    border: 1px solid #eaeaea;
    border-top: 0;
  }
  .ant-list-item {
    font-family: var(--open-sans-reg);
  }
`

export const AllSelected = styled('div')`
  width: 100%;
  height: 3.313em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: var(--bold);
  background: rgba(242, 161, 34, 0.8);
  border: 2px solid #f2a122;
  margin-bottom: 1em;
`

export const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 27px;
`

export const GridTop = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 0.7fr;
  gap: 27px;
`

export const GridBot = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 27px;
`

export const UnderInput = styled('div')`
  color: #000;
  font-family: var(--open-sans-reg);
  font-size: 0.875em;
  padding-left: 1em;
`

export const ButtonsWrap = styled('div')`
  display: flex;
`
