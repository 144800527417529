import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Wrap = styled('div')`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0px 0 15px 0;
  align-items: flex-end;
`

export const Button = styled(Link)`
  height: ${({ active }) => (active ? '35px' : '32px')};
  box-shadow: 0px -2px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px 4px 0px 0px;
  background-color: ${({ active }) => (active ? '#fff' : '#F3F3F3')};
  font-size: ${({ active }) => (active ? '14px' : '12px')};
  padding: 0 30px;
  display: flex;
  align-items: center;
  color: ${({ active }) => (active ? 'var(--text)' : 'rgba(69, 81, 84, 0.6)')};
  font-family: ${({ active }) => (active ? 'var(--medium)' : 'var(--regular)')};
  cursor: pointer;
  &:hover {
    color: var(--text);
  }
`
