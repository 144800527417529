
import Modal from "../../../../components/common/ModalNew"
import { Text, Title,  } from "../RemoveuserModal/style";
import Button from "../../../../components/common/Button/button";



const EmailModal = ({ onClose }) => {

  return (
    <Modal
      styleWrap={{ width: 'auto', height: 'auto', padding: 30, maxWidth: 580 }}
      onClose={onClose}>
      <Title>
        User Added Successfully
      </Title>
      <Text>
        Credentials have been sent to the user's email
      </Text>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1.5em' }}>
        <Button
          text={`Close`}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onClose()
          }}
          type={'main'}
          style={{
            padding: 10,
            marginRight: 0,
          }}
        />
      </div>
    </Modal>
  )
}

export default EmailModal