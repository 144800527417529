import React from 'react'
import {
  Render,
  TableHeaderTitle,
} from '../../../../../Tracking/Table/table.style'
import Moment from 'react-moment'
import ButtonOrange from '../../../../../../components/common/ButtonOrange'
import { Footer, TableS } from './style'
import CustomPagination
  from '../../../../../../components/common/Pagination/pagination'
import PageSizeChanger
  from '../../../../../../components/common/PageSizeChanger'
import ItemsShowing from '../../../../../../components/common/TableItemsShowing'

const Table = ({
  data,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  loading,
  handleFetch,
  settingsActivity,
}) => {
  const columns = [
    {
      title: <TableHeaderTitle>Date and Time</TableHeaderTitle>,
      dataIndex: "created",
      key: "created",
      width: "15%",
      sorter: true,
      render: (text) => (
        <Render>
          <Moment format={"D/MM/YY HH:mm"}>{text}</Moment>
        </Render>
      ),
    },
    {
      title: <TableHeaderTitle>Reference #</TableHeaderTitle>,
      dataIndex: "reference_number",
      key: "reference_number",
      sorter: true,
      width: "15%",
      render: (text) => (
        <Render style={{ fontFamily: "var(--semiBold)" }}>{text}</Render>
      ),
    },
    {
      dataIndex: "legislative",
      key: "legislative",
      width: 0,
      render: () => null,
    },
    {
      title: <TableHeaderTitle>Action by User</TableHeaderTitle>,
      dataIndex: "html_text",
      key: "html_text",
      width: "60%",
      sorter: true,
      render: (text) => <div dangerouslySetInnerHTML={{ __html: text }} />,
    },
    {
      title: <TableHeaderTitle>View</TableHeaderTitle>,
      dataIndex: "",
      key: "",
      render: (text, record) => (
        <ButtonOrange
          text={"View this Activity"}
          link={`/tracking/${record.legislative}`}
        />
      ),
    },
  ];

  const settingsActivityColumns = [
    {
      title: <TableHeaderTitle>Date and Time</TableHeaderTitle>,
      dataIndex: "created",
      key: "created",
      width: "15%",
      sorter: true,
      render: (text) => (
        <Render>
          <Moment format={"D/MM/YY HH:mm"}>{text}</Moment>
        </Render>
      ),
    },

    {
      dataIndex: "legislative",
      key: "legislative",
      width: 0,
      render: () => null,
    },
    {
      title: <TableHeaderTitle>Action</TableHeaderTitle>,
      dataIndex: "html_text",
      key: "html_text",
      width: "60%",
      sorter: true,
      render: (text) => <div dangerouslySetInnerHTML={{ __html: text }} />,
    },
  ];

  return (
    <>
      <TableS
        columns={settingsActivity ? settingsActivityColumns : columns}
        dataSource={data?.results}
        rowKey={"id"}
        scroll={{ y: 450 }}
        loading={loading}
        onChange={(pagination, b, sorter) => {
          if (sorter.column) {
            handleFetch(
              (sorter.order === "ascend" ? "" : "-") + sorter.column.dataIndex
            );
          } else {
            handleFetch(null);
          }
        }}
        size="small"
        pagination={false}
      />
      <Footer>
        <CustomPagination
          total={data?.count}
          pageSize={pageSize}
          style={{ marginRight: 10 }}
          onChange={(current) => {
            onPageChange(current);
          }}
          current={page}
        />
        {data?.count > 11 && (
          <PageSizeChanger
            onChange={(value) => onPageSizeChange(+value)}
            pageSize={pageSize}
            total={data?.count}
          />
        )}

        <ItemsShowing page={page} pageSize={pageSize} total={data?.count} />
      </Footer>
    </>
  );
};

export default Table;
