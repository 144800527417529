import React from 'react'
import { Wrap } from './style'

const RoundButton = ({ checked, onClick, disabled }) => {
  return (
    <Wrap
      onClick={!disabled ? onClick : undefined}
      checked={checked}
      disabled={disabled}
    >
      {checked && <span />}
    </Wrap>
  );
};

export default RoundButton;
