import styled from 'styled-components'

export const Container = styled("div")`
  width: 100%;
  min-height: ${(props) => (props.modalOpen ? '300px' : '915px')};
  height: ${(props) => (props.modalOpen ? '300px' : '100%')};
  overflow: ${(props) => (props.modalOpen ? 'hidden' : 'none')};
  display: flex;
  padding-top: 150px;
  padding-bottom: 200px;
  justify-content: center;
`;

export const Grid = styled("div")`
  display: grid;
  grid-template-columns: 220px 1fr;
  width: 1000px;
`;
