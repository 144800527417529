import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Disclaimer from '../../../components/common/Disclaimer'
import TopLine from '../../../components/TopLine/topLine'
import { useStore } from '../../../mobx-store/context'
import Header from './Header'
import { Text } from '../components/style'
import Filters from '../Resource/Filters'
import { Wrap } from '../Resource/style'
import { Container } from './style'
import Table from './Table'
import ChangePasswordModal from '../../Auth/ChangePassowordModal'
import TCModal from '../../Tracking/Modal/tcModal'

const ResourceSearch = observer(() => {
  const store = useStore()
  const history = useHistory()
  const [expiredPassword, setExpiredPassword] = useState(false)
  const [showDisclaimer, setShowDisclaimer] = useState({ show: false, fadeOut: false });


  useEffect(() => {
    setExpiredPassword(localStorage.getItem('is_time_to_change_password') === 'true')
  }, [])

  useEffect(() => {
    const show = sessionStorage.getItem('showDisclaimer') === 'true';
    setShowDisclaimer({ show: show, fadeOut: !show });
  }, []);

  const handleDisclaimerClose = () => {
    setShowDisclaimer(prev => ({ ...prev, fadeOut: true }));
    setTimeout(() => {
      setShowDisclaimer({ show: false, fadeOut: false });
      sessionStorage.setItem('showDisclaimer', 'false');
    }, 500);
  }

  useEffect(() => {
    if (!store.resourceTariffInfo) store.getResourceTariffInfo()
  }, [])

  useEffect(() => {
    if (!store.company?.[0].have_access_to_resource)
      history.push('/edit-profile')
  }, [store.resourceTariffInfo])

  useEffect(() => {
    if (!store.jurs?.length) store.loadSelect('companyOptions')
    if (!store.resources?.length) store.getResourceTopic()
    if (!store.resourceFilters) store.getResourceFilters()
  }, [])



  return (
    <>
      <TopLine />
      <Wrap>
        <Container>
          {
            !store.user[0].is_accepted_terms_and_conditions &&
            <TCModal />
          }
          {store.user[0].should_see_pbm_updates && <TCModal type={'announcement'} />}
          {expiredPassword && <ChangePasswordModal onClose={() => setExpiredPassword(false)} expiredPassword />}
          <div style={{ position: 'sticky', top: 90, zIndex: 98, background: '#FFF' }}>
            <Header />
          </div>
          < Table />
        </Container>
      </Wrap>
      {showDisclaimer.show && <Disclaimer onClose={handleDisclaimerClose} fadeOut={showDisclaimer.fadeOut} />}
    </>
  )
})

export default ResourceSearch
