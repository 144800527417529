import styled from 'styled-components'

export const Wrap = styled('div')`
  display: flex;
  align-items: center;
  max-width: 600px;
  img {
    height: 200px;
  }
`

export const Description = styled('div')`
  font-family: var(--regular);
  font-size: 16px;
  color: var(--text);
  a {
    color: #70a3ec;
    font-family: var(--medium);
    text-decoration: underline;
  }
`

export const Button = styled('div')`
  font-family: var(--medium);
  font-size: 16px;
  color: #70a3ec;
  text-decoration: underline;
  margin-top: 10px;
  display: flex;
  cursor: pointer;
`
