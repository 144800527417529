import { message } from 'antd'
import React from 'react'
import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'
import { api } from '../../../api'
import img from '../../../assets/images/reset-password.svg'
import Logo from '../../../components/common/Logo/logo'
import {
  BackButton,
  Container,
  Description,
  Wrap,
} from '../Agreement/agreement.style'
import { Title } from '../authForm.style'
import { Image, MiniTitle } from './resetPass.style'

const Step2 = () => {
  const [cookies] = useCookies()

  const sendAgain = () => {
    api(
      '/password_reset/',
      { email: sessionStorage.getItem('email') },
      'POST',
      true
    ).then((data) => {
      if (!data.errors) {
        message.success('Email sent')
      }
    })
  }

  return (
    <Container>
      <Wrap disableScroll>
        <Logo large />
        <Title
          style={{
            borderBottom: '1px solid rgba(196, 202, 204, 0.6)',
            paddingBottom: '.7em',
          }}
        >
          Reset Your Password <span>Secure Email Link</span>
        </Title>
        <Image src={img} alat={'email-image'} />
        <MiniTitle>Check your email.</MiniTitle>
        <Description
          style={{ fontFamily: 'var(--regular)', fontSize: '.875em' }}
        >
          We’ve sent a secure password reset link to your email address. Please
          enter it below to reset your password.{' '}
          <span style={{ fontFamily: 'var(--medium)' }}>
            Didn’t get the message?
          </span>{' '}
          <span
            style={{
              color: 'var(--orange)',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={sendAgain}
          >
            Send it again.
          </span>{' '}
          {/*OR{" "}*/}
          {/*<span style={{ color: "var(--orange)", textDecoration: "underline" }}>*/}
          {/*  Try a different recovery method.*/}
          {/*</span>*/}
        </Description>
        <BackButton
          to={cookies.pbm ? '/password-and-security' : '/login'}
          as={Link}
        >
          &lt;&lt; {cookies.pbm ? 'Return to Settings' : 'Return to Sign In'}
        </BackButton>
      </Wrap>
    </Container>
  )
}

export default Step2
