import React, { useState, useEffect } from "react";
import Modal from "../../../../../../components/common/ModalNew";
import { useForm } from "react-hook-form";
import { useStore } from "../../../../../../mobx-store/context";
import Button from "../../../../../../components/common/Button/button";
import { SwitchTitle, Row, DetailsWrap, TableTitle } from "../../../../CreateCompany/pages/ManageProducts/style";
import Input from "../../../../../../components/common/Input";
import { api } from "../../../../../../api";
import CheckboxGroup from '../../../../CreateCompany/pages/ManageProducts/CheckboxGroup'
import { useParams } from "react-router-dom/";
import moment from 'moment'
import EndDateConfirmModal from "../../../../EditCompany/Tabs/Resource/GuideGroup/EndDateConfirmModal";



const EditGuideGroupModal = ({ group, onClose, id, edit = false }) => {
  const store = useStore()
  const params = useParams()
  const [chosenGuides, setChosenGuides] = useState([])
  const [loading, setLoading] = useState(edit ? true : false)
  const [endDateModalVisible, setEndDateModalVisible] = useState(false)
  const [tempEndDate, setTempEndDate] = useState(null)
  const { control, getValues, setValue } = useForm({
  })

  const getGuidesInfo = () => {
    setLoading(true)
    api(`/cms/companies/${params.id}/guides/`, {}, 'GET').then((res) => {
      if (!res.errors) {
        const filteredObjects = res.guides.filter(obj => obj.is_taken === true);
        const ids = filteredObjects.map(obj => obj.id);
        setChosenGuides(ids)
        setLoading(false)
      }
    })
  }
  function excludeArrayValues(array1, array2) {
    return array1.filter(item => !array2.includes(item));
  }

  const onSubmit = (values) => {
    const url = edit ? `/cms/resource_portions/${id}/` : `/cms/companies/${params.id}/add_guides/`
    const data = !edit
      ? {
        guides: excludeArrayValues(values.guides, chosenGuides),
        start_date: values.resource_start_date ? moment(values.resource_start_date).format('YYYY-MM-DD') : null,
        end_date: values.resource_end_date
          ? moment(values.resource_end_date).format('YYYY-MM-DD')
          : values.resource_start_date
            ? moment(values.resource_start_date).add(1, 'years').format('YYYY-MM-DD') : null,
      }
      : { new_guides: values.guides }
    api(url, data, edit ? 'PATCH' : 'POST').then((res) => {
      if (!res.errors) {
        onClose()
        getGuidesInfo()
      }
    })
  }

  useEffect(() => {
    getGuidesInfo()
  }, [])


  return (
    <Modal
      styleWrap={{
        width: 600,
        height: 'fit-content',
        borderRadius: 8,
        padding: 30,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}
      onClose={onClose}
    >
      {endDateModalVisible && <EndDateConfirmModal onClose={() => setEndDateModalVisible(false)} setValue={() => setValue('resource_end_date', tempEndDate)} />}
      <SwitchTitle style={{ fontSize: 24, fontFamily: 'var(--regular)', marginBottom: 20 }}>
        Select ReSource Guides for this Group
      </SwitchTitle>

      {!loading && <div >
        {!edit &&
          <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Input
              smallLabel
              name={'resource_start_date'}
              label={'Select Start Date'}
              type={'date'}
              control={control}
              validation={{ required: false }}
              showCross={false}
              transparent
              placeholder={'Select Start Date'}
              noLimit
            />
            <Input
              smallLabel
              name={'resource_end_date'}
              label={'Select End Date'}
              type={'date'}
              showCross={true}
              control={control}
              validation={{ required: false }}
              transparent
              placeholder={'Select End Date'}
              noLimit
              handleChange={(e) => {
                setTempEndDate(e)
                setEndDateModalVisible(true)
              }}
              handleReset={() => setValue('resource_end_date', null)}
            />
          </Row >
        }
        <DetailsWrap style={{ gridTemplateColumns: '1fr' }}>
          <TableTitle>
            Guides
          </TableTitle>
        </DetailsWrap>
        <DetailsWrap style={{ gridTemplateColumns: '1fr' }}>
          <CheckboxGroup
            disabledefault={true}
            control={control}
            name={'guides'}
            options={store?.cms_options.guides}
            defaultValue={chosenGuides}
          />
        </DetailsWrap>
        <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            // disabled={!edit && (!getValues('resource_start_date') || !getValues('resource_end_date'))}
            text={edit ? 'Add Guides' : 'Add New Group'}
            onClick={(e) => {
              e.preventDefault()
              onSubmit(getValues())
            }}
            type={'main'}
            style={{
              width: 'auto',
              padding: 10,
              marginRight: 0,
              marginLeft: '.5em',
              marginTop: '2.3em',
            }}
          />
        </Row>
      </div>}
    </Modal>
  )

};

export default EditGuideGroupModal;