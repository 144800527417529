import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useStore } from "../../../../../mobx-store/context";
import moment from 'moment'
import { observer } from "mobx-react-lite";
import { StyledSwitch } from "../../../CreateCompany/pages/FirstPage/style";
import {
  SwitchTitle,
  SwitchText,
  Row,
  AddButton,
  DetailsWrap,
  AllCheckboxContainer,
  TableTitle
} from "../../../CreateCompany/pages/ManageProducts/style";
import { useForm } from "react-hook-form";
import Input from "../../../../../components/common/Input";
import { Divider, Checkbox, Select } from "antd";
import Button from "../../../../../components/common/Button/button";
import { PrevBtn } from "../../../CreateCompany/pages/FirstPage/Form/style";
import { SubTitle } from '../../../CreateCompany/pages/FirstPage/style'
import { api } from "../../../../../api";
import EditGuideGroupModal from "./Modal";
import CheckboxGroup from '../../../CreateCompany/pages/ManageProducts/CheckboxGroup'
import { TextContainer, Text } from "../../style";

const ResourceTab = observer(({ handleTabChange, companyId }) => {
  const history = useHistory()
  const store = useStore()
  const { handleSubmit, control, getValues, watch, errors } = useForm({
    defaultValues: {
      resource_start_date: store?.cmsCompany[0]?.resource_start_date || null,
      resource_end_date: store?.cmsCompany[0]?.resource_end_date || null,
      resource_custom_price: store?.cmsCompany[0]?.resource_custom_price || '',
    }
  })

  const [hasAccesstoResource, setHasAccesstoResource] = useState(store?.editedCmsUser?.have_access_to_resource || false)
  const [guidesCount, setGuidesCount] = useState(store?.editedCmsUser?.guides?.length || 0)
  const [jursCount, setJursCount] = useState(store?.editedCmsUser?.resource_jurisdictions?.length || 0)
  const [allJurisdictions, setAllJurisdictions] = useState(store?.editedCmsUser?.all_resource_jurisdictions)
  const [allGuides, setAllGuides] = useState(store?.editedCmsUser?.all_resource_guides)
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)

  const updateCompany = (values) => {
    const data = {
      ...values,
      have_access_to_resource: hasAccesstoResource,
      all_resource_jurisdictions: allJurisdictions,
      all_resource_guides: allGuides,
    }
    api(`/cms/users/${store.editedCmsUser.id}/`, data, 'PATCH').then((res) => {
      if (!res.errors)
        store.setSingleValue('editedCmsUser', res)
    })
  }

  const rolesMap = [
    {
      name: 'Admin',
      value: 'admin'
    },
    {
      name: 'Member',
      value: 'member'
    },
  ]

  const onSubmit = (values) => {
    updateCompany(values)
    handleTabChange('report')
  }


  const watchValues = watch();


  useEffect(() => {
    setGuidesCount((getValues('resource_guides') && getValues('resource_guides').length) || 0)
    setJursCount((getValues('resource_jurisdictions') && getValues('resource_jurisdictions').length) || 0)
  }, [watchValues]);

  useEffect(() => {
    updateCompany(getValues())
  }, [hasAccesstoResource,
    allJurisdictions,
    allGuides,
    watch('resource_role'),
    watch('resource_jurisdictions'),
    watch('resource_guides')])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (

    <form style={{ width: 710 }} onSubmit={handleSubmit(onSubmit)}>

      <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: 25, marginBottom: 20, flexDirection: 'column' }}>
        <SwitchTitle>Has Access to Resource</SwitchTitle>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 10, }}>
          <StyledSwitch
            defaultChecked={store.editedCmsUser.have_access_to_resource}
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={(checked) => { setHasAccesstoResource(checked) }}
          />
          <TextContainer style={{ marginLeft: 10 }}>
            <Text>{store.editedCmsUser.access_to_resource_granted_date}</Text>
          </TextContainer>
        </div>
      </div>
      <Row>
        <Input
          smallLabel
          type={'select'}
          customOptions={
            rolesMap.map((item) => (
              <Select.Option value={item.value}>
                {item.name}
              </Select.Option>
            ))
          }
          name="resource_role"
          label="User Role for ReSource"
          defaultValue={store.editedCmsUser.resource_role}
          control={control}
          errors={errors}
        />
      </Row>

      <Divider style={{ background: '#CCC' }} />
      <SwitchTitle style={{ marginBottom: 10 }}>Select Jurisdictions or Guides for this user. Choices will be limited to what the company has access to:</SwitchTitle>
      <DetailsWrap style={{ gridTemplateColumns: '1fr 1fr' }}>
        <TableTitle> Jurisdictions ({allJurisdictions ? 'All' : jursCount})</TableTitle>
        <TableTitle> Guides ({allGuides ? 'All' : guidesCount})</TableTitle>
      </DetailsWrap>
      <DetailsWrap style={{ gridTemplateColumns: '1fr 1fr' }}>
        <AllCheckboxContainer>
          <div> All Jurisdictions </div>
          <Checkbox
            onChange={(e) => setAllJurisdictions(e.target.checked)}
            name={'all_resource_jurisdictions'}
            style={{ marginLeft: 0, marginRight: 43 }}
            checked={allJurisdictions}
          >
          </Checkbox>
        </AllCheckboxContainer>
        <AllCheckboxContainer>
          <div> All Guides </div>
          <Checkbox
            onChange={(e) => setAllGuides(e.target.checked)}
            name={'all_resource_guides'}
            style={{ marginLeft: 0, marginRight: 5 }}
            checked={allGuides}
          >
          </Checkbox>
        </AllCheckboxContainer>
      </DetailsWrap>
      {(!allJurisdictions || !allGuides) &&
        <DetailsWrap style={{ gridTemplateColumns: '1fr 1fr' }}>
          <CheckboxGroup
            defaultValue={store.editedCmsUser.resource_jurisdictions}
            control={control}
            name={'resource_jurisdictions'}
            disabled={allJurisdictions}
            options={store?.cms_company_options.resource_jurisdictions} />
          <CheckboxGroup
            defaultValue={store.editedCmsUser.resource_guides}
            control={control}
            name={'resource_guides'}
            disabled={allGuides}
            options={store?.cms_company_options.resource_guides} />
        </DetailsWrap>
      }
    </form>
  )
});

export default ResourceTab;