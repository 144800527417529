import React, { useEffect, useState } from 'react';
import { useStore } from '../../../../mobx-store/context';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx'
import {
  TableStyled,
  TableHeaderTitle,
  Wrap,
  PastDueCol,
  MonthHeaderTitle,
} from './style'
import Column from './monthName';

const ReportMonthlyTable = observer(({ isReportSource, isLicenseSource, active }) => {

  const store = useStore();
  const [reportStats, setReportStats] = useState([])

  useEffect(() => {
    store.setSingleValue('filterMode', false)
  }, [])

  useEffect(() => {
    setReportStats(toJS(store.reportStats))
  }, [store.reportStats, store.activeSearchMonth])

  const isRSLight = store.company[0].report_source_mode === "report_source_lite"

  const columns = [
    {
      title: <MonthHeaderTitle>Month</MonthHeaderTitle>,
      dataIndex: 'month_name',
      key: 'month_name',
      width: 350,
      render: (text, record) => <Column
        text={text}
        record={record}
        isReportSource={isReportSource}
        isLicenseSource={isLicenseSource}
        active={active}
        columnType={'month_name'}
      />
    },
    {
      title: <TableHeaderTitle>Total</TableHeaderTitle>,
      dataIndex: 'total',
      key: 'total',
      render: (text, record) => <Column
        text={text}
        record={record}
        isReportSource={isReportSource}
        isLicenseSource={isLicenseSource}
        active={active}
        columnType={'total'}
      />
    },
    {
      title: <TableHeaderTitle>Past Due</TableHeaderTitle>,
      dataIndex: 'past_due',
      key: 'past_due',
      render: (text, record) => <Column
        text={text}
        record={record}
        isReportSource={isReportSource}
        isLicenseSource={isLicenseSource}
        active={active}
        columnType={'past_due'}
      />
    },
    {
      title: <TableHeaderTitle>{isRSLight ? 'Not Completed' : 'Upcoming'}</TableHeaderTitle>,
      dataIndex: 'not_completed',
      key: 'not_completed',
      render: (text, record) => <Column
        text={text}
        record={record}
        isReportSource={isReportSource}
        isLicenseSource={isLicenseSource}
        columnType={'not_completed'}
        active={active}
      />
    },
    {
      title: <TableHeaderTitle>{isRSLight ? 'Archived' : 'Completed'}</TableHeaderTitle>,
      dataIndex: 'completed',
      key: 'completed',
      render: (text, record) => <Column
        text={text}
        record={record}
        isReportSource={isReportSource}
        isLicenseSource={isLicenseSource}
        columnType={'completed'}
        active={active}
      />
    },
  ];

  return (
    <Wrap >
      <TableStyled
        dataSource={reportStats}
        columns={columns}
        pagination={false}
        style={{ padding: 2 }}
      />
    </Wrap>
  )
}
)

export default ReportMonthlyTable