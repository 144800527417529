import React, { useState } from 'react'
import Footer from '../Compliance/Footer'
import Demo from './Demo'
import Header from './Header'
import Tools from './Tools/index'
import CookieNotification from '../../../components/common/CookieNotification/CookieNotification'

const Home = () => {

  return (
    <div>
      <Header />
      <Tools />
      <Demo />
      <CookieNotification/>
      <Footer />
    </div>
  )
}

export default Home
