import styled from 'styled-components'

export const Title = styled('div')`
  font-family: var(--bold);
  font-size: 18px;
  color: var(--text);
  margin-bottom: 10px;
`

export const Text = styled('div')`
  font-family: var(--regular);
  font-size: 16px;
  color: var(--text);
`
