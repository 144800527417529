import React, { useState } from 'react';
import { Button, Menu, Dropdown, Divider } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

const CustomDropdownButton = ({ options, onSelectOption, name, selectedOptions, setSelectedOptions, style, noIcon, disabled = false, setPage = () => { } }) => {


  const toggleOption = (key) => {
    const isSelected = selectedOptions.includes(key);
    if (isSelected) {
      setPage(1)
      setSelectedOptions(selectedOptions.filter((item) => item !== key));
    } else {
      setPage(1)
      setSelectedOptions([...selectedOptions, key]);
    }
  };

  const handleMenuClick = (e) => {
    const optionKey = e.key;
    toggleOption(optionKey);
    onSelectOption(optionKey);
  };

  const menu = (
    <Menu
      style={{ borderRadius: '16px 0px 16px 16px', }}
      onClick={handleMenuClick}>
      {options.map((option) => (
        <React.Fragment key={option.key}>
          <Menu.Item
            style={{ padding: '10px 20px' }}
            key={option.key}
            icon={noIcon ? null : selectedOptions.includes(option.key) ? <CheckCircleOutlined /> : null}
          >
            {option.label}
            <Divider style={{ margin: 0 }} />
          </Menu.Item>
        </React.Fragment>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} disabled={disabled} placement="bottomRight">
      <Button
        type='primary'
        style={{
          ...style,
          borderRadius: 4,
          padding: '4px 8px',
          background: disabled ? 'var(--grey)' : 'var(--blue)',
          border: 'none',
          fontFamily: 'var(--semibold)',
        }}
      >
        {name}
      </Button>
    </Dropdown>
  );
};

export default CustomDropdownButton;
