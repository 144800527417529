import Tabs from "../../components/common/Tabs/tabs";
import { useState } from "react";
import Reporting from './index'
import { Wrap, Title } from './style'

const ProposedLegislationPage = ({ archive }) => {
  const [activeTab, setActiveTab] = useState('actual');

  const showTabs = (obj) => {
    return {
      actual: {
        render: () => <Reporting handleTabChange={obj.handleTabChange} loading={obj.loading} active={obj.activeTab} companyId={obj.id} archive={false} />,
      },
      archived: {
        render: () => <Reporting handleTabChange={obj.handleTabChange} loading={obj.loading} active={obj.activeTab} companyId={obj.id} archive={true} />,
      },
    }
  }
  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <Wrap >
      <Title>ProposedSource</Title>
      <Tabs
        Reporting={false}
        tabs={[
          {
            key: 'actual',
            title: 'Current',
          },
          {
            key: 'archived',
            title: 'Archived Sessions',
          },
        ]}
        onClick={(key) => {
          setActiveTab(key)
        }}
        active={activeTab}
      />

      {showTabs({
        handleTabChange,
        activeTab,
        archive,
      })[activeTab].render()
      }
    </Wrap>
  );
};

export default ProposedLegislationPage;