import React from 'react'
import { useForm } from 'react-hook-form'
import { BGImage, Contact, Row, Wrap } from './style'
import { Container, Text, Title } from '../style'
import Button from '../../../components/common/Button/button'
import { api } from '../../../api'
import { message } from 'antd'
import { getError } from '../../../helpers/helpers'
import logoBg from '../../../assets/images/logo-bg.svg'
import placeImg from '../../../assets/images/place.svg'
import phone from '../../../assets/images/phone.svg'
import Input from '../../../components/common/Input'

const Form = () => {
  const { handleSubmit, errors, control, reset, setError } = useForm()

  const onSubmit = (values) => {
    api('/about_us/feedback/', values, 'POST', true).then((data) => {
      if (!data.errors) {
        reset({
          name: '',
          email: null,
          message: '',
        })
        message.success('Message Sent!')
      } else {
        getError(data, setError)
      }
    })
  }

  return (
    <Wrap>
      <Container>
        <Title>Get in Touch With Us</Title>
        <Text>
          At vero eos et accusamus et iusto odio dignissimos ducimus qui
          blanditiis praesentium voluptatum deleniti atque corrupti quos dolores
          et quas molestias excepturi sint occaecati cupiditate non provident,
          similique sunt in culpa qui officia.
        </Text>
        <Row>
          <BGImage src={logoBg}/>
          <div
            style={{
              borderRight: '1px solid rgba(0, 0, 0, 0.2)',
              position: 'relative',
            }}
          >
            <Contact>
              <img src={placeImg} alt="#"/> Street Address, Suite ### City,
              State Zip###
            </Contact>
            <Contact>
              <img src={phone} alt="#"/> (555) 555 - 8555
            </Contact>
          </div>
          <div style={{ paddingLeft: 65 }}>
            <Title
              style={{
                fontSize: 36,
                fontFamily: 'var(--light)',
                textAlign: 'left',
                marginBottom: 10,
              }}
            >
              How can we help you today?
            </Title>
            <Input
              name={'name'}
              control={control}
              placeholder={'Name'}
              style={{ marginBottom: 15 }}
              validation={{ required: true }}
              error={errors.name && 'Enter valid name'}
            />
            <Input
              name={'email'}
              type={'email'}
              control={control}
              placeholder={'Email Address'}
              style={{ marginBottom: 15 }}
              validation={{ required: true }}
              error={errors.email && 'Enter valid email'}
            />
            <Input
              name={'message'}
              type={'textarea'}
              control={control}
              placeholder={'Message'}
              style={{ marginBottom: 15 }}
              validation={{ required: true }}
              error={errors.message && 'Enter valid email'}
            />
            <Button
              text={'Submit'}
              style={{
                backgroundColor: 'var(--orange)',
                width: 200,
                marginLeft: 'auto',
              }}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </Row>
      </Container>
    </Wrap>
  )
}

export default Form
