import styled from 'styled-components'

export const Block = styled("div")`
  margin-top: 1em;
  width: 100%;
  border: 1px solid #DF7411;
  display: flex;
  justify-content: space-around;
  padding: 12px 20px;
`;

export const Text = styled("div")`
  font-size: 14px;
  font-family: var(--regular);
  color: var(--text);
`;