import styled from 'styled-components'

export const Block = styled('div')`
  border-bottom: 1px solid ${(props) => (props.bordered ? '#C4CACC' : '#fff')};
`

export const Activity = styled('div')`
  margin-top: 0;
  background-color: #f4f6fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  padding: 1em 1em;
`

export const DocumentsPlaceholder = styled('div')`
  color: var(--text);
  font-size: 1em;
  font-family: var(--regular);
  opacity: 0.6;
`

export const Label = styled('div')`
  font-family: var(--semiBold);
  font-size: 0.875em;
  color: var(--text);
  margin-bottom: 0.5em;
`

export const Text = styled('div')`
  font-family: var(--regular);
  font-size: 0.875em;
  color: var(--text);
  letter-spacing: 0.3px;

  mark {
    background-color: var(--yellow);
  }

  pre {
    font-family: var(--regular);
  }

  b {
    font-family: var(--bold);
  }

  p {
    margin: 0;
  }

  a {
    color: var(--green);
    text-decoration: underline;
  }

  .file-deleted {
    text-decoration: underline;
    color: #c4c4c4;
  }

  mention {
    color: var(--orange);
    font-family: var(--bold);
  }
`