import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useStore } from "../../../../../mobx-store/context";
import moment from 'moment'
import { observer } from "mobx-react-lite";
import { StyledSwitch } from "../../../CreateCompany/pages/FirstPage/style";
import { SwitchTitle, SwitchText, Row, DetailsWrap, AllCheckboxContainer, TableTitle } from "../../../CreateCompany/pages/ManageProducts/style";
import { useForm } from "react-hook-form";
import Input from "../../../../../components/common/Input";
import { Divider, Checkbox } from "antd";
import Button from "../../../../../components/common/Button/button";
import { PrevBtn } from "../../../CreateCompany/pages/FirstPage/Form/style";
import CheckboxGroup from '../../../CreateCompany/pages/ManageProducts/CheckboxGroup'
import { api } from "../../../../../api";
import { TextContainer, Text } from "../../style";

const ProposedTab = observer(({ handleTabChange }) => {
  const history = useHistory()
  const store = useStore()
  const { handleSubmit, control, getValues, watch, errors } = useForm()

  const [hasAccesstoPS, setHasAccesstoPS] = useState(store.editedCmsUser.have_access_to_proposed_source)
  const [allJurisdictions, setAllJurisdictions] = useState(store.editedCmsUser.all_proposed_source_jurisdictions)
  const [subscribedToAll, setSubscribedToAll] = useState(store.editedCmsUser.subscribed_to_all_proposed_source_items)
  const [jursCount, setJursCount] = useState(store.editedCmsUser.proposed_jurisdictions?.length)

  const updateCompany = (values) => {
    const data = {
      ...values,
      have_access_to_proposed_source: hasAccesstoPS,
      all_proposed_source_jurisdictions: allJurisdictions,
      subscribed_to_all_proposed_source_items: subscribedToAll,
    }
    api(`/cms/users/${store.editedCmsUser.id}/`, data, 'PATCH').then((res) => {
      if (!res.errors)
        store.setSingleValue('editedCmsUser', res)
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onSubmit = (values) => {
    updateCompany(values)
  }

  const watchValues = watch();

  useEffect(() => {
    setJursCount((getValues('proposed_jurisdictions') && getValues('proposed_jurisdictions').length) || 0)
  }, [watchValues]);

  useEffect(() => {
    updateCompany(getValues())
  }, [hasAccesstoPS, allJurisdictions,subscribedToAll,
    watch('proposed_source_jurisdictions')])

  return (
    <form style={{ width: 710 }} onSubmit={handleSubmit(onSubmit)}>
      <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: 25, marginBottom: 20, flexDirection: 'column' }}>
        <SwitchTitle>Has Access to ProposedSource</SwitchTitle>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 10, }}>
          <StyledSwitch
            defaultChecked={store.editedCmsUser.have_access_to_proposed_source}
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={(checked) => { setHasAccesstoPS(checked) }}
          />
          <TextContainer style={{ marginLeft: 10 }}>
            <Text>{store.editedCmsUser.access_to_proposed_source_granted_date}</Text>
          </TextContainer>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 25, marginBottom: 20 }}>
          <StyledSwitch
            defaultChecked={store.editedCmsUser.subscribed_to_all_proposed_source_items}
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={(checked) => { setSubscribedToAll(checked) }}
          />
          <div style={{ marginLeft: 10 }} >
            <Text>Subscribed to All ProposedSource Items</Text>
          </div>
        </div>
      </div>
      <Divider style={{ background: '#CCC' }} />
      <SwitchTitle>Select Jurisdictions for this user. Choices will be limited to what the company has access to:</SwitchTitle>
      <DetailsWrap style={{ marginTop: 20, gridTemplateColumns: '1fr' }}>
        <TableTitle>Jurisdictions ({allJurisdictions ? 'All' : jursCount})</TableTitle>
      </DetailsWrap>
      <DetailsWrap style={{ gridTemplateColumns: '1fr' }}>
        <AllCheckboxContainer>
          <div> All Jurisdictions </div>
          <Checkbox
            onChange={(e) => setAllJurisdictions(e.target.checked)}
            name={'all_proposed_jurisdictions'}
            style={{ marginLeft: 0, marginRight: 125 }}
            checked={allJurisdictions}
          >
          </Checkbox>
        </AllCheckboxContainer>
      </DetailsWrap>
      {(!allJurisdictions) &&
        <DetailsWrap style={{ gridTemplateColumns: '1fr' }}>
          <CheckboxGroup
            control={control}
            name={'proposed_source_jurisdictions'}
            options={store?.cms_company_options.jurisdictions}
            disabled={allJurisdictions}
            defaultValue={store.editedCmsUser.proposed_source_jurisdictions}
          />
        </DetailsWrap>
      }
    </form>
  )
});

export default ProposedTab;