import { message } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import { api } from '../../../../api'
import Button from '../../../../components/common/Button/button'
import Validation from '../../../../components/common/Validation/validation'
import useModal from '../../../../hooks/useModal'
import { useStore } from '../../../../mobx-store/context'
import { PageTitle, Title } from '../../components/common/style'
import PageWrap from '../../components/Container'
import { BlueBlock } from '../AddTeamMember/style'
import { UnderTitle } from '../Email/style'
import Card from './Card'
import Form from './Form'
import { Cards, Grid } from './style'

const BillingInfo = observer(() => {
  const store = useStore()
  const [value, setValue] = useState('year')
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showTariffPayment, setShowTariffPayment] = useState(false)
  const { visible: visibleConfirm, handleOpen, handleClose } = useModal()
  const [defaultValues, setDefaultValues] = useState({})

  const onSubmit = (value) => {
    setLoading(true)
    api(
      `/companies/${store.company[0].id}/`,
      { payment_period: value },
      'PATCH'
    ).then((data) => {
      if (!data.errors) {
        setValue(value)
        store.setSingleValue('company', [data])
      } else {
        message.error('error')
      }
      setLoading(false)
      handleClose()
    })
  }

  const getTariffPrice = () => {
    let result
    if (value === 'month') {
      result = store.company[0].is_premium
        ? store.currentTariff?.month_premium_price
        : store.currentTariff?.month_base_price
    } else {
      result = store.company[0].is_premium
        ? store.currentTariff?.month_premium_price *
          12 *
          (1 - store.tariffInfo?.discount)
        : store.currentTariff?.month_base_price *
          12 *
          (1 - store.tariffInfo?.discount)
    }

    return result.toFixed(2)
  }

  const handleChange = (value) => {
    setValue(value)
    if (
      store.tariffInfo?.can_change_all_tariffs &&
      store.company[0].tariff !== 'trial'
    ) {
      handleOpen()
    } else {
      onSubmit(value)
    }
  }

  const onCardEdit = (data) => {
    setDefaultValues(data)
    setVisible(true)
    setShowTariffPayment(false)
  }

  useEffect(() => {
    setValue(store.company[0].payment_period)
    if (!store.tariffInfo) store.getTariffInfo()
    if (!store.billingCards) store.getBillingCards()
  }, [])

  return (
    <PageWrap>
      <PageTitle>Billing Information</PageTitle>

      {store.company[0]?.payment_status === 'trial_is_over' && (
        <Validation upperText={`Trial period ended`} text={''} />
      )}
      {store.company[0]?.payment_status === 'trial' && (
        <BlueBlock style={{ marginBottom: 15 }}>
          Trial period will be finished{' '}
          <Moment format={'MM/DD/YYYY'}>{store.company[0]?.trial_end}</Moment>
        </BlueBlock>
      )}

      <Title style={{ marginTop: 15 }}>Payment Methods</Title>

      <Cards>
        {store.billingCards?.map((item) => (
          <Card
            onClick={(data) => onCardEdit(data)}
            data={item}
            styleWrap={{ padding: '10px 0 0 0' }}
            isLastCard={store.billingCards?.length === 1}
            cardType={item.card_short_description}
            allowDelete
          />
        ))}
        {!showTariffPayment && (
          <Button
            text={'+ Add New Card'}
            border
            style={{ margin: '10px 0' }}
            onClick={() => {
              setShowTariffPayment(true)
              setDefaultValues({})
            }}
          />
        )}
      </Cards>

      {(visible || showTariffPayment) && (
        <Form
          onClose={() => {
            setVisible(false)
            setShowTariffPayment(false)
          }}
          addingNewMethod={showTariffPayment}
          defaultValues={defaultValues}
          mainBtnText={'Save'}
        />
      )}

      {/* <Title style={{ marginTop: 15 }}>Billing Schedule Settings</Title>
      <UnderTitle style={{ marginTop: 5 }}>
        You have opted for the {store.company[0]?.payment_period} billing
        schedule.{' '}
        {store.company[0]?.next_payment_date && (
          <span>
            Your next payment will be collected on{' '}
            <span style={{ fontFamily: 'var(--bold)' }}>
              {store.company[0]?.next_payment_date}
            </span>
            . Please note that any changes to your billing schedule and plan
            will not take effect until after this date.
          </span>
        )}{' '}
      </UnderTitle> */}
      <Grid>
        {/* <CustomRadioGroup style={{ width: 250 }}>
          <Title style={{ fontSize: 16 }}>Billing Frequency</Title>
          <RadioConfirm visibleConfirm={visibleConfirm}>
            <Radio.Group
              onChange={(e) => handleChange(e.target.value)}
              value={value}
              disabled={!store.tariffInfo?.can_change_all_tariffs}
            >
              <RadioWrap>
                <Radio value={'year'}>Annual</Radio>
                <Radio value={'month'}>Monthly</Radio>
              </RadioWrap>
            </Radio.Group>
            {visibleConfirm && (
              <RadioRight>
                You have opted to change your plan to be billed{' '}
                {value === 'month' ? 'monthly' : 'annually'} beginning{' '}
                {store.company[0]?.next_payment_date}. This will be billed at a
                rate of ${getTariffPrice()}
                /month. Do you want to confirm this change to your plan?
                <div style={{ display: 'flex', marginLeft: 15 }}>
                  <Button
                    text={'Yes'}
                    onClick={() => onSubmit(value)}
                    style={{ marginRight: 5 }}
                    loading={loading}
                  />
                  <Button
                    text={'No'}
                    onClick={() => {
                      setValue(value === 'year' ? 'month' : 'year')
                      handleClose()
                    }}
                    border
                  />
                </div>
              </RadioRight>
            )}
          </RadioConfirm>
        </CustomRadioGroup> */}
        {/* <AutoRenewal
          styleWrap={{ marginTop: 0, zIndex: 3 }}
          disabled={visibleConfirm}
        /> */}
      </Grid>
    </PageWrap>
  )
})

export default BillingInfo
