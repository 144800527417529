import { useState } from 'react';
import CustomDropdownButton from './dropdownButton.jsx';
import TagList from './tagList.jsx';
const Filters = ({
  setPage,
  selectedFilters,
  setSelectedFilters,
  selectedEditionItems,
  setSelectedEditionItems,
  onEdit,
  disabled = false,
  allUsersPage }) => {

  const filterOptions = [
    { key: 'have_access_to_tracking', label: ' By ComplianceSource' },
    { key: 'have_access_to_proposed_source', label: 'By ProposedSource' },
    { key: 'have_access_to_resource', label: 'By ReSource' },
    { key: 'have_access_to_report_source', label: 'By ReportSource' }
  ];

  const filterOptionsAllUsers = [
    { key: 'have_access_to_tracking', label: 'Has access to ComplianceSource' },
    { key: 'have_access_to_proposed_source', label: 'Has access to ProposedSource' },
    { key: 'have_access_to_resource', label: 'Has access to ReSource' },
    { key: 'have_access_to_report_source', label: 'Has access to ReportSource' },
    { key: 'normal_access', label: 'Normal Access' },
    { key: 'admin_access', label: 'Admin Access' },
    { key: 'staff_access', label: 'Staff Access' },
    { key: 'only_admins', label: 'User Admin' },
    { key: 'only_compliance_owners', label: 'ComplianceSource Owner' },
    { key: 'only_report_owners', label: 'ReportSource Owner' },
    { key: 'only_active', label: 'Active Account' },
    { key: 'only_inactive', label: 'Inactive Account' },
    { key: 'only_locked', label: 'Locked Account' },
    { key: 'terms_and_conditions', label: 'Has Accepted Terms & Conditions' },
  ];

  const editOptions = [
    { key: 'run_report', label: 'Run a Report On Selected Users' },
    { key: 'grant_report_access', label: 'Grant Access to ReportSource' },
    { key: 'grant_resource_access', label: 'Grant Access to ReSource' },
    { key: 'grant_compliance_access', label: 'Grant Access to ComplianceSource' },
    { key: 'grant_proposed_access', label: 'Grant Access to ProposedSource' },
    { key: 'delete_selected_users', label: 'Delete Selected Users' },
  ];

  const companyFilterOptions = [
    { key: 'is_on_trial', label: 'Is on Trial' },
    { key: 'is_trial_is_over', label: 'Trial is Over' },
    { key: 'has_access_to_compliance_source', label: 'Has access to ComplianceSource' },
    { key: 'has_access_to_proposed_source', label: 'Has access to ProposedSource' },
    { key: 'has_access_to_resource', label: 'Has access to ReSource' },
    { key: 'has_access_to_report_source', label: 'Has access to ReportSource' },
    { key: 'is_testing_account', label: 'Testing Account' },
    {key: 'is_inactive', label: 'Is Inactive'},
  ];


  const handleTagClose = (removedTag) => {
    const updatedTags = selectedFilters.filter((tag) => tag !== removedTag);
    setSelectedFilters(updatedTags);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <div>
        {selectedFilters.length ? <TagList tags={selectedFilters} onTagClose={handleTagClose} /> : ''}
      </div>
      <div>
        <CustomDropdownButton
          setPage={setPage}
          style={{ marginRight: 10 }}
          options={allUsersPage === 'all'
            ? filterOptionsAllUsers
            : allUsersPage === 'companyTable'
              ? companyFilterOptions
              : filterOptions}
          onSelectOption={() => { }}
          name={'Filter Results'}
          selectedOptions={selectedFilters}
          setSelectedOptions={setSelectedFilters} />
        {allUsersPage !== 'companyTable' &&
          <CustomDropdownButton
            noIcon={true}
            options={editOptions}
            onSelectOption={(option) => onEdit(option)}
            name={'Edit'}
            selectedOptions={selectedEditionItems}
            setSelectedOptions={setSelectedEditionItems}
            disabled={disabled}
          />
        }
      </div>
    </div>
  );

};

export default Filters;