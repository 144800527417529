import React from 'react'
import { Wrap, Title } from './style'
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import CloseIcon from '../../../assets/images/close-grey.svg'
const Disclaimer = ({ settingsPage, onClose, fadeOut }) => {
  const params = useParams()
  const currentYear = new Date().getFullYear();
  const exeptions = params.type && params?.type === 'topics' ? true : false
  return (
    <Wrap settingsPage={settingsPage} exeptions={exeptions} fadeOut={fadeOut}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', right: '-1px', top: '-1px' }}>
        <img
          src={CloseIcon}
          alt={'delete-icon'}
          onClick={() => {
            if (onClose) onClose()
            sessionStorage.setItem('showDisclaimer', 'false')
          }}
          style={{ cursor: 'pointer', marginTop: 5, marginRight: 5, width: 10, height: 10 }}
        />
      </div>
      <Title style={{ marginleft: 3, marginTop: 10 }}>
        The information posted on PBMSource’s website includes links to information created and maintained by other public and/or private organizations. PBMSource provides these links solely for your information and convenience. When you select a link to an outside website, you are subject to the privacy, copyright, security, and information quality policies of that website. If you find a link that does not work, please let us know - <a href='mailto:contact@pbmsource.com'>contact@pbmsource.com</a>. <a href='https://pbmsource.statuspage.io/' target="_blank" rel="noopener noreferrer">Having issues? View Our Current Status</a>.
        <br />By using PBMSource you agree to our <a href='/terms-and-privacy'>Terms and Conditions of Use, </a> <a href='/terms-and-privacy'>Privacy Policy,</a> <a href='/secuity-and-privacy'>and Subscription Agreement</a>
      </Title>
      <Title>
        PBMSource is not a law firm or a substitute for an attorney or law firm. This website is for informational purposes. Use of this website does not constitute legal advice, nor does it create an attorney/client relationship. © PBM Source {currentYear} All rights reserved.
      </Title>
    </Wrap>
  );
};

export default Disclaimer