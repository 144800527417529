import { PageTitle, NavPageWrap, Subtitle } from "../GuidesTable/style";
import ButtonOrange from "../../../../components/common/ButtonOrange";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Input from "../../../../components/common/Input";
import { Divider, message } from "antd";
import Button from "../../../../components/common/Button/button";
import { api } from "../../../../api";
import Wraper from "../../CMSContainer";
const AddGuideNamePage = () => {
  const history = useHistory()
  const { handleSubmit, control, reset, errors } = useForm();

  const onSubmit = (data) => {
    api('/cms/resource_guide_names/', { ...data }, 'POST').then((res) => {
      if (!res.errors) {
        message.success('Guide name added successfully')
        reset({
          name: '',
          description: '',
        })
        history.push('/dashboard-guides-table')
      }
    })
  };

  return (
    <Wraper>
      <NavPageWrap style={{ width: '60%' }}>
        <PageTitle>
          Add New Guide Name
        </PageTitle>
        <Subtitle>
          Add a new Guide name and description.
        </Subtitle>
        <ButtonOrange
          text={'< Go Back'}
          onClick={() => history.goBack()}
          style={{ fontFamily: 'var(--bold)', color: '#4E74A9', fontSize: 12, textDecoration: 'none', marginBottom: 32 }}
        />
        <Input
          style={{ width: '100%', marginBottom: 32 }}
          smallLabel
          name={'name'}
          label={'Guide Name'}
          type={'text'}
          control={control}
          validation={{ required: true }}
          transparent
        />
        <Input
          style={{ width: '100%', marginBottom: 32 }}
          smallLabel
          name={'description'}
          label={'Guide Description'}
          type={'textarea'}
          control={control}
          validation={{ required: true, maxLength: 500 }}
          error={errors && errors.description && 'Ensure this field has no more than 500 characters'}
          transparent
          placeholder={'Guide Description'}
        />
        {/* <Input
          smallLabel
          style={{ width: '40%', marginBottom: 32 }}
          name={'price'}
          label={'Price'}
          type={'text'}
          control={control}
          validation={{ required: true }}
          transparent
          placeholder={'Guide Name'}
          defaultValue={formatCurrency(0)}
          showCross={false}
        /> */}
        <Divider style={{ background: '#8B9394' }} />
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            text={'Save'}
            onClick={handleSubmit(onSubmit)}
            htmlType="submit"
            type={'main'}
            style={{
              width: '10%',
              padding: 0,
              marginRight: 0,
              marginLeft: '.5em',
              marginTop: '2.3em',
            }}
          />
        </div>
      </NavPageWrap >
    </Wraper>
  );
};

export default AddGuideNamePage;