import React from 'react'
import { checkRole } from '../../../helpers/helpers'
import { useStore } from '../../../mobx-store/context'
import { Item, Wrap, WorkingFlow } from './tabs.style'
import { useState } from 'react'
import NoAccessModal from './NoAcessModal'
const Tabs = ({ tabs, active, onClick, isResource, Reporting = true, isReportSource, documentTabs, isLicenseSource }) => {
  const store = useStore()
  const user = store.user?.[0]
  const [visible, setVisible] = useState(false)
  const isAdminOrComplianceOwner = checkRole([user?.member_type], ['admin', 'compliance_owner']);
  const isAdminOrReportOwner = checkRole([user?.report_source_role], ['admin', 'report_owner']);
  const isAdminOrLicenseOwner = checkRole([user?.license_source_role], ['admin', 'license_owner']);

  const accessAllowed = isReportSource ? isAdminOrReportOwner : isLicenseSource ? isAdminOrLicenseOwner : isAdminOrComplianceOwner;
  const trackingMode = isReportSource ? store?.company[0]?.report_source_mode : store?.company[0]?.compliance_source_mode;

  return (
    <Wrap isResource={isResource}>
      {visible && <NoAccessModal onClose={() => setVisible(false)} />}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div name={'tabs-container'} style={{ display: 'flex', alignItems: 'flex-end' }}>
          {tabs
            .filter(item => {
              if (isReportSource) {
                return !item.disabledFor?.includes(store.user?.[0]?.report_source_role) && item.title !== 'Activity';
              } else if (isLicenseSource) {
                return !item.disabledFor?.includes(store.user?.[0]?.license_source_role) && item.title !== 'Activity';
              }
              else {
                return !item.disabledFor?.includes(store.user?.[0]?.member_type) && item.title !== 'Activity';
              }
            })
            .map(item => (
              <>
                <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                  {item.title === 'Working' && <WorkingFlow> Document Flow:</WorkingFlow>}
                  <Item
                    disabled={item.disabled}
                    documentTabs={documentTabs}
                    calendar={item.title === 'Calendar'}
                    myTasks={item.title === 'My Tasks'}
                    key={item.key}
                    name={item.title + '-tab'}
                    active={active === item.key}
                    onClick={item.disabled ? () => setVisible(true) : () => onClick(item.key, item.name)}
                  >
                    {item.title}
                  </Item>
                </div>
                {item.divider && <div style={{ width: 2, height: 30, backgroundColor: '#E0E0E0', margin: '0 5px' }}></div>}
              </>
            ))}
        </div>

        {isReportSource ?
          trackingMode === 'report_source'
            ? accessAllowed && !isResource && Reporting && !isLicenseSource && (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Item
                  active={active === 'reporting-task'}
                  onClick={() => onClick('reporting-task', 'reporting-task')}
                  style={{ marginRight: 5 }}
                >
                  Task Report
                </Item>
                <Item
                  active={active === 'reporting-item'}
                  onClick={() => onClick('reporting-item', 'reporting-item')}
                  style={{ marginRight: 0 }}
                >
                  Item Report
                </Item>
              </div>)
            : Reporting && accessAllowed && <Item
              active={active === 'reporting-item'}
              onClick={() => onClick('reporting-item', 'reporting-item')}
              style={{ marginRight: 0 }}
            >
              Reporting
            </Item>
          : trackingMode === 'compliance_source'
            ? accessAllowed && !isResource && Reporting && !isLicenseSource && (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Item
                  active={active === 'reporting-task'}
                  onClick={() => onClick('reporting-task', 'reporting-task')}
                  style={{ marginRight: 5 }}
                >
                  Task Report
                </Item>
                <Item
                  active={active === 'reporting-item'}
                  onClick={() => onClick('reporting-item', 'reporting-item')}
                  style={{ marginRight: 0 }}
                >
                  Item Report
                </Item>
              </div>)
            : Reporting && accessAllowed && <Item
              active={active === 'reporting-item'}
              onClick={() => onClick('reporting-item', 'reporting-item')}
              style={{ marginRight: 0 }}
            >
              Reporting
            </Item>
        }

        {tabs
          .filter(item => {
            if (isReportSource) {
              return !item.disabledFor?.includes(store.user?.[0]?.report_source_role) && item.title === 'Activity';
            }
            else if (isLicenseSource) {
              return !item.disabledFor?.includes(store.user?.[0]?.license_source_role) && item.title === 'Activity';
            }
            else {
              return !item.disabledFor?.includes(store.user?.[0]?.member_type) && item.title === 'Activity';
            }
          })
          .map(item => (
            <div name={'tabs-container'} style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Item
                disabled={item.disabled}
                documentTabs={documentTabs}
                calendar={item.title === 'Calendar'}
                key={item.key}
                name={item.title + '-tab'}
                active={active === item.key}
                onClick={item.disabled ? () => { } : () => onClick(item.key, item.name)}
                style={{ marginRight: 0 }}
              >
                {item.title}
              </Item>
            </div>
          ))}
      </div>
    </Wrap>
  )
}

export default Tabs
