import styled from 'styled-components'
import {
  Grid as PricingGrid,
  RightSide as Right,
} from '../../../Auth/Pricing/pricing.style'

export const Grid = styled(PricingGrid)`
  max-width: 900px;
  margin: 0 auto;
`;

export const RightSide = styled(Right)`
  grid-template-columns: 270px 290px;
`;

export const Text = styled("div")`
  font-family: var(--regular);
  font-size: 14px;
  color: #000;
`;
