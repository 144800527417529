import { Spin } from 'antd'
import { observer } from 'mobx-react'
import ButtonOrange from '../../../../../components/common/ButtonOrange'
import { useStore } from '../../../../../mobx-store/context'
import { Row, Title } from '../../../components/common/style'
import { Text } from '../style'
import Table from '../table'

const ProposedSourceTab = observer(() => {
  const store = useStore()
  const company = store.company[0]
  const members = store.members.filter((item) => item.have_access_to_proposed_source)

  if (!company) return <Spin />

  return (
    <>
      {/* <Row Row style={{ marginTop: '1em' }}>
        <Title>ProposedSource Plan</Title>
      </Row>
      {company?.under_contract ?
        <Text style={{ marginTop: '10px' }}>
          Custom Price:{' '}
          <span style={{ fontFamily: 'var(--medium)' }}>
            ${company?.proposed_source_custom_price}/yr
          </span>
        </Text>
        : <Text style={{ marginTop: '10px' }}>
          Annual Subscription:{' '}
          <span style={{ fontFamily: 'var(--medium)' }}>
            $60,000/yr
          </span>
        </Text>
      } */}
      <Text>{members?.length} Active Users</Text>
      {/* <ButtonOrange
        text={'Export Proposed Source'}
        link={'/product-settings/proposedSource'}
      /> */}
      {store.members.length > 0 ? (
        <Table data={members} isProductSetting />
      ) : (
        <Spin />
      )}
    </>
  )
})

export default ProposedSourceTab

