import styled from 'styled-components'

export const Wrap = styled('div')`
  margin: 0 auto;
  border: 1px solid #fff;
  border-radius: 8px;
  overflow: hidden;
`

export const Button = styled('div')`
  width: 100%;
  min-height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.active ? props.activeTextColor ?? 'var(--blue)' : '#fff'};
  font-size: 18px;
  font-family: ${(props) => (props.active ? 'var(--bold)' : 'var(--thin)')};
  background-color: ${(props) =>
    props.active ? props.activeColor ?? '#fff' : 'transparent'};
  cursor: pointer;
  padding: 0 15px;
  flex-direction: column;
  span {
    text-align: center;
  }
`
