import styled from 'styled-components'

export const Title = styled('div')`
  font-size: 36px;
  font-family: var(--semiBold);
  color: ${(props) => props.locked ? 'var(--red)' : 'var(--text)'};
  margin-bottom: 10px;
`

export const Description = styled('div')`
  font-size: 18px;
  font-family: var(--regular);
  color: var(--text);
  span {
    font-size: 18px;
    font-family: var(--semiBold);
    color: var(--text);
  }
`