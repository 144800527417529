import styled from 'styled-components'

export const LogoWrap = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 3em;
  padding-left: 5vw;
`;

export const Wrap = styled("div")`
  width: 600px;
  height: 70vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-height: 700px) {
    height: auto;
    padding-top: 7em;
  }
`;

export const Row = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  padding: 2em 3em 0;
  margin: 0 auto;
`;
