import styled from 'styled-components'

export const Wrap = styled('div')`
  background-color: #f4f6f6;
`

export const Item = styled('div')`
  display: grid;
  grid-template-columns: 1fr 80px;
  align-items: center;
  min-height: 45px;
  font-family: var(--medium);
  font-size: 14px;
  color: ${(props) => (props.active ? '#fff' : 'var(--text)')};
  padding: 10px 15px;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  background-color: ${(props) => (props.active ? '#4B75B1' : 'transparent')};
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};
  span {
    &:nth-child(2) {
      //background-color: red;
    }
  }

  .ant-spin-dot-item {
    background-color: #fff;
  }
`

export const Header = styled(Item)`
  border-bottom: 1px solid #969fa2;
  cursor: default;
  margin-bottom: 5px;
  display: ${(p) => (p.details && 'grid')};
  grid-template-columns: ${(p) => (p.details && '1.1fr 1fr')}
`

export const Scrollable = styled('div')`
  height: 50vh;
  overflow-y: auto;
  position: relative;
`

export const GuideName = styled('div')`
  color: var(--blue);
  font-family: var(--bold);
  font-size: 14px;
`
