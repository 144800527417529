import styled from "styled-components";

export const Title = styled("div")`
color: var(--text);
font-family: var(--medium);
font-size: 24px;
`
export const SubTitle = styled("div")`
color: var(--text);
font-family: var(--semiBold);
font-size: 14px;
`