import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { api } from '../../../api'
import check from '../../../assets/images/check.svg'
import BillPeriodButtons from '../../../components/common/BillPeriodButtons/buttons'
import Button from '../../../components/common/Button/button'
import Logo from '../../../components/common/Logo/logo'
import StatusBar from '../../../components/common/StatusBar/status'
import Container from '../../../components/containers/container'
import { useStore } from '../../../mobx-store/context'
import { TryFree } from '../Billing/billing.style'
import { getItems } from './data/items'
import { leftSide } from './data/leftSide'
import {
  AnnualRequired,
  BestTitle,
  Footer,
  Grid,
  Item,
  LeftSide,
  Option,
  PageTitle,
  Price,
  PriceBlock,
  PriceDescription,
  RightSide,
  Row,
  Title,
  TitleLeft,
  Tooltip,
  UnderTitle,
  Wrap,
} from './pricing.style'

const Pricing = observer(() => {
  const history = useHistory()
  const store = useStore()
  const [loading, setLoading] = useState(false)
  const [active, setActive] = useState(null)
  const [premium, setPremium] = useState(false)
  const [activeButton, setActiveButton] = useState('year')

  const handleFetch = (tariff) => {
    setLoading(true)
    return api(
      `/companies/${store.company[0].id}/`,
      {
        tariff,
        is_premium: premium,
        jurisdictions: [],
        topics: [],
        payment_period: activeButton,
      },
      'PATCH'
    )
      .then((data) => {
        setLoading(false)
        if (!data.errors) {
          store.setSingleValue('company', [data])
          history.push(
            data.tariff === 'total_tract' ? '/addMember' : '/topicsAndJur'
          )
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const jurisContent = useMemo(() => {
    return (
      <>
        <Tooltip>• All US States</Tooltip>
        <Tooltip>• District of Columbia</Tooltip>
        <Tooltip>• Federal</Tooltip>
      </>
    )
  }, [store.jurs])

  const premiumContent = useMemo(() => {
    return (
      <>
        {store.lineOfBusinesses?.map((item) => (
          <Tooltip key={item}>• {item.name}</Tooltip>
        ))}
      </>
    )
  }, [store.lineOfBusinesses])

  const topicsContent = useMemo(() => {
    return (
      <>
        {store.topics?.map((item) => (
          <Tooltip key={item}>• {item?.name}</Tooltip>
        ))}
      </>
    )
  }, [store.topics])

  useEffect(() => {
    if (store.company.length) {
      const company = store.company[0]
      setActive(company.tariff)

      if (company.is_premium) {
        setPremium(true)
      }
    }
  }, [store.company])

  useEffect(() => {
    store.loadSelect('companyOptions')
  }, [])

  return (
    <Container wide>
      <Wrap>
        <Row style={{ justifyContent: 'flex-start', alignItems: 'flex-end' }}>
          <Logo large />
          <StatusBar
            style={{ marginLeft: '1em', marginBottom: '1em' }}
            step={3}
          />
        </Row>

        <Grid>
          <LeftSide>
            <TitleLeft style={{ backgroundColor: '#4e74a9' }}>
              <PageTitle>Pricing</PageTitle>
              Features
              <div style={{ fontSize: '24px', marginBottom: 30 }}>
                ComplianceSource
              </div>
            </TitleLeft>
            {leftSide.map((item) => {
              return (
                <Option key={item.key} style={item.style}>
                  {item.content({
                    topicsContent,
                    jurisContent,
                    premiumContent,
                    premium,
                    setPremium,
                  })}
                </Option>
              )
            })}
            <Footer>
              Choose Your Plan:{' '}
              <BillPeriodButtons
                paymentPeriod={activeButton}
                setActiveButton={(active) => setActiveButton(active)}
                styleContainer={{ marginTop: 15 }}
                activeTextColor={'var(--green)'}
              />
            </Footer>
          </LeftSide>
          <RightSide>
            {getItems(premium).map((item) => {
              return (
                <Item
                  key={item.key}
                  active={
                    active === item.planNameForBackend ||
                    active === item.planNameForBackend + '_premium'
                  }
                  onClick={() => {
                    setActive(item.planNameForBackend)
                    store.updateCompany({ month_price: item.price })
                  }}
                >
                  <Title>{item.planName}</Title>
                  <UnderTitle>{item.underTitle}</UnderTitle>
                  <PriceBlock>
                    <PriceDescription>
                      <BestTitle>Best for Businesses</BestTitle>
                      {item.description}
                    </PriceDescription>
                    <Price>
                      <span>
                        {item.planNameForBackend === 'select_tract' && (
                          <span style={{ fontSize: 12 }}>As low as</span>
                        )}{' '}
                        ${item.price}
                      </span>{' '}
                      /month
                    </Price>

                    {activeButton === 'year' && (
                      <AnnualRequired>
                        *Annual subscription required
                      </AnnualRequired>
                    )}
                  </PriceBlock>
                  {item.options?.map((optionItem) => (
                    <Option
                      key={optionItem.key}
                      style={
                        optionItem.styleOption
                          ? optionItem.styleOption
                          : { height: optionItem.style?.height }
                      }
                    >
                      {optionItem.includes === 'all' ? (
                        <img src={check} alt='check-icon' />
                      ) : (
                        optionItem[item.planNameForBackend](item)
                      )}
                    </Option>
                  ))}

                  <Footer
                    active={active === item.key}
                    style={{
                      backgroundColor:
                        active === item.planNameForBackend
                          ? 'rgba(145, 192, 62, 0.5)'
                          : '#F5F9EC',
                    }}
                  >
                    {activeButton === 'year'
                      ? item.total.annual
                      : item.total.month}
                    <Button
                      text={`Choose ${item.button}`}
                      style={{
                        width: 180,
                        borderColor: 'var(--blue)',
                        color: 'var(--blue)',
                        fontFamily: 'var(--medium)',
                        fontSize: 16,
                        marginTop: 15,
                      }}
                      type={'white'}
                      onClick={() => {
                        handleFetch(item.planNameForBackend.toLowerCase())
                        store.setSingleValue('maxUsers', item.maxUsers)
                      }}
                      loading={loading}
                    />
                    <TryFree
                      style={{
                        fontSize: 12,
                        fontFamily: 'var(--regular)',
                        color: 'var(--text)',
                        marginTop: 0,
                      }}
                    >
                      Try for free first!
                    </TryFree>
                  </Footer>
                </Item>
              )
            })}
          </RightSide>
        </Grid>
      </Wrap>
    </Container>
  )
})

export default Pricing
