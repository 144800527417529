import Modal from "../../../../components/common/ModalNew"
import Button from "../../../../components/common/Button/button"
import { api } from "../../../../api"
import { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { Divider, message } from "antd"
import { Title } from "../Table/style"
import Input from "../../../../components/common/Input";
import { StyledSwitch } from "../../CreateCompany/pages/FirstPage/style"
import { SwitchWrap } from "../../CreateCompany/pages/ManageProducts/style"

const DictionariesEditModal = ({ onClose, isEdit, title, id, name, store, getData, }) => {
  const [initialData, setInitialData] = useState(null)
  const [loading, setLoading] = useState(isEdit ? true : false)
  const [deprecated, setDeprecated] = useState(false)
  const { register, handleSubmit, errors, reset, control } = useForm()

  const label = name === 'jurisdictions' ? 'Jurisdiction' : 'Name'

  const getDictionary = () => {
    const url = name === 'applies_to' ? `/cms/dictionaries/applies_to/${id}/` : `/cms/${name}/${id}/`
    api(url, {}, 'GET').then((data) => {
      if (data.errors) return
      setInitialData(data)
      setDeprecated(data.is_deprecated)
      reset(data)
    }).then(() => setLoading(false))
  }

  useEffect(() => {
    isEdit && getDictionary()
  }, [])

  const onSubmit = (values) => {
    const url = isEdit ? `/cms/${name === 'applies_to' ? 'dictionaries/' : ''}${name}/${id}/` : `/cms/${name === 'applies_to' ? 'dictionaries/' : ''}${name}/`
    const obj = isEdit ? { ...values, is_deprecated: deprecated } : { ...values }
    api(url, obj, isEdit ? 'PATCH' : 'POST').then((data) => {
      getData()
      if (data.errors) return
      onClose()
      message.success(`${title} ${isEdit ? 'Updated' : 'Added'} successfully`)
    })
  }
  return (
    <>
      {!loading &&
        <Modal
          styleWrap={{
            padding: '30px 40px',
          }}
          onClose={onClose} >
          <Title style={{ marginBottom: 32 }}>
            {isEdit ? 'Edit' : 'Add New'} {title}
          </Title>
          <Input
            style={{ marginBottom: 32 }}
            smallLabel
            name={'name'}
            label={isEdit ? `${title} Name` : `${title} Name being Added`}
            type={'text'}
            control={control}
            validation={{ required: !isEdit }}
            transparent
          />
          {name === 'jurisdictions' &&
            <Input
              style={{ marginBottom: 32 }}
              smallLabel
              name={'code'}
              label={isEdit ? `Jurisdiction Abbreviation` : `Jurisdiction Abbreviation being Added`}
              type={'text'}
              control={control}
              validation={{ required: !isEdit }}
              transparent
            />
          }
          {name === 'sub_topics' &&
            <Input
              style={{ marginBottom: 32 }}
              dropdownStyle={{ position: 'absolute', zIndex: 9999999 }}
              smallLabel
              name={'topic'}
              label={'Topic'}
              type={'select'}
              options={store?.topics}
              control={control}
              validation={{ required: !isEdit }}
              transparent
            />
          }
          {isEdit &&
            <SwitchWrap>
              <StyledSwitch
                disabled={false}
                checked={deprecated}
                style={{ marginRight: 10 }}
                checkedChildren="Yes"
                unCheckedChildren="No"
                onChange={(checked) => { setDeprecated(checked) }}
              />
              {'Deprecated'}
            </SwitchWrap>
          }
          <Divider />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button text={isEdit ? 'Edit' : 'Add'} onClick={handleSubmit(onSubmit)} />
          </div>
        </Modal >
      }
    </>
  )
}

export default DictionariesEditModal