import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Disclaimer from '../../../components/common/Disclaimer'
import TopLine from '../../../components/TopLine/topLine'
import { useStore } from '../../../mobx-store/context'
import Header from '../components/Header'
import { Layout } from '../components/style'
import { Container, Wrap } from '../Resource/style'
import Left from './Left'
import Right from './Right'

const TopicsJursList = observer(() => {
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const [active, setActive] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showDisclaimer, setShowDisclaimer] = useState({ show: false, fadeOut: false });

  const isGuides = params.type === 'guides'

  useEffect(() => {
    if (store.resourcesCurrent) setActive(store.resourcesCurrent)
  }, [])

  useEffect(() => {
    setShowDisclaimer(sessionStorage.getItem('showDisclaimer') === 'true')
  }, [])

  useEffect(() => {
    const show = sessionStorage.getItem('showDisclaimer') === 'true';
    setShowDisclaimer({ show: show, fadeOut: !show });
  }, []);

  const handleDisclaimerClose = () => {
    setShowDisclaimer(prev => ({ ...prev, fadeOut: true }));
    setTimeout(() => {
      setShowDisclaimer({ show: false, fadeOut: false });
      sessionStorage.setItem('showDisclaimer', 'false');
    }, 500);
  }

  useEffect(() => {
    if (!store.jurs) store.loadSelect('companyOptions')
    if (!store.resourceFiltered)
      store.getJurisdiction(
        +params.jurisdictionID || +params.selectId, +params.selectId,
        (data) => {
          if (!data.length) history.push('/resource/table/guides')
        }
      )
    if (!store.resourceFilters) store.getResourceFilters()
    if (!store.resources) store.getResourceTopic()
    if (!store.resourcesCurrentTopic)
      store.getResourceTopic(
        isGuides ? +params.selectId : +params.id,
        null,
        () => {
          history.push('/resource/table/guides')
        }
      )
  }, [])

  const handleSetLoading = (value) => setLoading(value)

  const handleSetActive = (value) => setActive(value)

  return (
    <>
      <TopLine />
      <Wrap>
        <Container>
          <Header goBack />
          <Layout>
            {store.resourceFiltered && store.resourcesCurrentTopic && (
              <Left
                active={active}
                setActive={handleSetActive}
                setLoading={handleSetLoading}
                loading={loading}
              />
            )}

            {active && <Right item={active} loading={loading} />}
          </Layout>
        </Container>
      </Wrap>
      {showDisclaimer.show && <Disclaimer onClose={handleDisclaimerClose} fadeOut={showDisclaimer.fadeOut} />}
    </>
  )
})

export default TopicsJursList
