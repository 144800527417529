import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { api } from '../../../../../../api'
import Button from '../../../../../../components/common/Button/button'
import Input from '../../../../../../components/common/Input'
import { useStore } from '../../../../../../mobx-store/context'
import { Row, Wrap, PrevBtn, Title, SubTitle } from './style'
import { StyledSwitch, StyledRadioGroup } from '../style'
import { Radio } from 'antd'
import { Divider } from 'antd'

const Form = observer(() => {
  const store = useStore()
  const history = useHistory()
  const [trialEnabled, setTrialEnabled] = useState(!localStorage.getItem('cmsCompanyId') ? 'not_in_trial' : store?.cmsCompany[0]?.trial_option || 'not_in_trial')
  const [testingEnabled, setTestingEnabled] = useState(!localStorage.getItem('cmsCompanyId') ? false : store?.cmsCompany[0]?.is_testing_company || false)
  // const [loading, setLoading] = useState(true)
  const { control, handleSubmit, errors } = useForm(
    {
      defaultValues: !localStorage.getItem('cmsCompanyId') ? {} : {
        name: store?.cmsCompany[0]?.name || '',
        address1: store?.cmsCompany[0]?.address1 || '',
        address2: store?.cmsCompany[0]?.address2 || '',
        address3: store?.cmsCompany[0]?.address3 || '',
        state: store?.cmsCompany[0]?.state || '',
        zip: store?.cmsCompany[0]?.zip || '',
        web_domain: store?.cmsCompany[0]?.domain || '',
        trial_option: store.cmsCompany[0]?.trial_option || 'not_in_trial',
        is_testing_company: store.cmsCompany[0]?.is_testing_company || false,
      }
    }
  )

  const getCmsOptions = () => {
    api(`/cms/dictionaries_data/all/`, {}, 'GET').then((data) => {
      if (!data.errors) {
        store.setSingleValue('cms_options', data)
      }
    })
  }
  useEffect(() => {
    getCmsOptions()
  }, [])



  const getCompanyInfo = (id) => {
    api(`/cms/companies/${id}/`, {}, 'GET').then((data) => {
      store.setSingleValue('cmsCompany', [data])
    })
  }

  useEffect(() => {
    if (!store.states.length) store.loadSelect('states')
  }, [])

  useEffect(() => {
    localStorage.getItem('cmsCompanyId') && getCompanyInfo(localStorage.getItem('cmsCompanyId'))

  }, [])

  const createCompany = (values) => {
    const url = localStorage.getItem('cmsCompanyId') ? `/cms/companies/${localStorage.getItem('cmsCompanyId')}/` : `/cms/companies/`

    api(url, {
      name: values.name,
      address1: values.address1,
      address2: values.address2 || '',
      state: values.state,
      zip: values.zip,
      domain: values.web_domain,
      trial_option: trialEnabled,
      is_testing_company: testingEnabled,
    }, localStorage.getItem('cmsCompanyId') ? 'PATCH' : 'POST').then((data) => {
      store.setSingleValue('cmsCompany', [data])
      !localStorage.getItem('cmsCompanyId') && localStorage.setItem('cmsCompanyId', data.id)
      history.push(`/manage-products/`)
    })
  }


  const onSubmit = (data) => {
    createCompany(data)
  }

  return (

    <Wrap onSubmit={handleSubmit(onSubmit)}>
      <Input
        smallLabel
        name={'name'}
        label={'Company Name'}
        error={errors && errors.name && 'Enter valid company name'}
        type={'text'}
        control={control}
        validation={{ required: true }}
        transparent
        placeholder={'Company Name'}
        style={{ marginBottom: errors && errors.name ? '0' : '1em' }}
      />
      <Row style={{ alignItems: 'flex-start' }}>
        <Input
          smallLabel
          name={'address1'}
          label={'Address 1'}
          error={errors && errors.address1 && 'Enter valid address name'}
          type={'text'}
          control={control}
          validation={{ required: true }}
          transparent
          placeholder={'Address'}
        />
        <Input
          smallLabel
          name={'address2'}
          control={control}
          label={'Address 2'}
          error={errors && errors.address2 && 'Enter valid address name'}
          validation={{ required: false }}
          disableValidation={true}
          type={'text'}
          transparent
          placeholder={'Address'}
        />
      </Row>
      <Row style={{ alignItems: 'flex-start', gridTemplateColumns: '0.87fr 0.4fr 0.4fr', }}>
        <Input
          smallLabel
          name={'address3'}
          control={control}
          label={'City'}
          error={errors && errors.address3 && 'Enter valid address name'}
          validation={{ required: false }}
          disableValidation={true}
          type={'text'}
          transparent
          placeholder={'City'}
        />
        <Input
          smallLabel
          name={'state'}
          error={errors && errors.state && 'Enter valid state'}
          type={'select'}
          control={control}
          validation={{ required: true }}
          transparent
          options={store.states}
          label={'State'}
          placeholder={'State'}
          style={{ marginBottom: errors && errors.state && '0' }}
          selectVars={{ value: 0, name: 1 }}
          showSearch
        />
        <Input
          smallLabel
          name={'zip'}
          error={errors && errors.zip && 'Enter valid zip code (5 numbers)'}
          type={'text'}
          control={control}
          label={'ZIP'}
          validation={{ required: true, maxLength: 5, minLength: 5 }}
          transparent
          placeholder={'Zip Code'}
          mask={'99999'}
        />
      </Row>
      <Row style={{ alignItems: 'flex-start' }}>
        <Input
          smallLabel
          name={'web_domain'}
          label={'Web Domain'}
          type={'text'}
          control={control}
          transparent
          placeholder={'Web Domain'}
          error={errors && errors.web_domain && 'Enter web domain'}
          style={{ marginBottom: '0.3em' }}
          validation={{ required: true }}
        />
      </Row>
      <Divider style={{ padding: '0, 32px', backgroundColor: '#8B9394' }} />
      <Row style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'flex-start', gap: 0 }}>
        <div>
          <Title>Trial Account </Title>
          <SubTitle>
            Adds trial tag to company and allows it to be filtered on the <br /> companies page and in mailchimp.com
          </SubTitle>
          <div style={{ fontSize: 14, fontFamily: 'var(--regular)', display: 'flex', marginTop: 15 }}>
            <StyledRadioGroup onChange={(e) => setTrialEnabled(e.target.value)} value={trialEnabled}>
              <Radio value={'not_in_trial'}>Not on Trial</Radio>
              <Radio value={'trial_is_active'}>Trial is <strong>Active</strong></Radio>
              <Radio value={'trial_is_over'}>Trial is <strong>Over</strong></Radio>
            </StyledRadioGroup>
          </div>
        </div>
        <div>
          <Title>Testing Account </Title>
          <SubTitle>
            Adds testing tag to company and allows it to be filtered on  <br /> the companies page and will be ignored in mailchimp.com
          </SubTitle>
          <div style={{ fontSize: 14, fontFamily: 'var(--regular)', display: 'flex', marginTop: 15 }}>

            <StyledSwitch
              defaultChecked={testingEnabled}
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={(checked) => setTestingEnabled(checked)}
            />
            {testingEnabled
              ? <div style={{ marginLeft: 10 }}>This company  <strong> is </strong> a testing account</div>
              : <div style={{ marginLeft: 10 }}>This company <strong> is not </strong>a testing account</div>
            }
          </div>
        </div>
      </Row>
      <Divider style={{ padding: '0, 32px', backgroundColor: '#8B9394' }} />
      <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PrevBtn onClick={() => {
          localStorage.removeItem('cmsCompanyId')
          history.goBack()
        }}>Previous</PrevBtn>
        <Button
          text={'Next'}
          type={'main'}
          style={{
            width: '10%',
            padding: 0,
            marginRight: 0,
            marginLeft: '.5em',
            marginTop: '2.3em',
          }}
        />
      </Row>
    </Wrap>
  )
})

export default Form
