import { Select, message } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'
import crossOrange from '../../../../../assets/images/cross-orange.svg'
import Button from '../../../../../components/common/Button/button'
import { useStore } from '../../../../../mobx-store/context'
import { Title, Item, ItemsWrap, SelectBLock, Wrap } from './style'
import { api } from '../../../../../api'
import { getError } from '../../../../../helpers/helpers'
import { toJS } from 'mobx'

const RestrictedItems = observer(
  ({ name, restrictions, title }) => {
    const store = useStore()
    const [value, setValue] = useState(null)

    const [restrictedItems, setRestrictedItems] = useState([])
    const [companyOptions, setCompanyOptions] = useState([])

    useEffect(() => {
      setRestrictedItems(toJS(store?.restrictedItems?.[restrictions]))
      setCompanyOptions(toJS(store?.company_options?.[name]))
    }, [])

    const getRestrictdItems = () => {
      api('/companies/restrict/', {}, 'GET').then((data) => {
        if (!data.errors) {
          store.setSingleValue('restrictedItems', data)
        }
      })
    }
    const getCompanyOptions = () => {
      api('/companies/company_options/', {}, 'GET').then((data) => {
        if (!data.errors) {
          store.setSingleValue('company_options', data)
        }
      })
    }

    const updateRestrictedList = (id, remove) => {
      api('/companies/restrict/',
        { [restrictions]: +id },
        remove ? 'POST' : 'DELETE',
      )
        .then(data => {
          if (data.errors) return message.error(getError(data))
          getRestrictdItems()
          getCompanyOptions()
        })
    }

    const moveItem = (id, remove) => {
      let itemToMove = null;
      let sourceArray = null;
      let destinationArray = null;


      if (remove) {
        // Move from activeItems to disabledItems
        itemToMove = companyOptions.find((item) => item.id === id);
        sourceArray = companyOptions;
        destinationArray = restrictedItems;
      } else {
        // Move from disabledItems to activeItems
        itemToMove = restrictedItems.find((item) => item.id === id);
        sourceArray = restrictedItems;
        destinationArray = companyOptions;
      }

      if (itemToMove) {
        const updatedSourceArray = sourceArray.filter((item) => item.id !== id);

        const updatedDestinationArray = [...destinationArray, itemToMove];

        // Update the state
        if (remove) {
          setCompanyOptions(updatedSourceArray);
          setRestrictedItems(updatedDestinationArray);
          updateRestrictedList(id, remove)
        } else {
          setCompanyOptions(updatedDestinationArray);
          setRestrictedItems(updatedSourceArray);
          updateRestrictedList(id, remove)
        }
      }
    };

    const onRestrict = (value, mode) => {
      if (!restrictedItems.includes(value)) {
        moveItem(value, mode)
        setValue(null)
      }
    }

    const getOptions = () => {
      return companyOptions?.map((item) => (
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      ))
    }

    return (
      <Wrap>
        <Title>
          {title} <span>({restrictedItems.length})</span>
        </Title>
        <ItemsWrap>
          {restrictedItems.map((item, index) => (
            item.name && <Item
              key={item.id}
              style={{
                backgroundColor: index % 2 !== 0 && 'rgba(34, 81, 148, 0.05)',
              }}
            >
              {item.name}
              <img
                src={crossOrange}
                onClick={() => moveItem(item.id, false)}
                alt='cross-icon'
              />
            </Item>
          ))}
        </ItemsWrap>
        {(
          <SelectBLock>
            <Select
              onChange={(value) => setValue(value)}
              value={value}
            >
              {getOptions()}
            </Select>
            <Button
              text={'Restrict'}
              style={{ height: 20, fontSize: 10, marginLeft: 15 }}
              onClick={() => onRestrict(value, true)}
            />
          </SelectBLock>
        )}
      </Wrap>
    )
  }
)

export default RestrictedItems
