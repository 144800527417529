import React, { useState, useEffect } from "react";
import Button from "../../../../../components/common/Button/button";
import { observer } from 'mobx-react-lite'
import { TableStyled } from "./style";
import { useStore } from "../../../../../mobx-store/context";
import { ViewButton } from "../../../Resource/RightSide/style";
import { LockOutlined } from "@ant-design/icons";
import { api } from "../../../../../api";
import { message } from 'antd'
// const serverData = {
//   jurisdictions_column_is_visible: true,
//   differentiator_column_is_visible: false,
//   summary_column_is_visible: true,
//   details_column_is_visible: true,
//   lines_of_business_column_is_visible: false,
//   citations_definitions_column_is_visible: true,
// };

const nameMap = {
    jurisdictions_column_is_visible: "Jurisdictions",
    differentiator_column_is_visible: "Differentiator",
    summary_column_is_visible: "Summary",
    details_column_is_visible: "Details",
    lines_of_business_column_is_visible: "Lines of Business",
    citations_definitions_column_is_visible: "Citations & Definitions",
};

const SettingsTable = observer(({onClose}) => {
    const [settings, setSettings] = useState([]);
    const store = useStore()

    const serverData = store?.crossJursTebleSettings

    useEffect(() => {
        const transformData = () => {
            const transformedSettings = Object.keys(serverData).map((key, index) => ({
                key: String(index + 1),
                name: nameMap[key],
                initialStatus: serverData[key] ? "show" : "hide",
            }));
            setSettings(transformedSettings);
        };
        transformData();
    }, []);

    const handleToggle = (key) => {
        if (key === "1") return;
        setSettings((prevState) =>
            prevState.map((setting) =>
                setting.key === key
                    ? {
                        ...setting,
                        initialStatus: setting.initialStatus === "show" ? "hide" : "show",
                    }
                    : setting
            )
        );
    };

    const updateSettings = () => {
        const updatedData = settings.reduce((result, setting) => {
            const serverKey = Object.keys(nameMap).find(
                (key) => nameMap[key] === setting.name
            );
            result[serverKey] = setting.initialStatus === "show";
            return result;
        }, {});

        api('/user_settings/user_resource_columns/', { ...updatedData }, 'PATCH')
            .then((response) => {
                store.setSingleValue('crossJursTebleSettings', response)
                message.success('Settings Updated Successfully')
                onClose()
            })
            .catch((error) => {
                console.log(error)
            });
    };

    const columns = [
        {
            title: "",
            dataIndex: "name",
            key: "name",
            width: "90%",
        },
        {
            title: "",
            key: "action",
            width: "10%",
            render: (text, record) => {
                if (record.key === "1") {
                    return (
                        <div style={{ width: 50, display: 'flex', justifyContent: 'end' }}>
                            <LockOutlined style={{ paddingRight: 4, paddingTop: 5 }} />
                            <ViewButton onClick={() => handleToggle(record.key)}>
                                {record.initialStatus === "show" ? "Hide" : "Show"}
                            </ViewButton>
                        </div>
                    );
                }
                return (
                    <div style={{ width: 50, display: 'flex', justifyContent: 'end' }}>
                        <ViewButton onClick={() => handleToggle(record.key)}>
                            {record.initialStatus !== "show" ? "Show" : "Hide"}
                        </ViewButton>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <TableStyled
                dataSource={settings}
                columns={columns}
                pagination={false}
                showHeader={false}
            />
            <div style={{ display: "flex", justifyContent: "end", marginTop: 20 }}>
                <Button text="Update Columns" onClick={() => { updateSettings() }} />
            </div>
        </>
    );
});

export default SettingsTable;
