import React, { useState } from 'react'
import { DocumentRight, DocumentWrap } from './style'
import { observer } from 'mobx-react-lite'


const Document = observer(({ item, index, onDeleted }) => {


  const downloadTemplate = () => {
    const link = document.createElement('a')
    link.href = item.file
    link.download = item.file_name
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  return (
    <>
      <DocumentWrap grey={index % 2 === 0}>
        <div>
          {item.file_name}
        </div>
        <DocumentRight>
          <a
            onClick={() => {
              downloadTemplate()
            }}
            target={'_blank'}
            download>
            Download
          </a>
          <div onClick={() => onDeleted(item.id)}>
            Delete
          </div>
        </DocumentRight>
      </DocumentWrap >
    </>
  )
})

export default Document
