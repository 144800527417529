export const vars = {
  name: '',
  maxUsers: 0,
  tariffInfo: null,
  currentTariff: null,
  jurs: null,
  jursMembers: [],
  jursCurrentMember: [],
  topics: [],
  topicsMembers: [],
  topicsCurrentMember: [],
  lineOfBusinesses: [],
  lineOfBusinessesMembers: [],
  lineOfBusinessesCurrentMember: [],
  states: [],
  totalPLan: '',
  company: [],
  legislatives: null,
  legislativesArray: [],
  surveys: [],
  reference_types: [],
  statuses: [],
  members: [],
  membersMentions: [],
  currentLegislative: null,
  user: [],
  comments: [],
  documents: [],
  activity: [],
  member: null,
  addedMembers: [],
  isTrialSelected: false,
  agreement: false,
  registerData: {
    password: null,
    email: null,
  },
  searchableObject: {},
  searchableObjectTasks: {},
  searchableMembersObject: null,
  upgradePro: false,
  legislativeToGo: null,
  legislativesTemporary: {},
  resources: null,
  resourcesTable: null,
  resourcesSelect: null,
  resourceTab: 0,
  resourceFiltered: null,
  resourceFilteredTopics: null,
  resourcesCurrent: null,
  resourcesCurrentTopic: null,
  resourceFilters: null,
  resourceFiltersValues: null,
  resourceTariffs: null,
  guideCategories: null,
  legislativesStatus: 'actual',
  billingCards: null,
  tasks: null,
  legislativeTasks: null,
  resourceTariffInfo: null,
  bills: null,
  resourcePrevLink: [],
  isResourceRegisterStarted: false,
  started_from_resource: false,
  legislative_options: null,
}
