import styled from 'styled-components'

export const Overlay = styled('div')`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Wrap = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1190px;
  height: 80%;
  max-height: 90%;
  background-color: #fff;
  border-radius: 10px;
  z-index: 99999;
  position: fixed;
  z-index: 99999;
  top: 50%;
  left: 50%;
  cursor: default;
  transform: translate(-50%, -50%);
  font-family: var(--regular);
  color: var(--text);
  font-size: 16px;

  @media (max-width: 1300px) {
    width: 990px;
    height: 762px;
  }
  @media (max-width: 1000px) {
    width: 890px;
    height: 662px;
  }
  @media (max-width: 900px) {
    width: 790px;
    height: 662px;
  }

  @media (max-width: 750px) {
    width: 690px;
    height: 612px;
  }

  @media (max-height: 1000px) {
    height: 762px;
  }
  
  @media (max-height: 850px) {
    height: 662px;
  }
`
export const Container = styled("div")`
width: 990px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: start;
// margin-top: 76px;
height: 100%;

@media (max-width: 1300px) {
  width: 790px;
}

@media (max-width: 1000px) {
  width: 690px;
}

@media (max-width: 750px) {
  width: 670px;
}

@media (max-width: 900px) {
  width: 590px;
  margin-top: 56px;
}
`;

export const TitleContainer = styled("div")`
width: 974px;
display: flex;
flex-direction: column;
position: relative;

#close {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  z-index: 9999;
}

@media (max-width: 1300px) {
  width: 783px;
}

@media (max-width: 1000px) {
  width: 723px;
}

@media (max-width: 900px) {
  width: 623px;
}

@media (max-width: 750px) {
  width: 553px;
}
`;
export const Title = styled("div")`
width: 708px;
height: 61px;
margin-bottom: 22px;
font-family: var(--medium);
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 29px;
letter-spacing: 0.25px;

@media (max-width: 1300px) {
  font-size: 22px;
}

@media (max-width: 1000px) {
  width: 608px;
  font-size: 21px;
}

@media (max-width: 750px) {
  width: 508px;
  font-size: 20px;
}
`;

export const TextContainer = styled("div")`
width: 974px;
height: 58%;
overflow-y: scroll;
padding-right: 51px;
padding-top: 12px;
padding-bottom: 12px;
max-height: 80%;

&::-webkit-scrollbar {
  background-color: var(--light-bg);
  width: 8px;
  position: absolute;
}

&::-webkit-scrollbar-thumb {
  background-color: var(--light-blue);
  border-radius: 5px;
  width: 8px;
}


@media (max-width: 1300px) {
  width: 783px;
  height: 477px;
}
@media (max-width: 1000px) {
  width: 723px;
  height: 377px;
}

@media (max-width: 900px) {
  width: 623px;
}

@media (max-width: 750px) {
  width: 553px;

}

@media (max-height: 1000px) {
  height: 477px;
}

@media (max-height: 850px) {
  height: 387px;
}

@media (max-height: 650px) {
  height: 280px;
}
@media (max-height: 530px) {
  height: 200px;
}
`;


export const TextArea = styled("div")`
font-family: var(--medium);
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 29px;
letter-spacing: 0.25px;
max-height: 80%;
`;

export const Text = styled("div")`
  font-size: 14px;
  font-family: var(--regular);
  color: var(--text);
  p {
    margin-top: 20px;
  }
  ol {
    li {
      margin-top: 15px;
      span {
        text-decoration: underline;
      }
    }
  }
`;

export const TextTitle = styled("div")`
  text-transform: uppercase;
  font-family: var(--bold);
  margin-top: 30px;
`;

export const ConfirmContainer = styled("div")`
position: relative;
padding-top: 27px;
width: 974px;
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: ${props => props.isTC ? 'space-between' : 'flex-end'};

div:before{
  position:absolute;
  top:0;
  left:0;
  width:95%;
  border-top:1px solid var(--grey);
  content:"";
}

@media (max-width: 1300px) {
  width: 783px;
}
@media (max-width: 1000px) {
  width: 723px;
}
@media (max-width: 900px) {
  width: 623px;
}
@media (max-width: 750px) {
  width: 553px;
}
`;

export const CheckboxContainer = styled("div")`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
`;

export const CheckboxText = styled("div")`
font-family: var(--medium);
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
margin-left: 10px;
`;
