import { Table } from 'antd'
import styled from 'styled-components'

export const Wrap = styled('div')`
  border-radius: 7px;
  border: 1px solid #c4cacc;
  margin: 20px 0px 20px 0px;
`

export const Header = styled('div')`
  height: 2.813em;
  border-radius: 6px 6px 0 0;
  background: #f4f6f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em;
  border: 1px solid #dcdee1;
`

export const HeaderTitle = styled('div')`
  font-size: 1.125em;
  font-family: var(--medium);
  color: var(--text);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`

export const HeaderButton = styled('div')`
  font-size: 0.75em;
  color: var(--blue);
  text-decoration: underline;
  font-family: var(--open-sans-reg);
  cursor: pointer;

  img {
    max-height: 20px;
    margin-left: 0.8em;
  }
`

export const Status = styled('div')`
  font-size: 1em;
  width: 10px;
  height: 10px;

  div {
    width: 10px;
    height: 10px;
    margin-right: 1em;
    background-color: ${(props) => props.bg};
    border-radius: 100%;
  }
`

export const TableHeaderTitle = styled('div')`
  font-size: 14px;
  line-height: 15px;
  font-family: var(--semiBold);
  color: var(--text);
  text-align: center;
`
export const MonthHeaderTitle = styled('div')`
  font-size: 16px;
  font-family: var(--semiBold);
  color: var(--text);
  text-align: start;
`

export const FirstLetterUppercase = styled('div')`
  &::first-letter {
    text-transform: uppercase;
  }
`

export const TableStyled = styled(Table)`
  .ant-table {
    font-size: 16px;
    line-height: 8px;
    padding: 0px;
  }

  .ant-table-expanded-row {
    td {
      padding: 0;
    }
  }

  .ant-table-thead {
    tr {
      th {
        background-color: #fff !important;
      }
    }
  }

  .ant-table-cell {
    font-size: 12px;
    font-family: var(--semiBold);
    color: var(--text);
    cursor: pointer;
    text-align: center;
    padding: 8px 8px;
  }

  .active-row {
    td {
      color: var(--green);
      background-color: #f5f9ec;
      font-family: var(--open-sans-bold);

      &:first-child {
        border-left: 4px solid var(--green);
      }
    }
  }

  .unread-row {
    td {
      font-family: var(--open-sans-bold);
    }
  }

`

export const Render = styled('div')`
  font-family: ${(props) =>
    props.status === 'unread' && 'var(--open-sans-bold)'};
`
export const Container = styled('div')`
  display: flex;
  justify-content: ${(props) =>
    props.columnType === 'month_name' ? 'start' : 'center'};
`

export const MonthCol = styled('div')`
  display: flex;
  cursor: pointer;
  color: ${(props) =>
    props.disabled ? '#CCCCCC' : props.columnType === 'past_due' ? 'var(--red)' : 'var(--text)'};
  text-align: ${(props) =>
    props.columnType === 'month_name' ? 'start' : 'center'};
    text-decoration: ${(props) => props.columnType === 'month_name' || props.noReports ? 'none' : 'underline'};
`
export const Clear = styled('div')`
margin-left: 10px;
  cursor: pointer;
  color: var(--blue);
  text-align: start;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
export const PastDueCol = styled('div')`
  color: var(--red);
  text-align: center;
`
