import styled from 'styled-components'
import { Text } from '../style'

export const Wrap = styled('div')`
  display: flex;
  align-items: center;

  ${Text} {
    &:first-child {
      font-size: 21px;
      font-family: var(--medium);
    }
  }
`

export const Jurisdiction = styled('div')`
  width: 67px;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-family: var(--medium);
  color: #fff;
  border-radius: 8px;
  background-color: #6594d6;
  margin-right: 11px;
  text-transform: uppercase;
`
