import { useEffect, useState } from "react";
import Modal from "../../../../../../components/common/ModalNew";
import Cookies from 'universal-cookie'
import { Title, SubTitle, Button } from "./style";
import { useForm } from "react-hook-form";
import Input from "../../../../../../components/common/Input";
import { useGA4React } from 'ga-4-react'
import { api } from "../../../../../../api";
import { message } from "antd";
import { useHistory } from "react-router-dom/";
const AddMultipleUsersModal = ({ onClose, name, companyId }) => {
  const {
    control,
    handleSubmit,
    getValues,
    reset
  } = useForm()
  const history = useHistory()
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const ga4React = useGA4React()

  const onUpload = () => {
    setLoading(true)

    ga4React?.gtag('event', 'file_upload')

    let formData = new FormData()

    formData.append('file', file)

    api(`/cms/companies/${companyId}/upload_users_from_csv/`, formData, 'POST', false, true).then((data) => {
      if (!data.errors) {
        setLoading(false)
        reset('file')
        setFile(null)
        message.success('Users uploaded successfully')
        history.push(`/add-multiple-users/${companyId}`)
        onClose()
      } else {
        message.error('Something went wrong')
      }
    })
  }
  const downloadDoc = async (url, name,) => {
    const cookies = new Cookies()
    const csrftoken = cookies.get('csrftoken')
    const token = cookies.get('pbm_token')
    const requestUrl = `${url}`
    const responce = await fetch(requestUrl, {
      method: 'GET',
      headers: {
        Authorization: `Token ${token}`,
        'X-CSRFToken': csrftoken,
        'Content-Type': 'application/json',
      },
    })

    if (responce.status === 200) {
      const blob = await responce.blob()
      const downloadUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = downloadUrl
      link.download = name
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }

  const downloadTemplate = () => {
    api(`/cms/templates/multiple_users_template/`, {}, 'GET', false, true).then((data) => {
      if (!data.errors) {
          const link = document.createElement('a')
          link.href = data.file
          link.download = name
          document.body.appendChild(link)
          link.click()
          link.remove()
        
        // downloadDoc(data.file, 'multiple_users_template.csv')
      }
    })
  }

  return (
    <Modal
      styleWrap={{ width: 500, padding: 36 }}
      onClose={onClose}>
      <Title>Upload Multiple Users for {name}</Title>
      <SubTitle>Use <a onClick={() => downloadTemplate()}>this template</a> to get started.</SubTitle>
      <Input
        labelStyle={{ marginTop: 25 }}
        control={control}
        name='file'
        type='file'
        handleChange={(e) => setFile(e.target.files[0])}
        showFile
        label='Users File'
        smallLabel
      />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 25, alignItems: 'center' }}>
        <div onClick={() => { onClose() }}
          style={{ fontSize: 15, fontFamily: 'var(--regular)', color: 'var(--blue)' }}
        >
          Go Back
        </div>
        <Button onClick={() => onUpload()}>
          Upload
        </Button>
      </div>
    </Modal>
  )
}

export default AddMultipleUsersModal;