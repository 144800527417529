import React, { useEffect } from 'react'
import Benefits from './Benefits'
import Footer from './Footer'
import Header from './Header'
import Slider from './Slider'
import { Wrap } from './styles'

const HomeCompliance = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Wrap>
      <Header />
      <Benefits />
      <Slider />
      <Footer productPage={true} />
    </Wrap>
  )
}

export default HomeCompliance
