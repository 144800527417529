import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../../../components/common/Logo/logo'
import {
  BackButton,
  Container,
  Description,
  Wrap,
} from '../../Agreement/agreement.style'
import { Title } from '../../authForm.style'
import { Image, MiniTitle } from '../../ResetPass/resetPass.style'

const RestrictModal = ({
  description,
  title,
  underTitle,
  showBackButton,
  underTitleStyle,
  img,
  imgStyle,
}) => {
  return (
    <Container>
      <Wrap disableScroll>
        <Logo large />
        <Title
          style={{
            borderBottom: '1px solid rgba(196, 202, 204, 0.6)',
            paddingBottom: '.7em',
          }}
        >
          {title}
        </Title>
        <Image src={img} alt={'email-image'} style={imgStyle} />
        <MiniTitle style={underTitleStyle}>{underTitle}</MiniTitle>
        <Description
          style={{
            fontFamily: 'var(--regular)',
            fontSize: '.875em',
            textAlign: 'center',
          }}
        >
          {description}
        </Description>
        {showBackButton && (
          <BackButton to={'/login'} as={Link}>
            &lt;&lt; {'Return to Sign In'}
          </BackButton>
        )}
      </Wrap>
    </Container>
  )
}

export default RestrictModal
