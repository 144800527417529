import React from 'react'
import { useParams } from 'react-router-dom'
import { PageTitle } from '../../components/common/style'
import PageWrap from '../../components/Container/index'
import TabsNav from '../../components/TabsNav/tabsNav'
import Tab from './tab'

const ManageDictionaries = () => {
  const params = useParams()

  return (
    <PageWrap style={{ height: 'auto' }} >
      <PageTitle style={{ marginBottom: 30 }}>Manage Dictionaries</PageTitle>
      <TabsNav tabs={tabs} active={params.type} />
      <Tab />
    </PageWrap>
  )
}

export default ManageDictionaries

const tabs = {
  topics: {
    link: '/manage-dictionaries/topics',
    button: 'Topics',
  },
  // sub_topics: {
  //   link: '/manage-dictionaries/sub_topics',
  //   button: 'Sub-Topics',
  // },
  lines_of_business: {
    link: '/manage-dictionaries/lines_of_business',
    button: 'Line of Business',
  },
  jurisdiction: {
    link: '/manage-dictionaries/jurisdiction',
    button: 'Jurisdictions',
  },
  report_types: {
    link: '/manage-dictionaries/report_types',
    button: 'Report Types',
  },
  reporting_entities: {
    link: '/manage-dictionaries/reporting_entities',
    button: 'Reporting Entity',
  },

}
