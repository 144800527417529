import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { api } from '../../../../api'
import Button from '../../../../components/common/Button/button'
import { useStore } from '../../../../mobx-store/context'
import { PageTitle } from '../../components/common/style'
import PageWrap from '../../components/Container'
import { Row, UnderTitle } from './style'
import TabsNav from '../../components/TabsNav/tabsNav'
import TrackingTab from './Tabs/trackingTab'
import ResourceTab from './Tabs/resourceTab'
import ProposedTab from './Tabs/proposedTab'
import ReportTab from './Tabs/reportTab'
import LicenseTab from './Tabs/licenseTab'

const EmailSettings = observer(({ site }) => {
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const { handleSubmit, reset } = useForm()
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({})

  const getFilteredItems = () => {
    setLoading(true)
    api(`/users/${store?.user[0]?.id}/customize_emails/`, {}, 'GET').then((data) => {
      if (!data.errors) {
        store.setSingleValue('customize_emails', data)
      }
      setLoading(false)
    })
  }
  const getCustomizeEmails = () => {
    setLoading(true)
    api(`/users/${store?.user[0]?.id}/customize_data/`, {}, 'GET').then((data) => {
      if (!data.errors) {
        store.setSingleValue('customize_data', data)
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    if (!store.user.length) {
      store.getUser()
    } else {
      reset(store.user[0])
    }
  }, [store.user])

  useEffect(() => {
    getFilteredItems()
    getCustomizeEmails()
  }, [])

  if (!store.user.length) return null

  const tab =
    params.type ||
    (store.user[0]?.have_access_to_tracking
      ? 'tracking'
      : store.user[0]?.have_access_to_proposed_source
        ? 'proposedSource'
        : store.user[0]?.have_access_to_report_source
          ? 'reportSource'
          : store.user[0]?.have_access_to_license_source
            ? 'licenseSource'
            : store.user[0]?.have_access_to_resource
              ? 'resource'
              : 'tracking')

  const onSubmit = () => {
    setLoading(true)

    const notificationTypes = {
      tracking: site ? 'compliance_site_notifications' : 'compliance_source_notifications',
      resource: site ? 'resource_site_notifications' : 'resource_notifications',
      reportSource: site ? 'report_source_site_notifications' : 'report_source_notifications',
      licenseSource: site ? 'license_source_site_notifications' : 'license_source_notifications',
      proposedSource: site ? 'proposed_source_site_notifications' : 'proposed_source_notifications'
    };

    const fieldName = notificationTypes[tab] || 'proposed_source_notifications';



    api(`/users/${store.user[0].id}/`, { [fieldName]: state }, 'PATCH').then((data) => {
      setLoading(false)
      if (data.error) {
        console.log('error')
      } else {
        const notificationFields = [
          'compliance_source_notifications',
          'resource_notifications',
          'report_source_notifications',
          'proposed_source_notifications',
          'compliance_site_notifications',
          'resource_site_notifications',
          'report_source_site_notifications',
          'proposed_source_site_notifications',
          'license_source_notifications',
          'license_source_site_notifications'
        ];

        const updatedUser = {
          ...store.user[0],
          ...notificationFields.reduce((acc, field) => {
            acc[field] = data[field];
            return acc;
          }, {})
        };
        store.setSingleValue('user', [updatedUser]);
      }
    })
  }
  const tabs = {
    tracking: {
      button: 'ComplianceSource',
      component: <TrackingTab
        state={state}
        setState={(value) => setState(value)}
        site={site}
      />,
      link: site ? '/site-notifications/tracking' : '/profile-email/tracking',
    },
    resource: {
      button: 'ReSource',
      component: <ResourceTab
        state={state}
        setState={(value) => setState(value)}
        site={site}
      />,
      link: site ? '/site-notifications/resource' : '/profile-email/resource',
    },
    reportSource: {
      button: 'ReportSource',
      component: <ReportTab
        state={state}
        setState={(value) => setState(value)}
        site={site}
      />,
      link: site ? '/site-notifications/reportSource' : '/profile-email/reportSource',
    },
    proposedSource: {
      button: 'ProposedSource',
      component: <ProposedTab
        state={state}
        setState={(value) => setState(value)}
        site={site}
      />,
      link: site ? '/site-notifications/proposedSource' : '/profile-email/proposedSource',
    },
    licenseSource: {
      button: 'LicenseSource',
      component: <LicenseTab
        state={state}
        setState={(value) => setState(value)}
        site={site}
      />,
      link: site ? '/site-notifications/licenseSource' : '/profile-email/licenseSource',
    }
  }



  // useEffect(() => {
  //   setActive(params.type || 'tracking')
  // }, [params.type])

  return (
    <PageWrap>
      <PageTitle> {site ? 'Site Notification Settings' : 'Email Notification Settings'}</PageTitle>
      <UnderTitle>
        {site ? `PBMSource will alert you with notifications  so you don't miss a beat. You can customize which notifications you want to receive, or turn off all notifications.`
          : 'PBMSource can send email notifications so you don’t miss a beat. You can also decide if there are any additional updates you’d like to receive'}

      </UnderTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <NotificationsTime control={control} /> */}
        <TabsNav
          tabs={tabs}
          active={tab}
          onRedirectTo={(page) => history.push(site ? `/site-notifications/${page}` : `/profile-email/${page}`)}
        />
        {tabs[tab].component}
        <Row style={{ paddingBottom: 50 }}>
          <Button text={'Save Preferences'} type={'green'} loading={loading} />
        </Row>
      </form>
    </PageWrap>
  )
})

export default EmailSettings
