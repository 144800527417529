import styled from 'styled-components'

export const Overlay = styled('div')`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
`

export const Wrap = styled('div')`
  position: absolute;
  top: 25px;
  width: 361px;
  left: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  padding: 50px;
  border-radius: 5px;
  margin-top: 35px;
  background-color: #fff;
  z-index: 999;
  display: ${(p) => (p.visible ? 'block' : 'none')};

  img {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }

  .react-calendar {
    width: 100%;
  }
`
export const CalendarText = styled('div')`
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-family: var(--semiBold);
  color: var(--text);
  padding-bottom: 5px;
`

export const Block = styled('div')`
  border-radius: 8px;
  height: 42px;
  padding: 0 11px;
  display: flex;
  align-items: center;
  border: 1px solid
    ${(p) => (p.noBorder ? ' #fff' : p.error ? 'var(--orange)' : '#c4cacc')};
  font-size: 14px;
  color: var(--text);
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  width: 100%;

  &:hover {
    span {
      display: block;
    }
  }

  span {
    position: absolute;
    right: 11px;
    opacity: 0.6;
    display: none;
  }
`
