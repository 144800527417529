import GA4React from 'ga-4-react'
import { CookiesProvider } from 'react-cookie'
import ReactDOM from 'react-dom'
import Favicon from 'react-favicon'
import { hotjar } from 'react-hotjar'
import { BrowserRouter } from 'react-router-dom'
import Cookies from 'universal-cookie'
import App from './App'
import favicon from './assets/images/pbm_favicon_xwm_icon.ico'
import { StoreProvider } from './mobx-store/context'
import reportWebVitals from './reportWebVitals'

const gaKey = window.location.host === 'pbmsource.com' ? 'G-RNSQDKZ8MG' : '123'

const ga4react = new GA4React(gaKey)
const cookies = new Cookies()

hotjar.initialize(2522893, 6)
;(async () => {
  try {
    if (
      window.location.pathname !== '/analytic/hide-me' &&
      !cookies.get('hideMe')
    ) {
      await ga4react.initialize()
    }
  } catch (err) {
    console.log(err)
  }

  ReactDOM.render(
    // <React.StrictMode>
    <>
      <Favicon url={favicon} />
      <BrowserRouter>
        <StoreProvider>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </StoreProvider>
      </BrowserRouter>
    </>,
    // </React.StrictMode>,
    document.getElementById('root')
  )
})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
