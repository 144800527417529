import styled from "styled-components";

export const Row = styled("div")`
  display: flex;
  justify-content: space-between;
`;

export const Wrap = styled("div")`
  margin-top: 30px;
`;
