import Modal from "../../../../../../../components/common/ModalNew"
import { Title, Text } from "./style"
import moment from "moment"
import Button from "../../../../../../../components/common/Button/button"
import { PrevBtn } from "../../../../../CreateCompany/pages/FirstPage/Form/style"
import { Row } from "../../../../../CreateCompany/pages/ManageProducts/style"
const RemoveGroupModal = ({ isOpen, onClose, onRemove, group }) => {

  function extractNames(arr) {
    const namesArray = arr.map(obj => obj.name);
    const namesString = namesArray.join(', ');

    return namesString;
  }
  return (
    <Modal styleWrap={{
      borderRadius: 8,
      padding: 30,
      display: 'flex',
      flexDirection: 'column',
    }}
      onClose={onClose}
    >
      <Title>Delete <span>Portion</span>?</Title>
      <Text>Are you sure you want to delete this portion?</Text>
      <Text>Date Range: {moment(group.date_added).format('YYYY/MM/DD')} - {moment(group.end_date).format('YYYY/MM/DD')}</Text>
      <Text>Guides: {extractNames(group.selected_guides)} </Text>
      <Text style={{ fontFamily: 'var(--bold)', marginTop: 10 }}>This action can not be undone.</Text>
      <div style={{ marginTop: 40, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center' }}>
        <PrevBtn style={{ marginTop: 7, textDecoration: 'none' }} onClick={() => onClose()}>Go Back</PrevBtn>
        <Button
          text={'Delete Portion'}
          onClick={() => onRemove()}
          type={'main'}
          style={{

            padding: '10px 10px',
            marginRight: 0,
            marginLeft: '.5em',

          }}
        />
      </div>
    </Modal>
  )
}

export default RemoveGroupModal