import styled from 'styled-components'

export const Grid = styled('div')`
display: grid;
grid-template-columns: ${(props) => props.team ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr'};
gap: ${(props) => props.team ? '40px' : '27px'};
margin-top: 70px;
@media (max-width: 700px) {
  grid-template-columns: 1fr;
}
`