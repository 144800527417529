import { SubTitle, CompanyDescription } from '../FirstPage/style'
import { useState, useEffect } from 'react';
import { PageWrap } from './style';
import ComplianceTab from './Tabs/Compliance'
import ProposedTab from './Tabs/Proposed'
import ResourceTab from './Tabs/Resource'
import ReportTab from './Tabs/Report'
import UsersTab from './Tabs/Users';
import Tabs from '../../../../../components/common/Tabs/tabs'
import { useStore } from '../../../../../mobx-store/context';
import { api } from '../../../../../api';
import FullScreenLoader from '../../../../../components/common/FullScreenLoader';

const ManageProducts = () => {
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState("compliance");
  const store = useStore()

  const getCmsOptions = () => {
    setLoading(true)
    api(`/cms/dictionaries_data/all/`, {}, 'GET').then((data) => {
      if (!data.errors) {
        store.setSingleValue('cms_options', data)
      }
    }).then(() => {
      getCompanyInfo(localStorage.getItem('cmsCompanyId'))
    })
  }

  const getCompanyInfo = (id) => {
    api(`/cms/companies/${id}/`, {}, 'GET').then((data) => {
      store.setSingleValue('cmsCompany', [data])
      setLoading(false)
    })
  }

  useEffect(() => {
    getCmsOptions()
  }, [])

  const showTabs = (obj) => {
    return {
      compliance: {
        render: () => <ComplianceTab handleTabChange={obj.handleTabChange} loading={obj.loading} active={obj.activeTab} />,
      },
      proposed: {
        render: () => <ProposedTab handleTabChange={obj.handleTabChange} active={obj.activeTab} />,
      },
      resource: {
        render: () => <ResourceTab handleTabChange={obj.handleTabChange} active={obj.activeTab} />,
      },
      report: {
        render: () => <ReportTab handleTabChange={obj.handleTabChange} active={obj.activeTab} />,
      },
      users: {
        render: () => <UsersTab handleTabChange={obj.handleTabChange} active={obj.activeTab} />,
      }
    }
  }

  const servicesMap = {
    compliance: 'ComplianceSource',
    proposed: 'ProposedSource',
    resource: 'ReSource',
    report: 'ReportSource',
    users: 'Users',
  }

  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  return (
    <PageWrap style={{ width: 660 }}>
      {loading ? <FullScreenLoader />
        : <>
          <SubTitle style={{ fontSize: 24, marginTop: 30 }}>Add New Company - {servicesMap[activeTab]}</SubTitle>
          <CompanyDescription>
            <div>Company Name - {store?.cmsCompany[0]?.name || 'N/A'}</div>
            <div>{store?.cmsCompany[0]?.address1}</div>
          </CompanyDescription>
          <SubTitle>Manage:</SubTitle>
          <>
            <Tabs
              Reporting={false}
              tabs={[
                {
                  key: 'compliance',
                  title: 'ComplianceSource',
                },
                {
                  key: 'proposed',
                  title: 'ProposedSource',
                },
                {
                  key: 'resource',
                  title: 'ReSource',
                },
                {
                  key: 'report',
                  title: 'ReportSource',
                },
                {
                  key: 'users',
                  title: 'Users',
                },

              ]}
              onClick={(key) => {
                setActiveTab(key)
              }}
              active={activeTab}
            />

            {showTabs({
              handleTabChange,
              loading,
              activeTab,
            })[activeTab].render()
            }
          </>
        </>
      }
    </PageWrap>
  );
};

export default ManageProducts;