import React, { useEffect } from 'react'
import { Container, Grid } from './style'
import Menu from './menu'

const Wraper = ({ children, showAdding, gridStyle, style, modalOpen = false }) => {

  useEffect(() => {
    document.title = 'PBMSource - Settings';
  }, []);

  return (
    <>
      <Container modalOpen={modalOpen} style={style}>
        <Grid style={gridStyle}>
          <Menu/>
          <div style={{ paddingLeft: '25px' }}>{children}</div>
        </Grid>
      </Container>
    </>
  )
}

export default Wraper