
import GuideItem from './guideItem'
import { ListTitle, ListContainer } from '../style'
const GuidesList = ({ guides, selectedGuideId, setActiveGuide }) => {
  return (
    <div style={{ width: '80%', margin: '0 auto', marginBottom: 45, }}>
      <ListTitle>All Current Available ReSource Guides</ListTitle>
      <ListContainer >
        {guides.map((guide) => (
          <GuideItem guide={guide} key={guide.guide_id} id={guide.guide_id} targetId={selectedGuideId} setActiveGuide={setActiveGuide} />
        ))}
      </ListContainer>
    </div>

  );
};

export default GuidesList;