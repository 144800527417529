import React from 'react'
import { Block, Resource } from './dashboard.style'
import { Header, HeaderTitle } from '../Tracking/Table/table.style'

const Resources = () => {
  return (
    <Block style={{ marginTop: "2em", paddingBottom: "1em" }}>
      <Header
        style={{
          marginBottom: "1em",
        }}
      >
        <HeaderTitle>Other Resources</HeaderTitle>
      </Header>
      <Resource>
        <div>Medicaid</div>
        <div>AL</div>
        <span>medicaid.alabama.gov</span>
      </Resource>
      <Resource>
        <div>Medicaid</div>
        <div>TX</div>
        <span>hhs.texas.gov/services/health/medicaid-chip</span>
      </Resource>
      <Resource>
        <div>Medicaid</div>
        <div>OK</div>
        <span>okhca.org</span>
      </Resource>
      <Resource>
        <div>Medicaid</div>
        <div>LA</div>
        <span>ldh.la.gov/index.cfm/subhome/1</span>
      </Resource>
    </Block>
  );
};

export default Resources;
