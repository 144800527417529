import { Popover } from 'antd'
import questionIcon from '../../../../assets/images/question.svg'
import { OptionTitle, Premium } from '../pricing.style'
import React from 'react'
import Switch from '../Switch/switch'
import user from '../../../../assets/images/user-plans.svg'

const teamMembers = (maxUsers) => <>
  <div style={{ display: 'flex' }}>
    <img src={user} style={{ marginRight: 5 }} alt="user"/>
    {maxUsers}
  </div>
  {/*<div*/}
  {/*  style={{*/}
  {/*    fontSize: 14,*/}
  {/*    fontStyle: 'italic',*/}
  {/*    fontFamily: 'var(--light)',*/}
  {/*    color: 'rgba(69, 81, 84, 0.7)',*/}
  {/*  }}*/}
  {/*>*/}
  {/*  + $59/mo per additional <br/> team member*/}
  {/*</div>*/}
</>

export const leftSide = [

  {
    key: 0,
    includes: 'all',
    content: () => 'Identification of new laws, rules, sub-regulatory guidance',
  },
  {
    key: 1,
    includes: 'all',
    content: () => 'Comprehensive summaries',
    style: { height: 100 },
  },
  {
    key: 2,
    includes: 'all',
    content: () => 'Compliance status dashboard',

  },
  {
    key: 3,
    includes: 'all',
    content: () => 'Automated compliance status tracking',
    style: { height: 120 },
  },
  {
    key: 4,
    includes: 'all',
    content: () => 'Receive alerts',
  },
  {
    key: 5,
    includes: 'all',
    content: () => 'Assign to team members',
  },
  {
    key: 6,
    includes: 'all',
    content: () => 'Collaborate with team members',
  },
  {
    key: 7,
    includes: 'all',
    content: () => 'Central comment repository',
  },
  {
    key: 8,
    includes: 'all',
    content: () => 'Upload compliance documentation',
    style: { height: 130 },
  },
  {
    key: 9,
    includes: 'all',
    content: () => 'Collaborate with team members',
  },
  {
    key: 10,
    includes: 'all',
    content: () => 'Activity notifications',
  },
  {
    key: 11,
    includes: 'all',
    content: () => 'History tracking',
  },
  {
    key: 12,
    includes: 'all',
    content: () => 'Sort/Search functionality by topic, jurisdiction, compliance date, reference number, text',
  },
  {
    key: 13,
    content: (obj) =>
      <OptionTitle>
        Topics included
        <Popover
          placement="rightTop"
          content={obj.topicsContent}
          trigger="click"
          overlayStyle={{
            width: '240px',
          }}
        >
          <img src={questionIcon} alt="question-icon"/>
        </Popover>
      </OptionTitle>,
    style: { height: 60 },
    topic_tract: () => 'ALL',
    juris_tract: () => 'ONE',
    total_tract: () => 'ALL',
    select_tract: () => <div style={{ fontSize: 14 }}>$199/topic</div>,
  },
  {
    key: 14,
    content: (obj) =>
      <OptionTitle>
        Jurisdictions included
        <Popover
          placement="rightTop"
          content={obj.jurisContent}
          trigger="click"
          overlayStyle={{
            width: '220px',
          }}
        >
          <img src={questionIcon} alt="question-icon"/>
        </Popover>
      </OptionTitle>,
    style: { height: 60 },
    topic_tract: () => 'ONE',
    juris_tract: () => 52,
    total_tract: () => 52,
    select_tract: () => <div style={{ fontSize: 14 }}>$199/jurisdiction</div>,

  },
  {
    key: 15,
    content: () => 'Team Members Included',
    topic_tract: () => teamMembers('Unlimited'),
    juris_tract: () => teamMembers('Unlimited'),
    total_tract: () => teamMembers('Unlimited'),
    select_tract: () => teamMembers('Unlimited'),

  },
  {
    key: 16,
    content: (obj) => (
      <>
        <div
          style={{
            backgroundColor: 'var(--blue)',
            width: '100%',
            paddingLeft: 15,
            borderTopLeftRadius: 4,
          }}
        >
          Add Lines of Business
          <Popover
            placement="rightBottom"
            content={obj.premiumContent}
            trigger="click"
            overlayStyle={{
              width: '240px',
            }}
          >
            <img
              style={{ position: 'absolute', right: '3px', top: '3px' }}
              src={questionIcon}
              alt="question-icon"
            />
          </Popover>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 10,
          }}
        >
          <div
            style={{
              fontFamily: 'var(--regular)',
              fontSize: '.875em',
              color: 'var(--blue)',
              paddingLeft: 15,
            }}
          >
            Need to know applicable <br/> lines of business?
          </div>
          <Switch
            style={{ marginRight: '1em' }}
            checked={obj.premium}
            onChange={(premium) => {
              obj.setPremium(premium)
            }}
          />
        </div>
      </>
    ),
    topic_tract: (item) => (
      <Premium
        style={{
          borderRight:
            item.key === 3
              ? '1px solid rgba(34, 81, 148, 0.8)'
              : 0,
        }}
      >
        {item.planName} {item.premium}
      </Premium>
    ),
    juris_tract: (item) => (
      <Premium
        style={{
          borderRight:
            item.key === 3
              ? '1px solid rgba(34, 81, 148, 0.8)'
              : 0,
        }}
      >
        {item.planName} {item.premium}
      </Premium>
    ),
    total_tract: (item) => (
      <Premium
        style={{
          borderRight:
            item.key === 3
              ? '1px solid rgba(34, 81, 148, 0.8)'
              : 0,
        }}
      >
        {item.planName} {item.premium}
      </Premium>
    ),
    select_tract: (item) => (
      <Premium
        style={{
          borderRight:
            item.key === 3
              ? '1px solid rgba(34, 81, 148, 0.8)'
              : 0,
        }}
      >
        {item.planName} {item.premium}
      </Premium>
    ),

    style: {
      height: '88px',
      position: 'relative',
      marginTop: 40,
      borderTop: 0,
      borderBottomLeftRadius: 8,
      borderTopLeftRadius: 8,
      backgroundColor: '#fff',
      display: 'block',
      paddingLeft: 0,
      border: '1px solid var(--blue)',
    },
    styleOption: {
      marginTop: 36,
      height: 93,
      borderTop: 0,
    },
  },
]
