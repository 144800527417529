
import Search from './search'
import { PageWrap } from './style'
import CompaniesTable from '../CompaniesTable'
import Wraper from '../CMSContainer';
const DashboardLanding = () => {

  return (
    <Wraper>
      <PageWrap>
        <Search />
        <CompaniesTable />
      </PageWrap>
    </Wraper>
  );
};

export default DashboardLanding;