import styled from 'styled-components'

export const TopLine = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #c4cacc;
  margin-bottom: 20px;
`

export const Title = styled('div')`
  font-family: var(--semiBold);
  font-size: 24px;
  color: var(--blue);
`
export const MaterialsGroupWrap = styled('div')`
margin-bottom: 20px;
padding: 15px 15px;
border: 1px solid #c4cacc;
border-radius: 6px;
`
export const Wrap = styled('div')`
  padding-left: 1.8em;
  padding-top: 50px; 
  padding-right: 2em;
  // margin-bottom: 50px;
  max-height: 74vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    position: absolute;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: var(--grey);
    border-radius: 5px;
    width: 8px;
  }

  @media (max-width: 1350px) {
    padding-left: 0;
    padding-top: 2em;
    padding-right: 2em;
  }
  @media (max-height: 1200px) {
    max-height: 76vh;
  }
  @media (max-height: 1050px) {
    max-height: 73vh;
  }
  @media (max-height: 915px) {
    max-height: 66vh;
  }
  @media (max-height: 880px) {
    max-height: 69vh;
  }
  @media (max-height: 800px) {
    max-height: 65vh;
  }
  @media (max-height: 750px) {
    max-height: 65vh;
  }

`