
import { Wrap } from './style'
import Button from '../../../components/common/Button/button'
import Input from '../../../components/common/Input'
import { useForm } from 'react-hook-form'
import { useStore } from '../../../mobx-store/context'
import { AddButton } from './style'
import { useHistory } from 'react-router-dom'

const Search = () => {
  const store = useStore()
  const {
    control,
    handleSubmit,
    getValues,
  } = useForm()

  const history = useHistory()

  const onSubmit = () => { store.setSingleValue('companiesSearch', getValues('name')) }

  return (
    <Wrap >
      <div style={{ display: 'flex', marginRight: 90 }}>
        <Input
          style={{ marginRight: 15 }}
          control={control}
          name='name'
          placeholder='Search by Company Name'
          validation={{ validate: () => true }}
          handleClear={handleSubmit(onSubmit)}
        />
        <Button
          text='Search'
          style={{ marginLeft: 16 }}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
      <AddButton onClick={() => {
        history.push('/create-new-company')
        store.setSingleValue('cmsCompanyCreatingMode', true)
      }}
      >+Add New Company</AddButton>
    </Wrap>
  );
};

export default Search; 