import styled from 'styled-components'

export const Item = styled('div')`
  font-size: 16px;
  color: #304659;
 font-family: var(--regular);
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
  
  border-bottom: 1px solid #C4CACC;

`
export const DeleteCover = styled('div')`
display: flex; 
align-items: center;
&:hover {
  text-decoration: underline;
  cursor: pointer;
} 

`
