import { useEffect } from "react";
import Modal from "../../../../../components/common/ModalNew";
import { SubTitle, Title } from "./style";
import { useForm } from "react-hook-form";
import { Checkbox } from "antd";
import { useState } from "react";
import CheckboxGroup from "../../../CreateCompany/pages/ManageProducts/CheckboxGroup";
import { useStore } from "../../../../../mobx-store/context";
import { DetailsWrap, TableTitle, AllCheckboxContainer } from "../../../CreateCompany/pages/ManageProducts/style";
import { api } from "../../../../../api";
const CustomizeUserModal = ({ onClose, service, user, getTempUsers }) => {

  const {  control, getValues, watch } = useForm()
  const store = useStore()
  const [allJurisdictions, setAllJurisdictions] = useState(user.all_jurisdictions)
  const [allTopics, setAllTopics] = useState(user.all_topics)
  const [allLinesOfBusiness, setAllLinesOfBusiness] = useState(user.all_line_of_business)
  const [allTypes, setAllTypes] = useState(user.all_report_source_report_types)
  const [allGuides, setAllGuides] = useState(user.all_resource_guides)
  const [jursCount, setJursCount] = useState(user.jurisdictions?.length || 0)
  const [topicsCount, setTopicsCount] = useState(user.topics?.length || 0)
  const [proposedJursCount, setProposedJursCount] = useState(user.proposed_source_jurisdictions?.length || 0)
  const [resourceJursCount, setResourceJursCount] = useState(user.resource_jurisdictions?.length || 0)
  const [guidesCount, setGuidesCount] = useState(user.resource_guides?.length || 0)
  const [reportJursCount, setReportJursCount] = useState(user.report_source_jurisdictions?.length || 0)
  const [typesCount, setTypesCount] = useState(user.report_source_report_types?.length || 0)
  const [reportLinesOfBusinessCount, setReportLinesOfBusinessCount] = useState(user.report_source_line_of_business?.length || 0)
  const [linesOfBusinessCount, setLinesOfBusinessCount] = useState(user.line_of_business?.length || 0)
  const [allReportJurisdictions, setAllReportJurisdictions] = useState(user.all_report_source_jurisdictions)
  const [allReportLinesOfBusiness, setAllReportLinesOfBusiness] = useState(user.all_report_source_line_of_business)
  const [allResourceJurisdictions, setAllResourceJurisdictions] = useState(user.all_resource_jurisdictions)
  const [allProposedJurisdictions, setAllProposedJurisdictions] = useState(user.all_proposed_source_jurisdictions)
  const watchValues = watch();

  const updateUser = (values) => {
    const data = service === 'compliance'
      ? {
        ...values,
        all_jurisdictions: allJurisdictions,
        all_topics: allTopics,
        all_line_of_business: allLinesOfBusiness,
      }
      : service === 'proposed'
        ? {
          ...values,
          all_proposed_source_jurisdictions: allProposedJurisdictions,
        }
        : service === 'resource'
          ? {
            ...values,
            all_resource_jurisdictions: allResourceJurisdictions,
            all_resource_guides: allGuides,
          }
          : {
            ...values,
            all_report_source_jurisdictions: allReportJurisdictions,
            all_report_source_report_types: allTypes,
            all_report_source_line_of_business: allReportLinesOfBusiness,
          }

    api(`/cms/intermediate_users/${user.id}/`, data, 'PATCH').then((res) => {
      if (!res.errors)
        getTempUsers()
    })
  }

  const onSubmit = (values) => {
    updateUser(values)
  }

  const serviceName = service === 'compliance'
    ? 'ComplianceSource'
    : service === 'proposed'
      ? 'ProposedSource'
      : service === 'resource'
        ? 'ReSource'
        : 'ReportSource'

  const subtitleText = service === 'compliance'
    ? <>Select Jurisdictions, Topics or Lines of Business for this user. <br />Choices will be limited to what the company has access to:</>
    : service === 'proposed'
      ? <>Select Jurisdictions for this user.<br />Choices will be limited to what the company has access to:</>
      : service === 'resource'
        ? <>Select Jurisdictions, Guides or Lines of Business for this user.<br />Choices will be limited to what the company has access to:</>
        : <>Select Jurisdictions, Report Types or Lines of Business for this user. <br />Choices will be limited to what the company has access to:</>

  const content = service === 'compliance' ?
    <>
      <DetailsWrap style={{ marginTop: 20 }}>
        <TableTitle>Jurisdictions ({allJurisdictions ? 'All' : jursCount})</TableTitle>
        <TableTitle>Topics ({allTopics ? 'All' : topicsCount})</TableTitle>
        <TableTitle>Lines of Business ({allLinesOfBusiness ? 'All' : linesOfBusinessCount})</TableTitle>
      </DetailsWrap>
      <DetailsWrap>
        <AllCheckboxContainer>
          <div> All Jurisdictions </div>
          <Checkbox
            onChange={(e) => setAllJurisdictions(e.target.checked)}
            name={'all_jurisdictions'}
            style={{ marginLeft: 0, marginRight: 15 }}
            checked={allJurisdictions}>
          </Checkbox>

        </AllCheckboxContainer>
        <AllCheckboxContainer>
          <div> All Topics </div>
          <Checkbox
            onChange={(e) => setAllTopics(e.target.checked)}
            name={'all_topics'}
            style={{ marginLeft: 0, marginRight: 6 }}
            checked={allTopics}>
          </Checkbox>

        </AllCheckboxContainer>
        <AllCheckboxContainer>
          <div> All Lines Of Business</div>
          <Checkbox
            onChange={(e) => setAllLinesOfBusiness(e.target.checked)}
            name={'all_line_of_business'}
            checked={allLinesOfBusiness}
            style={{ marginLeft: 0, marginRight: 8 }}>
          </Checkbox>

        </AllCheckboxContainer>
      </DetailsWrap>
      {(!allJurisdictions || !allTopics || !allLinesOfBusiness) &&
        <DetailsWrap >
          <CheckboxGroup
            control={control}
            name={'jurisdictions'}
            options={store?.cms_company_options.jurisdictions}
            disabled={allJurisdictions}
            defaultValue={user.jurisdictions}
          />
          <CheckboxGroup
            control={control}
            name={'topics'}
            options={store?.cms_company_options.topics}
            disabled={allTopics}
            defaultValue={user.topics}
          />
          <CheckboxGroup
            control={control}
            name={'line_of_business'}
            options={store?.cms_company_options.line_of_business}
            disabled={allLinesOfBusiness}
            defaultValue={user.line_of_business}
          />
        </DetailsWrap>
      }
    </> : service === 'proposed'
      ? <>
      <DetailsWrap style={{ marginTop: 20, gridTemplateColumns: '1fr' }}>
        <TableTitle>Jurisdictions ({allProposedJurisdictions ? 'All' : proposedJursCount})</TableTitle>
      </DetailsWrap>
        <DetailsWrap style={{ gridTemplateColumns: '1fr' }}>
          <AllCheckboxContainer style={{ gridTemplateColumns: '82% 18%' }}>
            <div> All Jurisdictions </div>
            <Checkbox
              onChange={(e) => setAllProposedJurisdictions(e.target.checked)}
              name={'all_proposed_jurisdictions'}
              style={{ marginLeft: 0, }}
              checked={allProposedJurisdictions}
            >
            </Checkbox>
          </AllCheckboxContainer>
        </DetailsWrap>
        {(!allProposedJurisdictions) &&
          <DetailsWrap style={{ gridTemplateColumns: '1fr' }}>
            <CheckboxGroup
              control={control}
              name={'proposed_source_jurisdictions'}
              options={store?.cms_company_options.jurisdictions}
              disabled={allProposedJurisdictions}
              defaultValue={user.proposed_source_jurisdictions}
            />
          </DetailsWrap>
        }
      </> : service === 'report'
        ? <>
        <DetailsWrap style={{ marginTop: 20 }}>
        <TableTitle>Jurisdictions ({allReportJurisdictions ? 'All' : reportJursCount})</TableTitle>
        <TableTitle>Types ({allTypes ? 'All' : typesCount})</TableTitle>
        <TableTitle>Lines of Business ({allReportLinesOfBusiness ? 'All' : reportLinesOfBusinessCount})</TableTitle>
      </DetailsWrap>
          <DetailsWrap>
            <AllCheckboxContainer>
              <div>All Jurisdictions</div>
              <Checkbox
                checked={allReportJurisdictions}
                onChange={(e) => setAllReportJurisdictions(e.target.checked)}
                name={'all_report_jurisdictions'}
                style={{ marginLeft: 0, marginRight: 16 }}
              />
            </AllCheckboxContainer>
            <AllCheckboxContainer>
              <div>All Types</div>
              <Checkbox
                checked={allTypes}
                onChange={(e) => setAllTypes(e.target.checked)}
                name={'all_types'}
                style={{ marginLeft: 0, marginRight: 15 }} />
            </AllCheckboxContainer>
            <AllCheckboxContainer>
              <div> All Lines of Business</div>
              <Checkbox
                checked={allReportLinesOfBusiness}
                onChange={(e) => setAllReportLinesOfBusiness(e.target.checked)}
                name={'all_line_of_business'}
                style={{ marginLeft: 0, marginRight: 9 }} />
            </AllCheckboxContainer>
          </DetailsWrap>
          {(!allReportJurisdictions || !allTypes || !allReportLinesOfBusiness) &&
            <DetailsWrap >
              <CheckboxGroup
                defaultValue={user.report_source_jurisdictions}
                control={control}
                name={'report_source_jurisdictions'}
                options={store?.cms_company_options.report_source_jurisdictions}
                disabled={allReportJurisdictions} />
              <CheckboxGroup
                defaultValue={user.report_source_report_types}
                control={control}
                name={'report_source_report_types'}
                options={store?.cms_company_options.report_source_report_types}
                disabled={allTypes} />
              <CheckboxGroup
                defaultValue={user.report_source_line_of_business}
                control={control}
                name={'report_source_line_of_business'}
                options={store?.cms_company_options.report_source_line_of_business}
                disabled={allReportLinesOfBusiness} />
            </DetailsWrap>
          }
        </>
        : <>
         <DetailsWrap style={{ marginTop: 20, gridTemplateColumns: '1fr 1fr' }}>
        <TableTitle>Jurisdictions ({allResourceJurisdictions ? 'All' : resourceJursCount})</TableTitle>
        <TableTitle>Guides ({allGuides ? 'All' : guidesCount})</TableTitle>
      </DetailsWrap>
          <DetailsWrap style={{ gridTemplateColumns: '1fr 1fr' }}>
            <AllCheckboxContainer>
              <div> All Jurisdictions </div>
              <Checkbox
                onChange={(e) => setAllResourceJurisdictions(e.target.checked)}
                name={'all_resource_jurisdictions'}
                style={{ marginLeft: 0, marginRight: 43 }}
                checked={allResourceJurisdictions}
              >
              </Checkbox>
            </AllCheckboxContainer>
            <AllCheckboxContainer>
              <div> All Guides </div>
              <Checkbox
                onChange={(e) => setAllGuides(e.target.checked)}
                name={'all_resource_guides'}
                style={{ marginLeft: 0, marginRight: 5 }}
                checked={allGuides}
              >
              </Checkbox>
            </AllCheckboxContainer>
          </DetailsWrap>
          {(!allResourceJurisdictions || !allGuides) &&
            <DetailsWrap style={{ gridTemplateColumns: '1fr 1fr' }}>
              <CheckboxGroup
                defaultValue={user.resource_jurisdictions}
                control={control}
                name={'resource_jurisdictions'}
                disabled={allResourceJurisdictions}
                options={store?.cms_company_options.resource_jurisdictions} />
              <CheckboxGroup
                defaultValue={user.resource_guides}
                control={control}
                name={'resource_guides'}
                disabled={allGuides}
                options={store?.cms_company_options.resource_guides} />
            </DetailsWrap>
          }
        </>

useEffect(() => {
  setReportJursCount((getValues('report_source_jurisdictions') && getValues('report_source_jurisdictions').length) || 0)
  setTypesCount((getValues('report_source_report_types') && getValues('report_source_report_types').length) || 0)
  setReportLinesOfBusinessCount((getValues('report_source_line_of_business') && getValues('report_source_line_of_business').length) || 0)
  setResourceJursCount((getValues('resource_jurisdictions') && getValues('resource_jurisdictions').length) || 0)
  setGuidesCount((getValues('resource_guides') && getValues('resource_guides').length) || 0)
  setProposedJursCount((getValues('proposed_source_jurisdictions') && getValues('proposed_source_jurisdictions').length) || 0)
  setJursCount((getValues('jurisdictions') && getValues('jurisdictions').length) || 0)
  setTopicsCount((getValues('topics') && getValues('topics').length) || 0)
  setLinesOfBusinessCount((getValues('line_of_business') && getValues('line_of_business').length) || 0)
}, [watchValues]);

useEffect(() => {
  updateUser(getValues())
}, [ allJurisdictions, allTopics, allLinesOfBusiness, allGuides, allTypes, allReportJurisdictions, allReportLinesOfBusiness, allResourceJurisdictions, allProposedJurisdictions,
  watch('jurisdictions'), watch('topics'), watch('line_of_business'),
  watch('proposed_source_jurisdictions'),
  watch('report_source_jurisdictions'), watch('report_source_report_types'), watch('report_source_line_of_business'),
  watch('resource_jurisdictions'), watch('resource_guides')
])

  return (
    <Modal onClose={onClose}>
      <Title>
        Select {serviceName} Permissions for <br />{user?.first_name} {user?.last_name}
      </Title>
      <SubTitle>
        {subtitleText}
      </SubTitle>
      {content}
    </Modal>
  );
};

export default CustomizeUserModal;