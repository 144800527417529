import React from 'react'

const InputEmail = ({
  value,
  handleChange,
  placeholder,
  disabled,
  onChange,
  onBlur,
  onFocus,
}) => {
  return <input
    type={'email'}
    onChange={(e) => {
      handleChange && handleChange(e.target.value)
      onChange(e)
    }}
    onBlur={onBlur}
    onFocus={onFocus}
    value={value || ''}
    placeholder={placeholder}
    disabled={disabled}
  />
}

export default InputEmail
