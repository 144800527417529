import React from 'react'
import { Title } from '../../authForm.style'
import { Text } from './style'
import Button from '../../../../components/common/Button/button'
import { TryFree } from '../billing.style'
import { useHistory } from 'react-router-dom'

const NoPayment = ({ isValid, onSubmit, setError }) => {
  const history = useHistory()

  const onClick = () => {
    // if (!isValid) return null

    onSubmit()

    // history.push('/tracking')
  }

  return (
    <div>
      <Title style={{ margin: 0, marginBottom: '15px' }}>
        Call To Complete Billing
      </Title>
      <Text>
        Contact Us 24/7 <br/>
        Phone: 888-684-1391
        <br/>
        Email: contact@pbmsource.com
      </Text>
      <Button
        text={'Start Your Free Trial'}
        type={'green'}
        border
        style={{ width: '100%' }}
        onClick={onClick}
      />
      <TryFree>
        Try free today and gain access to 5 items in the tracker!
      </TryFree>
    </div>
  )
}

export default NoPayment
