import styled from 'styled-components'

export const Wrap = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 445px;
`

export const Item = styled('div')`
  width: 100%;
  height: 3px;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  background-color: ${(props) =>
  props.index <= props.step ? 'var(--green)' : '#c4c4c4'};
  &:after {
    content: "";
    position: absolute;
    right: -10px;
    width: 10px;
    height: 3px;
    background-color: ${(props) =>
  props.index < props.step - 1 ? 'var(--green)' : '#fff'};
  }
  &:last-child {
    margin-right: 0;
  }
  &:first-child {
    margin-left: 0;
  }
`
