import React, { useEffect, useState } from 'react'
import { Block, DocumentsPlaceholder, Label } from './style'
import { observer } from 'mobx-react-lite'

import Document from './document'


const Documents = observer(({ reportId, files, deleteFile }) => {


  return (
    <Block style={{ marginTop: '1em', }} bordered>
      <Label>Files</Label>
      {files.length <= 0 && (
        <DocumentsPlaceholder>
          There are no documents associated with this item yet.
        </DocumentsPlaceholder>
      )}
      {files.map((item, index) => (
        <Document
          item={item}
          index={index}
          key={item?.id}
          onDeleted={(id) => {
            deleteFile(id)
          }}
          reportId={reportId}
        />
      ))}
    </Block>
  )
})

export default Documents
