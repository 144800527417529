import {
  BlockWrap,
  ImageBlock,
  BlockTitle,
  BlockDescription,
  BlockContent,
  BlockSignUpButton
} from '../style'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { api } from '../../../api'
import { useStore } from '../../../mobx-store/context'
import  newLabel  from '../../../assets/images/new_large_label.png'
import ScheduleDemoModal from '../../Auth/ScheduleDemoModal'

export const ServiceBlock = ({
  number,
  name,
  itemNew,
  description,
  image,
  color,
  link,
  checked,
  signUpText,
  disabled }) => {
  const history = useHistory()
  const store = useStore()
  const [showModal, setShowModal] = useState(false)

  const onClick = () => {
    if (disabled) {
      return
    }
    checked ?
      api('/user_settings/user_memorized_tool/',
        { 'memorized_tool': name.toLowerCase() + '_source' },
        'POST').then((data) => {
          if (!data.errors) {
            store.getUser()
            history.push(link)
          }
        })
      : history.push(link)
  }

  return (
    <>
      {showModal && <ScheduleDemoModal service={name} onClose={() => setShowModal(false)} />}
      <BlockWrap
        number={number}
        onClick={onClick}
        name={name + ' Source'}
      >
        <ImageBlock disabled={disabled}>
          <img src={image} alt={{ name }} />
        </ImageBlock>
        <BlockContent>
          <BlockTitle color={color} disabled={disabled}>
            <strong>{name}</strong>Source
            {itemNew && <img style={{marginLeft: 10}} src={newLabel} alt={'new label'} />}
          </BlockTitle>
          <BlockDescription disabled={disabled}>{description}</BlockDescription>
          {disabled && <BlockSignUpButton
            onClick={() => {
              if (signUpText === 'Reach Out To Sales') {
                setShowModal(true)
              } else {
                history.push('/resource/options')
              }
            }}>
            {signUpText}
          </BlockSignUpButton>}
        </BlockContent>
      </BlockWrap>
    </>

  )
}