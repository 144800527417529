import React, { useState } from 'react'

const useModal = () => {
  const [visible, setVisible] = useState(false);

  const handleClose = () => setVisible(false);

  const handleOpen = () => setVisible(true);

  const handleToggle = () => setVisible(!visible);

  return { visible, handleClose, handleOpen, handleToggle };
};

export default useModal;
