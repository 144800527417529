import styled from 'styled-components'

export const Wrap = styled('div')`
  display: flex;
  font-size: 10px;
  align-items: center;
  font-family: var(--regular);
  letter-spacing: 0.3px;
  select {
    height: 20px;
    border-radius: 4px;
    border: 1px solid #cacfd0;
    margin-left: 10px;
    outline: none;
  }
`
