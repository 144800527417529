import React from 'react'
import Employees from './RightSide'
import { Wrap, Container, Image } from './styles'
import image from '../../../../assets/images/list_group.png'

const Team = () => {
  return (
    <Wrap>
      <Container style={{ marginTop: 60, marginBottom: 90 }}>
        <Employees/>
      </Container>
      <Image src={image} alt='listGroup'/>
    </Wrap>
  )
}

export default Team