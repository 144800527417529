import styled from 'styled-components'

export const Wrap = styled("div")`
  background-color: #f4f7fa;
  padding: 80px 0;
`;

export const ImageWrap = styled("div")`
  width: 528px;
  height: 528px;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    width: 100%;
  }
`;

export const Row = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;

  p {
    margin-top: 30px;
  }
`;

export const Title = styled("div")`
  font-family: var(--thin);
  color: var(--blue);
  font-size: 64px;
  text-align: center;
  width: 100%;
  line-height: 1em;
`;

export const UnderTitle = styled(Title)`
  font-size: 36px;
  font-family: var(--medium);
  font-style: italic;
  margin-top: 10px;
`;

export const MembersListWrap = styled("div")`
  display: flex;
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const MembersList = styled("div")`
  display: flex;
  margin-top: 90px;
  //width: 100000px;
`;

export const Member = styled("div")`
  cursor: pointer;
  width: 200px;
  margin-right: 60px;
  display: ${(props) => props.active && "none"};
  img {
    width: 100%;
    opacity: ${(props) => (props.active ? 1 : 0.6)};
  }
`;

export const Loader = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
`;
