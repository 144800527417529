import { BackTop } from 'antd';
import { VerticalAlignTopOutlined } from '@ant-design/icons'
import { Button } from './styles'

const BackTopButton = () => {
  return (
    <BackTop style={{ bottom: '80px' }}>
      <Button>
        <VerticalAlignTopOutlined
          style={{
            color: '#fff',
            fontSize: 24
          }} />
      </Button>
    </BackTop>
  )
}

export default BackTopButton