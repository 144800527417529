import React, { useEffect, useState } from 'react'
import { Container, Grid } from './style'
import Menu from '../Menu'
import Disclaimer from '../../../../components/common/Disclaimer'

const PageWrap = ({ children, showAdding, gridStyle, style, modalOpen = false }) => {

  const [showDisclaimer, setShowDisclaimer] = useState({ show: false, fadeOut: false });


  useEffect(() => {
    const show = sessionStorage.getItem('showDisclaimer') === 'true';
    setShowDisclaimer({ show: show, fadeOut: !show });
  }, []);

  const handleDisclaimerClose = () => {
    setShowDisclaimer(prev => ({ ...prev, fadeOut: true }));
    setTimeout(() => {
      setShowDisclaimer({ show: false, fadeOut: false });
      sessionStorage.setItem('showDisclaimer', 'false');
    }, 500);
  }

  useEffect(() => {
    document.title = 'PBMSource - Settings';
  }, []);


  return (
    <>
      <Container modalOpen={modalOpen} style={style}>
        <Grid style={gridStyle}>
          <Menu showAdding={showAdding} />
          <div style={{ paddingLeft: '50px' }}>{children}</div>
        </Grid>
      </Container>
      {showDisclaimer.show && <Disclaimer settingsPage={true} onClose={handleDisclaimerClose} fadeOut={showDisclaimer.fadeOut} />}
    </>
  )
}

export default PageWrap
