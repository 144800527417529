import { Empty, message, Tabs } from "antd";
import Modal from "../../common/ModalNew";
import { Title, SubTitle, NotificationArea } from "./style";
import NotificationItem from "./NotifItem";
import AnnouncementItem from "./AnnouncementItem";
import { api } from "../../../api";
import { useEffect, useState } from "react";

const { TabPane } = Tabs;

const NotificationCenterModal = ({ onClose, getNewNotifications }) => {
    const [notificationList, setNotificationList] = useState([]);
    const [mentionNotifications, setMentionNotifications] = useState([]);
    const [announcements, setAnnouncements] = useState([]);
    const [activeTab, setActiveTab] = useState("all");

    const getNotifications = () => {
        api("/notifications/", "GET").then((data) => {
            setNotificationList(data);
        });
    };

    const getMentionNotifications = () => {
        api("/notifications/?filter=mentions", "GET").then((data) => {
            setMentionNotifications(data);
        });
    };

    const getAnnouncements = () => {
        api('/announcement/', 'GET').then((data) => {
            setAnnouncements(data)
        })
    }

    const clearNotifications = (delete_all, id = null) => {
        api('/notifications/clear_notifications/', { delete_all: delete_all, notification_id: id }, 'DELETE').then((res) => {
            if (res.error) {
                message.error(res.error)
            } else {
                if (delete_all) {
                    message.success('All notifications deleted')
                } else {
                    message.success('Notification deleted')
                }
            }
        }).finally(() => {
            getNotifications()
            getMentionNotifications()
        })
    }

    const readAllNotifications = () => {
        api("/notifications/read_notifications/", {}, "POST");
    };

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    useEffect(() => {
        getNotifications();
        readAllNotifications();
        getNewNotifications();
        getMentionNotifications();
        getAnnouncements();
    }, []);

    return (
        <Modal
            styleWrap={{
                right: 0,
                top: "0",
                bottom: "0",
                left: "auto",
                transform: "none",
                height: "100vh",
                maxHeight: "100vh",
                width: 570,
                borderRadius: 0,
                padding: 40,
            }}
            onClose={onClose}
        >
            <Title>Your Latest Alerts</Title>
            <SubTitle>
                Below are your notifications and alerts that you are related to items you are a part of or own.
                <br />
                You can <a style={{ textDecoration: "underline" }} href="/site-notifications">modify your settings</a> here.
                <a style={{ textDecoration: "underline" }} onClick={() => clearNotifications(true)}>
                    Clear all notifications.
                </a>
            </SubTitle>
            <Tabs defaultActiveKey="all" onChange={handleTabChange} type="card" size='large'>
                <TabPane tab="Alerts" key="all" >
                    {activeTab === "all" &&
                        (notificationList.length ? (
                            <NotificationArea>
                                {notificationList.map((item) => (
                                    <NotificationItem
                                        key={item.id}
                                        notification_type={item.notification_type}
                                        name={item.item_name}
                                        text={item.text}
                                        created={item.created}
                                        item_url={item.item_url}
                                        id={item.id}
                                        clearNotifications={clearNotifications}
                                        getNotifications={getNotifications}
                                        getNewNotifications={getNewNotifications}
                                    />
                                ))}
                            </NotificationArea>
                        ) : (
                            <Empty description={"No Notifications"} />
                        ))}
                </TabPane>
                <TabPane tab="@Mentions" key="mentions">
                    {activeTab === "mentions" &&
                        (mentionNotifications.length ? (
                            <NotificationArea>
                                {mentionNotifications.map((item) => (
                                    <NotificationItem
                                        key={item.id}
                                        notification_type={item.notification_type}
                                        name={item.item_name}
                                        text={item.text}
                                        created={item.created}
                                        item_url={item.item_url}
                                        id={item.id}
                                        clearNotifications={clearNotifications}
                                        getNotifications={getNotifications}
                                        getNewNotifications={getNewNotifications}
                                    />
                                ))}
                            </NotificationArea>
                        ) : (
                            <Empty description={"No Mention Notifications"} />
                        ))}
                </TabPane>
                <TabPane tab="Announcements" key="announcements">
                    {activeTab === "announcements" &&
                        (announcements.length ? (
                            <NotificationArea>
                                {announcements.map((item) => (
                                    <AnnouncementItem
                                        title={item.title}
                                        key={item.id}
                                        text={item.text}
                                        created={item.created}
                                        id={item.id}
                                    />
                                ))}
                            </NotificationArea>
                        ) : (
                            <Empty description={"No Mention Notifications"} />
                        ))}
                </TabPane>
            </Tabs>
        </Modal>
    );
};

export default NotificationCenterModal;
