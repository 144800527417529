import React, { useEffect } from 'react'
import { Header, HeaderTitle } from '../Tracking/Table/table.style'
import { Block, Form, FormRow } from './dashboard.style'
import { Select } from 'antd'
import Button from '../../components/common/Button/button'
import { useForm } from 'react-hook-form'
import { useStore } from '../../mobx-store/context'
import { observer } from 'mobx-react-lite'
import Input from '../../components/common/Input'

const Search = observer(() => {
  const store = useStore()
  const { control } = useForm()

  useEffect(() => {
    store.loadSelect('currentMemberOptions')
  }, [])

  return (
    <Block>
      <Header>
        <HeaderTitle>Legislative Tracking</HeaderTitle>
      </Header>
      <Form>
        <Input
          name={'topics'}
          control={control}
          label={'Topic to Search (May select Multiple)'}
          options={
            store.topicsCurrentMember.length && store.topicsCurrentMember
          }
          loading={!store.topics.length}
          transparent
          type={'multiSelect'}
          smallLabel
        />
        <FormRow>
          <Input
            name={'date'}
            control={control}
            label={'Compliance Year'}
            transparent
            type={'date'}
            smallLabel
          />
          <Input
            name={'jurisdictions'}
            control={control}
            label={'Jurisdiction'}
            options={store.jursCurrentMember.length && store.jursCurrentMember}
            showSearch
            loading={!store.jursCurrentMember.length}
            transparent
            type={'multiSelect'}
            smallLabel
          />
        </FormRow>
        <FormRow>
          <Input
            name={'document'}
            control={control}
            label={'Document ID'}
            transparent
            type={'text'}
            placeholder={'###ABCD'}
            smallLabel
          />
          <Input
            name={'status'}
            control={control}
            label={'Current Status'}
            options={
              <>
                <Select.Option value={'unread'}>Unread</Select.Option>
                <Select.Option value={'read'}>Read</Select.Option>
                <Select.Option value={'inProgress'}>In progress</Select.Option>
              </>
            }
            transparent
            type={'select'}
            smallLabel
          />
        </FormRow>
        <Button text={'Search'} style={{ marginTop: '1em' }}/>
      </Form>
    </Block>
  )
})

export default Search
