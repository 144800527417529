import styled from 'styled-components'
import { query500, query800 } from '../../../helpers'
import { DescriptionGlobal } from '../../styles'

export const Wrap = styled('div')`
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    display: block;
  }
  ${DescriptionGlobal} {
    @media${query500} {
      font-size: 12px !important;
    }
  }
`

export const LeftSide = styled('div')`
  width: 527px;
  ${DescriptionGlobal} {
    font-size: 20px;
    margin-top: 30px;
  }
  @media (max-width: 1400px) {
    padding-right: 20px;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media${query800} {
    width: 100%;
  }
`

export const RightSide = styled('div')`
  height: 660px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: var(--orange);
  transition: all 0.2s ease-in-out;
  margin-left: 100px;
  padding-left: 100px;
  position: relative;
  img {
    width: 529px;
    transition: all 0.2s ease-in-out;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  @media (max-width: 1400px) {
    padding-left: 0;
    justify-content: center;
    height: auto;
    padding-top: 50px;
    align-self: flex-end;
    max-width: 49vw;
    img {
      width: 90%;
    }
  }
  @media (max-width: 1024px) {
    height: 500px;
    width: 100vw;
    margin-left: -15px;
    max-width: none;
    margin-top: 15px;
    img {
      width: auto;
      height: 90%;
      object-fit: contain;
      object-position: bottom;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 50vw;
    right: -50vw;
    height: 100%;
    background-color: ${(props) => props.bg};
    @media (max-width: 1050px) {
      display: none;
    }
  }
`

export const Buttons = styled('div')`
  display: flex;
  margin-top: 40px;
`

export const Button = styled('div')`
  width: 44px;
  height: 44px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  opacity: ${(props) => (props.active ? 1 : '.5')};
  background-color: var(--blue);
  font-size: 24px;
  font-family: var(--medium);
  margin-right: 50px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  @media${query800} {
    margin-right: 20px;
  }
`
