import React, { useState } from 'react'
import Button from '../../../../components/common/Button/button'
import { Label } from '../../../../components/common/Input/input.style'
import ModalWrap from '../../../../components/common/ModalNew/index'
import { Text } from '../right.style'
import { Cancel } from '../Tasks/Modal/style'
import Input from '../../../../components/common/Input'
import { Select } from 'antd'
import { useStore } from '../../../../mobx-store/context'
import { checkRole } from '../../../../helpers/helpers'
import { useForm } from 'react-hook-form'
import { toJS } from 'mobx'

const TasksReAssignModal = ({
    isReportSource,
    isLicenseSource,
    assignee,
    onClose,
    onSubmit,
    showCancel = true
}) => {
    const store = useStore()
    const [value, setValue] = useState('')
    const { control } = useForm()

    const members = store.members.filter((item) => {
        return (
            item.is_active && item.id !== +assignee &&
            (isReportSource
                ? item.have_access_to_report_source
                : isLicenseSource
                    ? item.have_access_to_license_source
                    : item.have_access_to_tracking) &&
            checkRole([item.member_type], ['admin', 'compliance_owner', 'owner, report_owner','license_owner'])
        )
    })
        .map((item) => (
            <Select.Option value={item.id} key={item.id}>
                {item.first_name + ' ' + item.last_name}
            </Select.Option>
        ));


    return (
        <ModalWrap onClose={onClose} id={'modal'}>
            <Label>Please assign a new owner</Label>
            <Text style={{ margin: '15px 0' }}>{`Because there are tasks associated with this item, please assign a new ${isReportSource ? 'report' : isLicenseSource ? 'license' : 'compliance'} owner:`}</Text>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, flexDirection: 'column' }}>
                <Input
                    control={control}
                    customOptions={members}
                    type={'select'}
                    name={'assignee'}
                    placeholder={'Select user'}
                    popupContainer={'modal'}
                    getPopupContainer={true}
                    handleChange={(value) => setValue(value)}
                />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 30 }}>
                    {showCancel && <Cancel onClick={onClose}>Cancel</Cancel>}
                    <Button
                        text={'Confirm'}
                        style={{ marginLeft: 'auto' }}
                        onClick={() => onSubmit(value)}
                        disabled={!value}
                    />
                </div>
            </div>
        </ModalWrap>
    )
}

export default TasksReAssignModal
