import React, { useState, useEffect } from 'react'
import { Title } from './style'
import { Checkbox } from 'antd'
import { useStore } from '../../../mobx-store/context'
import { api } from '../../../api'
import AllSubscriptionModal from './AllSubscriptionModal'

const TopLine = ({ resetItems, subscription, loading, setSearchObj, setAlertForAllMode, archive }) => {
  const [checked, setChecked] = useState(null)
  const [visible, setVisible] = useState(false)

  const handleClose = () => {
    setVisible(false)
  }

  useEffect(() => {
    setChecked(subscription)
  }, [subscription])

  const handleSubscribe = () => {
    setChecked((prev) => !prev)
    const url = checked ? '/proposed_source/unsubscribe_for_all/' : '/proposed_source/subscribe_for_all/'
    api(url, {}, 'POST').then((data) => {
      if (!data.errors) {
        setSearchObj((prev) => ({ pageNumber: 1 }))
        resetItems()
        checked ? setAlertForAllMode(false) : setAlertForAllMode(true)
      }
    })
  }

  const onChange = () => {
    checked ? handleSubscribe() : setVisible(true)
  }

  return (
    <>
      {visible && <AllSubscriptionModal handleClose={handleClose} handleClick={handleSubscribe} />}
      {
        !loading && <div
          style={{
            display: 'grid',
            gridTemplateColumns: '3fr 3fr 3fr 1.25fr 1.5fr 1.2fr 2.5fr ',
            borderBottom: '1px solid #c4cacc',
          }}
        >
          <Title style={{ paddingLeft: 5 }}>Reference Number</Title>
          <Title>Title</Title>
          <Title>Notes</Title>
          <Title>Juris</Title>
          <Title>Status</Title>
          <Title>Date</Title>
          {!archive &&
            <Title style={{ marginLeft: 50 }}>
              <Checkbox
                onChange={(e) => { onChange() }}
                style={{ marginRight: '.5em' }}
                checked={checked}
              />
              Alert Me For All
            </Title>}
          <div />
        </div>
      }
    </>
  )
}

export default TopLine
