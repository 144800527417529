import styled from 'styled-components'
import { query1300, query500 } from '../../helpers'
import { ContainerGlobal, TitleGlobal } from '../styles'

export const Wrap = styled('div')`
  ${ContainerGlobal} {
    @media (max-width: 955px) {
      display: block;
    }
  }
  ${TitleGlobal} {
    @media (max-width: 955px) {
      text-align: center;
      br {
        display: none;
      }
    }
    @media${query500} {
      text-align: left;
    }
  }
`

export const LeftSide = styled('div')`
  width: 560px;
  @media (max-width: 1070px) {
    width: 450px;
  }
  @media (max-width: 955px) {
    width: 100%;
    text-align: center;
  }
  img {
    width: 664px;
    margin-left: -122px;
    @media (max-width: 1070px) {
      width: 475px;
      margin-left: -51px;
    }
    @media${query500} {
      width: 100%;
    }
  }
`

export const RightSide = styled('div')`
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: 292px 292px;
  gap: 15px;
  margin-left: auto;
  @media${query1300} {
    grid-template-columns: 252px 252px;
  }
  @media (max-width: 955px) {
    align-content: center;
    justify-content: center;
    margin-top: 30px;
  }
  @media${query500} {
    grid-template-columns: 252px;
  }
`
