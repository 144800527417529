import { TableHeaderTitle } from "../../CompaniesTable/style";
import { useState, useEffect } from 'react'
import { useStore } from "../../../../mobx-store/context";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom/";
import { api } from "../../../../api";
import Cookies from 'universal-cookie';
import Wraper from "../../CMSContainer";
import ItemsShowing from '../../../../components/common/TableItemsShowing'
import { StyledTable } from '../../ResourceEdit/GuidesTable/style'
import AddingButton from "../../ResourceEdit/GuidesTable/addingButton";
import { Title } from './style'
import DictionariesEditModal from '../Modal'
import { set } from "mobx";


const DictionariesTable = observer(({ name, title, btnName }) => {
  const [page, setPage] = useState(1)
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)
  const [visibleAddModal, setVisibleAddModal] = useState(false)
  const [visibleEditModal, setVisibleEditModal] = useState(false)
  const [id, setId] = useState(null)
  const store = useStore()
  const history = useHistory()

  const getData = () => {
    setLoading(true)
    const url = name === 'applies_to' ? `/cms/dictionaries/applies_to/?page=${page}` : `/cms/${name}/?page=${page}`
    api(url, {}, 'GET').then((data) => {
      if (data.errors) return history.push('/home')
      store.setSingleValue(`dictionaries_${name}`, data.results)
      setData(data.results)
      setTotal(data.count)
      setLoading(false)
    })
  }
  useEffect(() => {
    getData(false)
  }, [page])


  const columns = [
    {
      title: <TableHeaderTitle>Name</TableHeaderTitle>,
      dataIndex: 'name',
      key: 'name',
      width: '90%',
      sorter: false,
      render: (text, record) => (
        <div style={{ color: 'var(--text)', fontFamily: 'var(--semiBold)', width: '100%',}}
          onClick={() => {
            setId(record.id)
            setVisibleEditModal(true)
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: <TableHeaderTitle>{name === 'jurisdictions' ? 'Abbreviation' : 'Date Added'}</TableHeaderTitle>,
      dataIndex: name === 'jurisdictions' ? 'code' : 'created',
      key: name === 'jurisdictions' ? 'code' : 'created',
      width: '10%',
      sorter: false,
    },
  ]

  return (
    <Wraper>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20,
      }}>
        {visibleAddModal &&
          <DictionariesEditModal name={name} onClose={() => setVisibleAddModal(false)} isEdit={false} title={btnName} store={store} getData={getData} id={id} />
        }
        {visibleEditModal &&
          <DictionariesEditModal name={name} onClose={() => setVisibleEditModal(false)} isEdit={true} title={btnName} store={store} getData={getData} id={id} />
        }
        <Title>{title}</Title>
        <AddingButton onClick={() => setVisibleAddModal(true)} text={`Add New ${btnName}`} />
      </div>
      {!loading &&
        <>
          <StyledTable
            bordered
            columns={columns}
            dataSource={store[`dictionaries_${name}`] || data.results}
            rowKey={'id'}
            size='small'
            onChange={(pagination, _, sorter) => {
              setPage(pagination.current)
            }}
            pagination={{
              hideOnSinglePage: false,
              pageSize: 20,
              position: ['bottomRight'],
              total: total,
              current: page,
              showSizeChanger: false,
            }}
          />
          <div style={{ marginTop: 5, display: 'flex', flexDirection: 'row', width: 'fit-content', alignItems: 'center' }}>
            <ItemsShowing
              itemsType={title}
              page={page}
              pageSize={20}
              total={total}
              position={'right'}
              adminPanel={true}
              style={{
                textAlign: 'right',
                marginTop: -31,
                paddingRight: 10,
                width: 'fit-content',
                marginBottom: 10,
              }}
            />
          </div>
        </>
      }
    </Wraper>
  )
});

export default DictionariesTable;