import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useStore } from '../../mobx-store/context'
import registerImage from '../../assets/images/register-bg.svg'
import bg from '../../assets/images/bg-logo.png'
import maintenanceBg from '../../assets/images/maintenance-bg-logo.png'
import cmsBg from '../../assets/images/cms_login_bg.png'
import Logo from '../../components/common/Logo/logo'
import Container from '../../components/containers/container'
import { Description, FormWrap, Right, Title, Wrap, MaintenanceDescription } from './authForm.style'
import SelectBlock from './SelectBlock/selectBlock'
import Form from './Form/form'
import ScheduleDemoModal from './ScheduleDemoModal'

const AuthForm = ({ login }) => {
  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_API_DEV}/users/?format=api`, {
  //     method: 'GET',
  //   })
  // }, [])
  const store = useStore()
  const [productSelected, setproductSelected] = useState(false);
  const [visible, setVisible] = useState(false);
  const [service, setService] = useState('Home Page')

  const onClose = () => {
    setVisible(false)
  }

  const selectBlocks = [
    {
      title: 'ComplianceSource',
      description: <>Identifies <strong>new</strong> laws, regulations, and guidance when adopted.</>,
      titleColor: 'blue',
      onSignUp: () => {
        store.setSingleValue('started_from_resource', false);
        setproductSelected(true);
      },
      onSchedule: (title) => {
        setVisible(true);
        setService(title)
      },
    },
    {
      title: 'ProposedSource',
      description: <>Identifies <strong>new</strong> laws and regulations that are proposed at the federal and state level.</>,
      titleColor: 'text',
      onSignUp: () => {
        store.setSingleValue('started_from_report_source', true);
        setproductSelected(true);
      },
      onSchedule: (title) => {
        setVisible(true);
        setService(title)
      },
    },
    {
      title: 'ReportSource',
      description: <>Alerts and tracking of reporting requirements.</>,
      titleColor: 'orange',
      onSignUp: () => {
        store.setSingleValue('started_from_resource', false);
        store.setSingleValue('started_from_report_source', true);
        setproductSelected(true);
      },
      onSchedule: (title) => {
        setVisible(true);
        setService(title)
      },
    },
    {
      title: 'ReSource',
      description: <>Identifies <strong>existing</strong> laws and regulations.</>,
      titleColor: 'green',
      onSignUp: () => {
        store.setSingleValue('started_from_resource', true);
        store.setSingleValue('started_from_report_source', false);
        setproductSelected(true);
      },
      onSchedule: (title) => {
        setVisible(true);
        setService(title)
      },
    },
  ];

  const maintenanceStatus = sessionStorage.getItem('maintenanceStatus');

  return (
    <Container wide>
      {visible && <ScheduleDemoModal onClose={onClose} service={service} />}
      <Wrap>
        {login || productSelected ?
          <>
            <FormWrap>
              <Logo large />
              <Title maintenanceStatus={maintenanceStatus}>
                {login ? (
                  maintenanceStatus === 'true' ? (
                    <>
                      Hang on! <br /> We are under maintenance
                    </>
                  ) : (
                    'Login to Your Account'
                  )
                ) : (
                  'Register'
                )}
              </Title>
              {maintenanceStatus === 'false' &&
                <Description>
                  <span style={{ fontFamily: 'var(--medium)' }}>
                    {login ? "Don't have an account yet? " : 'Already registered? '}
                    <Link
                      to={login ? '/register' : '/login'}
                      style={{ color: 'var(--orange)', textDecoration: 'underline' }}
                    >
                      {login ? 'Sign Up' : 'Sign In'}
                    </Link>
                  </span>
                </Description>
              }
              {
                maintenanceStatus === 'true' &&
                <MaintenanceDescription>
                  During our maintenance and upgrade period, PBMSource will be temporarily unavailable. Normal operations will resume on <span>November 11th at 4 AM EST.</span>
                </MaintenanceDescription>
              }
              <Form login={login} />
            </FormWrap>
            <Right maintenanceStatus={maintenanceStatus}>
              <img src={maintenanceStatus === 'true' ? maintenanceBg : registerImage} alt='#' />
            </Right>
          </>
          :
          <>
            <FormWrap>
              <Logo large />
              <Title style={{
                lineHeight: '38px',
                fontFamily: 'var(--bold)',
                fontSize: 32,
              }}>
                Subscribe to any of PBMSource’s products to save time and money on compliance.
              </Title>
              <Description style={{
                fontFamily: 'var(--medium)',
                fontSize: 24,
                lineHeight: '29px',
              }}>
                Select a product you want to start with. You can add additional products later.
              </Description>
              {selectBlocks.map((block) => (
                <SelectBlock
                  key={block.title}
                  title={block.title}
                  description={block.description}
                  titleColor={block.titleColor}
                  onSignUp={block.onSignUp}
                  onSchedule={block.onSchedule}
                />
              ))}
            </FormWrap>
            <Right>
              <img src={maintenanceStatus === 'true' ? maintenanceBg : bg} alt='#' />
            </Right>
          </>
        }
      </Wrap>
    </Container >
  )
}

export default AuthForm
