import styled from 'styled-components'

export const Wrap = styled('div')`
  padding-right: 2em;
  // margin-bottom: 20px;
  max-height: 74vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    position: absolute;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: var(--grey);
    border-radius: 5px;
    width: 8px;
  }

  @media (max-width: 1350px) {
    padding-right: 2em;
    // margin-bottom: 20px;
  }
  @media (max-height: 1200px) {
    max-height: 76vh;
  }
  @media (max-height: 1050px) {
    max-height: 73vh;
  }
  @media (max-height: 915px) {
    max-height: 66vh;
  }
  @media (max-height: 880px) {
    max-height: 69vh;
  }
  @media (max-height: 800px) {
    max-height: 65vh;
  }
  @media (max-height: 750px) {
    max-height: 65vh;
  }
`

export const Title = styled('div')`
  font-family: var(--regular);
  font-size: 2.5em;
  color: var(--text);
  // margin-top: 2.75em;
`
export const TitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`