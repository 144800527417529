import styled from 'styled-components';
import { Table } from 'antd';


export const StyledTable = styled(Table)`
  .ant-table {
    border: 0px solid #f0f0f0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: 14px;
    font-family: var(--medium);
    color: #515151;
    max-height: ${(props) => props.compact ? '200px' : '470px'};
    overflow-y: auto;

    .ant-table-cell, .ant-table-header-cell {
      border-bottom: 0px solid #f0f0f0; 
    }

    ::-webkit-scrollbar {
      width: 7px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #f0f0f0;
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: #F2F5FF;
    }
    @media (max-height: 970px) {
      max-height: 400px;
    }

    @media (max-height: 840px) {
      max-height: 350px;
    }

    @media (max-height: 800px) {
      max-height: 200px;
    }
  }
`;

export const TableHeaderTitle = styled('div')`
  font-size: 12px;
  font-family: var(--bold);
  color: var(--black);
`
export const PageWrap = styled('div')`
  width: 80%;
  padding-top: 90px;
  padding-bottom: 200px;
  margin: 0 auto;
  min-height: calc(100vh - 50px);
`
export const NavPageWrap = styled('div')`
  width: 100%;
  // padding-top: 90px;
  padding-bottom: 200px;
  // margin: 0 auto;
  min-height: calc(100vh - 50px);
`
export const PageTitle = styled('div')`
  color: var(--blue);
  font-family: var(--bold);
  font-size: 24px;
`
export const TitleBlock = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`
export const InputsBlock = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
`

export const Subtitle = styled('div')`
  color: var(--text);
  font-family: var(--regular);
  font-size: 12px;
  a {
    color: var(--blue);
    font-family: var(--regular);
    font-size: 12px;
    text-decoration-line: underline;
  }
`
export const CheckboxTableTitle = styled('div')`

color: var(--text);
font-family: var(--medium);
font-size: 14px;
`

export const SwitchTitle = styled('div')`
color: var(--text);
font-family: var(--regular);
font-size: 16px;
`


export const RemoveButton = styled('div')`
  margin-top: 25px;
  cursor: pointer;  
  font-size: 16px;
  font-family: var(--bold);
  color: var(--blue);
`

export const ButtonContainer = styled('div')`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content:  ${(props) => props.editMode ? 'space-between' : 'flex-end'};
  align-items: center;
  span {
    font-size: 12px;
    text-decoration: underline; 
    cursor: pointer; 
    color: #F24722; 
  }
`