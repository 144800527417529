import Wraper from "../../CMSContainer";
import React, { useEffect, useState } from "react";
import Button from '../../../../components/common/Button/button'
import { PrevBtn } from "../../CreateCompany/pages/FirstPage/Form/style";
import { SubTitle, CompanyDescription } from "../../CreateCompany/pages/FirstPage/style";
import { Row } from '../../CreateCompany/pages/ManageProducts/style'
import { ButtonCover } from "./style"
import { Divider, message } from "antd";
import { useStore } from "../../../../mobx-store/context";
import Input from "../../../../components/common/Input/";
import { useForm } from "react-hook-form";
import { api } from "../../../../api";
import { useHistory, useParams } from "react-router-dom";
import FullScreenLoader from "../../../../components/common/FullScreenLoader";
import RemoveUserModal from '../../EditUser/RemoveuserModal'
const AddAdminStaffPage = ({ isEditMode }) => {

  const { control, errors, handleSubmit, reset } = useForm();
  const store = useStore();
  const history = useHistory();
  const params = useParams();

  const [adminCompanies, setAdminCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);

  const onSubmit = (values) => {
    const url = isEditMode ? `/cms/users/${params.id}/` : '/cms/users/';
    api(url, values, isEditMode ? 'PATCH' : 'POST').then((res) => {
      if (res.error) {
        message.error(res.error);
      } else {
        message.success(isEditMode ? 'User updated successfully ' : 'User added successfully');
        history.push('/dashboard-settings');
      }
    })
  }

  const getUser = () => {
    const url = `/cms/users/${params.id}/`
    api(url, {}, 'GET').then((res) => {
      if (!res.errors) {
        store.setSingleValue('cmsUser', res)
        reset(res)
      }
    })
  }

  const getCompanyList = () => {
    api('/cms/companies/?is_admin_company=true', {}, 'GET').then((data) => {
      setAdminCompanies(data.results);
    }).then(() => {
      isEditMode && getUser();
    }).then(() => {
      setLoading(false);
    })
  }

  const roles = [
    {
      name: 'Admin',
      id: 'admin'
    },
    {
      name: 'Staff',
      id: 'staff'
    }
  ]

  useEffect(() => {
    getCompanyList();
  }, []);

  const onDeleteUser = () => {
    api(`/cms/users/${params.id}/`, {}, 'DELETE').then((res) => {
      if (!res.errors)
        store.setSingleValue('editedCmsUser', res)
      message.success('User deleted successfully')
      setVisible(false)
      history.goBack()
    })
  }


  return (
    <Wraper>
      {loading ? <FullScreenLoader />
        : <>
          {visible && <RemoveUserModal
            onSubmit={() => onDeleteUser()}
            onClose={() => setVisible(false)}
            userName={store?.cmsUser?.username}
          />}
          <SubTitle style={{ fontSize: 24, marginTop: 30 }}>
            {isEditMode ? "Edit Admin/Staff" : "Add New Admin/Staff"}
          </SubTitle>
          {isEditMode &&
            <CompanyDescription>
              <div>User Name - {store?.cmsUser?.username || 'N/A'}</div>
              <div>Company Name - {store?.cmsUser?.company_name || 'N/A'}</div>
            </CompanyDescription>
          }
          <Divider />
          <Row style={{ width: '70%' }}>
            <Input
              smallLabel
              name="email"
              label="Email"
              defaultValue={store?.editedCmsUser?.email}
              control={control}
              rules={{ required: true }}
              errors={errors}
            />
            <Input
              type='select'
              smallLabel
              options={adminCompanies}
              name="company"
              label="Company Selection"
              control={control}
              rules={{ required: true }}
              errors={'This field is required'}
            />
          </Row>
          <Row style={{ width: '70%' }}>
            <Input
              smallLabel
              name="first_name"
              label="First Name"
              defaultValue={store?.editedCmsUser?.username}
              control={control}
              rules={{ required: true }}
              errors={errors}
            />
            <Input
              smallLabel
              name="last_name"
              label="Last Name"
              defaultValue={store?.editedCmsUser?.username}
              control={control}
              rules={{ required: true }}
              errors={errors}
            />
          </Row>
          <Row style={{ width: '70%' }}>
            <Input
              type='select'
              smallLabel
              options={roles}
              name="account_type"
              label="Company Role"
              control={control}
              rules={{ required: true }}
              errors={errors}
            />
          </Row>
          <Divider />
          <ButtonCover isEditMode={isEditMode}>
            {isEditMode && <PrevBtn style={{ color: 'var(--red)' }} onClick={() => { setVisible(true) }}>Remove this User</PrevBtn>}
            <Button
              text={isEditMode ? "Save" : "Add"}
              style={{
              }}
              onClick={handleSubmit(onSubmit)}
            />
          </ButtonCover>
        </>}
    </Wraper>
  );
};

export default AddAdminStaffPage;