import styled from "styled-components";
import { Tooltip } from 'antd';

export const StyledTooltip = styled(Tooltip)`
& .ant-tooltip-inner {
  background-color: white;

  display: flex;  
  justify-content: center;
}

& .ant-tooltip-arrow-content {
  background-color: white; /* Цвет стрелки */
}


`;


export const SubTitle = styled.div`
  color: var(--text);
  font-family: var(--regular);
  font-size: 20px;
`;

export const Title = styled.div`
  color: var(--orange);
  font-family: var(--bold);
  font-size: 60px;
`;

export const HeaderWrapper = styled.div`
  width: 80%;
  height: auto;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 150px;
`

export const GuideWrapper = styled.div`
background-color: ${props => props.isSelected || props.actuveGuide ? 'rgba(242, 161, 34, 0.10)' : 'none'};
display: flex;
justify-content: center;
padding: 24px;
border-bottom: 1px solid #CCC;
`
export const ListTitle = styled.div`
color: var(--text);
font-family: var(--bold);
font-size: 32px;
margin: 44px 0;
`
export const GuideNameContainer = styled.div`
display: flex;
color: var(--text);
justify-content: flex-end;
text-align: right;
font-family: var(--fira-bold);
font-size: 16px;
`
export const GuideDescriptionContainer = styled.div`
display: flex;
color: var(--text);
justify-content: flex-start;
font-family: var(--fira-regular);
font-size: 16px;
width: 97%;
`
export const ListContainer = styled.div`
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr; 
  column-gap: 10%;
`