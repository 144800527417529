export const blocksTracking = [
  {
    key: 0,
    restrictions: 'compliance_jurisdictions',
    name: 'compliance_jurisdictions',
    title: 'Filter By Jurisdictions',
  },
  {
    key: 1,
    restrictions: 'compliance_topics',
    name: 'compliance_topics',
    title: 'Filter By Topics',
  },
  {
    key: 2,
    restrictions: 'compliance_line_of_business',
    name: 'compliance_line_of_business',
    title: 'Filter By Line Of Business',
  },
]

export const blocksReportSource = [
  {
    key: 0,
    restrictions: 'report_jurisdictions',
    name: 'report_jurisdictions',
    title: 'Filter By Jurisdictions',
  },
  {
    key: 1,
    restrictions: 'report_types',
    name: 'report_types',
    title: 'Filter By Types',
  },
]