import React from 'react'
import { Checkbox as AntCheckbox } from 'antd'
import { CheckboxWrap } from './style'

const Checkbox = ({
  label,
  onChange,
  name,
  value,
  style,
  reverse,
  defaultChecked,
  checked,
  disabled,
}) => {
  return (
    <CheckboxWrap style={style} reverse={reverse} disabled={disabled}>
      <AntCheckbox
        onChange={onChange}
        name={name}
        value={value}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
      >
        {label}
      </AntCheckbox>
    </CheckboxWrap>
  )
}

export default Checkbox
