import { Checkbox, message, Radio } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Moment from 'react-moment'
import { api } from '../../../../../../api'
import Button from '../../../../../../components/common/Button/button'
import ButtonOrange from '../../../../../../components/common/ButtonOrange'
import Input from '../../../../../../components/common/Input'
import Validation from '../../../../../../components/common/Validation/validation'
import {
  getError,
  modifyPaymentCardValues,
} from '../../../../../../helpers/helpers'
import { useStore } from '../../../../../../mobx-store/context'
import Card from '../../../Billing/Card'
import { Wrap as CardWrap } from '../../../Billing/Card/style'
import { CustomRadioGroup } from '../../../Email/style'
import IncludingBlock from '../../IncludingBlock'
import Payment from './Payment'
import RoundButton from './RoundButton'
import {
  Bottom,
  CustomCheckboxGroup,
  Description,
  Grid,
  LeftSide,
  RightSide,
  Title,
  Wrap,
} from './style'

const messages = {
  topic_tract: 'Choose Jurisdiction',
  juris_tract: 'Choose Topic',
}

const Item = observer(
  ({
    item: {
      name,
      description,
      month_base_price,
      month_premium_price,
      included_members,
      code,
      place,
      min_price,
      premium_surcharge,
    },
    discount,
    currentPlace,
    active,
    changeTariff,
    resetTariff,
    closeModal,
  }) => {
    const store = useStore()
    const [billPeriod, setBillPeriod] = useState('year')
    const [premium, setPremium] = useState(
      store.company[0].is_premium ? ['premium'] : []
    )
    const [price, setPrice] = useState(0)
    const {
      control,
      getValues,
      reset,
      formState: { isDirty },
    } = useForm()
    const [finalConfirm, setFinalConfirm] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showCards, setShowCards] = useState(false)
    const [chooseOptions, setChooseOptions] = useState(false)
    const [recalculatedSum, setRecalculatedSum] = useState(0)
    const [jurs, setJurs] = useState([])
    const [topics, setTopics] = useState([])
    const company = store.company[0]
    const calculatedBillPeriod = billPeriod === 'month' ? 'mo' : 'year'
    const isCompanyPremium = company.is_premium
    const isPremiumChecked = premium.length > 0

    const getItems = () => {
      api('/jurisdictions/', {}, 'GET').then((data) => {
        if (data.errors) return message.error(getError(data))
        setJurs(data.filter((item) => +item.company !== +company.id))
      })

      api('/topics/', {}, 'GET').then((data) => {
        if (data.errors) return message.error(getError(data))
        setTopics(data.filter((item) => +item.company !== +company.id))
      })
    }

    const topicsAndJurisdictionsSelect = () => {
      if (code === 'total_tract') return null

      const styles = {
        margin: '15px auto 0',
        width: 500,
      }

      const isSelectTract = code === 'select_tract'

      const types = {
        topic_tract: {
          name: 'jurisdictions',
          placeholder: 'Select Jurisdiction',
          options: jurs,
        },
        juris_tract: {
          name: 'topics',
          placeholder: 'Select Topic',
          options: topics,
        },
        select_tract: {
          name: 'jurisdictions',
          placeholder: 'Select Jurisdictions',
          options: jurs,
        },
      }

      return (
        <>
          <Input
            control={control}
            name={types[code].name}
            placeholder={types[code].placeholder}
            type={isSelectTract ? 'multiSelect' : 'select'}
            styleContainer={styles}
            validation={{ required: true }}
            label={types[code]?.placeholder}
            options={types[code].options}
            popupContainer={'modal-container'}
            handleChange={recalculateSum}
            defaultValue={[]}
            showSearch={types[code].name === 'jurisdictions'}
          />
          {isSelectTract && (
            <Input
              control={control}
              name={'topics'}
              placeholder={'Select Topics'}
              type={'multiSelect'}
              styleContainer={styles}
              label={'Select Topics'}
              validation={{ required: true }}
              options={topics}
              handleChange={recalculateSum}
              popupContainer={'modal-container'}
              defaultValue={[]}
            />
          )}
        </>
      )
    }

    const calculatePrice = () => {
      if (billPeriod === 'month') {
        return premium.length ? month_premium_price : month_base_price
      } else {
        return premium.length
          ? month_premium_price * 12 - month_premium_price * 12 * discount
          : month_base_price * 12 - month_base_price * 12 * discount
      }
    }

    const popupText = (
      <div style={{ color: 'var(--blue)', fontFamily: 'var(--medium)' }}>
        <span style={{ fontFamily: 'var(--bold)' }}>
          Your plan upgrade will re-set your annual commitment and be billed at
          the new rate of{' '}
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(calculatePrice())}
          /{calculatedBillPeriod} starting on{' '}
          <Moment format={'MM/DD/YY'}>
            {company.payment_status === 'trial' ||
            code === company.tariff ||
            !store.tariffInfo.now_is_last_2_weeks_of_contract
              ? new Date()
              : company.next_contract_date}
          </Moment>
          .
        </span>
        <br />
        Do you wish to confirm this selection?
      </div>
    )

    const confirmText = (
      <div style={{ color: 'var(--blue)', fontFamily: 'var(--medium)' }}>
        <span style={{ fontFamily: 'var(--bold)' }}>
          Changes made to your plan will take effect{' '}
          <Moment format={'MM/DD/YY'}>
            {company.payment_status === 'trial' ||
            code === company.tariff ||
            !store.tariffInfo.now_is_last_2_weeks_of_contract
              ? new Date()
              : company.next_contract_date}
          </Moment>{' '}
          Apply these changes?
        </span>
      </div>
    )

    const isDisabled = () => {
      if (
        store.tariffInfo.now_is_last_2_weeks_of_contract ||
        store.tariffInfo.can_change_all_tariffs
      )
        return false

      if (currentPlace === place) return false

      if (company.payment_period === 'month') {
        return place < currentPlace
      }

      return true
    }

    const shouldShowChooseOptions =
      currentPlace === place &&
      code !== 'total_tract' &&
      (company.payment_period === 'month'
        ? true
        : store.tariffInfo.now_is_last_2_weeks_of_contract ||
          store.tariffInfo.can_change_all_tariffs)

    const isValidationActive = () => {
      if (active && currentPlace !== place) return true

      if (active && currentPlace === place) {
        const is_premium = !!premium.length
        if (
          is_premium !== company.is_premium ||
          billPeriod !== company.payment_period ||
          chooseOptions
        )
          return true
      }
    }

    const convertToArray = (name) => {
      return getValues(name)
        ? Array.isArray(getValues(name))
          ? getValues(name)
          : [getValues(name)]
        : company[name]
    }

    const type = {
      topic_tract: 'jurisdictions',
      juris_tract: 'topics',
    }

    const addCart = (values) => {
      api(`/payment_cards/`, modifyPaymentCardValues(values), 'POST').then(
        (data) => {
          if (!data.errors) {
            message.success('Card added')
            store.getBillingCards()
          } else {
            message.error(getError(data))
          }
        }
      )
    }

    const getTopicsAndJurs = () => {
      return code === 'topic_tract' || code === 'juris_tract'
        ? {
            [code === 'topic_tract' ? 'jurisdictions' : 'topics']:
              convertToArray(type[code]),
          }
        : {
            jurisdictions: convertToArray('jurisdictions'),
            topics: convertToArray('topics'),
          }
    }

    const postTariff = (values = null) => {
      if (
        !store.tariffInfo.now_is_last_2_weeks_of_contract &&
        code === 'select_tract' &&
        company.payment_status !== 'trial' &&
        (getTopicsAndJurs().topics.length < company.topics.length ||
          getTopicsAndJurs().jurisdictions.length <
            company.jurisdictions.length)
      ) {
        return message.error('You can not select less options')
      }

      setLoading(true)

      api(
        '/payments/change_tariff/',
        values
          ? {
              tariff: code,
              payment_period: billPeriod,
              is_premium: premium.length,
              ...getTopicsAndJurs(),
              ...modifyPaymentCardValues(values),
            }
          : {
              tariff: code,
              payment_period: billPeriod,
              is_premium: premium.length,
              ...getTopicsAndJurs(),
            },
        'POST'
      ).then((data) => {
        if (data.ok) {
          if (values) addCart(values)
          message.success('Plan changed')
          store.getCompany(() => {
            store.getTariffInfo()
            store.getUser()
          })
          closeModal()
          store.getBillingCards()
          store.loadSelect('memberOptions')
        } else {
          message.error(getError(data))
        }
        setLoading(false)
      })
    }

    const isPeriodDisabled = () => {
      if (store.tariffInfo.can_change_all_tariffs) return false

      if (company.payment_period === 'month') {
        return !(
          place >= currentPlace &&
          store.tariffInfo.now_is_last_2_weeks_of_contract &&
          store.tariffInfo.can_change_all_tariffs
        )
      } else {
        if (company?.payment_status === 'trial') return false

        if (
          !store.tariffInfo.now_is_last_2_weeks_of_contract &&
          !store.tariffInfo.can_change_all_tariffs
        )
          return true
        return store.tariffInfo.now_is_last_2_weeks_of_contract
      }
    }

    const setCardPrimary = (id) => {
      api(`/payment_cards/${id}/set_is_primary/`, {}, 'POST').then((data) => {
        if (!data.errors) {
          store.getBillingCards()
        }
      })
    }

    const recalculateSum = (obj) => {
      api(
        '/payments/calc_amount_for_increase_tariff/',
        {
          tariff: code,
          payment_period: obj?.billPeriod || billPeriod,
          is_premium:
            typeof obj === 'object' && 'premium' in obj
              ? obj?.premium
              : isPremiumChecked,
          ...getTopicsAndJurs(),
        },
        'POST'
      ).then((data) => {
        if (!data.errors && data.ok) {
          setRecalculatedSum(data.amount)
        } else {
          setRecalculatedSum(0)
          message.error(messages[code])
        }
      })
    }

    const onConfirm = () => {
      const isOptionAdded = (type) =>
        getTopicsAndJurs()[type]?.length > company?.[type]?.length

      const isPremium = premium.length > 0

      const isPremiumChanged = company.is_premium !== isPremium

      if (finalConfirm) {
        if (
          chooseOptions &&
          company.payment_status !== 'trial' &&
          !isOptionAdded('topics') &&
          !isOptionAdded('jurisdictions') &&
          !isPremiumChanged
        ) {
          postTariff()
        } else {
          store.billingCards.length ? setShowCards(true) : setShowForm(true)
        }
      } else {
        setFinalConfirm(true)
      }
    }

    useEffect(() => {
      setBillPeriod(company.payment_period)

      setPrice(calculatePrice())

      reset({
        jurisdictions: company?.jurisdictions,
        topics: company?.topics,
      })

      if (!store.topics || !store.jurs) store.loadSelect('companyOptions')
    }, [])

    useEffect(() => {
      setPrice(calculatePrice())
    }, [premium, billPeriod])

    useEffect(() => {
      if (place !== currentPlace) {
        reset({})
      }
      setShowCards(false)
      setShowForm(false)
      setFinalConfirm(false)
    }, [active])

    useEffect(() => {
      getItems()
    }, [])

    return (
      <Wrap disabled={isDisabled()}>
        <Grid>
          <LeftSide>
            <RoundButton
              checked={active}
              onClick={() => {
                changeTariff(place)
                if (store.company?.[0]?.payment_status !== 'trial')
                  recalculateSum()
              }}
              disabled={isDisabled()}
            />
          </LeftSide>
          <RightSide>
            <Title>
              {name} <span>${min_price} per / mo</span>
            </Title>
            <Description>{description}</Description>
            <IncludingBlock min={included_members} code={code} />
            <CustomCheckboxGroup style={{ margin: '15px 0' }}>
              <Checkbox.Group
                onChange={(checkedValue) => {
                  setPremium(checkedValue)
                  recalculateSum({ premium: checkedValue.length > 0 })
                }}
                disabled={isCompanyPremium}
                value={premium}
              >
                <Checkbox value={'premium'}>
                  Add Pro Line of Business{' '}
                  {code === 'select_tract' ? 'from' : 'at'}{' '}
                  <span style={{ fontFamily: 'var(--medium)' }}>
                    +${premium_surcharge || 49}/
                    {code === 'select_tract' ? 'topic and jurisdiction ' : ''}
                    per month
                  </span>
                </Checkbox>
              </Checkbox.Group>
            </CustomCheckboxGroup>
            <Bottom>
              <CustomRadioGroup style={{ marginBottom: 0 }} row>
                <Radio.Group
                  name={'billing'}
                  value={billPeriod}
                  onChange={(e) => {
                    setBillPeriod(e.target.value)
                    recalculateSum({ billPeriod })
                  }}
                  disabled={isPeriodDisabled()}
                >
                  <Radio value={'year'}>
                    Billed Annually (Save 10% total cost)
                  </Radio>
                  <Radio value={'month'}>Billed Monthly</Radio>
                </Radio.Group>
              </CustomRadioGroup>
              <div>
                <Title style={{ fontSize: '1.125em', marginLeft: 'auto' }}>
                  Final Price:{' '}
                  <span>
                    {' '}
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(price)}
                    /{calculatedBillPeriod}
                  </span>
                </Title>
                {active &&
                  (isDirty || isCompanyPremium !== isPremiumChecked) &&
                  recalculatedSum > 0 && (
                    <Title style={{ fontSize: '1.125em', marginLeft: 'auto' }}>
                      Prorated Amount:
                      <span>
                        {' '}
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(recalculatedSum || 0)}
                        /{calculatedBillPeriod}
                      </span>
                    </Title>
                  )}
              </div>
            </Bottom>
            {shouldShowChooseOptions && (
              <ButtonOrange
                text={'Change Options'}
                onClick={() => {
                  setChooseOptions(!chooseOptions)
                  setShowCards(false)
                  setShowForm(false)
                }}
              />
            )}

            {['trial_is_over', 'error', 'canceled', 'trial'].includes(
              company.payment_status
            ) &&
              code === company.tariff && (
                <ButtonOrange
                  text={'Pay Tariff'}
                  onClick={() => {
                    setShowCards(false)
                    setShowForm(false)
                    if (store.billingCards.length) {
                      setShowCards(true)
                    } else {
                      setShowForm(true)
                    }
                    setChooseOptions(false)
                  }}
                  style={{ marginLeft: 15, marginBottom: 15 }}
                />
              )}

            {currentPlace === place
              ? chooseOptions &&
                isValidationActive() &&
                topicsAndJurisdictionsSelect()
              : isValidationActive() && topicsAndJurisdictionsSelect()}
            {isValidationActive() && (
              <Validation
                upperText={finalConfirm ? confirmText : popupText}
                text={
                  <div style={{ display: 'flex' }}>
                    <Button
                      text={'Yes'}
                      style={{ marginRight: 5 }}
                      loading={loading}
                      onClick={onConfirm}
                    />
                    <Button
                      text={'No'}
                      onClick={() => {
                        resetTariff()
                        setShowCards(false)
                        setFinalConfirm(false)
                        if (chooseOptions) setChooseOptions(false)
                      }}
                      style={{ marginLeft: 5 }}
                      border
                    />
                  </div>
                }
                style={{ margin: '15px 0' }}
                noLineThrough
              />
            )}
            {showCards && (
              <div>
                <ButtonOrange
                  text={'Choose card'}
                  style={{ cursor: 'default' }}
                />
                <div style={{ marginTop: 15, display: 'flex' }}>
                  {store.billingCards?.map((item) => (
                    <Card
                      onClick={(data) => {
                        setCardPrimary(data.id)
                      }}
                      data={item}
                      clickable
                    />
                  ))}
                  <CardWrap
                    style={{ alignItems: 'center' }}
                    clickable
                    onClick={() => setShowForm(true)}
                  >
                    + Add new card
                  </CardWrap>
                </div>
                <Button
                  text={'Pay with chosen card'}
                  style={{ margin: '15px 0' }}
                  onClick={() => postTariff()}
                  loading={loading}
                />
              </div>
            )}

            {showForm && (
              <Payment
                handleChangeTariff={(values) => postTariff(values)}
                onClose={() => setShowForm(false)}
                loading={loading}
                addingNewMethod
                leftTitle={'Add Billing Info'}
              />
            )}
          </RightSide>
        </Grid>
      </Wrap>
    )
  }
)
export default Item
