import React from 'react';
import { NavPageWrap, PageTitle, TitleBlock, Subtitle, InputsBlock, SwitchTitle, RemoveButton, ButtonContainer } from "./style";
import { useState, useEffect } from 'react'
import { useStore } from "../../../../mobx-store/context";
import { api } from "../../../../api";
import { useHistory } from 'react-router-dom'
import ButtonOrange from "../../../../components/common/ButtonOrange";
import Input from "../../../../components/common/Input";
import { useForm } from 'react-hook-form';
import CheckboxTable from './CheckboxGroup'
import { StyledSwitch } from '../../CreateCompany/pages/FirstPage/style';
import MyEditor from './CheckboxGroup/Editor';
import Button from "../../../../components/common/Button/button";
import { message } from 'antd';
import moment from 'moment'
import bin from '../../../../assets/images/delete_red_bin.svg'
import RemoveReportModal from './Modal';
import { useParams } from 'react-router-dom';
import GetReadyList from './GetReadyTasks';
import Tabs from '../../../../components/common/Tabs/tabs';
import MaterialTab from './MaterialTab';
import { set } from 'mobx';
import Wraper from '../../CMSContainer';


const ReportPage = ({ editMode }) => {
  const history = useHistory()
  const store = useStore()
  const params = useParams()
  const { handleSubmit, control, reset, getValues, watch, errors } = useForm();
  const [tips, setTips] = useState(store?.cmsCurrentReport?.tips || '')
  const [isPublished, setIsPublished] = useState(store?.cmsCurrentReport?.is_published || false)
  const [isNewReport, setIsNewReport] = useState(store?.cmsCurrentReport?.is_new_report || false)
  const [isEmail, setIsEmail] = useState(store?.cmsCurrentReport?.send_email_about_new_report || false)
  const [isVariable, setIsVariable] = useState(store?.cmsCurrentReport?.report_due_date_is_variable || false)
  const [isUponRequest, setIsUponRequest] = useState(store?.cmsCurrentReport?.report_due_date_is_upon_request || false)
  const [isEditMode, setIsEditMode] = useState(editMode)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [loading, setLoading] = useState(isEditMode ? true : false)
  const [activeTab, setActiveTab] = useState("form");
  const [formDescription, setFormDescription] = useState(params.id ? store?.cmsCurrentReport?.form[0]?.description : '')
  const [notesDescription, setNotesDescription] = useState(params.id ? store?.cmsCurrentReport?.notes[0]?.description : '')
  const [guidanceDescription, setGuidanceDescription] = useState(params.id ? store?.cmsCurrentReport?.guidance[0]?.description : '')
  const [definitionDescription, setDefinitionDescription] = useState(params.id ? store?.cmsCurrentReport?.definition[0]?.description : '')
  const [formFiles, setFormFiles] = useState(params.id ? store?.cmsCurrentReport?.form[0]?.files : [])
  const [notesFiles, setNotesFiles] = useState(params.id ? store?.cmsCurrentReport?.notes[0]?.files : [])
  const [guidanceFiles, setGuidanceFiles] = useState(params.id ? store?.cmsCurrentReport?.guidance[0]?.files : [])
  const [definitionFiles, setDefinitionFiles] = useState(params.id ? store?.cmsCurrentReport?.definition[0]?.files : [])
  const [isNewReq, setIsNewReq] = useState(store?.cmsCurrentReport?.is_new_requirement || false)


  const getCurrentReport = (reportId) => {
    setLoading(true)
    api(`/cms/report/${reportId}/`, {}, 'GET').then((res) => {
      store.setSingleValue('cmsCurrentReport', res)
      reset({
        report_title: res.report_title,
        report_type: res.report_type,
        reporting_time_period: res.reporting_time_period,
        jurisdiction: res.jurisdiction,
        report_due_date: moment(res.report_due_date).format('YYYY-MM-DD'),
        line_of_business: res.line_of_business,
        reporting_entity: res.reporting_entity,
        ties_to_license: res.ties_to_license,
        report_to: res.report_to,
        internal_tracking_number: res.internal_tracking_number,
        new_requirement_text: res.new_requirement_text,
        repeat_mode: res.repeat_mode,
        report_due_date_is_upon_request: res.report_due_date_is_upon_request,
        report_due_date_is_variable: res.report_due_date_is_variable,
      })
      setIsPublished(res.is_published)
      setIsNewReport(res.is_new_report)
      setIsEmail(res.send_email_about_new_report)
      setIsNewReq(res.is_new_requirement)
      setTips(res.tips)
      setLoading(false)
      setFormDescription(res.form[0].description)
      setNotesDescription(res.notes[0].description)
      setGuidanceDescription(res.guidance[0].description)
      setDefinitionDescription(res.definition[0].description)
      setFormFiles(res.form[0].files)
      setNotesFiles(res.notes[0].files)
      setGuidanceFiles(res.guidance[0].files)
      setDefinitionFiles(res.definition[0].files)
      setIsVariable(res.report_due_date_is_variable)
      setIsUponRequest(res.report_due_date_is_upon_request)
    }).then(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    if (store?.cmsCurrentReport?.id) {
      setIsEditMode(true)
    }
  }, [store?.cmsCurrentReport?.id])

  useEffect(() => {
    if (isEditMode && params.id) {
      getCurrentReport(params.id)
    }
  }, [])

  const onSubmit = (data) => {
    const url = isEditMode ? `/cms/report/${store?.cmsCurrentReport?.id}/` : `/cms/report/`
    const body = {
      ...data,
      tips: tips,
      is_published: isPublished,
      report_due_date_is_variable: isVariable,
      report_due_date_is_upon_request: isUponRequest,
      is_new_report: isNewReport,
      send_email_about_new_report: isEmail,
      is_new_requirement: isNewReq,
      report_due_date: moment(data.report_due_date).format('YYYY-MM-DD'),
    }
    api(url, body, isEditMode ? 'PATCH' : 'POST').then((res) => {
      if (!res.errors) {
        store.setSingleValue('cmsCurrentReport', res)
        message.success(isEditMode ? 'Report updated successfully' : 'Report created successfully')
        isEditMode && history.push(`/dashboard-report-table`)
        !isEditMode && setIsEditMode(true)
      }
    })
  }

  const onRemoveReport = () => {
    api(`/cms/report/${store?.cmsCurrentReport?.id}/`, {}, 'DELETE').then((res) => {
      if (!res.errors) {
        message.success('Report removed successfully')
        setIsRemoveModalOpen(false)
      }
    }).then(() => {
      setTimeout(() => {
        history.push(`/dashboard-report-table`)
      }, 2000);
    })
  }

  const getCmsOptions = () => {
    api(`/cms/dictionaries_data/all/`, {}, 'GET').then((data) => {
      if (!data.errors) {
        store.setSingleValue('cms_options', data)
      }
    })
  }

  const showTabs = () => {
    return {
      form: {
        render: () => <MaterialTab
          tabName={'form'}
          description={params.id ? formDescription || store?.cmsCurrentReport?.form[0]?.description : formDescription}
          setDescriprion={setFormDescription}
          files={params.id ? formFiles || store?.cmsCurrentReport?.form[0].files : formFiles}
          setFiles={setFormFiles}
          reportId={params.id || store?.cmsCurrentReport?.id}
        />,
      },
      notes: {
        render: () => <MaterialTab
          tabName={'notes'}
          description={params.id ? notesDescription || store?.cmsCurrentReport?.notes[0]?.description : notesDescription}
          setDescriprion={setNotesDescription}
          files={params.id ? notesFiles || store?.cmsCurrentReport?.notes[0].files : notesFiles}
          setFiles={setNotesFiles}
          reportId={params.id || store?.cmsCurrentReport?.id}
        />,
      },
      guidance: {
        render: () => <MaterialTab tabName={'guidance'}
          description={params.id ? guidanceDescription || store?.cmsCurrentReport?.guidance[0]?.description : guidanceDescription}
          setDescriprion={setGuidanceDescription}
          files={params.id ? guidanceFiles || store?.cmsCurrentReport?.guidance[0].files : guidanceFiles}
          setFiles={setGuidanceFiles}
          reportId={params.id || store?.cmsCurrentReport?.id}
        />,
      },
      definition: {
        render: () => <MaterialTab tabName={'definition'}
          description={params.id ? definitionDescription || store?.cmsCurrentReport?.definition[0]?.description : definitionDescription}
          setDescriprion={setDefinitionDescription}
          files={params.id ? definitionFiles || store?.cmsCurrentReport?.definition[0].files : definitionFiles}
          setFiles={setDefinitionFiles}
          reportId={params.id || store?.cmsCurrentReport?.id}
        />,
      },
    }
  }
  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    store.getLegislativeOptions()
  }, [])


  useEffect(() => {
    getCmsOptions()
  }, [])

  return (
    <>
      {!loading &&
        <Wraper>
          <NavPageWrap >
            {isRemoveModalOpen && isEditMode &&
              <RemoveReportModal
                serviceName={'report'}
                onClose={() => { setIsRemoveModalOpen(false) }}
                onRemove={onRemoveReport} />}
            <TitleBlock style={{ width: '50%' }}>
              <PageTitle>
                {isEditMode ? 'Edit Report Item' : 'Add New Report Item'}
              </PageTitle>
              <ButtonOrange
                text={'Activity'}
                onClick={() => history.push(`/dashboard-report-activity/${store?.cmsCurrentReport?.id || params.id}`)}
                style={{ fontFamily: 'var(--bold)', color: 'var(--blue)', fontSize: 12, }}
              />
            </TitleBlock>
            <TitleBlock style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
              <Subtitle>
                {isEditMode ? 'Edit a ReportSource item for subscribers.' : 'Add a new ReportSource item for subscribers.'}
              </Subtitle>
              <ButtonOrange
                text={'< Go Back'}
                onClick={() => history.goBack()}
                style={{ fontFamily: 'var(--bold)', color: '#4E74A9', fontSize: 12, textDecoration: 'none', marginBottom: 32 }}
              />
            </TitleBlock>
            <Input
              style={{ width: '50%', marginBottom: 32 }}
              smallLabel
              name={'report_title'}
              label={'Report Title'}
              type={'text'}
              control={control}
              validation={{ required: !isEditMode }}
              transparent
              defaultValue={store?.cmsCurrentReport?.report_title || ''}
            />
            <InputsBlock style={{ width: '50%' }}>
              <Input
                style={{ marginBottom: 32 }}
                smallLabel
                name={'report_type'}
                label={'Report Type'}
                type={'select'}
                options={store?.cms_options?.report_types}
                control={control}
                validation={{ required: !isEditMode }}
                transparent
                defaultValue={store?.cmsCurrentReport?.report_type || ''}
              />
              <Input
                style={{ marginBottom: 32 }}
                smallLabel
                name={'reporting_time_period'}
                label={'Reporting Time Period'}
                type={'text'}
                control={control}
                validation={{ required: !isEditMode }}
                transparent
                defaultValue={store?.cmsCurrentReport?.reporting_time_period || ''}
              />
            </InputsBlock>
            <InputsBlock style={{ width: '50%' }}>
              <Input
                style={{ marginBottom: 32 }}
                smallLabel
                name={'jurisdiction'}
                label={'Jurisdition'}
                options={store?.cms_options?.jurisdictions}
                type={'select'}
                control={control}
                validation={{ required: !isEditMode }}
                transparent
                defaultValue={store?.cmsCurrentReport?.jurisdiction || ''}
              />
              <Input
                smallLabel
                name={'report_due_date'}
                label={'Report Due Date'}
                type={'date'}
                control={control}
                transparent
                placeholder={'Select Due Date'}
                showCross={false}
                validation={{ required: !isEditMode }}
                noLimit
                defaultValue={store?.cmsCurrentReport?.report_due_date || ''}
              />
            </InputsBlock>
            <InputsBlock>
              <Input
                style={{ marginBottom: 32, width: '49%' }}
                smallLabel
                name={'repeat_mode'}
                label={'Repeat mode'}
                options={[
                  { id: 'never', name: 'Never' },
                  { id: 'monthly', name: 'Monthly' },
                  { id: 'quarterly', name: 'Quarterly' },
                  { id: 'annually', name: 'Annually' },
                ]}
                type={'select'}
                control={control}
                validation={{ required: !isEditMode }}
                transparent
                defaultValue={store?.cmsCurrentReport?.repeat_mode || 'never'}
              />
            </InputsBlock>
            <InputsBlock style={{ width: '50%' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 20 }}>
                <StyledSwitch
                  defaultChecked={store?.cmsCurrentReport?.report_due_date_is_variable || false}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  disabled={isUponRequest}
                  onChange={(checked) => { setIsVariable(checked) }}
                />
                <div style={{ marginLeft: 10 }} >
                  <SwitchTitle>Report due date is variable</SwitchTitle>
                </div>
              </div >
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 20 }}>
                <StyledSwitch
                  defaultChecked={store?.cmsCurrentReport?.report_due_date_is_upon_request || false}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  disabled={isVariable}
                  onChange={(checked) => { setIsUponRequest(checked) }}
                />
                <div style={{ marginLeft: 10 }} >
                  <SwitchTitle>Report due date is upon request</SwitchTitle>
                </div>
              </div >
            </InputsBlock>
            <InputsBlock style={{ width: '50%', marginBottom: 32 }}>
              <CheckboxTable
                options={store?.cms_options?.line_of_business}
                name={'line_of_business'}
                control={control}
                title={'Lines of Business'}
                disabled={false}
                defaultValue={store?.cmsCurrentReport?.line_of_business || []}
                compact={false}
              />
              <CheckboxTable
                options={store?.cms_options?.report_entities}
                name={'reporting_entity'}
                control={control}
                title={'Reporting Entity'}
                disabled={false}
                defaultValue={store?.cmsCurrentReport?.reporting_entity || []}
                compact={false}

              />
            </InputsBlock>
            <InputsBlock style={{ width: '50%', }}>
              <CheckboxTable
                options={store?.cms_options?.ties_to_license}
                name={'ties_to_license'}
                control={control}
                title={'Ties to license'}
                disabled={false}
                defaultValue={store?.cmsCurrentReport?.ties_to_license || []}
                compact={false}

              />
            </InputsBlock>

            <div style={{ width: '50%', margin: '32px 0 32px 0 ', border: '0.5px solid #8B9394' }} />
            <Input
              style={{ width: '50%', marginBottom: 32 }}
              smallLabel
              name={'report_to'}
              label={'Report To'}
              type={'text'}
              control={control}
              validation={{ required: !isEditMode }}
              transparent
              defaultValue={store?.cmsCurrentReport?.report_to || ''}
            />
            <Input
              style={{ width: '50%', marginBottom: 32 }}
              smallLabel
              name={'internal_tracking_number'}
              label={'Internal Tracking Number'}
              type={'text'}
              control={control}
              validation={{ required: !isEditMode }}
              transparent
              defaultValue={store?.cmsCurrentReport?.internal_tracking_number || ''}
            />
            <InputsBlock style={{
              width: '50%',
              gridTemplateColumns: '1fr',
              marginBottom: 32
            }} >
              <MyEditor
                title={'Tips'}
                handleChange={(value) => setTips(value)}
                defaultValue={store?.cmsCurrentReport?.tips || ''} />
            </InputsBlock>
            <InputsBlock>
              <Input
                style={{ marginBottom: 32 }}
                smallLabel
                name={'new_requirement_text'}
                label={'New Requirement Text'}
                type={'textarea'}
                control={control}
                transparent
                defaultValue={store?.cmsCurrentReport?.new_requirement_text || ''}
              />
            </InputsBlock>
            <InputsBlock style={{
              width: '100%',
              gridTemplateColumns: '0.75fr 0.75fr 1.5fr',
              gridColumnGap: 10,
            }}>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 20 }}>
                <StyledSwitch
                  defaultChecked={store?.cmsCurrentReport?.is_published || false}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onChange={(checked) => { setIsPublished(checked) }}
                />
                <div style={{ marginLeft: 10 }} >
                  <SwitchTitle>Is Published</SwitchTitle>
                </div>
              </div >
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 20 }}>
                <StyledSwitch
                  defaultChecked={store?.cmsCurrentReport?.is_new_report || false}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onChange={(checked) => { setIsNewReport(checked) }}
                />
                <div style={{ marginLeft: 10 }} >
                  <SwitchTitle>Is A New Report</SwitchTitle>
                </div>
              </div >

            </InputsBlock>
            <InputsBlock style={{
              width: '100%',
              gridTemplateColumns: '0.75fr 0.75fr 1.5fr',
              gridColumnGap: 10,
            }}>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 20 }}>
                <StyledSwitch
                  defaultChecked={store?.cmsCurrentReport?.is_new_requirement || false}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onChange={(checked) => { setIsNewReq(checked) }}
                />
                <div style={{ marginLeft: 10 }} >
                  <SwitchTitle>Is New Requirement</SwitchTitle>
                </div>
              </div >

              <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 20 }}>
                <StyledSwitch
                  defaultChecked={store?.cmsCurrentReport?.send_email_about_new_report || false}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onChange={(checked) => { setIsEmail(checked) }}
                />
                <div style={{ marginLeft: 10 }} >
                  <SwitchTitle>Send Email About New Report</SwitchTitle>
                </div>
              </div >
            </InputsBlock>
            <div style={{ width: '50%', margin: '32px 0 32px 0 ', border: '0.5px solid #8B9394' }} />
            {isEditMode && !loading &&
              <>
                <GetReadyList
                  reportId={params.id || store?.cmsCurrentReport?.id}
                  initialTasks={store?.cmsCurrentReport?.get_ready_checkboxes || []}
                  title={store?.cmsCurrentReport?.get_ready_checkboxes_title || ''}
                />
                <div style={{ width: '50%', margin: '32px 0 32px 0 ', border: '0.5px solid #8B9394' }} />
                <div style={{ width: '50%' }}>
                  <Tabs
                    Reporting={false}
                    tabs={[
                      {
                        key: 'form',
                        title: 'Form',
                      },
                      {
                        key: 'notes',
                        title: 'Notes',
                      },
                      {
                        key: 'guidance',
                        title: 'Guidance',
                      },
                      {
                        key: 'definition',
                        title: 'Definition',
                      },
                    ]}
                    onClick={(key) => {
                      setActiveTab(key)
                    }}
                    active={activeTab}

                  />

                  {showTabs()[activeTab].render()
                  }
                </div>
              </>
            }
            < ButtonContainer editMode={isEditMode}>
              {isEditMode &&
                <RemoveButton onClick={() => setIsRemoveModalOpen(true)}>
                  <img src={bin} alt='delete-icon' />
                  <span>Remove this ReportSource Item</span>
                </RemoveButton> 
              }
              <Button
                text={isEditMode ? 'Save and Go Back' : 'Save'}
                onClick={handleSubmit(onSubmit)}
                type={'main'}
                style={{
                  padding: 15,
                  marginRight: 0,
                  marginLeft: '.5em',
                  marginTop: '2.3em',
                }}
              />
            </ButtonContainer>

          </NavPageWrap >
        </Wraper>
      }
    </>

  );
};

export default ReportPage;
