import { observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'
import Moment from 'react-moment'
import ButtonOrange from '../../../../../components/common/ButtonOrange'
import { useStore } from '../../../../../mobx-store/context'
import ActivityModal from '../../ManageTeam/Modal'
import { Info, Wrap } from './style'
import { api } from '../../../../../api'
import { message } from 'antd'

const Right = observer(({ control, onSubmit, loading, memberType }) => {
  const store = useStore()
  const [visible, setVisible] = useState(false)
  const [activitVisible, setActivitVisible] = useState(false)

  const member = store.member
  const user = store.user[0]

  const reactivateUser = (reactivationType) => {
    api(`/members/${member.id}/${reactivationType}/`, {}, 'POST').then((res) => {
      if (!res.errors) {
        store.setSingleValue('member', res)
        reactivationType === 'reactivate'
          ? message.success('User has been reactivated')
          : message.success('User has been unlocked')
      }
    })
  }

  return (
    <Wrap activeModal={visible || activitVisible} >
      {visible && (
        <ActivityModal
          userId={member.id}
          userName={`${member.first_name} ${member.last_name}`}
          onClose={() => {
            store.setSingleValue('activityModalOpen', false)
            setVisible(false)
          }}
        />
      )}
      {activitVisible &&
        <ActivityModal
          settingsActivity={true}
          userId={member.id}
          userName={`${member.first_name} ${member.last_name}`}
          onClose={() => {
            store.setSingleValue('activityModalOpen', false)
            setActivitVisible(false)
          }}
        />
      }
      <Info>
        <div>
          User:
          <span>
            {member?.first_name} {member?.last_name}
          </span>
        </div>
        <div>
          Joined:
          <span>
            <Moment format={'M/DD/YY'}>{member?.date_joined}</Moment>
          </span>
        </div>
        <div>
          Status:
          <span>{member?.is_active ? 'Active' : 'Inactive'}</span>
        </div>
      </Info>
      {member && member.is_inactive && user.member_type === 'admin' && (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
          <div style={{ fontFamily: 'var(--bold)', fontSize: 14, color: '#F24722', marginTop: 20 }}>
            Inactive for more than 90 Days
          </div>
          <ButtonOrange
            text={`Cancel Inactive Status `}
            style={{ color: '#F24722', fontFamily: 'var(--bold)', display: 'flex', alignItems: 'flex-end' }}
            onClick={() => {
              reactivateUser('reactivate')
            }}
          />
        </div>
      )}
      {member && member.is_locked && user.member_type === 'admin' && (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
          <div style={{ fontFamily: 'var(--bold)', fontSize: 14, color: '#F24722', marginTop: 20 }}>
            Blocked: Too many incorrect login attempts
          </div>
          <ButtonOrange
            text={`Remove Blocked Status`}
            style={{ color: '#F24722', fontFamily: 'var(--bold)', display: 'flex', alignItems: 'flex-end' }}
            onClick={() => {
              reactivateUser('unlock')
            }}
          />
        </div>
      )}
      {member && (
        <>
          <ButtonOrange
            text={`View ${member.first_name}’s Activity Log`}
            style={{ margin: '10px 0' }}
            onClick={() => {
              store.setSingleValue('activityModalOpen', true)
              setVisible(true)
            }}
          />
          <ButtonOrange
            text={`View ${member.first_name}’s Settings Activity Log`}
            style={{ margin: '10px 0' }}
            onClick={() => {
              store.setSingleValue('activityModalOpen', true)
              setActivitVisible(true)
            }}
          />
        </>
      )}
    </Wrap>
  )
})

export default Right
