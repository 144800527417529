import { HeaderWrapper, Title, SubTitle } from '../style';
import ScheduleInput from '../../Main/Demo/ScheduleInput';
const Header = () => {
  return (
    <HeaderWrapper>
      <Title>ReSource Guides</Title>
      <SubTitle>
        See how ReSource can be used to view summaries of existing laws and regulations for creating and responding <br />
        to RFPs, creating P&Ps, onboarding new clients in new markets, developing new programs or networks.
      </SubTitle>
      <div style={{ marginTop: 40, marginBottom: 40 }}>
        <ScheduleInput border={true} service={'Resource'} />
      </div>
    </HeaderWrapper>
  )
};

export default Header;