import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Input from '../../../../../components/common/Input'
import { useStore } from '../../../../../mobx-store/context'
import ResourceModal from '../../../components/ResourceAccessModal/resourceModal'
import { proposedSourceInputs } from './inputs'
import { api } from '../../../../../api'

const ProposedSourceTab = ({
  control,
  errors,
  showFields,
  memberType,
  register,
  active,
  handleShowField,
}) => {
  const store = useStore()
  const history = useHistory()
  const user = store.user?.[0]
  const company = store.company?.[0]

  if (!user?.have_access_to_proposed_source || !company?.have_access_to_proposed_source)
    return (
      <ResourceModal
        type='proposedSource'
        to='/edit-profile'
        onClose={() => history.push('/edit-profile')}
      />
    )

  return (
    <>
      <input
        type='hidden'
        name='have_access_to_proposed_source'
        value={true}
        ref={register}
      />
      {proposedSourceInputs().map(
        ({
          key,
          name,
          label,
          type,
          placeholder,
          disabled,
        }) =>
        (
          <Input
            name={name}
            label={label}
            type={type}
            control={control}
            key={key}
            placeholder={placeholder}
            disabled={disabled}
          />
        )
      )}
    </>
  )
}

export default ProposedSourceTab
