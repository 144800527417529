import React from 'react'
import { Image, Wrap, Title, SubTitle, Description } from './styles'

const TeamMember = ({
  name,
  position,
  description,
  photo,
  status,
}) => {
  return (
    <Wrap>
      <Image src={photo} alt={position} status={status} />
      <Title status={status}>{name}</Title>
      <SubTitle status={status}>{position}</SubTitle>
      {status === 'leadership' && <Description status={status}>{description}</Description>}
    </Wrap>
  )
}

export default TeamMember
