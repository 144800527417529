import React from "react"
import { useStore } from "../../../mobx-store/context"
import moment from "moment"
import Moment from "react-moment"
import { Title, Text, BannerContainer } from "./trialBanner.style"

export const TrialBanner = () => {
  const store = useStore()
  const registrationDate = moment(store.company[0].trial_end).format('MM/DD/YYYY')

  return (
    <BannerContainer>
      <Title>Trial Account | Ends in {
        <Moment
          diff={moment().format('MM/DD/YYYY')}
          unit="days">
          {registrationDate}
        </Moment>} Days
      </Title>
      <Text>
      to continue reach out to <a
          href='mailto:contact@pbmsource.com'
          style={{ color: 'var(--blue)' }}
        >
          contact@pbmsource.com
        </a>
      </Text>
    </BannerContainer>
  )

}