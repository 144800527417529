import { Checkbox, message } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import Moment from 'react-moment'
import { api } from '../../../../api'
import mastercard from '../../../../assets/images/Mastercard.svg'
import visa from '../../../../assets/images/Visa.svg'
import Button from '../../../../components/common/Button/button'
import ButtonOrange from '../../../../components/common/ButtonOrange'
import Input from '../../../../components/common/Input'
import { getError } from '../../../../helpers/helpers'
import useDidMountEffect from '../../../../hooks/useEffectNotOnPageLoad'
import { useStore } from '../../../../mobx-store/context'
import Card from '../../../Profile/pages/Billing/Card'
import { Agreement } from '../billing.style'
import { Row, Wrap } from './style'

const Evalon = observer(
  ({
    control,
    handleSubmit,
    errors,
    smallLabel,
    showSkip,
    loading,
    isEditing,
    mainBtnText,
    onClose,
    noCancel,
    onSkip,
    unregister,
    register,
    isResource,
    isReportSource,
    isProposedSource,
    handleShowModal,
    addingCard,
    reset,
    setEditing,
    setAddingCard,
  }) => {
    const store = useStore()
    const isResourceRegisterPassed =
      store.company[0].is_resource_registration_passed
    const minYear = +new Date().getFullYear().toString().substring(2)
    const [required, setRequired] = useState(!isResourceRegisterPassed)
    const yearRef = useRef()
    const cvvRef = useRef()

    const card = store.billingCards?.filter((item) => item.is_primary)?.[0]
    const isResourceOffline =
      isResource && store.resourceTariffInfo?.payment_status === 'offline'

    const onChange = (e, type) => {
      const elem =
        e.target.parentElement.parentElement.parentElement.parentElement
          .nextSibling
      const value = e.target.value

      if (type === 'month') {
        if (value.length === 2 && value > 0 && value <= 12)
          elem.nextSibling.getElementsByTagName('input')[0].focus()
      } else {
        if (value.length === 2 && value >= minYear)
          elem.getElementsByTagName('input')[0].focus()
      }
    }

    const unregisterFields = () => {
      if (!unregister) return null
      unregister('card_number')
      unregister('card_holder_name')
      unregister('month')
      unregister('year')
      unregister('cvv')
    }

    const registerFields = () => {
      if (!register) return null
      register('card_number')
      register('card_holder_name')
      register('month')
      register('year')
      register('cvv')
    }

    useDidMountEffect(() => {
      if (!required) {
        unregister && unregisterFields()

        onSkip()
      } else {
        register && registerFields()

        handleSubmit()
      }
    }, [required])

    const patchCompany = () => {
      api(
        `/resources/my-tariff/`,
        {
          auto_renew_subscription:
            !store.resourceTariffInfo?.auto_renew_subscription,
        },
        'PATCH'
      )
        .then((data) => {
          if (data.errors) return message.error(getError(data))
        })
        .catch((err) => console.log(err))
    }

    const onSetOfflinePayment = () => {
      unregisterFields()
      handleShowModal()
    }

    const handleCartEdit = (data) => {
      setEditing(true)
      reset(data)
    }

    const onClick = () => {
      if (isResourceRegisterPassed) {
        if (addingCard) {
          registerFields()
        }
        return handleSubmit()
      }
      if (required) {
        registerFields()
        handleSubmit()
      } else {
        setRequired(true)
      }
    }

    useEffect(() => {
      if (!store.billingCards) store.getBillingCards()
    }, [])

    useEffect(() => {
      if (store.billingCards) unregisterFields()
    }, [store.billingCards])

    useEffect(() => {
      isResource && reset(card)
    }, [card])

    return (
      <Wrap>
        {(isResource || isReportSource || isProposedSource) && (
          <ButtonOrange
            text='Pay by ACH or Check'
            style={{ margin: '15px 0' }}
            onClick={onSetOfflinePayment}
          />
        )}

        {isResourceOffline && (
          <div
            style={{
              color: 'var(--blue)',
              fontFamily: 'var(--medium)',
              fontSize: 14,
              marginTop: 5,
            }}
          >
            Currently Paying by ACH or Check - Contact support@pbmsource.com for
            any changes or issues.
          </div>
        )}

        {card && !isEditing && !addingCard && !isResourceOffline ? (
          <Card data={card} onClick={handleCartEdit} />
        ) : (
          !isResourceOffline && (
            <>
              <Input
                label={"Card Holder's Name"}
                labelCustomComponent={
                  <div style={{ fontFamily: 'var(--light)', marginLeft: 5 }}>
                    (as it appears on card)
                  </div>
                }
                control={control}
                name={'card_holder_name'}
                placeholder={"Card Holder's Name"}
                validation={{ required }}
                error={errors?.card_holder_name && 'Enter card holder name'}
                smallLabel={smallLabel}
              />
              <Input
                label={'Card Number'}
                control={control}
                name={'card_number'}
                placeholder={'Card Number'}
                validation={{ required, minLength: 14, maxLength: 19 }}
                labelCustomComponent={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={mastercard}
                      alt='mastercard'
                      style={{ marginLeft: 5 }}
                    />
                    <img src={visa} alt='visa' style={{ marginLeft: 5 }} />
                  </div>
                }
                disabled={isEditing}
                error={errors?.card_number && 'Enter valid card number'}
                smallLabel={smallLabel}
                mask={isEditing ? null : '9999999999999999999'}
              />
              <Row>
                <Input
                  label={'Expiration'}
                  control={control}
                  name={'month'}
                  placeholder={'MM'}
                  validation={{ required, minLength: 2, min: 1, max: 12 }}
                  mask={'99'}
                  error={errors?.month && 'Enter valid month'}
                  smallLabel={smallLabel}
                  handleChange={(e) => onChange(e, 'month')}
                  styleError={{ position: 'absolute' }}
                />
                <span> / </span>
                <Input
                  control={control}
                  name={'year'}
                  placeholder={'YY'}
                  validation={{
                    required,
                    minLength: 2,
                    min: minYear,
                  }}
                  mask={'99'}
                  error={errors?.year && 'Enter valid year'}
                  smallLabel={smallLabel}
                  forwardRef={yearRef}
                  handleChange={(e) => onChange(e)}
                  styleError={{ position: 'absolute' }}
                />
                <Input
                  label={'CVV'}
                  control={control}
                  name={'cvv'}
                  placeholder={'___'}
                  validation={{ required, minLength: 3 }}
                  mask={'999'}
                  type={'password'}
                  error={errors?.cvv && 'Enter valid cvv'}
                  smallLabel={smallLabel}
                  forwardRef={cvvRef}
                  styleError={{ position: 'absolute' }}
                />
              </Row>
              <Row
                style={{
                  gridTemplateColumns: noCancel ? '1fr' : '1fr 1fr 1fr',
                  marginTop: 15,
                }}
              >
                <div />
              </Row>
            </>
          )
        )}
        {!isEditing && card && !addingCard && !isResourceOffline && (
          <Button
            text={'+ Add New Payment Method'}
            border
            style={{ margin: '10px 0' }}
            onClick={() => setAddingCard(true)}
          />
        )}
        <Button
          text={
            mainBtnText || card
              ? isEditing || addingCard
                ? 'Save Card'
                : 'Pay By Credit Card'
              : 'Proceed with Payment'
          }
          onClick={isResourceOffline ? onSetOfflinePayment : onClick}
          style={{
            width: '100%',
            marginTop: 15,
            marginBottom: 15,
            backgroundColor: 'var(--blue)',
          }}
          loading={loading}
        />
        {!noCancel && addingCard && (
          <Button
            text={'Cancel'}
            style={{ width: '100%', backgroundColor: 'var(--orange)' }}
            onClick={onClose}
          />
        )}

        {isResource && !store.billingCards && (
          <Agreement>
            <Checkbox
              onChange={(e) => patchCompany(e.target.checked)}
              style={{ marginRight: '.5em' }}
              checked={store.resourceTariffInfo?.auto_renew_subscription}
            />
            Recurring payments{' '}
            <span>
              (Next payment{' '}
              <Moment format={'MM/DD/YYYY'}>
                {store.resourceTariffInfo?.next_payment_date}
              </Moment>
              )
            </span>
          </Agreement>
        )}

        {showSkip && !isResource && !isReportSource && !isProposedSource && (
          <Button
            text={'Skip Payment and Try for Free'}
            onClick={() => {
              if (!required) {
                unregisterFields()
                onSkip()
              } else {
                setRequired(false)
              }
            }}
            type={'green'}
            style={{
              marginTop: '1em',
              width: '100%',
              background: '#fff',
              color: 'var(--green)',
            }}
          />
        )}
      </Wrap>
    )
  }
)

export default Evalon
