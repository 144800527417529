import React from 'react'
import { Close, Overlay, Wrap } from './style'
import close from '../../../assets/images/close-grey.svg'

const Modal = ({ children, onClose, style, id }) => (
  <Overlay>
    <Wrap style={style} id={id}>
      <Close src={close} alt={'close-icon'} onClick={onClose}/>
      {children}
    </Wrap>
  </Overlay>
)

export default Modal
