import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import plusBlue from '../../../assets/images/plus-blue.svg'
import CustomPromptModal from '../../../components/common/CustomPrompt/CustomPromptModal'
import ItemsShowing from '../../../components/common/TableItemsShowing'
import { checkRole, getLocalStorageItem, getReportLocalStorageItem } from '../../../helpers/helpers'
import { useStore } from '../../../mobx-store/context'
import {
  columnsCommon,
  columnsUltraLite,
  columnsCommonReports,
  columnsTasks,
  columnsLicenceTasksReporting,
  columnsLicenceTasksPending,
  columnsTasksReports,
  getRecord,
  licensecolumnsCommon,
  licenceColumnsRenewals,
  licensecolumnsHeld,
} from './columns'
import LicenseSelectFilter from './LicenseSelectFilter'
import Empty from './EmptyTable/empty'
import {
  Header,
  HeaderButton,
  HeaderTitle,
  TableStyled,
  Wrap,
  MonthName,
  Clear,
} from './table.style'

const tableHeaderButton = {
  main: {
    title: 'View My Tasks',
    key: 'myTasks',
  },
  myTasks: {
    title: 'View Tracking',
    key: 'main',
  },
  closed: {
    title: 'View Tracking',
    key: 'main',
  },
}

const tableHeaderButtonSecond = {
  main: {
    title: 'View Closed',
    key: 'closed',
  },
  myTasks: {
    title: 'View Closed',
    key: 'closed',
  },
  closed: {
    title: 'View My Tasks',
    key: 'myTasks',
  },
}

const Table = observer(
  ({
    style,
    getCurrentLegislative,
    count,
    archived,
    all,
    isTrial,
    currentPage,
    onCurrentPageChange,
    activeTab,
    isMyTasks,
    complianceItem,
    onCreateNew,
    setTouched,
    setEditing,
    setCreateItemVisible,
    touched,
    isReportSource = false,
    isLicenseSource = false,
  }) => {
    const history = useHistory()
    const store = useStore()
    const params = useParams()
    const [sorter, setSorter] = useState({})
    const [sortLoading, setSortLoading] = useState(false)
    const [visible, setVisible] = useState(false);
    const [active, setActive] = useState(null)
    const [dataSource, setDataSource] = useState([])

    const trackingMode = store?.company[0]?.compliance_source_mode
    const showModeBlocks = isReportSource
      ? true
      : trackingMode === 'compliance_source' ? true : false

    const sortLegislatives = (direction, mutatedDirection, dataIndex, page) => {
      if (direction) {
        store.setSingleValue('searchableObject', {
          ...store.searchableObject,
          ordering: mutatedDirection[direction] + dataIndex,
        })
      } else {
        const newObj = store.searchableObject

        delete newObj.ordering

        store.setSingleValue('searchableObject', {
          ...newObj,
        })
      }
      store.loadLegislatives({
        page,
        handleLoading: () => setSortLoading(false),
      }, isReportSource, isLicenseSource)
    }

    const sort = (direction, dataIndex, page) => {
      const mutatedDirection = {
        ascend: '',
        descend: '-',
      }
      if (activeTab === 'myTasks') {
        if (direction) {
          store.setSingleValue('searchableObjectTasks', {
            ...store.searchableObjectTasks,
            ordering: mutatedDirection[direction] + dataIndex,
          })
        } else {
          const newObj = store.searchableObjectTasks

          delete newObj.ordering

          store.setSingleValue('searchableObjectTasks', {
            ...newObj,
          })
        }
        store.loadTasks({
          page,
          handleLoading: () => setSortLoading(false),
        }, isReportSource)
      } else {
        sortLegislatives(direction, mutatedDirection, dataIndex, page,)
      }
    }

    const onClear = () => {
      store.setSingleValue('filterMode', false)
      store.setSingleValue('activeSearchMonth', null)
      isLicenseSource
        ? store.setSingleValue('searchableObject', { tab_name: activeTab })
        : store.setSingleValue('searchableObject', {})

      store.loadLegislatives({ page: 1, page_size: 20 }, isReportSource, isLicenseSource)
    }

    const handleTableChange = (sorter, page) => {
      setSorter(sorter)
      setSortLoading(true)
      sort(
        sorter.order,
        Array.isArray(sorter.field) ? sorter.field[1] : sorter.field,
        page
      )
    }

    const onClick = (record) => {
      localStorage.setItem('reportInLs', 'false')
      const id = getRecord({ record, activeTab, isMyTasks, isReportSource, isLicenseSource })?.id
      const type = getRecord({ record, activeTab, isMyTasks, isReportSource, isLicenseSource })?.type || false
      isLicenseSource && localStorage.setItem('noResetForReportInLs', 'false')
      isLicenseSource && (type === 'report'
        || params.tabName === 'apcd'
        ? localStorage.setItem('reportInLs', 'true')
        : localStorage.setItem('reportInLs', 'false'))
      getCurrentLegislative(
        getRecord({ record, activeTab, isMyTasks, isReportSource, isLicenseSource, })?.id
      )
      if (!isMyTasks && Object.keys(store.searchableObject) !== 0 && store.currentItemStatusChanged === true) {
        store.loadLegislatives({ page: currentPage || 1 }, isReportSource, isLicenseSource)
        store.setSingleValue('currentItemStatusChanged', false)
      }

      setActive(+id)
      history.push(`/${isLicenseSource
        ? 'license-source'
        : isReportSource
          ? 'report-source'
          : 'tracking'}/${activeTab}/?${isLicenseSource
            ? 'license_item'
            : isReportSource
              ? 'report'
              : 'compliance_item'
        }=${id}`)

      const readItems =
        isReportSource
          ? JSON.parse(localStorage.getItem('readReportItems'))
          : isLicenseSource ?
            JSON.parse(localStorage.getItem('readLicenceItems'))
            : JSON.parse(localStorage.getItem('readItems'))

      isReportSource
        ? localStorage.setItem(
          'readReportItems',
          JSON.stringify({ ...readItems, [id]: true })
        )
        : isLicenseSource ?
          localStorage.setItem(
            'readLicenceItems',
            JSON.stringify({ ...readItems, [id]: true })
          )
          : localStorage.setItem(
            'readItems',
            JSON.stringify({ ...readItems, [id]: true })
          )
    }

    const onConfirm = (record) => {
      setTouched(false);
      setVisible(false);
      onClick(record)
    }

    useEffect(() => {
      setSorter(null)
    }, [archived, activeTab])

    const getLicenseSourceTitle = () => {
      if (archived) return 'Closed Renewals/Reports';

      switch (activeTab) {
        case 'renewals_reporting':
          return 'Renewals/Reports Currently Being Tracked';
        case 'apcd':
          return 'APCD Currently Being Tracked';
        case 'held':
          return 'Licenses Held';
        case 'monitor':
          return 'Licenses To Monitor';
        case 'na':
          return 'Licenses That Are N/A';
        case 'need':
          return 'Licenses Needed';
        case 'pending_tasks':
          return 'Pending Assessments tasks';
        case 'assigned':
          return 'Renewals & Reporting tasks';
        case 'all_renewals':
          return 'All Items';
        default:
          return 'Licenses Not Assessed';
      }
    };

    const getTableTitle = () => {
      if (archived) return isLicenseSource ? 'Closed Renewals/Reports' : 'Tracking Archive';

      if (all) {
        if (isReportSource) return 'All Reports';
        if (isLicenseSource) return 'Potential Licenses';
        return 'All Compliance Items';
      }

      if (isMyTasks) return 'My Tasks';

      if (isReportSource) {
        return store.activeSearchMonth
          ? 'Reports Currently Being Tracked:'
          : 'Reports Currently Being Tracked';
      }

      return isLicenseSource ? getLicenseSourceTitle() : 'Compliance Items Currently Being Tracked';
    };

    const tableTitle = getTableTitle();

    useEffect(() => {
      if (isReportSource) {
        if (store.searchableObject?.status === 'unread') {
          const data = store.legislatives.results.filter(
            (item) => !JSON.parse(localStorage.getItem('readReportItems'))[item.id]
          )

          return setDataSource(data)
        }
        if (isLicenseSource) {
          const data = store.legislatives.results.filter(
            (item) => !JSON.parse(localStorage.getItem('readLicenseItems'))[item.id]
          )

          return setDataSource(data)
        }
        if (store.searchableObject?.status === 'read') {
          const data = store.legislatives.results.filter(
            (item) => JSON.parse(localStorage.getItem('readReportItems'))[item.id]
          )

          return setDataSource(data)
        }

        return setDataSource(store?.legislatives?.results)
      } else {
        if (store.searchableObject?.status === 'unread') {
          const data = store.legislatives.results.filter(
            (item) => !JSON.parse(localStorage.getItem('readItems'))[item.id]
          )

          return setDataSource(data)
        }
        if (store.searchableObject?.status === 'read') {
          const data = store.legislatives.results.filter(
            (item) => JSON.parse(localStorage.getItem('readItems'))[item.id]
          )

          return setDataSource(data)
        }

        return setDataSource(store?.legislatives?.results)
      }
    }, [isMyTasks, store.legislatives, store.legislatives?.results, isReportSource])

    useEffect(() => {
      if (store.newItemStatus?.id && store.newItemStatus?.status) {
        setDataSource((prevDataSource) =>
          prevDataSource.map((item) =>
            item.id === store.newItemStatus.id
              ? { ...item, status: store.newItemStatus.status }
              : item
          )
        )
      }
    }, [store.newItemStatus])

    useEffect(() => {
      if (isMyTasks) {
        setDataSource(store.tasks?.results)
      }
    }, [isMyTasks, store.tasks])

    const isLoading = isMyTasks ? !store.tasks : !store.legislatives

    const canCreateNew =
      !isMyTasks &&
      !archived &&
      showModeBlocks &&
      !isLicenseSource &&
      checkRole(
        isReportSource
          ? [store.user[0].report_source_role]
          : [store.user[0].member_type],
        ['admin', isReportSource ? 'report_owner' : 'compliance_owner']
      );

    if (!store.topics) return null

    return (
      <Wrap style={style}>
        <Header>
          <HeaderTitle>
            {tableTitle}
            {store.activeSearchMonth &&
              <>
                <MonthName>{store.activeSearchMonth}</MonthName>
                <Clear onClick={onClear}>(clear)</Clear>
              </>
            }

          </HeaderTitle>
          <div style={{ display: 'flex', }}>
            {canCreateNew && (
              <HeaderButton onClick={onCreateNew} style={{ marginRight: 10 }}>
                Create Your Own
                <img src={plusBlue} alt='plus-icon' />
              </HeaderButton>
            )}
            {isLicenseSource && (activeTab === 'all')
              &&
              <LicenseSelectFilter activeTab={activeTab} type={'pending'} />
            }
            {isLicenseSource && (activeTab === 'all_renewals' || activeTab === 'renewals_reporting')
              &&
              <LicenseSelectFilter activeTab={activeTab} type={'renewals'} />
            }
          </div>
        </Header>

        <TableStyled
          locale={{
            emptyText: <Empty isReportSource={isReportSource} isLicenseSource={isLicenseSource} />,
          }}
          dataSource={dataSource}
          rowKey={(record) => record.id} // Обязательно должны быть разные ID !!!!!
          columns={
            isReportSource
              ? isMyTasks
                ? columnsTasksReports({ store, sorter, isMyTasks, activeTab })
                : columnsCommonReports({ store, sorter, isMyTasks, activeTab })
              : isLicenseSource
                ? activeTab === 'assigned'
                  ? columnsLicenceTasksReporting({
                    store,
                    sorter,
                    isMyTasks,
                    activeTab,
                    reference_number: {
                      width: 200,
                    },
                  })
                  : activeTab === 'pending_tasks' ?
                    columnsLicenceTasksPending({
                      store,
                      sorter,
                      isMyTasks,
                      activeTab,
                      reference_number: {
                        width: 200,
                      },
                    })
                    : activeTab === 'held'
                      ? licensecolumnsHeld({ store, sorter, isMyTasks, activeTab })
                      : (activeTab === 'renewals_reporting'
                        || activeTab === 'apcd'
                        || activeTab === 'closed'
                        || activeTab === 'all_renewals')
                        ?
                        licenceColumnsRenewals({ store, sorter, isMyTasks, activeTab })
                        : licensecolumnsCommon({ store, sorter, isMyTasks, activeTab })
                : isMyTasks
                  ? columnsTasks({
                    store,
                    sorter,
                    isMyTasks,
                    activeTab,
                    reference_number: {
                      width: 200,
                    },
                  })
                  : store?.company[0]?.compliance_source_mode === 'compliance_source_ultra_lite'
                    ? columnsUltraLite({ store, sorter, isMyTasks, activeTab })
                    : columnsCommon({ store, sorter, isMyTasks, activeTab })
          }
          onChange={(pagination, _, sorter) => {
            handleTableChange(sorter, pagination.current)
            onCurrentPageChange(pagination.current)
          }}
          loading={isLoading || sortLoading}
          size='small'
          pagination={{
            position: ['bottomLeft'],
            pageSize: isReportSource || isLicenseSource ? 20 : isTrial ? 10 : 20,
            hideOnSinglePage: false,
            total: isReportSource || isLicenseSource ? count : isTrial ? 10 : count,
            disabled: isLoading,
            current: currentPage,
            showSizeChanger: false,
          }}
          rowClassName={(record) => {
            if (isReportSource) {
              if (+getRecord({ record, activeTab, isMyTasks, isReportSource, isLicenseSource })?.id === (+params.id || +complianceItem)) {
                return 'active-row'
              }
              if (!getLocalStorageItem('readReportItems', +getRecord({ record, activeTab, isMyTasks, isReportSource, isLicenseSource })?.id)) {
                return 'unread-row'
              }
            } else if (isLicenseSource) {
              if (+getRecord({ record, activeTab, isMyTasks, isReportSource, isLicenseSource })?.id === (+params.id || +complianceItem)) {
                return 'active-row'
              }
              if (!getLocalStorageItem('readLicenceItems', +getRecord({ record, activeTab, isMyTasks, isReportSource, isLicenseSource })?.id)) {
                return 'unread-row'
              }
            } else {
              if (+getRecord({ record, activeTab, isMyTasks, isReportSource })?.id === (+params.id || +complianceItem)) {
                return 'active-row'
              }
              if (!getLocalStorageItem('readItems', +getRecord({ record, activeTab, isMyTasks, isReportSource, isLicenseSource })?.id)) {
                return 'unread-row'
              }
            }
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                if (touched) { setVisible(true) }
                else {
                  onClick(record)
                }
              },
            }
          }}
        />

        <ItemsShowing
          page={currentPage}
          pageSize={isTrial ? 10 : 20}
          total={isMyTasks ? store.tasks?.count : store.legislatives?.count}
          style={{
            textAlign: 'right',
            marginTop: -31,
            paddingRight: 10,
            width: 'fit-content',
            marginBottom: 10,
          }}
        />
        {visible &&
          <CustomPromptModal
            onCancel={() => setVisible(false)}
            onConfirm={() => {
              setTouched(false);
              setVisible(false);
              setEditing(false);
              setCreateItemVisible(false);
            }}
          />
        }
      </Wrap>
    )
  }
)

Table.propTypes = {
  style: PropTypes.object,
}

export default Table

export const statuses = {
  read: ['Read', 'var(--blue)'],
  unread: ['Unread', 'var(--orange)'],
  in_progress: ['In progress', 'var(--green)'],
  closed: ['Closed', 'grey'],
  all_tasks_completed: ['All Tasks Completed', 'var(--green)'],
  complete: ['Completed', 'var(--green)'],
  incomplete: ['Incomplete', 'var(--orange)'],
  unassigned: ['Unassigned', 'var(--orange)'],
  monitor: ['Monitor', '#CBE0EC'],
  in_progress_deficiency: ['In progress', 'var(--green)'],
  closed_submitted: ['Closed-Submitted', 'grey'],
  closed_na: ['Closed-NA', 'grey'],
  needed: ['Needed', 'grey'],
  not_assessed: ['Not Assessed', 'grey'],
  na: ['License N/A', 'grey'],
}
export const statusesTaskReport = {
  complete: ['Completed', 'var(--green)'],
  incomplete: ['In Progress', 'var(--orange)'],

}

export const reportMyTaskStatuses = {
  report_owner: ['Report Item Owner', 'var(--blue)'],
  report_owner_and_all_tasks_completed: ['Report Item Owner: All Tasks Completed', 'var(--green)'],
  report_owner_and_have_incomplete_tasks: ['Report Item Owner: Have Incomplete Tasks', 'var(--orange)'],
  all_tasks_completed: ['All Tasks Completed', 'var(--green)'],
  have_incomplete_tasks: ['Have Incomplete Tasks', 'var(--orange)'],
}

export const trackingMyTaskStatuses = {
  compliance_owner: ['Compliance Item Owner', 'var(--blue)'],
  compliance_owner_and_all_tasks_completed: ['Compliance Item Owner: All Tasks Completed', 'var(--green)'],
  compliance_owner_and_have_incomplete_tasks: ['Compliance Item Owner: Have Incomplete Tasks', 'var(--orange)'],
  all_tasks_completed: ['All Tasks Completed', 'var(--green)'],
  have_incomplete_tasks: ['Have Incomplete Tasks', 'var(--orange)'],
}

export const renewalsLicensesMyTaskStatuses = {
  license_owner: ['License Item Owner', 'var(--blue)'],
  renewal_owner: ['Renewal Item Owner', 'var(--blue)'],
  license_owner_and_all_tasks_completed: ['License Item Owner: All Tasks Completed', 'var(--green)'],
  license_owner_and_have_incomplete_tasks: ['License Item Owner: Have Incomplete Tasks', 'var(--orange)'],
  renewal_owner_and_have_incomplete_tasks: ['Renewal Item Owner: Have Incomplete Tasks', 'var(--orange)'],
  renewal_owner_and_all_tasks_completed: ['Renewal Item Owner: All Tasks Completed', 'var(--green)'],
  all_tasks_completed: ['All Tasks Completed', 'var(--green)'],
  have_incomplete_tasks: ['Have Incomplete Tasks', 'var(--orange)'],
}
