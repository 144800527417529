import styled from 'styled-components'

export const Wrap = styled('div')`
  width: 1142px;
  padding-top: 90px;
  padding-bottom: 200px;
  margin: 0 auto;
  min-height: calc(100vh - 50px);
`

export const Title = styled('div')`
  padding-top: 20px;
  font-size: 40px;
  color: var(--text);
  font-family: var(--regular);
`

export const Description = styled('div')`
  font-size: 16px;
  font-family: var(--regular);
  color: var(--text);
  margin-top: 10px;
`
