import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useStore } from '../../../../mobx-store/context'
import { PageTitle } from '../../components/common/style'
import PageWrap from '../../components/Container'
import TabsNav from '../../components/TabsNav/tabsNav'
import ComplianceTab from './tabs/complianceTab'
import ResourceTab from './tabs/resourceTab'
import ReportSourceTab from './tabs/reportSourceTab'
import ProposedSourceTab from './tabs/proposedSourceTab'
import LicenseSourceTab from './tabs/licenseSourceTab'

const tabs = {
  tracking: {
    button: 'ComplianceSource',
    component: <ComplianceTab />,
    link: '/product-settings/tracking',
    isDisabled: (user) => !user?.have_access_to_tracking,
  },
  resource: {
    button: 'ReSource',
    component: <ResourceTab />,
    link: '/product-settings/resource',
    isDisabled: (user) => !user?.have_access_to_resource,
  },
  reportSource: {
    button: 'ReportSource',
    component: <ReportSourceTab />,
    link: '/product-settings/reportSource',
    isDisabled: (user) => !user?.have_access_to_report_source,
  },
  proposedSource: {
    button: 'ProposedSource',
    component: <ProposedSourceTab />,
    link: '/product-settings/proposedSource',
    isDisabled: (user) => !user?.have_access_to_proposed_source,
  },
  licenseSource: {
    button: 'LicenseSource',
    component: <LicenseSourceTab />,
    link: '/product-settings/licenseSource',
    isDisabled: (user) => !user?.have_access_to_license_source,
  },
}

const ProductSettings = observer(() => {
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const [active, setActive] = useState()
  const user = store.user?.[0]

  const tab =
    params.type ||
    ((user?.have_access_to_tracking && (user?.member_type === 'admin' || user?.member_type === 'compliance_owner'))
      ? 'tracking'
      : user?.resource_role === 'admin'
        ? 'resource'
        : (user?.have_access_to_report_source && (user?.report_source_role === 'admin' || user?.report_source_role === 'report_owner'))
          ? 'reportSource'
          : (user?.have_access_to_license_source && (user?.license_source_role === 'admin' || user?.license_source_role === 'license_owner'))
            ? 'licenseSource'
          : 'proposedSource'
    )

  useEffect(() => {
    setActive(tab)
  }, [params.type, store.user])

  useEffect(() => {
    if (!store.members.length) store.loadMembers()
  }, [])

  return (
    <PageWrap>
      <PageTitle>Product Settings</PageTitle>
      <TabsNav
        tabs={tabs}
        active={tab}
        tabsParams={store.user?.[0]}
        onRedirectTo={(page) => history.push(`/product-settings/${page}`)}
      />
      {tabs[tab].component}
    </PageWrap>
  )
})

export default ProductSettings
