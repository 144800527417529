import styled from 'styled-components'
import { DescriptionGlobal } from '../../Compliance/styles'

export const Wrap = styled('div')`
  width: 1240px;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 100px;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 50px 15px 50px 15px;
  }
`

export const Title = styled('div')`
  text-align: center;
  font-family: var(--bold);
  font-size: 32px;
  color: var(--text);
  @media (max-width: 1200px) {
    font-size: 24px;
  }
`
export const Description = styled(DescriptionGlobal)`
text-align: center;
  font-size: 24px;
  line-height: 28px;
  margin-top: 15px;
  span {
    font-family: var(--semiBold);
  }
  @media (max-width: 1200px) {
    font-size: 18px;
    margin-top: 5px;
  }
`

export const Grid = styled('div')`
  display: grid;
  margin: 60px 0px 0px 0px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 2px
`
