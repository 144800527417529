import React from 'react'
import img from '../../../assets/images/resource-on-hold.svg'
import ButtonOrange from '../../../components/common/ButtonOrange/index'
import TopLine from '../../../components/TopLine/topLine'
import RestrictModal from '../../Auth/components/Modal'

const ResourceOnHold = () => {
  return (
    <>
      <TopLine />
      <RestrictModal
        title={
          <>
            ReSource is on hold <span> Locked Account</span>{' '}
          </>
        }
        underTitle='To allow you and your team access, speak to your PBMSource admin.'
        description={
          <>
            We are still processing your manual payment. If you think you
            reached this page by mistake, <br /> please{' '}
            <ButtonOrange
              text='reach out to us'
              link='mailto:contact@pbmsource.com'
              defaultLink
            />{' '}
            .
          </>
        }
        underTitleStyle={{ whiteSpace: 'nowrap', marginTop: 20 }}
        imgStyle={{ height: 200 }}
        img={img}
      />
    </>
  )
}

export default ResourceOnHold
