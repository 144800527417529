import { message } from 'antd'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { api } from '../../../api'
import flag from '../../../assets/images/flag.svg'
import flagGrey from '../../../assets/images/flag_grey.svg'
import Button from '../../../components/common/Button/button'
import Input from '../../../components/common/Input/index'
import { getError } from '../../../helpers/helpers'
import { useStore } from '../../../mobx-store/context'
import { Cancel } from '../Right/Tasks/Modal/style'
import { topInputs, topReportInputs } from './inputs'
import AddNewModal from './Modal'
import { Title, TopLine, MaterialsGroupWrap, Wrap } from './style'
import MyEditor from '../../AdminPanel/ReportEdit/ReportPage/CheckboxGroup/Editor'
import Document from '../Right/ReportMaterials/document'
import GetReadyList from './getReady'
import { filterByTopicIds } from '../../../helpers/helpers'
import { get, toJS } from 'mobx'


const CreateNewComplianceItem = observer(
  ({ onClose, editing, getCurrentLegislative, touched, isMyTasks, isReportSource, activeTab }) => {
    const store = useStore()
    const history = useHistory()
    const {
      control,
      reset,
      handleSubmit,
      errors,
      setValue,
      getValues,
      watch
    } = useForm()
    const [isStarred, setIsStarred] = useState(false)
    const [formsFile, setFormsFile] = useState(null)
    const [notesFile, setNotesFile] = useState(null)
    const [guidancesFile, setGuidancesFile] = useState(null)
    const [file, setFile] = useState(null)
    const [visible, setVisible] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [tasks, setTasks] = useState([])
    const [summary, setSummary] = useState('')

    const transformArray = array => array.map(item => item.new ? { text: item.text } : item);

    watch()

    const keys = ['compliance_date', 'effective_date', 'end_date']

    const getBody = (values, file) => {
      let formData = new FormData()

      for (let key in values) {
        if (Array.isArray(values[key]) ? values[key].length : values[key]) {
          if (key === 'adopted_date') {
            formData.append(
              'adopted_date',
              moment(values.adopted_date).format('YYYY-MM-DD')
            )
          } else if (keys.includes(key)) {
            formData.append(key, moment(values[key]).format('MM/DD/YYYY'))
          } else if (key === 'file') {
            formData.append('reference_document', file)
          } else {
            formData.append(key, values[key])
          }
        }
      }
      formData.append('summary', summary)
      formData.append('is_starred', isStarred)

      return formData
    }

    const getReportBody = (values) => {
      let formData = new FormData()

      for (let key in values) {
        if (Array.isArray(values[key]) ? values[key].length : values[key]) {
          if (key === 'report_due_date') {
            formData.append(
              'report_due_date',
              moment(values.report_due_date).format('YYYY-MM-DD')
            )
          }
          else if (key === 'reporting_entities') {
            formData.append('reporting_entity', values.reporting_entities)
          }
          else {
            formData.append(key, values[key])
          }
        }
      }
      formData.append('is_starred', isStarred)
      formData.append('get_ready_checkboxes', JSON.stringify(transformArray(tasks)))

      return formData
    }

    const getReportMaterialsBody = (values, file, name) => {
      let formData = new FormData()

      for (let key in values) {
        if (Array.isArray(values[key]) ? values[key].length : values[key]) {
          if (key === name) {
            formData.append('description', values[key])
          } else if (key === `${name}_file`) {
            formData.append('file', file)
          }
        }
      }
      return formData
    }

    const onSubmit = (values) => {
      const url = editing
        ? `/legislative_edit/${store.currentLegislative.id}/`
        : '/legislative_edit/'
      setIsLoading(true)

      api(
        url,
        getBody(values, file),
        editing ? 'PATCH' : 'POST',
        false,
        true
      ).then((data) => {
        setIsLoading(false)
        touched(false)
        if (data.errors) return message.error('Summary: ' + getError(data), 1)
        store.setSingleValue('currentLegislative', null)
        getCurrentLegislative(data.id)
        history.push(`/tracking/${activeTab}/?compliance_item=${data.id}`)
        editing ?
          message.success('Compliance Item Updated')
          : message.success('Compliance Item Created')
        onClose()
      })
    }

    const sendData = (materialName, data) => {
      const url = editing && store.currentLegislative?.report[materialName].length > 0
        ? `/report_company/${data.id}/${materialName}/${store.currentLegislative?.report[materialName][0].id}/`
        : `/report_company/${data.id}/${materialName}/`

      const file = (materialName) => {
        if (materialName === 'forms') {
          return formsFile
        } else if (materialName === 'notes') {
          return notesFile
        } else {
          return guidancesFile
        }
      }

      return api(url, getReportMaterialsBody(values, file(materialName), materialName),
        editing && store.currentLegislative?.report[materialName].length > 0 ? 'PUT' : 'POST', true, true)
    }

    const onReportSubmit = (values) => {
      const url = editing
        ? `/report_company/${store?.currentLegislative?.id}/report/`
        : '/report/'
      setIsLoading(true)

      api(
        url,
        getReportBody(values),
        editing ? 'PUT' : 'POST',
        false,
        true
      ).then((data) => {
        sendData('forms', data)
        sendData('notes', data)
        sendData('guidances', data)
        setIsLoading(false)
        touched(false)
        if (data.errors) return message.error('Summary: ' + getError(data), 1)
        store.setSingleValue('currentLegislative', null)
        getCurrentLegislative(data.id, null, null, true, isReportSource)
        history.push(`/report-source/${activeTab}/?report=${data.id}`)
        editing ?
          message.success('Report Item Updated')
          : message.success('Report Item Created')
        onClose()
      })
    }
    const values = getValues();

    useEffect(() => {
      if (editing) {
        isReportSource
          ?
          reset({
            ...store.currentLegislative?.report,
            report_source_jurisdictions: store.currentLegislative?.report?.jurisdiction,
            report_types: store.currentLegislative?.report?.report_type,
            reporting_entities: store.currentLegislative?.report?.reporting_entity,
            forms: store.currentLegislative?.report?.forms[0]?.description,
            notes: store.currentLegislative?.report?.notes[0]?.description,
            guidances: store.currentLegislative?.report?.guidances[0]?.description,
          })
          : reset({
            ...store.currentLegislative,
            topics: toJS(store.currentLegislative?.topics)[0],
          })
      }
    }, [editing])

    useEffect(() => {
      if (editing) {
        isReportSource && receiveGetReadyData()
      }
    }, [editing])

    useEffect(() => {
      editing && setSummary(store.currentLegislative?.summary)
    }, [])

    const receiveGetReadyData = () => {
      api(`/report_company/${store.currentLegislative.id}/get_ready_tasks_information/`, {}, 'GET').then((data) => {
        setTasks(data)
      })
    }

    useEffect(() => {
      store.getLegislativeOptions()
    }, [])

    useEffect(() => {
      (values.summary || values.notes)
        ? touched(true)
        : touched(false)
    }, [values.notes, values.summary])

    return (
      <Wrap name={editing ? 'editing-right-screen' : 'create-new-right-screen'}>
        {visible && (
          <AddNewModal
            isReportSource={isReportSource}
            type={visible}
            onClose={() => setVisible(null)}
            setValue={setValue}
            onSuccess={() => store.getLegislativeOptions()}
            value={getValues(visible)}
            values={getValues()}
            reset={reset}
            adding
            cyo={true}
          />
        )}
        <TopLine>
          <Title>
            {isReportSource
              ? editing
                ? 'Update Report'
                : 'Create New Report'
              : editing
                ? 'Update Compliance Item'
                : 'Create New Compliance Item'
            }
          </Title>
          {!editing && <Button
            text='Priority'
            type='white'
            icon={isStarred ? flag : flagGrey}
            style={{ padding: '0 15px', flexDirection: 'row-reverse' }}
            styleIcon={{ margin: '0 0 0 10px' }}
            onClick={() => setIsStarred(!isStarred)}
          />}
        </TopLine>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            rowGap: 20,
            columnGap: 50,
            marginBottom: 20,
          }}
        >
          {isReportSource
            ? topReportInputs.map((item) => (
              <Input
                control={control}
                smallLabel
                {...item}

                reset={reset}
                options={store.legislative_options?.[item.options]}
                error={errors[item.name] && 'This field is required'}
                onAddNew={() => setVisible(item.name)}
                setValue={setValue}
                allowDisabled
                multiSelectItemColor='var(--text)'
                getPopupContainer
              />
            ))
            : topInputs.map((item) => (
              <Input
                control={control}
                smallLabel
                {...item}
                reset={reset}
                options={item.name === 'sub_topics' && values.topics
                  ? filterByTopicIds([values.topics], toJS(store.legislative_options?.sub_topics))
                  : store.legislative_options?.[item.options]}
                error={errors[item.name] && 'This field is required'}
                onAddNew={() => setVisible(item.name)}
                disabled={item.name === 'sub_topics'
                  ? !values.topics
                  : false}
                setValue={setValue}
                handleChange={(e) => {
                  if (item.name === 'topics') {
                    setValue('sub_topics', null)
                  }
                }}
                allowDisabled
                allowHandleChange={false}
                multiSelectItemColor='var(--text)'
                getPopupContainer
              />
            ))
          }
        </div>
        {isReportSource
          ?
          <>
            <MaterialsGroupWrap>
              <Input
                control={control}
                name='forms'
                type='textarea'
                smallLabel
                label={`Forms Description`}
                style={{ marginBottom: 15, }}
                placeholder={'Please upload all related documents to this task.'}
              />
              <Input
                control={control}
                name={`forms_file`}
                type='file'
                onChange={(e) => setFormsFile(e.target.files[0])}
                showFile
                label={`Forms Files`}
                smallLabel
                multiple
                accept='application/pdf'
              />
              {editing &&
                <div style={{ marginTop: 10 }}>
                  <Document materials={store.currentLegislative?.report.forms[0]} />
                </div>
              }
            </MaterialsGroupWrap>
            <MaterialsGroupWrap>
              <Input
                control={control}
                name='guidances'
                type='textarea'
                smallLabel
                label={`Guidance Description`}
                style={{ marginBottom: 15, }}
                placeholder={'Please upload all related documents to this task.'}
              />
              <Input
                control={control}
                name={`guidances_file`}
                type='file'
                handleChange={(e) => setGuidancesFile(e.target.files[0])}
                showFile
                label={`Guidance Files`}
                smallLabel
                multiple
                accept='application/pdf'
              />
              {editing &&
                <div style={{ marginTop: 10 }}>
                  <Document materials={store.currentLegislative?.report.guidances[0]} />
                </div>
              }
            </MaterialsGroupWrap>
            <MaterialsGroupWrap>
              <Input
                control={control}
                name='notes'
                type='textarea'
                smallLabel
                label={`Notes Description`}
                style={{ marginBottom: 15, }}
                placeholder={'Please upload all related documents to this task.'}
              />
              <Input
                control={control}
                name={`notes_file`}
                type='file'
                handleChange={(e) => setNotesFile(e.target.files[0])}
                showFile
                label={`Notes Files`}
                smallLabel
                multiple
                accept='application/pdf'
              />
              {editing &&
                <div style={{ marginTop: 10 }}>
                  <Document materials={store.currentLegislative?.report.notes[0]} />
                </div>}
            </MaterialsGroupWrap>
            <Input
              control={control}
              name='tips'
              type='textarea'
              smallLabel
              label='Tips'
              style={{ marginBottom: 15 }}
              validation={{ required: false }}
            // error={errors.tips && 'This field is required'}
            />
          </>
          : <>
            <div style={{ margin: '20px 0 20px' }}>
              <MyEditor
                required={true}
                title={'Summary'}
                handleChange={(value) => setSummary(value)}
                defaultValue={editing ? store?.currentLegislative?.summary ? store?.currentLegislative?.summary : '' : ''} />
            </div>
            <Input
              control={control}
              name='notes'
              type='textarea'
              smallLabel
              label='Notes'
              style={{ marginBottom: 15 }}
              error={errors.notes && 'This field is required'}
            />
            <Input
              control={control}
              name='file'
              type='file'
              handleChange={(e) => setFile(e.target.files[0])}
              showFile
              label='Upload Source Document (.pdf only)'
              smallLabel
              validation={{ required: !editing }}
              error={errors.file && 'This field is required'}
              accept='application/pdf'
            />
          </>
        }
        {isReportSource && <GetReadyList tasks={tasks} setTasks={setTasks} />}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 35,
            marginBottom: 80,
          }}
        >
          <Cancel onClick={onClose}>Cancel</Cancel>
          <Button
            text={
              isReportSource
                ? editing ? 'Update Report' : 'Create Report'
                : editing ? 'Update Compliance Item' : 'Create Compliance Item'
            }
            onClick={
              isReportSource
                ? handleSubmit(onReportSubmit)
                : handleSubmit(onSubmit)
            }
            loading={isLoading}
          />
        </div>
      </Wrap>
    )
  }
)

export default CreateNewComplianceItem

