import styled from 'styled-components'

export const Wrap = styled.div`
  width: ${(props) => (props.wide ? '100%' : '800px')};
  margin: 0 auto;
  min-height: 915px;
  overflow-x: hidden;
  @media (max-height: 915px) {
    min-height: 700px;
  }
`