import React from 'react'
import { TitleGlobal } from '../../Compliance/styles'
import { Description, Wrap } from './style'

const Header = () => {
  return (
    <Wrap>
      <div>
        <TitleGlobal style={{
          textAlign: 'center',
          fontFamily: 'var(--medium)',
          color: 'var(--blue)',
          fontSize: 32
        }}>
          About us
        </TitleGlobal>
        <TitleGlobal style={{ color: 'var(--black)', textAlign: 'center', fontSize: 26 }}>
          At PBMSource, our mission is to provide our customers with easy access to 
          <br/> information, tools, and resources needed to ensure compliance with laws,
          <br/> regulations, and guidance specific to the PBM industry.
        </TitleGlobal>
        <Description>
         We do this by providing a dedicated compliance team and web-based solutions to 
         <br/>assist with compliance workflow, assessments, required reporting, communication
         <br/> among team members, and collaboration within the organization in one unified 
         <br/>platform. Our team of compliance experts and suite of compliance solutions 
         <br/>does compliance for you.
        </Description>
      </div>
    </Wrap>
  )
}

export default Header
