export const ComplianceSourceSiteSettings = [
  {
    key: 'new_compliance_item_in_tracking_site_notification',
    name: 'New compliance item added',
    disabled: false,
  },
  {
    key: 'when_assigned_to_a_compliance_item_site_notification',
    name: 'When assigned to a compliance item',
    disabled: false,
  },
  {
    key: 'compliance_item_status_changed_site_notification',
    name: 'Compliance item status change',
    disabled: false,
  },
  {
    key: 'uploaded_or_deleted_documents_site_notification',
    name: 'Uploaded or deleted documents',
    disabled: false,
  },
  {
    key: 'compliance_item_is_due_site_notification',
    name: 'Compliance item is due',
    disabled: false,
  },
  {
    key: 'task_completion_site_notification',
    name: 'Task completion',
    disabled: false,
  },
  {
    key: 'comment_mentions_site_notifications',
    name: 'Comment Mentions',
    disabled: false,
  },
  {
    key: 'updates_to_existing_compliance_items_site_notification',
    name: 'Updates to existing compliance items',
    disabled: false,
  },
]

export const ComplianceSiteTaskSettings = [
  {
    key: 'assigned_new_task_site_notification',
    name: 'Assigned new task',
    disabled: false,
  },
  {
    key: 'task_is_due_site_notification',
    name: 'Task is due',
    disabled: false,
  },
];
export const ComplianceLiteSiteSettings = [
  {
    key: 'new_compliance_item_in_tracking_site_notification',
    name: 'New compliance item added',
    disabled: false,
  },
];
export const MemberSiteComplianceTaskSettings = [
  {
    key: 'assigned_new_task_site_notification',
    name: 'Assigned new task',
    disabled: false,
  },
  {
    key: 'task_is_due_site_notification',
    name: 'Task is due',
    disabled: false,
  },
  {
    key: 'comment_mentions_site_notifications',
    name: 'Comment Mentions',
    disabled: true,
  },
]

export const ComplianceSettings = [
  {
    key: 'new_legislatives',
    name: 'New compliance item added',
    disabled: false,
  },
  {
    key: 'new_compliance_item_assigned',
    name: 'When assigned to a compliance item',
    disabled: false,
  },
  {
    key: 'status_changed',
    name: 'Compliance item status change',
    disabled: false,
  },
  {
    key: 'documents',
    name: 'Uploaded or deleted documents',
    disabled: false,
  },
  {
    key: 'legislative_is_due',
    name: 'Compliance item is due',
    disabled: false,
  },
  {
    key: 'task_completion',
    name: 'Task completion',
    disabled: false,
  },
  {
    key: 'new_comments',
    name: 'Comment Mentions',
    disabled: false,
  },
  {
    key: 'updates_to_existing_compliance_items',
    name: 'Updates to existing compliance items',
    disabled: false,
  },
];

export const ComplianceLiteSettings = [
  {
    key: 'new_legislatives',
    name: 'New compliance item added',
    disabled: false,
  },
];

export const ComplianceTaskSettings = [
  {
    key: 'assigned_task',
    name: 'Assigned new task',
    disabled: false,
  },
  {
    key: 'task_is_due',
    name: 'Task is due',
    disabled: false,
  },
];

export const MemberComplianceTaskSettings = [
  {
    key: 'assigned_task',
    name: 'Assigned new task',
    disabled: false,
  },
  {
    key: 'task_is_due',
    name: 'Task is due',
    disabled: false,
  },
  {
    key: 'new_comments',
    name: 'Comment Mentions',
    disabled: true,
  },
]




export const LicenseSettings = [
  {
    key: 'new_license_items',
    name: 'New license items being tracked',
  },
  {
    key: 'assigned_to_license_item',
    name: 'When assigned to a license item',
  },
  {
    key: 'license_item_status_changed',
    name: 'License item status changed',
  },
  {
    key: 'uploaded_or_deleted_license_documents',
    name: 'Uploaded or deleted documents',
  },
  {
    key: 'license_items_is_due',
    name: 'License item is due',
  },
  {
    key: 'license_task_completion',
    name: 'Task completion',
  },
  {
    key: 'license_comment_mentions',
    name: 'Comment Mentions',
  },
  {
    key: 'updates_to_existing_license_items',
    name: 'Updates to existing license items'
  },
];


export const LicenseTaskSettings = [
  {
    key: 'assigned_new_license_task',
    name: 'Assigned new task',
  },
  {
    key: 'license_task_is_due',
    name: 'Task is due',
  },
];

export const LicenseSiteSettings = [
  {
    key: 'new_license_items_site_notifications',
    name: 'New license items being tracked',
  },
  {
    key: 'assigned_to_license_item_site_notifications',
    name: 'When assigned to a license item',
  },
  {
    key: 'license_item_status_changed_site_notifications',
    name: 'License item status change',
  },
  {
    key: 'uploaded_or_deleted_license_documents_site_notifications',
    name: 'Uploaded or deleted documents',
  },
  {
    key: 'license_items_is_due_site_notifications',
    name: 'License item is due',
  },
  {
    key: 'license_task_completion_site_notifications',
    name: 'Task completion',
  },
  {
    key: 'license_comment_mentions_site_notifications',
    name: 'Comment Mentions',
  },
  {
    key: 'updates_to_existing_license_items_site_notifications',
    name: 'Updates to existing license items'
  },
];


export const LicenseSiteTaskSettings = [
  {
    key: 'assigned_new_license_task_site_notifications',
    name: 'Assigned new task',
  },
  {
    key: 'license_task_is_due_site_notifications',
    name: 'Task is due',
  },
];

export const MemberSiteLicenseTaskSettings = [
  {
    key: 'assigned_new_license_task_site_notifications',
    name: 'Assigned new task',
    disabled: false,
  },
  {
    key: 'license_task_is_due_site_notifications',
    name: 'Task is due',
    disabled: false,
  },
  {
    key: 'license_comment_mentions_site_notifications',
    name: 'Comment Mentions',
    disabled: true,
  },
]

export const MemberLicenseTaskSettings = [
  {
    key: 'assigned_new_license_task',
    name: 'Assigned new task',
    disabled: false,
  },
  {
    key: 'license_task_is_due',
    name: 'Task is due',
    disabled: false,
  },
  {
    key: 'license_comment_mentions',
    name: 'Comment Mentions',
    disabled: true,
  },
]



export const ReportSiteSettings = [
  {
    key: 'new_report_item_in_tracking_site_notification',
    name: 'New report items being tracked',
  },
  {
    key: 'when_assigned_to_a_report_item_site_notification',
    name: 'When assigned to a report item',
  },
  {
    key: 'report_item_status_changed_site_notification',
    name: 'Report item status change',
  },
  {
    key: 'report_uploaded_or_deleted_documents_site_notification',
    name: 'Uploaded or deleted documents',
  },
  {
    key: 'report_item_due_site_notification',
    name: 'Report item is due',
  },
  {
    key: 'report_task_completion_site_notification',
    name: 'Task completion',
  },
  {
    key: 'report_comments_site_notification',
    name: 'Comment Mentions',
  },
];

export const ReportLiteSiteSettings = [
  {
    key: 'new_report_item_in_tracking_site_notification',
    name: 'New report items being tracked',
  },
];


export const ReportSiteTaskSettings = [
  {
    key: 'new_report_task_assigned_site_notification',
    name: 'Assigned new task',
  },
  {
    key: 'report_task_due_site_notification',
    name: 'Task is due',
  },
];

export const ReportSettings = [
  {
    key: 'new_report',
    name: 'New report items being tracked',
  },
  {
    key: 'assigned_to_report',
    name: 'When assigned to a report item',
  },
  {
    key: 'report_item_status_changed',
    name: 'Report item status change',
  },
  {
    key: 'upload_delete_document',
    name: 'Uploaded or deleted documents',
  },
  {
    key: 'report_item_due',
    name: 'Report item is due',
  },
  {
    key: 'report_task_completion',
    name: 'Task completion',
  },
  {
    key: 'new_report_comments',
    name: 'Comment Mentions',
  },
];

export const ReportLiteSettings = [
  {
    key: 'new_report',
    name: 'New report items being tracked',
  },
];

export const ReportTaskSettings = [
  {
    key: 'assigned_new_task',
    name: 'Assigned new task',
  },
  {
    key: 'report_task_is_due',
    name: 'Task is due',
  },
];

export const ReportMemberSiteTaskSettings = [
  {
    key: 'new_report_task_assigned_site_notification',
    name: 'Assigned new task',
  },
  {
    key: 'report_task_due_site_notification',
    name: 'Task is due',
  },
  {
    key: 'report_comments_site_notification',
    name: 'Comment Mentions',
  },
];

export const ReportMemberTaskSettings = [
  {
    key: 'assigned_new_task',
    name: 'Assigned new task',
  },
  {
    key: 'report_task_is_due',
    name: 'Task is due',
  },
  {
    key: 'new_report_comments',
    name: 'Comment Mentions',
  },
];