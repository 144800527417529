import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DefinitionsContainer, DefinitionsTitle, DefinitionsText, ShowMore } from './styles';

const LicenseInfoBlock = ({ title, description, link }) => {
  const [showFullText, setShowFullText] = useState(false);
  const maxLength = 160;

  const toggleTextDisplay = () => {
    setShowFullText(!showFullText);
  };

  const truncatedDescription = description && description.length > maxLength
    ? `${description.slice(0, maxLength)}...`
    : description;

  return (
    <>
      {description && (
        <DefinitionsContainer>
          <DefinitionsTitle>{title}:</DefinitionsTitle>
          <DefinitionsText
            dangerouslySetInnerHTML={{
              __html: showFullText ? description : truncatedDescription
            }}
          />
          {description.length > maxLength && (
            <ShowMore onClick={toggleTextDisplay}>
              {showFullText ? `Hide` : `Show Full ${title}`}
            </ShowMore>
          )}
          {link && (
            <Link
              style={{
                textDecoration: 'underline',
                color: 'var(--blue)',
                fontSize: '14px',
                fontFamily: 'var(--regular)',
              }}
              to={link}
            >
              Show Definitions
            </Link>
          )}
        </DefinitionsContainer>
      )}
    </>
  );
};

export default LicenseInfoBlock;
