import avatar_ceo from '../../../../../assets/images/avatars/Avatar_CEO.png'
import avatar_coo from '../../../../../assets/images/avatars/Avatar_COO.png'
import avatar_vp from '../../../../../assets/images/avatars/Avatar_VP.png'
import avatar_attorney from '../../../../../assets/images/avatars/Avatar_Atorney.png'
import avatar_paralegal from '../../../../../assets/images/avatars/Avatar_Paralegal.png'
import avatar_laa from '../../../../../assets/images/avatars/Avatar_LAA.png'
import avatar_des from '../../../../../assets/images/avatars/Avatar_DES.png'
import avatar_pm from '../../../../../assets/images/avatars/Avatar_PM.png'
import avatar_rap from '../../../../../assets/images/avatars/Avatar_RAP.png'
import avatar_rap_2 from '../../../../../assets/images/avatars/Avatar_RAP_2.png'
import avatar_laa_2 from '../../../../../assets/images/avatars/Avatar_LAA_2.png'


export const leadership = [
  {
    key: 0,
    name: 'Samantha Brown',
    position: 'Owner & President',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquet scelerisque felis quis lacinia.Ut vel tellus felis. Nam id porttitor dolor. Sed faucibus lorem sed nibh consequat, in euismod lacus scelerisque. Aliquam erat volutpat. Morbi ac gravida nisi. Vivamus eu tellus feugiat, fermentum velit ac, porttitor ex. Suspendisse purus dolor, condimentum ut lacus eu, efficitur tincidunt eros. Aliquam commodo et libero id ullamcorper. Etiam laoreet sollicitudin mi eget vestibulum.',
    photo: avatar_ceo,
    status: 'leadership'

  },
  {
    key: 1,
    name: 'Rebecca Kordys',
    position: 'Chief Operating Officer',
    description: 'Rebecca Kordys is a graduate of the KCMO school of Practical Nursing with over 30 years of experience in healthcare, specifically healthcare management, and operations. She has managed a medical office with 7 providers, 30 staff and 85-100 patients per day. In her role as Chief Operations Officer, Rebecca oversees overall operations for PBMSource, provides support for the PBMSource team and clients and has direct responsibility for ensuring workflow is operating smoothly, enhancements are made as needed, and information is easily accessible for PBMSource customers.',
    photo: avatar_coo,
    status: 'leadership'
  },
  {
    key: 2,
    name: 'Dena Perez',
    position: 'Executive VP, Business Development',
    description: 'Dena Perez is a seasoned executive with over 20 years of experience in the PBM Industry. Throughout her career, she has successfully led teams to develop and maintain longstanding partnerships with PBM clients and consulting firms resulting in the achievement of customer satisfaction, retention, growth, and overall compliance.In her role as Executive Vice President, Business Development, Dena will leverage her PBM expertise to support the PBMSource team with ongoing development and execution of strategic initiatives designed to enhance customer experience by providing easy access to information which will allow them to ensure overall compliance with laws, regulations, and sub-regulatory guidance in the most cost effective and efficient manner.',
    photo: avatar_vp,
    status: 'leadership'
  },
]

export const team = [
  {
    key: 10,
    name: 'Alissa Meszaros',
    position: 'Legal Research Oversight Paralegal',
    photo: avatar_paralegal,
    status: 'team'
  },
  {
    key: 8,
    name: 'Pam Kofmehl',
    position: 'Legal Research Analyst Paralegal',
    photo: avatar_rap_2,
    status: 'team'
  },
  {
    key: 7,
    name: 'Jeanette Voss',
    position: 'Legal Research Analyst Paralegal',
    photo: avatar_rap,
    status: 'team'
  },
  {
    key: 4,
    name: 'Carl Gardner',
    position: 'Legal Analyst Attorney ',
    photo: avatar_laa,
    status: 'team'
  },
  {
    key: 9,
    name: 'Ariel Alvarez',
    position: 'Legal Analyst Attorney',
    photo: avatar_laa_2,
    status: 'team'
  },
  {
    key: 3,
    name: 'Julide Mencek',
    position: 'Legal Analyst Attorney',
    photo: avatar_attorney,
    status: 'team'
  },
  {
    key: 5,
    name: 'Sharon Mico',
    position: 'Research and Data Entry Specialist',
    photo: avatar_des,
    status: 'team'
  },
  {
    key: 6,
    name: 'Scott Shell',
    position: 'IT Product Manager',
    photo: avatar_pm,
    status: 'team'
  },
]