import styled, { keyframes } from 'styled-components'

const slideIn = keyframes`
  from {
    transform: translateY(-100%); 
  }
  to {
    transform: translateY(0);  
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);  
  }
  to {
    transform: translateY(-100%); 
  }
`;

export const Wrap = styled("div")`
  animation: ${props => props.fadeOut ? slideOut : slideIn} 1s forwards;
  z-index: 9998;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: #D52118;
  color: #fff;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  margin-top: 90px;
  
  `;

export const Title = styled("div")`
  font-family: var(--open-sans-reg);
  font-style: normal;
  font-size: 12px;
  text-align: center;
   color: #fff  
  padding: 7px 15px;
  span {
  font-family: var(--open-sans-bold);
  }
  @media (min-width: 1200px) {
      font-size: 12px;
      line-height: 12px;
      padding: 7px 15px 5px 15px;
  };

`;