import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Input from '../../../components/common/Input'
import { useStore } from '../../../mobx-store/context'
import List from '../components/List'
import { Text } from '../components/style'
import { Item } from '../Resource/LeftSide/style'
import { Wrap } from './style'

const Left = observer(({ onItemChoose, currentItemCode }) => {
  const params = useParams()
  const store = useStore()
  const { control, reset, getValues } = useForm()
  const history = useHistory()
  const location = useLocation()

  const isGuides = params.type === 'guides'

  const resources = store.resources?.filter((item) =>
    store.user?.[0]?.resource_guides.includes(item.guide_name_id)
  )

  const getOptions = (type, all = true) => {
    const options = store.resourceFilters?.[type]

    return options && (all ? [['_all', 'All'], ...options] : options)
  }

  useEffect(() => {
    if (!store.resources) store.getResourceTopic()
    if (!store.resourcesCurrentTopic)
      store.getResourceTopic(isGuides ? +params.selectId : +params.topicId, null, null, params.selectId)
  }, [])

  useEffect(() => {
    reset({
      topics: isGuides ? +params.selectId : +params.topicId,
    })
    onItemChoose(
      store.resourcesCurrentTopic?.guide_data?.custom_fields?.find(
        (item) => item.code === params.code
      )
    )
  }, [location])

  return (
    <Wrap>
      <Input
        name={'topics'}
        getPopupContainer={true}
        style={{ marginBottom: 10 }}
        control={control}
        type={'select'}
        options={resources}
        label={`Select a ReSource Guide`}
        allowClear={false}
        bigText={true}
        handleChange={(id) => {
          store.getResourceTopic(id, null, null, params.selectId)
          history.push(
            `/resource/across-jurisdiction/${params.code}/${isGuides ? params.topicId : id
            }/${isGuides ? id : params.selectId}/${params.type}`
          )
        }}
      />
      <Input
        getPopupContainer={true}
        name={'line_of_business'}
        control={control}
        type={'multiSelect'}
        selectAll={false}
        options={getOptions('line_of_business', false)}
        selectVars={{ value: 0, name: 1 }}
        label={'Line of Business'}
        smallLabel
        defaultValue={store.resourceLob || []}
        loading={!store.resourceFilters?.line_of_business}
        handleChange={() => { store.setSingleValue('resourceLob', getValues('line_of_business')) }}
        allowClear={false}
      // styleRow={{ maxWidth: 400 }}
      />
      <Text style={{ margin: '10px 0' }}>Select a Requirement</Text>
      <List
        noHeader
        options={store.resourcesCurrentTopic?.guide_data?.custom_fields.map(
          (item) => (
            <Item
              key={item.code}
              active={item.code === currentItemCode}
              onClick={() => {
                onItemChoose(item)
                history.push(
                  `/resource/across-jurisdiction/${item.code}/${params.topicId}/${params.selectId}/${params.type}`
                )
              }}
            >
              {item.name}
            </Item>
          )
        )}
        customOptions
      />
    </Wrap>
  )
})

export default Left
