import React, { useState } from 'react'
import { Description, FormWrap, Title, Wrap } from '../authForm.style'
import Logo from '../../../components/common/Logo/logo'
import { HookForm } from '../Form/form.style'
import { useForm } from 'react-hook-form'
import Button from '../../../components/common/Button/button'
import { Back, ResetContainer, Row } from './resetPass.style'
import { api } from '../../../api'
import { useHistory } from 'react-router-dom'
import { getError } from '../../../helpers/helpers'
import Validation from '../../../components/common/Validation/validation'
import Input from '../../../components/common/Input'
import { message } from 'antd'

const Step1 = () => {
  const history = useHistory()
  const {
    control,
    handleSubmit,
    errors,
    setError,
    getValues,
    watch,
  } = useForm()
  const [loading, setLoading] = useState(false)
  const watchAllFields = watch()

  const onSubmit = (values) => {
    setLoading(true)
    api('/password_reset/', { email: values.email }, 'POST', true).then(
      (data) => {
        setLoading(false)
        if (!data.errors) {
          sessionStorage.setItem('email', values.email)
          history.push('/reset-password-email')
        } else {
          message.error(getError(data, setError))
        }
      },
    )
  }

  return (
    <ResetContainer wide>
      <Wrap>
        <FormWrap
          onSubmit={handleSubmit(onSubmit)}
          style={{ backgroundColor: 'transparent' }}
        >
          <Logo large />
          <Title>Forgot Your Password?</Title>
          <Description>
            Enter the email address associated with your account.
          </Description>
          <HookForm>
            {watchAllFields && (
              <Input
                control={control}
                name={'email'}
                label={'Email Address'}
                placeholder={'Email Address'}
                validation={{ required: true }}
                type={'email'}
                error={errors.email && 'Enter valid email'}
                transparent
              />
            )}

            {errors.email && (
              <Validation
                text={''}
                upperText={
                  'We’re Sorry, that email doesn’t appear to match our records. Please ensure  you’ve entered the correct email address, and try again.'
                }
              />
            )}

            <Row
              style={{ justifyContent: 'flex-start', alignItems: 'flex-end' }}
            >
              <Button
                text={'Reset My Password'}
                type={'main'}
                loading={loading}
                disabled={!getValues('email')}
              />
              <Back
                to={
                  history.location.state?.fromSettings
                    ? '/password-and-security'
                    : '/login'
                }
                style={{ marginLeft: '1em' }}
              >
                &lt;&lt; Take me back{' '}
                {history.location.state?.fromSettings
                  ? 'settings'
                  : 'to sign in'}
              </Back>
            </Row>
          </HookForm>
        </FormWrap>
      </Wrap>
    </ResetContainer>
  )
}

export default Step1
