import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useStore } from '../../../../mobx-store/context'
import { Spin } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { PageTitle } from '../../components/common/style'
import PageWrap from '../../components/Container'
import TabsNav from '../../components/TabsNav/tabsNav'
import TrackingTab from './Tabs/Tracking/tracking'
import ReportTab from './Tabs/ReportSource/'
import LicenseTab from './Tabs/LicenseSource/'
import { api } from '../../../../api'
import SettingsActivity from './Settings Activity'

const AutoAssignments = observer(() => {
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const [active, setActive] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showActivity, setShowActivity] = useState(false)
  const [activityData, setActivityData] = useState([])
  const user = store.user?.[0]

  const getAssignLists = async () => {
    try {
      const autoAssignLists = await api('/companies/auto_assign_lists/', {}, 'GET');
      if (!autoAssignLists.errors) {
        store.setSingleValue('autoAssignLists', autoAssignLists);
      }

      const autoAssignType = await api('/companies/auto_assign_type/', {}, 'GET');
      store.setSingleValue('autoAssignType', autoAssignType);


      const autoAssignMembersPending = await api('/companies/auto_assign_members/?filter_for=license_source_pending', {}, 'GET');
      store.setSingleValue('autoAssignMembersPending', autoAssignMembersPending);

      const autoAssignMembersRenewals = await api('/companies/auto_assign_members/?filter_for=license_source_renewals', {}, 'GET');
      store.setSingleValue('autoAssignMembersRenewals', autoAssignMembersRenewals);

      const filter = active === 'tracking' ? 'compliance_source' : 'report_source';
      const autoAssignMembers = await api(`/companies/auto_assign_members/?filter_for=${filter}`, {}, 'GET');
      store.setSingleValue('autoAssignMembers', autoAssignMembers);

      await store.loadMembers();
      await store.loadSelect('companyOptions');
      setLoading(false);
    } catch (error) {
      console.error('Error in getAssignLists:', error);
      setLoading(false);
    }
  };

  const getActtivity = () => {
    api(`/companies/auto_assign_activity/`, {}, 'GET').then((data) => {
      setActivityData(data)
    })
  }

  const tabs = {
    tracking: {
      button: 'ComplianceSource',
      component: <TrackingTab setShowActivity={setShowActivity} />,
      link: '/auto-assignments/tracking',
    },
    reportSource: {
      button: 'ReportSource',
      component: <ReportTab setShowActivity={setShowActivity} />,
      link: '/auto-assignments/reportSource',
    },
    licenseSource: {
      button: 'LicenseSource',
      component: <LicenseTab setShowActivity={setShowActivity} mptyTab />,
      link: '/auto-assignments/licenseSource',
    }
  }

  useEffect(() => {
    setActive(
      user?.have_access_to_tracking
        ? 'tracking'
        : user?.have_access_to_license_source
          ? 'licenseSource'
          : 'reportSource'
    )
  }, [])

  useEffect(() => {
    params.type && setActive(params.type)
  }, [params.type])

  useEffect(() => {
    setShowActivity(false)
  }, [active])

  useEffect(() => {
    getAssignLists()
  }, []);

  useEffect(() => {
    getActtivity()
  }, [showActivity])



  return (
    <PageWrap modalOpen={false}>
      <PageTitle>{showActivity ? 'Auto Assignments Activity' : 'Auto Assignments'}</PageTitle>
      {showActivity ?
        <SettingsActivity data={activityData} setShowActivity={setShowActivity} />
        : <>
          <TabsNav
            tabs={tabs}
            active={active}
            onRedirectTo={(page) => history.push(`/auto-assignments/${page}`)}
          />
          {loading ? <Spin /> : tabs[active].component}
        </>
      }
    </PageWrap>
  )
})

export default AutoAssignments
