import styled from 'styled-components'
import { query1300, query500 } from '../../helpers'
import { ContainerGlobal, TitleGlobal } from '../styles'

export const Wrap = styled('div')`
  ${ContainerGlobal} {
    max-width: 100%;
    @media (max-width: 955px) {
      display: block;
    }
  }
  ${TitleGlobal} {
    @media (max-width: 955px) {
      text-align: center;
      br {
        display: none;
      }
    }
    @media${query500} {
      text-align: left;
    }
  }
`

export const LeftSide = styled('div')`
  width: 580px;
  margin-right: 60px;
  @media (max-width: 1070px) {
    width: 450px;
  }
  @media (max-width: 955px) {
    width: 100%;
    text-align: center;
  }
  img {
    width: 600px;
    margin-top:  ${(props) => (props.service === 'proposed-source' ? '0px' : '100px')};
    margin-left: -100px;
    @media (max-width: 1070px) {
      width: 475px;
      margin-left: -51px;
    }
    @media${query500} {
      width: 100%;
    }
  }
`

export const RightSide = styled('div')`
  display: grid;  
  grid-auto-rows: min-content;
  grid-template-columns: 1fr;
  gap: 15px;
  margin-left: auto;
  @media (max-width: 955px) {
    align-content: center;
    justify-content: center;
    margin-top: 30px;
  }
`
export const Container = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
`