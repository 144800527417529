import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Wrap = styled('div')`
  width: 19.375em;
  //min-height: 46.25em;
  padding-bottom: 2em;
  background-color: #fff;
  transform: translateY(-9.125em);
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 4px 4px 2px rgba(0, 0, 0, 0.1);
`

export const Top = styled('div')`
  width: 100%;
  height: 2.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--medium);
  font-size: 1.875em;
  color: #fff;
  background-color: var(--orange);
  margin-bottom: 1em;
`

export const ItemWrap = styled('div')`
  width: 100%;
  padding: 0 1em;
  margin: 1em 0;
`

export const ItemTop = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--bold);
  font-size: 0.875em;
  color: var(--text);

  span {
    font-family: var(--regular);
    font-size: 1.286em;
    width: 50%;
    text-align: right;
    color: ${(p) => (p.orange ? 'var(--orange)' : 'var(--text)')};
  }
`

export const SubItem = styled(ItemTop)`
  font-family: var(--medium);
  font-size: 0.75em;
`

export const ItemContent = styled('div')`
  display: flex;
  justify-content: space-between;
  font-family: var(--semiBold);
  font-size: 0.875em;
  color: var(--text);
  margin-top: 0.5em;

  div:last-child {
    width: 70%;
    font-family: var(--medium);
  }
`

export const Total = styled('div')`
  font-family: var(--regular);
  font-size: 2.25em;
  color: var(--text);
  border-top: 2px solid #dcdcdc;
  padding-top: 32px;
  margin-top: 1em;

  div {
    text-align: center;

    &:last-child {
      color: var(--green);

      span {
        font-size: 0.667em;
      }
    }
  }
`

export const Edit = styled(Link)`
  font-family: var(--medium);
  font-size: 0.75em;
  color: var(--orange);
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: var(--orange);
  }
`
