import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import { useHistory } from 'react-router-dom'
import { api } from '../../../../../../api'
import { tariffs } from '../../../../../../helpers/helpers'
import { useStore } from '../../../../../../mobx-store/context'
import { Title } from '../../../../components/common/style'
import ResourceModal from '../../../../components/ResourceAccessModal/resourceModal'
// import BillingSchedule from '../../bollingSchedule'
import DateRange from '../../dateRange'
import Info from '../../info'
import PlanInfo from '../../planInfo'

const TrackingTab = observer(() => {
  const store = useStore()
  const history = useHistory()
  const [visible, setVisible] = useState(false)

  const company = store.company[0]
  const currentTariff = store.currentTariff

  const getPlanCost = () => {
    if (company.payment_period === 'year') {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(
        (
          currentTariff[
          company.is_premium ? 'month_premium_price' : 'month_base_price'
          ] *
          12 *
          (1 - store.tariffInfo.discount)
        ).toFixed(2)
      )
    } else {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(
        currentTariff[
          company.is_premium ? 'month_premium_price' : 'month_base_price'
        ].toFixed(2)
      )
    }
  }

  const leftData = [
    {
      key: 0,
      title: 'Plan Admin',
      text:
        store.members
          ?.filter((item) => item.member_type === 'admin')
          ?.map((item) => ` ${item.first_name} ${item.last_name}`)
          .toString() || '-',
    },
    // {
    //   key: 1,
    //   title: 'Team Size',
    //   text: `${store.members.length} Members`,
    // },
    {
      key: 1,
      title: 'Effective Date',
      hide: !company.start_date,
      text: (
        company.start_date ?
          <>
            <Moment format={'MM/DD/YYYY'}>{company.start_date}</Moment>
          </> : '-'
      )
    },
    {
      key: 2,
      title: 'Access Through',
      hide: !company.next_payment_date,
      text: (company.next_payment_date ?
        <>
          <Moment format={'MM/DD/YYYY'}>{company.next_payment_date}</Moment>
        </> : '-'
      )
    },
    {
      key: 3,
      title: 'Look Back Date',
      hide: !company.show_compliance_items_adopted_date,
      text: (company.show_compliance_items_adopted_date ?
        <>
          <Moment format={'MM/DD/YYYY'}>{company.show_compliance_items_adopted_date}</Moment>
        </> : '-'
      )
    },
    // {
    //   key: 2,
    //   title: 'Additional Date Range',
    //   text: company.start_date ? (
    //     <>
    //       (<Moment format={'MM/DD/YY'}>{company.start_date}</Moment> -{' '}
    //       <Moment format={'MM/DD/YY'}>
    //         {company.payment_status === 'trial'
    //           ? company.trial_end
    //           : company.first_payment_date}
    //       </Moment>
    //       )
    //     </>
    //   ) : (
    //     '-'
    //   ),
    // },
    // {
    //   key: 3,
    //   title: 'Pro Line of Business',
    //   text: company.is_premium ? 'Yes' : 'No',
    // },
  ]
  const rightData = [
    // {
    //   key: 0,
    //   title: company?.under_contract ? 'Custom Price' : 'Plan Cost',
    //   text: company?.under_contract
    //     ? `$${company.compliance_custom_price}/year`
    //     : currentTariff && getPlanCost(),
    // },
    // {
    //   key: 1,
    //   title: 'Next Billing Date',
    //   text: (
    //     <Moment format={'MM/DD/YY'}>
    //       {company.payment_status === 'trial'
    //         ? company.trial_end
    //         : company.next_contract_date}
    //     </Moment>
    //   ),
    // },
    // {
    //   key: 2,
    //   title: 'Billing Schedule',
    //   text:
    //     store.company[0].payment_period === 'month'
    //       ? 'Month'
    //       : 'Annual (Discount Applied)',
    // },
  ]

  useEffect(() => {
    if (!store.members.length) store.loadMembers()
    if (!store.billingCards) store.getBillingCards()
    store.loadSelect('memberOptions')
  }, [])

  // useEffect(() => {
  //   api('/resources/guides_in_plan/', {}, 'GET').then((data) => null)
  // }, [])

  if (!store.user?.[0]?.have_access_to_tracking || !store.company?.[0]?.have_access_to_tracking)
    return (
      <ResourceModal
        type='tracking'
        to='/edit-profile'
        onClose={() => history.push('/edit-profile')}
      />
    )

  return (
    <>
      <Title>
        {company.compliance_source_mode === 'compliance_source_ultra_lite' && 'ComplianceSource Ultra Lite |'}
        {company.compliance_source_mode === 'compliance_source_lite' && 'ComplianceSource Lite |'}
        {store.tariffInfo && tariffs[company.tariff]}{' '}
        {/* {company.is_premium && '| Pro Line of Business'} | Additional Date Range{' '}
        {company.payment_period === 'year' && '| Annual Discount'} */}
      </Title>
      <Info leftData={leftData} rightData={rightData} />
      <PlanInfo visible={visible} setVisible={setVisible} />
      {/* <DateRange /> */}
      {/* <BillingSchedule /> */}
    </>
  )
})

export default TrackingTab
