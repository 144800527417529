import React from 'react';
import { Checkbox } from 'antd';
import { Controller } from 'react-hook-form';
import { StyledTable, CheckboxTableTitle } from '../style';
import AddingImg from '../../../../../assets/images/addingIcon.svg';

const CheckboxTable = ({ options, name, title, control, disabled = false, defaultValue, compact = false, disabledefault, required }) => {

  const onRowClick = (record, index, event) => {

    event.preventDefault();
  };

  const handleCheckboxClick = (e) => {
    e.stopPropagation();
  };

  // // const locale = name === 'sub_topics' ? {
  // //   emptyText: 'Choose a Topic first',
  // // } : {
  // //   emptyText: 'No Data',
  // // }

  // const emptyPlaceholder = (
  //   name === 'sub_topics' ?
  //     <Empty description="Choose a Topic first" />
  //     : <Empty description="No Data" />
  // );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || []}
      render={({ value, onChange }) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{
            borderBottom: '1px solid #CCC',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingBottom: 10,
          }}>
            <div style={{display: 'flex',
              flexDirection: 'row',
            }}>
              <CheckboxTableTitle>{title}</CheckboxTableTitle>
              {required && <span style={{ color: 'var(--orange)', marginLeft: 5 }}>*</span>}
            </div>
            <img src={AddingImg} alt="add" />
          </div>
          <StyledTable
            compact={compact}
            // locale={{ emptyText: emptyPlaceholder }}
            id='name'
            dataSource={options}
            rowKey="id"
            pagination={false}
            showHeader={false}
            onRow={(record, index) => ({
              onClick: (event) => onRowClick(record, index, event),
            })}
            size="small"
            columns={[
              {
                title: 'Checkbox',
                dataIndex: 'id',
                key: 'id',
                width: 10,
                render: (id) => (
                  <Checkbox
                    disabled={disabled || (disabledefault && defaultValue.includes(id))}
                    checked={value.includes(id)}
                    onClick={handleCheckboxClick}
                    onChange={(e) => {
                      const checkedId = id;
                      const newValue = e.target.checked
                        ? [...value, checkedId]
                        : value.filter((v) => v !== checkedId);
                      onChange(newValue);
                    }}
                  />
                ),
              },
              {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                render: (name) => <span onClick={() => { }}>{name}</span>,
              },
            ]}
          />
        </div>
      )}
    />
  );
};

export default CheckboxTable;
