import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useStore } from "../../../../../mobx-store/context";
import { observer } from "mobx-react-lite";
import { StyledSwitch, StyledRadioGroup } from "../../../CreateCompany/pages/FirstPage/style";
import { Radio } from 'antd'
import { useForm } from "react-hook-form";
import Input from "../../../../../components/common/Input";
import { Divider, } from "antd";
import Button from "../../../../../components/common/Button/button";
import { PrevBtn, Row, Wrap, Title, SubTitle } from "../../../CreateCompany/pages/FirstPage/Form/style";
import { api } from "../../../../../api";


const CompanyTab = observer(({ handleTabChange, active, companyId }) => {
  const store = useStore()
  const history = useHistory()
  const { handleSubmit, control, getValues, watch, errors } = useForm({
    defaultValues: {
      name: store?.cmsCompany[0]?.name || '',
      address1: store?.cmsCompany[0]?.address1 || '',
      address2: store?.cmsCompany[0]?.address2 || '',
      state: store?.cmsCompany[0]?.state || '',
      zip: store?.cmsCompany[0]?.zip || '',
      web_domain: store?.cmsCompany[0]?.domain || '',
      company_internal_notes: store?.cmsCompany[0]?.company_internal_notes || '',
      trial_option: store.cmsCompany[0]?.trial_option || 'not_in_trial',
      is_testing_company: store.cmsCompany[0]?.is_testing_company || false,
    }
  })
  const watchValues = watch();
  const [trialEnabled, setTrialEnabled] = useState(store?.cmsCompany[0]?.trial_option || 'not_in_trial')
  const [testingEnabled, setTestingEnabled] = useState(store?.cmsCompany[0]?.is_testing_company || false)

  const updateCompany = (values) => {
    const data = {
      name: values.name,
      address1: values.address1,
      address2: values.address2 || '',
      state: values.state,
      zip: values.zip,
      domain: values.web_domain,
      company_internal_notes: values.company_internal_notes || '',
      trial_option: trialEnabled,
      is_testing_company: testingEnabled,
    }
    api(`/cms/companies/${companyId}/`, data, 'PATCH').then((res) => {
      if (!res.errors)
        store.setSingleValue('cmsCompany', [res])
    })
  }

  const onSubmit = (values) => {
    updateCompany(values)
    handleTabChange('compliance')
  }

  useEffect(() => {
    if (!store.states.length) store.loadSelect('states')
  }, [])

  useEffect(() => {
    updateCompany(getValues())
  }, [watch('name'), watch('address1'), watch('address2'), watch('state'), watch('zip'), watch('web_domain'), watch('company_internal_notes'), trialEnabled, testingEnabled])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
      <Row style={{ gridTemplateColumns: '1fr', marginTop: 30 }}>
        <Input
          smallLabel
          name={'name'}
          label={'Company Name'}
          error={errors && errors.name && 'Enter valid company name'}
          type={'text'}
          control={control}
          validation={{ required: true }}
          transparent
          placeholder={'Company Name'}
          style={{ marginBottom: errors && errors.name ? '0' : '1em', }}
        />
      </Row>
      <Row style={{ alignItems: 'flex-start' }}>
        <Input
          smallLabel
          name={'address1'}
          label={'Address 1'}
          error={errors && errors.address1 && 'Enter valid address name'}
          type={'text'}
          control={control}
          validation={{ required: true }}
          transparent
          placeholder={'Address'}
        />
        <Input
          smallLabel
          name={'address2'}
          control={control}
          label={'Address 2'}
          error={errors && errors.address2 && 'Enter valid address name'}
          validation={{ required: false }}
          disableValidation={true}
          type={'text'}
          transparent
          placeholder={'Address'}
        />
      </Row>
      <Row style={{ alignItems: 'flex-start' }}>
        <Input
          smallLabel
          name={'state'}
          error={errors && errors.state && 'Enter valid state'}
          type={'select'}
          control={control}
          validation={{ required: true }}
          transparent
          options={store.states}
          label={'State'}
          placeholder={'State'}
          style={{ marginBottom: errors && errors.state && '0' }}
          selectVars={{ value: 0, name: 1 }}
          showSearch
        />
        <Input
          smallLabel
          name={'zip'}
          error={errors && errors.zip && 'Enter valid zip code (5 numbers)'}
          type={'text'}
          control={control}
          label={'ZIP'}
          validation={{ required: true, maxLength: 5, minLength: 5 }}
          transparent
          placeholder={'Zip Code'}
          mask={'99999'}
        />
      </Row>
      <Row style={{ alignItems: 'flex-start' }}>
        <Input
          smallLabel
          name={'web_domain'}
          label={'Web Domain'}
          type={'text'}
          control={control}
          transparent
          placeholder={'Web Domain'}
          error={errors && errors.web_domain && 'Enter web domain'}
          style={{ marginBottom: '0.3em' }}
          validation={{ required: true }}
        />
      </Row>
      <Divider style={{ padding: '0, 32px', backgroundColor: '#8B9394' }} />
      <Row style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'flex-start', gap: 0 }}>
        <div>
          <Title>Trial Account </Title>
          <SubTitle>
            Adds trial tag to company and allows it to be filtered on the <br /> companies page and in mailchimp.com
          </SubTitle>
          <div style={{ fontSize: 14, fontFamily: 'var(--regular)', display: 'flex', marginTop: 15 }}>
            <StyledRadioGroup onChange={(e) => setTrialEnabled(e.target.value)} value={trialEnabled}>
              <Radio value={'not_in_trial'}>Not on Trial</Radio>
              <Radio value={'trial_is_active'}>Trial is <strong>Active</strong></Radio>
              <Radio value={'trial_is_over'}>Trial is <strong>Over</strong></Radio>
            </StyledRadioGroup>
          </div>
        </div>
        <div>
          <Title>Testing Account </Title>
          <SubTitle>
            Adds testing tag to company and allows it to be filtered on  <br /> the companies page and will be ignored in mailchimp.com
          </SubTitle>
          <div style={{ fontSize: 14, fontFamily: 'var(--regular)', display: 'flex', marginTop: 15 }}>

            <StyledSwitch
              defaultChecked={testingEnabled}
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={(checked) => setTestingEnabled(checked)}
            />
            {testingEnabled
              ? <div style={{ marginLeft: 10 }}>This company  <strong> is </strong> a testing account</div>
              : <div style={{ marginLeft: 10 }}>This company <strong> is not </strong>a testing account</div>
            }
          </div>
        </div>
      </Row>
      <Divider style={{ padding: '0, 32px', backgroundColor: '#8B9394' }} />
      <div>
        <Input
          smallLabel
          name={'company_internal_notes'}
          label={'Internal Notes Only'}
          type={'textarea'}
          control={control}
          transparent
          placeholder={''}
          style={{ marginBottom: '0.3em' }}
        />
      </div>
      <Row style={{ display: 'flex', justifyContent: 'flex-end', }}>
        <Button
          text={'Save'}
          type={'main'}
          style={{
            width: '10%',
            padding: 0,
            marginRight: 0,
            marginLeft: '.5em',
            marginTop: '2.3em',
          }}
        />
      </Row>
    </form>
  )
});

export default CompanyTab;