import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import Button from '../../../../components/common/Button/button'
import { Label } from '../../../../components/common/Input/input.style'
import Modal from '../../../../components/common/ModalNew/index'
import { useStore } from '../../../../mobx-store/context'
import { Cancel } from '../../../Tracking/Right/Tasks/Modal/style'

const types = {
  resource: 'ReSource',
  tracking: 'ComplianceSource',
  reportSource: 'ReportSource',
  licenseSource: 'LicenseSource',
  proposedSource: 'ProposedSource',
}

const ResourceModal = ({ onClose, type = 'resource', to }) => {
  const store = useStore()
  const history = useHistory()

  const link =
    type === 'resource'
    ? '/no-resource-subscription/' 
    : type === 'tracking' 
    ? '/no-compliance-source-subscription/' 
    : type === 'reportSource' 
    ? '/no-report-source-subscription/' 
    : type === 'licenseSource' 
    ? '/no-license-source-subscription/' 
  : '/no-proposed-source-subscription/'

  const onClick = () => {
    if (store.user?.[0]?.member_type === 'admin') {
      history.push(link)
    } else {
      history.push('/edit-profile')
    }
  }

  return (
    <Modal onClose={onClose}>
      <>
        <Label>You do not have access to {types[type]}</Label>
        <div style={{marginBottom: 20}}>
          If you’d like to learn more about {types[type]}
          <Link
            to={link}
            style={{ fontFamily: 'var(--medium)', color: '#70A3EC' }}
          >
            {' '}
            click here.
          </Link>
          
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Cancel onClick={onClose}>Cancel</Cancel>
          <Button text={`OK`} onClick={onClose} />
        </div>
      </>

    </Modal>
  )
}

export default ResourceModal
