import React, { useEffect } from 'react'
import Logo from '../../../components/common/Logo/logo'
import { FormWrap, Right, Title, Wrap } from '../authForm.style'
import Form from './Form/form'
import Container from '../../../components/containers/container'
import StatusBar from '../../../components/common/StatusBar/status'
import { useStore } from '../../../mobx-store/context'
import { observer } from 'mobx-react-lite'
import businessInfoImage from '../../../assets/images/business.png'
import { Spin } from 'antd'

const BusinessInfo = observer(() => {
  const store = useStore();

  useEffect(() => {
    if (!store.user.length) store.getUser();
  }, []);

  const getPreloaded = () =>
    store.user.length
      ? {
          ...store.company[0],
          first_name: store.user[0].first_name,
          last_name: store.user[0].last_name,
        }
      : store.company[0];

  return (
    <Container wide>
      <Wrap>
        <FormWrap>
          <Logo large />
          <StatusBar style={{ marginTop: "1em" }} step={2} />
          <Title style={{ marginTop: "0.5em" }}>Register Your Business</Title>

          {!store.user.length > 0 && <Spin />}
          {store.user.length > 0 && <Form preloaded={getPreloaded()} />}
        </FormWrap>
        <Right>
          <img src={businessInfoImage} alt="register-image" />
        </Right>
      </Wrap>
    </Container>
  );
});

export default BusinessInfo;
