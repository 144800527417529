import { Divider } from "antd";
import { useEffect, useState } from "react";
import Footer from "../Compliance/Footer";
import Header from "./Header";
import GuidesList from "./GuidesList";
import { api } from "../../../api";
import FullScreenLoader from "../../../components/common/FullScreenLoader";
import { useParams } from "react-router-dom";
import { useStore } from "../../../mobx-store/context";
const ResourceGuidesLanding = () => {
  const [guides, setGuides] = useState(null)
  const [loading, setLoading] = useState(true)
  const params = useParams()
  const [activeGuide, setActiveGuide] = useState(params.id);
const store = useStore()
  const getGuides = () => {
    setLoading(true)
    api(`/resource_guide/landing/`, {}, 'GET', true).then((data) => {
      setGuides(data)
      setLoading(false)
    })
  };

  useEffect(() => {
    getGuides()
  }, [])

  useEffect(() => {
    store.setSingleValue('selectedProduct', 'ReSource')
  },[])

  return (
    <div>
      {loading
        ? <FullScreenLoader />
        : <>
          <Header />
          <Divider style={{ padding: '0px, 0px', background: '#F2A122', margin: 0, height: 5, opacity: 0.2 }} />
          <GuidesList guides={guides} selectedGuideId={activeGuide} setActiveGuide={setActiveGuide} />
          <Footer />
        </>
      }
    </div>
  );
};

export default ResourceGuidesLanding;