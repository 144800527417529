import styled from 'styled-components'

export const CheckboxWrap = styled('div')`
  opacity: ${(props) => (props.disabled ? '.5' : 1)};
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
  .ant-checkbox-wrapper {
    font-size: 16px;
    font-family: var(--regular);
    color: var(--text);
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.reverse && 'flex-end'};
    flex-direction: ${(props) => props.reverse && 'row-reverse'};
  }
  .ant-checkbox {
    top: 0;
    & + span {
      padding-left: ${(props) => props.reverse && 0};
    }
  }
  .ant-checkbox-inner {
    border: 2px solid var(--text);
    width: 20px;
    height: 20px;
    &:after {
      background-color: var(--text);
    }
  }
  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
  }
  .ant-radio-checked {
    border-color: var(--text);
    box-shadow: none !important;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--text);
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    background-color: transparent;
    top: 6px;
    left: 3px;
    color: var(--text);
    border-color: var(--text);
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #fff;
  }
  .ant-checkbox:hover::after,
  .ant-checkbox-wrapper:hover .ant-checkbox::after {
    visibility: hidden;
  }
`
