import styled from 'styled-components'

export const Title = styled('div')`
  font-size: 40px;
  font-family: var(--medium);
  color: var(--text);
  margin-bottom: 10px;
`

export const Description = styled('div')`
  font-size: 18px;
  font-family: var(--regular);
  color: var(--text);
  padding-bottom: 30px;
`
