import styled from 'styled-components'

export const Title = styled('div')`
  font-family: var(--medium);
  font-size: 20px;
  color: var(--text);
`

export const Description = styled(Title)`
  font-size: 16px;
  font-family: var(--regular);
`
