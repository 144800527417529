import { Block, Text } from "./style"
import { Tag } from "antd"


const NewRequirementBlock = ({ text = 'N/A', updateDate = 'N/A' }) => {

  return (
    <Block>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%' }}>
        <Tag
          style={{ width: 'auto', fontFamily: 'var(--regular)', borderRadius: 4, fontSize: 10 }}
          color="#DF7411">
          New Requirement: {updateDate}
        </Tag>
        <Text>
          {text}
        </Text>
      </div>
    </Block>
  )
}

export default NewRequirementBlock