import { useState, useEffect } from "react";
import Modal from "../../../../components/common/ModalNew"
import { Text, Title, GoBackButton, ModalWrapper } from "../../EditUser/RemoveuserModal/style";
import { useForm } from "react-hook-form";
import Input from "../../../../components/common/Input";
import Button from "../../../../components/common/Button/button";

const RemoveCompanyModal = ({ onClose, onSubmit, companyName }) => {
  const { control, getValues, watch, } = useForm()
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    setInputValue(getValues('delete'))
  }, [watch('delete')])

  return (
    <Modal
      styleWrap={{ width: 'auto', height: 'auto', padding: 30, maxWidth: 580 }}
      onClose={onClose}>
      <Title>
        Delete <span>{companyName}</span>
      </Title>
      <Text>
        Are you sure you want to Delete this company?<br />If so type DELETE below to complete this action. <span>It can not be undone</span>.
      </Text>
      <Input
        style={{ width: 300, marginTop: 20 }}
        control={control}
        type="text"
        name="delete"
        placeholder="Type DELETE"
      />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1.5em' }}>
        <GoBackButton onClick={() => onClose()}>
          GoBack
        </GoBackButton>
        <Button
          text={`Delete Company`}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onSubmit()
          }}
          disabled={inputValue !== 'DELETE'}
          type={'main'}
          style={{
            padding: 10,
            marginRight: 0,
          }}
        />
      </div>
    </Modal>
  )
}

export default RemoveCompanyModal