import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Tabs from '../../../../components/common/Tabs/tabs'
import { checkRole } from '../../../../helpers/helpers'
import { useStore } from '../../../../mobx-store/context'
import { Title } from '../../Resource/style'
import { Button, Wrap } from './style'

const Header = observer(({ onTabChange, active: activeTab, goBack }) => {
  const params = useParams()
  const store = useStore()
  const history = useHistory()
  const location = useLocation()

  const isGuides = params.type === 'guides'

  const [active, setActive] = useState(isGuides ? 0 : 1)

  useEffect(() => {
    const tab = isGuides ? 0 : 1

    setActive(tab)

    onTabChange && onTabChange(tab)

    store.setSingleValue('resourceTab', tab)

    if (
      store.resourcePrevLink.length &&
      store.resourcePrevLink[store.resourcePrevLink.length - 2] ===
      location.pathname
    ) {
      const arr = [...store.resourcePrevLink]
      arr.pop()
      store.setResourcePrevLink(arr)
    }
  }, [])

  const onGoBack = () => {
    store.getJurisdiction(store.prevJurId)
    store.setSingleValue('currentJurId', store.prevJurId)
    history.push(
      store.resourcePrevLink[store.resourcePrevLink.length - 1] ||
      '/resource/table/guides'
    )
    const arr = [...store.resourcePrevLink]
    arr.pop()
    store.setResourcePrevLink(arr)
  }

  return (
    <Wrap>
      {goBack &&
        <Button onClick={onGoBack}>Go Back</Button>
      }
      <Title>ReSource</Title>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {!goBack && (
          <Tabs
            active={active}
            isResource
            tabs={[
              {
                key: 0,
                title: 'By Guides',
              },
              {
                key: 1,
                title: 'By Jurisdiction',
              },
            ]}
            onClick={(value) => {
              if (activeTab === value) return
              setActive(value)
              onTabChange && onTabChange(value)
              store.setSingleValue('resourcesCurrent', null)
              store.setSingleValue('resourceFiltered', null)
              store.setSingleValue('resourcesTable', null)
              store.setSingleValue('resourcesCurrentTopic', null)
              store.setSingleValue('resourceFilteredTopics', null)
              history.push(
                `/resource/table/${value === 0 ? 'guides' : 'jurisdictions'}`
              )
            }}
          />
        )}
        {/* {checkRole([store.user?.[0]?.resource_role], ['admin']) && (
          <Button to='/resource/options' style={{ whiteSpace: 'nowrap' }}>
            Edit My Available Guides
          </Button>
        )} */}
      </div>
    </Wrap>
  )
})

export default Header
