import { observer } from 'mobx-react-lite'
import React from 'react'
import Input from '../../../../../components/common/Input'
import { useStore } from '../../../../../mobx-store/context'
import { Bottom } from '../../../../Auth/authForm.style'
import { GridBot, GridTop, Wrap } from './form.style'

export const roles = [
  {
    key: 'admin',
    value: 'admin',
    name: 'ComplianceSource Admin',
    roles_that_can_change_current_role: ['admin'],
  },
  {
    key: 'member',
    value: 'member',
    name: 'ComplianceSource Team Member',
    roles_that_can_change_current_role: ['compliance_owner', 'admin'],
  },
  {
    key: 'compliance_owner',
    value: 'compliance_owner',
    name: 'ComplianceSource Owner',
    roles_that_can_change_current_role: ['compliance_owner', 'admin'],
  },
]

export const complianceLiteRoles = [
  {
    key: 'admin',
    value: 'admin',
    name: 'ComplianceSource Admin',
    roles_that_can_change_current_role: ['admin'],
  },
  {
    key: 'member',
    value: 'member',
    name: 'ComplianceSource Team Member,',
    roles_that_can_change_current_role: ['compliance_owner', 'admin'],
  },
]

export const reportRoles = [
  {
    key: 'admin',
    value: 'admin',
    name: 'ReportSource Admin',
    roles_that_can_change_current_role: ['admin'],
  },
  {
    key: 'member',
    value: 'member',
    name: 'ReportSource Team Member',
    roles_that_can_change_current_role: ['report_owner', 'admin'],
  },
  {
    key: 'report_owner',
    value: 'report_owner',
    name: 'ReportSource Owner',
    roles_that_can_change_current_role: ['report_owner', 'admin'],
  },
]
export const licenseRoles = [
  {
    key: 'admin',
    value: 'admin',
    name: 'LicenseSource Admin',
    roles_that_can_change_current_role: ['admin'],
  },
  {
    key: 'member',
    value: 'member',
    name: 'LicenseSource Team Member',
    roles_that_can_change_current_role: ['license_owner', 'admin'],
  },
  {
    key: 'license_owner',
    value: 'license_owner',
    name: 'LicenseSource Owner',
    roles_that_can_change_current_role: ['license_owner', 'admin'],
  },
]

export const reportLiteRoles = [
  {
    key: 'admin',
    value: 'admin',
    name: 'ReportSource Admin',
    roles_that_can_change_current_role: ['admin'],
  },
  {
    key: 'member',
    value: 'member',
    name: 'ReportSource Team Member',
    roles_that_can_change_current_role: ['report_owner', 'admin'],
  },
]

const Form = observer(({ control, errors }) => {
  const store = useStore()

  const isActive = store.member?.is_active

  return (
    <Wrap>
      <div style={{ display: 'flex' }}>
        <div>
          <GridTop>
            <Input
              name={'first_name'}
              label={'First Name'}
              error={errors && errors.first_name && 'Enter valid first name'}
              type={'text'}
              transparent
              control={control}
              validation={{ required: true, pattern: /[A-Za-z]+/ }}
              placeholder={'First Name'}
              style={{ marginBottom: errors && errors.first_name && '0' }}
              disabled={!isActive}
            />
            <Input
              name={'last_name'}
              label={'Last Name'}
              error={errors && errors.last_name && 'Enter valid last name'}
              type={'text'}
              control={control}
              placeholder={'Last Name'}
              transparent
              validation={{ required: true, pattern: /[A-Za-z]+/ }}
              style={{ marginBottom: errors && errors.last_name && '0' }}
              disabled={!isActive}
            />
          </GridTop>
          <GridBot>
            <Input
              name={'email'}
              label={'Email'}
              error={
                errors &&
                errors.email &&
                (errors.email.message || 'Enter valid email')
              }
              type={'email'}
              placeholder={'Email Address'}
              transparent
              disabled
              control={control}
              validation={{ required: true }}
              style={{ marginBottom: errors && errors.email && '0' }}
            />

            <Input
              name={'phone_number'}
              label={'Phone'}
              error={
                errors &&
                errors.phone_number &&
                'Enter valid phone or leave this field empty'
              }
              type={'phone'}
              transparent
              control={control}
              style={{ marginBottom: errors && errors.phone_number && '0' }}
              disabled={!isActive}
            />
          </GridBot>
        </div>
      </div>
      <Bottom
        style={{
          maxWidth: '100%',
          alignItems: 'center',
          justifyContent: 'flex-end',
          flexDirection: 'row',
        }}
      />
    </Wrap>
  )
})

export default Form
