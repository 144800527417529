import { message } from 'antd'
import React, { useState } from 'react'
import { api } from '../../../../../api'
import ButtonOrange from '../../../../../components/common/ButtonOrange'
import { useStore } from '../../../../../mobx-store/context'
import Modal from './modal'
import { Image, Wrap } from './style'

const Card = ({
  onClick,
  data,
  clickable,
  styleWrap,
  isLastCard,
  allowDelete,
  cardType,
}) => {
  const store = useStore()
  const [visible, setVisible] = useState(false)

  const expire =
    data.card_exp_date.substring(0, 2) + `/` + data.card_exp_date.substring(2)

  const ending = data.card_number.substring(12)

  const deleteCard = () => {
    api(`/payment_cards/${data.id}/`, {}, 'DELETE').then((data) => {
      if (data.deleted) {
        store.getBillingCards()
        setVisible(false)
        message.success('Card deleted')
      } else {
        message.error('You can not delete this card')
      }
    })
  }

  return (
    <Wrap
      clickable={clickable}
      onClick={() => {
        if (clickable) onClick(data)
      }}
      active={data.is_primary}
      style={styleWrap}
    >
      {visible && (
        <Modal
          onClose={() => setVisible(false)}
          onDelete={deleteCard}
          card={ending}
          cardType={cardType}
        />
      )}
      <Image src={data.img_full_url} alt={'credit-card-icon'} />
      <div>
        <div>
          {data.card_short_description} ending in {ending}
        </div>
        <div
          style={{
            display: 'flex',
            whiteSpace: 'nowrap',
            justifyContent: 'space-between',
          }}
        >
          <div>
            Expires {expire} <br />
            {!isLastCard && allowDelete && (
              <ButtonOrange
                text={'Delete Card'}
                style={{ color: '#d9363e' }}
                onClick={() => setVisible(true)}
              />
            )}
          </div>
          {!clickable && (
            <div>
              <ButtonOrange
                text={'Edit Card'}
                style={{ marginLeft: 3 }}
                onClick={() => onClick(data)}
              />
            </div>
          )}
        </div>
      </div>
    </Wrap>
  )
}

export default Card
