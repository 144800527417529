import styled from 'styled-components'

export const BlueBlock = styled("div")`
  text-align: center;
  padding: 10px 0;
  border: 2px solid var(--blue);
  border-radius: 3px;
  font-size: 0.875em;
  color: var(--blue);
  margin-top: 20px;
`;

export const Grid = styled("div")`
  display: grid;
  grid-template-columns: 79% 21%;
  margin-bottom: 30px;
`;
