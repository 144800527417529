import styled, { keyframes } from 'styled-components'

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

export const Wrap = styled("div")`
  animation: ${props => props.fadeOut ? slideOut : slideIn} 1s forwards;
  z-index: 9998;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1px;
  height: auto;
  background: #F4F6FA;
  display: flex;
  justify-content: center;
  position: fixed;
    left: 0;
    bottom: 0;
    margin-top: 10px;
  // @media (min-height: ${(props) => (!props.settingsPage ? '900px' : props.exeptions ? '1100px' : '1000px')}) {
  //     position: absolute;
  //     bottom: 0;
  //   };
  `;

export const Title = styled("div")`
  font-family: var(--open-sans-semiBold);
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.4px;
  text-align: center;
  color: #515151;
  padding: 7px 15px;
  @media (min-width: 1200px) {
      font-size: 10px;
      line-height: 12px;
      padding: 7px 15px 5px 15px;
  };

`;