import styled from 'styled-components'

export const Wrap = styled('div')`
 width: ${props => props.calendar ? '100%' : ''};
  margin-top: 1em;
`

export const TopLine = styled('div')`
  display: flex;
  justify-content: ${props => props.calendar ? 'flex-end' : 'space-between'};
  align-items: center;
`

export const ShowMore = styled('div')`
  color: var(--orange);
  text-decoration: underline;
  font-family: var(--medium);
  font-size: 0.875em;
  cursor: pointer;

  .ant-checkbox-inner {
    width: 15px;
    height: 15px;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    transform: rotate(45deg) scale(1) translate(-65%, -40%);
  }
`

export const Bottom = styled('div')`
  max-height: ${(props) => (props.active ? 'fit-content' : '0')};
  overflow-y: ${p => p.isDateRangeVisible ? 'visible' : 'hidden'};
  transition: all 0.2s ease-in-out;
  border: 1px solid ${(props) => (props.active
    ? 'var(--orange)'
    : 'transparent')};
  padding: ${(props) => (props.active ? '1em' : 0)};
  margin: ${(props) => (props.isMyTasks ?
    props.active ? '1em 0'
      : '0'
    : props.calendar ? '2em 0' :
      '1em 0')}; 
  margin-bottom: 1em;
`

export const Inputs = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  margin-bottom: 1em;
`
export const CalendarInputs = styled('div')`
  display: grid;
  grid-template-columns: ${props => !props.showModeBlocks ? '1fr 1fr 1fr' : '1fr 1fr 1fr 1fr'};
  gap: 5em;
  margin-bottom: 1.5em;
`
export const CheckboxCover = styled('div')`
display: flex;
  margin-bottom: 1em;
  margin-left: 1em;
  align-items: center;
`
export const CheckboxName = styled('div')`
  font-family: var(--regular);
  opacity: 0.6;
  font-size: 16px;
  color: var(--text);
`

