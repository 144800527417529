import styled from 'styled-components'
import { WrapWhite } from '../../../components/common/Button/button.style'

export const Wrap = styled('div')`
  width: 80vw;
  max-width: 1500px;
  margin: 0 auto 3em auto;

  @media (max-width: 1800px) {
    width: 98vw;
    max-width: 100%;
  }
`

export const Row = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80vw;
  max-width: 1500px;
  margin-top: 2em;
  @media (max-width: 1800px) {
    width: 98vw;
    max-width: 100%;
  }
`

export const Title = styled('div')`
  //height: 60px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: var(--text);
  font-family: var(--semiBold);
  font-size: 1.625em;
  text-align: center;
  border-bottom: 6px solid transparent;
  margin: 0 0.5em 0 0.5em;
  transition: all 0.2s ease-in-out;
  line-height: 1.1em;
`

export const UnderTitle = styled('div')`
  font-size: 1.375em;
  font-family: var(--medium);
  font-style: italic;
  color: var(--text);
  text-align: center;
  margin-bottom: 0.5em;
`

export const Option = styled('div')`
  height: 80px;
  font-family: var(--medium);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1em;
  color: var(--text);
  border-top: 2px solid #d8d8da;
  text-align: center;

  span {
    font-family: var(--open-sans-bold);
    display: contents;
  }

  .ant-popover-inner-content {
    max-height: 300px !important;
    overflow-y: auto;
  }
`

export const OptionTitle = styled('div')`
  display: flex;
  align-items: center;

  img {
    margin-left: 0.5em;
    cursor: pointer;
  }
`

export const Tooltip = styled('div')`
  font-size: 16px;
  font-family: var(--medium);
  margin-bottom: 0.5em;
  color: var(--blue);
  white-space: nowrap;

  span {
    font-family: var(--bold);
    color: var(--orange);
  }
`

export const Premium = styled('div')`
  font-family: var(--light);
  font-size: 1.563em;
  height: 3.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(34, 81, 148, 0.8);
  border-left: 0;
  width: 100%;

  span,
  div > div {
    font-family: var(--regular);
    font-size: 0.542em;
  }

  div > div {
    line-height: 1em;
  }
`

export const PremiumDiscount = styled('div')`
  font-family: var(--medium);
  color: var(--orange);
`

export const Footer = styled('div')`
  font-size: 1.375em;
  color: ${(props) => (props.active ? 'var(--text)' : '#848e8b')};
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: var(--semiBold);
  line-height: 1.3em;
  margin-top: 20px;

  span {
    font-size: 0.727em;
    margin-top: 0.3em;
    font-family: var(--regular);
  }

  img {
    margin-left: 0.5em;
    cursor: pointer;
  }
`

export const TotalOld = styled('div')`
  font-size: 0.727em;
  font-family: var(--light);
  text-decoration: line-through;
  color: var(--blue);
  margin-right: 0.5em;
`

export const Item = styled('div')`
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 4px 4px 2px rgba(0, 0, 0, ${(props) => (props.active
    ? '0.1'
    : 0)});
  z-index: ${(props) => props.active && '3'};
  border-radius: 4px;
  border-top: 5px solid ${(props) => (props.active
    ? 'var(--orange)'
    : 'transparent')};

  ${Footer} {
    border-color: ${(props) => (props.active ? '#C9DF9B' : 'var(--green)')};
  }

  ${Option} {
    border-color: ${(props) => (props.active ? '#E9E9EB' : '#D8D8DA')};
  }

  ${WrapWhite} {
    background-color: ${(props) => (props.active ? 'var(--orange)' : '#fff')};
    color: ${(props) => (props.active ? '#fff' : 'var(--blue)')} !important;
    border-color: ${(props) =>
      props.active ? 'var(--orange)' : 'var(--blue)'} !important;
    font-family: ${(props) =>
      props.active ? 'var(--bold)' : 'var(--regular)'};
  }

  &:hover {
    z-index: 3;
    box-shadow: 0 4px 4px 2px rgba(0, 0, 0, 0.1);
  }
`

export const LogoWrap = styled('div')`
  padding-left: 5vw;
  padding-top: 3em;
`

export const PageTitle = styled('div')`
  font-size: 65px;
  font-family: var(--thin);
  color: #fff;
  background-color: #4e74a9;
  margin-bottom: 50px;
  padding-top: 50px;
  border-top-left-radius: 8px;
  @media (max-width: 1800px) {
    min-width: 234px;
  }
`

export const Grid = styled('div')`
  display: grid;
  grid-template-columns: 234px 1fr;
  margin-top: 5em;
  @media (max-width: 1800px) {
    grid-template-columns: minmax(234px, 20vw) 1fr;
  }
`

export const RightSide = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, 316px);
  overflow-x: auto;
  padding-bottom: 1em;
  //padding-top: 4em;
  @media (max-width: 1800px) {
    grid-template-columns: repeat(4, minmax(316px, 20vw));
  }

  div:first-child {
    div {
      div {
        padding-left: 0;
      }
    }
  }
`

export const LeftSide = styled('div')`
  ${Footer} {
    background-color: ${(props) =>
      props.active ? 'rgba(145, 192, 62, 0.5) !important' : 'var(--green)'};
    border-bottom-left-radius: 7px;
    font-family: var(--bold);
    color: #fff;
    justify-content: center;
    font-size: 1.375em;
    padding-left: 0.6em;
  }

  ${Option} {
    background-color: ${(props) => (props.bgColor ? props.bgColor : '#4e74a9')};
    align-items: flex-start;
    padding-left: 1em;
    text-align: left;
    font-family: var(--semiBold);
    color: #fff;

    ul {
      padding: 0;

      li {
        font-family: var(--regular);
        list-style-position: inside;

        &::marker {
          margin-right: 0;
        }
      }
    }
  }
`

export const TitleLeft = styled('div')`
  font-family: var(--regular);
  font-size: 36px;
  color: #fff;
  text-align: left;
  padding-bottom: 0.3em;
  border-top-left-radius: 8px;
  padding-left: 15px;
`

export const PriceBlock = styled('div')`
  height: 13.7em;
  display: flex;
  flex-direction: column;

  span {
    font-size: 1.125em;
    font-family: var(--medium);
    text-align: center;
    margin-top: 1em;
    color: #8c8c8c;
  }
`

export const Features = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 22.313em;
  background-color: #4e74a9;
  padding-top: 2em;
`

export const FeaturesTitle = styled('div')`
  font-family: var(--regular);
  font-size: 1.25em;
  color: #fff;
  text-align: center;
  margin-top: 1em;
`

export const Low = styled('div')`
  font-family: var(--regular);
  font-size: 1.25em;
  color: var(--green);
  text-align: center;
  margin-top: 0.75em;
  background-color: #f5f9ec;
  border-top: 2px solid #d8dad4;
  border-bottom: 2px solid #d8dad4;
`

export const BestTitle = styled('div')`
  font-family: var(--regular);
  font-size: 1.25em;
  color: var(--text);
  margin-top: 0.5em;
`

export const PriceDescription = styled('div')`
  font-size: 1em;
  color: var(--text);
  font-family: var(--regular);
  width: 12.75em;
  margin: 1em auto;
  text-align: center;
`

export const StartingAt = styled('div')`
  color: var(--grey);
  font-family: var(--open-sans-reg);
  font-size: 1.313em;
  margin-top: 0.5em;
`

export const Price = styled('div')`
  font-family: var(--light);
  font-size: 1.563em;
  color: #000;
  text-align: center;
  margin-top: auto;

  span {
    color: var(--green);
    font-size: 1.12em;
    font-weight: var(--medium);
  }
`

export const AnnualRequired = styled('div')`
  font-size: 14px;
  font-family: var(--regular);
  font-style: italic;
  color: rgba(69, 81, 84, 0.7);
  text-align: center;
  line-height: 1em;

  span {
    color: #a7b9d4;
    text-decoration: line-through;
    margin-right: 0.3em;
  }
`
