import { Select } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import crossOrange from '../../../../../../assets/images/cross-orange.svg'
import Button from '../../../../../../components/common/Button/button'
import { useStore } from '../../../../../../mobx-store/context'
import { Title } from '../../Bills/style'
import { Item, ItemsWrap, SelectBLock, Wrap } from './style'

const AssignedItems = observer(
  ({ items, title, type, onChange, disabled, findItemVar }) => {
    const store = useStore()
    const [value, setValue] = useState(null)
    
    const getArray = () => {
      const arr = items.map((item) => {
        return {
          value: item,
          string: store[type]?.find((findItem) => {
            return (
              (findItemVar ? +findItem[findItemVar] : +findItem?.id) ===
              (+item || +item.id)
            )
          })?.name,
        }
      })
      return arr.filter((item) => item.string)
    }

    const onDelete = (value) => {
      const filtered = items.filter((item) => item !== value)
      onChange(filtered)
    }

    const assign = () => {
      if (!items.includes(value)) {
        onChange([...items, value])
        setValue(null)
      }
    }

    const getOptions = () => {
      const options =
        store[type]?.filter(
          (item) =>
            !items.includes(
              type === 'resources' ? item.guide_name_id : item.id
            )
        )
      return options?.map((item) => (
        <Select.Option value={type === 'resources' ? item.guide_name_id : item.id} key={type === 'resources' ? item.guide_name_id : item.id}>
          {item.name}
        </Select.Option>
      ))
    }

    return (
      <Wrap>
        <Title>
          {title} <span>({getArray() && getArray()?.length})</span>
        </Title>
        <ItemsWrap>
          {getArray()?.map((item, index) => (
            item.string && <Item
              key={item.value}
              style={{
                backgroundColor: index % 2 !== 0 && 'rgba(34, 81, 148, 0.05)',
              }}
            >
              {item.string}
              <img
                src={crossOrange}
                onClick={() => !disabled && onDelete(item.value)}
                alt='cross-icon'
              />
            </Item>
          ))}
        </ItemsWrap>
        {((items?.length !== store[type]?.length)  ) && (
          <SelectBLock>
            <Select
              onChange={(value) => setValue(value)}
              value={value}
              loading={!store.member}
              disabled={disabled}
            >
              {getOptions()}
            </Select>
            <Button
              text={'Assign'}
              style={{ height: 20, fontSize: 10, marginLeft: 15 }}
              onClick={() => value && assign()}
              disabled={disabled}
            />
          </SelectBLock>
        )}
      </Wrap>
    )
  }
)

export default AssignedItems
