import styled from 'styled-components'

export const Wrap = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
`

export const Image = styled('img')``

export const Title = styled('div')`
  font-family: var(--medium);
  font-size: 24px;
  color: var(--blue);
  text-align: left;
  margin-left: 15px;
`

export const Desc = styled('div')`
  font-size: 14px;
  color: #455154;
  width: 314px;
  text-align: left;
  margin: 15px 0 0 15px;

  a {
    color: var(--orange)
  }
`
