import styled from 'styled-components'

export const Block = styled('div')`
  border-bottom: 1px solid ${(props) => (props.bordered ? '#C4CACC' : '#fff')};
`

export const DocumentsPlaceholder = styled('div')`
  color: var(--text);
  font-size: 1em;
  font-family: var(--regular);
  opacity: 0.6;
`

export const Label = styled('div')`
  font-family: var(--semiBold);
  font-size: 0.875em;
  color: var(--text);
  margin-bottom: 0.5em;
`
export const DocumentWrap = styled('div')`
  font-family: var(--open-sans-semiBold);
  font-size: 0.875em;
  color: var(--text);
  padding: 1em;
  background-color: ${(props) => (props.grey ? '#f4f6f6' : '#fff')};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const DocumentRight = styled('div')`
  display: flex;

  div,
  a {
    display: flex;
    align-items: center;
    color: var(--text);
    font-style: italic;
    cursor: pointer;
    font-family: var(--regular);
      margin-right: 2em;
      &:last-child {
        margin-right: 0;
      }
    

    img {
      margin-right: 0.5em;
      height: 1em;
    }
  }
`