import styled from "styled-components";

export const SelectBLock = styled("div")`
  display: flex;
  .ant-select-selector {
    height: 20px !important;
    border-radius: 5px !important;
    font-family: var(--regular) !important;
  }
  .ant-select-selection-search-input {
    height: 20px !important;
    width: 100px !important;
  }
  .ant-select {
    height: 20px;
    width: 100px;
  }
  .ant-select-selection-item {
    height: 20px;
    line-height: 20px !important;
    font-size: 12px !important;
  }
  .ant-select-selection-search {
    height: 20px;
  }`

export const CustomizeButton = styled("div")`
color: var(--blue);
font-family: var(--regular);
font-size: 10px;
cursor: pointer;
text-decoration-line: underline;
`