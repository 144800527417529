import styled from 'styled-components'
import { Text, Title } from '../style'

export const Wrap = styled("div")`
  padding-top: 70px;
  padding-bottom: 150px;
  ${Title} {
    text-align: center;
  }
  ${Text} {
    text-align: center;
    font-size: 20px;
    margin-bottom: 80px;
  }
`;

export const Row = styled("div")`
  display: grid;
  grid-template-columns: 600px 1fr;
`;

export const Contact = styled("div")`
  display: flex;
  font-family: var(--regular);
  font-size: 32px;
  color: var(--blue);
  margin-bottom: 30px;
  img {
    height: 35px;
    margin-top: 10px;
    margin-right: 30px;
  }
`;

export const BGImage = styled("img")`
  position: absolute;
  width: 580px;
  height: 580px;
  margin-top: -120px;
  margin-left: -120px;
`;
