import PropTypes from 'prop-types'
import React from 'react'
import Item from './item'
import { Top, Total, Wrap } from './summary.style'

const Summary = ({ data, isResource, isReportSource, isProposedSource }) => {
  return (
    <Wrap>
      <Top>Order Summary</Top>

      {isReportSource
        ? reportSummary.map((item, index) => (
          <Item
            key={index}
            item={item}
            isResource={isResource}
            isReportSource={isReportSource}
            isProposedSource={isProposedSource} />
        ))
        : isProposedSource
          ? proposedSummary.map((item, index) => (
            <Item
              key={index}
              item={item}
              isResource={isResource}
              isReportSource={isReportSource}
              isProposedSource={isProposedSource} />
          ))
          : data?.summary?.map((item, index) => (
            <Item key={index} item={item} isResource={isResource} />
          ))
      }

      <Total>
        <div>Order Total</div>
        <div>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(data?.total)}
        </div>
      </Total>
    </Wrap>
  )
}
const reportSummary = [
  {
    label: 'ReportSource',
    description: '(All) Jurisdictions',
    sum: 5000,
  },
  // {
  //   label: 'Annual Discount',
  //   description: '$600 discount for annual payment',
  //   sum: -600,
  //   code: 'annual_discount',
  // },
]

const proposedSummary = [
  {
    label: 'ReportSource',
    description: 'Stand Alone Price',
    sum: 60000,
  },
  // {
  //   label: 'Annual Discount',
  //   description: '$600 discount for annual payment',
  //   sum: -600,
  //   code: 'annual_discount',
  // },
]


Summary.propTypes = {
  data: PropTypes.object,
  paymentPeriod: PropTypes.string,
}

export default Summary
