import Modal from "../../../../../components/common/ModalNew"
import { Title, Text } from "./style"
import Button from "../../../../../components/common/Button/button"
import { PrevBtn } from "../../../CreateCompany/pages/FirstPage/Form/style"
const RemoveReportModal = ({ onClose, onRemove, serviceName }) => {
  const isReportSource = serviceName === 'report'
  const isCompliance = serviceName === 'compliance'

  return (
    <Modal styleWrap={{
      borderRadius: 8,
      padding: 30,
      display: 'flex',
      flexDirection: 'column',
    }}
      onClose={onClose}
    >
      <Title>Delete This {isReportSource ? 'Report' : isCompliance ? 'Compliance Item' : 'Proposed Item'}  </Title>
      <Text>Are you sure you want to delete this {isReportSource ? 'Report' : isCompliance ? 'Compliance Item' : 'Proposed Item'}?</Text>
      <Text style={{ fontFamily: 'var(--bold)', marginTop: 10 }}>This action can not be undone.</Text>
      <div style={{ marginTop: 40, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center' }}>
        <PrevBtn style={{ marginTop: 7, textDecoration: 'none' }} onClick={() => onClose()}>Go Back</PrevBtn>
        <Button
          text={isReportSource ? 'Delete Report' : isCompliance ? 'Delete Compliance Item' : 'Delete Proposed Item'}
          onClick={(e) => {
            onRemove()
            e.stopPropagation()
          }}
          style={{
            padding: '10px 10px',
            marginRight: 0,
            marginLeft: '.5em',
          }}
        />
      </div>
    </Modal>
  )
}

export default RemoveReportModal