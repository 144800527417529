import React, { useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import img from '../../../assets/images/trialOver.svg'
import Button from '../../../components/common/Button/button'
import Logo from '../../../components/common/Logo/logo'
import { useStore } from '../../../mobx-store/context'
import {
  BackButton,
  Container,
  Description,
  Wrap,
} from '../../Auth/Agreement/agreement.style'
import { Title } from '../../Auth/authForm.style'
import { Image, MiniTitle } from '../../Auth/ResetPass/resetPass.style'

const TrialEnd = () => {
  const store = useStore()
  const location = useLocation()
  const history = useHistory()

  const isAdmin = store.user?.[0]?.member_type === 'admin'
  const isTrialEnded = location.pathname.split('/')[2] === 'trial_is_over'

  useEffect(() => {
    if (store.company?.[0]?.payment_status === 'paid')
      history.push('/tracking/main')
  }, [])

  return (
    <Container>
      <Wrap disableScroll>
        <Logo large />
        <Title
          style={{
            borderBottom: '1px solid rgba(196, 202, 204, 0.6)',
            paddingBottom: '.7em',
          }}
        >
          {isTrialEnded
            ? 'Your Trial Has Ended'
            : 'Unable to Process Your Payment'}
          <span>Locked Account</span>
        </Title>
        <Image
          src={img}
          alat={'email-image'}
          style={{ height: 'auto', width: '80%', margin: '20px auto 0 auto' }}
        />
        <MiniTitle style={{ marginTop: 10, textAlign: 'center' }}>
          {isTrialEnded
            ? `To allow ${isAdmin && 'you and'} your team access, speak to your
          PBMSource admin.`
            : 'PMBSource has been locked for your team.'}
        </MiniTitle>
        <Description
          style={{
            fontFamily: 'var(--regular)',
            fontSize: '.875em',
            textAlign: 'center',
          }}
        >
          {isAdmin ? (
            <>
              We were unable to process your payment. Please check your payment
              details and make sure they’re correct.{' '}
              <Link
                to='/billing-info'
                style={{
                  color: 'var(--orange)',
                  textDecoration: 'underline',
                  fontFamily: 'var(--medium)',
                }}
              >
                Billing Info
              </Link>
              . OR if you continue having problems{' '}
              <a
                href='mailto:contact@pbmsource.com'
                style={{
                  color: 'var(--orange)',
                  textDecoration: 'underline',
                  fontFamily: 'var(--medium)',
                }}
              >
                contact us
              </a>
              .
            </>
          ) : (
            'We were unable to process your payment. Please speak to your admin to have the payment information updated.'
          )}
        </Description>
        {isAdmin && (
          <Button
            text={isTrialEnded ? 'Add Payment Method' : 'Go to Billing Info'}
            style={{ margin: '20px auto 0 auto' }}
            to={'/billing-info'}
          />
        )}
        <BackButton to={'/login'} as={Link}>
          &lt;&lt;Return to Sign In
        </BackButton>
      </Wrap>
    </Container>
  )
}

export default TrialEnd
