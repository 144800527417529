import React from 'react'
import { Wrap } from './style'
import { Spin } from 'antd'

const FullScreenLoader = () => {
  return (
    <Wrap>
      <Spin/>
    </Wrap>
  )
}

export default FullScreenLoader
