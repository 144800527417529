import { observer } from 'mobx-react'
import React from 'react'
import { Label } from '../../../../components/common/Input/input.style'
import { useStore } from '../../../../mobx-store/context'
import Item from './item'
import { Scrollable } from './style'

const UsersFromTracking = observer(({ onChange, isResource }) => {
  const store = useStore()

  const filter = (item) =>
    item.member_type !== 'admin' &&
    (isResource
      ? item.have_access_to_tracking && !item.have_access_to_resource
      : !item.have_access_to_tracking && item.have_access_to_resource)

  const members = store.members?.filter((item) => filter(item))

  if (!members?.length) return null

  return (
    <div style={{ marginBottom: 20, overflow: 'hidden', minHeight: 300 }}>
      <Label>
        Add Team Members From {isResource ? 'ComplianceSource' : 'ReSource'}
      </Label>
      <Scrollable>
        {members?.map((item) => (
          <Item
            key={item.id}
            data={item}
            onChange={onChange}
            isResource={isResource}
          />
        ))}
      </Scrollable>
    </div>
  )
})

export default UsersFromTracking
