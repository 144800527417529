import { Popover } from 'antd'
import infoIcon from '../../../assets/images/infoIcon.svg'
import {Title} from './style'
// import './style.css'

const GuideNameTooltip = ({ guideName, description = 'No Description' }) => {
  return (
    <Title style={{ display: 'flex', alignItems: 'center' }}>
      {guideName}
      <Popover
        placement="right"
        content={description}
        overlayInnerStyle={{ border: '2px solid #4B75B1', borderRadius: 5 }}
      >
        <img style={{ marginLeft: 5 }} src={infoIcon} alt="info-icon" />
      </Popover>
    </Title>
  )

}

export default GuideNameTooltip