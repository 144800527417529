import styled from 'styled-components'

export const UnderTitle = styled('div')`
  font-size: 1em;
  font-family: var(--regular);
  color: var(--text);
  margin-top: 10px;
  margin-bottom: 30px;
`

export const CustomRadioGroup = styled('div')`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  .ant-radio-group {
    display: flex;
    flex-direction: ${(props) => (props.row ? 'row' : 'column')};
    align-items: ${(props) => props.row && 'center'};
  }
  .ant-radio-wrapper {
    font-size: 16px;
    font-family: var(--regular);
    color: var(--text);
  }
  .ant-radio-inner {
    border: 2px solid var(--text);
    width: 20px;
    height: 20px;
    &:after {
      background-color: var(--text);
    }
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: var(--text);
    box-shadow: none !important;
  }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: var(--text);
  }
`

export const Row = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`
