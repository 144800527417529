import styled from 'styled-components'

export const Wrap = styled('div')``

export const Grid = styled('div')`
  display: grid;
  grid-template-columns: 250px 1fr;
  position: relative;
`

export const Row = styled('div')`
  display: flex;
`

export const Cards = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`
