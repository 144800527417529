import { message } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import Moment from 'react-moment'
import { useHistory } from 'react-router-dom'
import { api } from '../../../api'
import Button from '../../../components/common/Button/button'
import Logo from '../../../components/common/Logo/logo'
import SelectedPlan from '../../../components/common/SelectedPlan/selectedPlan'
import { formatDate, getError } from '../../../helpers/helpers'
import { useStore } from '../../../mobx-store/context'
import { Description, Title } from '../authForm.style'
import {
  CalendarWrap,
  CalendarWrap1,
  Container,
  DateBlock,
  ForgetLink,
  Row,
} from './startDate.style'

const StartDate = observer(() => {
  const store = useStore()
  const history = useHistory()
  const [startDate, setStartDate] = useState(null)
  const [amount, setAmount] = useState(0)
  const [endDate, setEndDate] = useState(null)
  const [string, setString] = useState('')

  const patchCompany = async (noSave) => {
    await api(
      `/companies/${store.company[0].id}/`,
      {
        start_date: noSave
          ? formatDate(new Date(), true)
          : formatDate(startDate, true),
      },
      'PATCH'
    ).then((data) => {
      if (!data.errors) {
        store.setSingleValue('company', [data])
        history.push(`/billing`)
      }
    })
  }

  const handleStartDate = (date, format = true) => {
    api(
      `/payments/pre_registration_amount/?start_date=${
        format ? formatDate(date, true) : date
      }`,
      {},
      'GET'
    ).then((data) => {
      if (!data.errors) {
        setAmount(data.pre_registration_amount)
      } else {
        message.error(getError(data))
      }
    })
  }

  useEffect(() => {
    if (store.company.length) {
      if (store.company[0].start_date) {
        setStartDate(new Date(store.company[0].start_date))
        handleStartDate(store.company[0].start_date, false)
      } else {
        setStartDate(new Date())
      }
    }
  }, [store.company])

  useEffect(() => {
    api('/payments/tariff_string/', {}, 'GET')
      .then((data) => {
        if (data.errors) {
          message.error(getError(data))
        }
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <>
      <Row style={{ marginTop: '1em' }}>
        <Logo />
        <SelectedPlan />
      </Row>
      <Container>
        <Title>Select a Date Range</Title>
        <Description style={{ maxWidth: 'none' }}>
          Select a date range prior to today to get legislation that was
          released before your start date of (
          <Moment format={'MM/DD/YYYY'}>{startDate}</Moment>). Additions will
          affect the final price according to your selected plan period
        </Description>
        <DateBlock>
          <span>+${amount}</span>
          {/*{string.additional_string}*/}
          <span>
            {startDate && formatDate(startDate)} - {formatDate(new Date())}
          </span>
        </DateBlock>
        <Row style={{ width: '100%', height: 'auto' }}>
          <CalendarWrap>
            <Calendar
              calendarType={'US'}
              locale={'en-EN'}
              onChange={(value) => {
                setStartDate(value)
                handleStartDate(value)
              }}
              defaultActiveStartDate={startDate}
              value={startDate}
              maxDate={new Date()}
              minDate={new Date(2021, 0, 1)}
            />
          </CalendarWrap>
          <span>to</span>
          <CalendarWrap1 startDate={startDate} endDate={endDate}>
            <Calendar
              calendarType={'US'}
              locale={'en-EN'}
              onChange={(value) => setEndDate(value)}
              minDate={startDate}
              maxDate={new Date()}
              tileDisabled={() => !startDate}
              defaultActiveStartDate={new Date()}
              value={new Date()}
            />
          </CalendarWrap1>
        </Row>
        <Row
          style={{ width: '100%', justifyContent: 'center', marginTop: '2em' }}
        >
          <Button
            type={'main'}
            text={'Confirm Date Selection and Add to Order'}
            onClick={() => patchCompany()}
            disabled={!store.company.length}
          />
        </Row>
        <ForgetLink
          to={'/billing'}
          onClick={() => {
            patchCompany(true) // set today date
          }}
        >
          Skip this for now {'>>'}
        </ForgetLink>
      </Container>
    </>
  )
})

export default StartDate
