import styled from 'styled-components'

export const Wrap = styled("div")``;

export const List = styled("div")`
  padding-left: 70px;
  max-width: 220px;
`;

export const ListItem = styled("div")`
  font-size: 1em;
  font-family: var(--semiBold);
  color: var(--blue);
  margin-bottom: 0.5em;
  span {
    font-family: var(--regular);
  }
`;
