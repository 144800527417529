import {
  Activity,
  Block,
  Label,
  Text,
} from './style'
import Moment from 'react-moment'
import parse from 'html-react-parser'
import { Divider } from 'antd';
import { Back } from '../Tabs/Tracking/style'
import { Empty } from 'antd'

const SettingsActivity = ({ data, setShowActivity }) => {

  return (
    <>
      <Back style={{ marginBottom: 0, textDecoration: 'underline', cursor: 'pointer' }}
        onClick={() => setShowActivity(false)}>
        Go Back
      </Back>
      <Divider style={{ height: 1, backgroundColor: 'rgba(0, 0, 0, 0.20)', margin: '10px 0' }} />
      {data.length > 0
        ? <Block style={{ marginTop: '1em' }}>
          <Label>Activity</Label>
          {data.map(({ created, html_text }, index) => (
            <Activity
              key={created}
              style={{ backgroundColor: index % 2 !== 0 && '#fff' }}
            >
              <Text style={{ maxWidth: '80%' }}>{parse(html_text)}</Text>
              <Text style={{ fontSize: '.75em', color: '#7D8587' }}>
                <Moment format="MMM D, YYYY, hh:mm A" withTitle>
                  {created}
                </Moment>
              </Text>
            </Activity>
          ))}
        </Block>
        : < Empty description={"No Activities"} />
      }
    </>
  )
}

export default SettingsActivity

