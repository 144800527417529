import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { useStore } from '../../../../../../mobx-store/context'
import { Title } from '../../../../components/common/style'
import ResourceModal from '../../../../components/ResourceAccessModal/resourceModal'
import RestrictedItems from '../../RestrictedItemsBlock'
import { blocksTracking } from '../../RestrictedItemsBlock/static'
import { Back } from '../../../AutoAssignments/Tabs/Tracking/style'

const TrackingTab = observer(({ setShowActivity }) => {
  const store = useStore()
  const history = useHistory()

  if (!store.user?.[0]?.have_access_to_tracking || !store.company?.[0]?.have_access_to_tracking)
    return (
      <ResourceModal
        type='tracking'
        to='/edit-profile'
        onClose={() => history.push('/edit-profile')}
      />
    )

  return (
    <>
      <Title style={{ fontSize: 14, marginBottom: 15, fontFamily: 'var(--regular)' }}>
        Adding selection to the lists below will hide the selections from all users.
        <Back onClick={() => setShowActivity(true)}>View Restictions Activity</Back>
      </Title>
      <div style={{ display: 'flex' }}>
        {blocksTracking.map((item) =>
        (
          <RestrictedItems
            name={item.name}
            restrictions={item.restrictions}
            type={item.type}
            title={item.title}
          />
        )
        )}
      </div>
    </>
  )
})

export default TrackingTab
