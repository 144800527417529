import styled from 'styled-components'

export const Container = styled('div')`
  width: 1200px;
  margin: 0 auto;
`

export const GreenLineWrap = styled('div')`
  height: 11em;
  background-color: var(--green);
  display: flex;
  align-items: center;
`

export const PlanName = styled('div')`
  font-family: var(--light);
  font-size: 3em;
  color: #fff;
  max-width: 7em;
  line-height: 1.5em;
  margin-left: 1em;
`

export const TopTotal = styled('div')`
  font-family: var(--regular);
  font-size: 2.5em;
  line-height: 1.3em;
  color: #fff;
  margin-left: 1em;

  span {
    font-family: var(--light);
    font-size: 0.9em;
  }
`

export const Grid = styled('div')`
  display: grid;
  grid-template-columns: 420px 420px 1fr;
  padding-top: 2.5em;
`

export const Left = styled('div')`
  border-right: 1px solid #e4e4e4;
  padding-right: 3em;
  padding-bottom: 3em;
`

export const Middle = styled('div')`
  padding-left: 3em;
`

export const Agreement = styled('div')`
  font-family: var(--semiBold);
  font-size: 1.125em;
  color: var(--green);
  margin-top: 1em;

  span {
    font-size: 0.875em;
    font-family: var(--medium);
    color: #969fa2;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #fff;
    border-color: var(--green) !important;
  }

  .ant-checkbox-inner::after {
    border-color: var(--green);
  }

  .ant-checkbox-inner {
    border: 2px solid #d9d9d9 !important;
  }

  .ant-checkbox-checked::after {
    border: none;
  }
`

export const Right = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
`

export const TryFree = styled('div')`
  font-size: 0.875em;
  font-family: var(--medium);
  color: #969fa2;
  margin-top: 1em;
  letter-spacing: 0.25px;
  text-align: center;
`

export const RadioConfirm = styled('div')`
  border: 2px solid ${(props) => (props.visibleConfirm
    ? 'var(--orange)'
    : 'transparent')};
  padding: 0 15px 15px ${(props) => (props.visibleConfirm ? '15px' : 0)};
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  margin-top: 30px;
`

export const RadioWrap = styled('div')`
  display: flex;
  flex-direction: column;
`

export const RadioRight = styled('div')`
  display: flex;
  align-items: center;
  font-size: 0.75em;
  color: var(--orange);
  font-family: var(--semiBold);
  padding-top: 10px;
`
