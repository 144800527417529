import styled from 'styled-components'

export const Wrap = styled("div")`
  padding-top: 90px;
`;

export const Info = styled("div")`
  border: 1px solid #225194;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: var(--blue);
  font-family: var(--bold);
  font-size: 0.75em;
  padding: 0 15px;
  div {
    margin: 5px 0;
  }
  span {
    font-family: var(--regular);
  }
`;
