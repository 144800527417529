import styled from "styled-components";

export const Title = styled('div')`
color: var(--text);
font-family: var(--medium);
font-size: 24px;
  span {
  font-family: var(--bold);
  color: #455154;
  font-size: 24px;

}
`
export const Text = styled('div')`
color: var(--text);
font-family: var(--regular);
font-size: 16px;
`
