import styled from 'styled-components'

export const SelectedJurisdictions = styled('div')`
  width: 100%;
  border: 1px solid #cacfd0;
  border-radius: 6px;
  min-height: 40px;
  font-family: var(--medium);
  font-size: 16px;
  color: var(--green);
  padding: 0 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  div {
    margin-right: 10px;
    img {
      margin-left: 5px;
    }
  }
  span {
    color: var(--text);
    font-size: 16px;
    font-family: var(--regular);
    opacity: 0.6;
  }
`

export const CurrentJurs = styled('div')`
  font-family: var(--medium);
  color: #000;
  opacity: 0.3;
  font-size: 18px;
`
