import styled from 'styled-components'
import bg from '../../../assets/images/bg-logo.png'
import { Wrap as ButtonOrange } from '../../../components/common/ButtonOrange/style'

export const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 4;
  backdrop-filter: blur(3px);
`

export const BackButton = styled(ButtonOrange)`
  width: calc(100% + 100px);
  margin-left: -50px;
`

export const Wrap = styled('div')`
  width: 800px;
  height: ${(p) => p.disableScroll && '700px'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid #225194;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px 7.438em;
  position: relative;
  background-size: cover;

  ${BackButton} {
    width: ${(p) => p.disableScroll && 'auto'};
    margin-left: ${(p) => p.disableScroll && 0};
    ${(p) =>
      p.disableScroll &&
      `
      position: absolute;
      bottom: 10px;
      left: 10px;
    `}
  }

  @media (max-height: 830px) {
    justify-content: ${(p) => (p.disableScroll ? 'center' : 'flex-start')};
    height: ${(p) => (p.disableScroll ? '700px' : 'auto')};
    overflow-y: ${(p) => (!p.disableScroll ? 'auto' : 'hidden')};
    overflow-x: hidden;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media (max-height: 700px) {
    justify-content: ${(p) => (p.disableScroll ? 'center' : 'flex-start')};
    height: ${(p) => (p.disableScroll ? '550px' : 'auto')};
    overflow-y: ${(p) => (!p.disableScroll ? 'auto' : 'hidden')};
    overflow-x: hidden;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media (max-height: 600px) {
    justify-content: ${(p) => (p.disableScroll ? 'center' : 'flex-start')};
    height: ${(p) => (p.disableScroll ? '550px' : 'auto')};
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 20px;
    padding-bottom: 20px;
  }


  &:after {
    content: '';
    position: absolute;
    background: url(${bg});
    height: 800px;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    z-index: -1;
    right: -44%;
    top: -90px;
  }
`
export const Description = styled('div')`
  font-size: 14px;
  color: var(--text);
  font-family: var(--medium);
  text-align: justify;
  margin-top: 1em;
  width: calc(100% + 100px);
  transform: translateX(-50px);
`

export const CheckRow = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--semiBold);
  color: ${(props) => (props.isNotAgreed ? 'var(--orange)' : 'var(--text)')};
  font-size: 1.125em;
  margin-top: 2em;
  cursor: pointer;

  .ant-checkbox-inner {
    border-color: ${(props) => props.isNotAgreed && 'var(--orange)'};
  }
`
