import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import checkGreenIcon from '../../../assets/images/check-green.svg'
import crossOrangeIcon from '../../../assets/images/cross-orange.svg'
import DateRange from '../DateRange'
import { Popover } from 'antd'
import questIcon from '../../../assets/images/help_24px.svg'
import {
  ErrorText,
  InputWrap,
  Label,
  Row,
  ValidationIcon,
  Wrap,
} from './input.style'
import { inputTypes } from './inputTypes'
import './style.css'

const Input = (props) => {
  const {
    disableValidation = false,
    control,
    name,
    validation,
    defaultValue,
    styleRow,
    styleContainer,
    disabled,
    label,
    smallLabel,
    labelStyle,
    error,
    style,
    placeholderColor,
    bigText,
    success,
    relativeIcon,
    type = 'text',
    transparent,
    isSearchOpened,
    onKeyDown,
    handleChange,
    handleShowDateRange,
    reset,
    styleError,
    noLimit,
    disableFutureDates,
    dateRangeStyle,
    addNew,
    onAddNew,
    multiSelectItemColor,
    patternText,
    isPatternText,
    editing,
    handleBlur,
    onFocus,
    showCross = true,
    disclamer,
    disclamerContent,
    listHeight,
    allowHandleChange,
  } = props

  const [showDateRange, setShowDateRange] = useState(false)

  const rules = {
    email: {
      validate: (value) => {
        return !!value?.trim()
      },
      ...validation,
    },
    text: {
      validate: (value) => {
        return !disableValidation ? !!value?.trim() : true
      },
      ...validation,
    },
    password: {
      validate: (value) => {
        return !!value?.trim()
      },
      ...validation,
    },
  }

  const onDateRangeClick = (e) => {
    if (e.target.classList.contains('date_range_click')) {
      setShowDateRange(!showDateRange)
      handleShowDateRange && handleShowDateRange(!showDateRange)
    }
  }

  const onClick = (e) => {
    if (type === 'date_range') {
      onDateRangeClick(e)
    }
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={
        rules[type] || {
          ...validation,
        }
      }
      defaultValue={defaultValue || null}
      render={({ onChange, value }) => (
        <Row style={styleRow} type={type}>
          <Wrap style={styleContainer} disabled={disabled}>
            {label && (
              <Label
                smallLabel={smallLabel}
                style={labelStyle}
                required={validation?.required}
              >
                <div>
                  {label}
                  <span>*</span>
                  {disclamer &&
                    <Popover
                      placement='rightTop'
                      title={''}
                      overlayStyle={{ width: 290, fontFamily: 'var(--medium)' }}
                      overlayInnerStyle={{ border: '1px solid #F2A122' }}
                      content={disclamerContent}
                      trigger='click'
                    >
                      <img style={{ marginBottom: 2, marginLeft: 3 }} src={questIcon} alt='question-icon' />
                    </Popover>
                  }
                </div>
                {addNew && (
                  <div
                    style={{
                      color: '#70A3EC',
                      fontSize: 12,
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={onAddNew}
                  >
                    Add New
                  </div>
                )}
              </Label>
            )}
            <InputWrap
              name={name}
              error={error}
              style={{
                ...style,
                overflow: type === 'date' ? 'visible' : 'hidden',
              }}
              transparent={transparent}
              placeholderColor={placeholderColor}
              bigText={bigText}
              className={type === 'date_range' && 'date_range_click'}
              onClick={(e) => onClick(e)}
              multiSelectItemColor={multiSelectItemColor}
            >
              {inputTypes({ ...props, allowHandleChange, onChange, value, reset, isSearchOpened, showDateRange, handleChange, showCross, onKeyDown, handleBlur, onFocus, listHeight, disableFutureDates })[type]}
            </InputWrap>
            {error && <ErrorText style={styleError}>{error}</ErrorText>}
          </Wrap>
          {(error || success) && (
            <ValidationIcon
              src={success ? checkGreenIcon : crossOrangeIcon}
              smallLabel={smallLabel}
              alt={success ? 'check-icon' : 'cross-icon'}
              relativeIcon={relativeIcon}
              label={label}
            />
          )}
        </Row>
      )}
    />
  )
}

export default Input
