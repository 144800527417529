import React from 'react'
import { Bottom, Top, Wrap } from './style'

const Total = ({ total, active }) => {
  return (
    <Wrap>
      <Top>
        Resource: ${total?.toFixed(2)} <br />
        <span>
          Annual Subscription, Billed{' '}
          {active === 'month' ? 'Monthly' : 'Annually'}
        </span>
      </Top>
      <Bottom>
        Need help selecting your plan?
        <br />
        1-888-684-1391 or contact@pbmsource.com
      </Bottom>
    </Wrap>
  )
}

export default Total
