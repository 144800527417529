import { Spin } from 'antd'
import { observer } from 'mobx-react'
import ButtonOrange from '../../../../../components/common/ButtonOrange'
import { useStore } from '../../../../../mobx-store/context'
import { Row, Title } from '../../../components/common/style'
import { Text } from '../style'
import PastDueAlerts from '../pastDueAlerts'
import { checkRole } from '../../../../../helpers/helpers'
import Table from '../table'

const ReportSourceTab = observer(() => {
  const store = useStore()
  const company = store.company[0]
  const members = store.members.filter((item) => item.have_access_to_report_source)

  if (!company) return <Spin />

  return (
    <>
      {/* <Row Row style={{ marginTop: '1em' }}>
        <Title>ReportSource Plan</Title>
      </Row>
      {company?.under_contract ?
        <Text style={{ marginTop: '10px' }}>
          Custom Price:{' '}
          <span style={{ fontFamily: 'var(--medium)' }}>
            ${company?.report_source_custom_price}/yr
          </span>
        </Text>
        : <Text style={{ marginTop: '10px' }}>
          Annual Subscription:{' '}
          <span style={{ fontFamily: 'var(--medium)' }}>
            $4,500/yr
          </span>
        </Text>
      } */}

      <Text>{members?.length} Active Users</Text>

      {checkRole([store.user?.[0]?.member_type], ['admin']) &&
        <PastDueAlerts
          checked={company?.report_past_due_items_notifications_frequency}
          isReportSource={true}
        />
      }

      {/* <ButtonOrange
        text={'Export Report Source'}
        link={'/product-settings/reportSource'}
      /> */}

      {store.members.length > 0 ? (
        <Table data={members} isProductSetting />
      ) : (
        <Spin />
      )}
    </>
  )
})

export default ReportSourceTab

