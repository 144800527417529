import React, { useState } from 'react';
import { Wrap, Title, ShowBtn } from './style';

function InterLinks({ data, store }) {
  const MAX_ITEMS = 5;

  const renderInterlinkItems = (items, title, showAll, toggleShowAll, noAccessUrl, accessCheck) => (
    <>
      <Title>{title}</Title>
      {items.slice(0, showAll ? items.length : MAX_ITEMS).map((link, index) => (
        <a key={index} href={accessCheck ? link.url : noAccessUrl} target="_blank" rel="noopener noreferrer">
          {link.report_due_date}&nbsp;&nbsp;|&nbsp;&nbsp;{link.name}&nbsp;&nbsp;|&nbsp;&nbsp;{link.jurisdiction}&nbsp;&nbsp;|&nbsp;&nbsp;{link.topic || `Type: ${link.report_type}`}
        </a>
      ))}
      {items.length > MAX_ITEMS && (
        <ShowBtn onClick={toggleShowAll}>
          {showAll ? 'Show Less' : 'Show More'}
        </ShowBtn>
      )}
    </>
  );

  const [showAllReports, setShowAllReports] = useState(false);
  const [showAllCompliance, setShowAllCompliance] = useState(false);

  const hasReports = !!data?.report_interlinking_items?.length;
  const hasCompliance = !!data?.compliance_interlinking_items?.length;

  return (
    <>
      {(hasReports || hasCompliance) &&
        <Wrap>
          {hasReports &&
            renderInterlinkItems(
              data.report_interlinking_items,
              'View Related ReportSource Items:',
              showAllReports,
              () => setShowAllReports(!showAllReports),
              '/no-report-source-subscription/',
              store.company[0].have_access_to_report_source && store.user?.[0]?.have_access_to_report_source
            )
          }

          {hasCompliance &&
            renderInterlinkItems(
              data.compliance_interlinking_items,
              'View Related ComplianceSource Items:',
              showAllCompliance,
              () => setShowAllCompliance(!showAllCompliance),
              '/no-compliance-source-subscription/',
              store.company[0].have_access_to_tracking && store.user?.[0]?.have_access_to_tracking
            )
          }
        </Wrap>}
    </>
  );
}

export default InterLinks;