import React, { useEffect, useState } from 'react'
import notAvailable from '../../assets/images/notAvailable.png'
import reportSourceImage from '../../assets/images/rsImage.svg'
import resourceResourceImage from '../../assets/images/resource-benefits.svg'
import proposedSourceImage from '../../assets/images/proposed-benefits.svg'
import licenseSourceImage from '../../assets/images/license_benefits.svg'
import { Wrap, Title, Text, AdvantagesWrap, AdvantagesTitle, AdvantagesText, BenefitsWrap } from './styles'
import Item from '../Home/ReportSource/Benefits/Item'
import { useStore } from '../../mobx-store/context'
import FullScreenLoader from '../../components/common/FullScreenLoader'
import Benefits from '../Home/Compliance/Benefits'

const NoSubscription = ({ serviceName }) => {
  const [loading, setLoading] = useState(true)
  const [userRole, setUserRole] = useState('')
  const store = useStore()

  const licenseSourceBenefits = [
    {
      key: 0,
      title: 'Provides definitions, lines of business, and penalties for comprehensive review to determine license applicability',
    },
    {
      key: 1,
      title: 'Identifies links for current forms, instructions, and guidance',
    },
    {
      key: 2,
      title: 'Lists all items required for license applications and renewals with ability to assign tasks for each item',
    },
    {
      key: 3,
      title: 'Notifies users of new licenses and when license requirements have changed',
    },
    {
      key: 4,
      title: 'Maintains record of all relevant documents and information for each license held',
    },
    {
      key: 5,
      title: 'Tracks all renewal and reporting deadlines in one place',
    },
  ]

  function getUserRole(serviceName) {
    let userRole;
    switch (serviceName) {
      case 'ReportSource':
        userRole = store.user[0].report_source_role;
        break;
      case 'LicenseSource':
        userRole = store.user[0].license_source_role;
        break;
      case 'ReSource':
        userRole = store.user[0].resource_role;
        break;
      case 'ProposedSource':
        userRole = 'admin';
        break;
      default:
        userRole = store.user[0].member_type;
    }
    setUserRole(userRole);
  }

  let serviceImage;
  switch (serviceName) {
    case 'ReportSource':
      serviceImage = reportSourceImage;
      break;
    case 'LicenseSource':
      serviceImage = licenseSourceImage;
      break;
    case 'ReSource':
      serviceImage = resourceResourceImage;
      break;
    case 'ProposedSource':
      serviceImage = proposedSourceImage;
      break;
    default:
      serviceImage = notAvailable;
  }

  useEffect(() => {
    store.getUser(() => store.getCompany(() => {
      getUserRole(serviceImage)
      setLoading(false)
    }))
  }, [])

  if (loading) return <FullScreenLoader />

  return (
    <Wrap serviceName={serviceName}>
      <Title serviceName={serviceName}>
        Sign up for <strong>{serviceName}</strong>
      </Title>
      <img src={serviceImage} alt="not-available" />
      {(userRole !== 'admin')
        ? <Text>
          Your current account doesn’t have a subscription to {serviceName}, ask your account admin to add it.
          OR if you have questions or need a demo, please <a href='mailto:contact@pbmsource.com'>contact us</a> .
        </Text>
        : <Text>
          Your current account doesn’t have a subscription to {serviceName}.
          {/* <a
            href={
              store.user[0].member_type === 'admin' ? (
                serviceName === 'ReportSource'
                  ? '/addReportMember/'
                  : serviceName === 'ProposedSource'
                    ? '/addProposedMember/'
                    : serviceName === 'ReSource'
                      ? '/resource/options'
                      : '/topicsAndJur/')
                : '/edit-profile'
            }
          >
            Sign up here
          </a>  */}
          If you have questions or need a demo, please <a href='mailto:contact@pbmsource.com'>contact us</a> .
        </Text>
      }
      {serviceName === 'LicenseSource' &&
        <>
          <AdvantagesWrap>
            <AdvantagesTitle>
              Identify & manage licenses required for PBMs with LicenseSource
            </AdvantagesTitle>
            <AdvantagesText>
              LicenseSource simplifies license assessment and management with detailed statutory and regulatory information.
              It helps determine the applicability of licenses <span>required for PBMs across all 50 states</span>,
              streamlines application and renewal processes, and organizes all relevant documentation and deadlines in one centralized tool.
            </AdvantagesText>
          </AdvantagesWrap>
          <BenefitsWrap>
            {licenseSourceBenefits.map((item) => (
              <Item key={item.key} title={item.title} style={{marginTop: 20}}/>
            ))}
          </BenefitsWrap>
        </>
      }
    </Wrap >
  )
}

export default NoSubscription