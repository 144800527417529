import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Wrap = styled(Link)`
  font-family: var(--regular);
  font-size: 1em;
  color: var(--orange);
  display: flex;
  align-items: center;
  span {
    &:first-child {
      text-decoration: underline;
      margin-right: 1em;
    }
    &:last-child {
      font-size: var(--light);
      color: #bbb;
      text-decoration: none;
    }
  }
`
