import React, { useState, useMemo, useEffect } from 'react'
import { Table as AntTable, Checkbox } from 'antd'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useStore } from '../../../mobx-store/context'
import Title from '../components/Title'
import { None, ViewButton } from '../Resource/RightSide/style'
import ModalTerms from '../TopicsJursList/Right/Item/Modal'
import { TableWrap, SubTitle, RightWrap } from './style'
import TableOptionsModal from './OptionsModal'
import { modifyDescription } from '../../../helpers/helpers'


const Right = observer(({ currentItem, numberOfColumns }) => {
  const params = useParams()
  const history = useHistory()
  const store = useStore()
  const location = useLocation()

  const [guideName, setGuideName] = useState(null)
  const [visible, setVisible] = useState(false)
  const [terms, setTerms] = useState(null)
  const [currentTerm, setCurrentTerm] = useState('')
  // const guideName = store?.resources?.filter(item => item.id === +params.topicId)[0].name

  const [notSpecifiedHidden, setNotSpecifiedHidden] = useState(false)

  const handleChange = () => {
    setNotSpecifiedHidden(!notSpecifiedHidden)
    store.setSingleValue('notSpecifiedHidden', !notSpecifiedHidden)
  }

  const isGuides = params.type === 'guides'

  const getJurisdictionCode = (id) => {
    return store.resourceFilters?.jurisdictions.find(
      (item) => item[0] === id
    )[1]
  }

  const getArrItems = (ids, type) => {
    return ids
      ?.map(
        (item) =>
          store.resourceFilters[type]?.find((newItem) => newItem[0] === item)[1]
      )
      .join(', ')
  }

  const row = isGuides
    ? store.resourcesCurrentTopic.rows.find(
      (item) => item.jurisdiction === +params.topicId
    )
    : store.resourcesCurrent?.rows[0]

  const onClick = (record, summary) => {
    if (summary && summary !== 'Not Specified') {
      store.getJurisdiction(record.jurisdiction)
      store.setSingleValue('currentJurId', record.jurisdiction)
      store.setSingleValue('rowId', record.id)
      history.push(
        `/resource/details/${currentItem.code}/${isGuides ? record.id : params.topicId
        }/${params.selectId}/${params.type}/${isGuides ? record.jurisdiction : record.id
        }`
      )
      store.setResourcePrevLink([...store.resourcePrevLink, location.pathname])
    }
  }

  const filterRow = (row) => {
    if (row.row_data?.custom_fields_data?.find(item => (item.code === currentItem.code))?.summary !== "Not Specified")
      return true

    return false
  }

  const excludeNotSpecifiedRows = (arr) => {
    return arr.filter((item) => filterRow(item))
  }

  const columns = [
    {
      title: 'Jurisdictions',
      dataIndex: 'jurisdiction',
      key: 'jurisdiction',
      align: 'left',
      width: 60,
      hide: false,
      render: (id) => getJurisdictionCode(id),
      sorter: {
        compare: (a, b) =>
          getJurisdictionCode(a.jurisdiction).localeCompare(
            getJurisdictionCode(b.jurisdiction)
          ),
      },
      defaultSortOrder: store.sort_order_jurisdiction
    },
    {
      title: 'Differentiator',
      dataIndex: 'description',
      key: 'description',
      align: 'left',
      width: 250,
      hide: !store?.crossJursTebleSettings?.differentiator_column_is_visible,
      // sorter: {
      //   compare: (a, b) => a.description.localeCompare(b.description),
      // },
    },
    {
      title: 'Summary',
      dataIndex: 'row_data',
      key: 'row_data',
      align: 'left',
      width: 250,
      hide: !store?.crossJursTebleSettings?.summary_column_is_visible,
      render: (_, record) => {
        const summary = record?.row_data?.custom_fields_data?.find(
          (item) => item?.code === currentItem?.code
        )?.summary
        return summary && summary !== 'Not Specified' ? (
          <ViewButton onClick={() => onClick(record, summary)}>
            {summary}
          </ViewButton>
        ) : (
          <None>Not Specified</None>
        )
      },
      sorter: {
        compare: (a, b) => {
          const summaryA = a?.row_data?.custom_fields_data?.find(
            (item) => item?.code === currentItem?.code
          )?.summary

          const summaryB = b?.row_data?.custom_fields_data?.find(
            (item) => item?.code === currentItem?.code
          )?.summary

          return summaryA.localeCompare(summaryB)
        },
      },
      defaultSortOrder: store.sort_order_row_data
    },
    {
      title: 'Details',
      dataIndex: 'detail',
      key: 'detail',
      align: 'left',
      width: 400,
      hide: !store?.crossJursTebleSettings?.details_column_is_visible,
      render: (_, record) => {
        const detail = record?.row_data?.custom_fields_data?.find(
          (item) => item?.code === currentItem?.code
        )?.detail
        return detail && detail !== 'Not Specified' ? (
          <div>{detail}</div>
        ) : (
          <None>Not Specified</None>
        )
      }
    },
    {
      title: 'Lines of Business',
      dataIndex: 'line_of_business',
      key: 'line_of_business',
      align: 'left',
      width: 200,
      hide: !store?.crossJursTebleSettings?.lines_of_business_column_is_visible,
      render: (_, record) => <div dangerouslySetInnerHTML={{ __html: modifyDescription(store.lineOfBusinesses, record?.line_of_business) }} /> || (
        <None>Not Specified</None>
      )
    },
    {
      title: 'Citations & Definitions',
      dataIndex: 'citations',
      key: 'citations',
      align: 'left',
      width: 100,
      hide: !store?.crossJursTebleSettings?.citations_definitions_column_is_visible,
      render: (_, record) =>
        <>
          <ViewButton onClick={() => {
            setCurrentTerm(record.citations)
            setTerms('citations')
          }}> Citations</ViewButton>
          <ViewButton onClick={() => {
            setCurrentTerm(record.defined_terms)
            setTerms('defined_terms')
          }}>Definitions</ViewButton>
        </>
    },
  ]

  const resetSortOrders = () => {
    store.setSingleValue(`sort_order_jurisdiction`, null);
    store.setSingleValue(`sort_order_row_data`, null);
  }

  const joinNonSpecifiedJurs = (arr) => {
    const groups = {}
    arr.forEach(element => {
      if (!groups[element.jurisdiction]) groups[element.jurisdiction] = [];
      groups[element.jurisdiction].push(element)
    })

    Object.entries(groups).forEach(([jurisdiction, entities]) => {
      const entityWithSummary = entities.filter(entity =>
        entity.row_data?.custom_fields_data?.find(item => (item.code === currentItem.code))?.summary !== "Not Specified"
      )
      if (typeof entityWithSummary !== 'undefined' && entityWithSummary.length === 0) {
        groups[jurisdiction] = [{ ...entities[0], description: '' }]
      }
    });
    return Object.values(groups).flat()
  }

  const dataSource = () => {

    const filteredTopics = toJS(store.resourceFilteredTopics)
      .sort((x, y) => x.jurisdiction === 1 ? -1 : y.jurisdiction === 1 ? 1 : 0)
      .filter(topic => {
        if (!store.resourceLob || store.resourceLob.length === 0) {
          return true;
        }
        return topic.line_of_business.some(lob => store.resourceLob.includes(lob));
      });

    return notSpecifiedHidden
      ? excludeNotSpecifiedRows(filteredTopics)
      : joinNonSpecifiedJurs(filteredTopics);
  }

  useEffect(() => {
    setGuideName(store?.resources?.filter(item => item.id === +params.topicId)[0]?.name)
  }, [])

  useEffect(() => {
    store?.notSpecifiedHidden && setNotSpecifiedHidden(store.notSpecifiedHidden)
  }, [])
  // useEffect(() => {
  //   setNotSpecifiedHidden(false)
  // }, [currentItem.code])

  const visibleColumns = columns.filter((column) => !column.hide);

  return (
    <RightWrap numberOfColumns={numberOfColumns} >
      {terms && (
        <ModalTerms
          onClose={() => setTerms(null)}
          text={currentTerm.replace(/;/g, '\n')}
          title={terms === 'defined_terms' ? 'Defined Terms' : 'Citations'}
        />
      )}
      {visible && <TableOptionsModal onClose={() => { setVisible(false) }} />}
      <div style={{ position: 'sticky', top: 0, zIndex: 98, background: '#FFF' }}>
        <Title noJurisdiction title={`${guideName ? guideName : store.resourcesCurrentTopic.name}  - Across Jurisdictions`} />
        <SubTitle>{currentItem?.name}</SubTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ fontFamily: 'var(--regular)', color: 'var(--text)' }}>
            {' '}
            Select a summary to read more details
          </div>

          <div style={{ fontSize: 12, fontFamily: 'var(--regular)', color: 'var(--text)', display: 'flex' }}>
            <ViewButton onClick={() => setVisible(true)} >
              Column Options
            </ViewButton>
            <Checkbox
              onChange={(e) => { handleChange() }}
              style={{ marginRight: '.5em', marginLeft: '.5em' }}
              checked={notSpecifiedHidden}
            />
            Hide Not Specified{' '}
          </div>
        </div>
      </div>
      <TableWrap style={{ marginTop: 15 }}>
        <AntTable
          columns={visibleColumns}
          dataSource={dataSource()}
          pagination={false}
          onChange={(pagination, filters, sorter, { currentDataSource }) => {
            resetSortOrders();
            store.setSingleValue(`sort_order_${sorter.field}`, sorter.order);
          }}
        />
      </TableWrap>
    </RightWrap>
  )
})

export default Right
