import React from 'react'
import { Wrap } from './pagination.style'
import { Pagination } from 'antd'

const CustomPagination = ({
  total,
  pageSize,
  onChange,
  current,
  arrows = true,
  style,
  showSizeChanger = false,
}) => {
  return (
    <Wrap arrows={arrows}>
      <Pagination
        style={style}
        total={total}
        pageSize={pageSize}
        current={current}
        onChange={onChange}
        hideOnSinglePage={true}
        showSizeChanger={showSizeChanger}
      />
    </Wrap>
  )
}

export default CustomPagination
