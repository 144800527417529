import React from 'react'
import { Ads, Block, BlueBlock, Grid, Wrap } from './dashboard.style'
import eyeImg from '../../assets/images/eye-blue.svg'
import Search from './search'
import Resources from './resources'
import Table from '../Tracking/Table/table'

const Dashboard = () => {
  return (
    <Wrap>
      <Ads/>
      <Grid>
        <div>
          <BlueBlock>
            <img src={eyeImg} alt="eye-icon"/>
            Viewing: In-House Counsel Template
          </BlueBlock>
          <Search/>
          <Resources/>
        </div>
        <div>
          <Block>
            <Table style={{ border: 0 }}/>
          </Block>
        </div>
      </Grid>
    </Wrap>
  )
}

export default Dashboard
