import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'
import React from 'react'
import close from '../../../../assets/images/close-grey.svg'
import Button from '../../../../components/common/Button/button'
import Input from '../../../../components/common/Input/'
import { Label } from '../../../../components/common/Input/input.style'
import { Close, Overlay, Row, UnderLabel, Wrap } from './style'

const Modal = observer(
  ({
    onSave,
    store,
    control,
    onClose,
    selectedJurisdictions,
    error,
    primaryJurisdictions,
    notAllowedToDeleteJurisdictions,
    isPrimary,
    currentItem,
    paidJurs,
  }) => {
    const isResourceRegistrationPassed =
      store.company?.[0].is_resource_registration_passed

    const filtered = !isPrimary
      ? store.jurs
      : store.jurs
        ? store.jurs.filter(
          (item) =>
            !primaryJurisdictions?.find((findItem) => item.id === findItem)
        )
        : null

    const options = isResourceRegistrationPassed
      ? filtered.map((item) => {
        return {
          ...item,
          disabled: isPrimary
            ? paidJurs?.find((jurItem) => +jurItem === +item.id) || false
            : (currentItem?.status === 'paid' &&
              currentItem?.jurisdictions.includes(item.id)) ||
            false,
        }
      })
      : filtered

    return (
      <Overlay>
        <Wrap>
          <Close>
            <img src={close} alt={'close-icon'} onClick={onClose} />
          </Close>
          <Label required={isPrimary}>
            {isPrimary ? (
              <>
                Select Primary Jurisdiction (s) <span>*</span>
              </>
            ) : (
              'Select Jurisdiction (s) for the following guide'
            )}
          </Label>
          <UnderLabel>Claim Filing Timelines</UnderLabel>
          <Input
            control={control}
            name={'jurisdictions'}
            type={'multiSelect'}
            options={options}
            defaultValue={
              isPrimary
                ? selectedJurisdictions?.filter(
                  (item) => !paidJurs.includes(item)
                ) || []
                : selectedJurisdictions
            }
            validation={{ required: isPrimary }}
            error={error?.message}
            allowClear={false}
          />
          <Row>
            <UnderLabel onClick={onClose}>Cancel</UnderLabel>
            <Button
              text={
                selectedJurisdictions?.length
                  ? 'Modify Jurisdictions'
                  : 'Confirm Jurisdictions'
              }
              onClick={onSave}
            />
          </Row>
        </Wrap>
      </Overlay>
    )
  }
)

Modal.propTypes = {
  onSave: PropTypes.func,
  selectedJurisdictions: PropTypes.array,
  onClose: PropTypes.func,
  error: PropTypes.object,
}

export default Modal
