import { Wrap, Title, Button } from "./style";
import { useHistory } from "react-router-dom";

const Header = ({ searchWord = 'search' }) => {

  const history = useHistory();
  const onGoBack = () => {
    history.goBack();
  }
  return (
    <Wrap>
      <Button onClick={onGoBack}>Go Back</Button>
      <Title>ReSource</Title>
    </Wrap>
  );
};

export default Header;


