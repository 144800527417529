import React from 'react'
import { Item, Wrap } from './style'
import { Title } from '../../../components/common/style'
import check from '../../../../../assets/images/check-green.svg'

const IncludingBlock = ({ min, code }) => {
  const topics = {
    topic_tract: 'ALL',
    juris_tract: '1',
    total_tract: 'ALL',
    select_tract: (
      <div style={{ textAlign: 'center' }}>
        Customer <br/> Selected
      </div>
    ),
  }

  const jurisdictions = {
    topic_tract: '1',
    juris_tract: 'ALL',
    total_tract: 'ALL',
    select_tract: (
      <div style={{ textAlign: 'center' }}>
        Customer <br/> Selected
      </div>
    ),
  }

  const items = [
    {
      key: 0,
      title: 'Compliance Tracking',
      checked: true,
    },
    {
      key: 1,
      title: 'Team Assignment',
      checked: true,
    },
    {
      key: 2,
      title: 'Record Keeping',
      checked: true,
    },
    {
      key: 3,
      title: 'Search',
      checked: true,
    },
    {
      key: 4,
      title: 'Jurisdictions',
      value: jurisdictions[code],
    },
    {
      key: 5,
      title: 'Topics',
      value: topics[code],
    },
  ]

  return (
    <Wrap>
      {items.map((item) => (
        <Item key={item.key}>

          <Title
            style={{
              fontSize: '.875em',
              padding: '0 1em',
              marginBottom: 5,
              whiteSpace: 'nowrap',
            }}
          >
            {item.title}
          </Title>
          <span>
            {item.checked ? <img src={check} alt="icon"/> : item.value}
          </span>
        </Item>
      ))}
    </Wrap>
  )
}

export default IncludingBlock
