import React from 'react'
import { ContainerGlobal } from '../styles'
import Slide from './Slide'

const Slider = () => {
  return (
    <ContainerGlobal style={{ marginTop: 50, minHeight: 660 }}>
      <Slide />
    </ContainerGlobal>
  )
}

export default Slider
