import React from 'react'
import { Desc, Image, Title, Wrap } from './style'
import manImg from '../../../../assets/images/man.svg'
import { Link } from 'react-router-dom'

const Empty = ({ isReportSource = false, isLicenseSource = false }) => {

  const linkName = isReportSource ? '/profile-email/reportSource' : '/profile-email'
  return (
    <Wrap>
      <Image src={manImg} />
      <div>{isLicenseSource
        ? <Title>Looks like we can’t find entries <br /> that match this search.</Title>
        : <Title>We’ll Let You Know When <br /> We See Something!</Title>
      }
        {isLicenseSource
          ? <Desc>
            Try changing your search query or changing the search options.
          </Desc>
          : <Desc>
            There are no new {isReportSource ? 'report' : 'compliance'} items to track at the moment! Check
            your <Link to={linkName}>email preferences</Link> to make sure
            you’ll recieve an update when
            there is!
          </Desc>
        }
      </div>
    </Wrap>
  )
}

export default Empty
