import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useStore } from '../../../../mobx-store/context'
import { useHistory, useParams } from 'react-router-dom'
import { PageTitle } from '../../components/common/style'
import PageWrap from '../../components/Container'
import TabsNav from '../../components/TabsNav/tabsNav'
import ResourceTab from './Tabs/ResourceTab/resourceTab'
import TrackingTab from './Tabs/Tracking/tracking'
import ReportSourceTab from './Tabs/ReportSourceTab'
import ProposedSourceTab from './Tabs/ProposedSourceTab/proposedSourceTab'
import LicenseSourceTab from './Tabs/LicenseSourceTab/licenseSourceTab'
import SettingsActivity from '../AutoAssignments/Settings Activity'
import { api } from '../../../../api'




const Restrictions = observer(() => {
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const [active, setActive] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showActivity, setShowActivity] = useState(false)
  const [activityData, setActivityData] = useState([])
  const user = store.user?.[0]
  const tabs = {
    tracking: {
      button: 'ComplianceSource',
      component: <TrackingTab setShowActivity={setShowActivity} />,
      link: '/restrictions/tracking',
    },
    resource: {
      button: 'ReSource',
      component: <ResourceTab setShowActivity={setShowActivity} />,
      link: '/restrictions/resource',
    },
    reportSource: {
      button: 'ReportSource',
      component: <ReportSourceTab setShowActivity={setShowActivity} />,
      link: '/restrictions/reportSource',
    },
    proposedSource: {
      button: 'ProposedSource',
      component: <ProposedSourceTab setShowActivity={setShowActivity} />,
      link: '/restrictions/proposedSource',
    },
    licenseSource: {
      button: 'LicenseSource',
      component: <LicenseSourceTab setShowActivity={setShowActivity} />,
      link: '/restrictions/licenseSource',
    }
  }

  const getResources = () => {
    api('/companies/restrict/', {}, 'GET').then((data) => {
      if (!data.errors) {
        store.setSingleValue('restrictedItems', data)
      }
    }).then(() => {
      api('/companies/company_options/', {}, 'GET').then((data) => {
        if (!data.errors) {
          store.setSingleValue('company_options', data)
        }
      }).then(() => setLoading(false))
    })
  }

  useEffect(() => {
    setActive(
      (user?.have_access_to_tracking
        ? 'tracking'
        : user?.have_access_to_resource
          ? 'resource'
          : user?.have_access_to_report_source
            ? 'reportSource'
            : user?.have_access_to_license_source
              ? 'licenseSource'
              : 'proposedSource'
      )
    )
  }, [])

  useEffect(() => {
    params.type && setActive(params.type)
  }, [params.type])

  const getActtivity = () => {
    api(`/companies/restriction_activity/`, {}, 'GET').then((data) => {
      setActivityData(data)
    })
  }

  useEffect(() => {
    getResources()
  }, []);

  useEffect(() => {
    getActtivity()
  }, [showActivity])


  return (
    <PageWrap modalOpen={store.planModalOpen}>
      <PageTitle>{showActivity ? 'Restrictions Activity' : 'Restrictions'}</PageTitle>
      {showActivity ?
        <SettingsActivity data={activityData} setShowActivity={setShowActivity} />
        : <>
          <TabsNav
            tabs={tabs}
            active={active}
            onRedirectTo={(page) => history.push(`/restrictions/${page}`)}
          />
          {!loading && tabs[active].component}
        </>
      }

    </PageWrap>
  )
})

export default Restrictions
