import { SubTitle, ListItem } from "./styles";

const ChangingPasswordReminder = () => {

  const list = [
    {
      text: 'contain at least 8 characters (12+ recommended)',
      condition: true,
    },
    {
      text: 'contain at least one uppercase letter',
      condition: true,
    },
    {
      text: 'contain at least one lowercase letter',
      condition: true,
    },
    {
      text: 'contain at least one number',
      condition: true,
    },
    {
      text: 'contain at least one special character (!”#$...)',
      condition: true,
    },
    {
      text: ' contain first name, last name, email address mailbox or domain, company name or commonly used passwords',
      condition: false,
    },
    {
      text: 'contain a portion of your previous password',
      condition: false,
    },
  ]
  return (
    <div>
      <SubTitle>Please add all necessary characters to create safe password:</SubTitle>
      <div style={{ marginTop: 12 }}>
        {list.map((item, index) => {
          return (
            <div key={index}>
              <ListItem condition={item.condition}>
                {item.condition ? (<span>MUST</span>) : (<span>MAY NOT</span>)} {item.text}
              </ListItem>
            </div>
          )
        })}
      </div>
    </div>
  )
};

export default ChangingPasswordReminder;