import styled from "styled-components";

export const Overlay = styled("div")`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 3;
`;

export const Wrap = styled("div")`
  width: 850px;
  padding: 75px;
  background-color: #fff;
  border-radius: 5px;
`;

export const Close = styled("div")`
  display: flex;
  justify-content: flex-end;
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

export const UnderLabel = styled("div")`
  font-family: var(--medium);
  font-size: 14px;
  color: var(--text);
  margin-top: 15px;
  margin-bottom: 30px;
`;

export const Row = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  ${UnderLabel} {
    text-decoration: underline;
    font-size: 16px;
    font-family: var(--regular);
    cursor: pointer;
  }
`;
