import styled from 'styled-components'

export const Wrap = styled("div")`
  background: rgba(34, 81, 148, 0.05);
  padding: 20px 30px;
  display: grid;
  grid-template-columns: 400px 400px;
`;

export const Row = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
`;

export const Left = styled("div")`
  padding-right: 35px;
  border-right: 1px solid rgba(196, 202, 204, 0.6);
`;

export const Right = styled("div")`
  padding-left: 35px;
`;
