import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { useStore } from '../../../../../mobx-store/context'
import ResourceModal from '../../../components/ResourceAccessModal/resourceModal'
import React from 'react'
import EmailSettingsTable from './Table'
import { toJS } from 'mobx'
import { Title, SubTitle } from './Table/style'
import {
  ComplianceLiteSettings,
  ComplianceSettings,
  ComplianceLiteSiteSettings,
  ComplianceSourceSiteSettings,
  MemberComplianceTaskSettings,
  ComplianceTaskSettings,
  MemberSiteComplianceTaskSettings,
  ComplianceSiteTaskSettings,
} from './static'


const TrackingTab = observer(({ setState, state, site }) => {
  const store = useStore()
  const history = useHistory()

  const initialValues = site
    ? store?.user[0]?.compliance_site_notifications
    : store?.user[0]?.compliance_source_notifications

  const isMember = store?.user[0]?.member_type === 'member'

  const liteMode = ((store.company[0].compliance_source_mode === 'compliance_source_lite')
    || (store.company[0].compliance_source_mode === 'compliance_source_ultra_lite'))



  const initialSettings = !site
    ? liteMode
      ? ComplianceLiteSettings
      : ComplianceSettings
    : liteMode
      ? ComplianceLiteSiteSettings
      : ComplianceSourceSiteSettings;

  const initialTaskSettings = !site
    ? isMember
      ? MemberComplianceTaskSettings
      : ComplianceTaskSettings
    : isMember
      ? MemberSiteComplianceTaskSettings
      : ComplianceSiteTaskSettings;

  const isTaskTable = true && !liteMode;

  const titles = site? ['Compliance Owner Alerts', 'Task  Owner Alerts'] : ['Compliance Owner Emails', 'Task  Owner Emails']

  if (!store.user?.[0]?.have_access_to_tracking || !store.company?.[0]?.have_access_to_tracking)
    return (
      <ResourceModal
        type='tracking'
        to='/edit-profile'
        onClose={() => history.push('/edit-profile')}
      />
    )

  return (
    <>
      <Title>{liteMode ? 'ComplianceSource Lite' : 'ComplianceSource'}</Title>
      <SubTitle>Select notifications times for the following actions.</SubTitle>
      <EmailSettingsTable
        site={site}
        titles={titles}
        tasksSetings={true}
        initialSettings={initialSettings}
        initialTaskSettings={initialTaskSettings}
        initialValues={toJS(initialValues)}
        setState={setState}
        state={state}
        isTaskTable={isTaskTable}
        mainSettings={!isMember}
        userId={store.user[0].id}     
      />
    </>
  )
})

export default TrackingTab
