import styled from 'styled-components'

export const Wrap = styled('div')`
  font-family: var(--regular);
  margin-top: 15px;
  margin-bottom: 6em;
`

export const TopLine = styled('div')`
  display: flex;
  font-size: 14px;
`

export const Name = styled('div')`
  font-family: var(--bold);
  margin-right: 5px;
`

export const Text = styled('div')`
  font-family: var(--regular);
  font-size: 16px;

  p {
    margin: 0 !important;
  }

  strong {
    font-family: var(--bold);
  }
`
