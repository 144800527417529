import styled from 'styled-components'


export const Title = styled('div')`
  display: flex;
  align-items: center;

  .ant-popover-inner-content {
    border: 1px solid var(--blue);
    max-height: 400px;
    overflow-y: auto;
  }
`