
import styled from 'styled-components'
import { Table } from 'antd'
export const TableHeaderTitle = styled('div')`
  font-size: 16px;
  font-family: var(--semiBold);
  color: var(--text);
`
export const Title = styled(TableHeaderTitle )`
  padding-left: 15px;
  font-size: 20px;
  font-family: var(--medium);
`
export const SubTitle = styled(TableHeaderTitle )`
  padding-left: 15px;
  font-family: var(--medium);
`

export const TableStyled = styled(Table)`
  .ant-table {
    font-size: 16px;
  }
  .ant-table-thead {
    tr {
      th {
        background-color: #fff !important;
      }
    }
  }
  .ant-table-cell {
    font-size: 15px;
    font-family: var(--regular);
    color: var(--text);
    cursor: pointer;
    padding: 5px 13px;
  }
  .active-row {
    td {
      color: var(--green);
      background-color: #f5f9ec;
      font-family: var(--open-sans-bold);
      &:first-child {
        border-left: 4px solid var(--green);
      }
    }
  }
  .ant-pagination-item {
    border-color: #dadada !important;
    margin: 0 0.1em !important;
    border-radius: 4px;
    font-family: var(--regular);
    font-size: 0.714em;
    height: 15px !important;
    width: 16px !important;
    min-width: 16px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    &-active {
      a {
        font-family: var(--bold);
        color: var(--orange);
      }
    }
    a {
      height: 15px;
      line-height: 15px;
      &:hover {
        color: var(--orange);
      }
    }
  }
  .ant-table-row-expand-icon-cell {
    width: 0px !important;
    padding: 0px !important;
  }
  .ant-pagination.mini .ant-pagination-prev,
  .ant-pagination.mini .ant-pagination-next {
    height: 15px;
    line-height: 15px;
    button {
      color: rgba(0, 0, 0, 0.54);
    }
  }
`