import { Checkbox } from 'antd'
import { useGA4React } from 'ga-4-react'
import { observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { Link, useHistory } from 'react-router-dom'
import Button from '../../../components/common/Button/button'
import Logo from '../../../components/common/Logo/logo'
import Validation from '../../../components/common/Validation/validation'
import { useStore } from '../../../mobx-store/context'
import { Title } from '../authForm.style'
import { register } from '../Form/form'
import {
  BackButton,
  CheckRow,
  Container,
  Description,
  Wrap,
} from './agreement.style'

const AgreementPopup = observer(({ onClick, startedFromResource, startedFromReportSource, startedFromProposedSource,}) => {
  const ga4React = useGA4React()
  const history = useHistory()
  const store = useStore()
  const [clicked, setClicked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(['pbm', 'userId'])

  const isNotAgreed = !store.agreement && clicked

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const confirm = () => {
    setClicked(true)

    if (!isNotAgreed) {
      const { password, email } = store.registerData

      const passCheck = !password || password.length < 8
      const emailCheck = !email || !validateEmail(email)

      if (!passCheck && !emailCheck) {
        return register(
          store.registerData,
          setLoading,
          setCookie,
          store,
          history,
          removeCookie,
          ga4React,
          startedFromResource,
          startedFromReportSource,
          startedFromProposedSource,
        )
      } else {
        if (passCheck && emailCheck) {
          history.push({
            pathname: '/register',
            state: { password: true, email: true },
          })
        } else if (!password || password.length < 8) {
          history.push({ pathname: '/register', state: { password: true } })
        } else if (!email || !validateEmail(email)) {
          history.push({ pathname: '/register', state: { email: true } })
        }
      }
    }
  }

  return (
    <Container>
      <Wrap>
        <Logo large />
        <Title
          style={{
            borderBottom: '1px solid rgba(196, 202, 204, 0.6)',
            paddingBottom: '.7em',
            textAlign: 'center',
          }}
        >
          Acknowledgement of Service
        </Title>
        <Description>
          PBMSource is not a law firm, or a substitute for an attorney or law
          firm. Use of our products and services are governed by our{' '}
          <Link
            style={{ color: 'var(--orange)' }}
            to={{ pathname: '/terms-and-privacy', state: { fromPopup: true } }}
          >
            Terms of Use and Privacy Policy
          </Link>
          . This website is for informational purposes. Your use of this website
          does not constitute legal, medical, or any other type of advice, nor
          does it create an attorney/client relationship, or any other type of
          relationship. Any parties using this website must seek their own
          advice (legal, medical, or otherwise). This site contains links to
          other websites operated by third parties and are provided only for
          informational purposes. PBMSource is in no way responsible for these
          third party websites, including, without limitation, the content,
          operation, reliability, availability, or functionality of these
          websites. The inclusion of links to other websites does not imply any
          endorsement of the material on such websites. PBMSource encourages you
          to seek independent verification of any of the information provided.
          PBMSource expressly disclaims all responsibility for any loss, claim,
          liability, or any type of damage resulting from, or in any way related
          to, this website or any of the third party links.
        </Description>
        {isNotAgreed && (
          <Validation
            text={
              'Please acknowledge that you understand and agree with this statement of PBMSource’s service before you continue.'
            }
            style={{ fontSize: '1em' }}
            noLineThrough={true}
          />
        )}

        <CheckRow
          isNotAgreed={isNotAgreed}
          onClick={() => store.setAgreement()}
        >
          <Checkbox style={{ marginRight: '1em' }} checked={store.agreement} />I
          acknowledge that I have read and understand this statement
        </CheckRow>
        <Button
          text={'Confirm'}
          style={{ margin: '2em auto 0', minHeight: 37 }}
          onClick={confirm}
          loading={loading}
          disabled={loading || !store.agreement}
        />
        <BackButton onClick={onClick}>&lt;&lt; Return to Sign Up</BackButton>
      </Wrap>
    </Container>
  )
})

export default AgreementPopup
