import styled from 'styled-components'

export const Wrap = styled('div')`
  font-family: ${(p) => p.bold ? 'var(--bold)' : 'var(--regular)'};
  color: var(--orange);
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;

  &:hover {
    color: var(--orange);
  }
`
