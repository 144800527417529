import React, { useState } from "react";
import Modal from "../../../components/common/ModalNew";
import { Title, SubTitle, ListItem } from "./styles";
import { useForm } from "react-hook-form";
import Input from "../../../components/common/Input";
import { api } from "../../../api";
import { message } from "antd";
import Button from "../../../components/common/Button/button";

const ChangePasswordModal = ({ onClose, expiredPassword }) => {
  const { control, errors, getValues, clearErrors, handleSubmit } = useForm();
  const [isFormValid, setIsFormValid] = useState(false);



  function isPasswordValid(value) {

    const uppercaseRegex = /[A-Z]/;
    if (!uppercaseRegex.test(value)) {
      return false;
    }

    const lowercaseRegex = /[a-z]/;
    if (!lowercaseRegex.test(value)) {
      return false;
    }

    const numberRegex = /[0-9]/;
    if (!numberRegex.test(value)) {
      return false;
    }

    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/;
    if (!specialCharRegex.test(value)) {
      return false;
    }

    return true;
  }

  const validateForm = () => {
    const formValues = getValues();
    const isAllFieldsValid = (
      formValues.email &&
      formValues.first_name &&
      formValues.last_name
    );
    setIsFormValid(isAllFieldsValid);
  };

  const onSubmit = (data) => {
    api(`/user_settings/change_password/`, { password: data.password }, 'POST', false).then((data) => {
      if (!data.errors) {
        expiredPassword ?
          localStorage.setItem('required_to_change_temporary_password', 'false') :
          localStorage.setItem('is_time_to_change_password', 'false')
        onClose();
        message.success('Password changed successfully');
      }
    })
  }

  const list = [
    {
      text: 'contain at least 8 characters (12+ recommended)',
      condition: true,
    },
    {
      text: 'contain at least one uppercase letter',
      condition: true,
    },
    {
      text: 'contain at least one lowercase letter',
      condition: true,
    },
    {
      text: 'contain at least one number',
      condition: true,
    },
    {
      text: 'contain at least one special character (!”#$...)',
      condition: true,
    },
    {
      text: ' contain first name, last name, email address mailbox or domain, company name or commonly used passwords',
      condition: false,
    },
    {
      text: 'contain a portion of your previous password',
      condition: false,
    },
  ]



  return (
    <Modal
      showCloseBtn={false}
      throughOverlay={false}
    >
      <Title>{expiredPassword ? 'Time to set a new password' : 'Create your new password'}</Title>
      <SubTitle>{expiredPassword && 'We require new passwords every 6 months. '}Please add all necessary characters to create safe password:</SubTitle>
      <div style={{ marginTop: 12 }}>
        {list.map((item, index) => {
          return (
            <div key={index}>
              <ListItem condition={item.condition}>
                {item.condition ? (<span>MUST</span>) : (<span>MAY NOT</span>)} {item.text}
              </ListItem>
            </div>
          )
        })}
      </div>
      <form style={{ marginTop: 20 }} onSubmit={handleSubmit(onSubmit)}>
        <Input
          style={{ marginBottom: 20 }}
          name={'password'}
          type={'password'}
          label={'New Password'}
          control={control}
          error={errors && errors.password && 'Enter valid password'}
          handleChange={(value) => {
            if (
              value &&
              getValues('password_confirm') &&
              value === getValues('password_confirm')
            ) {
              clearErrors(['password_confirm'])
            }
          }}
          validation={{
            required: true,
            validate: (value) => isPasswordValid(value),
          }}
          showErrorText={false}
          success={isPasswordValid(getValues('password'))}
          smallLabel
          transparent
        />
        <Input
          name={'password_confirm'}
          type={'password'}
          label={'Confirm New Password'}
          control={control}
          error={
            (errors.password_confirm) && 'Password doesn\'t match'
          }
          validation={{
            validate: (value) => value === getValues('password'),
            required: true,
          }}
          showErrorText={false}
          success={
            getValues('password') &&
            getValues('password_confirm') &&
            !errors.password_confirm &&
            getValues('password') === getValues('password_confirm')
          }
          smallLabel
          transparent
          handleChange={() => validateForm()}
        />
        <Button
          style={{ marginTop: 20 }}
          text="Create Password"
          onClick={handleSubmit(onSubmit)}
        />
      </form>
    </Modal>
  )
};

export default ChangePasswordModal;