import React, { useEffect, useRef, useState } from 'react'
import slide_1 from '../../../../../assets/images/slide-1.png'
import slide_2 from '../../../../../assets/images/slide-2.png'
import slide_3 from '../../../../../assets/images/slide-3.png'
import { DescriptionGlobal, TitleGlobal } from '../../styles'
import { Button, Buttons, LeftSide, RightSide, Wrap } from './style'

const slides = {
  0: {
    title: 'Reduce Risks of Regulatory Fines and Lawsuits',
    src: slide_1,
    bg: 'var(--orange)',
    description:
      'Don’t wait to be fined by regulators, receive a corrective action plan, or be sued. Ensure ongoing compliance by tracking compliance activities at a glance. Easily pull evidence of compliance for regulators and clients.',
  },
  1: {
    title: 'PBMS, PSAOs, Pharmacies, UROs, Auditors, Consultants',
    src: slide_2,
    bg: 'var(--blue)',
    description:
      'This tool is for anyone responsible for any part of the administration of the pharmacy benefit. Select only those topics relevant to your business. Don’t be surprised by new laws and regulations impacting your business. ',
  },
  2: {
    title: 'Laws & Regulations Directly in Your Inbox',
    src: slide_3,
    bg: 'var(--green)',
    description:
      'Receive timely notifications directly in your inbox when a new law or regulation passes or sub-regulatory guidance is issued. Receive alerts when there is activity on the internal compliance assessment.',
  },
}

const buttons = [
  {
    key: 0,
    title: '1',
  },
  {
    key: 1,
    title: '2',
  },
  {
    key: 2,
    title: '3',
  },
]

const Slide = () => {
  const titleRef = useRef()
  const descriptionRef = useRef()
  const imageRef = useRef()
  const [active, setActive] = useState(0)

  const setOpacityToZero = () => {
    titleRef.current.style.opacity = '0'
    descriptionRef.current.style.opacity = '0'
    imageRef.current.style.opacity = '0'
  }

  const setOpacityToOne = () => {
    titleRef.current.style.opacity = '1'
    descriptionRef.current.style.opacity = '1'
    imageRef.current.style.opacity = '1'
  }

  const changeSlide = (key) => {
    setOpacityToZero()

    setTimeout(() => {
      setActive(key)

      setOpacityToOne()
    }, 200)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      changeSlide(active < 2 ? active + 1 : 0)
    }, 10000)

    return () => {
      clearTimeout(timeout)
    }
  }, [active])

  return (
    <Wrap>
      <LeftSide>
        <TitleGlobal ref={titleRef}>{slides[active].title}</TitleGlobal>
        <DescriptionGlobal ref={descriptionRef}>
          {slides[active].description}
        </DescriptionGlobal>
        <Buttons>
          {buttons.map((item) => (
            <Button
              key={item.key}
              active={active === item.key}
              onClick={() => changeSlide(item.key)}
            >
              {item.title}
            </Button>
          ))}
        </Buttons>
      </LeftSide>
      <RightSide
        bg={slides[active].bg}
        style={{ backgroundColor: slides[active].bg }}
      >
        <img src={slides[active].src} alt='slide' ref={imageRef} />
      </RightSide>
    </Wrap>
  )
}

export default Slide
