import Modal from "../../../components/common/ModalNew";
import Button from "../../../components/common/Button/button";
import { ModalTitle, Description, Row, Cancel } from "./style";

const AllSubscriptionModal = ({ handleClose, handleClick }) => {

  const onClick = () => {
    handleClick()
    handleClose()
  }
  return (
    <Modal onClose={handleClose}>
      <ModalTitle>Are You Sure You Want All Alerts?</ModalTitle>
      <Description>You will receive an email for every proposed legislation that is added as well.</Description>
      <Row>
        <Cancel onClick={handleClose}>Cancel</Cancel>
        <Button
          text='Alert Me For All'
          onClick={onClick}
        />
      </Row>
    </Modal>
  )
}

export default AllSubscriptionModal