import {
  BlockWrap,
  ImageBlock,
  BlockTitle,
  BlockSubtitle,
  BlockDescription,
  BlockContent,
  FillerTitle,
} from './style'
import { useHistory } from 'react-router-dom'
import Button from '../../../../../components/common/Button/button'

export const Tool = ({
  number,
  type,
  name,
  subtitle,
  description,
  backgroundColor,
  image,
  color,
  link,
  checked,
  signUpText,
  disabled }) => {
  const history = useHistory();

  return (
    <>
      {type === 'filler'
        ? <BlockWrap
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'default' }}
          number={number}
          backgroundColor={backgroundColor}
          onClick={() => { }}>
          <BlockContent style={{ alignItems: 'center'}}>
            <FillerTitle color={color} disabled={disabled}>
              More Coming Soon...
            </FillerTitle>
          </BlockContent>
        </BlockWrap>
        : <BlockWrap
          number={number}
          backgroundColor={backgroundColor}
          onClick={() => { }}>
          <ImageBlock disabled={disabled}>
            <img src={image} alt={{ name }} />
          </ImageBlock>
          <BlockContent>
            <BlockTitle color={color} disabled={disabled}>
              <span>{name}</span>Source
            </BlockTitle>
            <BlockSubtitle>{subtitle}</BlockSubtitle>
            <BlockDescription disabled={disabled}>{description}</BlockDescription>
            <Button
              text={'Learn More'}
              onClick={() => history.push(link)}
              style={{ marginTop: 20, padding: '20px 40px' }}
            />
          </BlockContent>
        </BlockWrap>
      }
    </>
  )
}