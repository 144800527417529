import { Popover } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { api } from '../../../../api'
import check from '../../../../assets/images/check.svg'
import questionIcon from '../../../../assets/images/question.svg'
import user from '../../../../assets/images/user-plans.svg'
import BillPeriodButtons
  from '../../../../components/common/BillPeriodButtons/buttons'
import Button from '../../../../components/common/Button/button'
import Logo from '../../../../components/common/Logo/logo'
import StatusBar from '../../../../components/common/StatusBar/status'
import Container from '../../../../components/containers/container'
import { useStore } from '../../../../mobx-store/context'
import {
  BestTitle,
  Footer,
  Item,
  LeftSide,
  Option,
  OptionTitle,
  PageTitle,
  Price,
  PriceBlock,
  PriceDescription,
  Row,
  Title,
  TitleLeft,
  Tooltip,
  UnderTitle,
  Wrap,
} from '../../../Auth/Pricing/pricing.style'
import { Grid, RightSide } from './style'

const PricingResource = observer(() => {
  const history = useHistory()
  const store = useStore()
  const [loading, setLoading] = useState(false)
  const [active, setActive] = useState(null)
  const [activeButton, setActiveButton] = useState('year')

  const items = [
    {
      key: 0,
      planName: 'Snapshot',
      underTitle: null,
      button: 'TotalSource',
      startingAt: true,
      price: () => {
        return 5999
      },
      annualDiscount: {
        old: 120,
        current: 114,
      },
      planNameForBackend: 'TotalSource',
      minimum: '(up to 5 users)',
      save: activeButton === 'year' ? 570 : 600,
      description: (
        <>
          with comprehensive service offerings in more than twenty jurisdictions
        </>
      ),
      jurisdictions: () => '52',
      guides: () => 'ALL',
      maxUsers: 5,
      total: {
        month: (
          <div style={{ textAlign: 'center', lineHeight: 1 }}>
            <div style={{ fontFamily: 'var(--regular)', fontSize: 22 }}>
              $1,250
              <span style={{ fontFamily: 'var(--light)' }}>/month</span>
            </div>
            <span
              style={{
                fontSize: 14,
                fontFamily: 'var(--light)',
              }}
            >
              $15,000/year
            </span>
          </div>
        ),
        annual: (
          <div
            style={{
              color: '#455154',
              fontSize: 22,
              fontFamily: 'var(--medium)',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                fontSize: 16,
                color: 'var(--blue)',
                textDecoration: 'line-through',
                fontFamily: 'var(--light)',
                marginRight: 10,
              }}
            >
              $15,000
            </span>
            $13,500
          </div>
        ),
      },
    },
    {
      key: 1,
      planName: 'Subscription',
      underTitle: null,
      button: 'SelectSource',
      startingAt: true,
      price: () => {
        return 149
      },
      annualDiscount: {
        old: 240,
        current: 228,
      },
      planNameForBackend: 'select_source',
      minimum: '(up to 5 users)',
      save: activeButton === 'year' ? '1,140' : '1,200',
      jurisdictions: () => {
        return '+199/jurisdiction'
      },
      guides: () => {
        return 'min. +$149/guide/jurisdiction'
      },

      maxUsers: 10,
      description: (
        <>with limited service offerings and/or in limited jurisdictions</>
      ),
      premium: null,
      total: {
        month: (
          <div style={{ textAlign: 'center', lineHeight: 1 }}>
            <div style={{ fontFamily: 'var(--regular)', fontSize: 22 }}>
              $2,490
              <span style={{ fontFamily: 'var(--light)' }}>/month</span>
            </div>
            <span
              style={{
                fontSize: 14,
                fontFamily: 'var(--light)',
              }}
            >
              $29,860/year
            </span>
          </div>
        ),
        annual: (
          <div
            style={{
              color: '#455154',
              fontSize: 22,
              fontFamily: 'var(--medium)',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                fontSize: 16,
                color: 'var(--blue)',
                textDecoration: 'line-through',
                fontFamily: 'var(--light)',
                marginRight: 10,
              }}
            >
              $29,860
            </span>
            $26,892
          </div>
        ),
      },
    },
  ]

  const handleFetch = (tariff) => {
    setLoading(true)
    return api(
      `/companies/${store.company[0].id}/`,
      {
        tariff,
        is_premium: false,
        jurisdictions: [],
        topics: [],
        payment_period: activeButton,
      },
      'PATCH',
    ).then((data) => {
      setLoading(false)
      if (!data.errors) {
        store.setSingleValue('company', [data])
        history.push(`/topicsAndJur`)
      }
    }).catch(() => {
      setLoading(false)
    })
  }

  const jurisContent = useMemo(() => {
    return (
      <>
        <Tooltip>• All US States</Tooltip>
        <Tooltip>• District of Columbia</Tooltip>
        <Tooltip>• Federal</Tooltip>
      </>
    )
  }, [store.jurs])

  const formatNumber = (number) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    }).format(number.toFixed(3))

  return (
    <Container wide>
      <Wrap>
        <Row style={{ justifyContent: 'flex-start', alignItems: 'flex-end' }}>
          <Logo large/>
          <StatusBar
            style={{ marginLeft: '1em', marginBottom: '1em' }}
            step={3}
          />
        </Row>

        <Grid>
          <LeftSide bgColor={'#70A637'}>
            <TitleLeft style={{ backgroundColor: '#70A637' }}>
              <PageTitle style={{ backgroundColor: '#70A637' }}>
                Pricing
              </PageTitle>
              Features
              <div style={{ fontSize: '24px', marginBottom: 30 }}>
                TotalSource
              </div>
            </TitleLeft>

            <Option style={{ height: 60 }}>Feature description</Option>
            <Option style={{ height: 100 }}>
              Another comprehensive feature description
            </Option>
            <Option style={{ height: 60 }}>Search functionality</Option>

            <Option style={{ height: 60 }}>
              <OptionTitle>
                Guides
                <Popover
                  placement="rightTop"
                  content={jurisContent}
                  trigger="click"
                  overlayStyle={{
                    width: '220px',
                  }}
                >
                  <img src={questionIcon} alt="question-icon"/>
                </Popover>
              </OptionTitle>
            </Option>

            <Option style={{ height: 100 }}>Team Members Included</Option>

            <Footer>
              Choose Your Plan:{' '}
              <BillPeriodButtons
                paymentPeriod={activeButton}
                setActiveButton={(active) => setActiveButton(active)}
                styleContainer={{ marginTop: 15 }}
                activeColor={'var(--green)'}
              />
            </Footer>
          </LeftSide>
          <RightSide>
            {items.map((item) => {
              return (
                <Item
                  key={item.key}
                  active={
                    active === item.planNameForBackend ||
                    active === item.planNameForBackend + '_premium'
                  }
                  onClick={() => {
                    setActive(item.planNameForBackend)
                    store.updateCompany({ month_price: item.price() })
                  }}
                >
                  <Title style={{ paddingTop: 50 }}>{item.planName}</Title>
                  <UnderTitle>{item.underTitle}</UnderTitle>
                  <PriceBlock style={{ height: '14.75em' }}>
                    <PriceDescription>
                      <BestTitle>Best for Businesses</BestTitle>
                      {item.description}
                    </PriceDescription>
                    <Price>
                      <span>
                        {item.planNameForBackend === 'select_source' && (
                          <span style={{ fontSize: 12 }}>As low as</span>
                        )}{' '}
                        {formatNumber(item.price())}
                      </span>{' '}
                      /month
                    </Price>
                  </PriceBlock>

                  <Option style={{ height: 60 }}>
                    <img src={check} alt={'check-icon'}/>
                  </Option>
                  <Option style={{ height: 100 }}>
                    <img src={check} alt={'check-icon'}/>
                  </Option>
                  <Option style={{ height: 60 }}>
                    <img src={check} alt={'check-icon'}/>
                  </Option>
                  <Option style={{ height: 60 }}>{item.guides()}</Option>

                  <Option style={{ height: 100 }}>
                    <div style={{ display: 'flex' }}>
                      <img src={user} style={{ marginRight: 5 }} alt="user"/>
                      Includes&nbsp;{item.maxUsers}
                    </div>
                    <div
                      style={{
                        fontSize: 14,
                        fontStyle: 'italic',
                        fontFamily: 'var(--light)',
                        color: 'rgba(69, 81, 84, 0.7)',
                      }}
                    >
                      + $59/mo per additional <br/> team member
                    </div>
                  </Option>

                  <Footer
                    active={active === item.key}
                    style={{
                      backgroundColor:
                        active === item.planNameForBackend
                          ? 'rgba(145, 192, 62, 0.5)'
                          : '#F5F9EC',
                    }}
                  >
                    <div style={{ textAlign: 'center' }}>
                      {activeButton === 'year' && (
                        <span
                          style={{
                            fontSize: 16,
                            color: 'var(--blue)',
                            textDecoration: 'line-through',
                            fontFamily: 'var(--light)',
                            marginRight: 10,
                          }}
                        >
                          {formatNumber(item.price() * 12)}
                        </span>
                      )}

                      {activeButton === 'year'
                        ? formatNumber(
                          item.price() * 12 - item.price() * 12 * 0.1,
                        )
                        : formatNumber(item.price())}
                      {activeButton === 'month' && <span>/month</span>}
                      <br/>
                      {activeButton === 'month' && (
                        <span>{formatNumber(item.price() * 12)}/year</span>
                      )}
                    </div>
                    <Button
                      text={`Choose ${item.button}`}
                      style={{
                        width: 160,
                        borderColor: 'var(--blue)',
                        color: 'var(--blue)',
                        fontFamily: 'var(--medium)',
                        fontSize: 16,
                        marginTop: 15,
                      }}
                      type={'white'}
                      onClick={() => {
                        handleFetch(item.planNameForBackend.toLowerCase())
                        store.setSingleValue('maxUsers', item.maxUsers)
                      }}
                      loading={loading}
                    />
                  </Footer>
                </Item>
              )
            })}
          </RightSide>
        </Grid>
      </Wrap>
    </Container>
  )
})

export default PricingResource
