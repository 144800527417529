import React from 'react';
import { Checkbox } from 'antd';
import { Controller } from 'react-hook-form';
import { StyledTable } from './styles';

const CheckboxTable = ({ options, name, control, disabled = false, defaultValue, compact = false, disabledefault, allChecked = false }) => {

  const onRowClick = (record, index, event) => {

    event.preventDefault();
  };

  const handleCheckboxClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || []}
      render={({ value, onChange }) => (
        <StyledTable
          compact={compact}
          id='name'
          dataSource={options}
          rowKey="id"
          pagination={false}
          showHeader={false}
          onRow={(record, index) => ({
            onClick: (event) => onRowClick(record, index, event),
          })}
          size="small"
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
              render: (name) => <span onClick={() => { }}>{name}</span>,
            },
            {
              title: 'Checkbox',
              dataIndex: 'id',
              key: 'id',
              render: (id) => (
                <Checkbox
                  disabled={disabled || (disabledefault && defaultValue.includes(id))}
                  checked={allChecked ? true : value.includes(id)}
                  onClick={handleCheckboxClick}
                  onChange={(e) => {
                    const checkedId = id;
                    const newValue = e.target.checked
                      ? [...value, checkedId]
                      : value.filter((v) => v !== checkedId);
                    onChange(newValue);
                  }}
                />
              ),
            },
          ]}
        />
      )}
    />
  );
};

export default CheckboxTable;
