import styled from 'styled-components'


export const Wrap = styled('div')`
  width: 1142px;
  padding-top: 290px;
  padding-bottom: 20px;
  margin: 0 auto;
  min-height: calc(100vh - 50px);
  @media (max-width: 1600px) {
    padding-top: 140px;
  }
  @media (max-width: 1500px) {
    padding-top: 140px;
    width: 942px;
  }
`
export const Title = styled('div')`
text-align: start;
font-family: var(--regular);
font-size: 36px;
color: var(--text);
@media (max-width: 1200px) {
  font-size: 24px;
}
`
export const SubTitle = styled(Title)`
font-size: 16px;
font-family: var(--medium);
@media (max-width: 1200px) {
  font-size: 14px;
}
`
export const ServiceBlocksWrap = styled('div')`
  display: grid;
  margin-top: 20px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 326px;
`

export const BlockWrap = styled('div')`
  cursor: pointer;
  border: 1px solid var(--grey);
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 40px 58px;
  grid-column: ${props => props.number === 4 ? '1 / -1' : 'auto'};
  border-radius:${(props) => props.number === 0
    ? '12px 0px 0px 0px'
    : props.number === 1
      ? '0px 12px 0px 0px'
      : props.number === 2
        ? '0px 0px 0px 0px'
        : props.number === 3
          ? '0px 0px 0px 0px'
          : props.number === 4
            ? '0px 0px 12px 12px'
            : '0px 0px 0px 0px'};
  &:hover { 
    border: 2px solid var(--text); 
  }
`
export const BlockContent = styled('div')`
  width: 370px;
  height: 90px;
  display: flex;
  flex-direction: column;
`

export const ImageBlock = styled('div')`
  height: 75px;
  width: 75px;
  margin-right: 15px;
  opacity: ${(props) => (props.disabled && '0.6')};
`
export const BlockTitle = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => (`var(--${props.color})`)};
  font-size: 28px;
  font-family: var(--medium);
  opacity: ${(props) => (props.disabled && '0.6')};
`
export const BlockDescription = styled('div')`
  color: var(--text);
  line-height: 1.3;
  font-size: 16px;
  font-family: var(--medium);
  opacity: ${(props) => (props.disabled && '0.6')};
`
export const BlockSignUpButton = styled('div')`
  color: #225194;
  line-height: 1.3;
  font-size: 16px;
  font-family: var(--medium);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`
