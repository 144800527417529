import { default as PropTypes, default as React, useState } from 'react'
import ModalTerms from '../../TopicsJursList/Right/Item/Modal'
import { Text } from '../style'
import { Jurisdiction, Wrap } from './style'
import { useStore } from '../../../../mobx-store/context'
const Title = ({ jurisdiction, title, noJurisdiction }) => {
  const [visible, setVisible] = useState(false)

  const store = useStore()

  const chooseJur = (jur) => {
    const obj = store.jurs.filter(item => item.name === jur)
    if (obj[0]?.code === 'federal') {
      return 'FD'
    } else if (obj[0]?.code === 'district_of_columbia') {
      return 'DC'
    }
      
    return obj[0]?.code || '--'
  }

  return (
    <Wrap>
      {visible && <ModalTerms onClose={() => setVisible(false)} />}
      {!noJurisdiction && (
        <Jurisdiction>
          {chooseJur(jurisdiction)}
        </Jurisdiction>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Text>{title}</Text>
      </div>
    </Wrap>
  )
}

Title.propTypes = {
  jurisdictions: PropTypes.string,
  title: PropTypes.string,
  noJurisdiction: PropTypes.bool,
}

export default Title


