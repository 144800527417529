import styled from 'styled-components'
import { Title } from '../Auth/authForm.style'

export const Wrap = styled("div")`
  width: 900px;
  padding-left: 80px;
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
`;

export const ImageWrap = styled("div")`
  position: absolute;
  width: 600px;
  height: 600px;
  top: 100px;
  right: 0;
  img {
    width: 100%;
  }
`;

export const Content = styled("div")`
  padding-left: 40px;
  padding-top: 30px;
  ${Title} {
    border-bottom: 1px solid #f6f7f8;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
`;

export const Rights = styled("div")`
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #969fa2;
  padding-bottom: 15px;
`;

export const Text = styled("div")`
  font-size: 14px;
  font-family: var(--regular);
  color: var(--text);
  p {
    margin-top: 20px;
  }
  ol {
    li {
      margin-top: 15px;
      span {
        text-decoration: underline;
      }
    }
  }
`;

export const TextTitle = styled("div")`
  text-transform: uppercase;
  font-family: var(--bold);
  margin-top: 30px;
`;
