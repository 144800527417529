import styled from 'styled-components'

export const Title = styled("div")`
  font-family: var(--medium);
  font-size: 1.25em;
  color: var(--text);
  margin-right: 15px;
`;

export const Form = styled("form")`
  display: flex;
  flex-direction: column;
  // grid-template-columns: 1fr 1fr;
  // gap: 20px 50px;
  margin-top: 10px;
`;

export const Row = styled("div")`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
`;
