import styled from 'styled-components'

export const Wrap = styled('form')`
  margin-top: 1em;
`

export const Row = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 26px;
  align-items: end;
`
