import styled from 'styled-components'

export const Button = styled('div')`
height: 45px;
width: 45px;
line-height: 54px;
border-radius: 50%;
background-color: var(--dark-grey);
color: #fff;
text-align: center;
font-size: 14px;
font-family: var(--medium);
`