import styled from 'styled-components'

export const Wrap = styled('div')`
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 1.2fr 1.5fr 1.2fr 2.5fr;
  align-items: center;
  min-height: 78px;
  border-bottom: 1px solid #c4cacc;
  background-color: ${(p) => (p.active ? '#F2F5FF' : 'fff')};
`

export const Text = styled('div')`
  padding: 10px 5px;
  font-size: 14px;
  font-family: ${(p) => (p.isRead  ? 'var(--regular)' : 'var(--bold)')} ;
  color: var(--text);
  display: flex;
  align-items: center;
  // img {
  //   margin: 0 25px 0 10px;
  // }
`
