import Modal from '../../../../components/common/ModalNew'
import { Title } from './style'
import SettingsTable from './Table'


const TableOptionsModal = ({ onClose }) => {
    return (
        <Modal onClose={onClose}>
            <Title>Select Your Column Options</Title>
            <SettingsTable onClose={onClose} />
        </Modal>
    )
}
export default TableOptionsModal
