import React from 'react'
import { Link } from 'react-router-dom'
import image from '../../../assets/images/signupresource.svg'
import TopLine from '../../../components/TopLine/topLine'
import { useStore } from '../../../mobx-store/context'
import { Container, Title, Wrap } from './style'

const SignUpForResource = () => {
  const store = useStore()

  const onClick = () => store.setSingleValue('started_from_resource', true)

  return (
    <Container>
      <TopLine />
      <Wrap>
        <Title>
          Sign up for <span> ReSource</span>
        </Title>
        <img src={image} alt='bg' />
        <Title style={{ fontSize: 25, marginTop: 50 }}>
          Your current account doesn’t have a subscription to ReSource.{' '}
          <Link to='/register' onClick={onClick}>
            Sign up here
          </Link>{' '}
          OR if you have questions or need a demo, please{' '}
          <a href='mailto:contact@pbmsource.com'>contact us</a> .
        </Title>
      </Wrap>
    </Container>
  )
}

export default SignUpForResource
