import React, { useState } from 'react';
import { ItemWrap, ItemText, FileName, AddedBy } from './style';
import ButtonOrange from '../../../../../components/common/ButtonOrange';
import moment from 'moment';
import deleteFileIcon from '../../../../../assets/images/delete-orange.svg';
import { downloadDoc } from '../../../../../api';
import HeldAdminItemAddModal from '../../HeldAdminItemAddModal';

const ItemBlock = ({ item, deliteFile, materialType, liceneCompanyId, trigger, setTrigger, historyMode }) => {
  const { description, files, author, created, id } = item;
  const [isExpanded, setIsExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded); 
  };

  return (
    <ItemWrap>
      {showModal && (
        <HeldAdminItemAddModal
          onClose={() => setShowModal(false)}
          id={liceneCompanyId}
          heldType={materialType}
          setTrigger={setTrigger}
          trigger={trigger}
          edit={true}
          itemId={id}
          initialText={description}
          initialFiles={files}
          removeFile={deliteFile}
        />
      )}
      <ItemText dangerouslySetInnerHTML={
        { __html: description }
      } />
      {files.length > 0 && (
        <ButtonOrange
          style={{
            fontFamily: 'var(--bold)',
            fontSize: 14,
            textDecoration: 'underline',
            width: 'fit-content',
            color: '#DF7411',
            // marginTop: 15
          }}
          onClick={toggleExpand}
          text={isExpanded ? 'Hide Documents' : `View Documents (${files.length})`}
        />
      )}
      {isExpanded && (
        <div>
          {files.map((file, index) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <FileName key={index}
                target='_blank'
                onClick={() => {
                  const url = `license_company/${liceneCompanyId}/${materialType}/${id}/files/${file.id}/`
                  downloadDoc(url, file.file_name, false, true, false, liceneCompanyId,null, true)
                }}
                download
              >
                {file.file_name}
              </FileName>
              <img
                src={deleteFileIcon}
                alt={'delete-icon'}
                onClick={() => deliteFile(id, file.id)}
                style={{ marginLeft: 5, cursor: 'pointer' }}
              />
            </div>
          ))}
        </div>
      )}
      <div style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
      <AddedBy>
        Added by: {author} {moment(created).format('MM/DD/YYYY')}
      </AddedBy>
      {!historyMode && (
        <ButtonOrange
        style={{
          fontFamily: 'var(--regular)',
          fontSize: 14,
          textDecoration: 'underline',
          width: 'fit-content',
          color: '#DF7411',
          // marginTop: 15
        }}
        onClick={setShowModal}
        text={`Edit`}
      />
      )}
      </div>
      
    </ItemWrap>
  );
};

export default ItemBlock;