import styled from 'styled-components'

export const Wrap = styled('div')`
  height: 32px;
  width: 21px;
  border-radius: 15px;
  background-color: ${(props) => (props.active ? 'var(--orange)' : '#455154')};
  border: 2px solid ${(props) => (props.active ? 'var(--orange)' : '#30393b')};
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
`

export const Circle = styled('div')`
  width: 28px;
  height: 28px;
  border-radius: 15px;
  border: 2px solid ${(props) => (props.active ? 'var(--orange)' : '#455154')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--semiBold);
  font-size: 12px;
  color: ${(props) => (props.active ? 'var(--orange)' : 'var(--blue)')};
  background-color: #fff;
  position: absolute;
  transform: translateY(${(props) => (props.active ? '-10px' : '10px')});
  transition: all 0.4s ease-in-out;
  box-shadow: 0 4px 2px rgba(0, 0, 0, 0.1);
`
