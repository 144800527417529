import styled from 'styled-components'

export const Text = styled('div')`
  font-family: var(--regular);
  font-size: 16px;
  color: var(--text);
`

export const Row = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`
