import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { api } from '../../../../api'
import Button from '../../../../components/common/Button/button'
import Input from '../../../../components/common/Input'
import { useStore } from '../../../../mobx-store/context'
import { Row, Wrap } from './form.style'

const Form = observer(({ preloaded }) => {
  const store = useStore()
  const history = useHistory()
  const { id } = useParams()

  const { control, handleSubmit, errors } = useForm({
    defaultValues: preloaded,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!store.user) {
      store.getUser()
    }
  }, [store.user])

  useEffect(() => {
    if (!store.states.length) store.loadSelect('states')
  }, [])

  const patchUsers = async (token, values) => {
    setLoading(true)
    await api(
      `/users/${store.user[0].id}/`,
      {
        first_name: values.first_name,
        last_name: values.last_name,
        phone_number: values.phone_number,
      },
      'PATCH'
    )
      .then((data) => {
        const companyId = store.company.length ? store.company[0].id : id

        store.setSingleValue('user', [data])

        return api(
          `/companies/${companyId}/`,
          {
            phone_number: values.phone_number || '',
            address1: values.address1 || '',
            address2: values.address2 || '',
            address3: values.address3 || '',
            name: values.name || '',
            state: values.state,
            zip: values.zip,
            tariff: 'select_tract',
            is_premium: false,
            jurisdictions: [],
            topics: [],
            payment_period: 'year',
          },
          'PATCH'
        )
      })
      .then((data) => {
        setLoading(false)
        if (data) {
          if (!data.errors) {
            store.setSingleValue('company', [data])
            history.push(data.started_from_report_source
              ? '/addReportMember/'
              : data.started_from_proposed_source ?
                '/addProposedMember/'
                : data.started_from_resource
                  ? '/resource/options'
                  : '/topicsAndJur'
            )
          }
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const onSubmit = (data) => {
    patchUsers(null, data)
  }

  return (
    <Wrap onSubmit={handleSubmit(onSubmit)}>
      <Input
        name={'name'}
        label={'Business Name'}
        error={errors && errors.name && 'Enter valid business name'}
        type={'text'}
        control={control}
        validation={{ required: true }}
        transparent
        placeholder={'Business Name'}
        style={{ marginBottom: errors && errors.name && '0' }}
      />
      <Row style={{ alignItems: 'baseline' }}>
        <Input
          name={'first_name'}
          label={'First Name'}
          error={errors && errors.first_name && 'Enter valid first name'}
          type={'text'}
          control={control}
          validation={{ required: true, pattern: /[A-Za-z]+/ }}
          transparent
          placeholder={'First Name'}
          style={{ marginBottom: errors && errors.first_name && '0' }}
        />
        <Input
          name={'last_name'}
          label={'Last Name'}
          error={errors && errors.last_name && 'Enter valid last name'}
          type={'text'}
          control={control}
          validation={{ required: true, pattern: /[A-Za-z]+/ }}
          transparent
          placeholder={'Last Name'}
          style={{ marginBottom: errors && errors.last_name && '0' }}
        />
      </Row>
      <Row style={{ alignItems: 'flex-start' }}>
        <Input
          name={'address1'}
          label={'Address'}
          error={errors && errors.address1 && 'Enter valid address name'}
          type={'text'}
          control={control}
          validation={{ required: true }}
          transparent
          placeholder={'Address'}
          style={{ marginBottom: '.3em' }}
        />

        <Input
          name={'address3'}
          error={errors && errors.address3 && 'Enter valid address name'}
          type={'text'}
          control={control}
          validation={{ required: true }}
          transparent
          placeholder={'Address'}
          style={{ marginBottom: '.3em', marginTop: 35 }}
        />
      </Row>
      <Row style={{ alignItems: 'flex-start' }}>
        <Input
          name={'address2'}
          error={errors && errors.address2 && 'Enter valid address name'}
          type={'text'}
          control={control}
          validation={{ required: false }}
          transparent
          placeholder={'City'}
          style={{ marginBottom: errors && errors.address2 && '0' }}
        />
        <Input
          name={'zip'}
          error={errors && errors.zip && 'Enter valid zip code (5 numbers)'}
          type={'text'}
          control={control}
          validation={{ required: true, maxLength: 5, minLength: 5 }}
          transparent
          placeholder={'Zip Code'}
          style={{ marginBottom: '0.3em' }}
          mask={'99999'}
        />
      </Row>
      <Row style={{ alignItems: 'flex-start' }}>
        <Input
          name={'state'}
          error={errors && errors.state && 'Enter valid state'}
          type={'select'}
          control={control}
          validation={{ required: true }}
          transparent
          options={store.states}
          placeholder={'State'}
          style={{ marginBottom: errors && errors.state && '0' }}
          selectVars={{ value: 0, name: 1 }}
          showSearch
        />
        <Input
          name={'phone_number'}
          error={errors && errors.phone_number && 'Enter a valid phone number'}
          type={'phone'}
          control={control}
          validation={{ required: true }}
          transparent
          placeholder={'( _ _ _ )  _ _ _ - _ _ _ _'}
          style={{ marginBottom: '0' }}
        />
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          text={'Submit Business Info'}
          type={'main'}
          style={{
            width: '48%',
            padding: 0,
            marginRight: 0,
            marginLeft: '.5em',
            marginTop: '2.3em',
          }}
          loading={loading}
        />
      </Row>
    </Wrap>
  )
})

export default Form
