import styled from 'styled-components'

export const Wrap = styled("div")`
  padding-top: 200px;
`;

export const Container = styled("div")`
  width: 1250px;
  margin: 0 auto;
`;

export const Logo = styled("div")`
  text-align: center;
  margin-bottom: 100px;
  img {
    width: 950px;
  }
`;

export const Title = styled("div")`
  font-size: 64px;
  font-family: var(--regular);
  color: var(--blue);
`;

export const Text = styled("div")`
  font-family: var(--regular);
  font-size: 20px;
  color: var(--text);
`;
