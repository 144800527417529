import React, { useState, useEffect } from 'react'
import { CheckboxWrap, Text, Wrap } from './agreement.style'
import { Checkbox } from 'antd'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../mobx-store/context'
import ButtonOrange from '../ButtonOrange'
import AgreementPopup from '../../../pages/Auth/Agreement/agreement'

const Agreement = observer(({ onChange, checked }) => {
  const store = useStore()
  const [visible, setVisible] = useState(false)
  const [startedFromReportSource, setstartedFromReportSource] = useState(false)
  const [startedFromResource, setStartedFromResource] = useState(false)
  const [startedFromProposedSource, setStartedFromProposedSource] = useState(false)

  useEffect(() => {
    setstartedFromReportSource(store.started_from_report_source)

  }, [])

  useEffect(() => {
    setStartedFromResource(store.started_from_resource)

  }, [])

  useEffect(() => {
    setStartedFromProposedSource(store.started_from_proposed_source)

  }, [])

  return (
    <>
      {visible && <AgreementPopup
        startedFromResource={startedFromResource}
        startedFromReportSource={startedFromReportSource}
        startedFromProposedSource={startedFromProposedSource}
        onClick={() => setVisible(false)}
      />}
      <Wrap>
        <CheckboxWrap>
          <Checkbox checked={checked} onChange={() => onChange(!checked)} />
        </CheckboxWrap>
        <Text>
          By signing up, you agree to the{' '}
          <ButtonOrange
            style={{ display: 'contents', fontSize: '1em' }}
            text={'Terms of Service, Privacy Policy'}
            link={'/terms-and-privacy'}
          />
          .
        </Text>
      </Wrap>
    </>
  )
})

export default Agreement
