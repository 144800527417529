import { message } from 'antd'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useStore } from '../../../../../mobx-store/context'
import { api } from '../../../../../api'
import Button from '../../../../../components/common/Button/button'
import Input from '../../../../../components/common/Input'
import { getError } from '../../../../../helpers/helpers'
import { Wrap } from './style'

const ScheduleInput = ({ border, style, onClose, service }) => {
  const { control, handleSubmit, reset } = useForm()
  const store = useStore();
  const serviceName = service || store.selectedProduct

  const onSubmit = (values) => {
    api('/resources/schedule_demo/', { service_name: serviceName, ...values }, 'POST', true)
      .then((data) => {
        if (data.errors) return message.error(getError(data))

        message.success('Message sent')
        reset()
      })
      .catch((err) => console.log(err))
    onClose && onClose()
  }

  return (
    <>
      <div style={{ display: 'flex', }}>
        <Wrap border={border} style={{ display: 'flex', width: 220, marginBottom: 5, marginRight: 5, }}>
          <Input
            type='text'
            name='name'
            control={control}
            validation={{ required: true }}
            style={{ border: 0, marginRight: 5 }}
            placeholder='Name'
          />
        </Wrap>
        <Wrap border={border} style={{ display: 'flex', width: 220, marginBottom: 5 }}>
          <Input
            type='text'
            name='company'
            control={control}
            validation={{ required: true }}
            style={{ border: 0 }}
            placeholder='Company'
          />
        </Wrap>
      </div>

      <Wrap border={border} style={style} onSubmit={handleSubmit(onSubmit)}>
        <Input
          type='email'
          name='email'
          control={control}
          validation={{ required: true }}
          style={{ border: 0, }}
          placeholder='yourname@mail.com'
        />
        <Button text='Schedule a Demo' style={{ height: 'calc(100% - 15px)', marginTop: 1 }} />
      </Wrap>
    </>

  )
}

export default ScheduleInput
