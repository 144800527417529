import { useEffect, useState } from "react";
import { useStore } from "../../../../../mobx-store/context";
import { useParams } from "react-router-dom/";
import { observer } from "mobx-react-lite";
import { useForm, Controller } from "react-hook-form";
import { Divider, Checkbox, message } from "antd";
import { ItemsShowing as ShowItem, StyledTable, UnderTableCover } from "./style";
import ItemsShowing from "../../../../../components/common/TableItemsShowing";
import { api } from "../../../../../api";
import { TableHeaderTitle } from "../../../CompaniesTable/style";
import Filters from "./filters";
import Search from "./search";
import Button from "../../../../../components/common/Button/button";
import { Row } from "../../../CreateCompany/pages/ManageProducts/style";
import { useHistory } from "react-router-dom/";
import Cookies from 'universal-cookie'
import ErrorUserModal from "./ErrorUsersModal";

import RemoveUserModal from "../../../EditUser/RemoveuserModal";

const UsersTab = observer(({ handleTabChange, allUsersPage }) => {
  const params = useParams()
  const history = useHistory()
  const {
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm()
  const store = useStore()
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [selectedBulkActions, setSelectedBulkActions] = useState([])
  const [isHeaderCheckboxChecked, setIsHeaderCheckboxChecked] = useState(false);
  const [page, setPage] = useState(1)
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedEditionItems, setSelectedEditionItems] = useState([]);
  const [usersNumber, setUsersNumber] = useState(0);
  const [selectedUsersNames, setSelectedUsersNames] = useState([])
  const [errorModalVisible, setErrorModalVisible] = useState(false)
  const [errorUsers, setErrorUsers] = useState([])

  const onHeaderCheckboxChange = () => {
    const newCheckedState = !isHeaderCheckboxChecked;

    setIsHeaderCheckboxChecked(newCheckedState);

    const users = allUsersPage === 'all'
      ? store?.cmsAllUsers
      : allUsersPage === 'admins'
        ? store?.cmsAdminsUsers
        : allUsersPage === 'companyUsers' ? store?.cmsCompanyUsers : []
    users?.forEach((record) => {
      setValue(`${record.id}-bulk_users_actions`, newCheckedState);
      handleBulkActionCheckboxChange(record, 'bulk_users_actions')(newCheckedState);
    });
  };

  const handleBulkActionCheckboxChange = (record, dataIndex) => (newValue) => {
    const { id: recordKey, full_name: fullName, email } = record;
    setSelectedBulkActions((prevSelectedBulkActions) => {
      const updatedSelectedBulkActions = [...prevSelectedBulkActions];
      if (newValue) {
        updatedSelectedBulkActions.push(recordKey);
      } else {
        const index = updatedSelectedBulkActions.indexOf(recordKey);
        if (index !== -1) {
          updatedSelectedBulkActions.splice(index, 1);
        }
      }
      return updatedSelectedBulkActions;
    });
    setSelectedUsersNames((prevSelectedUsersNames) => {
      let updatedSelectedUsersNames = [...prevSelectedUsersNames];
      if (newValue) {
        updatedSelectedUsersNames.push({ fullName, email: record.email });
      } else {
        updatedSelectedUsersNames = updatedSelectedUsersNames.filter(user => !(user.fullName === fullName && user.email === record.email));
      }
      return updatedSelectedUsersNames;
    });

  };

  const onBulkActionCheckboxChange = (record, dataIndex) => (e) => {
    const newValue = e.target.checked;
    const { id: recordKey, full_name: fullName, email } = record;
    setValue(`${recordKey}-${dataIndex}`, newValue);
    setSelectedBulkActions((prevSelectedBulkActions) => {
      const updatedSelectedBulkActions = [...prevSelectedBulkActions];
      if (newValue) {
        updatedSelectedBulkActions.push(recordKey);
      } else {
        const index = updatedSelectedBulkActions.indexOf(recordKey);
        if (index !== -1) {
          updatedSelectedBulkActions.splice(index, 1);
        }
      }
      return updatedSelectedBulkActions;
    });
    setSelectedUsersNames((prevSelectedUsersNames) => {
      let updatedSelectedUsersNames = [...prevSelectedUsersNames];
      if (newValue) {
        updatedSelectedUsersNames.push({ fullName, email });
      } else {
        updatedSelectedUsersNames = updatedSelectedUsersNames.filter(user => !(user.fullName === fullName && user.email === record.email));
      }
      return updatedSelectedUsersNames;
    });
  };

  const watchBulkActions = watch();

  const onNameClick = (id, company) => {
    const url = `/cms/users/${id}/`
    api(url, {}, 'GET').then((res) => {
      if (!res.errors) {
        store.setSingleValue('cmsUser', res)
        localStorage.setItem('editedCmsCompanyId', company)
      }
    }).then(() => {
      allUsersPage === 'admins'
        ? history.push(`/dashboard-edit-admin/${id}`)
        : history.push(`/dashboard-edit-user/${id}`)
    })
  }

  const onCompanyClick = (id) => {
    history.push(`/dashboard-edit-company/${id}`)
  }

  const finish = () => {
    handleTabChange('compliance')
  }

  const editUserAccess = (recordKey, dataIndex, newValue) => {
    api(`/cms/users/${recordKey}/`, { [dataIndex]: newValue }, 'PATCH').then((res) => {
      if (res.error) {
        message.error(res.error)
      } else {
        message.success('User access updated')
        setValue(`${recordKey}-${dataIndex}`, newValue);
      }
    }
    )
  }

  const onCheckboxChange = (recordKey, dataIndex) => (e) => {
    const newValue = e.target.checked;

    editUserAccess(recordKey, dataIndex, newValue)
  };

  function createQueryString(permissions) {
    if (permissions.length === 0) {
      return '';
    }

    const queryParams = {};
    permissions.forEach(permission => {
      queryParams[permission] = true;
    });

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    return queryString;
  }

  const getSearchableString = (item) => {
    if (!item) return ''
    return item

  }

  const getCompanyUsers = () => {
    setLoading(true)
    const url = allUsersPage === 'all'
      ? `/cms/users/?${createQueryString(selectedFilters)}&page=${page}&search=${getSearchableString(store.cmsUsersSearch)}`
      : allUsersPage === 'companyUsers'
        ? `/cms/users/?company=${params.id}&remove_pagination=true&${createQueryString(selectedFilters)}&search=${getSearchableString(store.cmsUsersSearch)}`
        : allUsersPage === 'admins' ?
          `/cms/users/?admin_access=true&staff_access=true&page=${page}&search=${getSearchableString(store.cmsUsersSearch)}` : ''
    api(url, {}, 'GET').then((res) => {
      if (!res.errors) {
        allUsersPage === 'all'
          ? store.setSingleValue('cmsAllUsers', res.results)
          : allUsersPage === 'companyUsers'
            ? store.setSingleValue('cmsCompanyUsers', res)
            : allUsersPage === 'admins'
              ? store.setSingleValue('cmsAdminsUsers', res.results)
              : store.setSingleValue('cmsCompanyUsers', res)
        allUsersPage === 'companyUsers'
          ? setUsersNumber(res.length)
          : setUsersNumber(res.count);
        setLoading(false);
      }
    });
  }

  const handleUserActions = (action_type) => {
    const data = !allUsersPage ? {
      action_type,
      users: selectedBulkActions
    } : {
      users: selectedBulkActions,
      service_name: action_type === 'grant_compliance_access'
        ? 'compliance_source'
        : action_type === 'grant_proposed_access'
          ? 'proposed_source'
          : action_type === 'grant_resource_access'
            ? 'resource'
            : action_type === 'grant_report_access'
              ? 'report_source'
              : null
    }
    const url = allUsersPage ? `/cms/users/grant_access/` : `/cms/companies/${params.id}/user_actions/`
    api(url, data, 'POST').then((res) => {
      if (!res.errors) {
        getCompanyUsers()
        setSelectedBulkActions([])
        setSelectedUsersNames([])
        if (res.error_users && res.error_users.length > 0) {
          setErrorModalVisible(true)
          setErrorUsers(res.error_users)
          setSelectedBulkActions([])
          setSelectedUsersNames([])
        }
      }
    })
  }

  const runReportOnUsers = () => {
    const cookies = new Cookies()
    const csrftoken = cookies.get('csrftoken')
    let token = cookies.get('pbm_token')
    const url = allUsersPage ? `/cms/users/export_to_xlsx/` : `/cms/companies/${params.id}/user_actions/`
    const data = allUsersPage ? {
      users: selectedBulkActions,
    } : {
      users: selectedBulkActions,
      action_type: "run_report_on_selected_users"
    }
    fetch(
      `${process.env.REACT_APP_API_DEV
      }${url}`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Authorization: `Token ${token}`,
          'X-CSRFToken': csrftoken,
          'Content-Type': 'application/json',
        },
      }
    ).then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `users.xlsx`)
        document.body.appendChild(link)

        link.click()

        link.parentNode.removeChild(link)
      })
  }

  const onEdit = (option) => {
    option === 'run_report'
      ? runReportOnUsers()
      : option === 'delete_selected_users'
        ? setVisible(true)
        : handleUserActions(option)
  }

  const onDeleteUser = () => {
    const data = allUsersPage ? { users: selectedBulkActions }
      : {
        users: selectedBulkActions,
        action_type: "delete_selected_users"
      }
    const url = allUsersPage
      ? `/cms/users/delete_selected/`
      : `/cms/companies/${params.id}/user_actions/`
    api(url, data, allUsersPage ? 'DELETE' : 'POST').then((res) => {
      if (!res.errors) {
        getCompanyUsers()
        setSelectedBulkActions([])
        setVisible(false)
        setSelectedUsersNames([])
        message.success(allUsersPage ? 'Users deleted successfully' : 'User deleted successfully')
      }
    })
  }

  const columns = [
    {
      title: <TableHeaderTitle>Name</TableHeaderTitle>,
      dataIndex: 'full_name',
      width: '20%',
      key: 'full_name',
      sorter: false,
      render: (text, record) => (
        <div
          style={{
            color: '#225194',
            cursor: 'pointer',
            textDecoration: 'underline',
            fontFamily: 'var(--regular)',
            fontSize: 12,
          }}
          onClick={() => onNameClick(record.id, record.company)}
        >
          {text}</div>
      ),
    },
    {
      title: <TableHeaderTitle>Email</TableHeaderTitle>,
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      sorter: false,
      render: (text, record) => (
        <div
          style={{
            fontFamily: 'var(--regular)',
            fontSize: 12,
          }}
        >
          {text}</div>
      ),
    },
    {
      title: <TableHeaderTitle>Compliance</TableHeaderTitle>,
      dataIndex: 'have_access_to_tracking',
      width: '5%',
      key: 'have_access_to_tracking',
      sorter: false,
      render: (text, record) => (
        <Controller
          name={`${record.id}-have_access_to_tracking`}
          control={control}
          defaultValue={text}
          render={(field) => (
            <Checkbox
              disabled={!store.cmsCompany[0].is_registration_passed}
              checked={field.value}
              onClick={(e) => e.stopPropagation()}
              onChange={onCheckboxChange(record.id, 'have_access_to_tracking')}
            />
          )}
        />
      ),
    },
    {
      title: <TableHeaderTitle>Proposed</TableHeaderTitle>,
      dataIndex: 'have_access_to_proposed_source',
      key: 'have_access_to_proposed_source',
      width: '5%',
      sorter: false,
      render: (text, record) => (
        <Controller
          name={`${record.id}-have_access_to_proposed_source`}
          control={control}
          defaultValue={text}
          render={(field) => (
            <Checkbox
              disabled={!store.cmsCompany[0].have_access_to_proposed_source}
              checked={field.value}
              onClick={(e) => e.stopPropagation()}
              onChange={onCheckboxChange(record.id, 'have_access_to_proposed_source')}
            />
          )}
        />
      ),
    },
    {
      title: <TableHeaderTitle>Resource</TableHeaderTitle>,
      dataIndex: 'have_access_to_resource',
      width: '5%',
      key: 'have_access_to_resource',
      sorter: false,
      render: (text, record) => (
        <Controller
          name={`${record.id}-have_access_to_resource`}
          control={control}
          defaultValue={text}
          render={(field) => (
            <Checkbox
              disabled={!store.cmsCompany[0].have_access_to_resource}
              checked={field.value}
              onClick={(e) => e.stopPropagation()}
              onChange={onCheckboxChange(record.id, 'have_access_to_resource')}
            />
          )}
        />
      ),
    },
    {
      title: <TableHeaderTitle>Report</TableHeaderTitle>,
      dataIndex: 'have_access_to_report_source',
      width: '5%',
      key: 'have_access_to_report_source',
      sorter: false,
      render: (text, record) => (
        <Controller
          name={`${record.id}-have_access_to_report_source`}
          control={control}
          defaultValue={text}
          render={(field) => (
            <Checkbox
              disabled={!store.cmsCompany[0].have_access_to_report_source}
              checked={field.value}
              onClick={(e) => e.stopPropagation()}
              onChange={onCheckboxChange(record.id, 'have_access_to_report_source')}
            />
          )}
        />
      ),
    },
    {
      title: <TableHeaderTitle>Last Activity</TableHeaderTitle>,
      dataIndex: 'last_login',
      key: 'last_login',
      sorter: false,
      render: (text, record) => (
        <>
          <div style={{ fontFamily: 'var(--regular)' }}>{text}</div>
          {text !== 'N/A' && <div style={{ fontFamily: 'var(--regular)' }}>{!record.is_last_login_failed ? 'Last Login Was Successful' : 'Last Login Was Not Successful'}</div>}
        </>

      ),
    },
    {
      title: (
        <div>
          <Checkbox
            checked={isHeaderCheckboxChecked}
            onChange={onHeaderCheckboxChange}
          />
        </div>
      ),
      dataIndex: 'bulk_users_actions',
      key: 'bulk_users_actions',
      sorter: false,
      width: '5%',
      render: (text, record) => (
        <Controller
          name={`${record.id}-bulk_users_actions`}
          control={control}
          defaultValue={text}
          render={(field) => (
            <Checkbox
              checked={field.value}
              onClick={(e) => e.stopPropagation()}
              onChange={onBulkActionCheckboxChange(record, 'bulk_users_actions')}
            />
          )}
        />
      ),
    },
  ]

  const allUsersColumns = [
    {
      title: <TableHeaderTitle>Name & Email</TableHeaderTitle>,
      dataIndex: 'full_name',
      width: '20%',
      key: 'full_name',
      sorter: false,
      render: (text, record) => (
        <>
          <div
            style={{
              color: '#225194',
              cursor: 'pointer',
              textDecoration: 'underline',
              fontFamily: 'var(--regular)',
              fontSize: 12,
            }}
            onClick={() => onNameClick(record.id, record.company)}
          >
            {text}</div>
          <div style={{
            color: '#225194',
            cursor: 'pointer',
            textDecoration: 'underline',
            fontFamily: 'var(--regular)',
            fontSize: 12,
          }}
            onClick={() => onNameClick(record.id, record.company)}
          > {record.email}</div>
        </>
      ),
    },
    {
      title: <TableHeaderTitle>Company Name</TableHeaderTitle>,
      dataIndex: 'company_name',
      key: 'company_name',
      width: '20%',
      sorter: false,
      render: (text, record) => (
        <div
          style={{
            color: '#225194',
            cursor: 'pointer',
            textDecoration: 'underline',
            fontFamily: 'var(--regular)',
            fontSize: 12,
          }}
          onClick={() => onCompanyClick(record.company)}
        >
          {text}</div>
      ),
    },
    {
      title: <TableHeaderTitle>Compliance</TableHeaderTitle>,
      dataIndex: 'have_access_to_tracking',
      width: '5%',
      key: 'have_access_to_tracking',
      sorter: false,
      render: (text, record) => (
        <Controller
          name={`${record.id}-have_access_to_tracking`}
          control={control}
          defaultValue={text}
          render={(field) => (
            <Checkbox
              // disabled={!store.cmsCompany[0].is_registration_passed}
              checked={field.value}
              onClick={(e) => e.stopPropagation()}
              onChange={onCheckboxChange(record.id, 'have_access_to_tracking')}
            />
          )}
        />
      ),
    },
    {
      title: <TableHeaderTitle>Proposed</TableHeaderTitle>,
      dataIndex: 'have_access_to_proposed_source',
      key: 'have_access_to_proposed_source',
      width: '5%',
      sorter: false,
      render: (text, record) => (
        <Controller
          name={`${record.id}-have_access_to_proposed_source`}
          control={control}
          defaultValue={text}
          render={(field) => (
            <Checkbox
              // disabled={!store.cmsCompany[0].have_access_to_proposed_source}
              checked={field.value}
              onClick={(e) => e.stopPropagation()}
              onChange={onCheckboxChange(record.id, 'have_access_to_proposed_source')}
            />
          )}
        />
      ),
    },
    {
      title: <TableHeaderTitle>Resource</TableHeaderTitle>,
      dataIndex: 'have_access_to_resource',
      width: '5%',
      key: 'have_access_to_resource',
      sorter: false,
      render: (text, record) => (
        <Controller
          name={`${record.id}-have_access_to_resource`}
          control={control}
          defaultValue={text}
          render={(field) => (
            <Checkbox
              // disabled={!store.cmsCompany[0].have_access_to_resource}
              checked={field.value}
              onClick={(e) => e.stopPropagation()}
              onChange={onCheckboxChange(record.id, 'have_access_to_resource')}
            />
          )}
        />
      ),
    },
    {
      title: <TableHeaderTitle>Report</TableHeaderTitle>,
      dataIndex: 'have_access_to_report_source',
      width: '5%',
      key: 'have_access_to_report_source',
      sorter: false,
      render: (text, record) => (
        <Controller
          name={`${record.id}-have_access_to_report_source`}
          control={control}
          defaultValue={text}
          render={(field) => (
            <Checkbox
              // disabled={!store.cmsCompany[0].have_access_to_report_source}
              checked={field.value}
              onClick={(e) => e.stopPropagation()}
              onChange={onCheckboxChange(record.id, 'have_access_to_report_source')}
            />
          )}
        />
      ),
    },
    {
      title: <TableHeaderTitle>Last Activity</TableHeaderTitle>,
      dataIndex: 'last_login',
      key: 'last_login',
      sorter: false,
    },
    {
      title: (
        <div>
          <Checkbox
            checked={isHeaderCheckboxChecked}
            onChange={onHeaderCheckboxChange}
          />
        </div>
      ),
      dataIndex: 'bulk_users_actions',
      key: 'bulk_users_actions',
      sorter: false,
      width: '5%',
      render: (text, record) => (
        <Controller
          name={`${record.id}-bulk_users_actions`}
          control={control}
          defaultValue={text}
          render={(field) => (
            <Checkbox
              checked={field.value}
              onClick={(e) => e.stopPropagation()}
              onChange={onBulkActionCheckboxChange(record, 'bulk_users_actions')}
            />
          )}
        />
      ),
    },
  ]

  const adminUsersColumns = [
    {
      title: <TableHeaderTitle>Name</TableHeaderTitle>,
      dataIndex: 'full_name',
      width: '20%',
      key: 'full_name',
      sorter: false,
      render: (text, record) => (
        <div
          style={{
            color: '#225194',
            cursor: 'pointer',
            textDecoration: 'underline',
            fontFamily: 'var(--regular)',
            fontSize: 12,
          }}
          onClick={() => onNameClick(record.id, record.company)}
        >
          {text}</div>
      ),
    },
    {
      title: <TableHeaderTitle>Email Adress</TableHeaderTitle>,
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      sorter: false,
      render: (text, record) => (
        <div
          style={{
            fontFamily: 'var(--regular)',
            fontSize: 12,
          }}
        >
          {text}</div>
      ),
    },
    {
      title: <TableHeaderTitle>Last Activity</TableHeaderTitle>,
      width: '30%',
      dataIndex: 'last_login',
      key: 'last_login',
      sorter: false,
      render: (text, record) => (
        <div
          style={{
            fontFamily: 'var(--regular)',
            fontSize: 12,
          }}
        >
          {text}</div>
      ),
    },
    {
      title: <TableHeaderTitle>User Type</TableHeaderTitle>,
      dataIndex: 'account_type',
      key: 'account_type',
      width: '20%',
      sorter: false,
      render: (text, record) => (
        <div
          style={{
            fontFamily: 'var(--regular)',
            fontSize: 12,
          }}
        >
          {text === 'admin' ? 'Admin' : 'Staff'}</div>
      ),
    },
  ]


  useEffect(() => {
    const users = allUsersPage === 'all'
      ? store?.cmsAllUsers
      : allUsersPage === 'companyUsers'
        ? store?.cmsCompanyUsers
        : allUsersPage === 'admins' ? store?.cmsAdminsUsers : []
    const areAllBulkActionsSelected = users?.every((record) =>
      watchBulkActions[`${record.id}-bulk_users_actions`] === true
    );
    setIsHeaderCheckboxChecked(areAllBulkActionsSelected);
  }, [watchBulkActions]);

  useEffect(() => {
    allUsersPage !== 'all' && getCompanyUsers()
  }, [])

  useEffect(() => {
    allUsersPage === 'all' && getCompanyUsers()
    setSelectedBulkActions([])
    setSelectedUsersNames([])
  }, [page])

  useEffect(() => {
    getCompanyUsers()
  }, [store?.cmsUsersSearch, selectedFilters])

  const getFile = () => {
    const cookies = new Cookies()
    const csrftoken = cookies.get('csrftoken')
    let token = cookies.get('pbm_token')
    const url = allUsersPage === 'all'
      ? `/cms/users/export_to_xlsx/`
      : allUsersPage === 'admins'
        ? '/cms/users/export_to_xlsx/?admin_access=true&staff_access=true'
        : `/cms/companies/${params.id}/export_users_to_xlsx/`
    fetch(
      `${process.env.REACT_APP_API_DEV}${url}`,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${token}`,
          'X-CSRFToken': csrftoken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `users.xlsx`)
        document.body.appendChild(link)

        link.click()

        link.parentNode.removeChild(link)
      })
  }


  return (
    <form >
      {visible && <RemoveUserModal
        allUsersPage={allUsersPage}
        onClose={() => setVisible(false)}
        onSubmit={() => onDeleteUser()}
        userName={selectedUsersNames} />
      }
      {errorModalVisible && <ErrorUserModal
        onClose={() => setErrorModalVisible(false)}
        errorUsers={errorUsers}
        setErrorUsers={setErrorUsers}
      />}
      <Search allUsersPage={allUsersPage} setPage={setPage} setSelectedUsersNames={setSelectedUsersNames} />
      <Divider />
      {allUsersPage !== 'admins' &&
        <Filters
          setPage={setPage}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          selectedEditionItems={selectedEditionItems}
          setSelectedEditionItems={setSelectedEditionItems}
          onEdit={(option) => onEdit(option)}
          disabled={selectedBulkActions.length === 0}
          allUsersPage={allUsersPage}
        />
      }

      {!loading &&
        <StyledTable
          style={{ marginTop: '1em' }}
          bordered
          columns={allUsersPage === 'all'
            ? allUsersColumns
            : allUsersPage === 'companyUsers'
              ? columns
              : allUsersPage === 'admins' ? adminUsersColumns : columns
          }
          dataSource={allUsersPage === 'all'
            ? store?.cmsAllUsers
            : allUsersPage === 'companyUsers'
              ? store?.cmsCompanyUsers
              : allUsersPage === 'admins' ? store?.cmsAdminsUsers : store?.cmsCompanyUsers}
          rowKey={'id'}
          size='small'
          onChange={(pagination, _, sorter) => {
            setPage(pagination.current)
          }}
          pagination={allUsersPage === 'all' ? {
            hideOnSinglePage: false,
            pageSize: 50,
            position: ['bottomRight'],
            total: usersNumber,
            current: page,
            showSizeChanger: false,
          } : false}
        />}
      <UnderTableCover allUsersPage={allUsersPage}>
        {allUsersPage !== 'all' &&
          <ShowItem position={'left'}>
            {usersNumber} Users
          </ShowItem>}
        {allUsersPage === 'all' &&
          <ItemsShowing
            page={page}
            pageSize={50}
            total={usersNumber}
            position={'right'}
            adminPanel={true}
            itemsType={'Users'}
            style={{
              textAlign: 'right',

              paddingRight: 10,
              width: 'fit-content',
              marginBottom: 10,
            }}
          />
        }

        <div
          onClick={() => getFile()}
          style={{
            color: '#225194',
            cursor: 'pointer',
            textDecoration: 'underline',
            fontFamily: 'var(--regular)',
            fontSize: 10,
            marginLeft: '1em'
          }}
        >Export to XLSX</div>

      </UnderTableCover>
      {allUsersPage === 'companyUsers' &&
        <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            text={'Save'}
            disabled={!store.cmsCompanyUsers?.length}
            onClick={() => finish()}
            type={'main'}
            style={{
              width: '80px',
              padding: 0,
              marginRight: 0,
              marginLeft: '.5em',
              marginTop: '2.3em',
            }}
          />
        </Row>
      }
    </form>
  )
});

export default UsersTab;