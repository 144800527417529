import { Table as AntTable } from 'antd'
import { observer } from 'mobx-react-lite'
import { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useStore } from '../../../../mobx-store/context'
import { TableWrap } from '../../ResourceTable/style.js'
import { api } from '../../../../api'
import { getJurisdictionCode, modifyDescription } from '../../../../helpers/helpers.js'
import { Title } from '../Header/style.js'
const Table = observer(() => {
  const history = useHistory()
  const store = useStore()
  const params = useParams()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const searchWord = localStorage.getItem('resource_search_word') || 'any'

  const getData = () => {
    setLoading(true)
    api(`/resource_guide/search/?page=${page}&page_size=${pageSize}&word=${searchWord}`, {}, 'GET').then((res) => {
      setData(res.results)
      setCount(res.count)
      setLoading(false)
    })
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    getData()
  }, [page])



  const isGuides = params.type === 'guides'


  const columns = [
    {
      title: 'Guide Name',
      dataIndex: 'guide_name',
      key: 'guide_name',
      // sorter: {
      //   compare: (a, b) => a.name.localeCompare(b.name),
      // },
      align: 'left',
      width: 550,
      render: (text, row) => (
        text
      ),
    },
    {
      title: 'Jursd.',
      dataIndex: 'jurisdiction',
      key: 'jurisdiction',
      width: 550,
      render: (text) => getJurisdictionCode(text, store),
      // sorter: {
      //   compare: (a, b) => a.rows[0].requirements - b.rows[0].requirements,
      // },
      align: 'center',
    },
    {
      title: 'Line of Business',
      dataIndex: 'line_of_business',
      key: 'line_of_business',
      width: 550,
      render: (text) => <div dangerouslySetInnerHTML={{
        __html: modifyDescription(store.lineOfBusinesses, text)
      }} />,
      // sorter: {
      //   compare: (a, b) => a.rows[0].requirements - b.rows[0].requirements,
      // },
      align: 'left',
    },
    {
      title: 'Requirements',
      dataIndex: 'requirements',
      key: 'requirements',
      width: 550,
      render: (text) => text,
      // sorter: {
      //   compare: (a, b) => a.rows[0].requirements - b.rows[0].requirements,
      // },
      align: 'left',
    },
    {
      title: 'Differentiator',
      dataIndex: 'differentiator',
      key: 'differentiator',
      width: 550,
      render: (text) => text,
      // sorter: {
      //   compare: (a, b) => a.rows[0].requirements - b.rows[0].requirements,
      // },
      align: 'left',
    },
    {
      title: 'Summary',
      dataIndex: 'summary',
      key: 'summary',
      width: 550,
      render: (text) => text,
      // sorter: {
      //   compare: (a, b) => a.rows[0].requirements - b.rows[0].requirements,
      // },
      align: 'center',
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      width: 550,
      render: (text) => text,
      // sorter: {
      //   compare: (a, b) => a.rows[0].requirements - b.rows[0].requirements,
      // },
      align: 'left',
    },
  ]


  // const onRowClick = (record) => {

  //   return {
  //     onClick: () => {
  //       if (!record.requirements && !record.rows?.[0]?.requirements) return
  //       store.setSingleValue('resourcesCurrent', record)
  //       if (isGuides) {
  //         history.push(
  //           `/resource/guides-jurisdictions-list/${params.type}/${record.id || record.jurisdiction
  //           }/${store.resourcesSelect?.value}/${record.jurisdiction}`
  //         )
  //       } else {
  //         store.getResourceTopic(record.id || record.jurisdiction, () => {
  //           history.push(
  //             `/resource/guides-jurisdictions-list/${params.type}/${record.id || record.jurisdiction
  //             }/${store.resourcesSelect?.value}`
  //           )
  //         }, null, store.resourcesSelect?.value)
  //       }
  //       store.setResourcePrevLink([
  //         ...store.resourcePrevLink,
  //         location.pathname,
  //       ])
  //     },
  //   }
  // }

  return (
    <div style={{ paddingBottom: 300 }}>
      <Title style={{fontSize: 24}}>
        Search Results for: <strong>{searchWord}</strong>
      </Title>
      <TableWrap searchTable={true}>
        <AntTable
          dataSource={data}
          columns={columns}
          onChange={(pagination, _, sorter) => {
            setPage(pagination.current)
          }}
          pagination={{
            position: ['bottomLeft'],
            pageSize,
            hideOnSinglePage: false,
            total: count,
            current: page,
            showSizeChanger: true,
          }}
        // rowClassName={(record) =>
        //   (record.requirements || record.rows?.[0]?.requirements) &&
        //   'active-row'
        // }
        // onRow={onRowClick}
        />
      </TableWrap>
    </div>
  )
})

export default Table