import styled from 'styled-components'


export const Title = styled('div')`
  font-family: var(--bold);
  font-size: 27px;
  margin-bottom: 5px;
  color: var(--blue);
`
export const SubTitle = styled('div')`
  font-family: var(--regular);
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 18px;
  margin-bottom: 20px;
  color: var(--text);
`
export const Wrap = styled('div')`
  display: grid;
  grid-template-columns: 1fr 0.3fr;
  border-bottom: 1px solid rgba(196, 202, 204, 0.6);
  padding-bottom: 20px;
  padding-top: 20px;
  margin-right: 15px;
`

export const NotificationArea = styled('div')`
  max-height: 80vh;
  overflow-y: auto;
  margin-bottom: 100px;
  &::-webkit-scrollbar {
    width: 8px;
    position: absolute;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: var(--grey);
    border-radius: 5px;
    width: 8px;
  }
`
export const NotificationTitle = styled('div')`
  font-size: 14px;
  font-family: var(--bold);
  color: var(--blue);
  & strong {
    color: var(--text);
  }
`
export const Text = styled('div')`
  font-size: 14px;
  font-family: var(--regular);
  color: var(--text);
  & strong {
    color: var(--text);
  }
  & b {
    color: var(--text);
    font-family: var(--bold);
  }
  & a {
    font-size: 14px;
  font-family: var(--bold);
  color: var(--blue);
  }
  & div {
    font-size: 14px;
  font-family: var(--bold);
  color: var(--blue);
  cursor: pointer;
  }
`
export const TimeStamp = styled('div')`
  font-size: 14px;
  font-family: var(--bold);
  color: var( --text);
`

export const MenuItem = styled('div')`
  font-size: 16px;
  font-family: var(--medium);
  color: var( --text);
  padding: 5px;
`