import React, { useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import { useStore } from '../../../../mobx-store/context';
import { DatePicker } from 'antd';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const { RangePicker } = DatePicker;

const InputDateRange = ({
  name,
  visible,
  onChange,
  reset,
  handleChange,
  disabled = false,
  getPopupContainer = false,
  value,
}) => {
  const store = useStore();
  const params = useParams();
  const [selectedDates, setSelectedDates] = useState([]);

  const localValue = useMemo(() => {
    if (value) {
      return [moment(value?.split('±')[0]), moment(value?.split('±')[1])];
    }
    return selectedDates;
  }, [value, selectedDates]);

  const parentNode = () => {
    if (getPopupContainer) {
      return (trigger) => trigger.parentNode.parentNode;
    }
    return false;
  };

  const handleReset = (allowHandleChange = false) => {
    const newSearchObj = params.tabName === 'myTasks' ? { ...store.searchableObjectTasks } : { ...store.searchableObject };

    delete newSearchObj.adopted_date_after;
    delete newSearchObj.adopted_date_before;
    delete newSearchObj.primary_due_date_before;
    delete newSearchObj.primary_due_date_after;
    delete newSearchObj.expiration_date_before;
    delete newSearchObj.expiration_date_after;
    delete newSearchObj.internal_due_date_before;
    delete newSearchObj.internal_due_date_after;
    delete newSearchObj.compliance_date_before;
    delete newSearchObj.compliance_date_after;

    params.tabName === 'myTasks'
      ? store.setSingleValue('searchableObjectTasks', newSearchObj)
      : store.setSingleValue('searchableObject', newSearchObj);

    reset(name);
    if (handleChange && allowHandleChange) handleChange();
    setSelectedDates([]);
  };

  useEffect(() => {
    if (value === null || value === undefined) {
      handleReset();
    }
  }, [value]);

  return (
    <RangePicker
      disabled={disabled}
      format="MM/DD/YYYY"
      disabledDate={(d) =>
        !d || d.isAfter('2030-12-31') || d.isSameOrBefore('2022-01-01')
      }
      value={localValue}
      open={visible}
      dropdownClassName={'dateRangePicker'}
      placeholder={['MM/DD/YYYY Start Date', 'MM/DD/YYYY End Date']}
      getPopupContainer={parentNode()}
      bordered={false}
      allowEmpty={[false, true]}
      onCalendarChange={(dates) => {
        if (dates && dates.length === 1) {
          setSelectedDates(dates);
        } else if (dates && dates.length === 2) {
          setSelectedDates(dates);
        }
      }}
      onOpenChange={(isOpen) => {
        if (!isOpen && selectedDates.length === 1) {
          handleReset(true);
        }
      }}
      onChange={(dates, dateStrings) => {
        if (!dates || dates.length === 0) {
          handleReset(true);
        } else if (dates.length === 2) {
          if (dateStrings[1] !== '') {
            onChange(`${dateStrings[0]}±${dateStrings[1]}`);
            if (handleChange) handleChange(`${dateStrings[0]}±${dateStrings[1]}`);
          }
        }
      }}
    />
  );
};

export default InputDateRange;