import styled from 'styled-components';

export const ItemWrap = styled('div')`
  width: 50%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #969FA2;
  border-radius: 6px;
  justify-content: flex-start;
  padding: 12px 16px;
`
export const ItemText = styled('div')`
  font-family: var(--regular);
  font-size: 14px;
  color: var(--text);
  letter-spacing: 0.3px;
  `
  export const Cancel = styled('div')`
  font-family: var(--regular);
  text-decoration: underline;
  cursor: pointer;
`