import React, { useEffect, useState } from 'react'
import { useGA4React } from 'ga-4-react'
import { useHistory, useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useStore } from '../../mobx-store/context'

const EmailConfirmForGA = () => {
  const store = useStore()
  const location = useLocation()
  const ga4React = useGA4React()
  const history = useHistory()
  const [trigerred, setTrigerred] = useState(false)
  const [cookies, setCookie] = useCookies(['pbm_token', 'userId'])

  const getToken = () => {
    const query = new URLSearchParams(location.search)
    return query.get('token')
  }

  useEffect(() => {
    if (ga4React && !trigerred) {
      setTrigerred(true)
      ga4React?.gtag('event', 'account_validated')
      if (!cookies.pbm_token) {
        setCookie('pbm_token', getToken(), { path: '/' })
        store.getCompany()
      }
      history.push('/businessInfo')
    }
  }, [ga4React])

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
        fontFamily: 'var(--bold)',
        fontSize: 48,
        textAlign: 'center',
      }}
    >
      Email confirmed, redirecting...
    </div>
  )
}

export default EmailConfirmForGA
