import styled from 'styled-components'

export const Wrap = styled('form')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 15px;
`
export const PageWrap = styled('div')`
  width: 100%;
  // padding-top: 90px;
  padding-bottom: 200px;
  // margin: 0 auto;
  min-height: calc(100vh - 50px);
`
export const AddPageWrap = styled('div')`
  width: 100%;
  padding-top: 90px;
  padding-bottom: 200px;
  margin: 0 auto;
  min-height: calc(100vh - 50px);
`

export const AddButton = styled('div')`
  cursor: pointer;  
  font-size: 16px;
  font-family: var(--bold);
  color: var(--blue);
`