import styled from 'styled-components'

export const Wrap = styled("div")`
  margin-bottom: 30px;
`;

export const ItemsWrap = styled("div")`
  max-height: 20vh;
  overflow-y: auto;
`;

export const Item = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: 20px;
  font-size: 12px;
  font-family: var(--regular);
  color: var(--text);
  img {
    width: 7px;
    height: 7px;
    cursor: pointer;
  }
`;

export const SelectBLock = styled("div")`
  display: flex;
  margin-top: 15px;
  .ant-select-selector {
    height: 20px !important;
  }
  .ant-select-selection-search-input {
    height: 20px !important;
  }
  .ant-select {
    height: 20px;
    width: 100%;
  }
  .ant-select-selection-item {
    height: 20px;
    line-height: 20px !important;
    font-size: 12px !important;
  }
  .ant-select-selection-search {
    height: 20px;
  }
`;
