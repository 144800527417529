import styled from 'styled-components'

export const Wrap = styled('div')`
  margin-bottom: 20px;
`

export const Button = styled('div')`
  font-family: var(--bold);
  font-size: 14px;
  color: var(--text);
  text-decoration: underline;
  cursor: pointer;
`
