import { observer } from 'mobx-react-lite'
import React from 'react'
import Moment from 'react-moment'
import PlainText from '../../../../components/common/PlainText'
import { useStore } from '../../../../mobx-store/context'
import { Title } from '../../components/common/style'
import { CustomRadioGroup } from '../Email/style'
import AutoRenewal from '../ProductSettings/autoRenewal'
import { BlockWrap } from './style'

const DateRange = observer(() => {
  const store = useStore()

  return (
    <BlockWrap>
      {(store.company[0].trial_end || store.company[0].next_contract_date) &&
        <>
          <Title>Current Commitment</Title>
          <PlainText>
            Your current commitment started on{' '}
            <span style={{ fontFamily: 'var(--semiBold)' }}>
              <Moment format={'MM/DD/YY'}>{store.company[0].start_date}</Moment>
            </span>{' '}
            and ends on{' '}
            <span style={{ fontFamily: 'var(--semiBold)' }}>
              <Moment format={'MM/DD/YY'}>
                {store.company[0]?.payment_status === 'trial'
                  ? store.company[0].trial_end
                  : store.company[0].next_contract_date}
              </Moment>
            </span>
            .
          </PlainText>

          <Title style={{ fontSize: '1em', marginTop: 20 }}>Auto Renew</Title>
          <CustomRadioGroup style={{ marginBottom: 0 }}>
            <AutoRenewal />
          </CustomRadioGroup>
        </>
      }
    </BlockWrap>
  )
})

export default DateRange
