import { useEffect, useState } from "react";
import Modal from "../../../../../../components/common/ModalNew";
import Cookies from 'universal-cookie'
import { Title, Button } from "./style";
import { useForm } from "react-hook-form";
import Input from "../../../../../../components/common/Input";
import { useGA4React } from 'ga-4-react'
import { api } from "../../../../../../api";
import { useStore } from "../../../../../../mobx-store/context";
import { useHistory } from "react-router-dom/";
import { message } from "antd";
const AddSingleUsersModal = ({ onClose, name, companyId }) => {
  const {
    control,
    handleSubmit,
    getValues,
    reset
  } = useForm()
  const history = useHistory()
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const ga4React = useGA4React()

  const store = useStore()

  const onCreate = (values) => {
    setLoading(true)

    const data = {
      email: values.email,
      company: localStorage.getItem('editedCmsCompanyId'),
    }

    api('/cms/users/', data, 'POST').then((res) => {
      if (res.error) {
        message.error(res.error)
      } else {
        store.setSingleValue('editedCmsUser', res)
        localStorage.setItem('newCompanyUser', true)
        history.push(`/dashboard-edit-user/${res.id}`)
      }
    })
  }

  return (
    <Modal
      styleWrap={{ width: 500, padding: 36 }}
      onClose={onClose}>
      <Title>Enter email for new user</Title>
      <Input
        type='email'
        name='email'
        control={control}
        validation={{ required: true }}
        placeholder='User Email'
        style={{ marginTop: 25 }}
      />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 25, alignItems: 'center' }}>
        <div onClick={() => { onClose() }}
          style={{ fontSize: 15, fontFamily: 'var(--regular)', color: 'var(--blue)' }}
        >
          Go Back
        </div>
        <Button onClick={handleSubmit(onCreate)}>
          Add User
        </Button>
      </div>
    </Modal>
  )
}

export default AddSingleUsersModal;