import React, { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import { useStore } from '../../../../../../mobx-store/context';
import { TableHeaderTitle, TableStyled } from './style';
import CustomizeBlock from './CustomizeBlock/customizeBlock';
import { blocksTracking, blocksReportSource } from './CustomizeBlock/static';

const EmailSettingsTable = ({
    initialSettings,
    initialTaskSettings,
    initialValues,
    state,
    setState,
    titles,
    isTaskTable = false,
    mainSettings = true,
    site,
    userId,
    isProposedSource = false,
    isReportSource = false,
    isLicenseSource = false,
}) => {

    const store = useStore()
    const [expandedRowKey, setExpandedRowKey] = useState(null);
    const [headerState, setHeaderState] = useState({ right_away: false, end_of_day: false, never: false });

    const handleRadioChange = (key, value) => {
        setState(prevState => ({ ...prevState, [key]: value }));
    };

    const handleHeaderCheckboxChange = (value, checked) => {
        const newState = { ...state };
        Object.keys(newState).forEach(key => {
            newState[key] = checked ? value : newState[key];
        });
        setState(newState);
    };

    const expandedContent = (record) => {

        if (record.name === 'New compliance item added' || record.name === 'New report items being tracked') {
            return (
                <div>
                    <div>Select data type you want to receive emails by:</div>
                    <div style={{ display: 'flex', marginTop: 10 }}>
                        {isReportSource || isLicenseSource
                            ? blocksReportSource.map((item) => (
                                <CustomizeBlock
                                    name={item.name}
                                    restrictions={item.restrictions}
                                    type={item.type}
                                    title={item.title}
                                    userId={userId} />
                            ))
                            : blocksTracking.map((item) => (
                                <CustomizeBlock
                                    name={item.name}
                                    restrictions={item.restrictions}
                                    type={item.type}
                                    title={item.title}
                                    userId={userId}
                                />
                            ))
                        }
                    </div>
                </div>
            );
        }
        return null;
    };
    const handleExpandClick = (record) => {
        if (expandedRowKey === record.key) {
            setExpandedRowKey(null);
        } else {
            if (record.name === 'New compliance item added' || record.name === 'New report items being tracked') {
                setExpandedRowKey(record.key);
            }

        }
    };

    const columns = [
        {
            title: <TableHeaderTitle>{titles[0]}</TableHeaderTitle>,
            dataIndex: 'name',
            key: 'name',
            width: '45%',
            align: 'left',
            render: (text, record) => {
                if ((record.name === 'New compliance item added' || record.name === 'New report items being tracked') && !site) {
                    return (
                        <>
                            {text}{' '}
                            (<a onClick={() => handleExpandClick(record)}>
                                customize
                            </a>)
                        </>
                    )
                }
                return text;
            }

        },
        {
            title: <TableHeaderTitle>
                <Checkbox
                    checked={headerState.right_away}
                    onChange={(e) => handleHeaderCheckboxChange('right_away', e.target.checked)}
                    style={{ marginRight: 10 }}
                />
                Right Away</TableHeaderTitle>,
            key: 'right_away',
            width: '20%',
            align: 'left',
            render: (_, record) => (
                <Checkbox
                    checked={state[record.key] === 'right_away'}
                    onChange={() => handleRadioChange(record.key, 'right_away')}
                />
            )
        },
        {
            title: <TableHeaderTitle>
                <Checkbox
                    checked={headerState.end_of_day}
                    onChange={(e) => handleHeaderCheckboxChange('end_of_day', e.target.checked)}
                    style={{ marginRight: 10 }}
                />End Of Day</TableHeaderTitle>,
            key: 'end_of_day',
            width: '20%',
            align: 'left',
            hidden: site,
            render: (_, record) => (
                <Checkbox
                    checked={state[record.key] === 'end_of_day'}
                    onChange={() => handleRadioChange(record.key, 'end_of_day')}
                />
            )
        },
        {
            title: <TableHeaderTitle>
                <Checkbox
                    checked={headerState.never}
                    onChange={(e) => handleHeaderCheckboxChange('never', e.target.checked)}
                    style={{ marginRight: 10 }}
                />
                Never</TableHeaderTitle>,
            key: 'never',
            width: '20%',
            align: 'left',
            hidden: isProposedSource && !site,
            render: (_, record) => (
                <Checkbox
                    checked={state[record.key] === 'never'}
                    onChange={() => handleRadioChange(record.key, 'never')}
                />
            )
        },
    ];

    const tasksColumns = [
        {
            title: <TableHeaderTitle>{titles[1]}</TableHeaderTitle>,
            dataIndex: 'name',
            key: 'name',
            width: '45%',
        },
        {
            title: <TableHeaderTitle>Right Away</TableHeaderTitle>,
            key: 'right_away',
            width: '20%',
            align: 'left',
            render: (_, record) => (
                <Checkbox
                    checked={state[record.key] === 'right_away'}
                    onChange={() => handleRadioChange(record.key, 'right_away')}
                />
            )
        },
        {
            title: <TableHeaderTitle>End Of Day</TableHeaderTitle>,
            key: 'end_of_day',
            width: '20%',
            align: 'left',
            hidden: site,
            render: (_, record) => (
                <Checkbox
                    checked={state[record.key] === 'end_of_day'}
                    onChange={() => handleRadioChange(record.key, 'end_of_day')}
                />
            )
        },
        {
            title: <TableHeaderTitle>Never</TableHeaderTitle>,
            key: 'never',
            width: '20%',
            align: 'left',
            render: (_, record) => (
                <Checkbox
                    checked={state[record.key] === 'never'}
                    onChange={() => handleRadioChange(record.key, 'never')}
                    disabled={record.disabled}
                />
            )
        },
    ];
    const visibleColumns = columns.filter((column) => !column.hidden);
    const visibleTaskColumns = tasksColumns.filter((column) => !column.hidden);

    useEffect(() => {
        setState(initialValues);
        if (isReportSource
            ? store?.customize_emails?.report_jurisdictions.length || store?.customize_emails?.report_types.length
            : store?.customize_emails?.compliance_jurisdictions.length || store?.customize_emails?.compliance_topics.length) {
            setExpandedRowKey(initialSettings[0]?.key);
        }
    }, []);

    useEffect(() => {
        const newHeaderState = { right_away: true, end_of_day: true, never: true };
        Object.entries(state).forEach(([key, value]) => {
            if (value !== "right_away") newHeaderState.right_away = false;
            if (value !== "end_of_day") newHeaderState.end_of_day = false;
            if (value !== "never") newHeaderState.never = false;
        });
        setHeaderState(newHeaderState);
    }, [state]);

    return (
        <>
            {mainSettings && site &&
                <TableStyled
                    columns={visibleColumns}
                    dataSource={initialSettings}
                    pagination={false}
                />}
            {mainSettings && !site &&
                <TableStyled
                    columns={visibleColumns}
                    dataSource={initialSettings}

                    pagination={false}
                    expandedRowRender={isProposedSource ? false : expandedContent}
                    expandedRowKeys={expandedRowKey ? [expandedRowKey] : []}
                    onExpand={(expanded, record) => handleExpandClick(record)}
                    expandable={{
                        expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
                        expandIcon: () => null,
                    }}
                />}
            {isTaskTable &&
                <div style={{ marginTop: 20 }}>
                    <TableStyled columns={visibleTaskColumns} dataSource={initialTaskSettings} pagination={false} />
                </div>
            }
        </>
    )
}

export default EmailSettingsTable;
