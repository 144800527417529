import styled from 'styled-components'

export const Wrap = styled('form')`
  margin-top: 1em;
`

export const Row = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 26px;
  align-items: end;
  margin-bottom: 1em;
`
export const PrevBtn = styled('div')`
  font-size: 14px;
  font-family: var(--bold);
  color: var(--blue);
  text-decoration: underline;
  cursor: pointer;
`
export const Title = styled('div')`
  font-size: 14px;
  font-family: var(--bold);
  color: var(--Text);
  margin-bottom: 5px;
`
export const SubTitle = styled('div')`
  font-size: 12px;
  font-family: var(--regular);
  color: var(--Text);
`

