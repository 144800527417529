import styled from 'styled-components'
import { query500 } from '../../../pages/Home/helpers'
import { Link } from 'react-router-dom'

export const Image = styled.img`
  width: ${(props) => (props.large ? '320px' : '202px')};
@media${query500} {
  width: 201px !important;
  height: auto !important;
}
`

export const Wrap = styled(Link)`
`
