import styled from 'styled-components'
import { Text } from '../../components/style'
import { Wrap as TableContainer } from '../../Resource/RightSide/style'
import { TableWrap as Wraped } from '../../../ResourceMain/components/style'

export const Row = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 15px;
  div {
    max-width: 300px;
    ${Text} {
      font-size: 14px;
      &:first-child {
        font-family: var(--semiBold);
      }
    }
  }
`
export const Wrap = styled('div')`
padding-right: 1em;
padding-bottom: 1em;
max-height: 74vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    position: absolute;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: var(--grey);
    border-radius: 5px;
    width: 8px;
  }

  // @media (max-width: 1350px) {
  //   padding-left: 0;
  //   padding-top: 2em;
  //   padding-right: 2em;
  // }
  @media (max-height: 1200px) {
    max-height: 76vh;
  }
  @media (max-height: 1050px) {
    max-height: 73vh;
  }
  @media (max-height: 915px) {
    max-height: 66vh;
  }
  @media (max-height: 880px) {
    max-height: 71vh;
  }
  @media (max-height: 800px) {
    max-height: 65vh;
  }
  @media (max-height: 750px) {
    max-height: 65vh;
  }
`

export const Table = styled(TableContainer)`

  
  .ant-table-cell {
    border-right: 0;
    font-family: var(--medium);
  }
  .ant-table-row,
  .ant-table-thead {
    height: 45px;
  }
  .ant-table-container {
    margin-bottom: 10%;
    border: 0;
  }
  .ant-table-body {
    &::-webkit-scrollbar {
      width: 8px;
      position: absolute;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: var(--grey);
      border-radius: 5px;
      width: 8px;
    }
  }
  .ant-table-row {
    &:nth-child(even) {
      background-color: rgba(34, 81, 148, 0.05);
    }
  }
`

export const TableWrap = styled(Wraped)`
tbody {
  .ant-table-cell {
    padding-left: 20px !important;
  }
}
.ant-table-thead th.ant-table-column-has-sorters {
  padding: 0 6px !important;
}
tbody {
  tr {
    .ant-table-cell {
      &:last-child {
        border-right: 1px solid #c4cacc;
      }
    }
  }
}
.ant-table-thead {
  tr {
    th {
      background-color: #fff !important;
       position: sticky;
       top: 0px; 
      z-index: 99;
    }
  }
}
tbody {
  tr {
    .ant-table-cell {
      &:first-child {
        border-left: 1px solid #c4cacc;
      }
    }
  }
}
  
`
