import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Input from '../../../../../components/common/Input'
import { useStore } from '../../../../../mobx-store/context'
import ResourceModal from '../../../components/ResourceAccessModal/resourceModal'
import { reportSourceInputs } from './inputs'
import { api } from '../../../../../api'

const ReportSourceTab = ({
  control,
  errors,
  showFields,
  memberType,
  register,
  active,
  handleShowField,
}) => {
  const store = useStore()
  const history = useHistory()
  const user = store.user?.[0]
  const company = store.company?.[0]

  useEffect(() => {
      company.have_acess_to_report_source &&
      api('/report_types/', {}, 'GET').then((data) => {
        store.setSingleValue('reportTypes', data)
      })
  }, [])

  useEffect(() => {
    store.getLegislativeOptions()
  }, [])

  const { name, label, type, customOptions, placeholder, validation, error } =
    reportSourceInputs(store, errors, showFields)[0]

  if (!user?.have_access_to_report_source || !company?.have_access_to_report_source)
    return (
      <ResourceModal
        type='reportSource'
        to='/edit-profile'
        onClose={() => history.push('/edit-profile')}
      />
    )

  return (
    <>
      <Input
        name={name}
        label={label}
        type={type}
        control={control}
        customOptions={customOptions}
        placeholder={placeholder}
        validation={validation}
        error={error}
        handleChange={handleShowField}
      />
      <input
        type='hidden'
        name='have_access_to_report_source'
        value={true}
        ref={register}
      />
      {reportSourceInputs(store, errors, showFields).map(
        ({
          name,
          label,
          type,
          options,
          placeholder,
          validation,
          error,
          showFields,
          key,
          transparent,
          style,
          loading,
        }) =>
          showFields &&
          memberType &&
          memberType[active] !== 'member' && (
            <Input
              name={name}
              label={label}
              error={error}
              type={type}
              control={control}
              validation={validation}
              key={key}
              transparent={transparent}
              style={style}
              placeholder={placeholder}
              options={options}
              loading={loading}
            />
          )
      )}
    </>
  )
}

export default ReportSourceTab
