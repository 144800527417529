import styled from 'styled-components'


export const Wrap = styled("div")`
width: 100%;
height: 90px;
background-color: #fff;
display: grid;
grid-template-columns: 1fr 300px;
align-items: center;
padding: 0 25px;
position: fixed; 
bottom: 10px;
z-index: 10; 
@media (max-width: 950px) {
  display: block;
  height: auto;
  padding: 15px;
}
@media (max-width: 650px) {
  flex-direction: column;
  justify-content: center;
}
`;

export const Text = styled("div")`
font-family: var(--medium);
color: var(--text);
font-size: 16px;
line-height: 18px;
letter-spacing: -0.4px;
align-items: center;
margin: 0 auto;
justify-content: center;
color: #455154;;
margin: 13px;
`;

export const Buttons = styled('div')`
  display: flex;
  font-family: var(--medium);
  font-size: 16px;
  align-items: center;
  @media (max-width: 950px) {
    margin-top: 15px;
  }
  a {
    color: var(--blue);
    padding: 0 15px;
    height: 15px;
    display: flex;
    align-items: center;
    &:first-child {
      border-right: 2px solid var(--blue);
      @media (max-width: 950px) {
        padding-left: 0;
      }
    }
  }
`;
export const MessageText = styled("div")`
font-family: var(--medium);
font-size: 26px;
line-height: 18px;
letter-spacing: -0.4px;
color: #455154;
margin-top: 24px;
margin-left: 24px;
margin-right: 24px;
`;

export const DescriptionText = styled("div")`
font-family: var(--light);
font-size: 16px;
line-height: 18px;
letter-spacing: -0.4px;
color: #455154;
margin-top: 20px;
margin-left: 24px;
margin-right: 20px;
margin-bottom: 0px;
`;

