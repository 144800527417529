import React, { useState } from 'react'
import { Icon } from '../input.style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import InputMask from 'react-input-mask'

const InputPassword = ({
  handleChange,
  onChange,
  placeholder,
  value,
  handleBlur,
  mask,
  styleInput,
  disabled,
}) => {
  const [password, setPassword] = useState(true)

  return <>
    <InputMask
      mask={mask}
      value={value || ''}
      onChange={(e) => {
        onChange(e.target.value || undefined)
        handleChange && handleChange()
      }}
      onBlur={() => handleBlur && handleBlur()}
      maskChar={null}
      placeholder={placeholder}
      disabled={disabled}
      style={styleInput}
      type={password ? 'password' : 'text'}
    />
    <Icon>
      <FontAwesomeIcon
        icon={password ? faEye : faEyeSlash}
        onClick={() => setPassword(!password)}
      />
    </Icon>
  </>
}

export default InputPassword
