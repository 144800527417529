import React from 'react'
import { Container, Logo, Text, Title, Wrap } from './style'
import logo from '../../assets/images/about-logo.svg'
import Team from './Team'
import Form from './Form'

const AboutPage = () => {
  return (
    <Wrap>
      <Container>
        <Logo>
          <img src={logo} alt="logo-img" />
        </Logo>
        <Title style={{ marginBottom: 20, marginTop: 120 }}>
          The Story of PBMSource
        </Title>
        <Text style={{ marginBottom: 120 }}>
          At vero eos et accusamus et iusto odio dignissimos ducimus qui
          blanditiis praesentium voluptatum deleniti atque corrupti quos dolores
          et quas molestias excepturi sint occaecati cupiditate non provident,
          similique sunt in culpa qui officia deserunt mollitia animi, id est
          laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita
          distinctio. Nam libero tempore, cum soluta nobis est eligendi optio
          cumque nihil impedit quo minus id quod maxime placeat facere possimus,
          omnis voluptas assumenda est, omnis dolor repellendus.{" "}
        </Text>
        <Title style={{ marginTop: 90 }}>Meet Our Team</Title>
      </Container>
      <Team />
      <Form />
    </Wrap>
  );
};

export default AboutPage;
