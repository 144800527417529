import React from 'react'
import { Wrap } from './style'

const ItemsShowing = ({ page, pageSize, total, style, text, position, adminPanel, itemsType = 'Companies' }) => {
  if (!total) return null

  const itemsDisplaying = page * pageSize - pageSize

  const itemsDisplayingMax =
    pageSize >= total ? total : +itemsDisplaying + +pageSize

  return (
    <Wrap style={style} position={position}>
      {adminPanel ? '' : 'Displaying items'} {pageSize >= total ? 1 : itemsDisplaying || 1}-
      {itemsDisplayingMax < total ? itemsDisplayingMax : total} of {total} {adminPanel ? itemsType : ''}
      {text && <div style={{ color: '#969FA2' }}>{text}</div>}
    </Wrap>
  )
}

export default ItemsShowing
