import styled from 'styled-components'

export const Wrap = styled("div")``;

export const Title = styled("div")`
  font-size: 0.875em;
  font-family: var(--semiBold);
  color: var(--text);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 5px;
  span {
    font-family: var(--regular);
  }
`;

export const Row = styled("div")`
  display: flex;
  justify-content: space-between;
`;
