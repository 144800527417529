import React from 'react'
import { Link } from 'react-router-dom'
import { ContainerGlobal, TitleGlobal } from '../styles'
import {
  ContactUsTitle,
  Description,
  LeftSide,
  Links,
  MailButton,
  Rights,
  RightSide,
  Title,
  Wrap,
} from './style'

const Footer = ({productPage}) => {
  const currentYear = new Date().getFullYear();
  return (
    <Wrap>
      <ContainerGlobal>
        <LeftSide>
          <Title>Get Compliant</Title>
          <TitleGlobal style={{ color: '#fff', fontSize: 'max(32px)' }}>
          Effective compliance starts with knowing the requirements that apply to you, 
          followed by collaboration and documentation. 
          PBMSource provides everything in one simple place.
          </TitleGlobal>
        </LeftSide>
        <RightSide>
          <Description>
            PBMSource, LLC <br /> 3302 E. Indian School Road
            <br /> Phoenix, AZ 85018
            <br />
            Phone: 888-684-1391
            <br /> Email: contact@pbmsource.com
          </Description>
          <MailButton href={'mailto:contact@pbmsource.com'}>
            {productPage? 'Reach Out': 'Contact Us'}
          </MailButton>
        </RightSide>
      </ContainerGlobal>
      <ContainerGlobal>
        <Rights>
          <div style={{ fontSize: 14 }}>
            PBMSource is not a law firm or a substitute for an attorney or law
            firm. This website is for informational purposes. Use of this
            website does not constitute legal advice, nor does it create an
            attorney/client relationship. © PBM Source {currentYear} All rights reserved.
          </div>
          <Links>
            <Link to='/terms-and-privacy'>Privacy Policy</Link>
            <Link to='/terms-and-privacy'>Terms & Conditions</Link>
          </Links>
        </Rights>
      </ContainerGlobal>
    </Wrap>
  )
}

export default Footer
