import styled from 'styled-components'
import { Row as Input } from '../../../../components/common/Input/input.style'

export const Wrap = styled('div')`
  ${Input} {
    margin-bottom: 15px !important;
  }
`

export const Row = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1px 1fr 1fr;
  gap: 10px;
  align-items: flex-end;

  ${Input} {
    margin-bottom: 0 !important;
  }
`
