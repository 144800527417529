import { Select } from 'antd'
import { observer } from 'mobx-react-lite'
import angle from '../../../../assets/images/angle-down.svg'
import { useStore } from '../../../../mobx-store/context'

const InputSelect = observer(
  ({
    allowClear = true,
    defaultValue,
    placeholder,
    disabled,
    options,
    handleChange,
    onChange,
    value,
    loading,
    colorArrow,
    popupContainer,
    customOptions,
    selectVars,
    name,
    dropdownStyle,
    showSearch,
    showDeprecated,
    allowDisabled,
    showDeprecatedLabel,
    onlyEnabled,
    getPopupContainer = false,
  }) => {
    const store = useStore()

    const mutatedOptions = options
      ?.filter((item) => {
        if (!item.is_system) {
          return showDeprecated ? item.id : !item.is_deprecated
        } else {
          return (
            (showDeprecated ? item.id : !item.is_deprecated) &&
            (onlyEnabled ? item.enabled : true)
          )
        }
      })
      ?.map((item, index) =>
        option({
          item,
          selectVars,
          index,
          disabled: item.disabled || !item.enabled,
          allowDisabled,
          showDeprecatedLabel,
        })
      )

    const parentNode = () => {
      if (getPopupContainer) {
        return (trigger) => trigger.parentNode.parentNode.parentNode
      }
      return false
    }
    
    return (
      <Select
        allowClear={allowClear}
        style={{ width: '100%' }}
        defaultValue={defaultValue}
        placeholder={placeholder}
        disabled={disabled}
        showSearch={showSearch}
        dropdownClassName={options?.length > 12 && 'select-scrollbar-visible'}
        dropdownStyle={{ zIndex: 999, ...dropdownStyle }}
        onChange={(value) => {
          onChange(value)
          if (handleChange) handleChange(value)
        }}
        showArrow={true}
        listHeight={380}
        onClear={() => {
          const newSearchObj = store.searchableObject

          delete newSearchObj[name]

          store.setSingleValue('searchableObject', newSearchObj)
        }}
        value={value}
        loading={loading}
        suffixIcon={colorArrow && <img src={angle} alt={'#'} />}
        getPopupContainer={parentNode()}
        filterOption={(input, option) => {
          if (Array.isArray(option.children)) {
            return option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        }
      >
        {customOptions || mutatedOptions}
      </Select>
    )
  }
)

export default InputSelect

export const names = [
  'jurisdictions',
  'topics',
  'lines_of_business',
  'jurisdiction',
]

export const checkIfDisabled = ({ name, store, item }) => {
  if (!names.includes(name)) return false

  return !store.company?.[0]?.[
    name === 'jurisdiction' ? 'jurisdictions' : name
  ].includes(item.id)
}

export const option = ({
  item,
  selectVars,
  index,
  disabled,
  showDeprecatedLabel,
  allowDisabled,
}) => (
  <Select.Option
    value={item[selectVars?.value] ?? item.id}
    key={item[selectVars?.value] || item.id || index}
    disabled={item.disabled || (allowDisabled && disabled)}
  >
    {item[selectVars?.name] || item.name}{' '}
    {(!item.enabled || item.is_deprecated) &&
      showDeprecatedLabel &&
      ' (Deprecated)'}
  </Select.Option>
)
