import styled from 'styled-components'

export const Wrap = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  font-size: 16px;
  font-family: var(--regular);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`

export const Scrollable = styled('div')`
  max-height: 300px;
  overflow: auto;
  overflow-x: hidden;
`
