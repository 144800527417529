import { useEffect, useState } from 'react'
import { api } from '../api'

export const useProposedLegistlationList = (searchObj, archive) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [items, setItems] = useState([])
  const [count, setCount] = useState(0)
  const [hasMore, setHasMore] = useState(false)
  const [update, setUpdate] = useState(false)
  const [subscription, setSubscription] = useState(null)

  const resetItems = () => {
    setUpdate((prev) => !prev)
  }

  useEffect(() => {
    setItems([])
  }, [searchObj.searchString, update, archive])

  useEffect(() => {
    setLoading(true)
    setError(false)
    const controller = new AbortController();
    const { signal } = controller;
    api(
      `/proposed_source/?${searchObj.searchString}&page=${searchObj.pageNumber}${archive ? '&archived=true' : ''}`,
      { signal },
      'GET'
    ).then(res => {
      setSubscription(res.subscribed_to_all_proposed_source_items)
      setItems(prevItems => {
        return ([...prevItems, ...res.results])
      })
      setCount(res.count)
      setHasMore(Boolean(res.next))
      setLoading(false)
    }).catch(e => {
      setError(true)
    })
    controller.abort()
  }, [searchObj.searchString, searchObj.pageNumber, update, archive]);

  return { loading, error, items, count, hasMore, resetItems, subscription }
}