import styled from 'styled-components'
import { DescriptionGlobal, TitleGlobal } from '../../../../Compliance/styles'
import { query1300, query500 } from '../../../../helpers'

export const Wrap = styled('div')`
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
`

export const Title = styled('div')`
max-width: ${(props) => props.status === 'leadership' ? '296px' : '188px'};
margin-top: 20px;
font-family: var(--medium);
font-size: 18px;
color: var(--grey900);
line-height: 1.3em;
transition: all 0.2s ease-in-out;
@media${query1300} {
  font-size: 16px;
}
@media${query500} {
  font-size: 12px;
  br {
    display: none;
  }
}
`
export const SubTitle = styled('div')`
max-width: ${(props) => props.status === 'leadership' ? '296px' : '188px'};
font-family: var(--medium);
font-size: 16px;
color: var(--blue);
line-height: 1.3em;
transition: all 0.2s ease-in-out;
@media${query1300} {
  font-size: 14px;
}
@media${query500} {
  font-size: 10px;
  br {
    display: none;
  }
}
`
export const Description = styled(DescriptionGlobal)`
  font-family: var(--regular);
  text-align: start;
  color: var(--text);
  max-width: ${(props) => props.status === 'leadership' ? '296px' : '188px'};
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 50px;
  @media (max-width: 1300px) {
    font-size: 14px;
  }
`
export const Image = styled('img')`
max-width: ${(props) => props.status === 'leadership' ? '296px' : '188px'};
max-height: ${(props) => props.status === 'leadership' ? '296px' : '188px'};
`