import React, { useState } from 'react'
import { DocumentRight, DocumentWrap } from '../ReportEdit/ReportPage/MaterialTab/style'
import { observer } from 'mobx-react-lite'


const Document = observer(({ fileName, index = 2,  }) => {

  return (
    <>
      <DocumentWrap grey={index % 2 === 0}>
        <div>
          {fileName}
        </div>
        <DocumentRight>
        </DocumentRight>
      </DocumentWrap >
    </>
  )
})

export default Document