import React from 'react'
import { DocumentRight, DocumentWrap } from '../right.style'

import { observer } from 'mobx-react-lite'
import { downloadDoc } from '../../../../api'
const Document = observer(({ materials, file = null }) => {

  const fileName = !file
    ? materials?.file_name?.split('/')[materials?.file_name.split('/').length - 1]
    : file.file_name.split('/')[file.file_name.split('/').length - 1]

  return (
    <>
      {fileName &&
        <DocumentWrap grey={true}>
          <div>
            {fileName}
          </div>
          <DocumentRight>
            <a
              // onClick={() => downloadDoc(materials?.id, fileName)}
              href={file ? file?.file : materials?.file}
              target={'_blank'}
              download>
              Download
            </a>
          </DocumentRight>
        </DocumentWrap>
      }
    </>
  )
})

export default Document


