import styled from "styled-components";

export const Title = styled.div`
  color: var(--text);
  font-size: 32px;
  font-family: var(--bold);
`;

export const SubTitle = styled.div`
  color: var(--text);
  font-size: 14px;
  font-family: var(--regular);
`;

export const ListItem = styled.li`
  color: var(--text);
  font-size: 14px;
  font-family: var(--regular);
  span {
    color: ${(props) => props.condition ? "green" : "red"};
  }
`;