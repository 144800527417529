import { message } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { api } from '../../../../../api'
import Input from '../../../../../components/common/Input'
import {
  getError,
  modifyPaymentCardValues,
} from '../../../../../helpers/helpers'
import { useStore } from '../../../../../mobx-store/context'
import Evalon from '../../../../Auth/Billing/Elavon'
import { Title } from '../../../components/common/style'
import { Left, Right, Row, Wrap } from './style'

const inputs = {
  upper: [
    {
      key: 0,
      label: 'Business Name',
      placeholder: 'Business Name',
      type: 'text',
      name: 'business_name',
      validation: { required: true },
      errorMessage: 'Enter valid business name',
    },
    {
      key: 1,
      label: 'Billing Address',
      placeholder: 'Billing Address',
      type: 'text',
      name: 'address',
      validation: { required: true },
      errorMessage: 'Enter valid billing address',
    },
    {
      key: 2,
      placeholder: 'City',
      type: 'text',
      name: 'city',
      validation: { required: true },
      errorMessage: 'Enter valid city',
    },
  ],
  lower: [
    {
      key: 0,
      label: 'Zip Code',
      placeholder: 'Zip Code',
      type: 'text',
      name: 'zip',
      validation: { required: true, maxLength: 5, minLength: 5 },
      errorMessage: 'Enter valid zip code',
      mask: '99999',
    },
    {
      key: 1,
      label: 'State',
      placeholder: 'State',
      type: 'select',
      name: 'state',
      validation: { required: true },
      errorMessage: 'Choose state',
    },
  ],
}

const Form = observer(
  ({
    onClose,
    addingNewMethod,
    defaultValues,
    isTariffChanging,
    handleCustomSubmit,
    propLoading,
    mainBtnText,
    leftTitle,
  }) => {
    const store = useStore()
    const { control, handleSubmit, errors, setError, reset } = useForm({
      mode: 'onChange',
    })
    const [loading, setLoading] = useState(false)

    const onSubmit = (values) => {
      if (isTariffChanging) return handleCustomSubmit(values)
      setLoading(true)
      api(`/companies/${store.company[0].id}/`, values, 'PATCH').then(
        (data) => {
          if (!data.errors) {
            store.setSingleValue('company', [data])
            if (!addingNewMethod) {
              editCard(values)
            } else {
              addCard(values)
            }
          } else {
            message.error(getError(data))
          }
        }
      )
    }

    const editCard = (values) => {
      api(
        `/payment_cards/${defaultValues.id}/`,
        modifyPaymentCardValues(values),
        'PATCH'
      ).then((data) => {
        if (!data.errors) {
          message.success('Info saved')
          store.getBillingCards()
          setLoading(false)
          onClose()
        }
      })
    }

    const addCard = (values) => {
      api(`/payment_cards/`, modifyPaymentCardValues(values), 'POST').then(
        (data) => {
          if (!data.errors) {
            message.success('Card added')
            store.getBillingCards()
            setLoading(false)
            onClose()
          } else {
            message.error(getError(data))
            getError(data, setError)
            setLoading(false)
          }
        }
      )
    }

    useEffect(() => {
      if (!store.states.length) store.loadSelect('states')
      reset(store.company[0])
    }, [])

    useEffect(() => {
      reset({
        ...defaultValues,
        month: defaultValues?.card_exp_date?.substring(0, 2),
        year: defaultValues?.card_exp_date?.substring(2),
      })
    }, [defaultValues])

    return (
      <Wrap>
        <Left>
          <Title style={{ marginBottom: 15 }}>
            {leftTitle
              ? leftTitle
              : store.company[0].payment_status === 'trial'
              ? 'Add Billing Info'
              : 'Change Billing Info'}{' '}
          </Title>
          <form onSubmit={(e) => e.preventDefault()} id={'input-container'}>
            {inputs.upper.map((item) => (
              <Input
                control={control}
                name={item.name}
                label={item.label}
                error={errors && errors[item.name] && item.errorMessage}
                smallLabel
                type={item.type}
                validation={item.validation}
                key={item.key}
                placeholder={item.placeholder}
                style={{ marginBottom: 15 }}
              />
            ))}
            <Row>
              {inputs.lower.map((item) => (
                <Input
                  control={control}
                  name={item.name}
                  label={item.label}
                  error={errors && errors[item.name] && item.errorMessage}
                  smallLabel
                  type={item.type}
                  validation={item.validation}
                  mask={item.mask}
                  key={item.key}
                  options={store.states}
                  selectVars={{ value: 0, name: 1 }}
                  placeholder={item.placeholder}
                  style={{ marginBottom: 15 }}
                  popupContainer={'input-container'}
                  showSearch
                />
              ))}
            </Row>
            {store.company[0].payment_status !== 'trial' &&
              store.company[0].payment_status !== 'trial_is_over' && (
                <Row style={{ width: '50%', marginTop: 15 }}>
                  {/*<Button*/}
                  {/*  text={'Cancel'}*/}
                  {/*  style={{ backgroundColor: 'var(--orange)' }}*/}
                  {/*  onClick={onClose}*/}
                  {/*/>*/}
                  {/*<Button*/}
                  {/*  text={'Save Billing Info'}*/}
                  {/*  type={'green'}*/}
                  {/*  onClick={handleSubmit(onSubmit)}*/}
                  {/*/>*/}
                </Row>
              )}
          </form>
        </Left>
        <Right>
          <Evalon
            control={control}
            smallLabel
            defaultValues={defaultValues}
            handleSubmit={handleSubmit(onSubmit)}
            errors={errors}
            loading={loading || propLoading}
            isEditing={!addingNewMethod}
            mainBtnText={mainBtnText}
            onClose={onClose}
            addingCard={addingNewMethod}
          />
        </Right>
      </Wrap>
    )
  }
)

export default Form
