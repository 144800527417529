import React from 'react'
import { useStore } from '../../../mobx-store/context'
import { SelectTitle, SelectDescription, SelectWrap, TextContainer, SignUpButton, ButtonsWrap } from './selectBlock.style'
import Button from '../../../components/common/Button/button'

const SelectBlock = ({ title, titleColor, description, onSchedule, onSignUp }) => {
  const store = useStore()

  const ScheduleHandler = (title) => {
    onSchedule(title);
    store.setSingleValue('selectedProduct', title)
  }
  return (
    <SelectWrap>
      <TextContainer>
        <SelectTitle titleColor={titleColor}>
          {title}
        </SelectTitle>
        <SelectDescription>
          {description}
        </SelectDescription>
      </TextContainer>
      <ButtonsWrap>
        <Button text={'Schedule a Demo'} onClick={() => ScheduleHandler(title)} />
        {/* {(title === 'ReSource' || title === 'ReportSource')
          && <SignUpButton onClick={onSignUp}>
            or Sign Up
          </SignUpButton>
        } */}
      </ButtonsWrap>
    </SelectWrap>
  )
}


export default SelectBlock;