import React, { useMemo } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { getJurisdictionCode, getDescription } from '../../../helpers/helpers'
import { Text } from '../components/style'
import Title from '../components/Title'
import { Wrap } from './style'

const Right = ({ store, params, currentItem }) => {
  const isGuides = params.type === 'guides'

  const info = useMemo(() => {
    return (
      store.resourcesCurrentTopic?.rows?.find(
        (item) => item.id === +params.jursId
      ) ||
      store.resourcesCurrentTopic?.rows?.find(
        (item) => item.id === +params.itemId
      ) ||
      store.resourcesCurrentTopic?.rows?.find(
        (item) => item.jurisdiction === +params.selectId
      )
    )?.row_data?.custom_fields_data?.find((item) => item.code === params.code)
  }, [store.resourcesCurrentTopic, params])

  const generalInfo = useMemo(() => {
    return (
      store.resourcesCurrentTopic?.rows?.find(
        (item) => item.id === +params.jursId
      ) ||
      store.resourcesCurrentTopic?.rows?.find(
        (item) => item.id === +params.itemId
      ) ||
      store.resourcesCurrentTopic?.rows?.find(
        (item) => item.jurisdiction === +params.selectId
      )
    )
  }, [store.resourcesCurrentTopic, params])


  const globalInfo = useMemo(() => {
    return (
      store.resourcesCurrentTopic?.rows?.find(
        (item) => item.id === +params.jursId
      ) ||
      store.resourcesCurrentTopic?.rows?.find(
        (item) => item.id === +params.itemId
      ) ||
      store.resourcesCurrentTopic?.rows?.find(
        (item) => item.jurisdiction === +params.selectId
      )
    )?.description
  }, [store.resourcesCurrentTopic, params])

  const name = store.resourcesCurrentTopic?.guide_data?.custom_fields?.find(
    (item) => item.code === params.code
  )?.name

  return (
    <Wrap>
      <Title
        jurisdiction={
          store.jurs?.find((item) => item.id === +store.currentJurId)?.name
        }
        title={name}
        showButton
      />
      <div style={{
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        gap: 40,
        marginBottom: 15
      }}>
        <div style={{ paddingRight: 25 }}>
          <Text
            style={{
              fontFamily: 'var(--bold)',
              marginTop: 35
            }}>Line of Business
          </Text>
          <Text dangerouslySetInnerHTML={{
            __html:
              getDescription(store.lineOfBusinesses, generalInfo.line_of_business)
                .split(',')
                .join(` <br/> `)
              || 'Not Specified'
          }} />
        </div>
        <div>
          {!isGuides && <Text style={{ fontFamily: 'var(--bold)', marginTop: 35 }}>Differentiator</Text>}
          {!isGuides && <Text>{generalInfo.description}</Text>}
        </div>
      </div>
      <Text style={{ fontFamily: 'var(--bold)', marginTop: 35 }}>Summary</Text>
      <Text>{info?.summary}</Text>
      <Text style={{ fontFamily: 'var(--bold)', marginTop: 35 }}>
        Details
      </Text>
      <Text style={{ paddingRight: 15 }}>
        {ReactHtmlParser(info?.detail?.replace(/\n/g, '<br />'))}
      </Text>
    </Wrap >
  )
}

export default Right
