import styled from 'styled-components'

export const Wrap = styled("div")`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid
    ${(props) => (props.checked ? "var(--orange)" : "var(--text)")};
  border-radius: 100%;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  span {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: var(--orange);
  }
`;
