import React from 'react'
import Modal from '../../../../../../components/common/ModalNew'
import { Text, Title } from './style'

const ModalTerms = ({ onClose, text, title }) => {
  const html = {__html: text.replace(/\n/g, '<br>')}
  return (
    <Modal
      styleWrap={{ width: '80vw', maxWidth: '1000px', overflow: 'auto' }}
      styleOverlay={{ backgroundColor: '#222222' }}
      onClose={onClose}
    >
      <Title>{title}</Title>
      <Text><div dangerouslySetInnerHTML={html}></div></Text>
    </Modal>
  )
}

export default ModalTerms
