import styled from 'styled-components'

export const Wrap = styled('div')`
  display: flex;
  align-items: center;
  border-top: 1px solid #dcdfe0;
  padding-top: 0.5em;
  margin-top: 1em;
`

export const CheckboxWrap = styled('div')`
  margin-right: 10px;
`

export const Text = styled('div')`
  color: var(--text);
  font-size: 0.75em;
  font-family: var(--sf-t-reg);
  padding-top: 2px;
`
