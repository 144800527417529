import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { useStore } from '../../../../../mobx-store/context'
import ResourceModal from '../../../components/ResourceAccessModal/resourceModal'
import React from 'react'
import EmailSettingsTable from './Table'
import {
  ReportSiteSettings,
  ReportLiteSiteSettings,
  ReportSiteTaskSettings,
  ReportSettings,
  ReportLiteSettings,
  ReportTaskSettings,
  ReportMemberSiteTaskSettings,
  ReportMemberTaskSettings
} from './static'
import { toJS } from 'mobx'
import { Title, SubTitle } from './Table/style'


const ReportTab = observer(({ setState, state, site }) => {
  const store = useStore()
  const history = useHistory()
  const reportEmailOptions = site ? store?.user[0]?.report_source_site_notifications : store?.user[0]?.report_source_notifications

  const liteMode = store.company[0].report_source_mode === 'report_source_lite'

  const isMember = store?.user[0]?.report_source_role === 'member'


  const isTaskTable = true && !liteMode;

  const titles = site ? ['Report Owner Alerts', 'Task  Owner Alerts'] : ['Report Owner Emails', 'Task  Owner Emails']

  if (!store.user?.[0]?.have_access_to_report_source || !store.company?.[0]?.have_access_to_report_source)
    return (
      <ResourceModal
        type='reportSource'
        to='/edit-profile'
        onClose={() => history.push('/edit-profile')}
      />
    )

  const initialSettings = site
    ? liteMode
      ? ReportLiteSiteSettings
      : ReportSiteSettings
    : liteMode
      ? ReportLiteSettings
      : ReportSettings

  const initialTaskSettings = site
    ? isMember
      ? ReportMemberSiteTaskSettings
      : ReportSiteTaskSettings
    : isMember
      ? ReportMemberTaskSettings
      : ReportTaskSettings

  return (
    <>
      <Title>{liteMode ? 'ReportSource Lite' : 'ReportSource'}</Title>
      <SubTitle>Select notifications times for the following actions.</SubTitle>
      <EmailSettingsTable
        site={site}
        titles={titles}
        tasksSetings={true}
        initialSettings={initialSettings}
        initialTaskSettings={initialTaskSettings}
        initialValues={toJS(reportEmailOptions)}
        setState={setState}
        state={state}
        mainSettings={!isMember}
        isTaskTable={isTaskTable}
        isReportSource={true}
      />
    </>
  )
})

export default ReportTab
