import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import arrow from '../../../../assets/images/Arrow.svg'
import macbookImage from '../../../../assets/images/macbook-video.png'
import video from '../../../../assets/video/ReSource.mp4'
import Button from '../../../../components/common/Button/button'
import Modal from '../../../../components/common/ModalNew'
import { useStore } from '../../../../mobx-store/context'
import ScheduleInput from '../../Main/Demo/ScheduleInput'
import { FreeSign } from '../../Compliance/Header/style'
import {
  BottomContainer,
  Description,
  LeftSide,
  Title,
  TitleSmall,
  TopContainter,
} from './style'

const Header = () => {
  const store = useStore()
  const history = useHistory()
  const [visible, setVisible] = useState(false)

  const onClick = () => {
    store.setSingleValue('started_from_resource', true)
    history.push('/register')
  }

  return (
    <>
      {visible && (
        <Modal
          onClose={() => setVisible(false)}
          styleWrap={{ backgroundColor: 'transparent', padding: 0 }}
        >
          <video src={video} style={{ width: '100%' }} autoPlay controls />
        </Modal>
      )}
      <TopContainter>
        <Title>
          <span style={{ color: '#70B461' }}>ReSource</span> is revolutionizing compliance for the PBM industry
        </Title>
        <Description>
          Do business right the first time. Save time. Save money. Increase
          customer satisfaction. Innovate new compliant programs. Avoid fines
          and penalties.
        </Description>
      </TopContainter>
      <BottomContainer>
        <LeftSide>
          <TitleSmall>
            View a 3-minute video to see how <span>ReSource</span> can help you.
          </TitleSmall>
          <Description>
            See how ReSource can be used to view summaries of existing laws and
            regulations for creating and responding to RFPs, creating P&Ps,
            onboarding new clients in new markets, developing new programs or
            networks. Let us know if you want to see a demo or if you have any questions.
          </Description>
          <div style={{ marginTop: 30, marginBottom: 24 }}>
            <ScheduleInput border service={'Resource'} />
          </div>
          {/* <span
            style={{
              color: 'var(--orange)',
              fontSize: 16,
              fontFamily: 'var(--bold)'
            }}>
            Or
          </span>
          <Button
            style={{ marginTop: 24 }}
            text='Purchase Now'
            to={store?.company[0]?.have_access_to_resource && store?.user[0]?.have_access_to_resource
              ? '/resource/table/guides'
              : '/register'}
          /> */}
          {/*<FreeSign>Try us for free! No credit card required.</FreeSign> */}
          {/* <Button
            text={'Or Get Started'}
            icon={arrow}
            onClick={onClick}
            style={{
              marginTop: 30,
              height: 44,
              flexDirection: 'row-reverse',
              padding: '0 20px',
            }}
            styleIcon={{ margin: '0 0 0 10px' }}
          /> */}
        </LeftSide>
        <img
          src={macbookImage}
          id='video-img'
          onClick={() => setVisible(true)}
          alt='video'
          style={{ cursor: 'pointer' }}
        />
      </BottomContainer>
    </>
  )
}

export default Header
