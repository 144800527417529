import React from 'react'
import { Wrap } from './style'
import { Link } from 'react-router-dom'

const ButtonOrange = ({ text, link, onClick, style, defaultLink, bold=false }) => {
  return (
    <Wrap name={text} as={link ? defaultLink ? 'a' : Link : 'button'} to={link} href={link}
          onClick={onClick} style={style}
          target={defaultLink ? '_blank' : '_self'}
          bold={bold}>
      {text}
    </Wrap>
  )
}

export default ButtonOrange
