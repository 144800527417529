import styled from 'styled-components'

export const Wrap = styled('div')``

export const Loader = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Title = styled('div')`
  font-family: var(--bold);
  font-size: 14px;
  color: var(--text);
  white-space: nowrap;
  padding: 10px 5px;
`
export const ModalTitle = styled(Title)`
  font-family: var(--semiBold);
  font-size: 18px;
  color: var(--text);
  white-space: nowrap;
  padding: 10px 5px;
`

export const Description = styled('div')`
  font-size: 14px;
  font-family: var(--regular);
  color: var(--text);
`
export const Row = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 75px;
`
export const Cancel = styled('div')`
  font-family: var(--regular);
  text-decoration: underline;
  cursor: pointer;
`