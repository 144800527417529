import styled from 'styled-components'


export const Wrap = styled('div')`
position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Container = styled('div')`
  max-width: 1350px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 950px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: start;
    padding: 0 15px;
  }
`
export const Image = styled('img')`
  position: absolute;
  left: 0px;
  bottom: -190px;
  z-index: -1;
`