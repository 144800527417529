import React from 'react'
import { Wrap } from './style'
import PropTypes from 'prop-types'

const sizes = [10, 20, 30, 40]

const PageSizeChanger = ({ onChange, pageSize }) => {
  return (
    <Wrap>
      Results per page{' '}
      <select onChange={(e) => onChange(e.target.value)} value={pageSize}>
        {sizes.map((item) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </select>
    </Wrap>
  )
}

PageSizeChanger.propTypes = {
  total: PropTypes.number.isRequired,
}

export default PageSizeChanger
