import Modal from "../../../../../../components/common/ModalNew";
import { Title, Button, SubTitle, ModalWrapper } from "../Modal/style";

const ErrorUsersModal = ({ onClose, errorUsers, setErrorUsers }) => {
  return (
    <Modal
      styleWrap={{ width: 500, padding: 36 }}
      onClose={() => {
        setErrorUsers([])
        onClose()
      }}>
      <Title>Warning!</Title>
      <SubTitle>
        The following users did not gain access to the selected resources due to the settings of their companies:
      </SubTitle>
      <ModalWrapper >
        {errorUsers.map((user, index) => {
          return (
            <div key={index}>
              <div style={{ fontFamily: 'var(--semiBold)', color: 'var(--blue)', fontSize: 16 }}>{user}</div>
            </div>
          )
        })}
      </ModalWrapper>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 25, alignItems: 'center' }}>
        <Button onClick={() => {
          setErrorUsers([])
          onClose()
        }}>
          Close
        </Button>
      </div>
    </Modal>
  )
}

export default ErrorUsersModal