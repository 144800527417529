import React from 'react'
import Button from '../../../../../../components/common/Button/button'
import { Label } from '../../../../../../components/common/Input/input.style'
import Modal from '../../../../../../components/common/ModalNew/index'
import { Cancel } from '../../../../../Tracking/Right/Tasks/Modal/style'
import { Row, Text } from '../Modal/style'

const AdminModal = ({ onClose, onCancel }) => {
  return (
    <Modal onClose={onClose}>
      <Label>Admin Role Applies to All Products</Label>
      <Text>
        Making a user an admin give them admin rights for all PBMSource
        products.{' '}
      </Text>
      <Row>
        <Cancel onClick={onCancel}>Cancel</Cancel>
        <Button text={'Change  to Admin'} onClick={onClose} />
      </Row>
    </Modal>
  )
}

export default AdminModal
