import { Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { api } from '../../../../api'
import Button from '../../../../components/common/Button/button'
import Input from '../../../../components/common/Input'
import { useStore } from '../../../../mobx-store/context'
import { AllSelected, Wrap } from '../../AddMember/Form/form.style'
import { Bottom } from '../../authForm.style'

const Form = observer(() => {
  const store = useStore()
  const history = useHistory()
  const { control, handleSubmit, reset, errors, getValues } = useForm({
    defaultValues: {
      jurisdictions: [],
      topics: [],
    },
  })

  const modifyValues = (values) => ({
    topics: Array.isArray(values.topics)
      ? values.topics
      : values.topics
      ? [values.topics]
      : [],
    jurisdictions: Array.isArray(values.jurisdictions)
      ? values.jurisdictions
      : values.jurisdictions
      ? [values.jurisdictions]
      : [],
  })

  const onSubmit = (values) => {
    api(`/companies/${store.company[0].id}/`, modifyValues(values), 'PATCH')
      .then((data) => {
        if (!data.errors) {
          store.setSingleValue('company', [data])
          history.push(`/addMember`)
        }
      })
      .catch((err) => alert(err))
  }

  useEffect(() => {
    store.loadSelect('companyOptions')
  }, [])

  const tariffNamesArray = {
    topic_tract: 'jurisdictions',
    juris_tract: 'topics',
  }

  useEffect(() => {
    if (!store.company.length) return

    if (store.company[0].tariff === 'total_tract') {
      return
    }
    if (
      store.company[0].tariff !== 'select_tract' &&
      store.company[0][tariffNamesArray[store.company[0].tariff]]?.length > 1
    ) {
      return
    }
    reset({
      topics: store.company[0].topics,
      jurisdictions: store.company[0].jurisdictions,
    })
  }, [store.company])

  const tariff = {
    topic_tract: (
      <AllSelected>All Topics are included with this plan.</AllSelected>
    ),
    juris_tract: (
      <AllSelected>All Jurisdictions are included with this plan.</AllSelected>
    ),
    total_tract: (
      <AllSelected>
        All Topics and Jurisdictions are included with this plan.
      </AllSelected>
    ),
  }

  const company = store.company

  if (!company.length) return <Spin />

  const companyTariff = company[0].tariff

  const companyTariffAlaCarteCheck = company[0].tariff === 'select_tract'

  return (
    <Wrap onSubmit={handleSubmit(onSubmit)}>
      {tariff[companyTariff]}
      {companyTariff !== 'topic_tract' && companyTariff !== 'total_tract' && (
        <Input
          name={'topics'}
          label={'Select Topic'}
          type={companyTariffAlaCarteCheck ? 'multiSelect' : 'select'}
          control={control}
          placeholder={
            companyTariffAlaCarteCheck
              ? 'Select All that Apply'
              : 'Select one Topics'
          }
          transparent
          options={store.topics}
          validation={{
            required: true,
            validate: (value) =>
              Array.isArray(value) ? value.length > 0 : value,
          }}
          loading={!store.topics.length}
          style={{ marginBottom: '20px' }}
          error={errors && errors.topics && 'This field is required'}
        />
      )}

      {companyTariff !== 'juris_tract' && companyTariff !== 'total_tract' && (
        <Input
          name={'jurisdictions'}
          label={'Select Jurisdiction'}
          type={companyTariffAlaCarteCheck ? 'multiSelect' : 'select'}
          control={control}
          placeholder={
            companyTariffAlaCarteCheck
              ? 'Select All that Apply'
              : 'Select one Jurisdiction'
          }
          validation={{
            required: true,
            validate: (value) =>
              Array.isArray(value) ? value.length > 0 : value,
          }}
          transparent
          options={store.jurs}
          error={errors && errors.jurisdictions && 'This field is required'}
          style={{ marginBottom: '0' }}
          loading={!store.jurs}
          showSearch
        />
      )}

      <Bottom style={{ maxWidth: '100%', marginTop: '1.5em' }}>
        <Button text={'Confirm Options'} type={'main'} style={{ height: 30 }} />
      </Bottom>
    </Wrap>
  )
})

export default Form
