import styled from 'styled-components'

export const Wrap = styled("div")``;

export const Item = styled("div")`
  font-size: 15px;
  font-family: ${(props) => (props.active ? "var(--bold)" : "var(--regular)")};
  color: ${(props) => (props.active ? "#fff" : "var(--text)")};
  cursor: pointer;
  padding: 15px 13px 15px 9px;
  background-color: ${(props) => (props.active ? "#4B75B1" : "#F4F6F6")};
  display: flex;
  align-items: center;
`;

export const Scroll = styled("div")`
  max-height: 69vh;
  overflow-y: auto;
`;

export const Title = styled("div")`
  font-family: var(--medium);
  font-size: 16px;
  color: var(--text);
  margin-top: 20px;
  margin-bottom: 10px;
`;
