import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import setting from '../../../../assets/images/settings_24px.svg'
import { checkRole } from '../../../../helpers/helpers'
import useDidMountEffect from '../../../../hooks/useEffectNotOnPageLoad'
import { useStore } from '../../../../mobx-store/context'
import { Item, Title, Wrap } from './style'
import { Divider } from 'antd'

const menu = [
  {
    key: 0,
    title: 'Companies',
    to: '/dashboard-landing',
    subitems: [
      {
        key: 0,
        title: 'Add New',
        to: '/create-new-company',
      },
    ],
  },
  {
    key: 1,
    title: 'Users',
    to: '/dashboard-all-users',
    subitems: [],
  },
  {
    key: 2,
    title: 'ComplianceSource',
    to: '/dashboard-compliance-table',
    subitems: [
      {
        key: 0,
        title: 'Add New',
        to: '/dashboard-add-compliance',
      },
    ],
  },
  {
    key: 3,
    title: 'ReSource',
    to: '/dashboard-guides-table',
    subitems: [
      {
        key: 0,
        title: 'Upload New Guide',
        to: '/dashboard-upload-guide',
      },
      {
        key: 1,
        title: 'Guide Names',
        to: '/dashboard-add-guide-name',
      },
    ],
  },
  {
    key: 4,
    title: 'ReportSource',
    to: '/dashboard-report-table',
    subitems: [
      {
        key: 0,
        title: 'Add New',
        to: '/dashboard-add-report',
      },
    ],
  },
  {
    key: 5,
    title: 'ProposedSource',
    to: '/dashboard-proposed-table',
    subitems: [
      {
        key: 0,
        title: 'Add New',
        to: '/dashboard-add-proposed',
      },
    ],
  },
]

const dictionaries = [
  {
    key: 0,
    title: 'Jurisdictions',
    to: '/dashboard-dictionaries-jurisdictions',
  },
  {
    key: 1,
    title: 'Lines of Business',
    to: '/dashboard-dictionaries-lines-of-business',
  },
  {
    key: 2,
    title: 'Topics',
    to: '/dashboard-dictionaries-topics',
  },
  {
    key: 3,
    title: 'Sub-Topics',
    to: '/dashboard-dictionaries-sub-topics',
  },
  {
    key: 4,
    title: 'Surveys',
    to: '/dashboard-dictionaries-surveys',
  },
  {
    key: 5,
    title: 'Reference Types',
    to: '/dashboard-dictionaries-reference-types',
  },
  {
    key: 6,
    title: 'Reporting Entities',
    to: '/dashboard-dictionaries-reporting-entities',
  },
  {
    key: 7,
    title: 'Reporting Types',
    to: '/dashboard-dictionaries-reporting-types',
  },
  {
    key: 8,
    title: 'Ties To License',
    to: '/dashboard-dictionaries-ties-to-license',
  },
  {
    key: 9,
    title: 'Applies To',
    to: '/dashboard-dictionaries-applies-to',
  },
]


const Menu = observer(() => {
  const store = useStore()

  return (
    <Wrap>
      <Title>Manage:</Title>
      {menu.map((item) => {
        return (
          <div style={{ marginBottom: 16 }}>
            <Item
              to={item.to}
              key={item.key}
              activeStyle={{
                fontFamily: 'var(--bold)',
                color: 'var(--blue)',
              }}
              secondary={false}
              disabled={item.disabled}
              isActive={(_, location) => {
                if (location.pathname.split('/')[1] === item.to.split('/')[1])
                  return true
                return false
              }}
            >
              {item.title}
            </Item>
            {item.subitems.length ? item.subitems.map((subitem) => {
              return (
                <Item
                  to={subitem.to}
                  key={subitem.key}
                  activeStyle={{
                    fontFamily: 'var(--bold)'
                  }}
                  onClick={() => {
                    if (subitem.to === '/dashboard-add-compliance' || subitem.to === '/dashboard-add-report' || subitem.to === '/dashboard-add-proposed') {
                      store.setSingleValue('cmsCurrentLegislative', null)
                      store.setSingleValue('cmsCurrentReport', null)
                    }
                  }}
                  secondary={true}
                  disabled={subitem.disabled}
                  isActive={(_, location) => {
                    if (location.pathname.split('/')[1] === subitem.to.split('/')[1])
                      return true
                    return false
                  }}
                >
                  {subitem.title}
                </Item>
              )
            })
              : null}
          </div>
        )
      })}
      <Divider />
      <Title>Dictionaries:</Title>
      {dictionaries.map((item) => {
        return (
          <div style={{ marginBottom: 2 }}>
            <Item
              to={item.to}
              key={item.key}
              activeStyle={{
                fontFamily: 'var(--bold)',
                color: 'var(--blue)',
              }}
              secondary={false}
              disabled={item.disabled}
              isActive={(_, location) => {
                if (location.pathname.split('/')[1] === item.to.split('/')[1])
                  return true
                return false
              }}
            >
              {item.title}
            </Item>
          </div>
        )
      })}
    </Wrap>
  )
})

export default Menu
