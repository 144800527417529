import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useStore } from '../../../../../mobx-store/context'
import Button from '../../../../../components/common/Button/button'
import Switch from '../../../../../components/common/Switch/switch'
import { api } from '../../../../../api'
import { Bottom } from '../../../../Auth/authForm.style'
import TabsNav from '../../../components/TabsNav/tabsNav'
import ComplianceTab from '../Form/complianceTab'
import { ButtonsWrap } from '../Form/form.style'
import { Spin } from 'antd'
import ResourceTab from '../Form/resourceTab'
import ReportSourceTab from '../Form/reportSourceTab'
import ProposedSourceTab from '../Form/proposedSourceTab'
import LicenseSourceTab from '../Form/licenseSourceTab'

const Tabs = ({ control, loading, onSubmit, memberType, register, errors }) => {
  const [active, setActive] = useState(null)
  const params = useParams()
  const history = useHistory()
  const [showFields, setShowField] = useState(false)
  const [loadingActive, setLoadingActive] = useState(true)
  const store = useStore()
  const company = store.company?.[0]
  const user = store.user?.[0]

  const tabsProps = {
    control,
    showFields,
    memberType,
    register,
    active,
    errors,
  }

  const handleShowField = (value) => {
    if (value !== 'member') setShowField(true)
  }
  const mode = store.company[0].compliance_source_mode
  const buttonName = mode === 'compliance_source_ultra_lite'
    ? 'CS Ultra Lite'
    : mode === 'compliance_source_lite'
      ? 'CS Lite' : 'ComplianceSource'

  const reportMode = store.company[0].report_source_mode
  const reportButtonName = reportMode === 'report_source_lite'
    ? 'ReportSource Lite' : 'ReportSource'

  const tabs = {
    tracking: {
      button: buttonName,
      link: '/add-team-members-profile/tracking',
      component: (
        <ComplianceTab {...tabsProps} handleShowField={handleShowField} />
      ),
    },
    resource: {
      button: 'ReSource',
      link: '/add-team-members-profile/resource',
      component: <ResourceTab {...tabsProps} />,
    },
    reportSource: {
      button: reportButtonName,
      link: '/add-team-members-profile/reportSource',
      component: (
        <ReportSourceTab {...tabsProps} handleShowField={handleShowField} />
      ),
    },
    licenseSource: {
      button: 'LicenseSource',
      link: '/add-team-members-profile/licenseSource',
      component: (
        <LicenseSourceTab {...tabsProps} handleShowField={handleShowField} />
      ),
    },
    proposedSource: {
      button: 'ProposedSource',
      link: '/add-team-members-profile/proposedSource',
      component: (
        <ProposedSourceTab {...tabsProps} handleShowField={handleShowField} />
      ),
    },
  }

  const switchText =
    active === 'tracking'
      ? 'Topic'
      : active === 'reportSource' ?
        'Report Type'
        : active === 'licenseSource' &&
        'License Type'

  useEffect(() => {
    company.is_report_source_registration_passed &&
      company.have_acess_to_report_source &&
      company.is_current_period_paid &&
      api('/report_types/', {}, 'GET').then((data) => {
        store.setSingleValue('reportTypes', data)
      })
  }, [])

  useEffect(() => {
    if (active === 'resource') {
      setShowField(true)
    }
  }, [active])

  useEffect(() => {
    params.type && setActive(params.type)
  }, [params.type])

  useEffect(() => {
    setActive(
      (user?.have_access_to_tracking
        ? 'tracking'
        : user?.have_access_to_resource
          ? 'resource'
          : user?.have_access_to_report_source
            ? 'reportSource'
            : user?.have_access_to_license_source
              ? 'licenseSource'
              : 'proposedSource'
      )
    )
    setLoadingActive(false)
  }, [])

  const showSwitch = (active) => {
    if (active === 'tracking') {
      return memberType.tracking !== 'member'
    } else if (active === 'reportSource') {
      return memberType.reportSource !== 'member'
    } else if (active === 'licenseSource') {
      return memberType.licenseSource !== 'member'
    }
  }

  return (
    <>
      <TabsNav
        tabs={tabs}
        active={active}
        styleWrap={{ marginTop: 30 }}
        onRedirectTo={(page) =>
          history.push(`/add-team-members-profile/${page}`)
        }
      />

      {loadingActive ? <Spin /> : tabs[active].component}
      <Bottom
        style={{
          maxWidth: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <ButtonsWrap>
          {showSwitch(active) && (
            <Switch
              text={
                <p style={{ fontSize: 14, marginRight: 15 }}>
                  Assign team member based on {switchText}, Jurisdiction, Line of
                  Business
                </p>
              }
              onChange={() => setShowField(!showFields)}
              active={showFields}
            />
          )}

          <Button
            text={'+Add to team'}
            style={{
              backgroundColor: '#91C03E',
              padding: '0 .5em',
              marginLeft: 'auto',
            }}
            loading={loading}
            disabled={loading}
            onClick={onSubmit}
          />
        </ButtonsWrap>
      </Bottom>
    </>
  )
}

export default Tabs
