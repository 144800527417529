import styled from 'styled-components'

export const Wrap = styled('div')`
  width: 1200px;
  min-height: 100px;
  max-height: 95vh;
  overflow-y: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: relative;
`

export const Overlay = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(3px);
  z-index: 99;
  padding-top: 100px;
`

export const Close = styled('img')`
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
`
