export const data = [
  {
    key: 0,
    mostPopular: true,
    title: 'ComplianceSource',
    titleColor: 'var(--blue)',
    description: (
      <>
        Identifies <span>new</span> laws, regulations, and guidance
        when adopted.
      </>
    ),
    listDescription: 'New Laws',
    link: '/home-compliance',
    list: [
      {
        key: 0,
        title: 'Identifies new laws, regulations, and subregulatory guidance specific to PBM industry. ',
      },
      {
        key: 1,
        title: 'Summarizes new requirements by topic and identifies applicable lines of business. ',
      },
      {
        key: 2,
        title: 'Uploads relevant new requirements to a central repository. ',
      },
      {
        key: 3,
        title: 'Allows collaboration within the organization to determine compliance. ',
      },
      {
        key: 4,
        title: 'Automated tracking of compliance status, with email reminders of due dates. ',
      },
      {
        key: 5,
        title: 'Archives closed compliance assessments.',
      },
    ],
  },
  {
    key: 1,
    mostPopular: false,
    title: 'ReSource',
    titleColor: 'var(--green)',
    description: (
      <>
        Identifies <span>existing</span> laws and regulations.
      </>
    ),
    listDescription:
      'Existing Laws',
    link: '/home-resource',
    list: [
      {
        key: 0,
        title: 'Provides guides of laws & regulations and identifies applicable lines of business.',
      },
      {
        key: 1,
        title: 'View requirements for a topic (e.g., AWP, steerage, prompt pay) for one jurisdiction or across multiple jurisdictions.',
      },
      {
        key: 2,
        title: 'View requirements (e.g., spread prohibitions) when creating or responding to RFPs. ',
      },
      {
        key: 3,
        title: 'View requirements (e.g., MAC requirements) to create or update P&Ps. ',
      },
      {
        key: 4,
        title: 'View requirements (e.g., minimum pharmacy pricing requirements) when contracting.',
      },
      {
        key: 5,
        title: 'View requirements (e.g., copay accumulator mandates) when implementing new programs.',
      },
    ],
  },
  {
    key: 2,
    mostPopular: false,
    title: 'ReportSource',
    titleColor: 'var(--orange)',
    disabled: false,
    description: (
      <>
        Organize, track, and collaborate on reporting requirements.
      </>
    ),
    listDescription:
      'Report Compliance Tracking',
    link: '/home-report-source',
    list: [
      {
        key: 0,
        title: 'Identifies reports and corresponding deadlines for PBMs.',
      },
      {
        key: 1,
        title: 'Access report forms, instructions, and guidance. ',
      },
      {
        key: 2,
        title: 'View upcoming reports in calendar and dashboard format.',
      },
      {
        key: 3,
        title: 'Allows for centralized collaboration for compliance with reporting requirements.',
      },
      {
        key: 4,
        title: 'Automated tracking of report compliance status, with email reminders of due dates. ',
      },
      {
        key: 5,
        title: 'Archives of completed reports.',
      },
    ],
  },
]
