import React from 'react'
import { useStore } from '../../../../../mobx-store/context'
import { Name, Text, TopLine, Wrap } from './style'
import Moment from 'react-moment'
import parse from 'html-react-parser'

const Item = ({ data, isReportSource, isLicenseSource, valueName, type }) => {
  const store = useStore()

  return (
    <Wrap>
      <TopLine>
        <Name>{store.getMember((isReportSource || isLicenseSource)
          ? type === 'details'
            ? data?.details_modified_by
            : data?.assessment_modified_by
          : data?.modified_by)}
          </Name>{' '}
        <Moment format={'lll'}>{type === 'details' ? data?.details_modified_at : data?.assessment_modified_at}</Moment>
      </TopLine>
      <Text>
        {valueName ? parse(data[valueName]) : parse(data.details_html)}
      </Text>
    </Wrap>
  )
}

export default Item
