import styled from 'styled-components'
import { Wrap } from '../Resource/RightSide/style'

export const Container = styled('div')`
  width: 1319px;
  margin: 0 auto;
  padding: 0 10px;
`

export const TableWrap = styled(Wrap)`

.ant-table-thead {
  tr {
    th {
      background-color: #fff !important;
       position: sticky;
       top: 340px; 
      z-index: 99;
    }
  }
}
  
  .ant-table-cell {
    border-right: 0;
    font-family: var(--medium);
  }
  .ant-table-row,
  .ant-table-thead {
    height: 45px;
  }
  .ant-table-container {
    margin-bottom: 10%;
    border: 0;
  }
  .ant-table-body {
    &::-webkit-scrollbar {
      width: 8px;
      position: absolute;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: var(--grey);
      border-radius: 5px;
      width: 8px;
    }
  }
  .ant-table-row {
    &:nth-child(even) {
      background-color: rgba(34, 81, 148, 0.05);
    }
  }
`
