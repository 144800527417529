import { MonthCol, Clear, Container } from "./style"
import { useStore } from "../../../../mobx-store/context"
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom"
import { is } from "immutable";

const Column = ({ text, record, isReportSource, columnType, isLicenseSource, active }) => {
  const store = useStore();
  const history = useHistory()
  const monthAbbr = record.month_abbr
  const monthName = record.month_name

  const filterMode = store.filterMode

  const [disabled, setDisabled] = useState(false)

  const tableTitleText = columnType === 'month_name'
    ? text
    : columnType === 'total'
      ? monthName + ' '
      : columnType === 'past_due'
        ? monthAbbr + ': Past Due'
        : columnType === 'not_completed'
          ? monthAbbr + ' : Upcoming'
          : monthAbbr + ' : Completed'

  useEffect(() => {
    setDisabled(filterMode && store.activeSearchMonth !== tableTitleText)
  }, [filterMode, monthName, store.activeSearchMonth, store.activeSearchMonthName, tableTitleText, text])

  const noReports = text === 0;


  const onClick = () => {

    if (columnType === 'completed') {
      isLicenseSource
        ? history.push(`/license-source/closed/`)
        : history.push(`/report-source/closed/`)
      return
    }

    store.setSingleValue('filterMode', true)
    store.setSingleValue('activeSearchMonth', tableTitleText)
    store.setSingleValue('activeSearchMonthName', monthName)

    const commonProperties = isLicenseSource && active !== 'apcd'
      ? {
        report_due_date_after: record.due_date_after,
        report_due_date_before: record.due_date_before,
      }
      : {
        report_due_date_after: record.report_due_date_after,
        report_due_date_before: record.report_due_date_before,
      };

    const searchObject =
      columnType === 'month_name'
        ? {
          ...commonProperties,
          tab_name: active,
        }
        : columnType === 'total'
          ? {
            ...commonProperties,
            stats_filter_by: 'total',
            tab_name: active,
          }
          : columnType === 'past_due'
            ? {
              ...commonProperties,
              stats_filter_by: 'past_due',
              tab_name: active,
            }
            : columnType === 'not_completed'
              ? {
                ...commonProperties,
                stats_filter_by: 'upcoming',
                tab_name: active,
              }
              : {
                ...commonProperties,
                stats_filter_by: 'completed',
                tab_name: active,
              };
    store.setSingleValue('searchableObject', {
      ...searchObject,
    })
    store.loadLegislatives({ page: 1, page_size: 20 }, isReportSource, isLicenseSource)
  }

  const onClear = () => {
    store.setSingleValue('filterMode', false)
    store.setSingleValue('activeSearchMonth', null)
    isLicenseSource ?
      store.setSingleValue('searchableObject', { tab_name: active})
      : store.setSingleValue('searchableObject', {})

    store.loadLegislatives({ page: 1, page_size: 20 }, isReportSource, isLicenseSource)
  }

  return (
    <Container columnType={columnType} >
      <MonthCol columnType={columnType} noReports={noReports} disabled={disabled} onClick={() => !disabled && !noReports && onClick()}>
        {`${text} ${record.order === 1 && columnType === 'month_name' ? '(Current Month)' : ''}`}
      </MonthCol>
      {filterMode && !disabled && <Clear onClick={onClear}> (clear)</Clear>}
    </Container>

  )
}

export default Column