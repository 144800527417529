import React, { useState, useEffect } from 'react';
import { Radio, Row } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import { api } from '../../../../../api';
import { CustomCheckbox, CustomRadio, Title } from './style'

const AppliesTo = ({ legislative }) => {
  const { control, setValue, getValues, reset } = useForm();
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [legislative]);

  const getData = () => {
    api(`/legislative/${legislative}/legislative_applies_to/`, {}, 'GET').then((dataFromServer) => {
      setFormData(dataFromServer);
    });
  };

  const handleCheckboxChange = (index) => {
    const newFormData = [...formData];
    newFormData[index].checked = !newFormData[index].checked;
    setFormData(newFormData);

    if (newFormData[index].checked) {
      setValue(`radio${index}`, 'direct');
    } else {
      setValue(`radio${index}`, null);
    }

    submitForm();
  };

  const handleRadioChange = (index, value) => {
    if (value === 'direct') {
      const newFormData = [...formData];
      newFormData[index].checked = true;
      setFormData(newFormData);
    }

    setValue(`radio${index}`, value);

    submitForm();
  };

  const submitForm = () => {
    const formDataToSend = formData.map((item, index) => ({
      id: item.id,
      checked: item.checked,
      type: getValues(`radio${index}`),
    }));

    api(`/legislative/${legislative}/legislative_applies_to/`, formDataToSend, 'PATCH')
  };

  return (
    <>
      {formData.length > 0 && <Title style={{ marginTop: 15, marginBottom: 5 }}>Applies To:</Title>}
      <form>
        {formData.map((item, index) => (
          <div style={{ display: 'flex', marginBottom: 5, alignItems: 'center' }} key={index}>
            <Controller
              name={`checkbox${index}`}
              control={control}
              render={({ field }) => (
                <CustomCheckbox
                  style={{ marginRight: 15 }}
                  {...field}
                  checked={item.checked}
                  onChange={() => handleCheckboxChange(index)}
                >
                  {item.text}
                </CustomCheckbox>
              )}
            />
            <Row>
              <Controller
                name={`radio${index}`}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Radio.Group
                    {...field}
                    value={getValues(`radio${index}`) || item.type}
                    onChange={(e) => handleRadioChange(index, e.target.value)}
                    disabled={!item.checked}

                  >
                    <CustomRadio value="direct">Direct</CustomRadio>
                    <CustomRadio value="indirect">Indirect</CustomRadio>
                  </Radio.Group>
                )}
              />
            </Row>
          </div>
        ))}
      </form>
    </>

  );
};

export default AppliesTo;
