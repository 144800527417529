import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { useStore } from '../../../../../mobx-store/context'
import ResourceModal from '../../../components/ResourceAccessModal/resourceModal'
import React from 'react'
import List from './list'
import EmailSettingsTable from './Table'
import { toJS } from 'mobx'
import { Title, SubTitle } from './Table/style'



const ProposedTab = observer(({ setState, state, site }) => {
  const store = useStore()
  const history = useHistory()

  const titles = site ? ['Alerts'] : ['Emails']

  const proposedEmailOptions = site ? store?.user[0]?.proposed_source_site_notifications : store?.user[0]?.proposed_source_notifications

  const ProposedSettings = [
    {
      key: 'legislation_updates',
      name: 'Legislation Updates',
    },
  ];
  const ProposedSiteSettings = [
    {
      key: 'legislation_updates_site_notifications',
      name: 'Legislation Updates',
    },
  ];

  if (!store.user?.[0]?.have_access_to_proposed_source || !store.company?.[0]?.have_access_to_proposed_source)
    return (
      <ResourceModal
        type='proposedSource'
        to='/edit-profile'
        onClose={() => history.push('/edit-profile')}
      />
    )

  return (
    <>
      <Title>ProposedSource</Title>
      <SubTitle>Select notifications times for the following actions.</SubTitle>
      <EmailSettingsTable
        site={site}
        titles={titles}
        tasksSetings={false}
        initialSettings={site ? ProposedSiteSettings : ProposedSettings}
        initialTaskSettings={{}}
        initialValues={toJS(proposedEmailOptions)}
        setState={setState}
        state={state}
        isTaskTable={false}
        isProposedSource={true}
      />
      <List />
    </>
  )
})

export default ProposedTab
