import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { useStore } from '../../../../../mobx-store/context'
import ResourceModal from '../../../components/ResourceAccessModal/resourceModal'
import React from 'react'
import EmailSettingsTable from './Table'
import { toJS } from 'mobx'
import { Title, SubTitle } from './Table/style'
import {
  LicenseSettings,
  LicenseTaskSettings,
  LicenseSiteSettings,
  LicenseSiteTaskSettings,
  MemberSiteLicenseTaskSettings,
  MemberLicenseTaskSettings,
} from './static'


const LicenseTab = observer(({ setState, state, site }) => {
  const store = useStore()
  const history = useHistory()
  const licenseEmailOptions = site ? store?.user[0]?.license_source_site_notifications : store?.user[0]?.license_source_notifications

  const isMember = store?.user[0]?.license_source_role === 'member'


  const initialSettings = site ? LicenseSiteSettings : LicenseSettings

  const initialTaskSettings = site
    ? isMember
      ? MemberSiteLicenseTaskSettings
      : LicenseSiteTaskSettings
    : isMember
      ? MemberLicenseTaskSettings
      : LicenseTaskSettings;

  const titles = site ? ['LicenseSource Owner Alerts', 'Task Owner Alerts'] : ['LicenseSource Owner Emails', 'Task Owner Emails']

  if (!store.user?.[0]?.have_access_to_license_source || !store.company?.[0]?.have_access_to_license_source)
    return (
      <ResourceModal
        type='licenseSource'
        to='/edit-profile'
        onClose={() => history.push('/edit-profile')}
      />
    )

  return (
    <>
      <Title>LicenseSource</Title>
      <SubTitle>Select notifications times for the following actions.</SubTitle>
      <EmailSettingsTable
        site={site}
        titles={titles}
        tasksSetings={true}
        initialSettings={initialSettings}
        initialTaskSettings={initialTaskSettings}
        initialValues={toJS(licenseEmailOptions)}
        setState={setState}
        state={state}
        mainSettings={!isMember}
        isTaskTable={true}
        isReportSource={false}
        isLicenseSource={true}
      />
    </>
  )
})

export default LicenseTab
