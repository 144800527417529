
import Modal from '../../../components/common/ModalNew'
import { Description, Title } from './style'
import ScheduleInput from '../.../../../Home/Main/Demo/ScheduleInput'


const ScheduleDemoModal = ({ onClose, service = 'Home Page' }) => {

  return (
    <Modal styleWrap={{ width: 520, height: 360, padding: '20px 40px' }} onClose={onClose}>
      <Title>Schedule a Demo</Title>
      <Description>Reach out to our team at PBMSource to schedule a personalized demonstration to meet your organization’s needs.</Description>
      <ScheduleInput border style={{ width: 440 }} onClose={onClose} service={service} />
    </Modal>
  )
}

export default ScheduleDemoModal