import styled from 'styled-components'

export const Wrap = styled("div")`
  height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
  img {
    height: 400px;
  }
`;

export const Title = styled("div")`
  font-family: var(--regular);
  font-size: 48px;
  color: var(--blue);
  margin-top: 70px;
  margin-bottom: 50px;
`;
