import styled from 'styled-components'

export const Wrap = styled('div')`
  display: flex;
  font-size: 14px;
  align-items: flex-start;
  margin-right: 15px;
  border: 2px solid ${p => p.clickable
    ? p.active ? 'var(--orange)' : '#eaeaea'
    : '#fff'};
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
`

export const Image = styled('img')`
  width: 36px;
  height: 36px;
  margin-right: 10px;
`
