import styled from 'styled-components'

export const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 200px;
  gap: 30px;
  padding: 0 2.313em 50px 2.313em;
  .ant-table-expanded-row .ant-table-cell {
    padding: 0 !important;
  }
`

export const Button = styled('div')`
  border: 1px solid ${(p) => (p.active ? p.color : 'rgba(0, 0, 0, 0.1)')};
  border-radius: 4px;
  height: 58px;
  display: flex;
  align-items: center;
  padding-left: 13px;
  font-size: 22px;
  font-family: var(--regular);
  margin-top: 15px;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};
  span {
    font-family: var(--bold);
    padding-left: 5px;
  }
`

export const ExportText = styled('div')`
  color: #969fa2;
  font-size: 12px;
  font-family: var(--light);
  text-align: center;
  margin-top: 10px;
`

export const ReportingContainer = styled('div')`
max-height: 53vh;
overflow-y: auto;
&::-webkit-scrollbar {
  width: 8px;
  position: absolute;
}

&::-webkit-scrollbar-thumb {
  background-color: var(--grey);
  border-radius: 5px;
  width: 8px;
}

@media (max-width: 1350px) {
  padding-right: 2em;
  margin-bottom: 20px;
}
@media (max-height: 1200px) {
  max-height: 76vh;
}
@media (max-height: 1050px) {
  max-height: 73vh;
}
@media (max-height: 915px) {
  max-height: 66vh;
}
@media (max-height: 880px) {
  max-height: 69vh;
}
@media (max-height: 800px) {
  max-height: 65vh;
}
@media (max-height: 750px) {
  max-height: 65vh;
}
`
