import styled from 'styled-components'

export const Wrap = styled('div')`
  display: flex;
  box-shadow: 0 4px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 15px;
`

export const Item = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;

  span {
    border-top: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 47px;
    font-size: 0.875em;
    font-family: var(--semiBold);
    color: var(--green);
  }
`
