import styled from 'styled-components'

export const Wrap = styled("div")`
  width: 39em;
  padding-left: 3em;
  padding-top: 7em;
  @media (max-width: 1350px) {
    padding-left: 0;
    padding-top: 3em;
  }
`;

export const Title = styled("div")`
  color: var(--grey);
  font-size: 3em;
  font-family: var(--open-sans-bold);
  line-height: 1em;
  display: flex;
  div {
    margin-right: 0.5em;
    transform-origin: center;
    transform: rotate(90deg) translateX(-10px);
    height: 70px;
  }
`;

export const Description = styled("div")`
  font-family: var(--open-sans-reg);
  font-size: 1.125em;
  color: var(--text);
  width: 34em;
  padding-left: 3.5em;
  margin-top: 1em;
`;
