import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { message } from 'antd'
import bg from '../../../assets/images/add-member-bg.svg'
import Logo from '../../../components/common/Logo/logo'
import SkipButton from '../../../components/common/SkipButton/skipButton'
import StatusBar from '../../../components/common/StatusBar/status'
import { useStore } from '../../../mobx-store/context'
import { Description, Title } from '../authForm.style'
import { Row } from '../TopicsAndJur/topic.style'
import { Grid, Left, Right, UnderBG, Wrap } from './addMember.style'
import { api } from '../../../api'
import { getError, formatDate } from '../../../helpers/helpers'
import Form from './Form/form'
import MembersList from './membersList'
import UsersFromTracking from './UserFromTracking/usersFromTracking'


const AddMember = observer(({ isResource, isReportSource, isProposedSource }) => {
  const history = useHistory()
  const store = useStore()
  const [editing, setEditing] = useState(false)
  const [defaultValues, setDefaultValues] = useState({
    jurisdictions: [],
    topics: [],
    line_of_business: [],
    resource_guides: [],
    resource_jurisdictions: [],
    assign: 'jurisdictions',
  })
  const [addedUsers, setAddedUsers] = useState([])
  const [existing, setExisting] = useState(false)
  const [editingId, setEditingId] = useState(null)
  const company = store.company?.[0]

  const editUser = (user) => {
    const index = addedUsers.findIndex((item) => item.id === user.id)

    const newUsers = addedUsers

    newUsers[index] = user

    setAddedUsers(newUsers)
  }

  const onSubmit = () => {
    if (isResource) return history.push('/resource/billing')
    if (isReportSource) return history.push('/report-source-billing')
    if (isProposedSource) return history.push('/proposed-source-billing')
    patchCompany() 
    startTrial()
  }

  const patchCompany = () => {
    api(
      `/companies/${store.company[0].id}/`,
      {
        start_date: formatDate(new Date(), true),
        have_access_to_tracking: true,
      },
      'PATCH'
    ).then((data) => {
      if (!data.errors) {
        store.setSingleValue('company', [data])
      }
    })
  }

  const startTrial = () => {
    if (!isResource) {
      api('/payments/start_trial/', {}, 'POST').then((data) => {
        if (data.errors) return message.error(getError(data))
        store.getCompany(() => {
          store.getUser(() => {
            history.push(
              isResource ? '/resource/table/guides' : '/tracking/main'
            )
          })
        })
      })
    } else {
      history.push(
        isResource ? '/resource/table/guides' : '/tracking/main'
      )
    }
  }

  useEffect(() => {
    if (store.user.member_type) {
      if (store.user[0].member_type === 'user') history.push('/tracking')
    } else {
      store.getUser()
    }
  }, [])

  const handleEdit = (id, existing) => {
    setEditing(true)
    setEditingId(id)
    setDefaultValues(store.getAddedMember(id, existing))
  }

  useEffect(() => {
    store.loadMembers(null, isReportSource)
  }, [])

  if (!store.user.length || store?.user[0]?.member_type === 'member')
    return null

  const skipButtonCheck = isResource
    ? store.addedMembers.length <= 0
    : store.addedMembers.length <= 0

  return (
    <Wrap>
      <Row
        style={{
          margin: '0 auto',
          width: '80vw',
          maxWidth: '80vw',
          padding: 0,
        }}
      >
        <Logo large />
      </Row>
      <Grid>
        <Left>
          <StatusBar style={{ marginTop: '1em' }} step={5} />
          <Title style={{ marginTop: '0.5em' }}>Add To The Team</Title>
          <Description>
            {isResource
              ? "Add team members to allow them to get regular updates on the ReSource guides you've signed up for."
              : 'Add colleagues for collaboration and assessment of compliance with new laws, rules, regulations, and sub-regulatory guidance.'}
          </Description>
          {skipButtonCheck && (
            <SkipButton
              mainText={'Skip adding team members  >>'}
              secondaryText={'( they can be added at any time)'}
              onClick={() => onSubmit()}
              style={{
                display: 'inline',
                fontFamily: 'var(--regular)',
              }}
            />
          )}

          <Form
            editing={editing}
            defaultValues={defaultValues}
            handleEditing={() => {
              setEditing(false)
              setDefaultValues({
                jurisdictions: [],
                resource_guides: [],
                resource_jurisdictions: [],
                topics: [],
                assign: 'jurisdictions',
                line_of_business: [],
              })
            }}
            onAddUser={(user) => setAddedUsers([...addedUsers, user])}
            onEditUser={(user) => editUser(user)}
            existing={existing}
            editingId={editingId}
            isResource={isResource}
            isProposedSource={isProposedSource}
            isReportSource={isReportSource}
          />
        </Left>
        <Right empty={store.addedMembers.length === 0}>
          {(isResource ||
            (company?.have_access_to_resource &&
              !company?.have_access_to_tracking)) && (
              <UsersFromTracking isResource={isResource} />
            )}
          {store.addedMembers.length > 0 || store.members.length > 1 ? (
            <MembersList
              handleEdit={(id, existing) => {
                handleEdit(id, existing)
                setExisting(existing)
              }}
              users={addedUsers}
              handleDelete={() => {
                setEditing(false)
                setDefaultValues({
                  jurisdictions: [],
                  topics: [],
                  resource_guides: [],
                  resource_jurisdictions: [],
                  assign: 'jurisdictions',
                })
              }}
              clearUsers={() => setAddedUsers(null)}
              isResource={isResource}
              isReportSource={isReportSource}
              isProposedSource={isProposedSource}
            />
          ) : (
            <>
              <img src={bg} style={{ margin: '0 auto' }} alt='bg' />
              <UnderBG>
                Invite your team members to begin collaborating on PBMSource.
              </UnderBG>
            </>
          )}
        </Right>
      </Grid>
    </Wrap>
  )
})

export default AddMember
