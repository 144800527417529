import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useStore } from "../../../../../mobx-store/context";
import moment from 'moment'
import { observer } from "mobx-react-lite";
import { StyledSwitch } from "../../../CreateCompany/pages/FirstPage/style";
import { Select } from 'antd'
import { SwitchTitle, SwitchText, Row, DetailsWrap, AllCheckboxContainer, TableTitle } from "../../../CreateCompany/pages/ManageProducts/style";
import { useForm } from "react-hook-form";
import Input from "../../../../../components/common/Input";
import { Divider, Checkbox } from "antd";
import Button from "../../../../../components/common/Button/button";
import { PrevBtn } from "../../../CreateCompany/pages/FirstPage/Form/style";
import CheckboxGroup from '../../../CreateCompany/pages/ManageProducts/CheckboxGroup'
import { api } from "../../../../../api";
import { TextContainer, Text } from "../../style";

const ComplianceTab = observer(({ handleTabChange, active, companyId, user }) => {
  const store = useStore()
  const history = useHistory()
  const { handleSubmit, control, getValues, watch, errors } = useForm({
    defaultValues: {
    },
  })
  const watchValues = watch();
  const [hasAccesstoCS, setHasAccesstoCS] = useState(store.editedCmsUser.have_access_to_tracking)

  const [allJurisdictions, setAllJurisdictions] = useState(store.editedCmsUser.all_jurisdictions)
  const [allTopics, setAllTopics] = useState(store.editedCmsUser.all_topics)
  const [allLinesOfBusiness, setAllLinesOfBusiness] = useState(store.editedCmsUser.all_line_of_business)
  const [jursCount, setJursCount] = useState(store.editedCmsUser.jurisdictions?.length)
  const [topicsCount, setTopicsCount] = useState(store.editedCmsUser.topics?.length)
  const [linesOfBusinessCount, setLinesOfBusinessCount] = useState(store.editedCmsUser.line_of_business?.length)

  const rolesMap = [
    {
      name: 'ComplianceSource Admin',
      value: 'admin'
    },
    {
      name: 'ComplianceSource Team Member',
      value: 'member'
    },
    {
      name: 'ComplianceSource Owner',
      value: 'compliance_owner'
    },
  ]

  const updateUser = (values) => {
    const data = {
      ...values,
      have_access_to_tracking: hasAccesstoCS,
      all_jurisdictions: allJurisdictions,
      all_topics: allTopics,
      all_line_of_business: allLinesOfBusiness,
      member_type: values.member_type,
    }
    api(`/cms/users/${store.editedCmsUser.id}/`, data, 'PATCH').then((res) => {
      if (!res.errors)
        store.setSingleValue('editedCmsUser', res)
    })
  }

  const onSubmit = (values) => {
    updateUser(values)
  }

  useEffect(() => {
    setJursCount((getValues('jurisdictions') && getValues('jurisdictions').length) || 0)
    setTopicsCount((getValues('topics') && getValues('topics').length) || 0)
    setLinesOfBusinessCount((getValues('line_of_business') && getValues('line_of_business').length) || 0)
  }, [watchValues]);

  useEffect(() => {
    updateUser(getValues())
  }, [hasAccesstoCS,
    allJurisdictions,
    allTopics,
    allLinesOfBusiness,
    watch('jurisdictions'),
    watch('topics'),
    watch('line_of_business'),
    watch('member_type')
  ])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <form style={{ width: 710 }} onSubmit={handleSubmit(onSubmit)}>
      <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: 25, marginBottom: 20, flexDirection: 'column' }}>
        <SwitchTitle>Has Access to ComplianceSource</SwitchTitle>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 10, }}>
          <StyledSwitch
            defaultChecked={store.editedCmsUser.have_access_to_tracking}
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={(checked) => { setHasAccesstoCS(checked) }}
          />
          <TextContainer style={{ marginLeft: 10 }}>
            <Text>{store.editedCmsUser.access_to_tracking_granted_date}</Text>
          </TextContainer>
        </div>
      </div>
      <Row>
        <Input
          smallLabel
          type={'select'}
          customOptions={
            rolesMap.map((item) => (
              <Select.Option value={item.value}>
                {item.name}
              </Select.Option>
            ))
          }
          name="member_type"
          label="User Role for ComplianceSource"
          defaultValue={store.editedCmsUser.member_type}
          control={control}
          errors={errors}
        />
      </Row>
      <SwitchTitle>Select Jurisdictions, Topics or Lines of Business for this user. Choices will be limited to what the company <br /> has access to:</SwitchTitle>
      <DetailsWrap style={{ marginTop: 20 }}>
        <TableTitle>Jurisdictions ({allJurisdictions ? 'All' : jursCount})</TableTitle>
        <TableTitle>Topics ({allTopics ? 'All' : topicsCount})</TableTitle>
        <TableTitle>Lines of Business ({allLinesOfBusiness ? 'All' : linesOfBusinessCount})</TableTitle>
      </DetailsWrap>
      <DetailsWrap>
        <AllCheckboxContainer>
          <div> All Jurisdictions </div>
          <Checkbox
            onChange={(e) => setAllJurisdictions(e.target.checked)}
            name={'all_jurisdictions'}
            style={{ marginLeft: 0, marginRight: 15 }}
            checked={allJurisdictions}>
          </Checkbox>

        </AllCheckboxContainer>
        <AllCheckboxContainer>
          <div> All Topics </div>
          <Checkbox
            onChange={(e) => setAllTopics(e.target.checked)}
            name={'all_topics'}
            style={{ marginLeft: 0, marginRight: 6 }}
            checked={allTopics}>
          </Checkbox>

        </AllCheckboxContainer>
        <AllCheckboxContainer>
          <div> All Lines Of Business</div>
          <Checkbox
            onChange={(e) => setAllLinesOfBusiness(e.target.checked)}
            name={'all_line_of_business'}
            checked={allLinesOfBusiness}
            style={{ marginLeft: 0, marginRight: 8 }}>
          </Checkbox>

        </AllCheckboxContainer>
      </DetailsWrap>
      {(!allJurisdictions || !allTopics || !allLinesOfBusiness) &&
        <DetailsWrap >
          <CheckboxGroup
            control={control}
            name={'jurisdictions'}
            options={store?.cms_company_options.jurisdictions}
            disabled={allJurisdictions}
            defaultValue={store.editedCmsUser.jurisdictions}
          />
          <CheckboxGroup
            control={control}
            name={'topics'}
            options={store?.cms_company_options.topics}
            disabled={allTopics}
            defaultValue={store.editedCmsUser.topics}
          />
          <CheckboxGroup
            control={control}
            name={'line_of_business'}
            options={store?.cms_company_options.line_of_business}
            disabled={allLinesOfBusiness}
            defaultValue={store.editedCmsUser.line_of_business}
          />
        </DetailsWrap>
      }
    </form>
  )
});

export default ComplianceTab;