import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useStore } from "../../../../../mobx-store/context";
import moment from 'moment'
import { observer } from "mobx-react-lite";
import { StyledSwitch } from "../../../CreateCompany/pages/FirstPage/style";
import { SwitchTitle, SwitchText, Row, DetailsWrap, AllCheckboxContainer, TableTitle } from "../../../CreateCompany/pages/ManageProducts/style";
import { useForm } from "react-hook-form";
import Input from "../../../../../components/common/Input";
import { Divider, Checkbox } from "antd";
import Button from "../../../../../components/common/Button/button";
import { PrevBtn } from "../../../CreateCompany/pages/FirstPage/Form/style";
import CheckboxGroup from '../../../CreateCompany/pages/ManageProducts/CheckboxGroup'
import { formatCurrency } from "../../../helpers";
import { api } from "../../../../../api";
import EndDateConfirmModal from "../../../EditCompany/Tabs/Resource/GuideGroup/EndDateConfirmModal";

const ReportTab = ({ handleTabChange }) => {
  const history = useHistory()
  const store = useStore()
  const { handleSubmit, control, getValues, watch, setValue } = useForm({
    defaultValues: {
      report_source_registration_date: store?.cmsCompany[0]?.report_source_registration_date,
      report_source_next_payment_date: store?.cmsCompany[0]?.report_source_next_payment_date,
      show_report_items_due_date: store?.cmsCompany[0]?.show_report_items_due_date,
      report_source_custom_price: formatCurrency(store?.cmsCompany[0]?.report_source_custom_price) || '',
      report_source_mode: store?.cmsCompany[0]?.report_source_mode,
      report_source_internal_notes: store?.cmsCompany[0]?.report_source_internal_notes || '',
      
    }
  })

  const [endDateModalVisible, setEndDateModalVisible] = useState(false)
  const [tempEndDate, setTempEndDate] = useState(null)
  const [hasAccesstoRS, setHasAccesstoRS] = useState(store?.cmsCompany[0]?.have_access_to_report_source || false)
  const [hasCustomPrice, setHasCustomPrice] = useState(store?.cmsCompany[0]?.has_report_source_custom_pricing || false)
  const [allJurisdictions, setAllJurisdictions] = useState(store?.cmsCompany[0]?.all_report_jurisdictions || false)
  const [allTypes, setAllTypes] = useState(store?.cmsCompany[0]?.all_report_types || false)
  const [allLinesOfBusiness, setAllLinesOfBusiness] = useState(store?.cmsCompany[0]?.all_report_line_of_business || false)
  const [jursCount, setJursCount] = useState(store?.cmsCompany[0]?.report_jurisdictions?.length || 0)
  const [typesCount, setTypesCount] = useState(store?.cmsCompany[0]?.report_types?.length || 0)
  const [linesOfBusinessCount, setLinesOfBusinessCount] = useState(store?.cmsCompany[0]?.report_line_of_business?.length || 0)

  const watchValues = watch();

  const updateCompany = (values) => {
    const data = {
      ...values,
      report_source_internal_notes: values.report_source_internal_notes || '',
      report_source_registration_date: values.report_source_registration_date ? moment(values.report_source_registration_date).format('YYYY-MM-DD') : null,
      report_source_next_payment_date: values.report_source_next_payment_date ? moment(values.report_source_next_payment_date).format('YYYY-MM-DD') : null,
      show_report_items_due_date: values.show_report_items_due_date ? moment(values.show_report_items_due_date).format('YYYY-MM-DD') : null,
      have_access_to_report_source: hasAccesstoRS,
      has_report_source_custom_pricing: hasCustomPrice,
      report_source_custom_price: hasCustomPrice && values.report_source_custom_price
        ? values.report_source_custom_price.replace(/[^0-9.]/g, '')
        : null,
      all_report_jurisdictions: allJurisdictions,
      all_report_types: allTypes,
      all_report_line_of_business: allLinesOfBusiness,
    }
    api(`/cms/companies/${store?.cmsCompany[0]?.id}/`, data, 'PATCH').then((res) => {
      if (!res.errors)
        store.setSingleValue('cmsCompany', [res])

    })
  }



  const onSubmit = (values) => {
    updateCompany(values)
    handleTabChange('users')
  }



  useEffect(() => {
    setJursCount((getValues('report_jurisdictions') && getValues('report_jurisdictions').length) || 0)
    setTypesCount((getValues('report_types') && getValues('report_types').length) || 0)
    setLinesOfBusinessCount((getValues('report_line_of_business') && getValues('report_line_of_business').length) || 0)
  }, [watchValues]);

  useEffect(() => {
    updateCompany(getValues())
  }, [watchValues])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
        {endDateModalVisible && <EndDateConfirmModal onClose={() => setEndDateModalVisible(false)} setValue={() => setValue('report_source_next_payment_date', tempEndDate)} />}
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 25, marginBottom: 20 }}>
        <StyledSwitch
          defaultChecked={hasAccesstoRS}
          checkedChildren="Yes"
          unCheckedChildren="No"
          onChange={(checked) => { setHasAccesstoRS(checked) }}
        />
        <div style={{ marginLeft: 10 }} >
          <SwitchTitle>Has Access Granted to ReportSource:</SwitchTitle>
          <SwitchText>Company will have access to ComplianceSource starting this date, the date they can first log in.</SwitchText>
        </div>
      </div>
      <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Input
          smallLabel
          name={'report_source_registration_date'}
          label={'Start Date'}
          type={'date'}
          showCross={false}
          control={control}
          validation={{ required: false }}
          transparent
          placeholder={'Select Date'}
          noLimit
        />
        <Input
          smallLabel
          name={'report_source_next_payment_date'}
          label={'End Date'}
          type={'date'}
          showCross={true}
          control={control}
          validation={{ required: false }}
          transparent
          placeholder={'Select Date'}
          noLimit
          handleChange={(e) => {
            setTempEndDate(e)
            setEndDateModalVisible(true)
          }}
          handleReset={() => setValue('report_source_next_payment_date', null)}
        />
      </Row >
      <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Input
          smallLabel
          name={'show_report_items_due_date'}
          label={'Show Report Items with a Due Date as of:'}
          showCross={false}
          type={'date'}
          control={control}
          validation={{ required: false }}
          transparent
          placeholder={'Select Date'}
          noLimit
          disableFutureDates
        />
        <Input
          smallLabel
          name={'report_source_mode'}
          label={'ReportSource Type'}
          type={'select'}
          options={[
            { id: 'report_source', name: 'ReportSource' },
            { id: 'report_source_lite', name: 'ReportSource Lite' },
          ]}
          control={control}
          transparent
          placeholder={'Select'}
        />
      </Row>
      {/* <Divider style={{ background: '#CCC' }} /> */}
      {/* <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 20 }}>
        <StyledSwitch
          defaultChecked={hasCustomPrice}
          checkedChildren="Yes"
          unCheckedChildren="No"
          onChange={(checked) => { setHasCustomPrice(checked) }}
        />
        <div style={{ marginLeft: 10 }} >
          <SwitchTitle>Has Custom Pricing</SwitchTitle>
          <SwitchText>Show a custom price, if not selected no price will <br /> show in the admin’s settings page.</SwitchText>
        </div>
      </div >
      {hasCustomPrice &&
        <Input
          smallLabel
          label={'Custom Price'}
          disabled={!hasCustomPrice}
          name={'report_source_custom_price'}
          type={'text'}
          control={control}
          validation={{ required: true }}
          transparent
          placeholder={'Custom Price'}
          error={errors && errors.report_source_custom_price && 'Enter valid custom price'}
          style={{ width: '48%' }}
        />
      } */}

      <Divider style={{ background: '#CCC' }} />
      <SwitchTitle>Select which Jurisdictions, Report Types or Lines of Business the company has access to:</SwitchTitle>
      <DetailsWrap>
        <TableTitle>Jurisdictions ({allJurisdictions ? 'All' : jursCount})</TableTitle>
        <TableTitle>Report Types ({allTypes ? 'All' : typesCount})</TableTitle>
        <TableTitle>Lines of Business ({allLinesOfBusiness ? 'All' : linesOfBusinessCount})</TableTitle>
      </DetailsWrap>
      <DetailsWrap>
        <AllCheckboxContainer>
          <div>All Jurisdictions</div>
          <Checkbox
            checked={allJurisdictions}
            onChange={(e) => setAllJurisdictions(e.target.checked)}
            name={'all_jurisdictions'}
            style={{ marginLeft: 0 }}
          />
        </AllCheckboxContainer>
        <AllCheckboxContainer>
          <div>All Report Types</div>
          <Checkbox
            checked={allTypes}
            onChange={(e) => setAllTypes(e.target.checked)}
            name={'all_topics'}
            style={{ marginLeft: 0 }} />
        </AllCheckboxContainer>
        <AllCheckboxContainer>
          <div> All Lines of Business</div>
          <Checkbox
            checked={allLinesOfBusiness}
            onChange={(e) => setAllLinesOfBusiness(e.target.checked)}
            name={'all_line_of_business'}
            style={{ marginLeft: 0 }} />
        </AllCheckboxContainer>
      </DetailsWrap>
      {(!allJurisdictions || !allTypes || !allLinesOfBusiness) &&
        <DetailsWrap >
          <CheckboxGroup
            defaultValue={store?.cmsCompany[0]?.report_jurisdictions}
            control={control}
            name={'report_jurisdictions'}
            options={store?.cms_options.jurisdictions}
            disabled={allJurisdictions} />
          <CheckboxGroup
            defaultValue={store?.cmsCompany[0]?.report_types}
            control={control}
            name={'report_types'}
            options={store?.cms_options.report_types}
            disabled={allTypes} />
          <CheckboxGroup
            defaultValue={store?.cmsCompany[0]?.report_line_of_business}
            control={control}
            name={'report_line_of_business'}
            options={store?.cms_options.line_of_business}
            disabled={allLinesOfBusiness} />
        </DetailsWrap>
      }
       <Divider style={{ background: '#CCC' }} />
      <div>
        <Input
          smallLabel
          name={'report_source_internal_notes'}
          label={'Internal Notes Only'}
          type={'textarea'}
          control={control}
          transparent
          placeholder={''}
          style={{ marginBottom: '0.3em' }}
        />
      </div>
      <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PrevBtn onClick={() => history.goBack()}>Previous</PrevBtn>
        <Button
          text={'Next'}
          htmlType="submit"
          type={'main'}
          style={{
            width: '10%',
            padding: 0,
            marginRight: 0,
            marginLeft: '.5em',
            marginTop: '2.3em',
          }}
        />
      </Row>
    </form>
  )
};

export default ReportTab;