import styled from 'styled-components'

export const Wrap = styled("div")`
  height: ${(props) => props.serviceName === 'LicenseSource'
    ? '100%' 
    : '100vh'};
  margin-top: ${props=> props.serviceName === 'LicenseSource' ? '50px' : '0px'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    height: 400px;
  }
  @media(max-height: 800px){
    margin-top: 50px;
    height: 100%;
  }
`;

export const Title = styled("div")`
  font-family: var(--regular);
  font-size: 36px;
  line-height: 40px;
  color: var(--black);
  margin-top: 70px;
  margin-bottom: 50px;
  strong {
    font-family: var(--medium);
    color: ${(props) => props.serviceName === 'ComplianceSource'
    ? 'var(--blue)'
    : props.serviceName === 'ReSource'
      ? 'var(--green)'
      : props.serviceName === 'ReportSource'
        ? 'var(--orange)'
        : props.serviceName === 'LicenseSource'
          ? '#553BA1'
          : 'var(--text)'};
  };
`;

export const Text = styled("div")`
  text-align: center;
  width: 900px;
  font-family: var(--light);
  font-size: 25px;
  line-height: 31px;
  color: var(--black);
  margin-top: 21px;
  strong {
    font-family: var(--medium)
    color: var(--green)
  }
  a {
    color: var(--orange);
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const AdvantagesTitle = styled("div")`
  text-align: center;
  font-family: var(--bold);
  font-size: 36px;
  color: var(--text);
`;

export const AdvantagesWrap = styled("div")`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

export const AdvantagesText = styled("div")`
  font-family: var(--regular);
  font-size: 19px;
  line-height: 30px;
  color: var(--text);
  text-align: left;
  margin-top: 20px;
  span {
  font-family: var(--semiBold);
  font-size: 19px;
  color: var(--text);
  }
`;

export const BenefitsWrap = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  width: 50%;
  margin-bottom: 100px;
`;