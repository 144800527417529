import React from 'react';
import Modal from '../../../../../components/common/ModalNew/index'
import Button from '../../../../../components/common/Button/button'
import { Label } from '../../../../../components/common/Input/input.style'
import { Text } from '../../right.style'
import { Cancel } from '../../Tasks/Modal/style'
const EditorSaveModal = ({ onClose, onSubmit }) => {
  return (
    <Modal
      styleWrap={{
        width: 'auto',
        height: 'auto',
        minHeight: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: '1px solid var(--grey)',
        padding: '50px',

      }}
      onClose={onClose}>
      <Label style={{ fontSize: '1.5em' }}>Continue Without Saving?</Label>
      <Text>You have unsaved edits. Do you want to save your changes?</Text>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 60 }}>
        <Cancel onClick={onClose}>Don’t Save</Cancel>
        <Button text='Save and Continue' onClick={() => onSubmit()} />
      </div>
    </Modal>
  )
}

export default EditorSaveModal
