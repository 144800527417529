import React from 'react'
import { Circle, Container, Wrap } from './switch.style'

const Switch = ({ onChange, active, text, half }) => {
  return (
    <Container half={half}>
      <Wrap active={active} onClick={onChange}>
        <Circle active={active} />
      </Wrap>
      {text}
    </Container>
  )
}

export default Switch
