import { Table as AntTable } from 'antd'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useStore } from '../../../../mobx-store/context'
import { None } from '../../Resource/RightSide/style'
import { TableWrap } from '../style'
import './style.css'
import GuideNameTooltip from '../../../../components/common/GuideNameWithTooltip'

const Table = observer(() => {
  const history = useHistory()
  const store = useStore()
  const params = useParams()
  const location = useLocation()

  const isGuides = params.type === 'guides'

  const getJurisdictionCode = (id) => {
    return store.resourceFilters?.jurisdictions.find(
      (item) => item[0] === id
    )[1]
  }

  const getArrItems = (ids, type) => {
    return (
      ids
        ?.map(
          (item) =>
            store.resourceFilters[type]?.find(
              (newItem) => newItem[0] === item
            )[1]
        )
        .join(', ') || ''
    )
  }

  const removeDuplicatesFromResource = (arr) => {
    let used = {};
    let filtered = arr.filter((obj) => {

      return obj.jurisdiction in used ? 0 : (used[obj.jurisdiction] = 1);
    })

    return filtered
      .sort((x, y) => {
        return x.jurisdiction === 1 ? -1 : y.jurisdiction === 1 ? 1 : 0;
      })
  }

  const columns = [
    {
      title: 'ReSource Guides',
      dataIndex: 'name',
      key: 'name',
      // sorter: {
      //   compare: (a, b) => a.name.localeCompare(b.name),
      // },
      align: 'left',
      width: 550,
      render: (text, row) => (
        <GuideNameTooltip guideName={text} description={row?.description} />
      ),
    },

    {
      title: 'Requirement',
      dataIndex: 'rows',
      key: 'requirements',
      width: 550,
      render: (text) => text && (text[0].requirements ? 'Yes' : 'No'),
      // sorter: {
      //   compare: (a, b) => a.rows[0].requirements - b.rows[0].requirements,
      // },
      align: 'left',
    },

  ]

  const columnsTopic = [
    {
      title: 'Jurisdiction',
      dataIndex: 'jurisdiction',
      key: 'jurisdiction',
      width: 550,
      render: (text) => (
        <div>{getJurisdictionCode(text)}</div>
      ),
      // sorter: {
      //   compare: (a, b) =>
      //     getJurisdictionCode(a.jurisdiction).localeCompare(
      //       getJurisdictionCode(b.jurisdiction)
      //     ),
      // },
      align: 'left',
    },
    {
      title: 'Requirement',
      dataIndex: 'requirements',
      key: 'requirements',
      render: (text) => (text ? 'Yes' : 'No'),
      width: 550,
      // sorter: {
      //   compare: (a, b) => a.requirements - b.requirements,
      // },
      align: 'left',
    },
  ]

  const onRowClick = (record) => {

    return {
      onClick: () => {
        if (!record.requirements && !record.rows?.[0]?.requirements) return
        store.setSingleValue('resourcesCurrent', record)
        if (isGuides) {
          history.push(
            `/resource/guides-jurisdictions-list/${params.type}/${record.id || record.jurisdiction
            }/${store.resourcesSelect?.value}/${record.jurisdiction}`
          )
        } else {
          store.getResourceTopic(record.id || record.jurisdiction, () => {
            history.push(
              `/resource/guides-jurisdictions-list/${params.type}/${record.id || record.jurisdiction
              }/${store.resourcesSelect?.value}`
            )
          }, null, store.resourcesSelect?.value)
        }
        store.setResourcePrevLink([
          ...store.resourcePrevLink,
          location.pathname,
        ])
      },
    }
  }

  return (
    <TableWrap>
      <AntTable
        dataSource={
          isGuides
            ? removeDuplicatesFromResource(toJS(store.resourceFilteredTopics))
            : toJS(store.resourceFiltered)
        }
        columns={isGuides ? columnsTopic : columns}
        pagination={false}
        rowClassName={(record) =>
          (record.requirements || record.rows?.[0]?.requirements) &&
          'active-row'
        }
        onRow={onRowClick}
      />
    </TableWrap>
  )
})

export default Table
