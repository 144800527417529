import React from 'react'
import { Row, Wrap } from './topic.style'
import { Title } from '../authForm.style'
import Form from './Form/form'
import Logo from '../../../components/common/Logo/logo'
import SelectedPlan from '../../../components/common/SelectedPlan/selectedPlan'
import StatusBar from '../../../components/common/StatusBar/status'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../mobx-store/context'

const TopicAndJur = observer(() => {

  return (
    <>
      <Row>
        <div>
          <Logo large />
          <StatusBar style={{ marginTop: "1em" }} step={4} />
        </div>
      </Row>
      <Wrap>
        <Title>
          Select Your Options
        </Title>
        <Form />
      </Wrap>
    </>
  );
});

export default TopicAndJur;
