import React from 'react'
import closeIcon from '../../../assets/images/close-grey.svg'
import { Overlay, Wrap } from '../../../pages/Tracking/Right/Tasks/Modal/style'

const Modal = ({ children, onClose, styleWrap, styleOverlay, showCloseBtn = true, throughOverlay = true,}) => {
  return (
    <>
      <Overlay style={styleOverlay} onClick={onClose} throughOverlay={throughOverlay} />
      <Wrap style={styleWrap}>
        {showCloseBtn && <img src={closeIcon} alt='close-icon' onClick={onClose} id='close' />}
        {children}
      </Wrap>
    </>
  )
}

export default Modal
