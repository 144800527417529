import MyEditor from '../CheckboxGroup/Editor';
import { useStore } from '../../../../../mobx-store/context';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from '../../../../../components/common/Input';
import { api } from '../../../../../api';
import { message } from 'antd';
import { useGA4React } from 'ga-4-react'
import Documents from './documents';

const MaterialTab = ({ tabName, reportId, description, setDescriprion, files, setFiles }) => {
  const store = useStore()
  const ga4React = useGA4React()
  const { control, reset, getValues } = useForm()
  const [loading, setLoading] = useState(false)

  const postFile = (e) => {
    setLoading(true)
    ga4React?.gtag('event', 'file_upload')
    let formData = new FormData()
    formData.append('file', e.target.files[0])
    const url = `/cms/report/${reportId}/set_${tabName}/`

    api(url, formData, 'POST', false, true).then((data) => {
      if (!data.errors) {
        setFiles([...files, data])
        setLoading(false)
      } else {
        message.error(data.errors)
      }
      reset({ ...getValues(), file: '' })
    })
  }
  function removeItemById(array, id) {
    return array.filter(item => item.id !== id);
  }

  const deleteFile = (id) => {
    api(`/cms/${tabName}_files/${id}/`, {}, 'DELETE').then((data) => {
      if (!data.errors) {
        const newFiles = removeItemById(files, id)
        setFiles([...newFiles])
        setLoading(false)
      } else {
        message.error(data.errors)
      }
    })
  }

  const postDescription = (value) => {
    const url = `/cms/report/${reportId}/change_${tabName}/`
    value && api(url, { description: value }, 'PATCH').then((res) => {
      setDescriprion(value)
    })
  }

  return (
    <div>
      <MyEditor title={''} handleChange={(value) => postDescription(value)} defaultValue={description || ''} />
      <Input
        type={'file'}
        control={control}
        name={'file'}
        loading={loading}
        placeholder={''}
        handleChange={(e) => {
          postFile(e)
        }}
        styleContainer={{ marginTop: '1em' }}
        label={'Upload File'}
        transparent
      />
      <Documents
        reportId={reportId}
        files={files}
        setFiles={setFiles}
        deleteFile={deleteFile}
      />
    </div>

  )
};

export default MaterialTab;