import React from 'react'
import { Link } from 'react-router-dom'
import flag from '../../../assets/images/flag.svg'
import { useStore } from '../../../mobx-store/context'

const ExpandItem = ({ data }) => {
  const store = useStore()
  const company = store.company?.[0]

  return data.legislatives.map(
    ({
      jurisdiction,
      id,
      topics,
      line_of_business,
      compliance_date,
      adopted_date,
      status,
      is_starred,
    }) => (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: company?.is_premium
            ? '15% 15% 10% 20% 17% 12% 5%'
            : '25% 20% 20% 20% 15%',
          paddingLeft: 50,
          width: '100%',
        }}
      >
        <div style={{ padding: 8 }}>
          {data.reference_number}{' '}
          <Link
            style={{
              textDecoration: 'underline',
              color: 'var(--text)',
              marginLeft: 5,
            }}
            to={
              status === 'Closed'
                ? `/tracking/closed?compliance_item=${id}`
                : `/tracking/main?compliance_item=${id}`
            }
            target='_blank'
          >
            View Details
          </Link>
        </div>
        <div style={{ padding: 8, textAlign: 'center' }}>{jurisdiction}</div>
        <div style={{ padding: 8 }}>{topics}</div>
        {company?.is_premium && (
          <div style={{ padding: 8, textAlign: 'center' }}>
            {line_of_business}
          </div>
        )}
        <div style={{ padding: 8 }}>{adopted_date|| 'See notes'}</div>
        <div style={{ padding: 8 }}>{compliance_date || 'See notes'}</div>
        <div style={{ padding: 8 }}>{status}</div>
        <div style={{ padding: 8, textAlign: 'center' }}>
          {is_starred && <img src={flag} alt='flag' />}
        </div>
      </div>
    )
  )
}

export default ExpandItem
