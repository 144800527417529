import { Checkbox, message, Spin } from 'antd'
import BraintreeDropin from 'braintree-dropin-react'
import braintree from 'braintree-web-drop-in'
import { observer } from 'mobx-react-lite'
import React from 'react'
import Moment from 'react-moment'
import { useHistory } from 'react-router-dom'
import { api } from '../../api'
import Button from '../../components/common/Button/button'
import { getError } from '../../helpers/helpers'
import { useStore } from '../../mobx-store/context'
import { Agreement, TryFree } from '../Auth/Billing/billing.style'
import { Wrap } from './payForm.style'
import './style.css'

const SubmitButtons = observer(
  ({
    onClick,
    isDisabled,
    isValid,
    setError,
    callback,
    noSkip,
    noCheckbox,
    submitButtonText,
  }) => {
    const history = useHistory()
    const store = useStore()

    if (!store.company.length) return null

    const patchCompany = (checked) => {
      api(
        `/companies/${store.company[0].id}/`,
        {
          auto_renew_subscription: checked,
        },
        'PATCH'
      )
        .then(() => {})
        .catch((err) => console.log(err))
    }

    return (
      <>
        {!noCheckbox && (
          <Agreement>
            <Checkbox
              onChange={(e) => patchCompany(e.target.checked)}
              style={{ marginRight: '.5em' }}
            />
            Recurring payments{' '}
            <span>
              (Next payment{' '}
              <Moment format={'MM/DD/YYYY'}>
                {store.tariffInfo?.next_contract_date}
              </Moment>
              )
            </span>
          </Agreement>
        )}

        <Button
          text={submitButtonText || 'Proceed with Payment'}
          onClick={() => {
            if (isValid) {
              onClick()
              callback && callback()
            } else {
              setError()
            }
          }}
          type={'main'}
          style={{ marginTop: '1em', width: '100%' }}
          disabled={isDisabled}
        />
        {!noSkip && (
          <Button
            text={'Skip Payment and Try for Free'}
            onClick={() => {
              if (isValid) {
                callback && callback()

                history.push('/tracking')
              } else {
                setError && setError()
              }
            }}
            type={'green'}
            style={{ marginTop: '1em', width: '100%' }}
          />
        )}
        {!noSkip && (
          <TryFree>
            Try free today and gain access to 5 items in the tracker!
          </TryFree>
        )}
      </>
    )
  }
)

const brainTreeCreateSubscription = async (nonce) => {
  await api('/payments/create_subscription/', { nonce: nonce }, 'POST')
    .then((data) => {
      if (data.ok) {
        window.location.href = '/tracking'
      } else {
        message.error(getError(data))
      }
    })
    .catch((err) => console.log(err))
}

const brainTreeCancel = async () => {
  await api('/payments/cancel/', {}, 'POST')
}

class ExampleComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      token: null,
      nonce: null,
    }
  }

  renderSubmitButton = ({ onClick, isDisabled }) => {
    if (this.props.noButtons) return null
    return (
      <SubmitButtons
        onClick={onClick}
        callback={this.props.callback}
        isDisabled={isDisabled}
        isValid={this.props.isValid}
        setError={this.props.setError}
        noSkip={this.props.noSkip}
        noCheckbox={this.props.noCheckbox}
        submitButtonText={this.props.submitButtonText}
      />
    )
  }

  componentDidMount = async () => {
    this.getBrainTreeToken()
  }

  getBrainTreeToken = () => {
    const getClientToken = () => {
      api('/payments/client_token/', {}, 'POST').then((response) => {
        if (response.braintree_token) {
          this.setState({ token: response.braintree_token })
        }
      })
    }
    getClientToken()
  }

  handlePaymentMethod = async (payload) => {
    // const { nonce } = await this.instance.requestPaymentMethod();

    this.setState(
      {
        nonce: payload.nonce,
      },
      () => {
        !this.props.changeTariff &&
          !this.props.onClick &&
          brainTreeCreateSubscription(this.state.nonce, this.props.noRedirect)
        this.props.changeTariff &&
          this.props.handleChangeTariff(this.state.nonce)
        this.props.onClick && this.props.onClick(this.state.nonce)
      }
    )
  }

  onError = (error) => {
    console.log('onError', error)
  }

  onCheckout = () => {
    brainTreeCreateSubscription(this.state.nonce)
  }

  onCancel = () => {
    brainTreeCancel()
  }

  render() {
    return (
      <Wrap>
        {this.state.token ? (
          <div>
            <BraintreeDropin
              braintree={braintree}
              options={{
                locale: 'en_US',
                vaultManager: false,
                showDefaultPaymentMethodFirst: false,
              }}
              authorizationToken={this.state.token}
              handlePaymentMethod={this.handlePaymentMethod}
              onError={this.onError}
              renderSubmitButton={this.renderSubmitButton}
              className={this.props.className ?? 'braintree-dropin-react'}
            />
          </div>
        ) : (
          <Spin />
        )}
      </Wrap>
    )
  }
}

export default ExampleComponent
