import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Divider, Checkbox, message } from "antd";
import { useStore } from "../../../../../../mobx-store/context";
import { SwitchTitle, SwitchText, Row, DetailsWrap, AllCheckboxContainer, TableTitle, AddButton } from "../../../../CreateCompany/pages/ManageProducts/style";
import Input from "../../../../../../components/common/Input";
import CheckboxGroup from '../../../../CreateCompany/pages/ManageProducts/CheckboxGroup'
import bin from '../../../../../../assets/images/delete_red_bin.svg'
import EditGuideGroupModal from "../Modal";
import { api } from "../../../../../../api";
import moment from 'moment'
import { formatCurrency } from "../../../../helpers";
import EndDateConfirmModal from './EndDateConfirmModal'
import RemoveGroupModal from "./RemoveGroupModal";


const GuideGroup = ({ group, edit, getAllGroups }) => {
  const store = useStore()
  const [visible, setVisible] = useState(false)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [allJurisdictions, setAllJurisdictions] = useState(group?.all_jurisdictions || false)
  const [guidesCount, setGuidesCount] = useState(extractIds(group.selected_guides.filter(guide => guide.have_access === true)).length || 0)
  const [jursCount, setJursCount] = useState(group.jurisdictions.length || 0)
  const [endDateModalVisible, setEndDateModalVisible] = useState(false)
  const [tempEndDate, setTempEndDate] = useState(null)
  const { handleSubmit, control, getValues, watch, errors, setValue, values} = useForm({
    defaultValues: !edit ? {} : {
      begin_date: group?.begin_date ? moment(group?.begin_date).format('YYYY-MM-DD') : null,
      end_date: group?.end_date ? moment(group?.end_date).format('YYYY-MM-DD') : null,
      jurisdictions: group.jurisdictions,
      selected_guides: extractIds(group.selected_guides.filter(guide => guide.have_access === true)),
      value: formatCurrency(group.value) || null,
      custom_notes: group.custom_notes || ''
    }
  })

  const watchValues = watch()

  function extractIds(objects) {
    const ids = objects.map(obj => obj.id);
    return ids;
  }

  const onModalClose = () => {
    setVisible(false)
    getAllGroups()
  }

  const onRemoveModalClose = () => {
    removeGroup()
    setIsRemoveModalOpen(false)
  }


  const removeGroup = () => {
    api(`/cms/resource_portions/${group.id}/`, {}, 'DELETE').then((res) => {
      if (!res.errors) {
        getAllGroups()
        message.success(' Guides Group removed successfully')
      }
    })
  }

  const onGudesToggle = (values) => {
    api(`/cms/resource_portions/${group.id}/toggle_have_access_for_guide/`, { guides: values }, 'POST').then((res) => {
      if (!res.errors) {
        // getAllGroups()
        // message.success(' Guides Group removed successfully')
      }
    })
  }

  useEffect(() => {
    setGuidesCount((getValues('selected_guides') && getValues('selected_guides').length) || 0)
    setJursCount((getValues('jurisdictions') && getValues('jurisdictions').length) || 0)
  }, [watchValues]);

  const onGroupChange = (values) => {
    api(`/cms/resource_portions/${group.id}/`, {
      begin_date: moment(values.begin_date).format('YYYY-MM-DD'),
      end_date: values.end_date ? moment(values.end_date).format('YYYY-MM-DD') : null,
      jurisdictions: values.jurisdictions || [],
      all_jurisdictions: allJurisdictions,
      value: values.value ? values.value.replace(/[$,a-zA-Z]/g, '') : null,
      custom_notes: values.custom_notes || '',
    }, 'PATCH').then((res) => {

    })
  }

  useEffect(() => {
    onGudesToggle(getValues('selected_guides'))
  }, [watch('selected_guides')])

  useEffect(() => {
    onGroupChange(getValues())
  }, [watch('selected_guides'),
  watch('jurisdictions'),
  watch('begin_date'),
  watch('end_date'),
  watch('value'),
  watch('custom_notes'),
    allJurisdictions])


  return (
    <>
      <form>
        {isRemoveModalOpen && <RemoveGroupModal onClose={() => { setIsRemoveModalOpen(false) }} onRemove={() => onRemoveModalClose()} group={group} />}
        {visible && <EditGuideGroupModal onClose={() => onModalClose()} id={group.id} edit={edit} />}
        {endDateModalVisible && <EndDateConfirmModal onClose={() => setEndDateModalVisible(false)} setValue={() => setValue('end_date', tempEndDate)} />}
        <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <SwitchTitle>ReSource Guides - Access Given {group.date_added}</SwitchTitle>
          <AddButton onClick={() => setIsRemoveModalOpen(true)}>
            <img src={bin} alt='delete-icon' /><span style={{ fontSize: 12, textDecoration: 'underline', cursor: 'pointer', color: '#F24722' }}>Remove Portion</span>
          </AddButton>
        </Row>
        <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
          <Input
            smallLabel
            name={'begin_date'}
            showCross={false}
            label={'Select Group Start Date'}
            type={'date'}
            control={control}
            validation={{ required: false }}
            transparent
            placeholder={'Select Start Date'}
            noLimit
          />
          <Input
            smallLabel
            name={'end_date'}
            showCross={true}
            setValue={() => { }}
            handleChange={(e) => {
              setTempEndDate(e)
              setEndDateModalVisible(true)
            }}
            handleReset={() => setValue('end_date', null)}
            label={'Select Group End Date'}
            type={'date'}
            control={control}
            validation={{ required: false }}
            transparent
            placeholder={'Select End Date'}
            noLimit
          />
          <Input
            control={control}
            smallLabel
            name={`value`}
            label="Price Per Guide"
            type="text"
            transparent
            placeholder="$"

            disableValidation={true}
          />
        </Row >
        <DetailsWrap style={{ gridTemplateColumns: '1fr 1fr' }}>
          <TableTitle> Jurisdictions ({allJurisdictions ? 'All' : jursCount})</TableTitle>
          <TableTitle>
            <span>Guides ({guidesCount})</span>
            <AddButton
              onClick={() => setVisible(true)}
              style={{ fontSize: 12, textDecoration: 'underline', cursor: 'pointer' }}
            >Edit Guides </AddButton>
          </TableTitle>
        </DetailsWrap>
        <DetailsWrap style={{ gridTemplateColumns: '1fr 1fr' }}>
          <AllCheckboxContainer>
            <div> All Jurisdictions </div>
            <Checkbox
              onChange={(e) => setAllJurisdictions(e.target.checked)}
              name={'all_resource_jurisdictions'}
              style={{ marginLeft: 0, marginRight: 45 }}
              checked={allJurisdictions}
            >
            </Checkbox>
          </AllCheckboxContainer>
          <AllCheckboxContainer>
            <div> All Guides </div>
            {/* <Checkbox
              disabled={true}
              onChange={(e) => { }}
              name={'all_resource_jurisdictions'}
              style={{ marginLeft: 0, marginRight: 20 }}
              checked={false}
            /> */}
          </AllCheckboxContainer>
        </DetailsWrap>
        {
          <DetailsWrap style={{ gridTemplateColumns: '1fr 1fr' }}>
            {!allJurisdictions ?
              <CheckboxGroup
                compact={true}
                defaultValue={group.jurisdictions}
                control={control}
                name={'jurisdictions'}
                disabled={allJurisdictions}
                options={store?.cms_options.jurisdictions}
              />
              : <div style={{ width: '100%' }}></div>
            }
            <CheckboxGroup
              // defaultValue={extractIds(group.selected_guides.filter(guide => guide.have_access === false))}
              compact={true}
              control={control}
              name={'selected_guides'}
              disabled={false}
              options={group.selected_guides} />
          </DetailsWrap>
        }
        <Input
          smallLabel
          name={'custom_notes'}
          label={'Internal Notes Only'}
          type={'textarea'}
          control={control}
          transparent
          placeholder={''}
          style={{ marginBottom: '0.3em' }}
        />
        <Divider style={{ background: '#CCC' }} />
      </form>
    </>
  )
};

export default GuideGroup;