import styled from 'styled-components';
import { Checkbox, Radio } from 'antd';

export const CustomCheckbox = styled(Checkbox)`
  color: var(--text);
  font-family: var(--medium);
  font-size: 14px;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: grey; 
    border-color: grey; 
  }
`;

export const CustomRadio = styled(Radio)`
  color: var(--text);
  font-family: var(--medium);
  font-size: 14px;

  .ant-radio-checked .ant-radio-inner {
    border-color: grey; 
  }

  .ant-radio-inner::after {
    background-color: grey; 
  }
`;

export const Title = styled('div')`
color: var(--text);
font-family: var(--bold);
font-size: 14px;
line-height: 22px;
`