
import { ItemTitle, Description, Item } from './style';
const SecurityItem = ({ item }) => {
  const { title, description1, description2, titleColor, titleLeft } = item;

  return (
    <Item titleLeft={titleLeft}>
      <ItemTitle color={titleColor}>{title}</ItemTitle>
      <Description>{description1}</Description>
      <Description>{description2}</Description>
    </Item>
  );
};

export default SecurityItem;
