import styled from 'styled-components'

export const Wrap = styled('form')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #c4cacc;
  padding-bottom: 15px;
`

export const Title = styled('div')`
  font-family: var(--bold);
  font-size: 14px;
  color: var(--text);
  white-space: nowrap;
`
export const ExportButton = styled('div')`
  color: #225194;
  cursor: pointer;
  text-decoration: underline;
  font-family: var(--regular);
  font-size: 12px;
  margin-left: 10px;
  padding-top: 23px;
`