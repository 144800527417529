import React, { useEffect, useState } from 'react'
import {
  ImageWrap,
  Loader,
  Member,
  MembersList,
  MembersListWrap,
  Row,
  Title,
  UnderTitle,
  Wrap,
} from './style'
import { Container, Text } from '../style'
import { api } from '../../../api'
import { Spin } from 'antd'
import ReactHtmlParser from 'react-html-parser'

const Team = () => {
  const [team, setTeam] = useState([]);
  const [active, setActive] = useState(0);

  useEffect(() => {
    api("/employees/", {}, "GET", true).then((data) => {
      if (!data.errors) {
        setTeam(data.results);
        setActive(data.results[0]);
      }
    });
  }, []);

  return (
    <Wrap>
      {!team.length ? (
        <Loader>
          <Spin style={{ margin: "0 auto" }} />
        </Loader>
      ) : (
        <Container>
          <Row>
            <ImageWrap>
              <img src={active?.photo} alt="#" />
            </ImageWrap>
            <div style={{ width: "100%" }}>
              <Title>
                {active?.first_name} {active?.last_name}
              </Title>
              <UnderTitle>{active?.title}</UnderTitle>
              <Text
                style={{
                  color: "var(--blue)",
                  marginTop: 20,
                  fontSize: 16,
                  lineHeight: "32px",
                }}
              >
                {ReactHtmlParser(active?.content)}
              </Text>
            </div>
          </Row>
          <MembersListWrap>
            <MembersList>
              {team.map((item, index) => {
                return (
                  <Member key={index} onClick={() => setActive(item)}>
                    <img src={item.photo_small} alt="#" />
                    <Title
                      style={{
                        fontSize: 28,
                        marginTop: 5,
                        fontFamily: "var(--light)",
                      }}
                    >
                      {item.first_name} {item.last_name}
                    </Title>
                    <UnderTitle style={{ fontSize: 18 }}>
                      {item.title}
                    </UnderTitle>
                  </Member>
                );
              })}
            </MembersList>
          </MembersListWrap>
        </Container>
      )}
    </Wrap>
  );
};

export default Team;
