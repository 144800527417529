import Wraper from "../CMSContainer";
import UsersTab from "../EditCompany/Tabs/Users";

const AdminStaffPage = () => {
  return (
    <Wraper>
      <UsersTab allUsersPage={'admins'} />
    </Wraper>
  );
};

export default AdminStaffPage;