import styled from 'styled-components'
import { query500 } from '../../helpers'
import { ContainerGlobal, TitleGlobal } from '../styles'

export const Wrap = styled('div')`
  background-color: var(--blue);
  padding-top: 70px;
  @media (max-width: 650px) {
    padding-top: 40px;
  }
  ${TitleGlobal} {
    font-size: 36px;
    color: #fff;
    @media (max-width: 650px) {
      font-size: 18px;
    }
  }
  ${ContainerGlobal} {
    @media (max-width: 650px) {
      display: block;
    }
    &:last-child {
      @media (max-width: 1024px) {
        width: 100vw;
      }
    }
  }
`

export const LeftSide = styled('div')`
  width: 530px;
  @media (max-width: 650px) {
    width: 100%;
  }
`

export const Title = styled('div')`
  font-family: var(--bold);
  font-size: 20px;
  color: #fff;
  opacity: 0.5;
  margin-bottom: 30px;
`

export const ContactUsTitle = styled('div')`
  font-family: var(--bold);
  font-size: 24px;
  color: #fff;
`

export const Description = styled('div')`
  font-family: var(--regular);
  font-size: 12px;
  color: #fff;
  opacity: 0.7;
  margin-top: 10px;
`

export const MailButton = styled('a')`
  background-color: #fff;
  font-size: 16px;
  color: var(--blue);
  font-family: var(--bold);
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  border-radius: 5px;
  margin-top: 20px;
`

export const RightSide = styled('div')`
  margin-left: 150px;
  @media (max-width: 650px) {
    margin-left: 0;
    margin-top: 50px;
  }
`

export const Rights = styled('div')`
  width: 100%;
  height: 100px;
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 480px;
  align-items: center;
  padding: 0 25px;
  color: var(--text);
  font-family: var(--medium);
  margin-top: 80px;
  @media (max-width: 950px) {
    display: block;
    height: auto;
    padding: 15px;
  }
  @media (max-width: 650px) {
    flex-direction: column;
    justify-content: center;
  }
`

export const Links = styled('div')`
  display: grid;
  grid-template-columns: 35% 35%;
  font-family: var(--medium);
  font-size: 16px;
  @media (max-width: 950px) {
    margin-top: 15px;
  }
  a {
    color: var(--blue);
    padding: 0 15px;
    height: 15px;
    margin-bottom: 10px;
    display: flex;
    // width: fit-content;
    align-items: center;
    &:first-child{
      border-right: 2px solid var(--blue);
      @media (max-width: 950px) {
        padding-left: 0;
      }
    }
    &:nth-child(3) {
      border-right: 2px solid var(--blue);
      @media (max-width: 950px) {
        padding-left: 0;
      }
    }
    &:nth-child(2) {
      padding-left: 30px;
      padding-right: 0px;
      width: 100%;
    }
    &:nth-child(4) {
      padding-left: 30px;
      padding-right: 0px;
      width: 100%;

    }
    @media${query500} {
      white-space: nowrap;
    }
  }
`
