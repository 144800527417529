import React from 'react'
import demoImage from '../../../../assets/images/demo.svg'
import ScheduleInput from './ScheduleInput'
import { Container, Description, Title, Wrap } from './style'

const Demo = () => {
  return (
    <Container>
      <Wrap>
        <div>
          <img src={demoImage} alt='process' />
        </div>
        <div>
          <Title>Interested in learning more?</Title>
          <Description>
            Reach out to our team at PBMSource to schedule a
            personalized demonstration to meet your organization’s needs.
          </Description>
          <ScheduleInput service={'Home Page'} />
        </div>
      </Wrap>
    </Container>
  )
}

export default Demo
