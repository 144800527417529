import React from 'react'
import { leftSide } from './leftSide'

export const getItems = (premium) => [
  {
    key: 0,
    planName: 'TopicTrack',
    underTitle: (
      <>
        All Topics, <br /> One Jurisdiction
      </>
    ),
    options: leftSide,
    button: 'TopicTrack',
    price: '1,250',
    planNameForBackend: 'topic_tract',
    description: <>with comprehensive service offerings in 1 jurisdiction</>,
    maxUsers: 'Unlimited',
    premium: (
      <div>
        +$600/<span>month</span>
      </div>
    ),
    total: {
      month: (
        <div style={{ textAlign: 'center', lineHeight: 1 }}>
          <div style={{ fontFamily: 'var(--regular)', fontSize: 22 }}>
            {premium ? '$1,850' : '$1,250'}
            <span style={{ fontFamily: 'var(--light)' }}>/month</span>
          </div>
          <span
            style={{
              fontSize: 14,
              fontFamily: 'var(--light)',
            }}
          >
            {premium ? '$22,200' : '$15,000'}/year
          </span>
        </div>
      ),
      annual: (
        <div
          style={{
            color: '#455154',
            fontSize: 22,
            fontFamily: 'var(--medium)',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              fontSize: 16,
              color: 'var(--blue)',
              textDecoration: 'line-through',
              fontFamily: 'var(--light)',
              marginRight: 10,
            }}
          >
            {premium ? '$22,200' : '$15,000'}
          </span>
          {premium ? '$19,980' : '$13,500'}
        </div>
      ),
    },
  },
  {
    key: 1,
    planName: 'JurisTrack',
    underTitle: (
      <>
        One Topic, All <br /> Jurisdictions{' '}
      </>
    ),
    button: 'JurisTrack',
    options: leftSide,

    price: '2,490',
    planNameForBackend: 'juris_tract',
    maxUsers: 'Unlimited',
    description: <>with limited service offerings nationwide</>,
    premium: (
      <div>
        +$1,200/<span>month</span>
      </div>
    ),
    total: {
      month: (
        <div style={{ textAlign: 'center', lineHeight: 1 }}>
          <div style={{ fontFamily: 'var(--regular)', fontSize: 22 }}>
            {premium ? '$3,690' : '$2,490'}
            <span style={{ fontFamily: 'var(--light)' }}>/month</span>
          </div>
          <span
            style={{
              fontSize: 14,
              fontFamily: 'var(--light)',
            }}
          >
            {premium ? '$44,280' : '$29,880'}/year
          </span>
        </div>
      ),
      annual: (
        <div
          style={{
            color: '#455154',
            fontSize: 22,
            fontFamily: 'var(--medium)',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              fontSize: 16,
              color: 'var(--blue)',
              textDecoration: 'line-through',
              fontFamily: 'var(--light)',
              marginRight: 10,
            }}
          >
            {premium ? '$44,280' : '$29,880'}
          </span>
          {premium ? '$39,852' : '$26,892'}
        </div>
      ),
    },
  },
  {
    key: 2,
    planName: 'TotalTrack',
    underTitle: (
      <>
        All Topics, All <br /> Jurisdictions{' '}
      </>
    ),
    button: 'TotalTrack',
    planNameForBackend: 'total_tract',
    price: '5,900',
    options: leftSide,

    description: (
      <>
        with comprehensive service offerings in more than twenty jurisdictions
      </>
    ),

    maxUsers: 'Unlimited',
    premium: (
      <div>
        +2,400/<span>month</span>
      </div>
    ),
    total: {
      month: (
        <div style={{ textAlign: 'center', lineHeight: 1 }}>
          <div style={{ fontFamily: 'var(--regular)', fontSize: 22 }}>
            {premium ? '$8,300' : '$5,900'}
            <span style={{ fontFamily: 'var(--light)' }}>/month</span>
          </div>
          <span
            style={{
              fontSize: 14,
              fontFamily: 'var(--light)',
            }}
          >
            {premium ? '$99,600' : '$70,800'}/year
          </span>
        </div>
      ),
      annual: (
        <div
          style={{
            color: '#455154',
            fontSize: 22,
            fontFamily: 'var(--medium)',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              fontSize: 16,
              color: 'var(--blue)',
              textDecoration: 'line-through',
              fontFamily: 'var(--light)',
              marginRight: 10,
            }}
          >
            {premium ? '$99,600' : '$70,800'}
          </span>
          {premium ? '$89,640' : '$63,720'}
        </div>
      ),
    },
  },
  {
    key: 3,
    planName: 'SelectTrack',
    underTitle: <>Select Topics and Jurisdictions of your choice</>,
    button: 'SelectTrack',
    options: leftSide,
    planNameForBackend: 'select_tract',
    price: '398',
    description: (
      <>with limited service offerings and/or in limited jurisdictions</>
    ),
    maxUsers: 'Unlimited',
    premium: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        +$49/
        <div>
          jurisdiction/topic <br /> per month
        </div>
      </div>
    ),
    total: {
      month: (
        <div style={{ textAlign: 'center', lineHeight: 1 }}>
          <div style={{ fontFamily: 'var(--regular)', fontSize: 22 }}>
            ${premium ? '496' : '398'}
            <span style={{ fontFamily: 'var(--light)' }}>/month</span>
          </div>
          <span
            style={{
              fontSize: 14,
              fontFamily: 'var(--light)',
            }}
          >
            {premium ? '$5,952' : '$4,776'}/year
          </span>
        </div>
      ),
      annual: (
        <div
          style={{
            color: '#455154',
            fontSize: 22,
            fontFamily: 'var(--medium)',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              fontSize: 16,
              color: 'var(--blue)',
              textDecoration: 'line-through',
              fontFamily: 'var(--light)',
              marginRight: 10,
            }}
          >
            {premium ? '$5,952' : '$4,776'}
          </span>
          {premium ? '$5,356' : '$4,298'}
        </div>
      ),
    },
  },
]
