export const notShowMenu = [
  '/register',
  '/login',
  '/validate-account',
  '/otp-validation',
  '/pricing',
  '/addMember',
  '/businessInfo',
  '/topicsAndJur',
  '/billing',
  '/startDate',
  '/reset-password-enter-email',
  '/reset-password-email',
  '/reset-password-confirm',
  '/agreement',
  '/terms-and-privacy',
  '/confirm-email',
  '/resource-pricing',
  '/resource',
  '/email-confirmed',
  '/analytic',
  '/company_error',
]

export const dashboardMenu = [
  '/dashboard-landing',
  '/create-new-company',
  '/manage-products',
  '/dashboard-edit-company',
  '/dashboard-edit-user',
  '/dashboard-guides-table',
  '/dashboard-guides-names',
  '/dashboard-upload-guide',
  '/dashboard-add-guide-name',
  '/dashboard-report-table',
  'dashboard-compliance-table',
  '/dashboard-add-report',
  '/dashboard-edit-report',
  '/dashboard-compliance-table',
  '/dashboard-proposed-table',
  '/dashboard-add-compliance',
  '/dashboard-edit-compliance',
  '/add-multiple-users',
  '/dashboard-compliance-activity',
  '/dashboard-add-proposed',
  '/dashboard-edit-proposed',
  '/dashboard-dictionaries-jurisdictions',
  '/dashboard-dictionaries-topics',
  '/dashboard-dictionaries-sub-topics',
  '/dashboard-dictionaries-lines-of-business',
  '/dashboard-dictionaries-report-types',
  '/dashboard-dictionaries-surveys',
  '/dashboard-dictionaries-reference-types',
  '/dashboard-dictionaries-reporting-entities',
  '/dashboard-dictionaries-reporting-types',
  '/dashboard-dictionaries-ties-to-license',
  '/dashboard-dictionaries-applies-to',
  '/dashboard-all-users',
  '/dashboard-settings',
  '/dashboard-add-admin',
  '/dashboard-edit-admin',
]

export const notAuth = [
  '/register',
  '/login',
  '/validate-account',
  '/otp-validation',
  '/step1',
  '/reset-password-enter-email',
  '/reset-password-email',
  '/reset-password-confirm',
  '/home',
  '/terms-and-privacy',
  '/secuity-and-privacy',
  '/confirm-email',
  '/resource-pricing',
  '/about',
  '/email-confirmed',
  '/analytic',
  '/home-compliance',
  '/home-resource',
  '/home-report-source',
  '/home-license-source',
  '/home-proposed-source',
  '/resource-guides-landing',
]

export const noEmailConfirm = [
  '/login',
  '/register',
  '/reset-password-enter-email',
  '/reset-password-email',
  '/reset-password-confirm',
  '/agreement',
  '/terms-and-privacy',
  '/secuity-and-privacy',
  '/confirm-email',
  '/home',
  '/email-confirmed',
  '/analytic',
  '/home-compliance',
  '/home-report-source',
  '/home-license-source',
  '/home-proposed-source',
  '/resource-guides-landing',
]
