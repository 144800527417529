import styled from 'styled-components'

export const SelectWrap = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.5em;
  align-items: center;
  border: 1px solid var(--blue);
  background: #fff;
  padding: 20px;
  z-index: 10;
`;
export const TextContainer = styled("div")`
display: flex;
flex-direction: column;
`;

export const SelectTitle = styled("div")`
font-size: 28px;
font-family: var(--bold);
color: ${(props) => (`var(--${props.titleColor})`)};
`;

export const SelectDescription = styled("div")`
font-size: 24px;
margin-top: 0.5em;
color: var(--text);
font-family: var(--regular);
line-height: 29px;
`;

export const SignUpButton = styled("div")`
font-size: 14px;
color: #4B75B1;
font-family: var(--regular);
cursor: pointer;
&:hover {
  text-decoration: underline;
}
`;
export const ButtonsWrap = styled("div")`
margin-left: 10px;
display: flex;
flex-direction: column;
align-items: center;
`;

