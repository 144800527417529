export const blocksTracking = [
  {
    key: 0,
    restrictions: 'compliance_restricted_topics',
    name: 'topics',
    type: 'topicsMembers',
    title: 'Restrict By Topics',
  },
  {
    key: 1,
    restrictions: 'compliance_restricted_jurisdictions',
    name: 'jurisdictions',
    type: 'jursMembers',
    title: 'Restrict By Jurisdictions',
  },
  {
    key: 2,
    restrictions: 'compliance_restricted_line_of_business',
    name: 'line_of_business',
    type: 'lineOfBusinessesMembers',
    title: 'Restrict By Lines of Business',
  },
]

export const blocksReportSource = [
  {
    key: 0,
    restrictions: 'report_source_restricted_report_types',
    name: 'report_source_report_types',
    type: 'memberReportTypes',
    title: 'Restrict By Report Type',
  },
  {
    key: 1,
    restrictions: 'report_source_restricted_jurisdictions',
    name: 'report_source_jurisdictions',
    type: 'jursMembers',
    title: 'Restrict By Jurisdictions',
  },
]

export const blocksPendingLicenseAssessments = [
  {
    key: 'license_pending_restricted_jurisdictions',
    restrictions: 'license_pending_restricted_jurisdictions',
    name: 'license_source_pending_jurisdictions',
    type: 'jursMembers',
    title: 'Restrict By Jurisdictions',
  },
  {
    key: 'license_pending_restricted_license_types',
    restrictions: 'license_pending_restricted_license_types',
    name: 'license_pending_types',
    type: 'memberLicenseTypes',
    title: 'Restrict By License Type',
  },
  {
    key: 'license_pending_restricted_lines_of_business',
    restrictions: 'license_pending_restricted_lines_of_business',
    name: 'license_source_pending_line_of_business',
    type: 'selectedJurisdictions',
    title: 'Restrict By Lines of Business',
  },
]
export const blocksLicenseRenewals = [
  {
    key: 'license_renewals_restricted_jurisdictions',
    restrictions: 'license_renewals_restricted_jurisdictions',
    name: 'license_source_renewals_jurisdictions',
    type: 'jursMembers',
    title: 'Restrict By Jurisdictions',
  },
  {
    key: 'license_renewals_restricted_license_types',
    restrictions: 'license_renewals_restricted_license_types',
    name: 'license_renewals_types',
    type: 'memberLicenseTypes',
    title: 'Restrict By License Type',
  },
  {
    key: 'license_renewals_restricted_lines_of_business',
    restrictions: 'license_renewals_restricted_lines_of_business',
    name: 'license_source_renewals_line_of_business',
    type: 'selectedJurisdictions',
    title: 'Restrict By Lines of Business',
  },
]

export const blocksLicenseReporting = [
  {
    key: 1,
    restrictions: 'license_reporting_restricted_jurisdictions',
    name: 'report_source_jurisdictions',
    type: 'jursMembers',
    title: 'Restrict By Jurisdictions',
  },
  {
    key: 0,
    restrictions: 'license_reporting_restricted_report_types',
    name: 'report_source_report_types',
    type: 'memberReportTypes',
    title: 'Restrict By Report Type',
  },
]

export const blocksResource = [
  {
    key: 0,
    restrictions: 'resource_restricted_jurisdictions',
    name: 'resource_jurisdictions',
    title: 'Restrict By Jurisdictions',
  },
  {
    key: 1,
    restrictions: 'resource_restricted_line_of_business',
    name: 'resource_line_of_business',
    type: 'selectedJurisdictions',
    title: 'Restrict By Lines of Business',
  },
]

export const blocksProposedSource = [
  {
    key: 0,
    restrictions: 'proposed_source_restricted_jurisdictions',
    name: 'proposed_source_jurisdictions',
    type: 'jursMembers',
    title: 'Restrict By Jurisdictions',
  },
]