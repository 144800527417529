import styled from 'styled-components'

export const TopContainter = styled('div')`
  max-width: 940px;
  margin: 0 auto;
  padding-top: 200px;
  @media (max-width: 1000px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

export const Title = styled('div')`
  font-family: var(--bold);
  font-size: 60px;
  color: var(--text);
  line-height: 70px;
  text-align: center;
`

export const Description = styled('div')`
  font-family: var(--medium);
  font-size: 20px;
  color: var(--text);
  line-height: 30px;
  text-align: center;
  margin-top: 30px;
`

export const BottomContainer = styled('div')`
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  margin-bottom: 300px;
  #video-img {
    position: absolute;
    right: 0;
  }
  @media (max-width: 1400px) {
    padding: 0 15px;
    #video-img {
      width: 50vw;
    }
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 15px;
  }
  @media (max-width: 985px) {
    display: block;
    margin-bottom: 0;
    #video-img {
      width: 100%;
      position: relative;
      transform: translateX(15px);
    }
  }
`

export const LeftSide = styled('div')`
  max-width: 576px;
  padding-top: 180px;
  ${Description} {
    text-align: left;
  }
  @media (max-width: 1200px) {
    padding-top: 50px;
  }
`

export const TitleSmall = styled(Title)`
  font-size: 40px;
  line-height: 50px;
  text-align: left;
  span {
    color: #70B461;
  }
`
