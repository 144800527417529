import styled from 'styled-components';


export const ButtonWrapper = styled.div`
  display: flex;
`;

export const Button = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
  font-family:  ${(props) => (props.active ? 'var(--bold)' : 'var(--semiBold)')};
  color: ${(props) => (props.active ? 'var(--black)' : 'var(--text)')};
  outline: none;
  margin-right: 10px;
  border-bottom: ${(props) => (props.active ? '2px solid var(--blue)' : 'none')};
`;

