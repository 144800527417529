import styled from 'styled-components'
import { query1300, query500 } from '../helpers'

export const Wrap = styled('div')`
  overflow-x: hidden;
`

export const ContainerGlobal = styled('div')`
  margin: 0 auto;
  width: ${(props) => (props.fluid ? '100%' : '80vw')};
  max-width: ${(props) => (props.fluid ? '100%' : '1200px')};
  display: flex;
  @media${query1300} {
    width: ${(props) => (props.fluid ? '100%' : 'calc(100vw - 30px)')};
  }
`

export const TitleGlobal = styled('div')`
  font-family: var(--bold);
  font-size: 52px;
  color: var(--text);
  line-height: 1.3em;
  transition: all 0.2s ease-in-out;
  @media${query1300} {
    font-size: 46px;
  }
  @media${query500} {
    font-size: 24px;
    br {
      display: none;
    }
  }
`

export const DescriptionGlobal = styled('div')`
  font-family: var(--regular);
  font-size: 16px;
  color: var(--text);
  transition: all 0.2s ease-in-out;
`
