import Modal from "../../../../../../components/common/ModalNew"
import Button from "../../../../../../components/common/Button/button"

const AutoAssignmentsInfoModal = ({ onClose }) => {
  return (
    <Modal
      styleWrap={{
        maxWidth: 500,
        padding: '30px 40px',
      }}
      onClose={onClose}
    >
      <div style={{ fontSize: 20, fontFamily: 'var(--medium)', color: 'var(--text)', marginBottom: 20, marginTop: 20 }}>
        The Auto Assign process takes time and the results will be available in a few minutes
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button text={'I Understand'} onClick={onClose} />
      </div>
    </Modal >
  )
}

export default AutoAssignmentsInfoModal