import React from 'react'
import image from '../../../../assets/images/mb_pro_main_home_2.png'
import { TitleGlobal } from '../../Compliance/styles'
import { Description, Wrap } from './style'

const Header = ({ title, subtitle, showImage = true }) => {
  return (
    <Wrap>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <TitleGlobal style={{ color: 'var(--blue)' }}>
          {title || 'Compliance Built for the PBM Industry'}
        </TitleGlobal>
        <Description dangerouslySetInnerHTML={{ __html: subtitle || 'We provide a compliance team and tools to <br /> take control of your compliance needs.' }} />
      </div>
      {showImage && <img src={image} alt='laptop' />}
    </Wrap>
  )
}

export default Header
