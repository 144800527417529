import { observer } from 'mobx-react-lite'
import React from 'react'
import arrowRight from '../../../../assets/images/arrow-right.svg'
import macbook from '../../../../assets/images/MacBook-Pro_new.png'
import Button from '../../../../components/common/Button/button'
import { useStore } from '../../../../mobx-store/context'
import { ContainerGlobal, DescriptionGlobal, TitleGlobal } from '../styles'
import { FreeSign, LeftSide, RightSide, Wrap } from './style'
import ScheduleInput from '../../Main/Demo/ScheduleInput'

const Header = observer(({ content, service }) => {

  const store = useStore()

  return (
    <Wrap>
      <ContainerGlobal>
        <LeftSide>
          <TitleGlobal style={{ color: content.titleColor }}>
            {content.title}
          </TitleGlobal>
          <DescriptionGlobal>
            {content.description}
          </DescriptionGlobal>
          <div style={{ marginTop: 40 }}>
            <ScheduleInput border={true} service={content.service} />
          </div>
          {/* {content.service === 'report-source' &&
            <>
              <span
                style={{
                  color: 'var(--orange)',
                  marginTop: 24,
                  fontSize: 16,
                  fontFamily: 'var(--bold)'
                }}>Or</span>
              <Button
                style={{ marginTop: 24, height: 37 }}
                text='Purchase Now'
                to={store.user.length ? '/tracking' : '/register'}
              />
            </>
          } */}
        </LeftSide>
        <RightSide color={content.bgColor}>
          <img src={content.leptopImage} alt='macbook-pro' />
        </RightSide>
      </ContainerGlobal>
    </Wrap>
  )
})

export default Header
