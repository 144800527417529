import { Table } from 'antd'
import styled from 'styled-components'

export const Wrap = styled('div')`
  border-radius: 7px;
  border: 1px solid #c4cacc;
  margin-bottom: 30px;
  
`

export const Header = styled('div')`
  height: 2.813em;
  border-radius: 6px 6px 0 0;
  background: #f4f6f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em;
  border: 1px solid #dcdee1;
  position: sticky;
  
`

export const HeaderTitle = styled('div')`
  display: flex;
  font-size: 1.125em;
  font-family: var(--medium);
  color: var(--text);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: sticky;
`
export const MonthName = styled('div')`
 font-family: var(--bold);
  margin-left: 5px;
  color: var(--blue);

`
export const Clear = styled('div')`
 font-family: var(--mediun);
  margin-left: 5px;
  color: var(--blue);
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

`
export const HeaderButton = styled('div')`
  font-size: 0.75em;
  color: var(--blue);
  text-decoration: underline;
  font-family: var(--open-sans-reg);
  cursor: pointer;

  img {
    max-height: 20px;
    margin-left: 0.8em;
  }
`

export const Status = styled('div')`
  font-size: 1em;
  width: 10px;
  height: 10px;

  div {
    width: 10px;
    height: 10px;
    margin-right: 1em;
    background-color: ${(props) => props.bg};
    border-radius: 100%;
  }
`

export const TableHeaderTitle = styled('div')`
  font-size: 16px;
  font-family: var(--semiBold);
  color: var(--text);
`

export const FirstLetterUppercase = styled('div')`
  &::first-letter {
    text-transform: uppercase;
  }
`

export const TableStyled = styled(Table)`
  .ant-table {
    font-size: 16px;
  }

  .ant-table-expanded-row {
    td {
      padding: 0;
    }
  }

  .ant-table-thead {
    tr {
      th {
        background-color: #fff !important;
         position: sticky;
         top: 0; 
        z-index: 99;
      }
    }
  }

  .ant-table-cell {
    font-size: 0.75em;
    font-family: var(--light);
    color: var(--text);
    cursor: pointer;
  }

  .active-row {
    td {
      color: var(--green);
      background-color: #f5f9ec;
      font-family: var(--open-sans-bold);

      &:first-child {
        border-left: 4px solid var(--green);
      }
    }
  }

  .unread-row {
    td {
      font-family: var(--open-sans-bold);
    }
  }

  .ant-pagination-item {
    border-color: #dadada !important;
    margin: 0 0.1em !important;
    border-radius: 4px;
    font-family: var(--regular);
    font-size: 0.714em;
    height: 15px !important;
    width: 16px !important;
    min-width: 16px !important;
    display: flex;
    align-items: center;
    justify-content: center;

    &-active {
      a {
        font-family: var(--bold);
        color: var(--orange);
      }
    }

    a {
      height: 15px;
      line-height: 15px;

      &:hover {
        color: var(--orange);
      }
    }
  }

  .ant-pagination.mini .ant-pagination-prev,
  .ant-pagination.mini .ant-pagination-next {
    height: 15px;
    line-height: 15px;

    button {
      color: rgba(0, 0, 0, 0.54);
    }
  }
`

export const Render = styled('div')`
  font-family: ${(props) =>
    props.status === 'unread' && 'var(--open-sans-bold)'};
`
