
import AddingImg from '../../../../assets/images/addingIcon.svg';

const AddingButton = ({ text, link, onClick }) => {
  return (
    <a style={{
      fontFamily: 'var(--bold)',
      fontSize: 16,
      color: 'var(--blue)',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    }}
      onClick={() => onClick && onClick()}
      href={link}>
      <img src={AddingImg} alt="add" />
      {text}
    </a>
  );
};

export default AddingButton;
