import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useStore } from '../../../../mobx-store/context'
import ResourceModal from '../ResourceAccessModal/resourceModal'
import { Button, Wrap } from './style'

const TabsNav = ({
  tabs,
  active = 'tracking',
  styleWrap,
  allowResource,
  onRedirectTo,
  noCheck,
}) => {
  const store = useStore()
  const history = useHistory()
  const params = useParams()
  const [visible, setVisible] = useState(false)
  const [modalData, setModalData] = useState({})

  const user = store.user?.[0]
  const company = store.company?.[0]

  const accessCheck = () => {
    const noAccess = (userAccess, companyAccess) => !userAccess || !companyAccess;

    if (
      user && company &&
      noAccess(user.have_access_to_tracking, company.have_access_to_tracking) &&
      noAccess(user.have_access_to_report_source, company.have_access_to_report_source) &&
      noAccess(user.have_access_to_proposed_source, company.have_access_to_proposed_source) &&
      noAccess(user.have_access_to_resource, company.have_access_to_resource) &&
      noAccess(user.have_access_to_license_source, company.have_access_to_license_source)
    ) {
      message.error("You don't have access to any mode");
      history.push('/edit-profile');
      return;
    }

    const accessChecks = [
      { activeMode: 'tracking', userAccess: user?.have_access_to_tracking, companyAccess: company?.have_access_to_tracking, redirect: 'resource' },
      { activeMode: 'proposedSource', userAccess: user?.have_access_to_proposed_source, companyAccess: company?.have_access_to_proposed_source, redirect: 'tracking' },
      { activeMode: 'resource', userAccess: user?.have_access_to_resource, companyAccess: company?.have_access_to_resource, redirect: 'reportSource' },
      { activeMode: 'reportSource', userAccess: user?.have_access_to_report_source, companyAccess: company?.have_access_to_report_source, redirect: 'proposedSource' },
      { activeMode: 'licenseSource', userAccess: user?.have_access_to_license_source, companyAccess: company?.have_access_to_license_source, redirect: 'licenseSource' },
    ];

    const check = accessChecks.find(check => active === check.activeMode && noAccess(check.userAccess, check.companyAccess));

    if (check) {
      onRedirectTo(check.redirect);
    }
  };

  const onClick = (item, e) => {
    const isAdmin = user?.member_type === 'admin'
      || user?.resource_role === 'admin'
      || user?.proposed_source_role === 'admin'
      || user?.report_source_role === 'admin'
      || user?.license_source_role === 'admin';
    const hasCompanyAccess = company['have_access_to_' + item];

    if (noCheck || (isAdmin && hasCompanyAccess)) return;

    const accessChecks = [
      { item: 'resource', userAccess: user?.have_access_to_resource, companyAccess: company?.have_access_to_resource, allowAccess: allowResource, type: 'resource', toAdmin: `/edit-team-members-profile/${user.id}/resource`, toNonAdmin: '/resource/options' },
      { item: 'tracking', userAccess: user?.have_access_to_tracking, companyAccess: company?.have_access_to_tracking, type: 'tracking', toAdmin: '/topicsAndJur', toNonAdmin: '/edit-profile' },
      { item: 'reportSource', userAccess: user?.have_access_to_report_source, companyAccess: company?.have_access_to_report_source, type: 'reportSource', toAdmin: '/addReportMember/', toNonAdmin: '/edit-profile' },
      { item: 'proposedSource', userAccess: user?.have_access_to_proposed_source, companyAccess: company?.have_access_to_proposed_source, type: 'proposedSource', toAdmin: '/addProposedMember', toNonAdmin: '/edit-profile' },
      { item: 'licenseSource', userAccess: user?.have_access_to_license_source, companyAccess: company?.have_access_to_license_source, type: 'licenseSource', toAdmin: '/addLicenseMember', toNonAdmin: '/edit-profile' },
    ];

    const check = accessChecks.find(check =>
      item === check.item &&
      (!check.userAccess || !check.companyAccess) &&
      (check.allowAccess === undefined || !check.allowAccess)
    );

    if (check) {
      setModalData({
        type: check.type,
        to: isAdmin ? check.toAdmin : check.toNonAdmin
      });
      setVisible(true);
      e.preventDefault();
    }
  };


  const onClose = () => setVisible(false)

  useEffect(() => {
    if (!noCheck) accessCheck()
  }, [user])

  return (
    <Wrap style={styleWrap}>
      {visible && <ResourceModal onClose={onClose} {...modalData} />}
      {Object.entries(tabs).map((item) => (
        <Button
          to={item[1].link}
          key={item[0]}
          active={active === item[0]}
          onClick={(e) => onClick(item[0], e)}
          name={item[1].button}
        >
          {item[1].button}
        </Button>
      ))}
    </Wrap>
  )
}

export default TabsNav
