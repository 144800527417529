import styled from 'styled-components'
import { query1300 } from '../../../helpers'
import { DescriptionGlobal } from '../../styles'

export const Wrap = styled('div')`
  ${DescriptionGlobal} {
    font-size: 12px;
    margin-top: 15px;
    padding-left: 40px;
    margin-bottom: 20px;
    @media${query1300} {
      font-size: 12px;
    }
  }
`

export const Title = styled('div')`
  display: flex;
  align-items: center;
  font-family: var(--bold);
  font-size: 16px;
  color: var(--text);
  img {
    margin-right: 15px;
    width: 25px;
    height: 25px;
  }
  @media${query1300} {
    font-size: 14px;
  }
`
