import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'
import { Link } from 'react-router-dom'
import { Wrap, WrapGreen, WrapWhite, WrapGrey } from './button.style'

const Button = (props) => {
  const {
    icon,
    loading,
    styleIcon,
    text,
    disabled,
    as,
    style,
    to,
    type = 'main',
  } = props

  const button = {
    main: (
      <Wrap
      name={text}
        disabled={disabled || loading}
        as={to ? Link : as || 'button'}
        color={style?.color}
        {...props}
      >
        {icon && !loading && <img src={icon} alt={''} style={styleIcon} />}
        {loading ? <LoadingOutlined style={{ color: '#fff' }} spin /> : text}
      </Wrap>
    ),
    white: (
      <WrapWhite as={to ? Link : 'button'} {...props}>
        {icon && !loading && <img src={icon} alt={''} style={styleIcon} />}
        {loading ? <LoadingOutlined style={{ color: '#98c33e' }} spin /> : text}
      </WrapWhite>
    ),
    green: (
      <WrapGreen as={to ? Link : 'button'} {...props}>
        {icon && !loading && <img src={icon} alt={''} />}
        {loading ? <LoadingOutlined style={{ color: '#98c33e' }} spin /> : text}
      </WrapGreen>
    ),
    grey: (
      <WrapGrey as={to ? Link : 'button'} {...props}>
        {icon && !loading && <img src={icon} alt={''} />}
        {loading ? <LoadingOutlined style={{ color: '#98c33e' }} spin /> : text}
      </WrapGrey>
    ),
  }

  return button[type]
}

export default Button
