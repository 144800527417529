import styled from 'styled-components'

export const Wrap = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 100px 0;
  @media (max-width: 1600px) {
    justify-content: flex-start;
  }
`

export const Grid = styled('div')`
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 50% 50%;
`

export const Left = styled('div')`
  width: 100%;
`

export const Right = styled('div')`
  padding-top: 10px;
  margin-top: 8em;
  margin-left: 2em;
  display: ${(props) => props.empty && 'flex'};
  /* align-items: center; */
  flex-direction: column;
  padding-left: 2em;
  border-left: 1px solid rgba(196, 202, 204, 0.6);
`

export const UnderBG = styled('div')`
  font-family: var(--regular);
  font-size: 1em;
  color: var(--text);
  text-align: center;
`

export const MembersWrap = styled('div')`
  height: 100%;
  width: 100%;
`

export const Scrollable = styled('div')`
  max-height: 250px;
  overflow-y: auto;
`

export const MemberItem = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 1em;
  margin-bottom: 1em;
`

export const MemberRight = styled('div')`
  display: flex;

  img {
    cursor: pointer;
    margin: 0 0.5em;
  }
`
