import styled from 'styled-components'
import { PlainText } from '../../../../components/common/PlainText/style'

export const InfoWrap = styled("div")`
  border: 2px solid var(--green);
  padding: 30px 60px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  ${PlainText} {
    font-size: 0.875em;
    padding: 5px 0;
    span {
      font-family: var(--semiBold);
    }
  }
`;

export const PlanIncluding = styled("div")`
  display: flex;
`;

export const BlockWrap = styled("div")`
  padding-bottom: 30px;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 35px;
`;
