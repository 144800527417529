import { message } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { api } from '../../../../../api'
import Input from '../../../../../components/common/Input'
import { Label } from '../../../../../components/common/Input/input.style'
import { getError } from '../../../../../helpers/helpers'
import { useStore } from '../../../../../mobx-store/context'
import Tabs from '../Tabs/tabs'
import { Grid, Wrap } from './form.style'

const Form = observer(
  ({ editing, defaultValues, onAddUser, additionalUsers }) => {
    const store = useStore()
    const {
      control,
      handleSubmit,
      setError,
      errors,
      reset,
      clearErrors,
      getValues,
      setValue,
      watch,
      register,
    } = useForm({
      defaultValues,
    })
    const [loading, setLoading] = useState(false)

    watch(['member_type', 'resource_role', 'report_source_role', 'license_source_role'])

    useEffect(() => {
      reset(defaultValues)
    }, [defaultValues])

    useEffect(() => {

      api('/report_types/', {}, 'GET').then((data) => {
        // const filteredReportTypes = data.filter(item => store?.member?.report_source_report_types.includes(item.id))
        store.setSingleValue('memberReportTypes', data)
      })

    }, [])

    const checkIfUserExist = (user) => {
      return !!additionalUsers.find((item) => item.email === user.email)
    }

    const confirmMember = (values) => {
      setLoading(true)
      api(
        '/members/confirm_team/',
        {
          members: [
            {
              ...values,
              ...(values.member_type === 'admin' ||
                values.resource_role === 'admin'
                ? { member_type: 'admin', resource_role: 'admin' }
                : {}),
            },
          ],
        },
        'POST'
      )
        .then((data) => {
          if (!data.errors) {
            reset({
              jurisdictions: [],
              topics: [],
              resource_guides: [],
              resource_jurisdictions: [],
              line_of_business: [],
              assign: 'jurisdictions',
              first_name: '',
              last_name: '',
              phone_number: '',
              email: '',
              member_type: null,
              resource_role: null,
            })

            store.setToExistingMembers(...data)
            message.success('User added')
          } else {
            message.error(getError(data))
          }
          setValue('member_type', null)
          setLoading(false)
        })
        .catch((error) => console.log(error))
    }

    function replaceNullWithArray(obj) {
      for (let key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === null) {
          obj[key] = [];
        }
      }
      return obj;
    }

    const onSubmit = (values) => {
     const updatedValues = replaceNullWithArray(values);
      const body = {
        ...updatedValues,
        have_access_to_resource:
          values.have_access_to_resource === 'true' ? true : false,
        have_access_to_tracking:
          values.have_access_to_tracking === 'true' ? true : false,
        have_access_to_report_source:
          values.have_access_to_report_source === 'true' ? true : false,
        have_access_to_proposed_source:
          values.have_access_to_proposed_source === 'true' ? true : false,
        have_access_to_license_source:
          values.have_access_to_license_source === 'true' ? true : false,
      }

      api('/members/check_member/', body, 'POST')
        .then((data) => {
          if (!data.errors) {
            if (store.members.length >= store.currentTariff?.included_members) {
              if (checkIfUserExist(body)) {
                setError('email', {
                  type: 'manual',
                  message:
                    'User with such email already exist. Please, enter another email',
                })
              } else {
                clearErrors()
                reset({
                  jurisdictions: [],
                  topics: [],
                  line_of_business: [],
                  assign: 'jurisdictions',
                  first_name: '',
                  last_name: '',
                  phone_number: '',
                  email: '',
                  member_type: null,
                  resource_role: null,
                })
                console.log('ASLDJKHAKLSJ')
                onAddUser(body)
              }
            } else {
              confirmMember(body)
            }
          } else {
            if (data.errors.email_domain) {
              setError('email', { message: data.errors.email_domain[0] })
            }
            getError(data, setError)
          }
        })
        .catch((err) => console.log(err))
    }

    useEffect(() => {
      store.loadSelect('memberOptions')
    }, [])

    return (
      <Wrap>
        {editing && (
          <Label style={{ fontFamily: 'var(--semiBold)' }}>
            Edit Team Member
          </Label>
        )}
        <Grid>
          <Input
            name={'first_name'}
            label={'First Name'}
            error={errors && errors.first_name && 'Enter valid first name'}
            type={'text'}
            transparent
            control={control}
            validation={{ required: true, pattern: /[A-Za-z]+/ }}
            placeholder={'First Name'}
            style={{ marginBottom: errors && errors.first_name && '0' }}
          />
          <Input
            name={'last_name'}
            label={'Last Name'}
            error={errors && errors.last_name && 'Enter valid last name'}
            type={'text'}
            control={control}
            placeholder={'Last Name'}
            transparent
            validation={{ required: true, pattern: /[A-Za-z]+/ }}
            style={{ marginBottom: errors && errors.last_name && '0' }}
          />
        </Grid>
        <Grid>
          <Input
            name={'email'}
            label={editing ? 'Email cannot be edited' : 'Email'}
            error={
              errors &&
              errors.email &&
              (errors.email.message || 'Enter valid email')
            }
            type={'email'}
            disabled={editing}
            placeholder={'Email Address'}
            transparent
            control={control}
            validation={{ required: !editing }}
            style={{ marginBottom: errors && errors.email && '0' }}
          />

          <Input
            name={'phone_number'}
            label={'Phone'}
            error={
              errors &&
              errors.phone_number &&
              (errors.phone_number.message || 'Enter a valid number')
            }
            type={'phone'}
            transparent
            control={control}
            validation={{ required: false }}
            style={{ marginBottom: errors && errors.phone_number && '0' }}
          />
        </Grid>
        <Tabs
          control={control}
          loading={loading}
          onSubmit={handleSubmit(onSubmit)}
          memberType={{
            tracking: getValues('member_type'),
            resource: getValues('resource_role'),
            reportSource: getValues('report_source_role'),
            licenseSource: getValues('license_source_role'),
          }}
          register={register}
          errors={errors}
        />
      </Wrap>
    )
  }
)

export default Form
