import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useStore } from "../../../../../mobx-store/context";
import moment from 'moment'
import { observer } from "mobx-react-lite";
import { StyledSwitch } from "../../../CreateCompany/pages/FirstPage/style";
import { SwitchTitle, SwitchText, Row, AddButton } from "../../../CreateCompany/pages/ManageProducts/style";
import { useForm } from "react-hook-form";
import Input from "../../../../../components/common/Input";
import { Divider, } from "antd";
import Button from "../../../../../components/common/Button/button";
import { PrevBtn } from "../../../CreateCompany/pages/FirstPage/Form/style";
import { SubTitle } from '../../../CreateCompany/pages/FirstPage/style'
import { api } from "../../../../../api";
import EditGuideGroupModal from "./Modal";
import GuideGroup from "./GuideGroup";
import { formatCurrency } from "../../../helpers";
import EndDateConfirmModal from "../../../EditCompany/Tabs/Resource/GuideGroup/EndDateConfirmModal";

const ResourceTab = ({ handleTabChange, companyId }) => {
  const history = useHistory()
  const store = useStore()
  const { handleSubmit, control, getValues, watch, setValue } = useForm({
    defaultValues: {
      resource_start_date: store?.cmsCompany[0]?.resource_start_date || null,
      resource_end_date: store?.cmsCompany[0]?.resource_end_date || null,
      resource_custom_price: formatCurrency(store?.cmsCompany[0]?.resource_custom_price) || '',
    }
  })

  const [endDateModalVisible, setEndDateModalVisible] = useState(false)
  const [tempEndDate, setTempEndDate] = useState(null)
  const [hasAccesstoResource, setHasAccesstoResource] = useState(store?.cmsCompany[0]?.have_access_to_resource || false)
  const [hasCustomPrice, setHasCustomPrice] = useState(store?.cmsCompany[0]?.has_resource_custom_pricing || false)
  const [guidesCount, setGuidesCount] = useState(store?.cmsCompany[0]?.guides?.length || 0)
  const [jursCount, setJursCount] = useState(store?.cmsCompany[0]?.resource_jurisdictions?.length || 0)
  const [allJurisdictions, setAllJurisdictions] = useState(store?.cmsCompany[0]?.all_resource_jurisdictions)
  const [allGuides, setAllGuides] = useState(store?.cmsCompany[0]?.all_resource_guides)
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)

  const updateCompany = (values) => {
    const data = {
      ...values,
      resource_start_date: moment(values.resource_start_date).format('YYYY-MM-DD') || null,
      resource_end_date: moment(values.resource_end_date).format('YYYY-MM-DD') || null,
      have_access_to_resource: hasAccesstoResource,
      has_resource_custom_pricing: hasCustomPrice,
      resource_custom_price: values.resource_custom_price && values.resource_custom_price
        ? values.resource_custom_price.replace(/[^0-9.]/g, "")
        : null,
      all_resource_jurisdictions: allJurisdictions,
      all_resource_guides: allGuides,
    }
    api(`/cms/companies/${store?.cmsCompany[0]?.id}/`, data, 'PATCH').then((res) => {
      if (!res.errors)
        store.setSingleValue('cmsCompany', [res])

    })
  }

  const onSubmit = (values) => {
    updateCompany(values)
    handleTabChange('report')
  }

  const getAllGroups = () => {
    setLoading(true)
    api(`/cms/resource_portions/?company=${companyId}`, {}, 'GET').then((res) => {
      if (!res.errors) {
        store.setSingleValue('cmsGuideGroups', res)
        setLoading(false)
      }
    })
  }

  const watchValues = watch();

  useEffect(() => {
    getAllGroups()
  }, [])

  useEffect(() => {
    setGuidesCount((getValues('guides') && getValues('guides').length) || 0)
    setJursCount((getValues('resource_jurisdictions') && getValues('resource_jurisdictions').length) || 0)
  }, [watchValues]);

  useEffect(() => {
    updateCompany(getValues())
  }, [watch('resource_start_date'), watch('resource_end_date'), hasAccesstoResource, hasCustomPrice, allJurisdictions, allGuides])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {visible && <EditGuideGroupModal onClose={() => {
        setVisible(false)
        getAllGroups()
      }} />}
      <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
        {endDateModalVisible && <EndDateConfirmModal onClose={() => setEndDateModalVisible(false)} setValue={() => setValue('resource_end_date', tempEndDate)} />}
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 25, marginBottom: 20 }}>
          <StyledSwitch
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={(checked) => { setHasAccesstoResource(checked) }}
            defaultChecked={store?.cmsCompany[0]?.have_access_to_resource}
          />
          <div style={{ marginLeft: 10 }} >
            <SwitchTitle>Has Access to ReSource:</SwitchTitle>
            <SwitchText>Company will have access to ReSource, the date they can first log in.</SwitchText>
          </div>
        </div>
        {/* <Divider style={{ background: '#CCC' }} />
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 20 }}>
          <StyledSwitch
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={(checked) => { setHasCustomPrice(checked) }}
            defaultChecked={store?.cmsCompany[0]?.has_resource_custom_pricing}
          />
          <div style={{ marginLeft: 10 }} >
            <SwitchTitle>Has Custom Pricing</SwitchTitle>
            <SwitchText>Show a custom price, if not selected no price will <br /> show in the admin’s settings page.</SwitchText>
          </div>
        </div >
        {hasCustomPrice &&
          <Input
            smallLabel
            label={'Custom Price'}
            disabled={!hasCustomPrice}
            name={'resource_custom_price'}
            type={'text'}
            control={control}
            validation={{ required: true }}
            error={errors && errors.resource_custom_price && 'Enter valid custom price'}
            transparent
            placeholder={'Custom Price'}
            style={{ width: '48%' }}
          />
        } */}
        <Divider style={{ background: '#CCC' }} />
        <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Input
            smallLabel
            name={'resource_start_date'}
            showCross={false}
            label={'Start Date'}
            type={'date'}
            control={control}
            validation={{ required: false }}
            transparent
            placeholder={'Select Date'}
            noLimit
          />
          <Input
            smallLabel
            name={'resource_end_date'}
            showCross={true}
            label={'End Date'}
            type={'date'}
            control={control}
            validation={{ required: false }}
            transparent
            placeholder={'Select Date'}
            noLimit
            handleChange={(e) => {
              setTempEndDate(e)
              setEndDateModalVisible(true)
            }}
            handleReset={() => setValue('resource_end_date', null)}
          />
        </Row >
        <Divider style={{ background: '#CCC' }} />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <SubTitle>ReSource Guide Groups</SubTitle>
          <AddButton onClick={() => setVisible(true)}>+Add New Group</AddButton>
        </div>
        <Divider style={{ background: '#CCC' }} />
        {store.cmsGuideGroups && !loading && store.cmsGuideGroups.map((group) => {
          return (
            <GuideGroup group={group} newGroup={false} edit={true} getAllGroups={getAllGroups} />
          )
        })}
        {/* <SwitchTitle>Select ReSource Guides To Provide Access To:</SwitchTitle>
     <DetailsWrap style={{ gridTemplateColumns: '1fr 1fr' }}>
       <TableTitle> Jurisdictions ({allJurisdictions ? 'All' : jursCount})</TableTitle>
       <TableTitle> Guides ({allGuides ? 'All' : guidesCount})</TableTitle>
     </DetailsWrap>
     <DetailsWrap style={{ gridTemplateColumns: '1fr 1fr' }}>
       <AllCheckboxContainer>
         <div> All Jurisdictions </div>
         <Checkbox
           onChange={(e) => setAllJurisdictions(e.target.checked)}
           name={'all_resource_jurisdictions'}
           style={{ marginLeft: 0, marginRight: 40  }}
           checked={allJurisdictions}
         >
         </Checkbox>
       </AllCheckboxContainer>
       <AllCheckboxContainer>
         <div> All Guides </div>
         <Checkbox
           onChange={(e) => setAllGuides(e.target.checked)}
           name={'all_topics'}
           style={{ marginLeft: 0, marginRight: 5}}
           checked={allGuides}
         >
         </Checkbox>
       </AllCheckboxContainer>
     </DetailsWrap>
     {(!allJurisdictions || !allGuides) &&
       <DetailsWrap style={{ gridTemplateColumns: '1fr 1fr' }}>
         <CheckboxGroup
           defaultValue={store?.cmsCompany[0]?.resource_jurisdictions} 
           control={control}
           name={'resource_jurisdictions'}
           disabled={allJurisdictions}
           options={store?.cms_options.jurisdictions} />
         <CheckboxGroup
           defaultValue={store?.cmsCompany[0]?.guides}
           control={control}
           name={'guides'}
           disabled={allGuides}
           options={store?.cms_options.guides} />
       </DetailsWrap>
     } */}
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <PrevBtn onClick={() => history.goBack()}>Previous</PrevBtn>
          <Button
            text={'Next'}
            htmlType="submit"
            type={'main'}
            style={{
              width: '10%',
              padding: 0,
              marginRight: 0,
              marginLeft: '.5em',
              marginTop: '2.3em',
            }}
          />
        </Row>
      </form>
    </>

  )
};

export default ResourceTab;