import React from 'react'
import angle from '../../../../assets/images/arrow_drop_up_24px.svg'

const SortIcon = ({ sorter }) => {
  return (
    <div style={{ marginLeft: 5, flexDirection: 'column', display: 'flex' }}>
      <img
        src={angle}
        alt='sort-icon'
        style={{
          marginTop: 2,
          opacity: sorter === ('descend' || '') ? 0.5 : 1,
        }}
      />
      <img
        src={angle}
        alt='sort-icon'
        style={{
          transform: 'rotate(180deg)',
          marginTop: 5,
          opacity: sorter === ('ascend' || '') ? 0.5 : 1,
        }}
      />
    </div>
  )
}

export default SortIcon
