import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import Checkbox from '../../../../components/common/Checkbox'
import { Wrap } from './style'
import { useForm } from 'react-hook-form'
import Input from '../../../../components/common/Input'
import { api } from '../../../../api'
import { useStore } from '../../../../mobx-store/context'

const options = [
    { id: 'weekly', name: 'Weekly' },
    { id: 'monthly', name: 'Monthly' },
]

const PastDueAlerts = observer(({ styleWrap, isReportSource, checked }) => {
   
    const { handleSubmit, control, reset } = useForm()

    const store = useStore()
    // const checked = isReportSource? store.company?.[0]?.report_past_due_items_notifications_frequency : store.company?.[0]?.compliance_past_due_items_notifications_frequency
    const [active, setActive] = useState(checked)
    const onSubmit = (value) => {
        api('/companies/past_due_notifications/', { ...value }, 'PATCH').then(data => {
            if (data.errors) return message.error('Something went wrong')
            message.success('Past Due Alerts Frequency Updated!')
            store.getCompany()
        })
        
    }

    const handleCheckbox = (value) => {
        if (!value) {
            setActive(value)
            const field = isReportSource
                ? 'report_past_due_items_notifications_frequency'
                : 'compliance_past_due_items_notifications_frequency'
            api('/companies/past_due_notifications/', { [field]: null }, 'PATCH').then(data => {
                if (data.errors) return message.error('Something went wrong')
                message.success('Past Due Alerts Frequency Updated!')
                reset({
                    report_past_due_items_notifications_frequency: null,
                    compliance_past_due_items_notifications_frequency: null,
                })
                store.getCompany()
            })
        } setActive(value)
    }

    return (
        <Wrap style={styleWrap}>
            <div style={{ display: 'grid', width: '100%', gridTemplateColumns: '1fr 1fr', alignItems: 'center', marginBottom: 15 }}>
                <Checkbox
                    label={isReportSource
                        ? 'Receive Alerts for Past Due Report Items'
                        : 'Receive Alerts for Past Due Compliance Items'}
                    checked={active}
                    onChange={(e) => handleCheckbox(e.target.checked)}
                />
                <Input
                    control={control}
                    name={isReportSource
                        ? 'report_past_due_items_notifications_frequency'
                        : 'compliance_past_due_items_notifications_frequency'
                    }
                    type={'select'}
                    options={options}
                    placeholder={'Select'}
                    allowClear={false}
                    style={{ width: 100 }}
                    disabled={!active}
                    defaultValue={checked}
                    handleChange={handleSubmit(onSubmit)}
                />
            </div>
            <div>Admins will receive an email with all {isReportSource ? 'reports' : 'compliance items'} past due and not completed.</div>
            <div> {isReportSource ? 'Report' : 'Compliance'} Owners will receive an email with only {isReportSource ? 'reports' : 'compliance'} items assigned to them and not completed.</div>
        </Wrap>
    )
}
)

export default PastDueAlerts
