import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Wrap = styled('div')`
  border-bottom: 1px solid #c4cacc;
`
export const Button = styled(Link)`
  font-family: var(--regular);
  font-size: 14px;
  color: #4b75b1;
  cursor: pointer;
  text-decoration: underline;
`
