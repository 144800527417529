import styled from 'styled-components'
import { Row as InputRow } from '../../../../components/common/Input/input.style'

export const Wrap = styled('div')``

export const Row = styled('div')`
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  margin-top: 20px;
  ${InputRow} {
    margin-right: 23px;
  }
`
