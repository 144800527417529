import styled from 'styled-components';
import { Table } from 'antd';


export const StyledTable = styled(Table)`
  .ant-table {
    font-size: 16px;
  }
  .ant-table-thead {
    tr {
      th {
        background-color:  #F2F5FF !important;
      }
    }
  }
  .ant-table-cell {
    font-size: 0.75em;
    font-family: var(--medium);
    color: var(--text);
    cursor: pointer;
  }
  .active-row {
    td {
      color: var(--green);
      background-color: #f5f9ec;
      font-family: var(--open-sans-bold);
      &:first-child {
        border-left: 4px solid var(--green);
      }
    }
  }
  .ant-pagination-item {
    border-color: #dadada !important;
    margin: 0 0.1em !important;
    border-radius: 4px;
    font-family: var(--regular);
    font-size: 0.714em;
    height: 15px !important;
    width: 16px !important;
    min-width: 16px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    &-active {
      a {
        font-family: var(--bold);
        color: var(--orange);
      }
    }
    a {
      height: 15px;
      line-height: 15px;
      &:hover {
        color: var(--orange);
      }
    }
  }
  .ant-pagination.mini .ant-pagination-prev,
  .ant-pagination.mini .ant-pagination-next {
    height: 15px;
    line-height: 15px;
    button {
      color: rgba(0, 0, 0, 0.54);
    }
  }
`

export const TableHeaderTitle = styled('div')`
  font-size: 12px;
  font-family: var(--bold);
  color: var(--black);
`
export const PageWrap = styled('div')`
  width: 80%;
  padding-top: 90px;
  padding-bottom: 200px;
  margin: 0 auto;
  min-height: calc(100vh - 50px);
`
export const NavPageWrap = styled('div')`
  width: 100%;
  padding-bottom: 200px;
  min-height: calc(100vh - 50px);
`

export const PageTitle = styled('div')`
  color: var(--blue);
  font-family: var(--bold);
  font-size: 24px;
`
export const TitleBlock = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

export const Subtitle = styled('div')`
  color: var(--text);
  font-family: var(--regular);
  font-size: 12px;
  a {
    color: var(--blue);
    font-family: var(--regular);
    font-size: 12px;
    text-decoration-line: underline;
  }
`