import { Row } from "../../../CreateCompany/pages/ManageProducts/style";
import React from 'react';
import Input from '../../../../../components/common/Input';
import { Divider } from 'antd';
import { formatCurrency } from "../../../helpers";
import { useState } from 'react';
import PricingRemoveModal from './modal';

const Item = ({ item, index, register, control, removePricing }) => {


  const [visible, setVisible] = useState(false)
  return (
    <div style={{ width: '100%' }}>
      {visible && <PricingRemoveModal onClose={() => setVisible(false)} onRemove={() => removePricing(item.pricing_id, index)} />}
      <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20 }}>
        <Input
          {...register(`items[${index}].product`)}
          control={control}
          smallLabel
          name={`items[${index}].product`}
          label="Product"
          type="select"
          options={[
            { id: 'bundle', name: 'Bundle' },
            { id: 'compliance_source', name: 'ComplianceSource' },
            { id: 'report_source', name: 'ReportSource' },
            { id: 'proposed_source', name: 'ProposedSource' },
            { id: 'license_source', name: 'LicenseSource' },
          ]}
          transparent
          placeholder="Select"
          defaultValue={item.product || ''}
          disableValidation={true}
        />
        <Input
          {...register(`items[${index}].value`)}
          control={control}
          smallLabel
          name={`items[${index}].value`}
          label="Value"
          type="text"
          transparent
          placeholder="$"
          defaultValue={formatCurrency(item.value) || null}
          disableValidation={true}
        />
      </Row>
      <Input
        {...register(`items[${index}].internal_notes`)}
        control={control}
        smallLabel
        name={`items[${index}].internal_notes`}
        label="Internal Notes Only"
        type="textarea"
        transparent
        placeholder=""
        defaultValue={item.internal_notes || ''}
        disableValidation={true}
      />
      <input
        type="hidden"
        {...register(`items[${index}].pricing_id`)}
        defaultValue={item.pricing_id}
      />
      <div style={{
        color: 'var(--red)',
        fontFamily: 'var(--bold)',
        fontSize: 12,
        cursor: 'pointer',
        textDecoration: 'underline',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 10
      }} onClick={() => setVisible(true)}> {'Remove'}</div>
      <Divider style={{ padding: '0, 32px', backgroundColor: '#CCC' }} />
    </div>
  );
};

export default Item;
