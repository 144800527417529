import styled from "styled-components";
import { query1300, query500 } from '../Home/helpers'

export const Divider = styled.div`
  width: 100%;
  height: 37px;
  background: linear-gradient(180deg, #F1F1F1 0%, #FFFFFF 100%);
  opacity: 0.5;
  flex: none;
  order: 1;
  flex-grow: 0;
`
export const ItemsRow = styled.div`
  margin: 0 auto;
  width: ${(props) => (props.fluid ? '100%' : '80vw')};
  max-width: ${(props) => (props.fluid ? '100%' : '1200px')};
  display: flex;
  @media${query1300} {
    width: ${(props) => (props.fluid ? '100%' : 'calc(100vw - 30px)')};
  }
  display: grid;
  grid-template-columns: 1fr 1fr ;
  gap: 32px;
  margin: 0 auto;
`
export const ItemTitle = styled.div`
  font-family: var(--inter-bold);
  font-size: 32px;
  margin-bottom: 20px;
  color: ${props => props.color};
`
export const Description = styled.div`
font-family: var(--inter-regular);
color: var(--text);
font-size: 14px;
margin-bottom: 10px;
`
export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.titleLeft ? 'start' : 'center'};
  background: #FFFFFF;
  border: 1px solid #E4E7EC;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  border-radius: 16px;
  padding: 32px;
`
