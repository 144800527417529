import { Link } from 'react-router-dom'
import styled from 'styled-components'
import bg from '../../../assets/images/bg-logo.png'
import { Wrap } from '../../../components/containers/container.style'

export const ResetContainer = styled(Wrap)`
  background-image: url(${bg});
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
`

export const MiniTitle = styled('div')`
  font-family: var(--medium);
  color: var(--text);
  font-size: 1.25em;
  margin: 0 auto;
  text-align: center;
`

export const Row = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1em;
`

export const Back = styled(Link)`
  font-family: var(--regular);
  font-size: 0.75em;
  color: var(--orange);
  text-decoration: underline; ;
`

export const Image = styled('img')`
  height: 125px;
  margin-top: 20px;
`
