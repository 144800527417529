import React from 'react'
import { Wrap, Title } from './style'
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import CloseIcon from '../../../assets/images/close_white.svg'
const MaintenanceDisclaimer = ({ settingsPage, onClose, fadeOut }) => {
  const params = useParams()
  const currentYear = new Date().getFullYear();
  const exeptions = params.type && params?.type === 'topics' ? true : false
  return (
    <Wrap settingsPage={settingsPage} exeptions={exeptions} fadeOut={fadeOut}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', right: '-1px', top: '-1px' }}>
        <img
          src={CloseIcon}
          alt={'delete-icon'}
          onClick={() => {
            if (onClose) onClose()
            sessionStorage.setItem('showDisclaimer', 'false')
          }}
          style={{ cursor: 'pointer', marginTop: 5, marginRight: 10, width: 9, height: 9 }}
        />
      </div>
      <Title style={{ padding: 5 }}>
        We are performing important updates to enhance your experience. The service will be temporarily unavailable on November 9th at 4AM EST to November 11th at 4AM EST. Thank you for your patience.
      </Title>
    </Wrap>
  );
};

export default MaintenanceDisclaimer