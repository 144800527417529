import styled from "styled-components";


export const Row = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin: 20px 0;
`

export const SubTitle = styled('div')`
font-family: var(--semiBold);
font-size: 12px;
color: #515151;
line-height: 17px;
`