import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import Modal from '../../../../../components/common/Modal'
import { useStore } from '../../../../../mobx-store/context'
import Item from './Item'
import { Title } from './style'

const PlansModal = observer(
  ({ data, discount, currentPlace, onClose, can_change_all_tariffs }) => {
    const [active, setActive] = useState(currentPlace)
    const store = useStore()

    if (!data) return null

    useEffect(() => {
      setActive(currentPlace)
    }, [currentPlace])

    useEffect(() => {
      store.loadSelect('companyOptions')
    }, [])
    
    useEffect(() => {
      store.setSingleValue('planModalOpen', true)
    }, [])

    return (
      <Modal onClose={onClose} id='modal-container'>
        <Title>Review Available Plans</Title>
        {data.map((item) => (
          <Item
            item={item}
            discount={discount}
            currentPlace={currentPlace}
            changeTariff={(place) => setActive(place)}
            active={active === item.place}
            resetTariff={() => setActive(currentPlace)}
            closeModal={onClose}
            can_change_all_tariffs={can_change_all_tariffs}
          />
        ))}
      </Modal>
    )
  }
)

export default PlansModal
