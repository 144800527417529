import React, { useEffect } from 'react'
import { api } from '../../../../../api'
import { Controller } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import Button from '../../../../../components/common/Button/button'
import { checkRole } from '../../../../../helpers/helpers'
import { useStore } from '../../../../../mobx-store/context'
import AssignedItems from './AssignedItems'
import {
  blocksResource,
  blocksTracking,
  blocksReportSource,
  blocksProposedSource,
  ultraLiteblocksTracking,
  blocksLicenseSource,
} from './static'

const Right = ({ memberType, control, onSubmit, loading, }) => {
  const store = useStore()
  const params = useParams()
  const company = store.company?.[0]

  const isResource = params.type === 'resource'
  const isReportSource = params.type === 'reportSource'
  const isProposedSource = params.type === 'proposedSource'
  const isLicenseSource = params.type === 'licenseSource'

  const member = store.member

  const blocks = isReportSource
    ? blocksReportSource
    : isResource
      ? blocksResource
      : isLicenseSource
        ? blocksLicenseSource
        : isProposedSource
          ? blocksProposedSource
          : company.compliance_source_mode === 'compliance_source_ultra_lite'
            ? ultraLiteblocksTracking
            : blocksTracking

  const roleCheck = isResource
    ? true
    : isReportSource
      ? checkRole([memberType], ['admin', 'report_owner'])
      : isLicenseSource ?
        checkRole([memberType], ['admin', 'license_owner'])
        : checkRole([memberType], ['admin', 'compliance_owner'])

  useEffect(() => {
    if (!store.resources) store.getResourceTopic()
  }, [])

  useEffect(() => {
    if (!store.memberReportTypes) {
      api('/report_types/', {}, 'GET').then((data) => {
        const filteredReportTypes = data.filter(item => member.report_source_report_types.includes(item.id))
        store.setSingleValue('memberReportTypes', filteredReportTypes)
      })
    }
  }, [])

  useEffect(() => {
    isReportSource &&
      api('/report_types/', {}, 'GET').then((data) => {
        store.setSingleValue('reportTypes', data)
      })
  }, [])

  useEffect(() => {
    if (!store.memberProposedJurisdictions) {
      const filteredProposedJurisdictions = store?.jurs?.filter(item => member.proposed_source_jurisdictions
        .includes(item.id))
      store.setSingleValue('memberProposedJurisdictions', filteredProposedJurisdictions)
    }
  }, [])

  return (
    <>
      {roleCheck &&
        blocks.map((item) =>
          item.type === 'lineOfBusinessesMembers' &&
            !company?.is_premium ? null : (
            <Controller
              control={control}
              name={item.name}
              defaultValue={[]}
              key={item.key}
              render={({ onChange, value }) => (
                <AssignedItems
                  items={value}
                  type={item.type}
                  title={item.title}
                  onChange={(value) => onChange(value)}
                  disabled={!member?.is_active}
                  findItemVar={item.findItemVar}
                />
              )}
            />
          )
        )}
      <Button
        text={'Save User'}
        style={{ backgroundColor: '#91C03E', padding: '0 3.25em' }}
        onClick={onSubmit}
        loading={loading}
        disabled={!member?.is_active}
      />
    </>
  )
}

export default Right