import React, { useEffect, useState } from 'react'
import {
  Activity,
  Block,
  DocumentsPlaceholder,
  Text,
} from '../../Tracking/Right/right.style'
import { PageTitle } from '../ReportEdit/ReportPage/style'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../mobx-store/context'
import { Spin } from 'antd'
import Moment from 'react-moment'
import CustomPagination from '../../../components/common/Pagination/pagination'
import parse from 'html-react-parser'
import Wraper from '../CMSContainer';
import { useParams } from 'react-router-dom'
import { api } from '../../../api'
import ButtonOrange from '../../../components/common/ButtonOrange'
import { useHistory } from 'react-router-dom'
import { set } from 'mobx'


const CMSItemActivity = observer(({ serviceName }) => {

  const store = useStore()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [current, setCurrent] = useState(1)
  const [count, setCount] = useState(0)
  const params = useParams()
  const history = useHistory()

  const isReportSource = serviceName === 'report'
  const isCompliance = serviceName === 'compliance'


  const getActivities = (page) => {
    setData(null)
    api(`/cms/${isReportSource ? 'report' : isCompliance ? 'legislatives' : 'pending_bills'}/${params.id}/activity/?page=${page}`, 'GET').then(res => {
      setData(res.results)
      setCount(res.count)
      getCurrentItem()
    }).then(() => setLoading(false))
      .catch(err => {
        setLoading(false)
      })
  }


  useEffect(() => {
    getActivities(1)
  }, [])

  const getCurrentItem = () => {
    api(`/cms/${isReportSource ? 'report' : isCompliance ? 'legislatives' : 'pending_bills'}/${params.id}/`, {}, 'GET').then((res) => {
      isReportSource
        ? store.setSingleValue('cmsCurrentReport', res)
        : isCompliance
          ? store.setSingleValue('cmsCurrentLegislative', res)
          : store.setSingleValue('cmsCurrentProposedItem', res)
    })
  }

  if (loading) return <Spin />

  if (!data) return null

  return (
    <Wraper>
      <Block style={{ marginTop: '1em', marginBottom: '5em', width: '70%' }}>
        <div>
          <PageTitle>{isReportSource
            ? store?.cmsCurrentReport?.report_title
            : isCompliance
              ? store?.cmsCurrentLegislative?.reference_number
              : store?.cmsCurrentProposedItem?.versions[0]?.reference_number}: Activity</PageTitle>
          <ButtonOrange
            text={'< Go Back'}
            onClick={() => history.goBack()}
            style={{ fontFamily: 'var(--bold)', color: '#4E74A9', fontSize: 12, textDecoration: 'none', marginBottom: 32 }}
          />
        </div>
        {!data.length &&
          <DocumentsPlaceholder
            style={{
              marginTop: 15,
              borderBottom: '1px solid #bbb',
              paddingBottom: 15,
            }}
          >
            There are no activities associated with this item yet.
          </DocumentsPlaceholder>}
        {data.map(({ created, activity_type, member, html_text }, index) => (
          <Activity
            key={created}
            style={{ backgroundColor: index % 2 !== 0 && '#fff' }}
          >
            <Text style={{ maxWidth: '80%' }}>{parse(html_text)}</Text>
            <Text style={{ fontSize: '.75em', color: '#7D8587' }}>
              <Moment format="MMM D, YYYY, hh:mm A" withTitle>
                {created}
              </Moment>
            </Text>
          </Activity>
        ))}
        <CustomPagination
          total={count}
          pageSize={'20'}
          current={current}
          arrows
          onChange={(page) => {
            getActivities(page)
            setCurrent(page)
          }}
        />
      </Block >
    </Wraper>
  );
});

export default CMSItemActivity;

// import React, { useEffect, useState } from 'react'
// import {
//   Activity,
//   Block,
//   DocumentsPlaceholder,
//   Label,
//   Text,
// } from './right.style'
// import { observer } from 'mobx-react-lite'
// import { useStore } from '../../../mobx-store/context'
// import { Spin } from 'antd'
// import Moment from 'react-moment'
// import CustomPagination from '../../../components/common/Pagination/pagination'
// import parse from 'html-react-parser'

// const Activities = observer(({ legislative, isReportSource = false }) => {
//   const store = useStore()
//   const [current, setCurrent] = useState(1)
//   const [loading, setLoading] = useState(true)

//   useEffect(() => {
//     store.loadActivity(legislative, 1, () => setLoading(false), isReportSource)
//   }, [legislative])

//   if (loading) return <Spin />

//   if (!store.activity.results) return null

//   const activity = store.activity.results

//   return (
//     <Block style={{ marginTop: '1em', marginBottom: '5em' }}>
//       <Label>Activity</Label>
//       {!activity.length &&
//         <DocumentsPlaceholder
//           style={{
//             marginTop: 15,
//             borderBottom: '1px solid #bbb',
//             paddingBottom: 15,
//           }}
//         >
//           There are no activities associated with this item yet.
//         </DocumentsPlaceholder>}

//       {isReportSource ?
//         activity.map(({ created, activity_type, member, content }, index) => (
//           <Activity
//             key={created}
//             style={{ backgroundColor: index % 2 !== 0 && '#fff' }}
//           >
//             <Text style={{ overflow: 'hidden' }}>{parse(content)}</Text>
//             <Text style={{ fontSize: '.75em', color: '#7D8587' }}>
//               <Moment format="MMM D, YYYY, hh:mm A" withTitle>
//                 {created}
//               </Moment>
//             </Text>
//           </Activity>
//         ))
//         : activity.map(({ created, activity_type, member, html_text }, index) => (
//           <Activity
//             key={created}
//             style={{ backgroundColor: index % 2 !== 0 && '#fff' }}
//           >
//             <Text style={{ maxWidth: '80%' }}>{parse(html_text)}</Text>
//             <Text style={{ fontSize: '.75em', color: '#7D8587' }}>
//               <Moment format="MMM D, YYYY, hh:mm A" withTitle>
//                 {created}
//               </Moment>
//             </Text>
//           </Activity>
//         ))}
//       <CustomPagination
//         total={store.activity.count}
//         pageSize={'24'}
//         current={current}
//         arrows
//         onChange={(page) => {
//           store.loadActivity(legislative, page, null, isReportSource)
//           setCurrent(page)
//         }}
//       />
//     </Block>
//   )
// })

// export default Activities
