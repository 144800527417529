import styled from 'styled-components'

export const Wrap = styled('div')`
  .braintree-sheet__header {
    display: none;
  }

  .braintree-sheet__content--form {
    padding: 0;
  }

  .braintree-sheet {
    border: 0;
  }

  .braintree-form__label {
    font-size: 1.25em !important;
    color: #455154 !important;
    font-family: var(--medium);
    margin-bottom: 0.2em;
  }

  .braintree-sheet__content--form,
  .braintree-form__field-group,
  .braintree-form__field,
  .braintree-form__hosted-field {
    height: 2.313em !important;
    border-radius: 7px;
    border-color: #c4cacc;
  }

  .braintree-sheet__content--form,
  .braintree-form__field-group,
  .braintree-form__field-error {
    color: var(--orange);
  }

  .braintree-sheet__content--form,
  .braintree-form__field-group,
  .braintree-form__field-error-icon,
  use {
    fill: var(--orange);
  }

  .braintree-sheet__content--form,
  .braintree-form__field-group.braintree-form__field-group--has-error,
  .braintree-form__field,
  .braintree-form__hosted-field {
    border-color: var(--orange);
  }

  .braintree-placeholder {
    margin-bottom: 0;
  }
`
