import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import InputMask from 'react-input-mask'
import close from '../../../assets/images/close-grey.svg'
import { CalendarWrap } from '../../../pages/Auth/StartDate/startDate.style'
import { ErrorText, InputWrap, Label } from '../Input/input.style'
import { Overlay, Wrap, CalendarText } from './style'
import './style.css'

const CalendarCustom = ({
  incomeValue = null,
  handleChange,
  disabled,
  required,
  error,
  styleDropdown,
  placeholder,
  label,
  handleOpen,
  redCircleDate,
  noLabel,
  noBorder,
  noLimit,
  disableFutureDates = false,
}) => {
  const [visible, setVisible] = useState(false)
  const [value, setValue] = useState('')
  const [calendarDate, setCalendarDate] = useState(new Date())
  const [lastValidDate, setLastValidDate] = useState(null) 
  const [inputError, setInputError] = useState('') 
  const [viewDate, setViewDate] = useState(new Date()) 
  const [shouldScrollToDate, setShouldScrollToDate] = useState(false) 

  const minDate = noLimit ? null : new Date() 
  const maxDate = disableFutureDates ? new Date() : null 

  const toLocalMidnight = (date) => {
    const localDate = new Date(date)
    localDate.setHours(0, 0, 0, 0) 
    return localDate
  }

  const onClose = (isClosedWithoutFetch) => {
    if (isClosedWithoutFetch && !moment(value, 'MM/DD/YYYY', true).isValid()) {
      setValue(lastValidDate ? moment(lastValidDate).format('MM/DD/YYYY') : '') 
    }
    setVisible(false)
    setInputError('') // Очистка сообщения об ошибке при закрытии
  }

  useEffect(() => {
    if (incomeValue) {
      const formattedDate = moment(incomeValue).format('MM/DD/YYYY')
      setValue(formattedDate)
      const localDate = toLocalMidnight(new Date(incomeValue)) 
      setCalendarDate(localDate)
      setLastValidDate(localDate)
      setViewDate(localDate) 
    } else {
      setValue('')
      setCalendarDate(new Date())
      setLastValidDate(null)
      setViewDate(new Date())
    }
  }, [incomeValue])

  const handleInputChange = (e) => {
    const inputDate = e.target.value
    setValue(inputDate) 

    if (moment(inputDate, 'MM/DD/YYYY', true).isValid()) {
      const formattedDate = toLocalMidnight(new Date(inputDate)) 

      // Проверка ограничений minDate и maxDate
      if (disableFutureDates && formattedDate > new Date()) {
        setInputError('Date cannot be in the future.')
        setValue(moment(lastValidDate).format('MM/DD/YYYY')) 
        return
      }

      if (!noLimit && formattedDate < new Date()) {
        setInputError('Date cannot be before today.')
        setValue(moment(lastValidDate).format('MM/DD/YYYY')) 
        return
      }

      setCalendarDate(formattedDate) 
      setViewDate(formattedDate) 
      setShouldScrollToDate(true)
      setLastValidDate(formattedDate) 
      setInputError('') 
      handleChange(formattedDate)
    } else {
      setInputError('Please enter a date in MM/DD/YYYY format.')
    }
  }

  const handleCalendarChange = (date) => {
    const localDate = toLocalMidnight(date) 
    setCalendarDate(localDate)
    const formattedDate = moment(localDate).format('MM/DD/YYYY')
    setValue(formattedDate)
    setLastValidDate(localDate) 
    setInputError('') 
    handleChange(localDate)
    onClose()
  }

  const handleViewChange = ({ activeStartDate }) => {
    if (!shouldScrollToDate) {
      setViewDate(activeStartDate) 
    }
    setShouldScrollToDate(false) 
  }

  return (
    <>
      {visible && <Overlay onClick={() => onClose(true)} />}
      <div
        style={{
          position: 'relative',
          width: '100%',
          opacity: disabled && '.5',
        }}
      >
        {!noLabel && (
          <Label smallLabel required={required}>
            {label || 'Due Date'}
            <span>*</span>
          </Label>
        )}
        <InputWrap>
          <InputMask
            mask="99/99/9999"
            value={value}
            onChange={handleInputChange}
            disabled={disabled}
            placeholder={placeholder || 'MM/DD/YYYY'}
            onClick={() => {
              if (!disabled) {
                setVisible(!visible)
                handleOpen && handleOpen(true)
              }
            }}
            className={`input-mask ${error || inputError ? 'error' : ''}`}
            style={{
              width: '100%',
              fontSize: '16px',
              fontFamily: 'var(--regular)',
            }}
          />
        </InputWrap>
        {(error || inputError) && <ErrorText>{error || inputError}</ErrorText>}

        <Wrap visible={visible} style={styleDropdown}>
          <CalendarText>Click the Month/Year to Change It</CalendarText>
          <img src={close} alt="close-icon" onClick={() => onClose(true)} />
          <CalendarWrap noLightFill>
            <Calendar
              calendarType={'US'}
              locale={'en-EN'}
              onChange={handleCalendarChange}
              onActiveStartDateChange={handleViewChange} 
              value={calendarDate}
              minDate={minDate}
              maxDate={maxDate}
              tileDisabled={({ date }) => disableFutureDates && date > new Date()}
              tileClassName={({ date }) => {
                if (redCircleDate === moment(date).format('MM/DD/YYYY'))
                  return 'red-circle'
              }}
              activeStartDate={shouldScrollToDate ? viewDate : undefined}
            />
          </CalendarWrap>
        </Wrap>
      </div>
    </>
  )
}

CalendarCustom.propTypes = {}

export default CalendarCustom