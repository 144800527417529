import React from 'react';
import { Badge } from 'antd';
import greyBell from '../../../assets/images/grey_bell.png'
import redBell from '../../../assets/images/red_bell.png'

const BellIconWithBadge = ({ messageCount, onClick }) => {
    const isMessageAvailable = messageCount > 0;

    const offset = messageCount <= 8 ? [10, 10] : [15, 10]

    return (
        <div onClick={onClick} style={{ marginRight: 30, marginLeft: 10, cursor: 'pointer' }}>
            {isMessageAvailable ? (
                <Badge count={messageCount} overflowCount={9} offset={offset}>
                    <img src={redBell} alt="Red Bell Icon" />
                </Badge>
            ) : (
                <img src={greyBell} alt="Gray Bell Icon" />
            )}
        </div>
    );
};

export default BellIconWithBadge;
