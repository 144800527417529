import styled from 'styled-components';

export const DefinitionsContainer = styled('div')`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const DefinitionsTitle = styled('div')`
  font-size: 14px;
  color: var(--text);
  font-family: var(--semiBold);
`;

export const DefinitionsText = styled(DefinitionsTitle)`
  font-family: var(--regular);
   p {
    margin: 0;
   }
`;

export const ShowMore = styled('div')`
  font-family: var(--regular);
  font-size: 14px;
  text-decoration: underline;
  color: #225194;
  cursor: pointer;
`;
