import styled from 'styled-components'
import { Wrap } from '../../../components/common/Input/input.style'

export const HookForm = styled('form')`
  margin-top: 1em;
  max-width: 460px;
  ${Wrap} {
    margin-bottom: 1em;
  }
`
