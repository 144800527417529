import styled from 'styled-components'

export const Grid = styled('div')`
  display: grid;
  grid-template-columns: ${(p) =>
    p.viewOnly ? '1fr 0.2fr' : '100px 70px 1fr 140px'};
  height: 44px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background-color: ${({ index = 1 }) =>
    index % 2 === 0 ? 'rgba(34, 81, 148, 0.05)' : '#fff'};
  gap: 15px;
`

export const Cell = styled('div')`
  height: 100%;
  display: flex;
  align-items: center;
  input {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
  .ant-checkbox-inner {
    border: 2px solid var(--green);
  }
`

export const Price = styled('div')`
  font-family: var(--medium);
  font-size: 14px;
  color: var(--text);
`

export const Guide = styled('div')`
  font-family: var(--medium);
  font-size: 14px;
  color: #225194;

  cursor: pointer;
  white-space: nowrap;
  a {
    color: #225194;
    font-family: var(--medium);
  }
`
