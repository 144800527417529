import { observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'
import Disclaimer from '../../components/common/Disclaimer'
import { useProposedLegistlationList } from '../../hooks/useProposedlegistlationList.js'
import Filters from './Filters'
import List from './List'
import TopLine from './List/topLine'
import BackTopButton from '../../components/common/BackTopButton';
import { Title, Wrap } from './style'
import ChangePasswordModal from '../Auth/ChangePassowordModal'
import TCModal from '../Tracking/Modal/tcModal'
import { useStore } from '../../mobx-store/context'

const ProposedLegislation = observer(({ archive }) => {
  const [searchObj, setSearchObj] = useState({ searchString: '', pageNumber: 1 })
  const [expiredPassword, setExpiredPassword] = useState(false)
  const [showDisclaimer, setShowDisclaimer] = useState({ show: false, fadeOut: false });
  const [alertForAllMode, setAlertForAllMode] = useState(false)
  const {
    loading,
    error,
    items,
    count,
    hasMore,
    resetItems,
    subscription,
  } = useProposedLegistlationList(searchObj, archive);


  const store = useStore()
  const [userLoading, setUserLoading] = useState(true)

  useEffect((
  ) => {
    store.getUser(() => setUserLoading(false))
  }, []);


  useEffect(() => {
    document.title = 'PBMSource - ProposedSource'
  }, []);

  useEffect(() => {
    setExpiredPassword(localStorage.getItem('is_time_to_change_password') === 'true')
  }, [])

  useEffect(() => {
    const show = sessionStorage.getItem('showDisclaimer') === 'true';
    setShowDisclaimer({ show: show, fadeOut: !show });
  }, []);

  const handleDisclaimerClose = () => {
    setShowDisclaimer(prev => ({ ...prev, fadeOut: true }));
    setTimeout(() => {
      setShowDisclaimer({ show: false, fadeOut: false });
      sessionStorage.setItem('showDisclaimer', 'false');
    }, 500);
  }

  useEffect(() => {
    setSearchObj({ searchString: '', pageNumber: 1 })
  }, [archive])

  return (
    <>
      {!userLoading &&
        <>
          {expiredPassword && <ChangePasswordModal onClose={() => setExpiredPassword(false)} expiredPassword />}
          {!store.user[0].is_accepted_terms_and_conditions && <TCModal />}
          {store.user[0].should_see_pbm_updates && <TCModal type='announcement' />}
          <div style={{ position: 'sticky', top: 90, backgroundColor: '#fff' }}>
            <Filters
              archive={archive}
              count={count}
              subscription={subscription}
              alertForAllMode={alertForAllMode}
              setSearchObj={(obj) => setSearchObj(obj)}
            />
            <TopLine archive={archive} resetItems={resetItems} subscription={subscription} loading={loading} setSearchObj={(obj) => setSearchObj(obj)} setAlertForAllMode={(value) => setAlertForAllMode(value)} />
          </div>
          <List
            archive={archive}
            items={items}
            setSearchObj={(obj) => setSearchObj(obj)}
            hasMore={hasMore}
            loading={loading}
            subscriptionAll={subscription}
          />
          <BackTopButton />
        </>
      }
      {showDisclaimer.show && <Disclaimer onClose={handleDisclaimerClose} fadeOut={showDisclaimer.fadeOut} />}
    </>
  )
})
export default ProposedLegislation
