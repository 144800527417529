import styled from 'styled-components'

export const Grid = styled('div')`
  display: grid;
  grid-template-columns: 50px 1fr;
`

export const Wrap = styled('div')`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0 100px 30px;
  padding-bottom: 15px;
  opacity: ${(props) => props.disabled && '.5'};
`

export const CustomCheckboxGroup = styled('div')`
  .ant-checkbox-inner {
    border-color: var(--text) !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent;

    &::after {
      border-color: var(--text) !important;
    }
  }

  .ant-checkbox-checked {
    &::after {
      border: 0;
    }
  }

  label {
    font-family: var(--regular);
    font-size: 16px;
  }
`

export const LeftSide = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const RightSide = styled('div')`
  margin-left: 30px;
`

export const Title = styled('div')`
  font-size: 1.375em;
  color: var(--text);
  font-family: var(--medium);

  span {
    color: var(--green);
  }
`

export const Description = styled('div')`
  font-size: 1em;
  color: var(--text);
  font-family: var(--regular);
`

export const Bottom = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`
