import { TableHeaderTitle, StyledTable, NavPageWrap, PageTitle, TitleBlock, Subtitle } from "./style";
import { useState, useEffect } from 'react'
import { useStore } from "../../../../mobx-store/context";
import { api } from "../../../../api";
import ItemsShowing from '../../../../components/common/TableItemsShowing'
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom/";
import AddingButton from "./addingButton";
import Wraper from "../../CMSContainer";

const GuidesTable = observer(({ tableType }) => {
  const [page, setPage] = useState(1)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const store = useStore()
  const history = useHistory()

  const getData = () => {
    setLoading(true)
    const url = tableType === 'guideNames' ? `/cms/resource_guide_names/?page=${page}` :
      tableType === 'guides'
        ? `/cms/resource_guides/?page=${page}`
        : tableType === 'compliance'
          ? `/cms/legislatives/?page=${page}`
          : tableType === 'report'
            ? `/cms/report/?page=${page}` :
            `/cms/pending_bills/?page=${page}`
    api(url, {}, 'GET').then((res) => {
      // if (res.errors) return history.push('/home')
      tableType === 'guideNames'
        ? store.setSingleValue('cmsGuidesNames', res)
        : tableType === 'guides'
          ? store.setSingleValue('cmsGuides', res)
          : tableType === 'compliance'
            ? store.setSingleValue('cmsLegislatives', res)
            : tableType === 'report'
              ? store.setSingleValue('cmsReportItems', res)
              : store.setSingleValue('cmsProposedSourceItems', res)
      setData(res)
      setLoading(false)
    })
  }

  const columns = [
    {
      title: <TableHeaderTitle>Guide Name</TableHeaderTitle>,
      dataIndex: 'guide_name',
      key: 'guide_name',
      sorter: false,
    },
    {
      title: <TableHeaderTitle>Last Updated</TableHeaderTitle>,
      dataIndex: 'updated',
      key: 'updated',
      sorter: false,
    },
    {
      title: <TableHeaderTitle>Uploaded By</TableHeaderTitle>,
      dataIndex: 'uploaded_by',
      key: 'uploaded_by',
    },
    {
      title: <TableHeaderTitle>Status</TableHeaderTitle>,
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: <TableHeaderTitle>Guide File</TableHeaderTitle>,
      dataIndex: 'file_name',
      key: 'file_name',
      render: (text, record) => (
        <a
          style={{ color: 'var(--text)', textDecoration: 'underline' }}
          target={'_blank'}
          download
          href={record.file}>{text}
        </a>
      ),
    },
  ]

  const guidesNamesColumns = [
    {
      title: <TableHeaderTitle>Guide Name</TableHeaderTitle>,
      dataIndex: 'name',
      key: 'name',
      sorter: false,
      render: (text, record) => (
        <a
          style={{ color: 'var(--text)', textDecoration: 'underline' }}
          target={'_blank'}
          download
          href={record.file}>{text}
        </a>
      )
    },
    {
      title: <TableHeaderTitle>$/Month/Juris.</TableHeaderTitle>,
      dataIndex: 'price',
      key: 'price',
      sorter: false,
      render: (text, record) => <>${text}</>
    },
    {
      title: <TableHeaderTitle>Description</TableHeaderTitle>,
      dataIndex: 'description',
      key: 'description',
    },
  ]

  const reportItemsColumns = [
    {
      title: <TableHeaderTitle>Report Title</TableHeaderTitle>,
      dataIndex: 'report_title',
      key: 'name',
      sorter: false,
      render: (text, record) => (
        <a
          style={{ color: 'var(--text)', textDecoration: 'underline' }}
          href={`dashboard-edit-report/${record.id}`}>{text}
        </a>
      )
    },
    {
      title: <TableHeaderTitle>Jurisdiction</TableHeaderTitle>,
      dataIndex: 'jurisdiction_name',
      key: 'jurisdiction_name',
      sorter: false,
    },
    {
      title: <TableHeaderTitle>Created</TableHeaderTitle>,
      dataIndex: 'created',
      key: 'created',
    },
    {
      title: <TableHeaderTitle>Repeats</TableHeaderTitle>,
      dataIndex: 'repeat_mode',
      key: 'repeat_mode',
    },
    {
      title: <TableHeaderTitle>Published</TableHeaderTitle>,
      dataIndex: 'is_published',
      key: 'is_published',
      render: (text, record) => (
        <>{text ? 'Yes' : 'No'}</>
      )
    },
    {
      title: <TableHeaderTitle>Report Due Date</TableHeaderTitle>,
      dataIndex: 'report_due_date',
      key: 'report_due_date',
      render: (text, record) => (
        <>{record.report_due_date_is_variable ? 'Variable' : text}</>
      )
    },
  ]

  const legislativesItemsColumns = [
    {
      title: <TableHeaderTitle>Reference #</TableHeaderTitle>,
      dataIndex: 'reference_number',
      key: 'reference_number',
      sorter: false,
      render: (text, record) => (
        <a
          style={{ color: 'var(--text)', textDecoration: 'underline' }}
          href={`dashboard-edit-compliance/${record.id}`}>{text}
        </a>
      )
    },
    {
      title: <TableHeaderTitle>Jurisdiction</TableHeaderTitle>,
      dataIndex: 'jurisdiction_name',
      key: 'jurisdiction_name',
      sorter: false,
    },
    {
      title: <TableHeaderTitle>Topic</TableHeaderTitle>,
      dataIndex: 'topics_names',
      key: 'topics_names',
      width: '25%',
    },
    {
      title: <TableHeaderTitle>Created Date</TableHeaderTitle>,
      dataIndex: 'created',
      key: 'created',
    },
    {
      title: <TableHeaderTitle>Adopted Date</TableHeaderTitle>,
      dataIndex: 'adopted_date',
      key: 'adopted_date',
    },
    {
      title: <TableHeaderTitle>Document</TableHeaderTitle>,
      dataIndex: 'reference_document_name',
      key: 'reference_document_name',
      render: (text, record) => (
        <a
          style={{ color: 'var(--text)', textDecoration: 'underline' }}
          href={record.reference_document}>{text}
        </a>
      )
    },
    {
      title: <TableHeaderTitle>Published</TableHeaderTitle>,
      dataIndex: 'is_published',
      key: 'is_published',
      render: (text, record) => (
        <>{text ? 'Yes' : 'No'}</>
      )
    },
  ]

  const proposedItemsColumns = [
    {
      title: <TableHeaderTitle>Reference Number</TableHeaderTitle>,
      dataIndex: 'reference_number',
      key: 'reference_number',
      sorter: false,
      render: (text, record) => (
        <a
          style={{ color: 'var(--text)', textDecoration: 'underline' }}
          href={`dashboard-edit-proposed/${record.id}`}>{text}
        </a>
      )
    },
    {
      title: <TableHeaderTitle>Jurisdiction</TableHeaderTitle>,
      dataIndex: 'jurisdiction',
      key: 'jurisdiction',
      sorter: false,
    },
    {
      title: <TableHeaderTitle>Created Date</TableHeaderTitle>,
      dataIndex: 'created',
      key: 'created',
      sorter: false,
    },
  ]

  const staticData = {
    urls: {
      guideNames: `/cms/resource_guide_names/?page=${page}`,
      guides: `/cms/resource_guides/?page=${page}`,
      compliance: `/cms/legislatives/?page=${page}`,
      report: `/cms/report/?page=${page}`,
      proposed: `/cms/pending_bills/?page=${page}`,
    },
    titles: {
      guideNames: 'Guide Names',
      guides: 'ReSource Guides',
      compliance: 'Compliance Items',
      report: 'ReportSource Items',
      proposed: 'ProposedSource Items',
    },
    subtitles: {
      guideNames: 'Below are guide names and their descriptions. Select a guide to edit it, or create a new guide name.',
      guides: 'Select a Guide File below to view it, or you can upload a new guide | Edit/Add Guide New Guide Names',
      compliance: 'Select a Compliance Item below to view it',
      report: 'Select a Reprt below to view it',
      proposed: 'Select a Proposed Item below to view it',
    },
    buttonsText: {
      guideNames: 'Create New Guide Name',
      guides: 'Upload New Guide',
      compliance: 'Add New Compliance Item',
      report: 'Add New Report Item',
      proposed: 'Add New Proposed Item',
    },
    createNewItemLinks: {
      guideNames: '/dashboard-add-guide-name',
      guides: '/dashboard-upload-guide',
      compliance: '/dashboard-add-compliance',
      report: '/dashboard-add-report',
      proposed: '/dashboard-add-proposed',
    },
    paginationItemNames: {
      guideNames: 'Names',
      guides: 'Guides',
      compliance: 'Legislatives',
      report: 'Reports',
      proposed: 'Proposed Items',
    },
    columns: {
      guideNames: guidesNamesColumns,
      guides: columns,
      compliance: legislativesItemsColumns,
      report: reportItemsColumns,
      proposed: proposedItemsColumns,
    }
  }

  useEffect(() => {
    getData()
  }, [page])

  useEffect(() => {
    getData(true)
  }, [store?.companiesSearch])

  return (
    <> {
      !loading &&
      <Wraper>
        <NavPageWrap >
          <TitleBlock>
            <PageTitle>
              {staticData.titles[tableType]}
            </PageTitle>
            <AddingButton text={staticData.buttonsText[tableType]}
              link={staticData.createNewItemLinks[tableType]}
            />
          </TitleBlock>
          <TitleBlock>
            <Subtitle>
              {staticData.subtitles[tableType]}
            </Subtitle>

          </TitleBlock>
          <StyledTable
            bordered
            columns={staticData.columns[tableType]}
            dataSource={tableType === 'guideNames'
              ? (store?.cmsGuidesNames.results || data.results)
              : tableType === 'guides'
                ? (store?.cmsGuides.results || data.results)
                : tableType === 'compliance'
                  ? (store?.cmsLegislatives.results || data.results)
                  : tableType === 'report'
                    ? (store?.cmsReportItems.results || data.results)
                    : (store?.cmsProposedSourceItems.results || data.results)
            }
            rowKey={'id'}
            size='small'
            onChange={(pagination, _, sorter) => {
              setPage(pagination.current)
            }}
            pagination={{
              hideOnSinglePage: false,
              pageSize: 20,
              position: ['bottomRight'],
              total: data?.count,
              current: page,
              showSizeChanger: false,
            }}
          />
          <ItemsShowing
            page={page}
            pageSize={20}
            total={data?.count}
            position={'right'}
            adminPanel={true}
            itemsType={staticData.paginationItemNames[tableType]}
            style={{
              textAlign: 'right',
              marginTop: -31,
              paddingRight: 10,
              width: 'fit-content',
              marginBottom: 10,
            }}
          />
        </NavPageWrap>
      </Wraper>
    }
    </>
  )
});

export default GuidesTable;