import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useStore } from '../../../../mobx-store/context'
import { useHistory, useParams } from 'react-router-dom'
import { PageTitle } from '../../components/common/style'
import { Spin } from 'antd'
import PageWrap from '../../components/Container'
import TabsNav from '../../components/TabsNav/tabsNav'
import ResourceTab from './Tabs/ResourceTab/resourceTab'
import TrackingTab from './Tabs/Tracking/tracking'
import ReportSourceTab from './Tabs/ReportSourceTab'
import LicenseSourceTab from './Tabs/LicenseSourceTab'
import ProposedSourceTab from './Tabs/ProposedSourceTab/proposedSourceTab'

const tabs = {
  tracking: {
    button: 'ComplianceSource',
    component: <TrackingTab />,
    link: '/current-plan/tracking',
  },
  resource: {
    button: 'ReSource',
    component: <ResourceTab />,
    link: '/current-plan/resource',
  },
  reportSource: {
    button: 'ReportSource',
    component: <ReportSourceTab />,
    link: '/current-plan/reportSource',
  },
  proposedSource: {
    button: 'ProposedSource',
    component: <ProposedSourceTab />,
    link: '/current-plan/proposedSource',
  },
  licenseSource: {
    button: 'LicenseSource',
    component: <LicenseSourceTab />,
    link: '/current-plan/licenseSource',
  }
}

const CurrentPlan = observer(() => {
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const [active, setActive] = useState(null)
  const [loading, setLoading] = useState(true)
  const user = store.user?.[0]

  useEffect(() => {
    setActive(
      (user?.have_access_to_tracking
        ? 'tracking'
        : user?.have_access_to_resource
          ? 'resource'
          : user?.have_access_to_report_source
            ? 'reportSource'
            : user?.have_access_to_license_source
              ? 'licenseSource'
            : 'proposedSource'
      )
    )
    setLoading(false)
  }, [])

  useEffect(() => {
    params.type && setActive(params.type)
  }, [params.type])

  return (
    <PageWrap modalOpen={store.planModalOpen}>
      <PageTitle>Current Plan</PageTitle>
      <TabsNav
        tabs={tabs}
        active={active}
        onRedirectTo={(page) => history.push(`/current-plan/${page}`)}
      />
      {loading ? <Spin /> : tabs[active].component}
    </PageWrap>
  )
})

export default CurrentPlan
