
import Modal from "../../../../components/common/ModalNew"
import { Text, Title, GoBackButton } from "../RemoveuserModal/style";
import Button from "../../../../components/common/Button/button";
import { useForm } from "react-hook-form";
import Input from "../../../../components/common/Input";
import { isPasswordValid } from "../../../..//helpers/helpers";
import { api } from "../../../../api";
import { message } from "antd";
import { useEffect } from "react";


const ManualPassModal = ({ onClose, id }) => {
  const {
    control,
    handleSubmit,
    errors,
    getValues,
    reset,
    clearErrors,
    setError,
    setValue,
  } = useForm()

  function generateRandomPassword() {
    const numbers = '0123456789';
    const specialChars = '!@#$%^&*()_+{}:"<>?[];,./`~';
    const allChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+{}:"<>?[];,./`~';

    let password = '';
    password += numbers.charAt(Math.floor(Math.random() * numbers.length));
    password += specialChars.charAt(Math.floor(Math.random() * specialChars.length));

    for (let i = 2; i < 12; i++) {
      password += allChars.charAt(Math.floor(Math.random() * allChars.length));
    }

    return password.split('').sort(() => 0.5 - Math.random()).join('');
  }

  const updatePassword = (values) => {
    api(`/cms/users/${id}/set_new_password/`, { password: values.password }, 'PATCH').then((res) => {
      if (res.error) {
        message.error(res.error, 1.5)
      } else {
        message.success('Password has been updated')
        onClose()

      }
    })
  }

  useEffect(() => {
    const password = generateRandomPassword()
    setValue('password', password)
    setValue('password_confirm', password)
  }, [])

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      message.success('Password copied to clipboard', 0.5);
    }).catch(err => {
      message.error('Failed to copy password', 0.8);
    });
  }

  return (
    <Modal
      styleWrap={{ width: '35%', height: 'auto', padding: 30, maxWidth: 580 }}
      onClose={onClose}>
      <Title>
        Manually Assign Password
      </Title>
      <Text>
        This will create a password and <strong>NOT</strong> send an email.
      </Text>
      <div style={{ marginTop: 20, display: 'grid', gridTemplateRows: '1fr', gap: 15 }}>
        <Input
          name={'password'}
          type={'password'}
          label={'New Password'}
          placeholder={'Password'}
          control={control}
          error={errors && errors.password && 'Enter valid password'}
          handleChange={(value) => {
            if (
              value &&
              getValues('password_confirm') &&
              value === getValues('password_confirm')
            ) {
              clearErrors(['password_confirm'])
            }
          }}
          validation={{
            required: true,
            validate: (value) => isPasswordValid(value)
          }}
          showErrorText={true}
          success={isPasswordValid(getValues('password'))}
          smallLabel
          transparent
        />
        <Input
          name={'password_confirm'}
          type={'password'}
          label={'Confirm New Password'}
          placeholder={'Password'}
          control={control}
          error={
            (getValues('password_confirm') &&
              getValues('password') &&
              getValues('password') !== getValues('password_confirm') &&
              'Password doesn\'t match') ||
            (errors.password_confirm && 'Password doesn\'t match')
          }
          validation={{
            validate: (value) => value === getValues('password'),
            required: true,
          }}
          showErrorText={true}
          success={
            getValues('password') &&
            getValues('password_confirm') &&
            !errors.password_confirm &&
            getValues('password') === getValues('password_confirm')
          }
          smallLabel
          transparent
        />
        <Text onClick={() => copyToClipboard(getValues('password'))}>
          <div>Copy the password to your clipboard</div>
        </Text>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1.5em' }}>
        <GoBackButton onClick={() => onClose()}>
          Go Back
        </GoBackButton>
        <Button
          text={'Change Password'}
          onClick={
            handleSubmit(updatePassword)
          }

          type={'main'}
          style={{
            padding: 10,
            marginRight: 0,
          }}
        />
      </div>
    </Modal>
  )
}

export default ManualPassModal