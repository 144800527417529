import React from 'react'
import { Wrap } from './validation.style'

const Validation = ({
  style,
  upperText,
  styleUpperText,
  success,
  noLineThrough,
  text = '• 8 or more characters',
}) => {
  return (
    <Wrap style={style} success={success}>
      {upperText && (
        <div style={{ marginBottom: '.5em', ...styleUpperText }}>
          {upperText}
        </div>
      )}
      <div style={{ textDecoration: !noLineThrough && 'line-through' }}>
        {text}
      </div>
    </Wrap>
  )
}

export default Validation
