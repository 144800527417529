import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import placeholderImage from '../../../../../../assets/images/access_placeholder.svg'
import { checkRole } from '../../../../../../helpers/helpers'
import { useStore } from '../../../../../../mobx-store/context'
import ResourceModal from '../../../../components/ResourceAccessModal/resourceModal'
import { Button, Description, Wrap } from './style'

const data = {
  tracking: {
    title: 'ComplianceSource',
    variable: 'have_access_to_tracking',
    successMessage: 'Access to ComplianceSource granted',
  },
  resource: {
    title: 'ReSource',
    variable: 'have_access_to_resource',
    successMessage: 'Access to ReSource granted',
  },
  reportSource: {
    title: 'ReportSource',
    variable: 'have_access_to_report_source',
    successMessage: 'Access to ReportSource granted',
  },
  proposedSource: {
    title: 'ProposedSource',
    variable: 'have_access_to_proposed_source',
    successMessage: 'Access to ReportSource granted',
  },
  licenseSource: {
    title: 'LicenseSource',
    variable: 'have_access_to_license_source',
    successMessage: 'Access to LicenseSource granted',
  },
}

const Placeholder = ({ toggleAccess, loading, myType }) => {
  const store = useStore()
  const params = useParams()
  const [visible, setVisible] = useState(false)

  const type = params.type || 'tracking'

  const onClick = () => {
    if (loading) return

    if (type === 'resource' && !store.company?.[0].have_access_to_resource) {
      setVisible(true)
      return
    }

    toggleAccess(data[type].variable, true)
  }

  const onClose = () => setVisible(false)

  return (
    <>
      {visible && <ResourceModal onClose={onClose} type={type} />}
      <Wrap>
        <img src={placeholderImage} alt='man-and-woman' />
        <div style={{ marginLeft: 30 }}>
          <Description>
            Give access to {data[type].title} for this user. You’ll be able to
            assign which guides and jurisdictions they have access too.
          </Description>
          {checkRole([myType], ['admin']) && (
            <Button onClick={onClick}>Add User to {data[type].title}</Button>
          )}
        </div>
      </Wrap>
    </>
  )
}

export default Placeholder
