import { PageTitle, NavPageWrap, Subtitle } from "../GuidesTable/style";
import { useState, useEffect, useRef } from 'react'
import ButtonOrange from "../../../../components/common/ButtonOrange";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { formatCurrency } from "../../helpers";
import Input from "../../../../components/common/Input";
import { Divider, message } from "antd";
import Button from "../../../../components/common/Button/button";
import { api } from "../../../../api";
import { useStore } from "../../../../mobx-store/context";
import FullScreenLoader from "../../../../components/common/FullScreenLoader";
import { StyledSwitch } from "../../CreateCompany/pages/FirstPage/style";
import moment from "moment";
import Wraper from "../../CMSContainer";

const UploadGuidePage = () => {
  const history = useHistory()
  const store = useStore()
  const { register, handleSubmit, errors, control, reset } = useForm();
  const [loading, setLoading] = useState(true)
  const [guideNames, setGuideNames] = useState([])
  const [emailUpdate, setEmailUpdate] = useState(false)
  const [guidefile, setFile] = useState(null)
  const fileInputRef = useRef();


  const getBody = (values, file) => {
    let formData = new FormData()

    formData.append('guide_name', values.guide_name)
    formData.append('updated', moment(values.updated).format('YYYY-MM-DD'))
    formData.append('file', file)
    formData.append('send_email_about_new_guide', emailUpdate)

    return formData
  }

  const onSubmit = (data) => {
    api('/cms/resource_guides/ ', getBody(data, guidefile), 'POST', false,
      true).then((res) => {
        if (!res.errors) {
          message.success('Guide added successfully')
          reset({
            guide_name: '',
            file: null,
            updated: '',
          })
          setEmailUpdate(false)
          setFile(null)
          history.push('/dashboard-guides-table/')
        }
      })
  };

  const getGuideNames = () => {
    api('/cms/resource_guide_names/all/', {}, 'GET').then((res) => {
      if (!res.errors) {
        setGuideNames(res)
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    getGuideNames()
  }, [])

  return (
    <>{loading ? <FullScreenLoader />
      : <Wraper>
        <NavPageWrap style={{ width: '60%' }}>
          <PageTitle>
            Upload New Guide
          </PageTitle>
          <Subtitle>
            Select a Guide Name and upload a file for it. If its a new guide, <a href="/dashboard-add-guide-name/">add a new guide name</a> first.
          </Subtitle>
          <ButtonOrange
            text={'< Go Back'}
            onClick={() => history.goBack()}
            style={{ fontFamily: 'var(--bold)', color: '#4E74A9', fontSize: 12, textDecoration: 'none', marginBottom: 32 }}
          />
          <Input
            style={{ width: '100%', marginBottom: 32 }}
            smallLabel
            name={'guide_name'}
            label={'Guide Name'}
            addNew={true}
            options={guideNames}
            onAddNew={() => history.push('/dashboard-add-guide-name/')}
            type={'select'}
            control={control}
            validation={{ required: true }}
            transparent
          />
          <Input
            ref={fileInputRef}
            control={control}
            style={{ width: '100%', marginBottom: 32 }}
            name='file'
            type='file'
            handleChange={(e) => {
              setFile(e.target.files[0])
            }}
            showFile
            label='Guide File'
            smallLabel
            validation={{ required: true }}
            error={errors.file && 'This field is required'}
          />
          <Input
            style={{ width: '50%', marginBottom: 32 }}
            smallLabel
            name={'updated'}
            label={'Updated'}
            type={'date'}
            control={control}
            transparent
            placeholder={'Select Date'}
            showCross={false}
            noLimit
          />
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20, }}>
            <StyledSwitch
              defaultChecked={false}
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={(checked) => { setEmailUpdate(checked) }}
            />
            <div style={{ marginLeft: 10 }} >
              <div style={{ fontFamily: 'var(--regular)', color: 'var(--text)', fontSize: 16 }}>
                Send an Email Update after a successful upload
              </div>
            </div>
          </div>
          <Divider style={{ background: '#8B9394' }} />
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              text={'Save'}
              onClick={handleSubmit(onSubmit)}
              htmlType="submit"
              type={'main'}
              style={{
                width: '10%',
                padding: 0,
                marginRight: 0,
                marginLeft: '.5em',
                marginTop: '2.3em',
              }}
            />
          </div>
        </NavPageWrap >
      </Wraper>
    }
    </>
  );
};

export default UploadGuidePage;