import styled from 'styled-components'

export const Wrap = styled('div')`
  .ant-pagination {
    display: flex;
    align-items: center;
  }
  .ant-pagination-item {
    border-color: #dadada !important;
    margin: 0 0.1em !important;
    border-radius: 4px;
    font-family: var(--regular);
    font-size: 0.714em;
    height: 15px !important;
    width: 16px !important;
    min-width: 16px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    &-active {
      a {
        font-family: var(--bold);
        color: var(--orange);
      }
    }
    a {
      height: 15px;
      line-height: 15px;
      &:hover {
        color: var(--orange);
      }
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    display: ${(props) => (props.arrows ? 'block' : 'none')} !important;
    margin: 0;
  }
  .ant-pagination-item-link {
    margin: 0;
    border: 0;
  }
`
