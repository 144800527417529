import styled from 'styled-components';

export const Title = styled('div')`
  color: var(--text);
  font-size: 24px;
  font-family: var(--medium);
  span {
    color: var(--text);
    font-size: 24px;
    font-family: var(--bold);
  }
`;

export const Text = styled('div')`
  color: var(--text);
  font-size: 16px;
  font-family: var(--regular);
  span {
    font-family: var(--bold);
    color: var(--text);
    font-size: 16px;
  }
  div {
    font-family: var(--regular);
    font-size: 16px;
    color: var(--blue);
    cursor: pointer;
    &:hover {
      text-decoration: underline;
  }
`
export const GoBackButton = styled('div')`
  color: var(--blue);
  font-size: 16px;
  font-family: var(--regular);
  cursor: pointer;
`
export const ModalWrapper = styled('div')`
  height: ${props => props.multipleUsers ? '200px' : 'auto'};
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    position: absolute;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: var(--grey);
    border-radius: 5px;
    width: 8px;
  }
`