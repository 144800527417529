export const topInputs = [
  {
    key: 0,
    name: 'reference_number',
    label: 'Reference Number/Name',
    type: 'text',
    validation: { required: true },
  },
  {
    key: 1,
    name: 'jurisdiction',
    label: 'Jurisdiction',
    type: 'select',
    placeholder: 'Select Jurisdiction',
    options: 'jurisdictions',
    validation: { required: true },
    addNew: true,
    showSearch: true,
    showDeprecated: true,
  },
  {
    key: 2,
    name: 'topics',
    label: 'Topic',
    type: 'select',
    placeholder: 'Select Topic',
    options: 'topics',
    validation: { required: true },
    addNew: true,
    allowClear: true,
    showDeprecated: true,
  },
  {
    key: 3,
    name: 'line_of_business',
    label: 'Line of Business',
    type: 'multiSelect',
    options: 'line_of_business',
    validation: { validate: (value) => value?.length > 0, required: true },
    addNew: true,
    allowClear: true,
    showDeprecated: true,
  },
  {
    key: 4,
    name: 'sub_topics',
    label: 'Sub-Topic',
    type: 'multiSelect',
    placeholder: 'Select Sub-Topic',
    options: 'sub_topics',
    addNew: false,
    allowClear: true,
    showDeprecated: true,
  },
  {
    key: 5,
    name: 'adopted_date',
    label: 'Adopted Date',
    type: 'date',
    validation: { required: true },
    placeholder: '00/00/00',
    noLimit: true,
  },
  {
    key: 6,
    name: 'effective_date',
    label: 'Effective Date',
    type: 'date',
    placeholder: '00/00/00',
    noLimit: true,
  },
  {
    key: 7,
    name: 'compliance_date',
    label: 'Compliance Date',
    type: 'date',
    placeholder: '00/00/00',
    noLimit: true,
  },
  {
    key: 8,
    name: 'end_date',
    label: 'End Date',
    type: 'date',
    placeholder: '00/00/00',
    noLimit: true,
  },
]

export const topReportInputs = [
  {
    key: 0,
    name: 'report_title',
    label: 'Report Title',
    type: 'text',
    validation: { required: true },
  },
  {
    key: 0,
    name: 'reporting_time_period',
    label: 'Report Period',
    validation: { required: true },
    type: 'text',
  },
  {
    key: 1,
    name: 'report_source_jurisdictions',
    label: 'Jurisdiction',
    type: 'select',
    placeholder: 'Select Jurisdiction',
    options: 'report_source_jurisdictions',
    validation: { required: true },
    addNew: true,
    showSearch: true,
    showDeprecated: true,
  },
  {
    key: 2,
    name: 'report_types',
    label: 'Report-Type',
    type: 'select',
    options: 'report_types',
    validation: { required: true },
    addNew: true,
    allowClear: true,
  },
  {
    key: 3,
    name: 'reporting_entities',
    label: 'Report Entity',
    type: 'multiSelect',
    options: 'report_entities',
    addNew: true,
    allowClear: true,
    showDeprecated: true,
    validation: { required: true },
  },

  {
    key: 4,
    name: 'report_to',
    label: 'Report To',
    validation: { required: true },
    type: 'text',
  },
  {
    key: 5,
    name: 'report_due_date',
    label: 'Report Due Date',
    type: 'date',
    validation: { required: true },
    placeholder: '00/00/00',
    noLimit: true,
  },
  {
    key: 6,
    name: 'ties_to_license',
    label: 'Ties To License',
    type: 'select',
    options: 'report_source_ties_to_license',
    allowClear: true,
    showDeprecated: true,
  },
]
