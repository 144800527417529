
import moment from "moment";
import {
  Wrap,
  NotificationTitle,
  Text,
  TimeStamp,
  MenuItem
} from "./style";
import { EllipsisOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import NotificationsOff from '../../../assets/images/notifications_off.svg'
import DeleteBin from '../../../assets/images/delete_bin.svg'
import { api } from "../../../api";
import { message } from "antd";



const AnnouncementItem = ({ title, text, created }) => {

  const getTimeAgoString = (date_stamp) => {
    const currentDate = moment();
    const inputDate = moment(date_stamp, 'YYYY-MM-DD HH:mm:ss');

    const hoursDifference = currentDate.diff(inputDate, 'hours');
    const daysDifference = currentDate.diff(inputDate, 'days');
    const monthsDifference = currentDate.diff(inputDate, 'months');
    const yearsDifference = currentDate.diff(inputDate, 'years');

    if (yearsDifference === 1) {
      return '1 Year Ago';
    } else if (yearsDifference >= 2) {
      return `${yearsDifference} Years Ago`;
    } else if (monthsDifference === 1) {
      return '1 Month Ago';
    } else if (monthsDifference >= 2) {
      return `${monthsDifference} Months Ago`;
    } else if (daysDifference === 1) {
      return '1 Day Ago';
    } else if (daysDifference >= 2) {
      return `${daysDifference} Days Ago`;
    } else if (hoursDifference === 1) {
      return '1 Hour Ago';
    } else {
      return `${hoursDifference} Hours Ago`;
    }
  };

  function cutHTMLText(htmlText) {
    const maxLength = 90;
    if (htmlText === null) return ''
    if (htmlText.length <= maxLength) return htmlText;


    // Strip HTML tags using a temporary element
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlText;
    let plainText = tempElement.textContent || tempElement.innerText || '';

    // Trim the text to the maximum length
    const trimmedText = plainText.trim().slice(0, maxLength);

    // Re-attach HTML tags
    tempElement.innerHTML = trimmedText;

    // Get the updated HTML content
    const truncatedHTML = tempElement.innerHTML;

    return truncatedHTML + '... ';
  }

  return (
    <Wrap style={{borderBottom: '3px solid var(--text)'}}>
      <div>
        <NotificationTitle dangerouslySetInnerHTML={{
          __html: title,
        }} />
        <Text dangerouslySetInnerHTML={{
          __html: text,
        }} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <TimeStamp>
          {getTimeAgoString(created)}
        </TimeStamp>
      </div>
    </Wrap>
  );
};

export default AnnouncementItem;