import styled from "styled-components";
import { Table } from "antd";

export const StyledTable = styled(Table)`
  .ant-table {
    border: 1px solid #f0f0f0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: 12px;
    font-family: var(--medium);
    color: #515151;
    max-height: ${(props) => props.compact ? '200px' : '470px'};
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 7px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #f0f0f0;
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: #F2F5FF;
    }
    @media (max-height: 970px) {
      max-height: 400px;
    }

    @media (max-height: 840px) {
      max-height: 350px;
    }

    @media (max-height: 800px) {
      max-height: 200px;
    }
  }
`;
