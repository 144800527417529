import styled from 'styled-components'

export const TeamMember = styled("div")`
  width: 100%;
  font-family: var(--medium);
  font-size: 0.75em;
  color: var(--text);
  padding: 1em 1em 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  img {
    margin-left: auto;
    width: 10px;
    cursor: pointer;
  }
`;
