import { useState, useEffect } from 'react'
import { NawPageWrap, Row, SwitchTitle, SwitchText } from '../CreateCompany/pages/ManageProducts/style'
import { SubTitle, CompanyDescription, StyledSwitch } from '../CreateCompany/pages/FirstPage/style'
import { Text, TextContainer, PasswordButton } from './style'
import { useParams } from 'react-router-dom/'
import { api } from '../../../api'
import { useForm } from 'react-hook-form'
import Input from "../../../components/common/Input";
import FullScreenLoader from '../../../components/common/FullScreenLoader'
import Tabs from '../../../components/common/Tabs/tabs'
import ComplianceTab from './Tabs/Compliance'
import ProposedTab from './Tabs/Proposed'
import ResourceTab from './Tabs/Resource'
import ReportTab from './Tabs/Report'
import { Divider, message, Select } from 'antd'
import { useStore } from '../../../mobx-store/context'
import ButtonOrange from '../../../components/common/ButtonOrange'
import Button from "../../../components/common/Button/button";
import { PrevBtn } from "../CreateCompany/pages/FirstPage/Form/style";
import RemoveUserModal from './RemoveuserModal';
import { useHistory } from 'react-router-dom'
import Wraper from '../CMSContainer'
import EmailModal from './EmailModal'
import TempPassModal from './PassModals/tempPassModal'
import ManualPassModal from './PassModals/manualPassModal'

const EditCMSUser = () => {
  const params = useParams()
  const [loading, setLoading] = useState(true)
  const [userData, setUserData] = useState(null)
  const [visible, setVisible] = useState(false)
  const [tempPassModalVisible, setTempPassModalVisible] = useState(false)
  const [manualPssModalVisible, setManualPssModalVisible] = useState(false)

  // const [active, setActive] = useState('')
  const { handleSubmit, errors, control } = useForm()
  const store = useStore()
  const [activeTab, setActiveTab] = useState('compliance');
  const [confirmed, setConfirmed] = useState(false)
  const [aceptedTC, setAceptedTC] = useState(true)
  const [emailModalVisible, setEmailModalVisible] = useState(false)
  const history = useHistory()
  const getUserData = (id) => {
    api(`/cms/users/${id}/`, {}, 'GET').then((data) => {
      store.setSingleValue('editedCmsUser', data)
      setUserData(data)
      getCompanyInfo(data.company)
    })
  }

  const newUser = localStorage.getItem('newCompanyUser') === 'true'

  const getCompanyInfo = (id) => {
    api(`/cms/companies/${id}/`, {}, 'GET').then((data) => {
      store.setSingleValue('cmsCompany', [data])
      const active = data.have_access_to_tracking ?
        'compliance'
        : data.have_access_to_proposed_source
          ? 'proposed'
          : data.have_access_to_resource
            ? 'resource'
            : 'report'
      setActiveTab(active)
      setConfirmed(data.is_confirmed)
      setAceptedTC(data.is_accepted_terms_and_conditions)
      setLoading(false)
    })
  }

  const getCmsOptions = (id) => {
    setLoading(true)
    api(`/cms/dictionaries_data/company_data/?company=${localStorage.getItem('editedCmsCompanyId')}`, {}, 'GET').then((data) => {
      if (!data.errors) {
        store.setSingleValue('cms_company_options', data)
      }
    }).then(() => {
      getUserData(id)
    })
  }

  useEffect(() => {
    getCmsOptions(params.id)
  }, [])

  const applicationsMap = [
    {
      name: 'ComplianceSource',
      value: 'compliance_source'
    },
    {
      name: 'ProposedSource',
      value: 'proposed_source'
    },
    {
      name: 'ReportSource',
      value: 'report_source'
    },
    {
      name: 'Resource',
      value: 'resource'
    },
    {
      name: 'Not Selected',
      value: null
    }
  ]

  const showTabs = (obj) => {
    return {
      compliance: {
        render: () => <ComplianceTab handleTabChange={obj.handleTabChange} loading={obj.loading} active={obj.activeTab} companyId={obj.id} user={obj.user} />,
      },
      proposed: {
        render: () => <ProposedTab handleTabChange={obj.handleTabChange} active={obj.activeTab} companyId={obj.id} />,
      },
      resource: {
        render: () => <ResourceTab handleTabChange={obj.handleTabChange} active={obj.activeTab} companyId={obj.id} />,
      },
      report: {
        render: () => <ReportTab handleTabChange={obj.handleTabChange} active={obj.activeTab} companyId={obj.id} />,
      },

    }
  }

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const onSubmit = (values) => {
    const data = {
      ...values,
      is_confirmed: confirmed,
      // is_accepted_terms_and_conditions: aceptedTC,
    }
    api(`/cms/users/${store.editedCmsUser.id}/`, data, 'PATCH').then((res) => {
      if (!res.error) {
        store.setSingleValue('editedCmsUser', res)
        message.success('User updated successfully')
        newUser && setEmailModalVisible(true)
        newUser && localStorage.setItem('newCompanyUser', false)
      } else {
        message.error(res.error)
      }
    })
  }

  // useEffect(() => {
  //   getCmsOptions(params.id)
  // }, [])

  const onDeleteUser = () => {
    api(`/cms/users/${store.editedCmsUser.id}/`, {}, 'DELETE').then((res) => {
      if (!res.error) {
        store.setSingleValue('editedCmsUser', res)
        message.success('User deleted successfully')
        setVisible(false)
        history.goBack()
      } else {
        message.error(res.error)
      }

    })
  }

  const resetTempPassword = (id) => {
    api(`/cms/users/${id}/generate_random_password/`, {}, 'POST').then((res) => {
      if (!res.error) {
        message.success('New password generated successfully')
      } else {
        message.error(res.error)
      }
    }).then(() => {
      setTempPassModalVisible(false)
    })
  }

  return (
    <>
      {loading
        ? <FullScreenLoader />
        : <Wraper>
          <NawPageWrap style={{ width: 910 }}>
            {visible && <RemoveUserModal
              onSubmit={() => onDeleteUser()}
              onClose={() => setVisible(false)}
              userName={store.editedCmsUser.username}
            />}
            {emailModalVisible && <EmailModal
              onClose={() => setEmailModalVisible(false)}
            />}
            {tempPassModalVisible && <TempPassModal
              openManualModal={() => {
                setTempPassModalVisible(false)
                setManualPssModalVisible(true)
              }}
              onSubmit={() => { resetTempPassword(store.editedCmsUser.id) }}
              onClose={() => setTempPassModalVisible(false)}
            />}
            {manualPssModalVisible &&
              <ManualPassModal
                id={store.editedCmsUser.id}
                onClose={() => setManualPssModalVisible(false)}
              />}
            {<SubTitle style={{ fontSize: 24, marginTop: 30 }}>
              User: {store.editedCmsUser.first_name || 'New'} {store.editedCmsUser.last_name || 'User'}
            </SubTitle>}
            <CompanyDescription style={{ marginBottom: 10 }}> Edit the users details below</CompanyDescription>
            <ButtonOrange
              text={'< Go Back'}
              onClick={() => history.goBack()}
              style={{ fontFamily: 'var(--bold)', color: '#4E74A9', fontSize: 12, textDecoration: 'none', marginBottom: 10 }}
            />
            <form style={{ width: 710 }}>
              <Row>
                <Input
                  smallLabel
                  name="user_name"
                  label="User Name"
                  defaultValue={store.editedCmsUser.username}
                  control={control}
                  rules={{ required: true }}
                  errors={errors}
                />
                <Input
                  smallLabel
                  name="email"
                  label="Email"
                  defaultValue={store.editedCmsUser.email}
                  control={control}
                  rules={{ required: true }}
                  errors={errors}
                />
              </Row>
              <Row>
                <Input
                  smallLabel
                  name="first_name"
                  label="First Name"
                  defaultValue={store.editedCmsUser.first_name}
                  control={control}
                  rules={{ required: true }}
                  errors={errors}
                />
                <Input
                  smallLabel
                  name="last_name"
                  label="Last Name"
                  defaultValue={store.editedCmsUser.last_name}
                  control={control}
                  rules={{ required: true }}
                  errors={errors}
                />
              </Row>
              <Row >
                <Input
                  smallLabel
                  name={'phone_number'}
                  label={'Phone Number'}
                  type={'phone'}
                  transparent
                  control={control}
                  defaultValue={store.editedCmsUser.phone_number}
                />
                <PasswordButton onClick={() => setTempPassModalVisible(true)} >
                  Generate new password
                </PasswordButton>
              </Row>
              <Row>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 25, marginBottom: 20, }}>
                  <StyledSwitch
                    defaultChecked={store.editedCmsUser.is_confirmed}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) => { setConfirmed(checked) }}
                  />
                  <div style={{ marginLeft: 10 }} >
                    <SwitchTitle>Active and Confirmed</SwitchTitle>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 25, marginBottom: 20, }}>
                  {/* <StyledSwitch
                    defaultChecked={true}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    disabled={true}
                    onChange={(checked) => { }}
                  /> */}
                  <div style={{ marginLeft: 10, marginRight: 5 }} >
                    <SwitchTitle>Accepted Terms & Conditions:</SwitchTitle>
                  </div>
                  <SwitchTitle>{store.editedCmsUser.is_accepted_terms_and_conditions ? 'YES' : 'NO'}</SwitchTitle>
                </div>
              </Row>
              <Row>
                <div>
                  <SwitchTitle style={{ marginBottom: 3, fontFamily: 'var(--medium)' }}>Company</SwitchTitle>
                  <TextContainer style={{ width: 340 }}>
                    <Text>{store.editedCmsUser.company_name}</Text>
                  </TextContainer>
                </div>
                {/* <UnderlinedButton>
                Select New Company
              </UnderlinedButton> */}
              </Row>
              <Row>
                <Input
                  smallLabel
                  type={'select'}
                  customOptions={
                    applicationsMap.map((item) => (
                      <Select.Option value={item.value}>
                        {item.name}
                      </Select.Option>
                    ))
                  }
                  name="memorized_tool"
                  label="Default Application on Sign In"
                  defaultValue={store.editedCmsUser.memorized_tool}
                  control={control}
                  rules={{ required: true }}
                  errors={errors}
                  disabled={false}
                />
              </Row>
            </form>
            <Divider style={{ background: '#CCC' }} />
            <SubTitle >Manage:</SubTitle>
            <>
              <Tabs
                Reporting={false}
                tabs={[
                  {
                    key: 'compliance',
                    title: 'ComplianceSource',
                    disabled: !store?.cmsCompany[0]?.is_registration_passed
                  },
                  {
                    key: 'proposed',
                    title: 'ProposedSource',
                    disabled: !store?.cmsCompany[0]?.have_access_to_proposed_source
                  },
                  {
                    key: 'resource',
                    title: 'ReSource',
                    disabled: !store?.cmsCompany[0]?.have_access_to_resource
                  },
                  {
                    key: 'report',
                    title: 'ReportSource',
                    disabled: !store?.cmsCompany[0]?.have_access_to_report_source
                  },


                ]}
                onClick={(key) => {
                  setActiveTab(key)
                }}
                active={activeTab}
              />

              {showTabs({
                handleTabChange,
                loading,
                activeTab,
                id: params.id,
                user: userData
              })[activeTab].render()
              }
            </>
            <Divider style={{ background: '#CCC' }} />
            <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
              <PrevBtn style={{ color: 'var(--red)' }} onClick={() => { setVisible(true) }}>Remove this User</PrevBtn>
              <Button
                onClick={handleSubmit(onSubmit)}
                text={'Save'}
                type={'main'}
                style={{
                  width: '10%',
                  padding: 0,
                  marginRight: 0,
                  marginLeft: '.5em',
                  marginTop: '2.3em',
                }}
              />
            </Row>
          </NawPageWrap>
        </Wraper>
      }
    </>
  )
}

export default EditCMSUser