import styled from 'styled-components'
import { TableWrap as Table } from '../components/style'
import { Link } from 'react-router-dom'
export const TableWrap = styled(Table)`
  tbody {
    .ant-table-cell {
      padding-left: 20px !important;
    }
  }
  .ant-table-thead th.ant-table-column-has-sorters {
    padding: 0 6px !important;
  }
  tbody {
    tr {
      .ant-table-cell {
        &:last-child {
          border-right: 1px solid #c4cacc;
        }
      }
    }
  }
  .ant-table-thead {
    tr {
      th {
        background-color: #fff !important;
         position: sticky;
         top: 93px; 
        z-index: 99;
      }
    }
  }
  tbody {
    tr {
      .ant-table-cell {
        &:first-child {
          border-left: 1px solid #c4cacc;
        }
      }
    }
  }
`
export const SubTitle = styled(Table)`
  font-size: 16px;
  font-family: var(--medium);
  color: var(--text);
  margin-bottom: 10px;
`
export const Button = styled(Link)`
  font-family: var(--regular);
  font-size: 12px;
  color: #4b75b1;
  cursor: pointer;
  text-decoration: underline;
  padding-right: 20px;
`
export const RightWrap = styled('div')`
  padding-right:${(props) => (+props.numberOfColumns <= 4 ? '0px' : '50px')} ;
  padding-right: 2em;
  padding-bottom: 1em;
  max-height: 74vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    position: absolute;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: var(--grey);
    border-radius: 5px;
    width: 8px;
  }

  // @media (max-width: 1350px) {
  //   padding-left: 0;
  //   padding-top: 2em;
  //   padding-right: 2em;
  // }
  @media (max-height: 1200px) {
    max-height: 76vh;
  }
  @media (max-height: 1050px) {
    max-height: 73vh;
  }
  @media (max-height: 915px) {
    max-height: 66vh;
  }
  @media (max-height: 880px) {
    max-height: 69vh;
  }
  @media (max-height: 800px) {
    max-height: 65vh;
  }
  @media (max-height: 750px) {
    max-height: 65vh;
  }
  
`
export const Wrap = styled('div')`
padding-right: 2em;
max-height: 74vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    position: absolute;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: var(--grey);
    border-radius: 5px;
    width: 8px;
  }

  // @media (max-width: 1350px) {
  //   padding-left: 0;
  //   padding-top: 2em;
  //   padding-right: 2em;
  @media (max-height: 1200px) {
    max-height: 76vh;
  }
  @media (max-height: 1050px) {
    max-height: 73vh;
  }
  @media (max-height: 915px) {
    max-height: 66vh;
  }
  @media (max-height: 880px) {
    max-height: 69vh;
  }
  @media (max-height: 800px) {
    max-height: 65vh;
  }
  @media (max-height: 750px) {
    max-height: 65vh;
  }
`