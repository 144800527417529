import styled from 'styled-components'

export const Wrap = styled('form')`
  width: 445px;
  height: 56px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding-right: 6px;
  overflow: hidden;
  border: 1px solid ${(p) => (p.border ? '#969FA2' : '#fff')};
  input {
    width: 100%;
    height: 100%;
    padding: 0 15px;
    border: 0;
    outline: none;
    font-family: var(--regular);
    color: var(--text);
  }
  @media (max-width: 550px) {
    width: 100%;
  }
`
