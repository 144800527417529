import React, { useEffect } from 'react'
import Cookies from 'universal-cookie'
import { useHistory } from 'react-router-dom'

const cookies = new Cookies()

const HideMeAnalytics = () => {
  const history = useHistory()

  useEffect(() => {
    cookies.set('hideMe', true)
    history.push('/home')
  }, [])

  return <div style={{ marginTop: 200 }}>redirecting...</div>
}

export default HideMeAnalytics
