import React from 'react'
import Button from '../../../../components/common/Button/button'
import Modal from '../../../../components/common/ModalNew'
import { Description, Title } from './style'

const OfflinePaymentModal = ({ onClose, handleFinish, unregister }) => {
  const unregisterFields = () => {
    unregister('card_number')
    unregister('card_holder_name')
    unregister('month')
    unregister('year')
    unregister('cvv')
  }

  const onFinish = () => {
    unregisterFields()

    handleFinish()
  }

  return (
    <Modal onClose={onClose}>
      <Title>Call To Complete Billing by ACH/Check</Title>
      <Description>Phone: 888-684-1391</Description>
      <Description>Email: contact@pbmsource.com</Description>
      <Description style={{ marginTop: 15 }}>
        We have saved your preferences and you’ll gain access as soon as we
        process your payment.
      </Description>
      <Button
        text='Finish'
        style={{ marginLeft: 'auto', marginTop: 30 }}
        onClick={onFinish}
      />
    </Modal>
  )
}

export default OfflinePaymentModal
