import Wraper from "../CMSContainer";
import UsersTab from "../EditCompany/Tabs/Users";

const DashboardAllUsers = () => {
  return (
    <Wraper>
      <UsersTab allUsersPage={'all'} />
    </Wraper>
  );
};

export default DashboardAllUsers;