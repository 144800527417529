import styled from 'styled-components'

export const Wrap = styled('div')`
  display: ${(props) => (props.fullWidth ? 'block' : 'grid')};
  grid-template-columns: 1fr 1fr;
  padding: 0 2.313em 0 2.313em;
  // padding-bottom: 3em;
  @media (max-width: 1350px) {
    display: block;
  }
`

export const Top = styled('div')`
  padding: 0 2.313em;
  width: ${(props) => (props.fullWidth ? '100%' : 'calc(50vw - 2.313em)')};
`
export const Back = styled('div')`
  font-family: var(--medium);
  font-size: 14px;
  color: #4B75B1;
  text-decoration: underline;
  cursor: pointer;
  width: 50px;
`;