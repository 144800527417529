import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import TopLine from '../../../components/TopLine/topLine'
import { useStore } from '../../../mobx-store/context'
import Header from '../components/Header'
import { Layout } from '../components/style'
import { Container, Wrap } from '../Resource/style'
import Left from './left'
import Right from './right'

const ResourceDetails = observer(() => {
  const store = useStore()
  const params = useParams()
  const [active, setActive] = useState(null)
  const location = useLocation()
  const history = useHistory()

  const isGuides = params.type === 'guides'

  const findActive = (item) =>
    isGuides ? item.id === +params.itemId : +params.selectId

  useEffect(() => {
    if (!store.jurs?.length) store.loadSelect('companyOptions')
    if (!store.resourceFiltered)
      store.getJurisdiction(+params.selectId, null, (data) => {
        if (!data.length) history.push('/resource/table/guides')
      })
    if (!store.resourceFilters) store.getResourceFilters()
    if (!store.resources) store.getResourceTopic()
  }, [active])

  const findOption = () => store.resourcesCurrentTopic?.rows?.find(findActive)

  const setOption = () => {
    const option = findOption()

    setActive(option)
  }

  useEffect(() => {
    if (store.resourceFiltered || store.resourcesCurrentTopic) setOption()

    if (!store.resourcesCurrentTopic)
      isGuides
        ? store.getResourceTopic(+params.selectId)
        : store.setSingleValue(
            'resourcesCurrentTopic',
            store.resourceFiltered?.find((item) => item.id === +params.itemId)
          )
  }, [store.resourceFiltered, store.resourcesCurrentTopic, location])

  return (
    <>
      <TopLine />
      <Wrap>
        <Container>
          <Header goBack />
          <Layout>
            <Left
              params={params}
              store={store}
              active={active}
              setActive={(item) => {
                setActive(item)
              }}
            />
            {store.resourceFilters && active && (
              <Right store={store} params={params} currentItem={active} />
            )}
          </Layout>
        </Container>
      </Wrap>
    </>
  )
})

export default ResourceDetails
