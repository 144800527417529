import React, { useState, useMemo } from 'react'
import moment from 'moment'
import useDidMountEffect from '../../../hooks/useEffectNotOnPageLoad'
import Overlay from '../Overlay'
import { DatePicker } from 'antd'
import './styles.css'

const { RangePicker } = DatePicker;

const DateRange = ({
  visible,
  onChange,
  onClose,
  onBlur,
  noLimit,
  style,
  value,
}) => {
  const [date, setDate] = useState({ start: null, end: null })

  const localValue = useMemo(() => {
    if (value) {
      return [moment(value?.split('±')[0]), moment(value?.split('±')[1])] 
    }
    return null
  }, [value])


  useDidMountEffect(() => {
    if (new Date(value?.split('±')[1]) < new Date(value?.split('±')[0])) {
      onChange({ start: date.end, end: date.start })
    }
  }, [value])

  return (
    <>
      {visible && <Overlay style={{ zIndex: 100000 }} onClick={onClose} />}
      <RangePicker
        format='MM/DD/YYYY'
        disabledDate={d => !d || d.isAfter("2030-12-31") || d.isSameOrBefore("2022-01-01") }
        value={localValue}
        open={visible}
        dropdownClassName = {'dateRangePicker'}
        onChange={(date, dateString) => {
          const newDate = { start: dateString[0], end: dateString[1] }
          setDate(newDate)
          onChange(newDate)
          onClose()
          onBlur && onBlur()
        }}
        style={{
          visibility: 'hidden',
          height: 0,
          padding: 0,
          width: 0,
          position: 'absolute',
        }}
      />
    </>
  )
}

export default DateRange
