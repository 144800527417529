import React from 'react'
import InputMask from 'react-input-mask'

const InputPhone = ({ value, onChange, disabled }) => {
  return <InputMask
    mask="+1 (999) 999-9999"
    value={value}
    onChange={onChange}
    maskChar={null}
    placeholder={'+1 (999) 999-9999'}
    disabled={disabled}
  />
}

export default InputPhone
