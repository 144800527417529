
import styled from 'styled-components'

export const BannerContainer = styled('div')`
display:flex;
flex-direction: column;
justify-content: center;
align-items: flex-end;
`

export const Title = styled('div')`
font-family: var(--regular);
font-size: 24px;
color: #4E74A9;
margin-top: 5.75em;
`
export const Text = styled('div')`
font-family: var(--regular);
font-size: 14px;
line-height: 22px;
`