export const data = {
  tracking: {
    inputName: 'member_type',
  },
  resource: {
    inputName: 'resource_role',
  },
  reportSource: {
    inputName: 'report_source_role',
  },
  proposedSource: {
    inputName: 'proposed_source_role',
  },
  licenseSource: {
    inputName: 'license_source_role',
  },
}

export const rolesCompliance = [
  {
    key: 'admin',
    value: 'admin',
    name: 'ComplianceSource Admin',
    roles_that_can_change_current_role: ['admin'],
  },
  {
    key: 'member',
    value: 'member',
    name: 'ComplianceSource Team Member',
    roles_that_can_change_current_role: ['compliance_owner', 'admin'],
  },
  {
    key: 'compliance_owner',
    value: 'compliance_owner',
    name: 'ComplianceSource Owner',
    roles_that_can_change_current_role: ['compliance_owner', 'admin'],
  },
]

export const rolesComplianceLite = [
  {
    key: 'admin',
    value: 'admin',
    name: 'ComplianceSource Admin',
    roles_that_can_change_current_role: ['admin'],
  },
  {
    key: 'member',
    value: 'member',
    name: 'ComplianceSource Team Member',
    roles_that_can_change_current_role: ['compliance_owner', 'admin'],
  },
]

export const rolesResource = [
  {
    key: 'admin',
    value: 'admin',
    name: 'ReSource Admin',
    roles_that_can_change_current_role: ['admin'],
  },
  {
    key: 'member',
    value: 'member',
    name: 'ReSource Team Member',
    roles_that_can_change_current_role: ['compliance_owner', 'admin'],
  },
]

export const rolesReportSource = [
  {
    key: 'admin',
    value: 'admin',
    name: 'ReportSource Admin',
    roles_that_can_change_current_role: ['admin'],
  },
  {
    key: 'member',
    value: 'member',
    name: 'ReportSource Team Member',
    roles_that_can_change_current_role: ['report_owner', 'admin'],
  },
  {
    key: 'report_owner',
    value: 'report_owner',
    name: 'ReportSource Owner',
    roles_that_can_change_current_role: ['report_owner', 'admin'],
  },
]

export const rolesLicenseSource = [
  {
    key: 'admin',
    value: 'admin',
    name: 'LicenseSource Admin',
    roles_that_can_change_current_role: ['admin'],
  },
  {
    key: 'member',
    value: 'member',
    name: 'LicenseSource Team Member',
    roles_that_can_change_current_role: ['license_owner', 'admin'],
  },
  {
    key: 'license_owner',
    value: 'license_owner',
    name: 'LicenseSource Owner',
    roles_that_can_change_current_role: ['license_owner', 'admin'],
  },
]

export const rolesReportSourceLite = [
  {
    key: 'admin',
    value: 'admin',
    name: 'ReportSource Admin',
    roles_that_can_change_current_role: ['admin'],
  },
  {
    key: 'member',
    value: 'member',
    name: 'ReportSource Team Member',
    roles_that_can_change_current_role: ['compliance_owner', 'admin'],
  },
]

export const rolesProposedSource = [
  {
    key: 'noRoles',
    value: 'noRoles',
    name: 'No Roles',
    roles_that_can_change_current_role: [],
  }
]

export const blocksTracking = [
  {
    key: 10,
    name: 'topics',
    type: 'topicsMembers',
    title: 'Assigned Topics',
  },
  {
    key: 11,
    name: 'jurisdictions',
    type: 'jursMembers',
    title: 'Assigned Jurisdictions',
  },
  {
    key: 12,
    name: 'line_of_business',
    type: 'lineOfBusinessesMembers',
    title: 'Assigned Lines of Business',
  },
]

export const ultraLiteblocksTracking = [
  {
    key: 8,
    name: 'jurisdictions',
    type: 'jursMembers',
    title: 'Assigned Jurisdictions',
  },
  {
    key: 9,
    name: 'line_of_business',
    type: 'lineOfBusinessesMembers',
    title: 'Assigned Lines of Business',
  },
]

export const blocksReportSource = [
  {
    key: 0,
    name: 'report_source_report_types',
    type: 'report_source_report_types',
    title: 'Assigned Report Type',
  },
  {
    key: 1,
    name: 'report_source_ties_to_license',
    type: 'reportTiesToLicense',
    title: 'Licenses',
  },
  {
    key: 2,
    name: 'report_source_jurisdictions',
    type: 'jursMembers',
    title: 'Assigned Jurisdictions',
  },
  {
    key: 3,
    name: 'report_source_apcd_jurisdictions',
    type: 'jursMembers',
    title: 'Assigned APCD Jurisdictions',
  },
]
export const blocksLicenseSource = [
  {
    key: 0,
    name: 'license_source_license_types',
    type: 'license_pending_types',
    title: 'Assigned License Types',
  },
  {
    key: 1,
    name: 'license_source_line_of_business',
    type: 'license_source_pending_line_of_business',
    title: 'Assigned Lines of Business',
  },
  {
    key: 2,
    name: 'license_source_jurisdictions',
    type: 'license_source_pending_jurisdictions',
    title: 'Assigned Jurisdictions',
  },
]

export const blocksResource = [
  {
    key: 4,
    name: 'resource_guides',
    type: 'resources',
    title: 'Assigned Guides',
    findItemVar: 'guide_name_id',
  },
  {
    key: 5,
    name: 'resource_line_of_business',
    type: 'resource_line_of_business',
    title: 'Assigned Lines of Business',
  },
  {
    key: 6,
    name: 'resource_jurisdictions',
    type: 'selectedJurisdictions',
    title: 'Assigned Jurisdictions',
  },
]

export const blocksProposedSource = [
  {
    key: 7,
    name: 'proposed_source_jurisdictions',
    type: 'jursMembers',
    title: 'Assigned Jurisdictions',
  },
]

export const varTypes = {
  tracking: {
    variable: 'have_access_to_tracking',
  },
  resource: {
    variable: 'have_access_to_resource',
  },
  reportSource: {
    variable: 'have_access_to_report_source',
  },
  proposedSource: {
    variable: 'have_access_to_proposed_source',
  },
  licenseSource: {
    variable: 'have_access_to_license_source',
  }
}
