import { AddPageWrap } from "../../../DashboardLanding/style";
import { Title } from "./style";
import Form from "./Form";
const FirstPage = () => {

  return (
    <AddPageWrap style={{ 
      width: 660 
      }}>
      <Title>
        Add New Company - Company Details
      </Title>
      <Form />
    </AddPageWrap>
  );
};

export default FirstPage;