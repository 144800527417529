import { SubTitle, CompanyDescription } from '../CreateCompany/pages/FirstPage/style'
import { useState, useEffect } from 'react';
import { NawPageWrap, } from '../CreateCompany/pages/ManageProducts/style';
import DeactivationModal from './DeactivationModal';
import ComplianceTab from './Tabs/Compliance'
import ProposedTab from './Tabs/Proposed'
import ResourceTab from './Tabs/Resource'
import ReportTab from './Tabs/Report'
import UsersTab from './Tabs/Users';
import CompanyTab from './Tabs/CompanyTab';
import Tabs from '../../../components/common/Tabs/tabs'
import { useStore } from '../../../mobx-store/context';
import { api } from '../../../api';
import FullScreenLoader from '../../../components/common/FullScreenLoader';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Wraper from '../CMSContainer';
import PricingTab from './Tabs/PricingTab';
import { ActivationButton } from './style';
import { PrevBtn } from '../CreateCompany/pages/FirstPage/Form/style';
import ButtonOrange from '../../../components/common/ButtonOrange';
import { message, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import RemoveCompanyModal from './RemoveCompanyModal';

const EditingCompany = observer(() => {
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState("company");
  const [showDeactivationModal, setShowDeactivationModal] = useState(false)
  const [showRemoveCompanyModal, setShowRemoveCompanyModal] = useState(false)
  const store = useStore()
  const params = useParams()
  const history = useHistory()

  const getCmsOptions = (id) => {
    setLoading(true)
    api(`/cms/dictionaries_data/all/`, {}, 'GET').then((data) => {
      if (!data.errors) {
        store.setSingleValue('cms_options', data)
      }
    }).then(() => {
      getCompanyInfo(id)
    })
  }


  const getCompanyInfo = (id) => {
    api(`/cms/companies/${id}/`, {}, 'GET').then((data) => {
      store.setSingleValue('cmsCompany', [data])
      localStorage.setItem('editedCmsCompanyId', data.id)
      setLoading(false)
    })
  }

  const onChangeCompanyActiveStatus = (id, type) => {
    api(`/cms/companies/${id}/${type}/`, {}, 'PATCH').then((data) => {
      if (!data.errors) {
        message.success(`Company ${type === 'activate' ? 'activated' : 'deactivated'} successfully`)
        getCompanyInfo(id)
      }
    })
  }

  const onRemoveCompany = (id) => {
    api(`/cms/companies/${id}/`, {}, 'DELETE').then((data) => {
      if (!data.errors) {
        history.push('/dashboard-landing/')
        message.success('Company removed successfully')
      }
    })
  }


  useEffect(() => {
    getCmsOptions(params.id)
  }, [])

  const showTabs = (obj) => {
    return {
      company: {
        render: () => <CompanyTab handleTabChange={obj.handleTabChange} loading={obj.loading} active={obj.activeTab} companyId={obj.id} />,
      },
      compliance: {
        render: () => <ComplianceTab handleTabChange={obj.handleTabChange} loading={obj.loading} active={obj.activeTab} companyId={obj.id} />,
      },
      proposed: {
        render: () => <ProposedTab handleTabChange={obj.handleTabChange} active={obj.activeTab} companyId={obj.id} />,
      },
      resource: {
        render: () => <ResourceTab handleTabChange={obj.handleTabChange} active={obj.activeTab} companyId={obj.id} />,
      },
      report: {
        render: () => <ReportTab handleTabChange={obj.handleTabChange} active={obj.activeTab} companyId={obj.id} />,
      },
      users: {
        render: () => <UsersTab handleTabChange={obj.handleTabChange} active={obj.activeTab} companyId={obj.id} allUsersPage={'companyUsers'} />,
      },
      pricing: {
        render: () => <PricingTab handleTabChange={obj.handleTabChange} active={obj.activeTab} companyId={obj.id} />
      },
    }
  }
  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  return (
    <Wraper>
      {showDeactivationModal
        && <DeactivationModal
          onClose={() => setShowDeactivationModal(false)}
          onSubmit={() => {
            onChangeCompanyActiveStatus(params.id, store?.cmsCompany[0]?.is_inactive ? 'activate' : 'deactivate')
            setShowDeactivationModal(false)
          }}
          companyName={store?.cmsCompany[0]?.name}
          usersNumber={store?.cmsCompany[0]?.number_of_users || ''}
          isInactive={store?.cmsCompany[0]?.is_inactive}
        />}
      {showRemoveCompanyModal
        && <RemoveCompanyModal
          onClose={() => setShowRemoveCompanyModal(false)}
          onSubmit={() => {
            onRemoveCompany(params.id)
            setShowRemoveCompanyModal(false)
          }}
          companyName={store?.cmsCompany[0]?.name}
          usersNumber={store?.cmsCompany[0]?.number_of_users || 10}
          isInactive={store?.cmsCompany[0]?.is_inactive}
        />}
      <NawPageWrap style={{ width: 910 }}>
        {loading ? <FullScreenLoader />
          : <>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <SubTitle style={{ fontSize: 24 }}>Edit {store?.cmsCompany[0]?.name}</SubTitle>
              {store?.cmsCompany[0]?.is_testing_company &&
                <Tag
                  style={{ width: 'fit-content', fontFamily: 'var(--regular)', borderRadius: 10, fontSize: 9, marginBottom: 5, marginLeft: 5, }}
                  color="var(--green)">
                  Testing Company
                </Tag>
              }
              {
                store?.cmsCompany[0]?.trial_option === 'trial_is_active' && !store?.cmsCompany[0]?.is_inactive
                  ? <Tag
                    style={{ width: 'fit-content', fontFamily: 'var(--regular)', borderRadius: 10, fontSize: 9, marginBottom: 5, marginLeft: 5 }}
                    color="var(--orange)">
                    Active Trial
                  </Tag>
                  : store?.cmsCompany[0]?.trial_option === 'trial_is_over' && !store?.cmsCompany[0]?.is_inactive
                    ? <Tag
                      style={{ width: 'fit-content', fontFamily: 'var(--regular)', borderRadius: 10, fontSize: 9, marginBottom: 5, marginLeft: 5 }}
                      color="var(--grey)">
                      Trial is Over
                    </Tag>
                    : null
              }

              {
                store?.cmsCompany[0]?.is_inactive &&
                <Tag
                  style={{ width: 'fit-content', fontFamily: 'var(--regular)', borderRadius: 10, fontSize: 9, marginBottom: 5, marginLeft: 5 }}
                  color="var(--dark-grey)">
                  Inactive
                </Tag>
              }
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <CompanyDescription style={{ marginBottom: 10 }}>
                <div>Company Name - {store?.cmsCompany[0]?.name || 'N/A'}</div>
                <div>{store?.cmsCompany[0]?.address1}</div>
              </CompanyDescription>
              <PrevBtn style={{ color: 'var(--red)' }} onClick={() => setShowRemoveCompanyModal(true)}>Remove this company</PrevBtn>
            </div>
            <ButtonOrange
              text={'< Go Back'}
              onClick={() => history.goBack()}
              style={{ fontFamily: 'var(--bold)', color: '#4E74A9', fontSize: 12, textDecoration: 'none', marginBottom: 10 }}
            />
            <SubTitle>Manage:</SubTitle>
            <>
              <Tabs
                Reporting={false}
                tabs={[
                  {
                    key: 'company',
                    title: 'Company',
                  },
                  {
                    key: 'pricing',
                    title: 'Pricing',
                  },
                  {
                    key: 'users',
                    title: 'Users',
                  },
                  {
                    key: 'compliance',
                    title: 'ComplianceSource',
                  },
                  {
                    key: 'proposed',
                    title: 'ProposedSource',
                  },
                  {
                    key: 'resource',
                    title: 'ReSource',
                  },
                  {
                    key: 'report',
                    title: 'ReportSource',
                  },


                ]}
                onClick={(key) => {
                  setActiveTab(key)
                }}
                active={activeTab}
              />

              {showTabs({
                handleTabChange,
                loading,
                activeTab,
                id: params.id,
              })[activeTab].render()
              }
            </>
           {activeTab === 'company' && 
            <div style={{ position: 'relative', top: '-45px' }}>
            <ActivationButton
              activate={store?.cmsCompany[0]?.is_inactive}
              onClick={() => { setShowDeactivationModal(true) }}>
              {store?.cmsCompany[0]?.is_inactive ? 'Activate This Company' : 'Deactivate This Company'}
            </ActivationButton>
          </div>
           }
          </>
        }
      </NawPageWrap>
    </Wraper>
  );
});

export default EditingCompany;