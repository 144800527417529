import { useEffect, useState } from "react";
import { useStore } from "../../../../../../../mobx-store/context";
import { StyledSwitch } from "../../../FirstPage/style";
import { useHistory } from "react-router-dom";
import { SwitchTitle, SwitchText, Row, DetailsWrap, AllCheckboxContainer, TableTitle } from "../../style";
import { useForm } from "react-hook-form";
import Input from "../../../../../../../components/common/Input";
import { Divider, Checkbox, message } from "antd";
import Button from "../../../../../../../components/common/Button/button";
import { PrevBtn } from "../../../FirstPage/Form/style";
import CheckboxGroup from '../../CheckboxGroup'
import { api } from "../../../../../../../api";
import moment from 'moment'
import { observer } from "mobx-react-lite";
import EndDateConfirmModal from "../../../../../EditCompany/Tabs/Resource/GuideGroup/EndDateConfirmModal";

const ComplianceTab = observer(({ handleTabChange, active }) => {
  const store = useStore()
  const history = useHistory()
  const { handleSubmit, control, getValues, watch, errors, setValue } = useForm({
    defaultValues: {
      start_date: store?.cmsCompany[0]?.start_date,
      next_payment_date: store?.cmsCompany[0]?.next_payment_date,
      compliance_source_mode: store?.cmsCompany[0]?.compliance_source_mode,
      show_compliance_items_adopted_date: store?.cmsCompany[0]?.show_compliance_items_adopted_date,
    },
  })
  const watchValues = watch();
  const [hasAccesstoCS, setHasAccesstoCS] = useState(store?.cmsCompany[0]?.is_registration_passed)
  const [hasCustomPrice, setHasCustomPrice] = useState(store?.cmsCompany[0]?.has_compliance_custom_pricing)
  const [allJurisdictions, setAllJurisdictions] = useState(store?.cmsCompany[0]?.all_jurisdictions)
  const [allTopics, setAllTopics] = useState(store?.cmsCompany[0]?.all_topics)
  const [allLinesOfBusiness, setAllLinesOfBusiness] = useState(store?.cmsCompany[0]?.all_line_of_business)
  const [jursCount, setJursCount] = useState(store?.cmsCompany[0]?.jurisdictions?.length)
  const [topicsCount, setTopicsCount] = useState(store?.cmsCompany[0]?.topics?.length)
  const [linesOfBusinessCount, setLinesOfBusinessCount] = useState(store?.cmsCompany[0]?.line_of_business?.length)
  const [endDateModalVisible, setEndDateModalVisible] = useState(false)
  const [tempEndDate, setTempEndDate] = useState(null)

  const updateCompany = (values) => {
    const data = {
      ...values,
      start_date: values.start_date
        ? moment(values.start_date).format('YYYY-MM-DD') : null,
      next_payment_date: values.next_payment_date
        ? moment(values.next_payment_date).format('YYYY-MM-DD') : null,
      show_compliance_items_adopted_date: values.show_compliance_items_adopted_date
        ? moment(values.show_compliance_items_adopted_date).format('YYYY-MM-DD') : null,
      is_registration_passed: hasAccesstoCS,
      has_compliance_custom_pricing: hasCustomPrice,
      compliance_custom_price: hasCustomPrice ? values.compliance_custom_price.replace(/[$,a-zA-Z]/g, '') : null,
      all_jurisdictions: allJurisdictions,
      all_topics: allTopics,
      all_line_of_business: allLinesOfBusiness,
    }
    api(`/cms/companies/${localStorage.getItem('cmsCompanyId')}/`, data, 'PATCH').then((res) => {
      if (!res.errors)
        store.setSingleValue('cmsCompany', [res])
    })
  }

  const onSubmit = (values) => {
    updateCompany(values)
    handleTabChange('proposed')
  }

  useEffect(() => {
    setJursCount((getValues('jurisdictions') && getValues('jurisdictions').length) || 0)
    setTopicsCount((getValues('topics') && getValues('topics').length) || 0)
    setLinesOfBusinessCount((getValues('line_of_business') && getValues('line_of_business').length) || 0)
  }, [watchValues]);

  useEffect(() => {
    updateCompany(getValues())
  }, [watch('start_date'),
  watch('next_payment_date'),
  watch('compliance_source_mode'),
  watch('show_compliance_items_adopted_date'),
    hasAccesstoCS, hasCustomPrice, allJurisdictions, allTopics, allLinesOfBusiness,
  watch('jurisdictions'), watch('topics'), watch('line_of_business')
  ])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {endDateModalVisible && <EndDateConfirmModal onClose={() => setEndDateModalVisible(false)} setValue={() => setValue('next_payment_date', tempEndDate)} />}
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 25, marginBottom: 20, }}>
        <StyledSwitch
          defaultChecked={store?.cmsCompany[0]?.is_registration_passed}
          checkedChildren="Yes"
          unCheckedChildren="No"
          onChange={(checked) => { setHasAccesstoCS(checked) }}
        />
        <div style={{ marginLeft: 10 }} >
          <SwitchTitle>Has Access Granted to ComplianceSource:</SwitchTitle>
          <SwitchText>Company will have access to ComplianceSource starting this date, the date they can first log in.</SwitchText>
        </div>
      </div>
      <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Input
          smallLabel
          name={'start_date'}
          label={'Select Start Date'}
          type={'date'}
          control={control}
          transparent
          placeholder={'Select Start Date'}
          noLimit
          showCross={false}
        />
        <Input
          handleChange={(e) => {
            setTempEndDate(e)
            setEndDateModalVisible(true)
          }}
          smallLabel
          name={'next_payment_date'}
          label={'Select End Date'}
          handleReset={() => setValue('next_payment_date', null)}
          type={'date'}
          control={control}
          transparent
          placeholder={'Select End Date'}
          showCross={true}
          noLimit
        />
      </Row >
      <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Input
          smallLabel
          name={'compliance_source_mode'}
          label={'Select ComplianceSource Type'}
          type={'select'}
          options={[
            { id: 'compliance_source', name: 'ComplianceSource' },
            { id: 'compliance_source_lite', name: 'ComplianceSource Lite' },
            { id: 'compliance_source_ultra_lite', name: 'ComplianceSource Ultra Lite' }
          ]}
          control={control}

          transparent
          placeholder={'Select'}
        />
        <Input
          smallLabel
          name={'show_compliance_items_adopted_date'}
          label={'Show Compliance Items Adopted Date as of:'}
          type={'date'}
          control={control}
          disableFutureDates
          transparent
          placeholder={'Select Date'}
          showCross={false}
          noLimit
        />
      </Row>
      {/* <Divider style={{ background: '#CCC' }} />
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 20 }}>
        <StyledSwitch
          defaultChecked={store?.cmsCompany[0]?.has_compliance_custom_pricing}
          checkedChildren="Yes"
          unCheckedChildren="No"
          onChange={(checked) => { setHasCustomPrice(checked) }}
        />
        <div style={{ marginLeft: 10 }} >
          <SwitchTitle>Has Custom Pricing</SwitchTitle>
          <SwitchText>Show a custom price, if not selected no price will <br /> show in the admin’s settings page.</SwitchText>
        </div>
      </div >
      {hasCustomPrice &&
        <Input
          smallLabel
          disabled={!hasCustomPrice}
          defaultValue={formatCurrency(store?.cmsCompany[0]?.compliance_custom_price) || ''}
          name={'compliance_custom_price'}
          type={'text'}
          control={control}
          transparent
          placeholder={'Enter Custom Price'}
          label={'Custom Price'}
          style={{ width: '48%' }}
          validation={{ required: true }}
          error={errors && errors.compliance_custom_price && 'Enter valid custom price'}
        />} */}

      <Divider style={{ background: '#CCC' }} />
      <SwitchTitle>Select which Jurisdictions, Topics or Lines of Business the company has access to:</SwitchTitle>
      <DetailsWrap style={{ marginTop: 20 }}>
        <TableTitle>Jurisdictions ({allJurisdictions ? 'All' : jursCount})</TableTitle>
        <TableTitle>Topics ({allTopics ? 'All' : topicsCount})</TableTitle>
        <TableTitle>Lines of Business ({allLinesOfBusiness ? 'All' : linesOfBusinessCount})</TableTitle>
      </DetailsWrap>
      <DetailsWrap>
        <AllCheckboxContainer>
          <div> All Jurisdictions </div>
          <Checkbox
            onChange={(e) => setAllJurisdictions(e.target.checked)}
            name={'all_jurisdictions'}
            style={{ marginLeft: 0, marginRight: 13 }}
            checked={allJurisdictions}>
          </Checkbox>

        </AllCheckboxContainer>
        <AllCheckboxContainer>
          <div> All Topics </div>
          <Checkbox
            onChange={(e) => setAllTopics(e.target.checked)}
            name={'all_topics'}
            style={{ marginLeft: 0 }}
            checked={allTopics}>
          </Checkbox>

        </AllCheckboxContainer>
        <AllCheckboxContainer>
          <div> All Lines Of Business</div>
          <Checkbox
            onChange={(e) => setAllLinesOfBusiness(e.target.checked)}
            name={'all_line_of_business'}
            checked={allLinesOfBusiness}
            style={{ marginLeft: 0, marginRight: 2 }}>
          </Checkbox>

        </AllCheckboxContainer>
      </DetailsWrap>
      {(!allJurisdictions || !allTopics || !allLinesOfBusiness) &&
        <DetailsWrap >
          <CheckboxGroup
            control={control}
            name={'jurisdictions'}
            options={store?.cms_options.jurisdictions}
            disabled={allJurisdictions}
            defaultValue={store?.cmsCompany[0]?.jurisdictions}
          />
          <CheckboxGroup
            control={control}
            name={'topics'}
            options={store?.cms_options.topics}
            disabled={allTopics}
            defaultValue={store?.cmsCompany[0]?.topics}
          />
          <CheckboxGroup
            control={control}
            name={'line_of_business'}
            options={store?.cms_options.line_of_business}
            disabled={allLinesOfBusiness}
            defaultValue={store?.cmsCompany[0]?.line_of_business}
          />
        </DetailsWrap>
      }
      <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PrevBtn onClick={() => history.goBack()}>Previous</PrevBtn>
        <Button
          text={'Next'}
          htmlType="submit"
          type={'main'}
          style={{
            width: '10%',
            padding: 0,
            marginRight: 0,
            marginLeft: '.5em',
            marginTop: '2.3em',
          }}
        />
      </Row>
    </form>
  )
});

export default ComplianceTab;