import React from 'react'
import Button from '../../../../components/common/Button/button'
import { Label } from '../../../../components/common/Input/input.style'
import Modal from '../../../../components/common/ModalNew/index'
import { Text } from '../right.style'
import { Cancel } from '../Tasks/Modal/style'

const DeleteModal = ({ onClose, onConfirm, name, loading, isReportSource }) => {
  return (
    <Modal onClose={onClose}>
      <Label>Are you sure you want to delete this {isReportSource ? 'Report' : ' Compliance Item'}?</Label>
      <Text>{name}</Text>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Cancel onClick={onClose}>Cancel</Cancel>
        <Button text='Delete' onClick={() => onConfirm(isReportSource)} loading={loading} />
      </div>
    </Modal>
  )
}

export default DeleteModal
