import React, { useState } from 'react'
import Button from '../../../../../../components/common/Button/button'
import { Label } from '../../../../../../components/common/Input/input.style'
import ModalWrap from '../../../../../../components/common/ModalNew/index'
import { useForm } from 'react-hook-form'
import { api } from '../../../../../../api'
import Input from '../../../../../../components/common/Input'
import { Cancel } from '../style'
import { useStore } from '../../../../../../mobx-store/context'
import TextEditor from '../../../../../Tracking/Right/HeldAdminItemAddModal/TextEditor'

const GetReadyModal = ({
  onClose,
  reportId,
  editMode,
  currentTask,
  setTasks,
  isReportSource,
  initialText = currentTask?.text || '',
}) => {
  // const store = useStore()
  const { control, errors, setValue, handleSubmit } = useForm()
  const [details, setDetails] = useState(editMode ? initialText : '')

  const createNewTask = () => {
    const url = isReportSource
      ? `/cms/report/${reportId}/create_get_ready_checkbox/`
      : `/cms/legislatives/${reportId}/set_applies_to/`
    api(url, { text: details }, 'POST').then((res) => {
      isReportSource ? setTasks([...res.checkboxes]) : setTasks([...res])
    });
    onClose()
  }

  const updateTasks = () => {
    const url = isReportSource ?
      `/cms/get_ready_checkboxes/${currentTask.id}/update_checkbox/`
      : `/cms/applies_to/${currentTask.id}/`
    api(url, { text: details }, 'PATCH').then((res) => {
      isReportSource ? setTasks([...res.checkboxes]) : setTasks([...res])
    });
    onClose()
  }


  return (
    <ModalWrap onClose={onClose}>
      <Label>{editMode ? 'Update Item' : 'Create New Item'}</Label>
      <Input
        required={true}
        style={{ marginBottom: 30 }}
        control={control}
        smallLabel
        name={'text'}
        label={'Item Text'}
        placeholder={'Type Text'}
        type={'text'}
        error={errors?.text && 'This field is required'}
        defaultValue={initialText}
        handleChange={(e) => setDetails(e.target.value)}
      />
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 30 }}>
        {<Cancel onClick={onClose}>
          Cancel
        </Cancel>}
        <Button
          text={editMode ? 'Update Item' : 'Create Item'}
          style={{ marginLeft: 'auto' }}
          onClick={editMode ? handleSubmit(updateTasks) : handleSubmit(createNewTask)}
        />
      </div>
    </ModalWrap>
  )
}

export default GetReadyModal
