import React from 'react'
import { leadership, team } from './data'
import { Grid } from './styles'
import TeamMember from './TeamMember'
import { TitleGlobal } from '../../../Compliance/styles'

const Employees = () => {
  return (
    <>
      <TitleGlobal style={{
        textAlign: 'center',
        justifyContent: 'center',
        fontFamily: 'var(--medium)',
        color: 'var(--blue)',
        fontSize: 32
      }}>
        Leadership
      </TitleGlobal>
      <Grid style={{marginTop: 24}}>
        {leadership.map((member) => (
          <TeamMember key={member.key} {...member} />
        ))}
      </Grid>
      <TitleGlobal style={{
        textAlign: 'center',
        justifyContent: 'center',
        fontFamily: 'var(--medium)',
        color: 'var(--blue)',
        fontSize: 32
      }}>
        The Team
      </TitleGlobal>
      <Grid team={true}>
        {team.map((member) => (
          <TeamMember key={member.key} {...member} />
        ))}
      </Grid>
    </>
  )
}

export default Employees