import { observer } from 'mobx-react'
import React from 'react'
import Checkbox from '../../../../components/common/Checkbox/index'
import { useStore } from '../../../../mobx-store/context'
import { Wrap } from './style'

const Item = observer(({ data, isResource }) => {
  const store = useStore()

  const isChecked = store.addedMembers.find((item) => item.id === data.id)

  // eslint-disable-next-line no-sparse-arrays
  const toggleMember = (e) => {
    if (isChecked) {
      store.deleteAddedMember(data.id)
    } else {
      store.setAddedMember(
        {
          ...data,
          ...(isResource
            ? { have_access_to_resource: true }
            : { have_access_to_tracking: true }),
        },
        ...[, ,],
        true
      )
    }
  }

  return (
    <Wrap>
      {data.first_name} {data.last_name}
      <Checkbox onChange={toggleMember} checked={isChecked} />
    </Wrap>
  )
})

export default Item
