import React from 'react'
import { Item, Wrap } from './status.styled'
import PropTypes from 'prop-types'

const StatusBar = ({ style, step }) => {
  const arr = [1, 2, 3, 4, 5]
  return (
    <Wrap style={style}>
      {arr.map((item, index) => (
        <Item key={item} step={step} index={index + 1}/>
      ))}
    </Wrap>
  )
}
StatusBar.propTypes = {
  step: PropTypes.number,
  style: PropTypes.object,
}

export default StatusBar
