import React from 'react'
import { observer } from 'mobx-react-lite'
import { Wrap } from './selectedPlan.style'
import { useStore } from '../../../mobx-store/context'

const SelectedPlan = observer(({ style }) => {
  const store = useStore()

  let isMonthly = store.company.length && store.company[0].payment_period ===
    'month'
  const period = isMonthly ? 'monthly' : 'annually'

  return (
    <Wrap style={style}>
      <div>
        Selected Plan Total: ${store.company[0]?.month_price}{' '}
        {store.company.length && '/ month'}
      </div>
      <div>{`Annual subscription, billed ${period}`}</div>
    </Wrap>
  )
})

export default SelectedPlan
