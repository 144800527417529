import React from 'react';
import { Checkbox, Button } from 'antd';
import { api } from '../../../../../api';
import { TaskCreateButton, Text, CustomCheckbox, CheckboxCover, CheckboxName, Title } from './style'
import { useStore } from '../../../../../mobx-store/context';


const CheckboxComponent = ({ data, legislative, getData, handleOpen, setAssigneNew }) => {
  const store = useStore()

  const group1Items = [];
  const group2Items = [];
  const group3Items = [];
  const group4Items = [];

  const toggleMainCheckbox = (name, value) => {
    const field = name + '_tasks'
    api(`/legislative/${legislative}/toggle_considerations_checkboxes/`, { [field]: value }, 'PATCH').then(() => {
      getData()
    })
  }
  const toggleContractImpacted = (name, value) => {
    api(`/legislative/${legislative}/toggle_considerations_checkboxes/`, { [name]: value }, 'PATCH').then(() => {
      getData()
    })
  }

  for (const key in data) {
    const item = data[key];
    const listItem = (
      <div >
        <div key={key} style={{ paddingTop: 3, paddingBottom: 3, display: 'flex' }}>
          <CheckboxCover>
            {(key !== "customer_contracts"
              && key !== "pharmacy_contracts"
              && key !== "rebate_contracts"
              && key !== "vendor_contracts"
            ) && <CustomCheckbox
                green={item.all_tasks_completed}
                disabled={item.na_checkbox}
                checked={item.main_checkbox}
                onChange={(e) => {
                  if (!e.target.checked) {
                    toggleMainCheckbox(key, e.target.checked)
                  }
                  key === 'other_contracts'
                    || key === 'vendor'
                    || key === 'internal'
                    || key === 'pharmacy'
                    || key === 'other'
                    || key === 'customer'
                    || key === 'update_formulary'
                    || key === 'update_clinical_guidelines'
                    ? store.setSingleValue('considerationTask', item.task_types[0])
                    : store.setSingleValue('considerationTask', item.task_type[0])
                  setAssigneNew(true)
                  handleOpen()

                }}
              />}
            {key === "customer_contracts"
              || key === "pharmacy_contracts"
              || key === "rebate_contracts"
              || key === "vendor_contracts"
              ? <Text style={{ width: 130 }}>{item.name}</Text>
              : <TaskCreateButton
                inActive={item.na_checkbox}
                onClick={() => {
                  if (item.na_checkbox) return
                  key === 'other_contracts'
                    || key === 'vendor'
                    || key === 'internal'
                    || key === 'pharmacy'
                    || key === 'other'
                    || key === 'customer'
                    || key === 'update_formulary'
                    || key === 'update_clinical_guidelines'
                    ? store.setSingleValue('considerationTask', item.task_types[0]) :
                    store.setSingleValue('considerationTask', item.task_type[0])
                  setAssigneNew(true)
                  handleOpen()
                }}>
                {item.name}
              </TaskCreateButton>
            } {
              (key !== "customer_contracts"
                && key !== "pharmacy_contracts"
                && key !== "rebate_contracts"
                && key !== "vendor_contracts"
              ) &&
              <CheckboxCover style={{ marginLeft: 10 }}>
                <CustomCheckbox
                  onChange={(e) => {
                    key === 'other_contracts'
                      || key === 'vendor'
                      || key === 'internal'
                      || key === 'pharmacy'
                      || key === 'other'
                      || key === 'customer'
                      || key === 'update_formulary'
                      || key === 'update_clinical_guidelines' ?
                      toggleContractImpacted(item.task_types[1], e.target.checked) :
                      toggleContractImpacted(item.task_type[1], e.target.checked)
                  }}
                  checked={item.na_checkbox}
                  green={false} />
                <CheckboxName>N/A</CheckboxName>
              </CheckboxCover>
            }
          </CheckboxCover>
          {(key === "customer_contracts"
            || key === "pharmacy_contracts"
            || key === "rebate_contracts"
            || key === "vendor_contracts"
          ) && (
              <>
                <CheckboxCover>
                  <CustomCheckbox
                    onChange={(e) => {
                      if (!e.target.checked) {
                        toggleContractImpacted(item.task_types[0], e.target.checked)
                      }
                      store.setSingleValue('considerationTask', item.task_types[0])
                      setAssigneNew(true)
                      handleOpen()
                    }}
                    checked={item.update_template_checkbox}
                    green={item.update_template_all_tasks_completed}
                    disabled={item.na_checkbox}
                  />
                  <TaskCreateButton inActive={item.na_checkbox} onClick={() => {
                    if (item.na_checkbox) return
                    store.setSingleValue('considerationTask', item.task_types[0])
                    setAssigneNew(true)
                    handleOpen()
                  }}>Update Template</TaskCreateButton>
                </CheckboxCover>
                <CheckboxCover>
                  <CustomCheckbox
                    onChange={(e) => {
                      toggleContractImpacted(item.task_types[1], e.target.checked)
                      if (e.target.checked) {
                        store.setSingleValue('considerationTask', item.task_types[1])
                        setAssigneNew(true)
                        handleOpen()
                      }
                    }}
                    disabled={item.na_checkbox}
                    checked={item.create_amendment_checkbox}
                    green={item.create_amendment_all_tasks_completed} />
                  <TaskCreateButton inActive={item.na_checkbox} onClick={() => {
                    if (item.na_checkbox) return
                    store.setSingleValue('considerationTask', item.task_types[1])
                    setAssigneNew(true)
                    handleOpen()
                  }}>Create Amendment</TaskCreateButton>
                </CheckboxCover>
                <CheckboxCover>
                  <CustomCheckbox
                    onChange={(e) => {
                      toggleContractImpacted(item.task_types[2], e.target.checked)
                    }}
                    checked={item.na_checkbox}
                    green={false} />
                  <CheckboxName>N/A</CheckboxName>
                </CheckboxCover>
              </>
            )}
        </div>
        {(key === "customer_contracts"
          || key === "pharmacy_contracts"
          || key === "rebate_contracts"
          || key === "vendor_contracts"
          || key === "other_contracts")
          && <div style={{ height: 1, backgroundColor: '#C4CACC', margin: '2px 0', width: 495 }} />}
      </div>
    );

    if (key === "update" || key === "create") {
      group1Items.push(listItem);
    } else if (key === "customer_contracts" || key === "pharmacy_contracts" || key === "rebate_contracts" || key === "vendor_contracts" || key === "other_contracts") {
      group2Items.push(listItem);
    } else if (key === "update_formulary" || key === "update_clinical_guidelines") {
      group4Items.push(listItem);
    } else {
      group3Items.push(listItem);
    }
  }
  return (
    <div>
      <div className="group">
        <Title style={{ marginTop: 15 }}>Policy and Procedures Impacted</Title>
        <div>{group1Items}</div>
      </div>
      <div className="group">
        <Title style={{ marginTop: 15 }}>Contract Impacted</Title>
        <div style={{ height: 1, backgroundColor: '#C4CACC', margin: '2px 0', width: 495 }} />
        <div>{group2Items}</div>
      </div>
      <div className="group">
        <Title style={{ marginTop: 15 }}>Communications Needed</Title>
        <div>{group3Items}</div>
      </div>
      <div className="group">
        <Title style={{ marginTop: 15 }}>Other</Title>
        <div>{group4Items}</div>
      </div>
    </div>
  );
};

export default CheckboxComponent;


