import styled from 'styled-components'

export const Wrap = styled('div')`
  border-top: 1px solid #C4CACC;
  border-bottom: 1px solid #C4CACC;
  padding: 30px 0 15px 0;
  margin-top: 15px;
`

export const Title = styled('div')`
  font-family: var(--bold);
  font-size: 14px;
  border-bottom: 1px solid #455154;
  padding-bottom: 15px;
  margin-bottom: 15px;
`

export const Placeholder = styled('div')`
  font-family: var(--regular);
  font-size: 14px;
`
