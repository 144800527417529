import styled from 'styled-components'

export const Wrap = styled('div')`
  border: 1px solid #c4cacc;
  padding: 20px;
  margin-top: 20px;
`

export const DescriptionLabel = styled('div')`
  font-family: var(--bold);
  font-size: 14px;
  color: var(--text);
  margin-bottom: 10px;
`
