import styled from 'styled-components'

export const Wrap = styled('form')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 15px;
`
export const PageWrap = styled('div')`
  width: 80%;
  padding-top: 90px;
  padding-bottom: 200px;
  margin: 0 auto;
  min-height: calc(100vh - 50px);
`

export const NawPageWrap = styled('div')`
  width: 100%;
  // padding-top: 90px;
  padding-bottom: 200px;
  // margin: 0 auto;
  min-height: calc(100vh - 50px);
`

export const AddButton = styled('div')`
  cursor: pointer;  
  font-size: 16px;
  font-family: var(--bold);
  color: var(--blue);
`
export const SwitchTitle = styled('div')`
font-size: 14px;
font-family: var(--bold);
color: var(--text);
`
export const SwitchText = styled('div')`
font-size: 12px;
font-family: var(--regular);
color: var(--text);
`
export const Row = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 26px;
  align-items: end;
  margin-bottom: 1em;
`

export const DetailsWrap = styled('label')`
font-size: 14px;
  gap: 26px;
font-family: var(--semiBold);
display: grid;
grid-template-columns: 1fr 1fr 1fr;
`

export const SwitchWrap = styled('label')`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: var(--regular);
  font-size: 16px;
`

export const AllCheckboxContainer = styled('div')`
  border: 1px solid #f0f0f0; 
  padding: 5px 10px; 
  display: grid; 
  grid-template-columns: 85% 15%;
  flex-direction: row; 
  justify-content: space-between; 
  font-family: var(--semiBold);
  font-size: 13px;
`
export const PageTitle = styled('div')`
  color: var(--blue);
  font-family: var(--bold);
  font-size: 24px;
`

export const TableTitle = styled('div')`
  border: 1px solid #f0f0f0; 
  border-bottom: none; 
  padding: 15px 10px; 
  background-color: #F2F5FF;
  display: flex; 
  flex-direction: row; 
  justify-content: space-between; 
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`
