import React from 'react'
import closeIcon from '../../../../../assets/images/close-grey.svg'
import Button from '../../../../../components/common/Button/button.jsx'
import { Label } from '../../../../../components/common/Input/input.style'
import { Overlay, Wrap } from '../../../../Tracking/Right/Tasks/Modal/style'

const Modal = ({ onClose, onDelete, card, cardType }) => {
  return (
    <>
      <Overlay />
      <Wrap>
        <img src={closeIcon} onClick={onClose} alt='close-icon' id='close' />
        <Label>
          Are you sure you want to delete the {cardType} ending *{card}?
        </Label>
        <div
          style={{
            marginTop: 20,
            marginLeft: 'auto',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button text='Delete a Card' onClick={onDelete} />
          <Button
            text='Cancel'
            style={{ backgroundColor: '#969FA2', marginLeft: 15 }}
            onClick={onClose}
          />
        </div>
      </Wrap>
    </>
  )
}

export default Modal
