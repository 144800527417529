
import React, { useState } from 'react'
import { Wrap, AddButton } from '../../../DashboardLanding/style'
import Button from '../../../../../components/common/Button/button'
import Input from '../../../../../components/common/Input'
import { useForm } from 'react-hook-form'
import { useStore } from '../../../../../mobx-store/context'
import AddMultipleUsersModal from './Modal'
import AddSingleUserModal from './SingleUserModal'
import { useHistory } from 'react-router-dom'

const Search = ({ allUsersPage, setPage, setSelectedUsersNames }) => {
  const store = useStore()
  const history = useHistory()
  const {
    control,
    handleSubmit,
    getValues,
  } = useForm()

  const [visible, setVisible] = useState(false)
  const [visibleUserModal, setVisibleUserModal] = useState(false)

  const onSubmit = () => {
    setPage(1)
    setSelectedUsersNames([])
    store.setSingleValue('cmsUsersSearch', getValues('name'))
  }

  return (
    <Wrap style={{ marginTop: 20 }}>
      {visible && <AddMultipleUsersModal onClose={() => setVisible(false)} name={store.cmsCompany[0].name} companyId={store.cmsCompany[0].id} />}
      {visibleUserModal && <AddSingleUserModal onClose={() => setVisibleUserModal(false)} name={store.cmsCompany[0].name} companyId={store.cmsCompany[0].id} />}
      <div style={{ display: 'flex', marginRight: 90 }}>
        <Input
          style={{ marginRight: 15 }}
          control={control}
          name='name'
          placeholder={allUsersPage === 'all' ? 'By Name, Email or Company' : 'Search by Users Name'}
          validation={{ validate: () => true }}
          handleClear={handleSubmit(onSubmit)}
        />
        <Button
          text='Search'
          style={{ marginLeft: 16, marginTop: 2 }}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
      {allUsersPage === 'companyUsers' &&
        <div style={{ display: 'flex', flexDirection: 'row', }}>
          <AddButton style={{ marginTop: 8, marginRight: 20 }} onClick={() => { setVisible(true) }}
          >+Add Multiple Users</AddButton>
          <AddButton style={{ marginTop: 8 }} onClick={() => { setVisibleUserModal(true) }}
          >+Add Single User </AddButton>
        </div>
      }
      {allUsersPage === 'admins' &&
        <div style={{ display: 'flex', flexDirection: 'row', }}>
          <AddButton style={{ marginTop: 8, marginRight: 20 }} onClick={() => { history.push('/dashboard-add-admin') }}
          >+Add New Admin/Staff</AddButton>
        </div>
      }
    </Wrap>
  );
};

export default Search; 