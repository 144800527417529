import { Select } from 'antd'
import React, { useRef } from 'react'
import angle from '../../../../assets/images/angle-down.svg'
import { useStore } from '../../../../mobx-store/context'
import { checkIfDisabled, option } from './input_select'

const InputMultiselect = ({
  placeholder,
  value,
  popupContainer,
  loading,
  options,
  colorArrow,
  onChange,
  customOptions,
  selectVars,
  handleChange,
  allowClear,
  showDeprecated,
  name,
  showDeprecatedLabel,
  allowDisabled,
  onlyEnabled,
  disabled,
  selectAll = true,
  getPopupContainer = false,
  smaillList = false,
  listHeight,
}) => {
  const store = useStore()
  const multiSelectRef = useRef()

  const mutatedOptions = options
    ?.filter(
      (item) =>
        (showDeprecated ? item.id : !item.is_deprecated) &&
        (onlyEnabled ? item.enabled : true)
    )
    ?.map((item, index) =>
      option({
        item,
        selectVars,
        index,
        disabled: !item.enabled,
        showDeprecatedLabel,
        allowDisabled,
      })
    )

  const parentNode = () => {
    if (getPopupContainer) {
      return (trigger) => trigger.parentElement.parentElement.parentElement
    }
    return false
  }

  return (
    <Select
      mode='multiple'
      disabled={disabled}
      allowClear={allowClear}
      style={{ width: '100%' }}
      placeholder={placeholder ? placeholder : 'Select all that apply'}
      listHeight={listHeight ? listHeight : smaillList ? 200 : 380}
      ref={multiSelectRef}
      onChange={(value) => {
        if (value.length && value[value.length - 1] === 'all') {
          const items = options
            ?.filter(
              (item) =>
                !item.is_deprecated
            ).filter((item) => !item.is_taken)
            .map((item) => item.id)
          onChange(items)
          multiSelectRef.current.blur()
        } else {
          onChange(value)
        }
        handleChange && handleChange()
      }}
      value={value || []}
      showArrow={true}
      loading={loading}
      suffixIcon={colorArrow && <img src={angle} alt={'#'} />}
      getPopupContainer={parentNode()}
      filterOption={(input, option) =>
        customOptions ?
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          : option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {selectAll && (options?.length > 2 || customOptions?.length > 2) && (
        <Select.Option value={'all'}>Select All</Select.Option>
      )}
      {customOptions || mutatedOptions}
    </Select>
  )
}

export default InputMultiselect
