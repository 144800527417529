import { observer } from 'mobx-react-lite'
import Cookies from 'universal-cookie'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { api } from '../../../api'
import Button from '../../../components/common/Button/button'
import Input from '../../../components/common/Input'
import { getDescription, getDirtyFields } from '../../../helpers/helpers'
import { useStore } from '../../../mobx-store/context'
import { Title, Wrap, ExportButton } from './style'
import { Checkbox } from 'antd'
import { get } from 'mobx'

const Filters = observer(({ setSearchObj, subscription, count, alertForAllMode, archive }) => {
  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    setValue,
    register,
    watch,
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      filter_alert_me: false,
      more_than_one_version: false,
    }
  })
  const store = useStore()
  const [savedSearch, setSavedSearch] = useState([])
  const [uniqueJurs, setUniqueJurs] = useState([])

  useEffect(() => {
    if (alertForAllMode) {
      setValue('filter_alert_me', false)
      setValue('more_than_one_version', false)
    }
  }, [alertForAllMode])

  useEffect(() => {
    reset()
  }, [archive])


  const proposedTypes = [
    {
      id: 'bill',
      name: 'Bill'
    },
    {
      id: 'regulation',
      name: 'Regulation'
    }
  ]

  const getUniqueJurs = () => {
    if (!store.proposed_source_jurisdictions) return
    const arr = [...store.proposed_source_jurisdictions]

    const result = arr
      .map((item) => item?.id || item)
      .filter((item, index, final) => item && final.indexOf(item) === index)
      .sort((a, b) => {
        if (a === 1) return -1;
        if (b === 1) return 1;
        return 0;
      })
      .map((item) => ({ id: item, name: getDescription(store.proposed_source_jurisdictions, item) }));


    setUniqueJurs(result)
  }

  const getSearchableString = (values) => {
    let result = ''
    if (!values) return result
    const lastKey = Object.keys(values)[Object.keys(values).length - 1]

    for (let key in values) {
      if (!values[key]) continue

      if (key === 'date') {
        result += `date_after=${moment(values[key].split('±')[0]).format(
          'YYYY-MM-DD'
        )}&`
        if (values[key].split('±')[0])
          result += `date_before=${moment(values[key].split('±')[1]).format(
            'YYYY-MM-DD'
          )}&`
      } else {
        result += `${key}=${values[key]}${key === lastKey ? '' : '&'}`
      }
    }

    return result
  }

  const onSubmit = (values) => {
    setSearchObj((prev) => ({
      ...prev,
      pageNumber: 1,
      searchString: getSearchableString(values)
    }))
    api(
      `/proposed_source/?${getSearchableString(
        getDirtyFields(dirtyFields, values)
      )}${archive ? '&archived=true' : ''}`,
      {},
      'GET'
    ).then((data) => {
      if (!data.errors) {
        store.setSingleValue('bills', data.results)
        if (!values.jurisdiction) return
        api(
          '/saved_search/proposed_source/',
          { jurisdiction: values.jurisdiction },
          'POST'
        )
          .then((data) => {
            if (!data.errors) {
              setSavedSearch(data.jurisdiction.slice(-5).reverse())
            }
          })
          .catch((err) => console.log(err))
      }
    })
  }



  const onExportProposedItems = () => {

    const cookies = new Cookies()
    const csrftoken = cookies.get('csrftoken')
    let token = cookies.get('pbm_token')

    fetch(
      `${process.env.REACT_APP_API_DEV
      }/proposed_source/export/?${getSearchableString(
        getDirtyFields(dirtyFields, getValues())
      )}${archive ? '&archived=true' : ''}${getValues('filter_alert_me') === true ? '&filter_alert_me=true' : ''}${getValues('more_than_one_version') === true ? '&more_than_one_version=true' : ''}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${token}`,
          'X-CSRFToken': csrftoken,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'proposed_source.xlsx')
        document.body.appendChild(link)

        link.click()

        link.parentNode.removeChild(link)
      })
  }

  useEffect(() => {
    if (!store.jurs) store.loadSelect('companyOptions')
    api('/saved_search/proposed_source/', {}, 'GET').then((data) => {
      if (!data.errors) {
        setSavedSearch(
          (data.jurisdiction && data.jurisdiction.slice(-5).reverse()) || []
        )
      }
    })
  }, [])

  useEffect(() => {
    getUniqueJurs()
  }, [store.jurs, savedSearch])

  if (!store.jurs) return null

  return (
    <Wrap style={{ marginTop: 15 }}>
      <div style={{ display: 'flex', width: '100%', alignItems: 'flex-end', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', marginRight: 90 }}>
          <Input
            style={{ marginRight: 15 }}
            control={control}
            name='name'
            placeholder='Search by Refence # or Title'
            validation={{ validate: () => true }}
            handleClear={handleSubmit(onSubmit)}
          />
          <Button
            text='Search'
            style={{ marginLeft: 16 }}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
        <div style={{ display: 'flex', minWidth: 620 }}>

          <Input
            control={control}
            name='type'
            type='select'
            placeholder='Select'
            options={proposedTypes}
            styleContainer={{ marginRight: 15 }}
            label='Type'
            handleChange={handleSubmit(onSubmit)}
            smallLabel
            showSearch
            getPopupContainer={true}
          />
          <Input
            control={control}
            name='jurisdiction'
            type='select'
            placeholder='Select'
            options={uniqueJurs}
            styleContainer={{ marginRight: 15 }}
            label='Jurisdiction'
            handleChange={handleSubmit(onSubmit)}
            smallLabel
            showSearch
            getPopupContainer={true}
          />
          <Input
            control={control}
            name='date'
            type='date_range'
            placeholder='Select a Date Range'
            dateRangeStyle={{ right: 0 }}
            label='Date Range'
            handleChange={handleSubmit(onSubmit)}
            reset={() => setValue('date', null)}
            smallLabel
            isSearchOpened
            noLimit
            getPopupContainer={true}
          />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <div style={{ display: 'flex' }}>
          <Title style={{ paddingTop: 24, fontSize: 12, fontFamily: 'var(--regular)', color: 'var(--text)' }}>{count} Results</Title>
          <ExportButton onClick={() => onExportProposedItems()}>Export Results</ExportButton>
        </div>
        <div style={{ display: 'flex' }}>
          <Title style={{ paddingTop: 24, marginRight: 15 }}>Filter By:</Title>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 23 }}>
            {!archive &&
              <>
                <Checkbox
                  control={control}
                  {...register('filter_alert_me')}
                  checked={subscription ? false : watch('filter_alert_me')}
                  onChange={(e) => {
                    setValue('filter_alert_me', e.target.checked);
                    handleSubmit(onSubmit)();
                  }}
                  name="filter_alert_me"
                  disabled={subscription}
                />
                <div style={{ width: 120, marginLeft: 5, marginRight: 10, fontSize: 14, fontFamily: 'var(--regular)', color: 'var(--text)' }}>Only Alert Me Items </div>
              </>
            }
            <Checkbox
              control={control}
              {...register('more_than_one_version')}
              checked={watch('more_than_one_version')}
              onChange={(e) => {
                setValue('more_than_one_version', e.target.checked);
                handleSubmit(onSubmit)();
              }}
              name="filter_alert_me"
            />
            <div style={{ width: 146, marginLeft: 5, marginRight: 10, fontSize: 14, fontFamily: 'var(--regular)', color: 'var(--text)' }}>Has More Than 1 Update </div>
          </div>
        </div>
      </div>
    </Wrap>
  )
})

export default Filters
