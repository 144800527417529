import styled from 'styled-components';
import { Table } from 'antd';


export const StyledTable = styled(Table)`
.ant-table-thead .ant-table-cell {
  background-color: #F2F5FF;
}
`;

export const ItemsShowing = styled('div')`
  font-size: 10px;
  color: var(--text);
  font-family: var(--regular);
  margin-left: ${props => props.position === 'right' ? '0px' : 'auto'};
  margin-right: ${props => props.position === 'right' ? 'auto' : '0px'};
`

export const UnderTableCover = styled('div')`
  margin-top: ${props => props.allUsersPage === 'all' ? '-20px' : '20px'};
  display: flex; 
  flex-direction: row; 
  width: fit-content;
`