import AppliesTo from "./AppliesTo";
import ImpactedClients from "./TagInput";
import TasksCreation from "./TaskCreation";
import { useStore } from "../../../../mobx-store/context";
import { checkRole } from '../../../../helpers/helpers'
import { Divider } from 'antd';
import { useEffect } from "react";

const Considerations = ({ legislative, handleOpen, setAssigneNew, assignee, status, getConsiderationData, considerationData }) => {
    const store = useStore();
    useEffect(() => {
        getConsiderationData();
    }, [legislative]);
    return (
        <div style={{ marginBottom: 100 }}>
            <AppliesTo legislative={legislative} />
            <ImpactedClients id={legislative} disabled={!checkRole(
                    [store.user?.[0]?.member_type],
                    ['owner', 'admin', 'compliance_owner']
                ) } />
            {assignee &&
                checkRole(
                    [store.user?.[0]?.member_type],
                    ['owner', 'admin', 'compliance_owner']
                ) &&
                status !== 'closed' ?
                <>
                    <Divider style={{ height: 3, borderRadius: 6, backgroundColor: '#225194', margin: '35px 0px' }} />
                    <TasksCreation
                        legislative={legislative}
                        handleOpen={handleOpen}
                        setAssigneNew={setAssigneNew}
                        getConsiderationData={getConsiderationData}
                        considerationData={considerationData}
                    />
                </>
                : <div
                    style={{
                        marginTop: 35,
                        fontFamily: 'var(--regular)',
                        fontSize: 14,
                        color: 'var(--text)',
                        borderBottom: '1px solid var(--grey)',
                    }}
                >
                    You must assign a ComplianceSource Owner before assigning Consideration tasks.
                </div>
            }
        </div>
    );
};

export default Considerations;