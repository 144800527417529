import styled from 'styled-components';

export const Title = styled('div')`
  color: var(--text);
  font-family: var(--medium);
  font-size: 24px;
`;

export const SubTitle = styled('div')`
  font-size: 16px;
  color: var(--text);
  font-family: var(--regular);
  a {
    color: var(--blue);
    text-decoration: underline;
    font-size: 16px;
    font-family: var(--bold);
  }
`
export const Button = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  border-radius: 6px;
  background-color:  var(--blue);
  border: 1px solid var(--blue);
  color: #fff !important;
  font-size: 1em;
  height: 37px;
  opacity: 1;
  width: fit-content;
  cursor: pointer;
  font-family: var(--regular) !important;;

  &:hover {
    background-color: #113363;
    color: #fff !important;
  }
`
export const ModalWrapper = styled('div')`
  height: 200px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    position: absolute;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: var(--grey);
    border-radius: 5px;
    width: 8px;
  }
`