import { useEffect } from "react";
import Header from "../Home/Main/Header";
import { Divider, ItemsRow } from "./style";
import SecurityItem from "./securityItem";
import Footer from "../Home/Compliance/Footer";
const SecurityPrivacy = () => {

  const securityItems = [
    {
      key: 0,
      title: 'Data at Rest',
      titleColor: 'var(--blue)',
      titleLeft: false,
      description1: 'All datastores with customer data, are encrypted at rest. Sensitive collections and tables also use row-level encryption.',
      description2: 'This means the data is encrypted even before it hits the database so that neither physical access, nor logical access to the database, is enough to read the most sensitive information.',
    },
    {
      key: 1,
      title: 'Data in Transit',
      titleColor: '#70B461',
      titleLeft: false,
      description1: 'Vanta uses TLS 1.2 or higher everywhere data is transmitted over potentially insecure networks. We also use features such as HSTS (HTTP Strict Transport Security) to maximize the security of our data in transit. ',
      description2: 'Server TLS keys and certificates are managed by AWS and deployed via Application Load Balancers.',
    },
    // {
    //   key: 2,
    //   title: 'SOC2 Compliance',
    //   titleColor: '#DF7411',
    //   titleLeft: false,
    //   description1: 'PBMSource upholds SOC2 Type 2 compliance by implementing robust security measures,  and consistently refining internal processes to meet the stringent standards set forth by the SOC2.',
    //   description2: 'Through continuous monitoring and proactive risk management, we ensure the confidentiality, integrity, and availability of customer data.',
    // },
  ];

  const vulnerability = {
    key: 3,
    title: 'Vulnerability and Intrusion Testing',
    titleColor: 'var(-text)',
    titleLeft: true,
    description1: 'We prioritize the security of our systems and data. That\'s why we partner with Intruder.io for intrusion testing and vulnerability scanning. Through their advanced platform, we proactively identify and mitigate potential security risks, ensuring that our infrastructure remains robust and resilient against cyber threats. With Intruder.io\'s comprehensive scanning capabilities, we maintain the highest standards of security to safeguard our clients\' sensitive information and uphold their trust in our services.'
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        title={'Security and Privacy '}
        subtitle={'Security is at the heart of what we do—helping our customers </br> improve their Compliance workflow in a secure environment. '}
        showImage={false}
      />
      <Divider />
      <ItemsRow>
        {securityItems.map((item) => (
          <SecurityItem key={item.key} item={item} />
        ))}
      </ItemsRow>
      <ItemsRow style={{ gridTemplateColumns: '1fr', marginTop: 32, marginBottom: 40 }}>
        <SecurityItem key={vulnerability.key} item={vulnerability} />
      </ItemsRow>
      <Footer />
    </div>
  );
};

export default SecurityPrivacy;




