import { Empty, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useRef, useCallback } from 'react'
import Item from './Item/index'
import { Wrap } from './style'

const List = observer(({ items, setSearchObj, hasMore, loading, subscriptionAll, archive }) => {

  const observer = useRef();
  const lastItemElementRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setSearchObj((prevSearchObj) => ({
          ...prevSearchObj,
          pageNumber: prevSearchObj.pageNumber + 1
        }))
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore])

  const content = items?.map((item, i) => {
    if (items.length === i + 1) {
      return <Item ref={lastItemElementRef} item={item} key={item.id} subscriptionAll={subscriptionAll} archive={archive} />
    }
    return <Item item={item} key={item.id} subscriptionAll={subscriptionAll} archive={archive} />
  })

  return (
    <Wrap>
      {!items || items.length === 0
        ? !loading && <div style={{ marginTop: 20 }}><Empty /></div>
        : content
      }
      {loading && <div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
      }}>
        <Spin />
      </div>}
    </Wrap>
  )
})

export default List