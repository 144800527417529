import styled from 'styled-components'

export const Wrap = styled('div')`
  padding-top: 5.625em;
  height: 100px;
`

export const Ads = styled('div')`
  height: 180px;
  width: 100%;
  background-color: var(--green);
`

export const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 3fr;
  width: 95vw;
  margin: 0 auto;
  padding-top: 2em;
  padding-bottom: 5em;
  gap: 2em;
`

export const BlueBlock = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3.375em;
  border: 2px solid var(--blue);
  font-family: var(--semiBold);
  font-size: 1em;
  color: var(--blue);
  margin-bottom: 2em;
  text-align: center;
  padding: 1em;

  img {
    margin-right: 0.5em;
    margin-top: 0.3em;
  }
`

export const Block = styled('div')`
  box-shadow: 0 4px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`

export const Form = styled('form')`
  padding: 1em;
`

export const FormRow = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  margin-top: 1em;
`

export const Resource = styled('div')`
  display: flex;
  align-items: center;
  font-family: var(--medium);
  font-size: 0.875em;
  color: var(--text);
  padding: 0 1em;
  height: 3em;

  span {
    color: var(--orange);
    margin-left: 1em;
  }

  div {
    margin-right: 1em;
  }
`
