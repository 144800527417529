import { useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import Item from './item';
import { useStore } from '../../../../../mobx-store/context';
import Button from "../../../../../components/common/Button/button";
import { api } from '../../../../../api';
import { Divider, message } from 'antd';
import ResourceInfo from './resourceInfo'

const PricingTab = ({ companyId, handleTabChange }) => {
  const store = useStore();
  const [resourceGroups, setResourceGroups] = useState([]);

  const { control, handleSubmit, register, setValue, errors } = useForm({
    defaultValues: { items: [] }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const onSubmit = data => {
    const processedItems = data.items.map(item => ({
      ...item,
      value: `${item.value}`.replace(/[$,a-zA-Z]/g, '')
    })).map(item => ({
      ...item,
      value: item.value ? item.value : null
    }));
    api(`/cms/companies/${companyId}/pricing/`, { values: processedItems }, 'POST').then((res) => {
      if (!res.error) {
        store.setSingleValue('cmsCompany', [res])
        message.success('Pricing saved successfully', 0.5);
        handleTabChange('users');
      } else {
        message.error('An error occurred while saving pricing', 0.5);
      }
    })
      .catch(err => {
        console.error('Submission error:', err);
        message.error('An error occurred while saving pricing', 0.5);
      });
  };

  const getAllGroups = () => {
    api(`/cms/resource_portions/?company=${companyId}`, {}, 'GET').then((res) => {
      if (!res.errors) {
        setResourceGroups(res);
      }
    })
  }

  const addItem = () => {
    append({ product: '', value: null, internal_notes: '', });
  };


  useEffect(() => {
    if (store?.cmsCompany[0].pricing.length > 0) {
      store?.cmsCompany[0].pricing.forEach(item => {
        append(item);
      })
    } else {
      addItem();
    }
  }, [store?.cmsCompany[0].pricing, append]);

  useEffect(() => {
    fields.forEach((field, index) => {
      setValue(`items[${index}].product`, field.product || '');
      setValue(`items[${index}].value`, field.value || '');
      setValue(`items[${index}].internal_notes`, field.internal_notes || '');
      field.pricing_id && setValue(`items[${index}].pricing_id`, field.pricing_id || '');
    });
  }, [fields, setValue]);

  useEffect(() => {
    getAllGroups()
  }, {})

  const removePricing = (id, index) => {
    api(`/cms/companies/${companyId}/pricing/`, { pricing_id: id }, 'DELETE').then((res) => {
      if (!res.errors) {
        remove(index)
        message.success('Pricing Item removed successfully', 0.5);
      }
    });
  };


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((item, index) => (
        <Item key={item.id}
          index={index}
          item={item}
          control={control}
          remove={remove}
          register={register}
          errors={errors}
          companyId={companyId}
          removePricing={removePricing}
        />
      ))}
      <div style={{
        color: 'var(--blue)',
        fontFamily: 'var(--bold)',
        fontSize: 12,
        cursor: 'pointer',
        textDecoration: 'underline',
      }} onClick={() => addItem()}> {'Add Another'}</div>
      {resourceGroups.length > 0 &&
        resourceGroups.map((group, index) => (
          <ResourceInfo resource={group} handleTabChange={handleTabChange} />
        ))}
      <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
        <Button
          text={'Save'}
          htmlType="submit"
          type={'main'}
          style={{
            width: '10%',
            padding: 0,
            marginRight: 0,
            marginLeft: '.5em',
            marginTop: '2.3em',
          }}
        />
      </div>
    </form>

  )
};

export default PricingTab;
