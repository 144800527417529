import styled from 'styled-components'
import { Switch, Radio } from 'antd';

export const Title = styled('form')`
  font-size: 24px;
  font-family: var(--semiBold);
  color: var(--blue);
  margin-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #8B9394;
`
export const SubTitle = styled('form')`
  font-size: 18px;
  font-family: var(--semiBold);
  color: var(--blue);
`
export const StyledSwitch = styled(Switch)`
  &&.ant-switch-checked {
    background-color: #2A8F11; 
    border-color: #2A8F11; 
`;

export const CompanyDescription = styled('div')`
 color: var(--text);
  font-size: 12px;
  font-family: var(--regular);
  line-height: 130%;
  margin-bottom: 30px;
`

export const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-checked .ant-radio-inner {
    border-color: #2A8F11;
  }

  .ant-radio-checked .ant-radio-inner::after {
    background-color: #2A8F11;
  }
`;