import React from 'react';
import Modal from '../../../../components/common/ModalNew/index'
import Button from '../../../../components/common/Button/button'
import { Label } from '../../../../components/common/Input/input.style'
import { Cancel } from '../Tasks/Modal/style'
const ConfirmModal = ({ onClose, onSubmit }) => {
  return (
    <Modal
      styleWrap={{
        width: 'auto',
        height: 'auto',
        minHeight: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: '1px solid var(--grey)',

      }}
      onClose={onClose}>
      <Label style={{ fontSize: '1.5em' }}>Are you sure you want to continue without saving?</Label>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 150 }}>
        <Cancel onClick={onClose}>Cancel</Cancel>
        <Button text='Yes' onClick={() => onSubmit()} />
      </div>
    </Modal>
  )
}

export default ConfirmModal
