import Modal from "../../ModalNew"
import Button from "../../Button/button"
const NoAccessModal = ({ onClose }) => {
  return (
    <Modal
      styleWrap={{
        maxWidth: 500,
        padding: '30px 40px',
      }}
      onClose={onClose} >
      <div style={{ fontSize: 20, fontFamily: 'var(--medium)', color: 'var(--text)', marginBottom: 10 }}>
        This company does not have access to <br />
        This Service
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button text={'I Understand'} onClick={onClose} />
      </div>
    </Modal >
  )
}

export default NoAccessModal