import styled from 'styled-components'
import { Title as GlobalTitle } from '../style'

export const Wrap = styled('div')`
  margin-top: 20px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 250px;
  width: 100%;
  padding: 32px;
  border: 1px solid #E4E7EC;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px 1px rgba(16, 24, 40, 0.05);
  border-radius: 16px;
  @media (max-width: 1300px) {
    height: auto;
  }
`
export const Title = styled(GlobalTitle)`
  font-size: 32px;
`
export const Subtitle = styled('div')`
  font-size: 20px;
  font-family: var(--medium);
`
export const Text = styled('div')`
  font-size: 16px;
  color: var(--grey500);
  font-family: var(--regular);
`
export const TextBlue = styled('div')`
  font-size: 16px;
  color: #4B75B1;
  font-family: var(--semiBold);
`


export const Section = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`