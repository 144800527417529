import React from 'react'
import check from '../../../../../assets/images/OK.svg'
import { DescriptionGlobal } from '../../styles'
import { Title, Wrap } from './style'

const Item = ({ title, description }) => {
  return (
    <Wrap>
      <Title>
        <img src={check} alt='check-icon' />
        {title}
      </Title>
      <DescriptionGlobal>{description}</DescriptionGlobal>
    </Wrap>
  )
}

export default Item
