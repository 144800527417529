import styled from 'styled-components'

export const Wrap = styled('div')`
  width: 32px;
  height: 21px;
  border-radius: 15px;
  background-color: ${(props) => (props.active ? 'var(--green)' : '#bbb')};
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-right: 1em;
`

export const Circle = styled('div')`
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background-color: #fff;
  border: 1px solid var(--green);
  transform: translateX(${(props) => (props.active ? '10px' : '-10px')});
  transition: all 0.2s ease-in-out;
`

export const Container = styled('div')`
  display: flex;
  align-items: center;
  font-family: var(--regular);
  font-size: 1.125em;
  color: var(--text);
  max-width: ${(props) => props.half && '55%'};
  p {
    margin: 0;
    padding: 0;
  }
`
