import { Select } from 'antd'
import { checkRole } from '../../../helpers/helpers'

export const inputs = (store, totals) => [
  {
    key: 1,
    name: 'jurisdiction',
    label: 'Jurisdiction',
    placeholder: 'Select',
    options: store.legislative_options?.jurisdictions,
    showDeprecatedLabel: true,
    showDeprecated: true,
    onlyEnabled: true,
  },
  {
    key: 0,
    name: 'assignee',
    label: 'ComplianceSource Owner',
    placeholder: 'All (Including Unassigned)',
    customOptions: store.members
      .filter((item) => {
        return (
          item.is_active &&
          item.have_access_to_tracking &&
          checkRole([item.member_type], ['admin', 'compliance_owner', 'owner'])
        )
      })
      .map((item) => (
        <Select.Option value={item.id} key={item.id}>
          {item.first_name + ' ' + item.last_name}
        </Select.Option>
      )),
  },

  {
    key: 2,
    name: 'topic',
    label: 'Topics',
    placeholder: 'Select',
    options: store.legislative_options?.topics,
    showDeprecatedLabel: true,
    showDeprecated: true,
    onlyEnabled: true,
  },
  {
    key: 3,
    name: 'line_of_business',
    label: 'Line of Business',
    placeholder: 'Select',
    options: store.legislative_options?.line_of_business,
    showDeprecatedLabel: true,
    showDeprecated: true,
    isHidden: !store.company?.[0]?.is_premium,
  },
  {
    key: 4,
    name: 'task_assigned_to',
    label: 'Task Assigned To:',
    placeholder: 'Select',
    customOptions: store.members
      .map((item) => (
        <Select.Option value={item.id} key={item.id}>
          {item.first_name + ' ' + item.last_name}
        </Select.Option>
      )),
  },
  {
    key: 3,
    name: 'status',
    label: 'Status',
    placeholder: 'Select',
    options: [
      {
        id: 'unassigned',
        name: 'Unassigned',
      },
      {
        id: 'in_progress',
        name: 'In Progress',
      },
      {
        id: 'all_tasks_completed',
        name: 'All Tasks Completed',
      },
      {
        id: 'closed',
        name: 'Closed',
      },
      {
        id: 'monitor',
        name: 'Monitor',
      },
    ],
  },
  {
    key: 4,
    name: 'today_proximity',
    label: 'Compliance Date Range',
    placeholder: 'Select',
    type: 'select',
    options: [
      {
        id: 30,
        name: '30 Days',
      },
      {
        id: 60,
        name: '60 Days',
      },
      {
        id: 90,
        name: '90 Days',
      },
      {
        id: 'last_quarter',
        name: 'Last Quarter',
      },
      {
        id: 'custom',
        name: 'Custom',
      },
    ],
  },
  {
    key: 7,
    name: 'close_date',
    label: 'Closed Date Range',
    placeholder: 'Select',
    type: 'select',
    options: [
      {
        id: 30,
        name: '30 Days',
      },
      {
        id: 60,
        name: '60 Days',
      },
      {
        id: 90,
        name: '90 Days',
      },
      {
        id: 'closed_date_last_quarter',
        name: 'Last Quarter',
      },
      {
        id: 'closed_date_custom',
        name: 'Custom',
      },
    ],
  },
  {
    key: 4,
    name: 'is_starred',
    label: 'Priority',
    placeholder: 'Select',
    type: 'select',
    options: [
      {
        id: null,
        name: 'All',
      },
      {
        id: 'yes',
        name: 'Yes',
      },

      {
        id: 'no',
        name: 'No',
      },
    ],
  },
]
export const complianceTaskinputs = (store, totals) => [
  {
    key: 0,
    name: 'jurisdiction',
    label: 'Jurisdiction',
    placeholder: 'Select',
    options: store.legislative_options?.jurisdictions,
    showDeprecatedLabel: true,
    showDeprecated: true,
    onlyEnabled: true,
  },
  {
    key: 1,
    name: 'compliance_owner',
    label: 'ComplianceSource Item Owner',
    placeholder: 'All',
    customOptions: store.members
      .filter((item) => {
        return (
          item.is_active &&
          item.have_access_to_tracking &&
          checkRole([item.member_type], ['admin', 'compliance_owner', 'owner'])
        )
      })
      .map((item) => (
        <Select.Option value={item.id} key={item.id}>
          {item.first_name + ' ' + item.last_name}
        </Select.Option>
      )),
  },
  {
    key: 2,
    name: 'assignee',
    label: 'Task Owner',
    placeholder: 'All',
    customOptions: store.members
      .map((item) => (
        <Select.Option value={item.id} key={item.id}>
          {item.first_name + ' ' + item.last_name}
        </Select.Option>
      )),
  },
  {
    key: 3,
    name: 'topic',
    label: 'Topics',
    placeholder: 'Select',
    options: store.legislative_options?.topics,
    showDeprecatedLabel: true,
    showDeprecated: true,
    onlyEnabled: true,
  },
  {
    key: 4,
    name: 'line_of_business',
    label: 'Line of Business',
    placeholder: 'Select',
    options: store.legislative_options?.line_of_business,
    showDeprecatedLabel: true,
    showDeprecated: true,
    isHidden: !store.company?.[0]?.is_premium,
  },
  {
    key: 5,
    name: 'status',
    label: 'Task Status',
    placeholder: 'Select',
    options: [
      {
        id: 'incomplete',
        name: 'Incomplete',
      },
      {
        id: 'complete',
        name: 'Complete',
      },
    ],
  },
  {
    key: 4,
    name: 'today_proximity',
    label: 'Compliance Date Range',
    placeholder: 'Select',
    type: 'select',
    options: [
      {
        id: 30,
        name: '30 Days',
      },
      {
        id: 60,
        name: '60 Days',
      },
      {
        id: 90,
        name: '90 Days',
      },
      {
        id: 'last_quarter',
        name: 'Last Quarter',
      },
      {
        id: 'custom',
        name: 'Custom',
      },
    ],
  },
  {
    key: 7,
    name: 'close_date',
    label: 'Closed Date Range',
    placeholder: 'Select',
    type: 'select',
    options: [
      {
        id: 30,
        name: '30 Days',
      },
      {
        id: 60,
        name: '60 Days',
      },
      {
        id: 90,
        name: '90 Days',
      },
      {
        id: 'closed_date_last_quarter',
        name: 'Last Quarter',
      },
      {
        id: 'closed_date_custom',
        name: 'Custom',
      },
    ],
  },
  {
    key: 7,
    name: 'task_assigned',
    label: 'Task Assigned Date Range',
    placeholder: 'Select',
    type: 'select',
    options: [
      {
        id: 30,
        name: '30 Days',
      },
      {
        id: 60,
        name: '60 Days',
      },
      {
        id: 90,
        name: '90 Days',
      },
      {
        id: 'assigned_date_last_quarter',
        name: 'Last Quarter',
      },
      {
        id: 'assigned_date_custom',
        name: 'Custom',
      },
    ],
  },
]

export const ultraLiteInputs = (store, totals) => [
  {
    key: 1,
    name: 'jurisdiction',
    label: 'Jurisdiction',
    placeholder: 'Select',
    options: store.legislative_options?.jurisdictions,
    showDeprecatedLabel: true,
    showDeprecated: true,
    onlyEnabled: true,
  },
  {
    key: 7,
    name: 'close_date',
    label: 'Archive Date Range',
    placeholder: 'Select',
    type: 'select',
    options: [
      {
        id: 30,
        name: '30 Days',
      },
      {
        id: 60,
        name: '60 Days',
      },
      {
        id: 90,
        name: '90 Days',
      },
      {
        id: 'closed_date_last_quarter',
        name: 'Last Quarter',
      },
      {
        id: 'closed_date_custom',
        name: 'Custom',
      },
    ],
  },
  {
    key: 4,
    name: 'is_starred',
    label: 'Priority',
    placeholder: 'Select',
    type: 'select',
    options: [
      {
        id: null,
        name: 'All',
      },
      {
        id: 'yes',
        name: 'Yes',
      },

      {
        id: 'no',
        name: 'No',
      },
    ],
  },
  {
    key: 3,
    name: 'status',
    label: 'Status',
    placeholder: 'Select',
    options: [
      {
        id: 'unassigned',
        name: 'Tracking',
      },
      {
        id: 'closed',
        name: 'Archived',
      },
    ],
  },
]

export const liteInputs = (store, totals) => [
  {
    key: 1,
    name: 'jurisdiction',
    label: 'Jurisdiction',
    placeholder: 'Select',
    options: store.legislative_options?.jurisdictions,
    showDeprecatedLabel: true,
    showDeprecated: true,
    onlyEnabled: true,
  },
  {
    key: 2,
    name: 'topic',
    label: 'Topics',
    placeholder: 'Select',
    options: store.legislative_options?.topics,
    showDeprecatedLabel: true,
    showDeprecated: true,
    onlyEnabled: true,
  },
  {
    key: 3,
    name: 'line_of_business',
    label: 'Line of Business',
    placeholder: 'Select',
    options: store.legislative_options?.line_of_business,
    showDeprecatedLabel: true,
    showDeprecated: true,
    isHidden: !store.company?.[0]?.is_premium,
  },
  {
    key: 3,
    name: 'status',
    label: 'Status',
    placeholder: 'Select',
    options: [
      {
        id: 'unassigned',
        name: 'Tracking',
      },
      {
        id: 'closed',
        name: 'Archived',
      },
    ],
  },
  {
    key: 4,
    name: 'today_proximity',
    label: 'Compliance Date Range',
    placeholder: 'Select',
    type: 'select',
    options: [
      {
        id: 30,
        name: '30 Days',
      },
      {
        id: 60,
        name: '60 Days',
      },
      {
        id: 90,
        name: '90 Days',
      },
      {
        id: 'last_quarter',
        name: 'Last Quarter',
      },
      {
        id: 'custom',
        name: 'Custom',
      },
    ],
  },
  {
    key: 7,
    name: 'close_date',
    label: 'Archive Date Range',
    placeholder: 'Select',
    type: 'select',
    options: [
      {
        id: 30,
        name: '30 Days',
      },
      {
        id: 60,
        name: '60 Days',
      },
      {
        id: 90,
        name: '90 Days',
      },
      {
        id: 'closed_date_last_quarter',
        name: 'Last Quarter',
      },
      {
        id: 'closed_date_custom',
        name: 'Custom',
      },
    ],
  },
  {
    key: 4,
    name: 'is_starred',
    label: 'Priority',
    placeholder: 'Select',
    type: 'select',
    options: [
      {
        id: null,
        name: 'All',
      },
      {
        id: 'yes',
        name: 'Yes',
      },

      {
        id: 'no',
        name: 'No',
      },
    ],
  },
]


export const reportInputs = (store, totals) => [
  {
    key: 1,
    name: 'jurisdiction',
    label: 'Jurisdiction',
    placeholder: 'Select',
    options: store.legislative_options?.report_source_jurisdictions,
    showDeprecatedLabel: true,
    showDeprecated: true,
    onlyEnabled: true,
  },
  {
    key: 0,
    name: 'assignee',
    label: 'ReportSource Owner',
    placeholder: 'All (Including Unassigned)',
    customOptions: store.members
      .filter((item) => {
        return (
          item.is_active &&
          item.have_access_to_report_source &&
          checkRole([item.report_source_role], ['admin', 'report_owner', 'owner'])
        )
      })
      .map((item) => (
        <Select.Option value={item.id} key={item.id}>
          {item.first_name + ' ' + item.last_name}
        </Select.Option>
      )),
  },

  {
    key: 2,
    name: 'report_type',
    label: 'Report Type',
    placeholder: 'Select',
    options: store?.legislative_options?.report_types,
  },
  {
    key: 4,
    name: 'reporting_entity',
    label: 'Reporting Entity',
    placeholder: 'Select',
    options: store.reportingEntities,
  },
  {
    key: 5,
    name: 'task_assigned_to',
    label: 'Task Assigned To:',
    placeholder: 'Select',
    customOptions: store.members
      .map((item) => (
        <Select.Option value={item.id} key={item.id}>
          {item.first_name + ' ' + item.last_name}
        </Select.Option>
      )),
  },
  {
    key: 8,
    name: 'report_due_date',
    label: 'Report Due Date Range',
    placeholder: 'Select',
    type: 'select',
    options: [
      {
        id: 30,
        name: '30 Days',
      },
      {
        id: 60,
        name: '60 Days',
      },
      {
        id: 90,
        name: '90 Days',
      },
      {
        id: 'last_quarter',
        name: 'Last Quarter',
      },
      {
        id: 'custom',
        name: 'Custom',
      },
    ],
  },
  {
    key: 9,
    name: 'close_date',
    label: 'Closed Date Range',
    placeholder: 'Select',
    type: 'select',
    options: [
      {
        id: 30,
        name: '30 Days',
      },
      {
        id: 60,
        name: '60 Days',
      },
      {
        id: 90,
        name: '90 Days',
      },
      {
        id: 'closed_date_last_quarter',
        name: 'Last Quarter',
      },
      {
        id: 'closed_date_custom',
        name: 'Custom',
      },
    ],
  },
  {
    key: 4,
    name: 'priority',
    label: 'Priority',
    placeholder: 'Select',
    type: 'select',
    options: [
      {
        id: null,
        name: 'All',
      },
      {
        id: 'true',
        name: 'Yes',
      },

      {
        id: 'false',
        name: 'No',
      },
    ],
  },
  {
    key: 6,
    name: 'ties_to_license',
    label: 'Report Ties To License',
    placeholder: 'Select License(s)',
    options: store?.legislative_options?.report_source_ties_to_license,
  },
]

export const reportTaskInputs = (store, totals) => [
  {
    key: 1,
    name: 'jurisdiction',
    label: 'Jurisdiction',
    placeholder: 'Select',
    options: store.legislative_options?.report_source_jurisdictions,
    showDeprecatedLabel: true,
    showDeprecated: true,
    onlyEnabled: true,
  },
  {
    key: 0,
    name: 'report_owner',
    label: 'ReportSource Owner',
    placeholder: 'All (Including Unassigned)',
    customOptions: store.members
      .filter((item) => {
        return (
          item.is_active &&
          item.have_access_to_report_source &&
          checkRole([item.report_source_role], ['admin', 'report_owner', 'owner'])
        )
      })
      .map((item) => (
        <Select.Option value={item.id} key={item.id}>
          {item.first_name + ' ' + item.last_name}
        </Select.Option>
      )),
  },
  {
    key: 5,
    name: 'assignee',
    label: 'Task Owner',
    placeholder: 'Select',
    customOptions: store.members
      .map((item) => (
        <Select.Option value={item.id} key={item.id}>
          {item.first_name + ' ' + item.last_name}
        </Select.Option>
      )),
  },
  {
    key: 2,
    name: 'report_type',
    label: 'Report Type',
    placeholder: 'Select',
    options: store?.legislative_options?.report_types,
  },
  {
    key: 4,
    name: 'reporting_entity',
    label: 'Reporting Entity',
    placeholder: 'Select',
    options: store.reportingEntities,
  },
  {
    key: 5,
    name: 'status',
    label: 'Task Status',
    placeholder: 'Select',
    options: [
      {
        id: 'incomplete',
        name: 'Incomplete',
      },
      {
        id: 'complete',
        name: 'Complete',
      },
    ],
  },
  {
    key: 8,
    name: 'report_due_date',
    label: 'Report Due Date Range',
    placeholder: 'Select',
    type: 'select',
    options: [
      {
        id: 30,
        name: '30 Days',
      },
      {
        id: 60,
        name: '60 Days',
      },
      {
        id: 90,
        name: '90 Days',
      },
      {
        id: 'last_quarter',
        name: 'Last Quarter',
      },
      {
        id: 'custom',
        name: 'Custom',
      },
    ],
  },
  {
    key: 9,
    name: 'close_date',
    label: 'Closed Date Range',
    placeholder: 'Select',
    type: 'select',
    options: [
      {
        id: 30,
        name: '30 Days',
      },
      {
        id: 60,
        name: '60 Days',
      },
      {
        id: 90,
        name: '90 Days',
      },
      {
        id: 'closed_date_last_quarter',
        name: 'Last Quarter',
      },
      {
        id: 'closed_date_custom',
        name: 'Custom',
      },
    ],
  },
  // {
  //   key: 4,
  //   name: 'priority',
  //   label: 'Priority',
  //   placeholder: 'Select',
  //   type: 'select',
  //   options: [
  //     {
  //       id: null,
  //       name: 'All',
  //     },
  //     {
  //       id: 'true',
  //       name: 'Yes',
  //     },

  //     {
  //       id: 'false',
  //       name: 'No',
  //     },
  //   ],
  // },
  {
    key: 6,
    name: 'ties_to_license',
    label: 'Report Ties To License',
    placeholder: 'Select License(s)',
    options: store?.legislative_options?.report_source_ties_to_license,
  },
]

export const reportLiteInputs = (store, totals) => [
  {
    key: 1,
    name: 'jurisdiction',
    label: 'Jurisdiction',
    placeholder: 'Select',
    options: store.legislative_options?.report_source_jurisdictions,
    showDeprecatedLabel: true,
    showDeprecated: true,
    onlyEnabled: true,
  },
  {
    key: 2,
    name: 'report_type',
    label: 'Report Type',
    placeholder: 'Select',
    options: store?.legislative_options?.report_types,
  },
  {
    key: 4,
    name: 'reporting_entity',
    label: 'Reporting Entity',
    placeholder: 'Select',
    options: store.reportingEntities,
  },
  {
    key: 5,
    name: 'priority',
    label: 'Priority',
    placeholder: 'Select',
    type: 'select',
    options: [
      {
        id: null,
        name: 'All',
      },
      {
        id: 'true',
        name: 'Yes',
      },

      {
        id: 'false',
        name: 'No',
      },
    ],
  },
  {
    key: 8,
    name: 'report_due_date',
    label: 'Report Due Date Range',
    placeholder: 'Select',
    type: 'select',
    options: [
      {
        id: 30,
        name: '30 Days',
      },
      {
        id: 60,
        name: '60 Days',
      },
      {
        id: 90,
        name: '90 Days',
      },
      {
        id: 'last_quarter',
        name: 'Last Quarter',
      },
      {
        id: 'custom',
        name: 'Custom',
      },
    ],
  },
  {
    key: 9,
    name: 'close_date',
    label: 'Closed Date Range',
    placeholder: 'Select',
    type: 'select',
    options: [
      {
        id: 30,
        name: '30 Days',
      },
      {
        id: 60,
        name: '60 Days',
      },
      {
        id: 90,
        name: '90 Days',
      },
      {
        id: 'closed_date_last_quarter',
        name: 'Last Quarter',
      },
      {
        id: 'closed_date_custom',
        name: 'Custom',
      },
    ],
  },

  {
    key: 6,
    name: 'ties_to_license',
    label: 'Report Ties To License',
    placeholder: 'Select License(s)',
    options: store?.legislative_options?.report_source_ties_to_license,
  },
]