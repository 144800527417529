import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import Moment from 'react-moment'
import { useHistory } from 'react-router-dom'
import { api } from '../../../../../../api'
import { useStore } from '../../../../../../mobx-store/context'
import { Title } from '../../../../components/common/style'
import ResourceModal from '../../../../components/ResourceAccessModal/resourceModal'
// import BillingSchedule from '../../bollingSchedule'
import Info from '../../info'

const ProposedSourceTab = observer(() => {
  const store = useStore()
  const history = useHistory()


  const company = store.company[0]

  const leftData = [
    {
      key: 0,
      title: 'Plan Admin',
      text:
        store.members
          ?.filter((item) => item.member_type === 'admin')
          ?.map((item) => ` ${item.first_name} ${item.last_name}`)
          .toString() || '-',
    },
    // {
    //   key: 1,
    //   title: 'Team Size',
    //   text: `${store.members.length} Members`,
    // },
    {
      key: 1,
      title: 'Effective Date',
      hide: !company.proposed_source_registration_date,
      text: (
        company.proposed_source_registration_date ?
          <>
            <Moment format={'MM/DD/YYYY'}>{company.proposed_source_registration_date}</Moment>
          </> : '-'
      )
    },
    {
      key: 2,
      title: 'Access Through',
      hide: !company.proposed_source_next_payment_date,
      text: (company.proposed_source_next_payment_date ?
        <>
          <Moment format={'MM/DD/YYYY'}>{company.proposed_source_next_payment_date}</Moment>
        </> : '-'
      )
    },
    {
      key: 3,
      title: 'Look Back Date',
      hide: !company.proposed_source_legislations_implement_date,
      text: (company.proposed_source_legislations_implement_date ?
        <>
          <Moment format={'MM/DD/YYYY'}>{company.proposed_source_legislations_implement_date}</Moment>
        </> : '-'
      )
    },
  ]
  const rightData = [
    //  {
    //   key: 0,
    //   title: company?.under_contract ? 'Custom Price' : 'Plan Cost',
    //   text: company?.under_contract
    //     ? `$${company.proposed_source_custom_price}/year`
    //     : '$60,000/year',
    // },
    // {
    //   key: 1,
    //   title: 'Next Billing Date',
    //   text: (
    //     <Moment format={'MM/DD/YYYY'}>
    //       {company.proposed_source_next_payment_date}
    //     </Moment>
    //   ),
    // },
    // {
    //   key: 2,
    //   title: 'Billing Schedule',
    //   text: 'Annual',
    // },
  ]

  useEffect(() => {
    if (!store.members.length) store.loadMembers()
    if (!store.billingCards) store.getBillingCards()
    store.loadSelect('memberOptions')
  }, [])

  // useEffect(() => {
  //   api('/resources/guides_in_plan/', {}, 'GET').then((data) => null)
  // }, [])

  if (!store.user?.[0]?.have_access_to_proposed_source || !store.company?.[0]?.have_access_to_proposed_source)
    return (
      <ResourceModal
        type='proposedSource'
        to={store.user?.[0]?.member_type === 'admin' ? '/addProposedMember' : '/edit-profile'}
        onClose={() => history.push('/edit-profile')}
      />
    )

  return (
    <>
      <Title>
        ProposedSource
      </Title>
      <Info leftData={leftData} rightData={rightData} />
      {/* <BillingSchedule /> */}
    </>
  )
})

export default ProposedSourceTab
