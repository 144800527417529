import styled from 'styled-components'

export const Wrap = styled('div')`
  height: 4.375em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2em;
  font-family: var(--semiBold);
  font-size: 1em;
  color: var(--green);
  border: 1px solid var(--green);
  white-space: nowrap;
  div {
    &:last-child {
      font-family: var(--light);
    }
  }
`
