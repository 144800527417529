import styled from 'styled-components'

export const Wrap = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em;
  width: 100%;
  border: 1px solid
    ${(props) => (props.success ? 'var(--green)' : 'var(--orange)')};
  font-size: 0.75em;
  color: ${(props) => (props.success ? 'var(--green)' : 'var(--orange)')};
  font-family: var(--bold);
  text-align: center;
`
