import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import Button from '../../../../components/common/Button/button'
import Checkbox from '../../../../components/common/Checkbox'
import { Row } from '../../components/common/style'
import { AutoRenewalWrap } from './style'

const AutoRenewal = observer(
  ({
    styleWrap,
    disabled,
    onCheck,
    checked,
    setChecked,
    firstPaymentDate,
    nextDate,
  }) => {
    const [active, setActive] = useState(false)

    const onChange = (checked) => {
      if (!checked) {
        setActive(true)
      } else {
        setActive(false)
        onCheck(checked)
      }
    }

    useEffect(() => {
      setActive(false)
    }, [checked])

    return (
      <AutoRenewalWrap active={!disabled && active} style={styleWrap}>
        <Checkbox
          label={
            firstPaymentDate
              ? `Auto Renews on ${moment(firstPaymentDate).format(
                  'MM/DD/YYYY'
                )}`
              : 'Auto Renews'
          }
          style={{ marginBottom: 10 }}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          // disabled={!store.tariffInfo?.can_change_all_tariffs || disabled}
          reverse
        />

        {!disabled && active && (
          <Row style={{ fontSize: 14, fontFamily: 'var(--medium)' }}>
            Are you sure that you want to disable auto renewal? Service will end
            on{' '}
            <Moment format={'MM/DD/YY'} style={{ display: 'contents' }}>
              {nextDate}
            </Moment>{' '}
            if not resumed.
            <Row style={{ marginLeft: 80 }}>
              <Button
                text={'No'}
                onClick={() => {
                  setActive(false)
                  onCheck(true)
                }}
                style={{ marginRight: '5px' }}
              />
              <Button
                text={'Yes'}
                onClick={() => onCheck(false)}
                style={{ marginLeft: '5px' }}
                border
              />
            </Row>
          </Row>
        )}
      </AutoRenewalWrap>
    )
  }
)

export default AutoRenewal
