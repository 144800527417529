import { GuideWrapper, GuideNameContainer, StyledTooltip } from "../style";
import React, { useEffect, useRef, } from 'react';
import { Tooltip } from 'antd';

const GuideItem = ({ guide, targetId, id, setActiveGuide }) => {
  const itemRef = useRef(null);

  useEffect(() => {
    if (+targetId === +id && itemRef.current) {
      const targetElement = itemRef.current;
      const rect = targetElement.getBoundingClientRect();
      const offset = 380;
      const absoluteTop = window.pageYOffset + rect.top - offset;
      window.scrollTo({
        top: absoluteTop,
        behavior: "smooth"
      });
    }
  }, [ id]);

  const customTooltipStyles = {
    borderRadius: '8px',
    background: '#FFFFFF',
    boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.25)',
    color: 'var(--text)',
    fontFamily: 'var(--regular)',
  };

  const handleMouseEnter = () => {
    setActiveGuide(id);
    // Дополнительные действия при наведении
  };

  const handleMouseLeave = () => {
    setActiveGuide(null);
    // Дополнительные действия при уходе курсора
  };

  return (

    <div ref={itemRef}>
      <GuideWrapper isSelected={+id === +targetId} >
        <StyledTooltip visible={+id === +targetId} overlayInnerStyle={customTooltipStyles} overlayStyle={{ minWidth: 500 }} title={guide.description} color={'#FFF'}>
          <GuideNameContainer
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >{guide.name}</GuideNameContainer>
        </StyledTooltip>
        {/* <GuideDescriptionContainer>{guide.description}</GuideDescriptionContainer> */}
      </GuideWrapper>
    </div>

  );
};

export default GuideItem;