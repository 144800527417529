import { message } from 'antd'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { api } from '../../../api'
import img from '../../../assets/images/reset-password.svg'
import Logo from '../../../components/common/Logo/logo'
import { useStore } from '../../../mobx-store/context'
import {
  BackButton,
  Container,
  Description,
  Wrap,
} from '../Agreement/agreement.style'
import { Title } from '../authForm.style'
import { Image, MiniTitle } from '../ResetPass/resetPass.style'
import { useEffect } from 'react'

const ConfirmEmail = observer(() => {
  const store = useStore()

  const sendAgain = () => {
    api('/users/send_confirmation_email/ ', {}, 'POST').then((data) => {
      if (!data.errors) {
        message.success('Email sent')
      } else {
        message.error('Error while sending email')
      }
    })
  }

  useEffect(() => {
    if (!store.user.length) store.getUser()
  }, [])

  return (
    <Container>
      <Wrap disableScroll>
        <Logo large/>
        <Title
          style={{
            borderBottom: '1px solid rgba(196, 202, 204, 0.6)',
            paddingBottom: '.7em',
            textAlign: 'center',
          }}
        >
          Validate Your Email: {store.user[0]?.email || store.user?.email}
        </Title>
        <Image src={img} alat={'email-image'}/>
        <MiniTitle>Check your email.</MiniTitle>
        <Description
          style={{
            fontFamily: 'var(--regular)',
            fontSize: '.875em',
            textAlign: 'center',
          }}
        >
          We’ve sent an email to {store.user[0]?.email || store.user?.email},
          please click the link to continue. <br/>
          <span style={{ fontFamily: 'var(--medium)' }}>
            Didn’t get the message? Check your spam folder or
            <span
              style={{
                color: 'var(--orange)',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={sendAgain}
            >
             {' '}send it again
            </span>
          </span>


        </Description>
        <BackButton to={'/login'} as={Link}>
          &lt;&lt; {'Return to Sign In'}
        </BackButton>
      </Wrap>
    </Container>
  )
})

export default ConfirmEmail
